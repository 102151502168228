import { MuiTheme } from "theme";
import { makeStyles } from "@material-ui/core/styles";

export default makeStyles((theme: MuiTheme) => {
  const {
    primary: { light, dark }
  } = theme.palette;

  return {
    root: {
      padding: "13px 24px",
      borderBottom: `1px solid ${light}`,
      backgroundColor: "#fff",
      maxHeight: 120
    },
    header: {
      display: "flex",
      justifyContent: "space-between",
      alignItems: "center",
      height: 38
    },
    title: {
      fontFamily: "SuisseIntlMedium, SuisseIntlRegular",
      fontSize: 16
    },
    entriesCount: {
      color: "#333"
    },
    settings: {
      display: "flex",
      justifyContent: "space-between"
    },
    viewSwitch: {
      display: "flex",
      justifyContent: "space-between",
      paddingTop: 12,
      gap: 20
    },
    formControlLabelLabel: {
      fontSize: 12,
      color: dark
    },
    selectAllBlock: {
      marginRight: 20,
      width: 86
    },
    selectAllText: {
      fontSize: 8,
      color: dark,
      textAlign: "center",
      lineHeight: "20px"
    },
    selectAllCheckboxes: {
      display: "flex",
      justifyContent: "space-between"
    },
    checkbox: {
      color: "#333",
      "&$checked": {
        color: "#333"
      },
      "& .MuiSvgIcon-root": {
        width: 28,
        height: 28
      }
    }
  };
});
