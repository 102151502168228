import React from "react";

import VetExamFormField from "../../../VetExamFormField";
import EntryItem from "../../../EntryItem";
import {
  clearExamDetailObservation,
  deleteExamDetailObservation,
  examDetailsObservationsFields,
  FormField,
  IExamDetailObservation
} from "../../../options";
import { useVetExamContext } from "../../../VetExamStateProvider/VetExamProvider";
import { useExamLockerContext } from "../../../../ExamLockerContext/ExamLockerContext";
import { checkSelectingFieldsOneEntry } from "../../../helper";
import useParentsStyles from "components/VetExamOverlay/styles";
import useStyles from "./styles";

interface IProps {
  index: number;
  observationItem: IExamDetailObservation;
  detailId: string;
}

const DetailsObservation = (props: IProps) => {
  const { index, observationItem, detailId } = props;

  const classes = useStyles();
  const parentsClasses = useParentsStyles();
  const { dispatch, state } = useVetExamContext();
  const { lockExam } = useExamLockerContext();
  const isDisabledDeleteButton =
    index === 1 && !checkSelectingFieldsOneEntry(observationItem);

  const handleDelete = () => {
    const currentDetail = state.examDetails.find(
      detail => detail.id === detailId
    );
    const actionCreator =
      currentDetail.workoutExamDetailObservationEntries.length === 1
        ? clearExamDetailObservation(detailId)
        : deleteExamDetailObservation(detailId, observationItem.id);

    dispatch(actionCreator);
    lockExam();
  };

  const renderField = (field: FormField) => {
    return (
      <VetExamFormField
        key={field.key}
        field={field}
        entryState={observationItem}
        parentId={detailId}
      />
    );
  };

  return (
    <EntryItem
      entryNumber={index}
      onDeleteEntry={handleDelete}
      disabledRemoveButton={isDisabledDeleteButton}
      className={classes.observationEntry}
      title={"Observation"}
    >
      <div className={parentsClasses.fieldsWrapper}>
        {examDetailsObservationsFields.map(renderField)}
      </div>
    </EntryItem>
  );
};

export default DetailsObservation;
