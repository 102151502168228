import React, { useEffect } from "react";
import { useHistory } from "react-router-dom";
import clsx from "clsx";

import Tabs from "@material-ui/core/Tabs";
import Tab from "@material-ui/core/Tab";

import ITab from "interfaces/Tab";
import { createDataTestAttr } from "utils/data-test-attributes-utils";
import useStyles from "./styles";

export interface Props {
  tabs: ITab[];
  selected: ITab;
  onChange: (tab: ITab) => void;
  className?: string;
}

const TabsComponent = React.memo((props: Props) => {
  const { tabs, selected, onChange, className } = props;
  const classes = useStyles();
  const history = useHistory();

  useEffect(() => {
    const tab = tabs.find(item => item.path === history.location.pathname);
    if (tab) {
      onChange(tab);
    }
  }, []);

  const handleChange = (
    event: React.ChangeEvent<{ value: string }>,
    value: string
  ) => {
    const tab = tabs.find(item => item.value === value);
    history.replace(tab.path);
    onChange(tab);
  };

  return (
    <Tabs
      value={selected.value}
      onChange={handleChange}
      className={clsx(classes.tabs, className)}
    >
      {tabs.map((item, i) => (
        <Tab
          classes={{
            wrapper: clsx(classes.wrapper, {
              [classes.tabUpdated]: item.isUpdated
            })
          }}
          className={classes.tab}
          label={item.name}
          value={item.value}
          key={i}
          data-test={`${createDataTestAttr(item.name)}-tab`}
        />
      ))}
    </Tabs>
  );
});

export default TabsComponent;
