import { makeStyles } from "@material-ui/core/styles";
import Breakpoints from "common/breakpoints";
import { MuiTheme } from "theme";

export default makeStyles((theme: MuiTheme) => {
  const { primary, secondary } = theme.palette;

  return {
    modal: {
      padding: "0 20px 12px",
      borderRadius: 3,
      "&.submit .MuiPaper-root": {
        width: 272
      },
      "&.reset .MuiPaper-root": {
        width: 272
      },
      "&.pauseOrClose .MuiPaper-root": {
        width: 366
      },
      "& .MuiDialog-paper": {
        [theme.breakpoints.down(Breakpoints.SM_600)]: {
          margin: 0
        }
      }
    },
    dialogTitle: {
      height: 55,
      display: "flex",
      alignItems: "center",
      padding: "0 20px",
      fontSize: 20,
      "& .MuiTypography-h6": {
        fontFamily: "SuisseIntlMedium, SuisseIntlRegular"
      }
    },
    dialogContent: {
      padding: "8px 20px 14px"
    },
    contentLabel: {
      color: primary.dark,
      fontSize: 12,
      height: 16
    },
    contentValue: {
      fontFamily: "SuisseIntlMedium, SuisseIntlRegular",
      height: 40,
      paddingTop: 4,
      color: primary.main
    },
    contentText: {
      color: primary.dark,
      lineHeight: "19px",
      paddingBottom: 18,
      wordWrap: "break-word"
    },
    dialogActions: {
      display: "flex",
      justifyContent: "flex-end",
      padding: "0 8px 12px"
    },
    actionButton: {
      height: 32,
      padding: "0 10px",
      marginLeft: 10,
      fontSize: 16,
      color: primary.dark,
      borderRadius: 2,
      fontFamily: "SuisseIntlMedium, SuisseIntlRegular",
      "&:first-child": {
        marginLeft: 0
      },
      [theme.breakpoints.down(Breakpoints.SM_600)]: {
        padding: "0 4px"
      }
    },
    successButton: {
      color: secondary.dark
    },
    cancelButton: {
      color: "#f73d54"
    }
  };
});
