import React, { Reducer, useEffect, useReducer } from "react";
import isMatch from "lodash/isMatch";
import { useQueryClient } from "react-query";
import formatISO from "date-fns/formatISO";
import parseISO from "date-fns/parseISO";
import sub from "date-fns/sub";

import Button from "@material-ui/core/Button";
import FormControl from "@material-ui/core/FormControl";
import MenuItem from "@material-ui/core/MenuItem";
import TextField from "@material-ui/core/TextField";
import IconButton from "@material-ui/core/IconButton";
import ClearIcon from "@material-ui/icons/Clear";
import DateFnsUtils from "@date-io/date-fns";
import { DatePicker, MuiPickersUtilsProvider } from "@material-ui/pickers";

import { racehorse360 } from "@tsg/1st-grpc-web";
import { useConfirmationContext } from "components/ConfirmationDialog/ConfirmationContext";
import Loader from "components/Loader";
import { useRacehorse360Api } from "hooks/api";
import { SortOrder } from "interfaces/SortOrder";
import useStyles from "./styles";

export interface Props {
  horse: racehorse360.IHorse;
  injection?: racehorse360.IInjection;
  type: racehorse360.MedicineType;
  isEdit?: boolean;
  onCancel: () => void;
  closeModal: () => void;
}

interface IState
  extends Omit<
    racehorse360.IInjection,
    "injectionDate" | "customExpirationDate"
  > {
  injectionDate?: Date;
  customExpirationDate?: Date;
}

interface IAction {
  type: EActionType;
  key?: string;
  value?: string | Date;
}

enum EActionType {
  CHANGE,
  RESET
}

const initialState: IState = {
  medicineId: "",
  manufacturer: "",
  injectionDate: null,
  customExpirationDate: null,
  comment: ""
};

const reducer = (state: IState, action: IAction) => {
  switch (action.type) {
    case EActionType.CHANGE:
      return {
        ...state,
        [action.key]: action.value
      };
    case EActionType.RESET:
      return initialState;
    default:
      return state;
  }
};

const mapStateToInjection = (state: IState): racehorse360.IInjection => {
  return {
    ...state,
    injectionDate: formatISO(state.injectionDate as Date, {
      representation: "date"
    }),
    customExpirationDate: state.customExpirationDate
      ? formatISO(state.customExpirationDate, {
          representation: "date"
        })
      : null,
    comment: state.comment ? state.comment : "",
    manufacturer: state.manufacturer ? state.manufacturer : ""
  };
};

const mapInjectionToState = (injection: racehorse360.IInjection): IState => {
  return {
    ...injection,
    injectionDate: injection?.injectionDate
      ? parseISO(injection?.injectionDate)
      : null,
    customExpirationDate: injection?.customExpirationDate
      ? parseISO(injection?.customExpirationDate)
      : null,
    comment: injection.comment ? injection.comment : "",
    manufacturer: injection.manufacturer ? injection.manufacturer : ""
  };
};

const InjectionForm = (props: Props) => {
  const { horse, type, isEdit, onCancel, closeModal, injection = {} } = props;

  const classes = useStyles();
  const queryClient = useQueryClient();
  const {
    openConfirmation,
    confirmationRequired,
    setConfirmationRequired,
    setConfirmationDisabled,
    setHandleConfirm,
    setHandleDiscard
  } = useConfirmationContext();
  const isBooster = type === racehorse360.MedicineType.MEDICINE_TYPE_BOOSTER;

  const [state, dispatch] = useReducer<Reducer<IState, IAction>>(
    reducer,
    isEdit
      ? {
          ...initialState,
          ...mapInjectionToState(injection)
        }
      : initialState
  );

  const hasChanges = isEdit
    ? !isMatch(mapInjectionToState(injection), state)
    : Boolean(Object.values(state).filter(Boolean).length);

  const {
    useAddVaccineInjection,
    useAddBoosterInjection,
    useUpdateInjection,
    useListMedicines
  } = useRacehorse360Api();

  const { data: medicinesData, isLoading: isListMedicinesLoading } =
    useListMedicines(
      {
        query: {
          medicineTypes: [type]
        },
        pagingOptions: {
          maxResults: 999
        },
        getOptions: {
          select: ["id", "name", "type", "expiredInDays"],
          orderBy: [`name ${SortOrder.ASC}`]
        }
      },
      {
        onError: error => console.error(error),
        initialData: {
          medicines: []
        }
      }
    );
  const { medicines } = medicinesData;
  const activeMedicine = medicines.find(m => m.id === state.medicineId);

  const {
    mutateAsync: addVaccineInjection,
    isLoading: isAddVaccineInjectionLoading
  } = useAddVaccineInjection();

  const {
    mutateAsync: addBoosterInjection,
    isLoading: isAddBoosterInjectionLoading
  } = useAddBoosterInjection();

  const { mutateAsync: updateInjection, isLoading: isUpdateInjectionLoading } =
    useUpdateInjection();

  const isLoading = [
    isListMedicinesLoading,
    isAddVaccineInjectionLoading,
    isAddBoosterInjectionLoading,
    isUpdateInjectionLoading,
    !medicines?.length
  ].some(Boolean);

  useEffect(() => {
    if (activeMedicine?.expiredInDays) {
      dispatch({
        type: EActionType.CHANGE,
        key: "customExpirationDate",
        value: null
      });
    }
  }, [state.medicineId]);

  useEffect(() => {
    setConfirmationDisabled(!isValidModel());
    setConfirmationRequired(hasChanges);
    if (hasChanges) {
      setHandleConfirm(handleChangesConfirm);
      setHandleDiscard(handleChangesDiscard);
    }
  }, [state]);

  const shouldDisableInjectionDate = (date: Date): boolean => {
    return state.customExpirationDate && date > state.customExpirationDate;
  };

  const shouldDisableExpirationDate = (date: Date): boolean => {
    return state.injectionDate && date < sub(state.injectionDate, { days: 1 });
  };

  const isValidModel = () =>
    Boolean(state.medicineId) && Boolean(state.injectionDate);

  const discardChanges = () => {
    setHandleConfirm(null);
    setHandleDiscard(null);
    closeModal();
  };

  const handleChangesConfirm = () => () => {
    handleAdd().then(() => {
      setConfirmationDisabled(false);
      setConfirmationRequired(false);
      setHandleConfirm(null);
      setHandleDiscard(null);
      closeModal();
    });
  };

  const handleChangesDiscard = () => () => {
    discardChanges();
  };

  const handleAdd = () => {
    if (isEdit) {
      return updateInjection({
        injection: { ...mapStateToInjection(state), dataSource: undefined },
        updateMask: {
          paths: [
            "medicineId",
            "manufacturer",
            "comment",
            "injectionDate",
            "customExpirationDate"
          ]
        }
      }).then(() => {
        discardChanges();
        return Promise.all([
          queryClient.invalidateQueries([
            `${injection.vaccination?.id}-list-boosters`
          ]),
          queryClient.invalidateQueries([`${horse.id}-list-vaccines`])
        ]);
      });
    } else {
      if (isBooster) {
        return addBoosterInjection({
          horseId: horse.id,
          injection: {
            ...mapStateToInjection(state),
            vaccinationId: injection.vaccination?.id
          },
          vaccinationName: medicines.find(
            medicine => medicine.id === state.medicineId
          ).name
        }).then(() => {
          discardChanges();
          return Promise.all([
            queryClient.invalidateQueries([
              `${injection.vaccination?.id}-list-boosters`
            ]),
            queryClient.invalidateQueries([`${horse.id}-list-vaccines`])
          ]);
        });
      } else {
        return addVaccineInjection({
          horseId: horse.id,
          injection: {
            ...state,
            injectionDate: formatISO(state.injectionDate as Date, {
              representation: "date"
            }),
            customExpirationDate:
              state.customExpirationDate && !activeMedicine?.expiredInDays
                ? formatISO(state.customExpirationDate, {
                    representation: "date"
                  })
                : null
          },
          vaccinationName: medicines.find(
            medicine => medicine.id === state.medicineId
          ).name
        }).then(() => {
          discardChanges();
          return queryClient.invalidateQueries(`${horse.id}-list-vaccines`);
        });
      }
    }
  };

  const handleChange = key => event => {
    dispatch({ type: EActionType.CHANGE, key, value: event.target.value });
  };

  const handleDateChange = key => value => {
    dispatch({ type: EActionType.CHANGE, key, value });
  };

  const handleClear = key => (event: React.SyntheticEvent) => {
    event.stopPropagation();
    dispatch({ type: EActionType.CHANGE, key, value: null });
  };

  const handleClose = () => {
    if (confirmationRequired) {
      openConfirmation();
    } else {
      onCancel();
    }
  };

  return (
    <>
      {isLoading && <Loader overlay />}
      <p className={classes.horseName}>Horse: {horse.name}</p>
      {isBooster && (
        <p className={classes.horseName}>
          Vaccine: {injection?.medicine?.name || "N/A"}
        </p>
      )}
      <MuiPickersUtilsProvider utils={DateFnsUtils}>
        <div className={classes.form}>
          <FormControl fullWidth>
            <TextField
              select
              label={`${isBooster ? "Booster" : "Vaccine"} Name`}
              value={state.medicineId}
              onChange={handleChange("medicineId")}
              variant={"outlined"}
              size={"small"}
              InputProps={{
                classes: {
                  root: classes.inputRoot,
                  focused: classes.inputFocused,
                  notchedOutline: classes.inputNotchedOutline
                }
              }}
              InputLabelProps={{
                shrink: true,
                classes: {
                  root: classes.inputLabelRoot,
                  shrink: classes.inputLabelShrink
                }
              }}
              SelectProps={{
                classes: { root: classes.selectRoot },
                displayEmpty: true,
                renderValue:
                  state.medicineId !== ""
                    ? undefined
                    : () => (
                        <span className={classes.selectPlaceholder}>
                          Select One
                        </span>
                      )
              }}
            >
              {medicines?.length ? (
                medicines.map(option => {
                  return (
                    <MenuItem key={option.id} value={option.id}>
                      {option.name}
                    </MenuItem>
                  );
                })
              ) : (
                <MenuItem key={state.medicineId} value={state.medicineId}>
                  {injection?.medicine?.name}
                </MenuItem>
              )}
            </TextField>
          </FormControl>

          <FormControl fullWidth>
            <TextField
              label={
                <>
                  Manufacturer{" "}
                  <span className={classes.subtitle}>(Optional)</span>
                </>
              }
              value={state.manufacturer}
              onChange={handleChange("manufacturer")}
              variant={"outlined"}
              size={"small"}
              InputProps={{
                classes: {
                  root: classes.inputRoot,
                  focused: classes.inputFocused,
                  notchedOutline: classes.inputNotchedOutline
                }
              }}
              InputLabelProps={{
                classes: {
                  root: classes.inputLabelRoot,
                  shrink: classes.inputLabelShrink
                }
              }}
              inputProps={{
                maxLength: 200
              }}
            />
          </FormControl>

          <FormControl fullWidth>
            <DatePicker
              autoOk
              disableToolbar
              format={"MM/dd/yyyy"}
              label={`${isBooster ? "Booster" : "Vaccination"} Date`}
              InputProps={{
                classes: {
                  root: classes.inputRoot,
                  focused: classes.inputFocused,
                  notchedOutline: classes.inputNotchedOutline,
                  input: classes.datePicker,
                  adornedEnd: classes.inputAdornedEnd
                },
                endAdornment: state.injectionDate ? (
                  <IconButton
                    size={"small"}
                    onClick={handleClear("injectionDate")}
                  >
                    <ClearIcon />
                  </IconButton>
                ) : (
                  <></>
                )
              }}
              InputLabelProps={{
                shrink: true,
                classes: {
                  root: classes.inputLabelRoot,
                  shrink: classes.inputLabelShrink
                }
              }}
              value={state.injectionDate}
              onChange={handleDateChange("injectionDate")}
              size={"small"}
              variant={"inline"}
              inputVariant={"outlined"}
              placeholder={"MM/DD/YYYY"}
              shouldDisableDate={shouldDisableInjectionDate}
              initialFocusedDate={state.customExpirationDate || new Date()}
            />
          </FormControl>

          {activeMedicine && !activeMedicine.expiredInDays && (
            <FormControl fullWidth>
              <DatePicker
                autoOk
                disableToolbar
                format={"MM/dd/yyyy"}
                label={
                  <>
                    Expiration Date{" "}
                    <span className={classes.subtitle}>(Optional)</span>
                  </>
                }
                InputProps={{
                  classes: {
                    root: classes.inputRoot,
                    focused: classes.inputFocused,
                    notchedOutline: classes.inputNotchedOutline,
                    input: classes.datePicker,
                    adornedEnd: classes.inputAdornedEnd
                  },
                  endAdornment: state.customExpirationDate ? (
                    <IconButton
                      size={"small"}
                      onClick={handleClear("customExpirationDate")}
                    >
                      <ClearIcon />
                    </IconButton>
                  ) : (
                    <></>
                  )
                }}
                InputLabelProps={{
                  shrink: true,
                  classes: {
                    root: classes.inputLabelRoot,
                    shrink: classes.inputLabelShrink
                  }
                }}
                value={state.customExpirationDate}
                onChange={handleDateChange("customExpirationDate")}
                size={"small"}
                variant={"inline"}
                inputVariant={"outlined"}
                placeholder={"MM/DD/YYYY"}
                shouldDisableDate={shouldDisableExpirationDate}
                initialFocusedDate={state.injectionDate || new Date()}
              />
            </FormControl>
          )}

          <FormControl fullWidth>
            <p className={classes.commentLabel}>
              Comment{" "}
              <span className={classes.commentLabelNote}>(Optional)</span>
            </p>
            <TextField
              minRows={2}
              multiline
              inputProps={{
                maxLength: 200
              }}
              InputProps={{
                classes: {
                  root: classes.inputRoot,
                  focused: classes.inputFocused
                }
              }}
              InputLabelProps={{
                classes: {
                  root: classes.inputLabelRoot
                }
              }}
              value={state.comment}
              onChange={handleChange("comment")}
              variant={"outlined"}
              size={"small"}
              placeholder={"Add Note"}
            />
          </FormControl>
        </div>
      </MuiPickersUtilsProvider>
      <div className={classes.actions}>
        <Button
          classes={{
            root: classes.buttonRoot,
            label: classes.buttonLabel
          }}
          variant={"outlined"}
          onClick={handleClose}
        >
          {"Cancel"}
        </Button>
        <Button
          className={classes.buttonAdd}
          classes={{
            root: classes.buttonRoot,
            label: classes.buttonLabel,
            disabled: classes.buttonAddDisabled
          }}
          variant={"outlined"}
          onClick={handleAdd}
          disabled={!isValidModel()}
        >
          {isEdit ? "Save" : "Add"} Entry
        </Button>
      </div>
    </>
  );
};

export default InjectionForm;
