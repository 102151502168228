import React, { useEffect, useState } from "react";
import clsx from "clsx";
import format from "date-fns/format";
import IconButton from "@material-ui/core/IconButton";
import DoneIcon from "@material-ui/icons/Done";
import DateRangeIcon from "@material-ui/icons/DateRange";
import CreateIcon from "@material-ui/icons/Create";
import Button from "@material-ui/core/Button";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import { DateRangePicker } from "react-date-range";
import DateRange, { DateRangeType } from "interfaces/DateRange";
import DropDownDialog from "components/DropDownDialog";
import useStyles from "./styles";
import "react-date-range/dist/styles.css";
import "react-date-range/dist/theme/default.css";

interface Props {
  selectedDateRange?: DateRange;
  onChange?: (dateRange: DateRange) => void;
}

const DateRangeSelect = React.memo((props: Props) => {
  const { onChange } = props;
  const classes = useStyles();
  const [open, setOpen] = useState<boolean>(false);
  const [selectedDateRangeType, setSelectedDateRangeType] =
    useState<DateRangeType>(
      props.selectedDateRange?.Type || DateRangeType.Next3Days
    );
  const [customDateRange, setCustomDateRange] = useState<Date[]>(
    props.selectedDateRange?.CustomDateRange || []
  );
  const [calendarOpen, setCalendarOpen] = useState<boolean>(false);

  const calendarInitialState = {
    startDate: customDateRange[0] || new Date(),
    endDate: customDateRange[1] || new Date(),
    key: "selection"
  };

  const [calendarState, setCalendarState] = useState([calendarInitialState]);

  const calendarDateFrom = calendarState[0].startDate || new Date();
  const calendarDateTo = calendarState[0].endDate || calendarDateFrom;

  const handleDialogOpen = () => {
    setOpen(true);
  };

  const handleDialogClose = () => {
    setOpen(false);
    setCalendarOpen(false);
  };

  const handleSelect = (dateRangeType: DateRangeType) => () => {
    if (dateRangeType === DateRangeType.Custom && !customDateRange.length) {
      handleSelectCalendar();
    } else {
      const dateRange: DateRange = {
        Type: dateRangeType,
        CustomDateRange: customDateRange
      };

      setSelectedDateRangeType(dateRangeType);

      if (onChange) {
        onChange(dateRange);
      }

      setOpen(false);
    }
  };

  const handleSelectCalendar = () => {
    setCalendarOpen(true);
  };

  const handleCalendarStateChanged = item => {
    setCalendarState([item.selection]);
  };

  const handleCalendarCancel = () => {
    setCalendarState([calendarInitialState]);
    setCalendarOpen(false);
  };

  const handleCalendarSave = () => {
    const calendarDateRange = [
      calendarState[0].startDate,
      calendarState[0].endDate
    ];

    const dateRange: DateRange = {
      Type: DateRangeType.Custom,
      CustomDateRange: calendarDateRange
    };

    setCustomDateRange(dateRange.CustomDateRange);
    setSelectedDateRangeType(dateRange.Type);
    setCalendarOpen(false);

    if (onChange) {
      onChange(dateRange);
    }

    setOpen(false);
  };

  const customDateRangeText = customDateRange
    .map(date => format(date, "MM/dd"))
    .join(" - ");
  let dateRangeText: string;

  if (selectedDateRangeType === DateRangeType.AllDates) {
    dateRangeText = "All Dates";
  } else if (selectedDateRangeType === DateRangeType.Today) {
    dateRangeText = "Today";
  } else if (selectedDateRangeType === DateRangeType.Tomorrow) {
    dateRangeText = "Tomorrow";
  } else if (selectedDateRangeType === DateRangeType.Next3Days) {
    dateRangeText = "Next 3 Days";
  } else {
    dateRangeText = customDateRangeText;
  }

  useEffect(() => {
    setSelectedDateRangeType(
      props.selectedDateRange?.Type || DateRangeType.Next3Days
    );
    setCustomDateRange(props.selectedDateRange?.CustomDateRange || []);
  }, [props.selectedDateRange]);

  return (
    <>
      <DropDownDialog
        open={open}
        dialogWidth={calendarOpen ? 840 : 240}
        openButtonText={dateRangeText}
        dialogClassName={clsx({
          [classes.dialogCalendarOpen]: calendarOpen
        })}
        dialogContentClassName={clsx({
          [classes.dialogContentCalendarOpen]: calendarOpen
        })}
        openButtonClassName={classes.dateRangeButton}
        onOpen={handleDialogOpen}
        onClose={handleDialogClose}
      >
        {!calendarOpen && (
          <List disablePadding>
            <ListItem button divider className={classes.listItem}>
              <Button
                fullWidth
                className={classes.listItemButton}
                onClick={handleSelect(DateRangeType.AllDates)}
                data-test={"all-dates-range"}
              >
                <DoneIcon
                  className={clsx(classes.listItemIconCheck, {
                    [classes.listItemIconCheckSelected]:
                      selectedDateRangeType === DateRangeType.AllDates
                  })}
                />
                <span className={classes.listItemText}>All Dates</span>
              </Button>
            </ListItem>
            <ListItem
              button
              divider
              className={classes.listItem}
              onClick={handleSelect(DateRangeType.Today)}
            >
              <Button
                fullWidth
                className={classes.listItemButton}
                data-test={"today-range"}
              >
                <DoneIcon
                  className={clsx(classes.listItemIconCheck, {
                    [classes.listItemIconCheckSelected]:
                      selectedDateRangeType === DateRangeType.Today
                  })}
                />
                <span className={classes.listItemText}>Today</span>
              </Button>
            </ListItem>
            <ListItem divider className={classes.listItem}>
              <Button
                fullWidth
                className={classes.listItemButton}
                onClick={handleSelect(DateRangeType.Tomorrow)}
                data-test={"tomorrow-range"}
              >
                <DoneIcon
                  className={clsx(classes.listItemIconCheck, {
                    [classes.listItemIconCheckSelected]:
                      selectedDateRangeType === DateRangeType.Tomorrow
                  })}
                />
                <span className={classes.listItemText}>Tomorrow</span>
              </Button>
            </ListItem>
            <ListItem divider className={classes.listItem}>
              <Button
                fullWidth
                className={classes.listItemButton}
                onClick={handleSelect(DateRangeType.Next3Days)}
                data-test={"next-3-days-range"}
              >
                <DoneIcon
                  className={clsx(classes.listItemIconCheck, {
                    [classes.listItemIconCheckSelected]:
                      selectedDateRangeType === DateRangeType.Next3Days
                  })}
                />
                <span className={classes.listItemText}>Next 3 Days</span>
              </Button>
            </ListItem>
            <ListItem className={classes.listItem}>
              <Button
                fullWidth
                className={classes.listItemButton}
                onClick={handleSelect(DateRangeType.Custom)}
                data-test={"custom-range"}
              >
                {!!customDateRange.length && (
                  <DoneIcon
                    className={clsx(classes.listItemIconCheck, {
                      [classes.listItemIconCheckSelected]:
                        selectedDateRangeType === DateRangeType.Custom
                    })}
                  />
                )}
                {!customDateRange.length && (
                  <DateRangeIcon className={classes.listItemIconDate} />
                )}
                <span className={classes.listItemDateText}>
                  {!customDateRange.length
                    ? "Custom Date Range"
                    : customDateRangeText}
                </span>
              </Button>
              {!!customDateRange.length && (
                <IconButton
                  className={classes.listItemButton}
                  onClick={handleSelectCalendar}
                >
                  <CreateIcon className={classes.listItemIconEdit} />
                </IconButton>
              )}
            </ListItem>
          </List>
        )}
        {calendarOpen && (
          <div className={classes.calendarContainer}>
            <div className={classes.selectedDates}>
              <div className={classes.selectedDatesTitle}>Select range</div>
              <div className={classes.selectedDatesContent}>
                <div className={classes.selectedDatesItem}>
                  <div className={classes.selectedDatesItemTitle}>from</div>
                  <div className={classes.selectedDatesItemValue}>
                    {format(calendarDateFrom, "eee, MMM d")}
                  </div>
                </div>
                <div className={classes.selectedDatesItem}>
                  <div className={classes.selectedDatesItemTitle}>to</div>
                  <div className={classes.selectedDatesItemValue}>
                    {format(calendarDateTo, "eee, MMM d")}
                  </div>
                </div>
              </div>
            </div>

            <div className={classes.calendar}>
              <div className={classes.datePickers}>
                <DateRangePicker
                  months={2}
                  ranges={calendarState}
                  rangeColors={["#62ecb2", "#3ecf8e", "#fed14c"]}
                  direction="horizontal"
                  showSelectionPreview={true}
                  showMonthAndYearPickers={false}
                  showDateDisplay={false}
                  onChange={handleCalendarStateChanged}
                />
              </div>
            </div>

            <div className={classes.calendarButtons}>
              <Button onClick={handleCalendarCancel}>Cancel</Button>
              <Button
                className={classes.calendarSaveButton}
                onClick={handleCalendarSave}
              >
                Save
              </Button>
            </div>
          </div>
        )}
      </DropDownDialog>
    </>
  );
});

export default DateRangeSelect;
