import React, { useState } from "react";
import clsx from "clsx";

import { Theme } from "@material-ui/core/styles";
import useMediaQuery from "@material-ui/core/useMediaQuery";

import { racehorse360 } from "@tsg/1st-grpc-web";

import CommentsHeader from "components/Comments/CommentsHeader";
import CollapsedList from "components/CollapsedList";
import Loader from "components/Loader";
import { useLoggedInUser } from "components/LoggedInUserProvider";
import { useRacehorse360Api } from "hooks/api";
import DashboardNotificationItem from "./DashboardNotificationItem";
import NotificationModal from "./NotificationModal";
import { SortOrder } from "interfaces/SortOrder";
import useStyles from "./styles";

export interface Props {
  className?: string;
}

const DashboardNotifications = (props: Props) => {
  const { className } = props;
  const classes = useStyles();
  const { currentUser } = useLoggedInUser();
  const matchesUpMd = useMediaQuery((theme: Theme) =>
    theme.breakpoints.up("md")
  );

  const [unreadCount, setUnreadCount] = useState<number>(0);
  const [activeNotificationIndex, setActiveNotificationIndex] =
    useState<number>(null);
  const [showNotificationModal, setShowNotificationModal] =
    useState<boolean>(false);

  const { useListNotifications, useMarkNotificationsAsRead } =
    useRacehorse360Api();

  const { mutateAsync: markNotificationsAsReadRequest } =
    useMarkNotificationsAsRead();
  const {
    isLoading,
    data,
    refetch: refetchListNotifications,
    isFetching
  } = useListNotifications(
    {
      query: {
        receivedByIds: [currentUser.rh360Id]
      },
      getOptions: {
        select: [
          "id",
          "type",
          "creatorRole",
          "eventType",
          "eventDate",
          "body",
          "horse.name",
          "isRead",
          "createdBy.firstName",
          "createdBy.lastName",
          "createdOn"
        ],
        orderBy: [`createdOn ${SortOrder.DESC}`]
      },
      pagingOptions: {
        maxResults: 1000
      }
    },
    {
      onSuccess: data => {
        if (data?.notifications) {
          const unreadCount = data.notifications.reduce(
            (acc, cur) => (cur.isRead ? acc : ++acc),
            0
          );

          setUnreadCount(unreadCount);
        }
      },
      onError: error => console.error(error)
    }
  );
  const notifications: racehorse360.INotification[] = data?.notifications || [];

  const markNotificationsAsRead = (
    notification: racehorse360.INotification,
    areAllRead: boolean = false
  ) => {
    if (areAllRead || (notification && !notification.isRead)) {
      return markNotificationsAsReadRequest({
        ids: notification ? [notification.id] : null,
        markAllAsRead: areAllRead
      })
        .then(response => {
          refetchListNotifications();

          return response;
        })
        .catch(error => console.error(error));
    }
  };

  const handleMarkAllAsRead = () => {
    markNotificationsAsRead(null, true);
  };

  const handleItemClick = notificationIndex => () => {
    setActiveNotificationIndex(notificationIndex);
    setShowNotificationModal(true);
  };

  const handleCloseNotificationModal = () => {
    setShowNotificationModal(false);
    setActiveNotificationIndex(null);
    markNotificationsAsRead(notifications[activeNotificationIndex]);
  };

  const handleNextClick = () => {
    markNotificationsAsRead(notifications[activeNotificationIndex]);
    setActiveNotificationIndex(activeNotificationIndex + 1);
  };

  const handlePreviousClick = () => {
    markNotificationsAsRead(notifications[activeNotificationIndex]);
    setActiveNotificationIndex(activeNotificationIndex - 1);
  };

  const renderNotifications = () => {
    return notifications.map((item, i) => (
      <DashboardNotificationItem
        notification={item}
        key={item.id}
        index={i}
        onClickNotification={handleItemClick(i)}
      />
    ));
  };

  return (
    <div
      className={clsx(classes.root, className, {
        [classes.maxHeight]: matchesUpMd
      })}
    >
      <CommentsHeader
        shouldDisplayUnreadCount={true}
        unreadCount={unreadCount}
        title="Notifications"
        hasComments={notifications.length && !isLoading}
        onMarkAllRead={handleMarkAllAsRead}
      />
      <div className={classes.list}>
        <section className={clsx({ [classes.scrollable]: matchesUpMd })}>
          {isFetching && <Loader className={classes.loaderFetching} />}
          {isLoading ? (
            <Loader />
          ) : (
            <CollapsedList minToShow={3} simpleList={matchesUpMd}>
              {renderNotifications()}
            </CollapsedList>
          )}
        </section>
      </div>

      {showNotificationModal && (
        <NotificationModal
          onClose={handleCloseNotificationModal}
          notification={notifications[activeNotificationIndex]}
          onNextClick={handleNextClick}
          onPreviousClick={handlePreviousClick}
          disableNextButton={
            activeNotificationIndex === notifications.length - 1
          }
          disablePreviousButton={!activeNotificationIndex}
          onMarkNotificationsAsRead={markNotificationsAsRead}
        />
      )}
    </div>
  );
};

export default DashboardNotifications;
