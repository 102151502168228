import React, { useEffect, useState, useRef } from "react";
import { useHistory } from "react-router";
import clsx from "clsx";

import Button from "@material-ui/core/Button";
import AddCircleOutlineIcon from "@material-ui/icons/AddCircleOutline";
import WarningIcon from "@material-ui/icons/Warning";

import { useClickBlockerContext } from "components/BlockableClickContext";
import Textarea from "components/Textarea";
import UnsavedChangesDialog from "components/UnsavedChangesDialog";
import Loader from "components/Loader";
import { useRacehorse360Api } from "hooks/api";

import useStyles from "./styles";

interface IProps {
  horseId: string;
  className?: string;
  refetchHorseWarningMessage: () => void;
  refetchComments: () => void;
}

const HorseGeneralVetCommentEditor = (props: IProps) => {
  const { horseId, className, refetchHorseWarningMessage, refetchComments } =
    props;

  const classes = useStyles();
  const { setBlockClick } = useClickBlockerContext();
  const commentInputRef = useRef<HTMLInputElement>(null);
  const warningCommentInputRef = useRef<HTMLInputElement>(null);
  const history = useHistory();

  const [isEditMode, setIsEditMode] = useState<boolean>(false);
  const comment = useRef<string>(null);
  const warningComment = useRef<string>(null);
  const [hasUnsavedChanges, setHasUnsavedChanges] = useState<boolean>(false);
  const [linkCandidate, setLinkCandidate] = useState<string>(null);
  const [
    shouldDisplayWarningMessageBlock,
    setShouldDisplayWarningMessageBlock
  ] = useState<boolean>(false);
  const [isSaveable, setIsSaveable] = useState<boolean>(false);

  const dataFormKey = "general-vet-comment-editor-form";

  const { useCreateHorseGeneralVetComment } = useRacehorse360Api();

  const {
    mutateAsync: createHorseGeneralVetComment,
    isLoading: isLoadingCreateHorseGeneralVetComment
  } = useCreateHorseGeneralVetComment({
    onError: error => {
      console.error(error);
    }
  });

  const redirectToLinkCandidate = () => {
    if (linkCandidate === "backLink") {
      history.goBack();
    } else {
      history.push(linkCandidate);
    }
  };

  const unlockNavigation = () => {
    setBlockClick(false);
    setHasUnsavedChanges(false);
  };

  const handleAddComment = () => {
    setIsEditMode(true);
  };

  const handleChangeComment = value => {
    comment.current = value;
    setIsSaveable(Boolean(comment.current?.trim().length));
  };

  const handleBlurComment = event => {
    if (event.relatedTarget?.dataset?.form?.localeCompare(dataFormKey) === 0)
      return;

    if (!comment.current && !warningComment.current) {
      setIsEditMode(false);
      setShouldDisplayWarningMessageBlock(false);
      return;
    }

    const path = event.relatedTarget?.dataset?.path;

    setBlockClick(true);
    setHasUnsavedChanges(true);
    path && setLinkCandidate(path);
  };

  const handleCancelComment = () => {
    comment.current = null;
    warningComment.current = null;

    setIsEditMode(false);
    setShouldDisplayWarningMessageBlock(false);
    unlockNavigation();
  };

  const handleSaveComment = () => {
    if (comment.current?.trim().length) {
      createHorseGeneralVetComment({
        horseGeneralVetComment: {
          horseId: horseId,
          body: comment.current.trim(),
          warningMessage: warningComment.current?.trim()
        }
      })
        .then(() => {
          const warningMessage = warningComment.current?.trim();
          if (warningMessage?.length) {
            refetchHorseWarningMessage();
          }
        })
        .then(() => {
          comment.current = null;
          warningComment.current = null;

          setIsEditMode(false);
          setShouldDisplayWarningMessageBlock(false);

          unlockNavigation();
          linkCandidate && redirectToLinkCandidate();
          refetchComments();
        });
    }
  };

  const handleChangeWarningComment = value => {
    warningComment.current = value;
  };

  const handleCancelUnsavedChanges = () => {
    setHasUnsavedChanges(false);
  };

  const handleDiscardChanges = () => {
    comment.current = null;
    warningComment.current = null;

    setIsEditMode(false);
    setShouldDisplayWarningMessageBlock(false);
    unlockNavigation();
    linkCandidate && redirectToLinkCandidate();
  };

  const handleWarningButtonClick = () => {
    if (shouldDisplayWarningMessageBlock) {
      commentInputRef.current?.focus();
      warningComment.current = null;
    }
    setShouldDisplayWarningMessageBlock(!shouldDisplayWarningMessageBlock);
  };

  useEffect(() => {
    const input = warningCommentInputRef.current;
    if (isEditMode && shouldDisplayWarningMessageBlock && input) {
      input?.focus();
      input.selectionStart = input.value.length;
    }
  }, [shouldDisplayWarningMessageBlock]);

  useEffect(() => {
    const input = commentInputRef.current;
    if (isEditMode && !hasUnsavedChanges && input) {
      input?.focus();
      input.selectionStart = input.value.length;
    }
  }, [isEditMode, hasUnsavedChanges]);

  const renderUnsavedChangesPopupContent = () => {
    return (
      <>
        <span className={classes.warningText}>
          Your comment has not been saved.
        </span>
        <span className={classes.warningText}>
          Are you sure you want to leave and discard your comment?
        </span>
      </>
    );
  };

  const renderContent = () => {
    if (isLoadingCreateHorseGeneralVetComment)
      return <Loader className={classes.loader} />;

    if (isEditMode) {
      return (
        <div className={classes.editMode}>
          <Textarea
            className={classes.commentTextarea}
            inputRef={commentInputRef}
            maxLength={500}
            label="General Comment"
            rows={8}
            defaultValue={comment.current || ""}
            onChange={handleChangeComment}
            onBlur={handleBlurComment}
            data-form={dataFormKey}
          />
          {shouldDisplayWarningMessageBlock && (
            <Textarea
              className={classes.commentTextarea}
              inputRef={warningCommentInputRef}
              maxLength={60}
              label="Warning Comment"
              rows={1}
              defaultValue={warningComment.current || ""}
              onChange={handleChangeWarningComment}
              onBlur={handleBlurComment}
              data-form={dataFormKey}
            />
          )}
          <div className={classes.buttons}>
            <Button
              className={clsx(classes.button, classes.warningButton, {
                [classes.warningButtonActive]: shouldDisplayWarningMessageBlock
              })}
              variant="outlined"
              startIcon={<WarningIcon className={classes.warningButtonIcon} />}
              onClick={handleWarningButtonClick}
              data-form={dataFormKey}
            >
              <span className={classes.warningButtonText}>
                {shouldDisplayWarningMessageBlock
                  ? "REMOVE WARNING"
                  : "ADD WARNING"}
              </span>
            </Button>
            <div>
              <Button
                className={classes.button}
                onMouseDown={handleCancelComment}
                data-form={dataFormKey}
              >
                Cancel
              </Button>
              <Button
                className={clsx(classes.button, classes.saveButton)}
                disabled={!isSaveable}
                onMouseDown={handleSaveComment}
                data-form={dataFormKey}
              >
                Save
              </Button>
            </div>
          </div>
        </div>
      );
    } else {
      return (
        <div className={classes.emptyComment}>
          <Button
            className={classes.addButton}
            startIcon={<AddCircleOutlineIcon className={classes.addIcon} />}
            onClick={handleAddComment}
          >
            Add Comment
          </Button>
        </div>
      );
    }
  };

  return (
    <div className={clsx(classes.root, className)}>
      {renderContent()}

      {hasUnsavedChanges && (
        <UnsavedChangesDialog
          onSave={handleSaveComment}
          onCancel={handleCancelUnsavedChanges}
          onDiscard={handleDiscardChanges}
          open={hasUnsavedChanges}
          title={"Discard Comment?"}
          content={renderUnsavedChangesPopupContent()}
          isSaveDisabled
        />
      )}
    </div>
  );
};

export default HorseGeneralVetCommentEditor;
