import React, { useEffect, useRef, useState } from "react";
import clsx from "clsx";
import { TransformWrapper, TransformComponent } from "react-zoom-pan-pinch";

import Box from "@material-ui/core/Box";
import Dialog from "@material-ui/core/Dialog";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
import IconButton from "@material-ui/core/IconButton";
import Close from "@material-ui/icons/Close";
import Refresh from "@material-ui/icons/Refresh";
import ZoomIn from "@material-ui/icons/ZoomIn";
import ZoomOut from "@material-ui/icons/ZoomOut";

import { racehorse360 } from "@tsg/1st-grpc-web";
import GoldenGateFieldMap from "components/GoldenGateFieldMap";
import GulfstreamParkMap from "components/GulfstreamParkMap";
import MapIcon from "components/Icons/Map";
import SantaAnitaMap from "components/SantaAnitaMap";
import { useWindowSize } from "hooks/screen";
import { toTitleCase } from "utils/horse";
import { getScreenPositionByBarn, Size } from "utils/map";
import useStyles from "./styles";

interface Props {
  horse: racehorse360.IHorse;
}

const DEFAULT_SCALE = 3;

const MapDialog = (props: Props) => {
  const { horse } = props;
  const horseFacility = horse.barn?.facility || horse.currentFacility;
  const classes = useStyles({
    foregroundColor: horseFacility?.foregroundColor
  });
  const rootRef = useRef<HTMLDivElement>();
  const { width, height } = useWindowSize();
  const [containerSize, setContainerSize] = useState<Size>({
    width: 0,
    height: 0
  });
  const mapRootRef = useRef<SVGGElement>();
  const clipPathRef = useRef<SVGGElement>();
  const svgContainerRef = useRef<HTMLDivElement>();
  const [positionX, setPositionX] = useState<number>(0);
  const [positionY, setPositionY] = useState<number>(0);
  const [isOpen, setIsOpen] = useState<boolean>(false);

  const processSize = () => {
    if (rootRef.current) {
      const rect = rootRef.current?.getBoundingClientRect();
      setContainerSize({
        width: Math.ceil(rect.width),
        height: Math.ceil(rect.height)
      });
    }
  };

  const handleMapOpen = () => {
    setIsOpen(true);
  };

  const handleMapClose = () => {
    setIsOpen(false);
  };

  const handlePanningStop = options => {
    setPositionX(options.positionX);
    setPositionY(options.positionY);
  };

  useEffect(() => {
    if (mapRootRef.current && clipPathRef.current) {
      const position = getScreenPositionByBarn(
        `[data-name='${horse?.barn?.name}']`,
        mapRootRef,
        svgContainerRef,
        containerSize
      );
      if (position) {
        setPositionX(position.x);
        setPositionY(position.y);
      }
    }
  }, [
    mapRootRef.current,
    clipPathRef.current,
    containerSize.width,
    containerSize.height
  ]);

  useEffect(() => {
    if (rootRef.current) {
      const rect = rootRef.current?.getBoundingClientRect();
      setContainerSize({
        width: Math.ceil(rect.width),
        height: Math.ceil(rect.height)
      });
    }
  }, [width, height]);

  const renderMap = () => {
    switch (horseFacility?.code) {
      case "SA":
        return (
          <SantaAnitaMap
            horses={[horse]}
            mapRootRef={mapRootRef}
            svgContainerRef={svgContainerRef}
            clipPathRef={clipPathRef}
            containerSize={containerSize}
          />
        );
      case "GG":
        return (
          <GoldenGateFieldMap
            horses={[horse]}
            mapRootRef={mapRootRef}
            svgContainerRef={svgContainerRef}
            clipPathRef={clipPathRef}
            containerSize={containerSize}
          />
        );
      case "GP":
        return (
          <GulfstreamParkMap
            horses={[horse]}
            mapRootRef={mapRootRef}
            svgContainerRef={svgContainerRef}
            clipPathRef={clipPathRef}
            containerSize={containerSize}
          />
        );
      default:
        return <></>;
    }
  };

  return (
    <>
      <IconButton onClick={handleMapOpen} className={classes.mapButton}>
        <MapIcon className={classes.mapIcon} />
      </IconButton>
      <Dialog
        onClose={handleMapClose}
        open={isOpen}
        fullScreen
        hideBackdrop
        classes={{
          root: classes.dialogRoot,
          paper: classes.dialogPaper
        }}
        PaperProps={{
          elevation: 0
        }}
        TransitionProps={{
          mountOnEnter: true,
          unmountOnExit: true,
          onEnter: () => {
            processSize();
          },
          onEntering: () => {
            processSize();
          },
          onEntered: () => {
            processSize();
          }
        }}
      >
        <DialogTitle id="barn-dialog-title" className={classes.dialogTitle}>
          <Box className={classes.dialogTitleLabel}>Location</Box>
          <Box className={classes.facilityName}>
            {toTitleCase(horseFacility?.name)}
          </Box>
          <IconButton
            color={"primary"}
            size={"small"}
            onClick={handleMapClose}
            className={classes.dialogTitleCloseButton}
          >
            <Close />
          </IconButton>
        </DialogTitle>
        <DialogContent dividers className={classes.dialogContent}>
          <Box className={clsx(classes.mapRoot, classes.fullscreen)}>
            <Box className={classes.content}>
              <div
                ref={rootRef}
                className={clsx(classes.wrapper, classes.relative)}
              >
                <div className={classes.innerWrapper}>
                  <TransformWrapper
                    onPanningStop={handlePanningStop}
                    defaultScale={DEFAULT_SCALE}
                    positionX={positionX}
                    positionY={positionY}
                    pan={{
                      velocity: false
                    }}
                    scalePadding={{
                      disabled: true
                    }}
                    options={{
                      limitToBounds: true,
                      limitToWrapper: true,
                      centerContent: false
                    }}
                  >
                    {({ zoomIn, zoomOut, resetTransform }) => (
                      <React.Fragment>
                        <Box>
                          <IconButton
                            className={clsx(classes.tool, classes.refreshTool)}
                            onClick={resetTransform}
                          >
                            <Refresh />
                          </IconButton>
                          <IconButton
                            className={clsx(classes.tool, classes.zoomInTool)}
                            onClick={zoomIn}
                          >
                            <ZoomIn />
                          </IconButton>
                          <IconButton
                            className={clsx(classes.tool, classes.zoomOutTool)}
                            onClick={zoomOut}
                          >
                            <ZoomOut />
                          </IconButton>
                        </Box>
                        <TransformComponent>{renderMap()}</TransformComponent>
                      </React.Fragment>
                    )}
                  </TransformWrapper>
                </div>
              </div>
            </Box>
          </Box>
        </DialogContent>
      </Dialog>
    </>
  );
};

export default MapDialog;
