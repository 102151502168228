import { EModalConfirmationTypes } from "interfaces/VetExamState";
import {
  ADD_EXAM_DETAIL,
  ADD_EXAM_JOG,
  buttonGroupColors,
  CHANGE_EXAM_DETAIL_FIELD,
  CHANGE_EXAM_FIELD,
  CHANGE_EXAM_JOG_FIELD,
  createEmptyExamDetail,
  createEmptyExamDetailObservation,
  createEmptyExamJog,
  DELETE_EXAM_DETAIL,
  DELETE_EXAM_JOG,
  CLEAR_EXAM_DETAIL,
  CLEAR_EXAM_JOG,
  ECustomFieldsValues,
  EVetExamFields,
  FRONT_ID_PREFIX,
  TAction,
  IExamDetail,
  IExamJog,
  IExamSelectOption,
  IExamDetailObservation,
  IVetExamLocalState,
  RESET_EXAM,
  SET_ENTIRE_EXAM,
  vetExamInitialState,
  ADD_EXAM_DETAIL_OBSERVATION,
  DELETE_EXAM_DETAIL_OBSERVATION,
  CLEAR_EXAM_DETAIL_OBSERVATION,
  CHANGE_EXAM_DETAIL_OBSERVATION_FIELD
} from "./options";
import { racehorse360 } from "@tsg/1st-grpc-web";
import {
  IButtonColors,
  IButtonGroup
} from "./ExamButtonsGroup/ExamButtonsGroup";

export const CUSTOM_VALUE = "custom_value";

export type reducerState =
  | racehorse360.IWorkoutExamDetail
  | racehorse360.IWorkoutExamJog;

export interface IModalsTypes {
  isSubmitModal: boolean;
  isPauseOrCloseModal: boolean;
  isResetModal: boolean;
}

export interface IUsingFields {
  isReasonField: boolean;
  isJogConditionField: boolean;
  isJogScoreField: boolean;
  isJogInfoField: boolean;
  isJogLimbField: boolean;
  isResultField: boolean;
  isRiskLevelField: boolean;
  isNoteField: boolean;
  isCommentField: boolean;
  isDetailLimbField: boolean;
  isDetailLocationField: boolean;
  isDetailStructureField: boolean;
  isDetailObservationField: boolean;
  isDetailConditionField: boolean;
  isDetailSeverityField: boolean;
  isDetailNoteField: boolean;
}

type TExamFormEntriesType = IExamJog | IExamDetail | IExamDetailObservation;

export enum EExamResult {
  PASSED = "Passed",
  FAILED = "Failed",
  NO_RESULT = "-"
}

export const checkTypeModal = (type: EModalConfirmationTypes): IModalsTypes => {
  return {
    isSubmitModal: type === EModalConfirmationTypes.SUBMIT,
    isPauseOrCloseModal: type === EModalConfirmationTypes.PAUSE_OR_CLOSE,
    isResetModal: type === EModalConfirmationTypes.RESET
  };
};

export const getExamResult = (
  result: racehorse360.WorkoutExamResult
): EExamResult => {
  switch (result) {
    case racehorse360.WorkoutExamResult.WORKOUT_EXAM_RESULT_PASSED:
      return EExamResult.PASSED;
    case racehorse360.WorkoutExamResult.WORKOUT_EXAM_RESULT_FAILED:
      return EExamResult.FAILED;
    default:
      return EExamResult.NO_RESULT;
  }
};

export const getSelectField = (
  exam: racehorse360.IWorkoutExam,
  field: EVetExamFields
): string | null => {
  return exam && exam[field] ? String(exam[field]) : null;
};

/*!Important! To array "customButtonsData" length should match to length of
"buttonsDataFromDB" array, otherwise will be applied default color (#489B77)
for active button */
export const prepareButtonsData = (
  buttonsDataFromDB: Partial<IButtonGroup>[],
  customButtonsData: IButtonColors[],
  sortBy?: string
): IButtonGroup[] => {
  return buttonsDataFromDB
    .sort((item1, item2) => item1[sortBy] - item2[sortBy])
    .map((button: Partial<IButtonGroup>, index: number) => {
      let activeColor, titleColor;

      if (buttonsDataFromDB.length === customButtonsData.length) {
        activeColor = customButtonsData[index].activeColor;
        titleColor = customButtonsData[index].titleColor;
      } else {
        activeColor = "#489B77";
        console.warn(
          "CustomButtonsData and ButtonsDataFromDB arrays have different length, so active buttons will be default color '#489B77'"
        );
      }

      return {
        id: button.id,
        value: button.value,
        description: button.description,
        activeColor,
        titleColor
      };
    });
};

export const checkUsingFields = (fieldName: EVetExamFields): IUsingFields => {
  return {
    isJogConditionField: fieldName === EVetExamFields.JOG_CONDITION,
    isJogScoreField: fieldName === EVetExamFields.JOG_SCORE,
    isJogInfoField: fieldName === EVetExamFields.JOG_INFO,
    isJogLimbField: fieldName === EVetExamFields.JOG_LIMB,
    isReasonField: fieldName === EVetExamFields.REASON,
    isResultField: fieldName === EVetExamFields.RESULT,
    isRiskLevelField: fieldName === EVetExamFields.RISK_LEVEL,
    isNoteField: fieldName === EVetExamFields.INTERNAL_NOTE,
    isCommentField: fieldName === EVetExamFields.COMMENT,
    isDetailLimbField: fieldName === EVetExamFields.DETAIL_LIMB,
    isDetailLocationField: fieldName === EVetExamFields.DETAIL_LOCATION,
    isDetailStructureField: fieldName === EVetExamFields.DETAIL_STRUCTURE,
    isDetailObservationField: fieldName === EVetExamFields.DETAIL_OBSERVATION,
    isDetailConditionField: fieldName === EVetExamFields.DETAIL_CONDITION,
    isDetailSeverityField: fieldName === EVetExamFields.DETAIL_SEVERITY,
    isDetailNoteField: fieldName === EVetExamFields.DETAIL_NOTE
  };
};

type GetActionReturnType = {
  isJogField: boolean;
  isDetailField: boolean;
  isObservationField: boolean;
};
export const getActionType = (
  fieldName: EVetExamFields
): GetActionReturnType => {
  const {
    isJogScoreField,
    isJogLimbField,
    isJogInfoField,
    isJogConditionField,
    isDetailSeverityField,
    isDetailConditionField,
    isDetailObservationField,
    isDetailLocationField,
    isDetailLimbField,
    isDetailStructureField,
    isDetailNoteField
  } = checkUsingFields(fieldName);

  const isJogField = [
    isJogScoreField,
    isJogLimbField,
    isJogConditionField,
    isJogInfoField
  ].some(Boolean);

  const isDetailField = [
    isDetailLocationField,
    isDetailLimbField,
    isDetailStructureField
  ].some(Boolean);

  const isObservationField = [
    isDetailSeverityField,
    isDetailConditionField,
    isDetailObservationField,
    isDetailNoteField
  ].some(Boolean);

  return {
    isJogField,
    isDetailField,
    isObservationField
  };
};

export const getCustomFieldName = (field: EVetExamFields): string => {
  switch (field) {
    case EVetExamFields.JOG_LIMB:
      return "customJogLimb";
    case EVetExamFields.JOG_INFO:
      return "customJogInfo";
    case EVetExamFields.JOG_CONDITION:
      return "customJogCondition";
    case EVetExamFields.DETAIL_LIMB:
      return "customDetailLimb";
    case EVetExamFields.DETAIL_LOCATION:
      return "customDetailLocation";
    case EVetExamFields.DETAIL_STRUCTURE:
      return "customDetailStructure";
    case EVetExamFields.DETAIL_OBSERVATION:
      return "customDetailObservation";
    case EVetExamFields.DETAIL_CONDITION:
      return "customDetailCondition";
    default:
      return "";
  }
};

export const createCustomOption = (name: string): IExamSelectOption => {
  return { name, value: CUSTOM_VALUE };
};

export const checkSelectingFieldsOneEntry = (
  entity: TExamFormEntriesType
): boolean => {
  const observationsField = "workoutExamDetailObservationEntries";

  return Object.keys(entity)
    .filter(field => {
      if (observationsField in entity) {
        return field !== "id" && field !== observationsField;
      }

      return field !== "id";
    })
    .some((field: EVetExamFields) => Boolean(entity[field]));
};

export const mapButtons = (list = [], key: EVetExamFields): IButtonGroup[] => {
  const { isJogScoreField, isDetailSeverityField } = checkUsingFields(key);

  if (isDetailSeverityField) {
    return list
      .sort((a, b) => Number(a.shortDescription) - Number(b.shortDescription))
      .map((item, index) => ({
        ...item,
        value: item.shortDescription,
        description: item.description,
        ...buttonGroupColors[index]
      }));
  }

  if (isJogScoreField) {
    return list
      .sort((a, b) => Number(a.value) - Number(b.value))
      .map((item, index) => ({
        ...item,
        value: item.value,
        description: item.description,
        ...buttonGroupColors[index]
      }));
  }
};

export const mapOptions = (list = []): any[] => {
  return list
    ?.map(item => ({
      name: item.description,
      value: item.id
    }))
    .sort((i1, i2) => i1.name.localeCompare(i2.name));
};

export const mapIWorkoutExamJogToLocalStateJog = (
  jog: racehorse360.IWorkoutExamJog
): IExamJog => {
  const customJogLimb = jog.customJogLimb?.value;
  const customJogInfo = jog.customJogInfo?.value;
  const customJogCondition = jog.customJogCondition?.value;

  return {
    id: jog.id,
    [EVetExamFields.JOG_SCORE]: jog.jogScoreId,
    [EVetExamFields.JOG_LIMB]: customJogLimb ? CUSTOM_VALUE : jog.jogLimbId,
    [ECustomFieldsValues.CUSTOM_JOG_LIMB]: customJogLimb ? customJogLimb : null,
    [EVetExamFields.JOG_INFO]: customJogInfo ? CUSTOM_VALUE : jog.jogInfoId,
    [ECustomFieldsValues.CUSTOM_JOG_INFO]: customJogInfo ? customJogInfo : null,
    [EVetExamFields.JOG_CONDITION]: customJogCondition
      ? CUSTOM_VALUE
      : jog.jogConditionId,
    [ECustomFieldsValues.CUSTOM_JOG_CONDITION]: customJogCondition
      ? customJogCondition
      : null
  };
};

export const mapWorkoutExamDetailObservationToLocalStateObservation = (
  observation: racehorse360.IWorkoutExamDetailObservationEntry
): IExamDetailObservation => {
  const customDetailCondition = observation.customDetailCondition?.value;
  const customDetailObservation = observation.customDetailObservation?.value;

  return {
    id: observation.id,
    [EVetExamFields.DETAIL_NOTE]: observation.notes || "",
    [EVetExamFields.DETAIL_SEVERITY]: observation.detailSeverityId,
    [EVetExamFields.DETAIL_OBSERVATION]: customDetailObservation
      ? CUSTOM_VALUE
      : observation.detailObservationId,
    [ECustomFieldsValues.CUSTOM_DETAIL_OBSERVATION]: customDetailObservation
      ? customDetailObservation
      : null,
    [EVetExamFields.DETAIL_CONDITION]: customDetailCondition
      ? CUSTOM_VALUE
      : observation.detailConditionId,
    [ECustomFieldsValues.CUSTOM_DETAIL_CONDITION]: customDetailCondition
      ? customDetailCondition
      : null
  };
};

export const mapIWorkoutDetailToLocalStateDetail = (
  detail: racehorse360.IWorkoutExamDetail
): IExamDetail => {
  const customDetailLimb = detail.customDetailLimb?.value;
  const customDetailStructure = detail.customDetailStructure?.value;
  const customDetailLocation = detail.customDetailLocation?.value;

  return {
    id: detail.id,
    [EVetExamFields.DETAIL_LIMB]: customDetailLimb
      ? CUSTOM_VALUE
      : detail.detailLimbId,
    [ECustomFieldsValues.CUSTOM_DETAIL_LIMB]: customDetailLimb
      ? customDetailLimb
      : null,
    [EVetExamFields.DETAIL_STRUCTURE]: customDetailStructure
      ? CUSTOM_VALUE
      : detail.detailStructureId,
    [ECustomFieldsValues.CUSTOM_DETAIL_STRUCTURE]: customDetailStructure
      ? customDetailStructure
      : null,
    [EVetExamFields.DETAIL_LOCATION]: customDetailLocation
      ? CUSTOM_VALUE
      : detail.detailLocationId,
    [ECustomFieldsValues.CUSTOM_DETAIL_LOCATION]: customDetailLocation
      ? customDetailLocation
      : null,
    workoutExamDetailObservationEntries:
      detail.workoutExamDetailObservationEntries?.map(
        mapWorkoutExamDetailObservationToLocalStateObservation
      )
  };
};

export const mapStateExamJogToRequestExamJog = (
  jog: IExamJog,
  workoutExamId: string
): racehorse360.IWorkoutExamJog => {
  const result: racehorse360.IWorkoutExamJog = {
    workoutExamId,
    [EVetExamFields.JOG_SCORE]: jog[EVetExamFields.JOG_SCORE],
    [EVetExamFields.JOG_LIMB]: jog[ECustomFieldsValues.CUSTOM_JOG_LIMB]
      ? null
      : jog[EVetExamFields.JOG_LIMB],
    [ECustomFieldsValues.CUSTOM_JOG_LIMB]: jog[
      ECustomFieldsValues.CUSTOM_JOG_LIMB
    ]
      ? { value: jog[ECustomFieldsValues.CUSTOM_JOG_LIMB] }
      : null,
    [EVetExamFields.JOG_INFO]: jog[ECustomFieldsValues.CUSTOM_JOG_INFO]
      ? null
      : jog[EVetExamFields.JOG_INFO],
    [ECustomFieldsValues.CUSTOM_JOG_INFO]: jog[
      ECustomFieldsValues.CUSTOM_JOG_INFO
    ]
      ? { value: jog[ECustomFieldsValues.CUSTOM_JOG_INFO] }
      : null,
    [EVetExamFields.JOG_CONDITION]: jog[
      ECustomFieldsValues.CUSTOM_JOG_CONDITION
    ]
      ? null
      : jog[EVetExamFields.JOG_CONDITION],
    [ECustomFieldsValues.CUSTOM_JOG_CONDITION]: jog[
      ECustomFieldsValues.CUSTOM_JOG_CONDITION
    ]
      ? { value: jog[ECustomFieldsValues.CUSTOM_JOG_CONDITION] }
      : null
  };

  if (jog.id.indexOf(FRONT_ID_PREFIX) === -1) {
    result.id = jog.id;
  }

  return result;
};

export const mapStateExamDetailObservationToRequestExamDetailObservation = (
  observation: IExamDetailObservation,
  examDetailId: string
): racehorse360.IWorkoutExamDetailObservationEntry => {
  const result: racehorse360.IWorkoutExamDetailObservationEntry = {
    workoutExamDetailId: examDetailId,
    [EVetExamFields.DETAIL_OBSERVATION]: observation[
      ECustomFieldsValues.CUSTOM_DETAIL_OBSERVATION
    ]
      ? null
      : observation[EVetExamFields.DETAIL_OBSERVATION],
    [ECustomFieldsValues.CUSTOM_DETAIL_OBSERVATION]: observation[
      ECustomFieldsValues.CUSTOM_DETAIL_OBSERVATION
    ]
      ? { value: observation[ECustomFieldsValues.CUSTOM_DETAIL_OBSERVATION] }
      : null,
    [EVetExamFields.DETAIL_CONDITION]: observation[
      ECustomFieldsValues.CUSTOM_DETAIL_CONDITION
    ]
      ? null
      : observation[EVetExamFields.DETAIL_CONDITION],
    [ECustomFieldsValues.CUSTOM_DETAIL_CONDITION]: observation[
      ECustomFieldsValues.CUSTOM_DETAIL_CONDITION
    ]
      ? { value: observation[ECustomFieldsValues.CUSTOM_DETAIL_CONDITION] }
      : null,
    [EVetExamFields.DETAIL_SEVERITY]:
      observation[EVetExamFields.DETAIL_SEVERITY],
    [EVetExamFields.DETAIL_NOTE]: observation[EVetExamFields.DETAIL_NOTE]
  };

  if (observation.id.indexOf(FRONT_ID_PREFIX) === -1) {
    result.id = observation.id;
  }

  return result;
};

export const mapStateExamDetailToRequestExamDetail = (
  detail: IExamDetail,
  workoutExamId: string
): racehorse360.IWorkoutExamDetail => {
  let detailId = null;

  if (detail.id.indexOf(FRONT_ID_PREFIX) === -1) {
    detailId = detail.id;
  }

  return {
    id: detailId,
    workoutExamId,
    [EVetExamFields.DETAIL_LIMB]: detail[ECustomFieldsValues.CUSTOM_DETAIL_LIMB]
      ? null
      : detail[EVetExamFields.DETAIL_LIMB],
    [ECustomFieldsValues.CUSTOM_DETAIL_LIMB]: detail[
      ECustomFieldsValues.CUSTOM_DETAIL_LIMB
    ]
      ? { value: detail[ECustomFieldsValues.CUSTOM_DETAIL_LIMB] }
      : null,
    [EVetExamFields.DETAIL_STRUCTURE]: detail[
      ECustomFieldsValues.CUSTOM_DETAIL_STRUCTURE
    ]
      ? null
      : detail[EVetExamFields.DETAIL_STRUCTURE],
    [ECustomFieldsValues.CUSTOM_DETAIL_STRUCTURE]: detail[
      ECustomFieldsValues.CUSTOM_DETAIL_STRUCTURE
    ]
      ? { value: detail[ECustomFieldsValues.CUSTOM_DETAIL_STRUCTURE] }
      : null,
    [EVetExamFields.DETAIL_LOCATION]: detail[
      ECustomFieldsValues.CUSTOM_DETAIL_LOCATION
    ]
      ? null
      : detail[EVetExamFields.DETAIL_LOCATION],
    [ECustomFieldsValues.CUSTOM_DETAIL_LOCATION]: detail[
      ECustomFieldsValues.CUSTOM_DETAIL_LOCATION
    ]
      ? { value: detail[ECustomFieldsValues.CUSTOM_DETAIL_LOCATION] }
      : null,
    workoutExamDetailObservationEntries:
      detail.workoutExamDetailObservationEntries.map(item =>
        mapStateExamDetailObservationToRequestExamDetailObservation(
          item,
          detailId
        )
      )
  };
};

export const getVetExamRequestState = (
  currentExam: Partial<racehorse360.WorkoutExam>,
  state: IVetExamLocalState
): unknown => {
  return {
    ...currentExam,
    workoutRequest: currentExam?.workoutRequest
      ? currentExam.workoutRequest
      : null,
    result: Number(state[EVetExamFields.RESULT]),
    reason: Number(state[EVetExamFields.REASON]),
    riskLevel: Number(state[EVetExamFields.RISK_LEVEL]),
    note: state[EVetExamFields.INTERNAL_NOTE],
    commentToTrainer: { body: state[EVetExamFields.COMMENT] },
    workoutExamJogs: state.examJogs.map(jog =>
      mapStateExamJogToRequestExamJog(jog, currentExam.id)
    ),
    workoutExamDetails: state.examDetails.map(detail =>
      mapStateExamDetailToRequestExamDetail(detail, currentExam.id)
    )
  };
};

export const getLocalStateExam = (
  exam: racehorse360.IWorkoutExam
): IVetExamLocalState => ({
  id: exam.id,
  [EVetExamFields.REASON]: getSelectField(exam, EVetExamFields.REASON) || "",
  [EVetExamFields.RESULT]: getSelectField(exam, EVetExamFields.RESULT),
  [EVetExamFields.RISK_LEVEL]: getSelectField(exam, EVetExamFields.RISK_LEVEL),
  [EVetExamFields.INTERNAL_NOTE]: exam.note || "",
  [EVetExamFields.COMMENT]: exam.commentToTrainer?.body || "",
  examJogs: exam.workoutExamJogs.map(mapIWorkoutExamJogToLocalStateJog),
  examDetails: exam.workoutExamDetails.map(mapIWorkoutDetailToLocalStateDetail)
});

export const reducer = (
  state: IVetExamLocalState = vetExamInitialState,
  action: TAction
): IVetExamLocalState => {
  switch (action.type) {
    case CHANGE_EXAM_FIELD: {
      return {
        ...state,
        [action.fieldName]: action.value
      };
    }
    case RESET_EXAM: {
      return {
        ...state,
        ...vetExamInitialState
      };
    }
    case SET_ENTIRE_EXAM: {
      return {
        ...state,
        ...action.newExam
      };
    }
    case ADD_EXAM_JOG: {
      return {
        ...state,
        examJogs: [...state.examJogs, createEmptyExamJog()]
      };
    }
    case DELETE_EXAM_JOG: {
      return {
        ...state,
        examJogs: state.examJogs.filter(jog => jog.id !== action.jogId)
      };
    }
    case CLEAR_EXAM_JOG: {
      return {
        ...state,
        examJogs: [createEmptyExamJog()]
      };
    }
    case CHANGE_EXAM_JOG_FIELD: {
      return {
        ...state,
        examJogs: state.examJogs.map((jog: IExamJog) => {
          const updatedJog = { ...jog, [action.fieldName]: action.value };

          if (action.customValue) {
            updatedJog[action.customFieldName] = action.customValue;
          }

          return jog.id === action.jogId ? updatedJog : jog;
        })
      };
    }
    case ADD_EXAM_DETAIL: {
      return {
        ...state,
        examDetails: [...state.examDetails, createEmptyExamDetail()]
      };
    }
    case DELETE_EXAM_DETAIL: {
      return {
        ...state,
        examDetails: state.examDetails.filter(
          detail => detail.id !== action.detailId
        )
      };
    }
    case CLEAR_EXAM_DETAIL: {
      return {
        ...state,
        examDetails: [createEmptyExamDetail()]
      };
    }
    case CHANGE_EXAM_DETAIL_FIELD: {
      return {
        ...state,
        examDetails: state.examDetails.map((detail: IExamDetail) => {
          const updatedJog = { ...detail, [action.fieldName]: action.value };

          if (action.customValue) {
            updatedJog[action.customFieldName] = action.customValue;
          }

          return detail.id === action.detailId ? updatedJog : detail;
        })
      };
    }
    case ADD_EXAM_DETAIL_OBSERVATION: {
      return {
        ...state,
        examDetails: state.examDetails.map(detail => {
          if (detail.id === action.detailId) {
            return {
              ...detail,
              workoutExamDetailObservationEntries: [
                ...detail.workoutExamDetailObservationEntries,
                createEmptyExamDetailObservation()
              ]
            };
          }

          return detail;
        })
      };
    }
    case DELETE_EXAM_DETAIL_OBSERVATION: {
      return {
        ...state,
        examDetails: state.examDetails.map(detail => {
          if (detail.id === action.detailId) {
            return {
              ...detail,
              workoutExamDetailObservationEntries:
                detail.workoutExamDetailObservationEntries.filter(
                  observation => observation.id !== action.observationId
                )
            };
          }

          return detail;
        })
      };
    }
    case CLEAR_EXAM_DETAIL_OBSERVATION: {
      return {
        ...state,
        examDetails: state.examDetails.map(detail => {
          if (detail.id === action.detailId) {
            return {
              ...detail,
              workoutExamDetailObservationEntries: [
                createEmptyExamDetailObservation()
              ]
            };
          }

          return detail;
        })
      };
    }
    case CHANGE_EXAM_DETAIL_OBSERVATION_FIELD: {
      return {
        ...state,
        examDetails: state.examDetails.map(detail => {
          if (detail.id === action.detailId) {
            return {
              ...detail,
              workoutExamDetailObservationEntries:
                detail.workoutExamDetailObservationEntries.map(item => {
                  const updatedObservation = {
                    ...item,
                    [action.fieldName]: action.value
                  };

                  if (action.customValue) {
                    updatedObservation[action.customFieldName] =
                      action.customValue;
                  }

                  return item.id === action.observationId
                    ? updatedObservation
                    : item;
                })
            };
          }

          return detail;
        })
      };
    }
    default:
      return state;
  }
};

export const checkSelectingExamFormFields = (
  examState: IVetExamLocalState
): boolean => {
  const hasSelectedJogsFields = examState.examJogs.some(
    checkSelectingFieldsOneEntry
  );
  const hasSelectedDetailsFields = examState.examDetails.some(
    checkSelectingFieldsOneEntry
  );
  const hasSelectedObservationsFields = examState.examDetails.some(detail => {
    return detail.workoutExamDetailObservationEntries.some(
      checkSelectingFieldsOneEntry
    );
  });
  const hasSelectedExamFields = Object.keys(examState)
    .filter(
      field => field !== "id" && field !== "examJogs" && field !== "examDetails"
    )
    .some(field => Boolean(examState[field]));

  return [
    hasSelectedJogsFields,
    hasSelectedDetailsFields,
    hasSelectedObservationsFields,
    hasSelectedExamFields
  ].some(Boolean);
};
