import React, { useCallback } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useQueryClient } from "react-query";
import Paper from "@material-ui/core/Paper";
import addDays from "date-fns/addDays";
import formatISO from "date-fns/formatISO";
import isFirstDayOfMonth from "date-fns/isFirstDayOfMonth";
import isLastDayOfMonth from "date-fns/isLastDayOfMonth";
import isSameDay from "date-fns/isSameDay";
import parseISO from "date-fns/parseISO";
import first from "lodash/first";
import last from "lodash/last";

import { racehorse360 } from "@tsg/1st-grpc-web";
import ErrorBoundary from "components/ErrorBoundary";
import Loader from "components/Loader";
import ScheduleDate from "components/ScheduleDate";
import { useRacehorse360Api } from "hooks/api";
import ScheduleCardType from "interfaces/ScheduleCardType";
import ScheduleDayType from "interfaces/ScheduleDayType";
import WorkoutsPageState from "interfaces/WorkoutsPageState";
import { SortOrder } from "interfaces/SortOrder";
import { setSelectedFacility as setWorkoutsPageStoreSelectedFacility } from "store/actions/workoutsPage";
import { currentTimeZoneString, getNextDates } from "utils/date-utils";
import {
  getCalendarDay,
  getFacilityBlackoutsCalendar
} from "utils/facilityBlackouts";
import ScheduleHeader from "./ScheduleHeader";
import useStyles from "./styles";

export interface Props {
  horse: racehorse360.IHorse;
  onChange?: (horse: racehorse360.IHorse) => void;
  onShowMessage?: () => void;
}

const HorseWorkoutSchedule = React.memo((props: Props) => {
  const { horse, onShowMessage } = props;
  const classes = useStyles();
  const queryClient = useQueryClient();
  const listWorkoutRequestsQueryKey = `${horse.id}-list-workout-requests`;

  const dispatch = useDispatch();

  const facility = useSelector(
    (state: { workoutsPage: WorkoutsPageState }) =>
      state?.workoutsPage.selectedFacility
  );

  const { useListWorkoutRequests, usePullFacilityBlackouts } =
    useRacehorse360Api();

  const {
    isLoading: isListWorkoutRequestsLoading,
    data: listWorkoutRequestsData
  } = useListWorkoutRequests(
    listWorkoutRequestsQueryKey,
    {
      query: {
        horseIds: [horse?.id],
        date: {
          range: {
            relative: {
              // starting two days ago
              startDate: { value: -2 },
              timezone: currentTimeZoneString()
            }
          }
        },
        statuses: [
          racehorse360.WorkoutRequestStatus.WORKOUT_REQUEST_STATUS_REQUESTED,
          racehorse360.WorkoutRequestStatus.WORKOUT_REQUEST_STATUS_EXAM_PENDING,
          racehorse360.WorkoutRequestStatus.WORKOUT_REQUEST_STATUS_APPROVED
        ]
      },
      pagingOptions: {
        maxResults: 30
      },
      getOptions: {
        select: [
          "id",
          "facility.name",
          "facility.code",
          "facility.background_color",
          "facility.foreground_color",
          "facility.stroke_color",
          "facility.shut_off_period_in_hours",
          "facility.shut_off_hours",
          "facility.timezone",
          "date",
          "status",
          "hasComment"
        ],
        orderBy: [`date ${SortOrder.ASC}`]
      }
    },
    {
      enabled: Boolean(horse?.id)
    }
  );

  const { selectedFacility } = useSelector(
    (state: { workoutsPage: WorkoutsPageState }) => state?.workoutsPage
  );
  const listBlackoutDatesKey = `blackout-${selectedFacility?.id}`;
  const { isLoading: isFacilityBlackoutsLoading, data: facilityBlackoutsData } =
    usePullFacilityBlackouts(listBlackoutDatesKey, {
      facilityId: selectedFacility?.id,
      startDate: formatISO(new Date(), {
        representation: "date"
      }),
      endDate: formatISO(addDays(new Date(), 35), {
        representation: "date"
      })
    });

  const isLoading = isListWorkoutRequestsLoading || isFacilityBlackoutsLoading;
  let schedule = getNextDates(new Date(), 35).map(getCalendarDay);
  const firstDay = first(schedule);
  const lastDay = last(schedule);
  if (!isLoading) {
    schedule = getFacilityBlackoutsCalendar(
      facilityBlackoutsData.facilityBlackouts,
      schedule
    ).map(futureDay => {
      const scheduledDay = listWorkoutRequestsData.workoutRequests.find(
        scheduleDay => isSameDay(parseISO(scheduleDay.date), futureDay.date)
      );

      return { ...scheduledDay, ...futureDay };
    });
  }

  const handleChangeFacility = useCallback(
    (facility: racehorse360.IFacility) => {
      dispatch(setWorkoutsPageStoreSelectedFacility(facility));
    },
    [dispatch]
  );

  const handleWorkoutRequestUpdateSuccess = async () => {
    onShowMessage();
    await queryClient.invalidateQueries(listWorkoutRequestsQueryKey);
  };

  return (
    <Paper className={classes.root}>
      <ErrorBoundary>
        <ScheduleHeader
          horse={horse}
          start={firstDay.date}
          end={lastDay.date}
          selectedFacility={facility}
          onChangeFacility={handleChangeFacility}
        />
      </ErrorBoundary>
      {isLoading ? (
        <Loader />
      ) : (
        <ErrorBoundary>
          <div className={classes.datesList}>
            {schedule.map((day, dayIndex) => {
              let dayType: ScheduleDayType;

              const date = day.date;

              if (day.isToday) {
                dayType = ScheduleDayType.Today;
              } else if (isFirstDayOfMonth(date)) {
                dayType = ScheduleDayType.FirstDay;
              } else if (isLastDayOfMonth(date)) {
                dayType = ScheduleDayType.LastDay;
              }

              return (
                <ScheduleDate
                  key={dayIndex}
                  className={classes.date}
                  cardType={ScheduleCardType.Detailed}
                  dayType={dayType}
                  workoutRequestData={{
                    ...day,
                    date: formatISO(date, { representation: "date" })
                  }}
                  isDisabled={Boolean(day.facilityBlackout)}
                  selectedFacility={facility}
                  horse={horse}
                  onUpdate={handleWorkoutRequestUpdateSuccess}
                />
              );
            })}
          </div>
        </ErrorBoundary>
      )}
    </Paper>
  );
});

export default HorseWorkoutSchedule;
