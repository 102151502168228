import React, { useCallback, useState } from "react";
import { racehorse360 } from "@tsg/1st-grpc-web";
import { useQueryClient } from "react-query";
import { useFormik } from "formik";
import clsx from "clsx";

import FormControl from "@material-ui/core/FormControl";
import InputLabel from "@material-ui/core/InputLabel";
import MenuItem from "@material-ui/core/MenuItem";
import Button from "@material-ui/core/Button";
import Select from "@material-ui/core/Select";
import TextField from "@material-ui/core/TextField";
import FormHelperText from "@material-ui/core/FormHelperText";
import Typography from "@material-ui/core/Typography";

import NumberInput from "components/NumberInput";
import TextInput from "components/TextInput";
import LogoIcon from "components/Icons/SapLogo";
import AvatarIcon from "components/Icons/Avatar";
import PhoneIcon from "components/Icons/Phone";
import EmailIcon from "components/Icons/Email";
import NumberIcon from "components/Icons/Number";
import UserRoleIcon from "components/Icons/UserRole";
import SubmissionDialog from "./SubmissionDialog";
import { useLoggedInUser } from "components/LoggedInUserProvider";
import FacilityIcon from "components/Icons/Facility";
import { useRacehorse360Api } from "hooks/api";
import Loader from "components/Loader";
import {
  validationSchemaAccount,
  requireTrainerName,
  requireFacility
} from "utils/validationSchemas";
import {
  MAX_LAST_NAME_LENGTH,
  MAX_FIRST_NAME_LENGTH,
  phoneNumberMask,
  MAX_LICENSE_NUMBER_LENGTH
} from "common/constants";
import * as pageQueries from "./queries";
import { UserProfileFormValues } from "./interfaces";
import useStyles from "./styles";

const STARTING_VALUES: UserProfileFormValues = Object.freeze({
  email: "",
  facility: null,
  firstName: "",
  lastName: "",
  licenseNumber: "",
  phoneNumber: "",
  role: null,
  trainerName: ""
});

const displayFields = (
  classes: Record<string, any>,
  formik,
  submitted: boolean,
  getFieldIconClass: (fieldName: string) => string
) => {
  return (
    <span>
      <div className={classes.formGroup}>
        <FormControl
          className={classes.formControl}
          error={!!formik.errors.firstName}
        >
          <TextField
            label="First Name"
            disabled={submitted}
            // className={getFieldClass("firstName")}
            id="firstName"
            name="firstName"
            autoComplete="off"
            fullWidth={true}
            InputLabelProps={{
              className: classes.formLabel
            }}
            InputProps={{
              endAdornment: (
                <AvatarIcon className={getFieldIconClass("firstName")} />
              )
            }}
            FormHelperTextProps={{
              className: classes.formControlHelper
            }}
            inputProps={{
              maxLength: MAX_FIRST_NAME_LENGTH
            }}
            onBlur={formik.handleBlur}
            value={formik.values.firstName}
            onChange={formik.handleChange}
            error={formik.touched.firstName && Boolean(formik.errors.firstName)}
            helperText={
              (formik.touched.firstName && formik.errors.firstName) || " "
            }
          />
        </FormControl>

        <FormControl
          className={classes.formControl}
          error={!!formik.errors.lastName}
        >
          <TextField
            label="Last Name"
            className={classes.formInput}
            disabled={submitted}
            id="lastName"
            name="lastName"
            autoComplete="off"
            fullWidth={true}
            InputLabelProps={{
              className: classes.formLabel
            }}
            InputProps={{
              endAdornment: (
                <AvatarIcon className={getFieldIconClass("lastName")} />
              )
            }}
            FormHelperTextProps={{
              className: classes.formControlHelper
            }}
            inputProps={{
              maxLength: MAX_LAST_NAME_LENGTH
            }}
            onBlur={formik.handleBlur}
            value={formik.values.lastName}
            onChange={formik.handleChange}
            error={formik.touched.lastName && Boolean(formik.errors.lastName)}
            helperText={
              (formik.touched.lastName && formik.errors.lastName) || " "
            }
          />
        </FormControl>
      </div>

      <div className={classes.formGroup}>
        <FormControl
          className={classes.formControl}
          error={!!formik.errors.email}
        >
          <TextField
            label="Email Address"
            disabled
            className={classes.formInput}
            id="email"
            name="email"
            autoComplete="off"
            fullWidth={true}
            InputLabelProps={{
              className: classes.formLabel
            }}
            InputProps={{
              endAdornment: <EmailIcon className={getFieldIconClass("email")} />
            }}
            FormHelperTextProps={{
              className: classes.formControlHelper
            }}
            inputProps={{
              inputMode: "email",
              maxLength: 255
            }}
            onBlur={formik.handleBlur}
            value={formik.values.email}
            onChange={formik.handleChange}
            error={formik.touched.email && Boolean(formik.errors.email)}
            helperText={(formik.touched.email && formik.errors.email) || " "}
          />
        </FormControl>
      </div>

      <div className={classes.formGroup}>
        <FormControl
          className={classes.formControl}
          error={
            formik.touched.phoneNumber && Boolean(formik.errors.phoneNumber)
          }
        >
          <InputLabel htmlFor="phoneNumber" className={classes.formLabel}>
            Phone Number
          </InputLabel>
          <NumberInput
            mask={phoneNumberMask}
            disabled={submitted}
            className={classes.formInput}
            id="phoneNumber"
            name="phoneNumber"
            autoComplete="off"
            fullWidth={true}
            endAdornment={
              <PhoneIcon className={getFieldIconClass("phoneNumber")} />
            }
            onTextChange={formik.handleChange("phoneNumber")}
            onBlur={formik.handleBlur}
            value={formik.values.phoneNumber}
            error={
              formik.touched.phoneNumber && Boolean(formik.errors.phoneNumber)
            }
          />
          <FormHelperText className={classes.formControlHelper}>
            {formik.touched.phoneNumber && formik.errors.phoneNumber}
          </FormHelperText>
        </FormControl>

        <FormControl
          className={classes.formControl}
          error={!!formik.errors.licenseNumber}
        >
          <TextField
            label="Commission License Number"
            className={classes.formInput}
            disabled={submitted}
            id="licenseNumber"
            name="licenseNumber"
            autoComplete="off"
            fullWidth={true}
            InputLabelProps={{
              className: classes.formLabel
            }}
            InputProps={{
              endAdornment: (
                <NumberIcon className={getFieldIconClass("licenseNumber")} />
              )
            }}
            FormHelperTextProps={{
              className: classes.formControlHelper
            }}
            inputProps={{
              maxLength: MAX_LICENSE_NUMBER_LENGTH
            }}
            onBlur={formik.handleBlur}
            value={formik.values.licenseNumber}
            onChange={formik.handleChange}
            error={
              formik.touched.licenseNumber &&
              Boolean(formik.errors.licenseNumber)
            }
            helperText={
              (formik.touched.licenseNumber && formik.errors.licenseNumber) ||
              " "
            }
          />
        </FormControl>
      </div>
    </span>
  );
};

const displayFieldsWhenNotSubmitted = (
  classes: Record<string, any>,
  formik,
  submitted: boolean,
  getFieldIconClass: (fieldName: string) => string,
  roles,
  facilities
) => {
  const handleRoleChange = async (
    event: React.ChangeEvent<{ value: string }>
  ) => {
    const selectedRoleId = event.target.value;
    const role = roles.find(r => r.id === selectedRoleId);
    await formik.setFieldValue("role", role, true);
  };

  const handleFacilityChange = async (
    event: React.ChangeEvent<{ value: string }>
  ) => {
    const selectedFacilityId = event.target.value;
    const facility = facilities.find(f => f.id === selectedFacilityId);
    await formik.setFieldValue("facility", facility, true);
  };

  return (
    <div className={classes.formGroup}>
      <FormControl
        className={classes.formControl}
        error={formik.touched.role && !!formik.errors.role}
      >
        <InputLabel id="roleLabel" className={classes.formLabel}>
          Requested User Role
        </InputLabel>
        <Select
          disabled={submitted}
          className={classes.formInput}
          name="role"
          displayEmpty
          labelId="roleLabel"
          fullWidth={true}
          value={formik.values["role"] ? formik.values["role"].id : ""}
          renderValue={() => (
            <>
              <UserRoleIcon className={getFieldIconClass("role")} />
              {formik.values["role"] ? formik.values["role"].name : ""}
            </>
          )}
          onChange={handleRoleChange}
          onBlur={formik.handleBlur}
          error={formik.touched.role && Boolean(formik.errors.role)}
        >
          {roles.map(role => (
            <MenuItem
              key={role.id}
              value={role.id}
              className={classes.formSelectItem}
            >
              <div>{role.name}</div>
            </MenuItem>
          ))}
        </Select>
        <FormHelperText className={classes.formControlHelper}>
          {formik.touched.role && formik.errors.role}
        </FormHelperText>
      </FormControl>

      {requireFacility(formik.values.role) && (
        <FormControl
          className={classes.formControl}
          error={formik.touched.facility && !!formik.errors.facility}
        >
          <InputLabel id="facilityLabel" className={classes.formLabel}>
            At Facility
          </InputLabel>
          <Select
            disabled={submitted}
            className={classes.formInput}
            name="facility"
            displayEmpty
            labelId="facilityLabel"
            fullWidth={true}
            value={
              formik.values["facility"] ? formik.values["facility"].id : ""
            }
            renderValue={() => (
              <>
                <FacilityIcon
                  className={getFieldIconClass("facility")}
                  selected={false}
                  code={formik.values["facility"]?.code?.trim()}
                  backgroundColor={formik.values["facility"]?.backgroundColor}
                  strokeColor={formik.values["facility"]?.strokeColor}
                />
                {formik.values["facility"]
                  ? formik.values["facility"].name
                  : ""}
              </>
            )}
            onChange={handleFacilityChange}
            onBlur={formik.handleBlur}
            error={formik.touched.facility && Boolean(formik.errors.facility)}
          >
            {facilities.map(facility => (
              <MenuItem
                key={facility.id}
                value={facility.id}
                className={classes.formSelectItem}
              >
                <div>{facility.name}</div>
              </MenuItem>
            ))}
          </Select>
          <FormHelperText className={classes.formControlHelper}>
            {formik.touched.facility && formik.errors.facility}
          </FormHelperText>
        </FormControl>
      )}

      {requireTrainerName(formik.values.role) && (
        <FormControl
          className={classes.formControl}
          error={!!formik.errors.trainerName}
        >
          <TextField
            label="Primary Trainer Name"
            disabled={submitted}
            // className={getFieldClass("trainerName")}
            id="trainerName"
            name="trainerName"
            autoComplete="off"
            fullWidth={true}
            InputLabelProps={{
              className: classes.formLabel
            }}
            InputProps={{
              endAdornment: (
                <AvatarIcon className={getFieldIconClass("trainerName")} />
              )
            }}
            FormHelperTextProps={{
              className: classes.formControlHelper
            }}
            inputProps={{
              maxLength: MAX_FIRST_NAME_LENGTH
            }}
            onBlur={formik.handleBlur}
            value={formik.values.trainerName}
            onChange={formik.handleChange}
            error={
              formik.touched.firstName && Boolean(formik.errors.trainerName)
            }
            helperText={
              (formik.touched.trainerName && formik.errors.trainerName) || " "
            }
          />
        </FormControl>
      )}
    </div>
  );
};

const AccountPageProfile = () => {
  const classes = useStyles();
  const [userProfile, setUserProfile] = useState<UserProfileFormValues>(null);
  const [submissionOpened, setSubmissionOpened] = useState<boolean>(false);
  const [submitted, setSubmitted] = useState<boolean>(false);
  const [apiError, setApiError] = useState<string | null>(null);
  const { currentUser, updatePermissions } = useLoggedInUser();
  const {
    useListFacilities,
    useListRoles,
    useListUserRoleRequests,
    useGetUser,
    useEditUser,
    useCreateUserRoleRequest
  } = useRacehorse360Api();
  const queryCache = useQueryClient();

  const handleFormSubmit = (data: UserProfileFormValues) => {
    const cleanedData = {
      ...data,
      // clean phone number
      phoneNumber: data.phoneNumber
        ? data.phoneNumber.replace(/\D/g, "")
        : undefined,
      // for the "Trainer" role (just the main trainer role, not ALL trainers):
      // Default the trainerName to the user's name
      trainerName:
        data.role.name === "Trainer"
          ? `${data.firstName} ${data.lastName}`
          : data.trainerName
    };

    setUserProfile(cleanedData);
    setSubmissionOpened(true);
  };

  const getFieldIconClass = (fieldName: string): string => {
    let stateClass: string;

    if (!formik.errors[fieldName]) {
      stateClass = classes.formInputIconValidated;
    }

    return clsx(classes.formInputIcon, stateClass);
  };

  // Mutations
  const { mutateAsync: editUser } = useEditUser({
    onError: error => {
      setApiError(
        error?.message || "There was an error attempting to edit your profile."
      );
      setSubmitted(false);
    }
  });
  const { mutateAsync: createUserRoleRequest } = useCreateUserRoleRequest({
    onSuccess: async userRoleRequest => {
      await queryCache.invalidateQueries("MY_PENDING_ROLES");
      setSubmitted(true);
    },
    onError: error => {
      setApiError(
        error?.message || "There was an error attempting to request your role."
      );
      setSubmitted(false);
    }
  });

  // Queries
  const { data: facilitiesResult, isLoading: facilitiesLoading } =
    useListFacilities(pageQueries.facilities, {
      onError: error => console.error(error),
      placeholderData: { facilities: [] }
    });

  const { data: rolesResult, isLoading: rolesLoading } = useListRoles(
    "ALL_USER_ROLES",
    pageQueries.roles,
    {
      onError: error => console.error(error),
      placeholderData: { roles: [] }
    }
  );

  const { data: userRoleRequestsResult, isLoading: userRoleRequestsLoading } =
    useListUserRoleRequests(
      "MY_PENDING_ROLES",
      pageQueries.myRoles(currentUser.rh360Id),
      {
        enabled: !!currentUser.rh360Id,
        onError: error => console.error(error),
        placeholderData: { userRoleRequests: [] },
        onSuccess: data => {
          const requested = data.userRoleRequests.filter(
            x =>
              x.userRoleRequestStatus ===
              racehorse360.UserRoleRequestStatus
                .USER_ROLE_REQUEST_STATUS_REQUESTED
          );
          const approved = data.userRoleRequests.filter(
            x =>
              x.userRoleRequestStatus ===
              racehorse360.UserRoleRequestStatus
                .USER_ROLE_REQUEST_STATUS_APPROVED
          );
          if (approved.length && currentUser.roles.length === 0) {
            // we have been approved but haven't got a new token yet (probably?)
            updatePermissions();
            // history.push(routes.dashboard.path)
          }
          if (requested.length) {
            setSubmitted(true);
          }
        }
      }
    );

  const { data: user, isLoading: isUserLoading } = useGetUser(
    pageQueries.myProfile(currentUser.rh360Id),
    {
      enabled: !!currentUser.rh360Id,
      onError: error => console.error(error),
      onSuccess: async data => {
        await formik.setValues(
          values => ({
            ...values,
            firstName: data.firstName,
            lastName: data.lastName,
            email: data.email,
            phoneNumber: data.phoneNumber,
            licenseNumber: data.licenseNumber
          }),
          true
        );
      },
      placeholderData: {}
    }
  );

  const loading =
    rolesLoading ||
    facilitiesLoading ||
    userRoleRequestsLoading ||
    isUserLoading;
  const { roles } = rolesResult || {};
  const { facilities } = facilitiesResult || {};

  const handleSubmissionClose = useCallback(() => {
    setSubmissionOpened(false);
  }, [setSubmissionOpened]);

  const handleSubmissionSubmit = useCallback(async () => {
    const {
      firstName,
      lastName,
      email,
      phoneNumber,
      licenseNumber,
      facility,
      role,
      trainerName
    } = userProfile;

    setApiError(null);

    await editUser({
      id: currentUser.rh360Id,
      firstName,
      lastName,
      email,
      phoneNumber,
      licenseNumber
    });

    await createUserRoleRequest({
      userId: currentUser.rh360Id,
      facilityId: facility?.id,
      roleId: role.id,
      trainerName: trainerName
    });

    setSubmissionOpened(false);
  }, [createUserRoleRequest, currentUser.rh360Id, editUser, userProfile]);

  const formik = useFormik({
    initialValues: STARTING_VALUES,
    onSubmit: handleFormSubmit,
    validationSchema: validationSchemaAccount
  });

  if (loading) {
    return <Loader size={20} />;
  }

  return (
    <div className={classes.accountPageProfileContainer}>
      <div className={classes.logo}>
        <LogoIcon className={classes.logoIcon} />
        RACEHORSE&nbsp;<b>360</b>
      </div>

      {!submitted && (
        <div className={classes.infoSubmitting}>
          Please fill out and submit the following information.
          <br />
          An administrator will then finalize your account activation.
        </div>
      )}

      {apiError && <div className={classes.infoError}>{apiError}</div>}

      {submitted && !isUserLoading && (
        <div className={classes.infoSubmitted}>
          Your profile information has been submitted successfully. You will
          receive an email at
          <br />
          <span className={classes.infoSubmittedEmail}>{user.email}</span> once
          an administrator has reviewed the information you've provided.
        </div>
      )}

      <form className={classes.form} onSubmit={formik.handleSubmit}>
        <div className={classes.formContent}>
          {displayFields(classes, formik, submitted, getFieldIconClass)}

          {!submitted &&
            displayFieldsWhenNotSubmitted(
              classes,
              formik,
              submitted,
              getFieldIconClass,
              roles,
              facilities
            )}

          {/* Show the pending role request in disabled text boxes */}
          {submitted && !userRoleRequestsLoading && (
            <div className={classes.formGroup}>
              <FormControl className={classes.formControl}>
                <InputLabel id="roleLabel" className={classes.formLabel}>
                  Requested User Role
                </InputLabel>
                <TextInput
                  disabled
                  autoComplete="off"
                  fullWidth={true}
                  value={
                    userRoleRequestsResult.userRoleRequests[0]?.role.name || ""
                  }
                  endAdornment={
                    <UserRoleIcon className={getFieldIconClass("role")} />
                  }
                />
                <FormHelperText className={classes.formControlHelper} />
              </FormControl>

              {userRoleRequestsResult.userRoleRequests[0]?.facility && (
                <FormControl className={classes.formControl}>
                  <InputLabel id="facilityLabel" className={classes.formLabel}>
                    At Facility
                  </InputLabel>
                  <TextInput
                    disabled
                    autoComplete="off"
                    fullWidth={true}
                    value={
                      userRoleRequestsResult.userRoleRequests[0]?.facility.name
                    }
                    endAdornment={
                      <UserRoleIcon className={getFieldIconClass("facility")} />
                    }
                  />
                </FormControl>
              )}

              {userRoleRequestsResult.userRoleRequests[0]?.trainerName && (
                <FormControl className={classes.formControl}>
                  <InputLabel
                    htmlFor="trainerName"
                    className={classes.formLabel}
                  >
                    Primary Trainer Name
                  </InputLabel>
                  <TextInput
                    disabled
                    autoComplete="off"
                    fullWidth={true}
                    value={
                      userRoleRequestsResult.userRoleRequests[0]?.trainerName
                    }
                    endAdornment={
                      <UserRoleIcon
                        className={getFieldIconClass("trainerName")}
                      />
                    }
                  />
                </FormControl>
              )}
            </div>
          )}
        </div>

        {!submitted && (
          <Button
            className={classes.formSubmit}
            type="submit"
            disabled={!formik.isValid}
          >
            Confirm Submission
          </Button>
        )}

        {submitted && <div className={classes.formSubmittedBackground} />}
      </form>

      {submitted && (
        <Typography paragraph className={classes.formSubmittedHelp}>
          Need help? <a href="mailto:rh360@1st.com">Contact Support</a>
        </Typography>
      )}

      <SubmissionDialog
        userProfile={userProfile}
        isOpen={submissionOpened}
        onClose={handleSubmissionClose}
        onSubmit={handleSubmissionSubmit}
      />
    </div>
  );
};

export default AccountPageProfile;
