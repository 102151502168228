import React from "react";
import Button from "@material-ui/core/Button";
import TextField, { TextFieldProps } from "@material-ui/core/TextField";
import AddIcon from "@material-ui/icons/Add";
import RemoveIcon from "@material-ui/icons/Remove";

import useStyles from "./styles";

export interface Props {
  hideButtons?: boolean;
  onChange: (value: number) => void;
  TextFieldProps: TextFieldProps;
}

const ButtonNumberInput = (props: Props) => {
  const { TextFieldProps, onChange, hideButtons } = props;
  const amount = Number(TextFieldProps.value) || 0;
  const classes = useStyles();

  const handleClick = (shift: number) => () => {
    onChange(shift + amount);
  };

  const handleChange = (
    event: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>
  ) => {
    if (event.target.value === "" || /^\d+$/.test(event.target.value)) {
      TextFieldProps.onChange(event);
    }
  };

  return (
    <div className={classes.root}>
      <TextField
        className={classes.textField}
        {...TextFieldProps}
        value={amount}
        onChange={handleChange}
      />
      {!hideButtons && (
        <>
          <Button
            variant={"contained"}
            color={"primary"}
            onClick={handleClick(-1)}
            className={classes.button}
            disabled={amount <= 0}
            disableElevation
          >
            <RemoveIcon />
          </Button>
          <Button
            variant={"contained"}
            color={"primary"}
            className={classes.button}
            onClick={handleClick(1)}
            disableElevation
          >
            <AddIcon />
          </Button>
        </>
      )}
    </div>
  );
};

export default ButtonNumberInput;
