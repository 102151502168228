import React, { useState } from "react";
import clsx from "clsx";
import format from "date-fns/format";
import isAfter from "date-fns/isAfter";
import subMinutes from "date-fns/subMinutes";
import parseISO from "date-fns/parseISO";

import Refresh from "@material-ui/icons/Refresh";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import DialogActions from "@material-ui/core/DialogActions";
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import Box from "@material-ui/core/Box";
import IconButton from "@material-ui/core/IconButton";
import Typography from "@material-ui/core/Typography";

import { racehorse360 } from "@tsg/1st-grpc-web";

import { useLoggedInUser } from "components/LoggedInUserProvider";
import useStyles from "./styles";

export interface Props {
  workoutRequest: racehorse360.IWorkoutRequest;
  onOpen: (workoutRequest: racehorse360.IWorkoutRequest) => void;
  onClose: () => void;
  onConfirm: (workoutRequest: racehorse360.IWorkoutRequest) => void;
  className?: string;
}

const UndoQuickPass = (props: Props) => {
  const { workoutRequest, onOpen, onClose, onConfirm } = props;
  const classes = useStyles();
  const [open, setOpen] = useState<boolean>(false);
  const { currentUser } = useLoggedInUser();
  const horse = workoutRequest.horse || workoutRequest.workoutExam?.horse;

  const isRevertable = (
    workoutRequest: racehorse360.IWorkoutRequest
  ): boolean => {
    const { completedDate, assignedUser } = workoutRequest.workoutExam;

    return (
      isAfter(parseISO(completedDate), subMinutes(new Date(), 60)) &&
      (currentUser.isChiefVet ||
        currentUser.isSeniorVet ||
        (currentUser.isTrackVet && currentUser.rh360Id === assignedUser.id))
    );
  };

  const handleRevertClick = e => {
    e.preventDefault();
    e.stopPropagation();
    setOpen(true);
    onOpen(workoutRequest);
  };
  const handleCloseClick = e => {
    setOpen(false);
    onClose();
  };
  const handleDialogClick = e => {
    e.stopPropagation();
  };
  const handleRevertConfirm = () => {
    setOpen(false);
    onConfirm(workoutRequest);
  };

  return isRevertable(workoutRequest) ? (
    <>
      <IconButton
        className={classes.revertButton}
        onClick={handleRevertClick}
        data-test={"revert-button"}
      >
        <Refresh fontSize={"small"} />
      </IconButton>
      <Dialog
        open={open}
        onClose={handleCloseClick}
        className={classes.confirmationDialog}
        onClick={handleDialogClick}
      >
        <DialogTitle>
          <Typography className={classes.dialogTitleTypography}>
            Undo Quick Pass
          </Typography>
        </DialogTitle>
        <DialogContent>
          <Typography className={classes.dialogContentText}>
            Undoing the quick pass decision will result in the horse bing placed
            back in Exams
          </Typography>
          <Typography className={classes.textTitle}>Horse Name</Typography>
          <Typography className={classes.text}>{horse?.name}</Typography>
          <Typography className={classes.textTitle}>
            Workout Request Date
          </Typography>
          <Typography className={classes.text}>
            {format(new Date(workoutRequest.date), "MM/dd/yyyy")}
          </Typography>
        </DialogContent>
        <DialogActions>
          <Box className={classes.decisions}>
            <Button
              className={clsx(classes.button, classes.cancel)}
              onClick={handleCloseClick}
              data-test={"cancel-button"}
            >
              Cancel
            </Button>
            <Button
              className={clsx(classes.button, classes.undo)}
              onClick={handleRevertConfirm}
              data-test={"undo-button"}
            >
              UNDO
            </Button>
          </Box>
        </DialogActions>
      </Dialog>
    </>
  ) : (
    <></>
  );
};

export default React.memo(UndoQuickPass);
