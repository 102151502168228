import React, { useState, useEffect } from "react";
import format from "date-fns/format";
import formatISO from "date-fns/formatISO";
import parseISO from "date-fns/parseISO";
import clsx from "clsx";

import Card from "@material-ui/core/Card";
import CardHeader from "@material-ui/core/CardHeader";
import CardContent from "@material-ui/core/CardContent";
import CardActions from "@material-ui/core/CardActions";
import Comment from "@material-ui/icons/Comment";
import MoreHorizIcon from "@material-ui/icons/MoreHoriz";
import Box from "@material-ui/core/Box";
import Typography from "@material-ui/core/Typography";

import { racehorse360 } from "@tsg/1st-grpc-web";

import Loader from "components/Loader";
import ScheduleDateMenu from "components/ScheduleDateMenu";
import { useSnackbar } from "components/SnackbarContext/SnackbarContext";
import { useRacehorse360Api } from "hooks/api";
import ScheduleCardType from "interfaces/ScheduleCardType";
import ScheduleDayType from "interfaces/ScheduleDayType";
import { shouldShutOffDate } from "utils/date-utils";

import useStyles from "./styles";

export interface Props {
  cardType?: ScheduleCardType;
  dayType?: ScheduleDayType;
  workoutRequestData: racehorse360.IWorkoutRequest;
  className?: string;
  onUpdate?: (
    id?: string,
    workoutRequest?: racehorse360.IWorkoutRequest
  ) => void;
  onUpdateError?: () => void;
  selectedFacility: racehorse360.IFacility;
  horse: racehorse360.IHorse;
  isDisabled?: boolean;
  isCondensed?: boolean;
  isWide?: boolean;
}

// eslint-disable-next-line complexity
const ScheduleDate = React.memo((props: Props) => {
  const {
    className,
    cardType,
    dayType,
    workoutRequestData,
    selectedFacility,
    horse,
    isDisabled,
    isCondensed,
    isWide,
    onUpdate,
    onUpdateError
  } = props;

  const [cardEl, setCardEl] = useState<HTMLDivElement>(null);
  const date = parseISO(workoutRequestData?.date);
  const [isMenuOpen, setIsMenuOpen] = useState<boolean>(false);
  const [workoutRequest, setWorkoutRequest] =
    useState<racehorse360.IWorkoutRequest>(workoutRequestData);

  const classes = useStyles({
    backgroundColor: workoutRequest?.facility?.backgroundColor,
    strokeColor: workoutRequest?.facility?.strokeColor
  });

  const {
    useCreateWorkoutRequest,
    useCancelWorkoutRequest,
    useMoveFacilityWorkoutRequest
  } = useRacehorse360Api();

  const {
    isLoading: isCreateWorkoutLoading,
    mutateAsync: createWorkoutRequest
  } = useCreateWorkoutRequest();

  const {
    isLoading: isCancelWorkoutLoading,
    mutateAsync: cancelWorkoutRequest
  } = useCancelWorkoutRequest();

  const {
    isLoading: isUpdateWorkoutLoading,
    mutateAsync: moveFacilityWorkoutRequest
  } = useMoveFacilityWorkoutRequest();

  const { showErrorSnack } = useSnackbar();

  const isLoading = [
    isCreateWorkoutLoading,
    isCancelWorkoutLoading,
    isUpdateWorkoutLoading
  ].some(item => item);

  const facility = workoutRequest.facility;
  const facilityName = facility?.name;
  const facilityCode = facility?.code.trim();
  const isScheduled = Boolean(facility);

  const isShutOffDate = shouldShutOffDate(
    parseISO(workoutRequest.date),
    selectedFacility
  );
  const isUpdatable = !isDisabled && (!isShutOffDate || isScheduled);

  const showMonthForDetailed =
    cardType === ScheduleCardType.Detailed &&
    (dayType === ScheduleDayType.FirstDay ||
      dayType === ScheduleDayType.LastDay);

  const getBlockState = () => {
    if (isUpdatable) {
      if (isScheduled) return "SCHEDULED";
      else return "AVAILABLE";
    } else {
      return "UNAVAILABLE";
    }
  };

  const handleCardClick = (event: React.MouseEvent<HTMLDivElement>) => {
    if (!isLoading) {
      if (!cardEl) {
        setCardEl(event.currentTarget);
      }
      if (workoutRequest.id) {
        setIsMenuOpen(true);
      } else {
        createWorkoutRequest({
          date: workoutRequest.date,
          facilityId: selectedFacility.id,
          horseId: horse.id,
          getOptions: {
            select: [
              "id",
              "facility.name",
              "facility.code",
              "facility.background_color",
              "facility.foreground_color",
              "facility.stroke_color",
              "date",
              "status",
              "hasComment"
            ]
          }
        })
          .then(async response => {
            onUpdate(response.id, response);
            setWorkoutRequest(response);
          })
          .catch(error => {
            console.error(error);
            onUpdateError();
          });
      }
    }
  };

  const handleWorkoutChange = (workoutData: racehorse360.IWorkout) => {
    if (workoutData.facility) {
      moveFacilityWorkoutRequest({
        id: workoutData.id,
        facilityId: workoutData.facility.id,
        getOptions: {
          select: [
            "id",
            "facility.name",
            "facility.code",
            "date",
            "status",
            "hasComment"
          ]
        }
      })
        .then(response => {
          onUpdate(response.id, response);
          setWorkoutRequest(response);
        })
        .catch(error => console.error(error));
    } else {
      cancelWorkoutRequest({
        id: workoutData.id
      })
        .catch(error => showErrorSnack(error.toString()))
        .then(() => {
          onUpdate(workoutRequest.id, null);
          setWorkoutRequest({
            date: workoutData.date
          });
        })
        .catch(error => console.error(error));
    }
  };

  const handleMenuClose = () => {
    setIsMenuOpen(false);
  };

  const handleMenuOpen = () => {
    setIsMenuOpen(true);
  };

  useEffect(() => {
    setWorkoutRequest(workoutRequestData);
  }, [workoutRequestData]);

  const renderHeader = () => {
    return (
      <>
        {isScheduled && (
          <CardHeader
            className={classes.cardHeader}
            disableTypography
            title={isWide ? facilityName.toLowerCase() : facilityCode}
          />
        )}

        <div
          className={clsx(classes.cardHeaderFiller, {
            [classes.cardDetailedDefaultHeader]:
              cardType === ScheduleCardType.Detailed
          })}
        />
      </>
    );
  };

  const renderCardActions = () => {
    if (isScheduled) {
      return (
        <CardActions className={classes.cardActions}>
          <MoreHorizIcon className={classes.moreHorizIcon} />
          {isUpdatable && workoutRequest.hasComment && (
            <Comment className={classes.commentIcon} />
          )}
        </CardActions>
      );
    } else {
      return (
        <div
          className={clsx(
            classes.cardActions,
            classes.cardDetailedDefaultActions
          )}
        />
      );
    }
  };

  return (
    <Card
      className={clsx(classes.card, className, {
        [classes.cardDetailed]: cardType === ScheduleCardType.Detailed,
        [classes.cardDisabled]: !isUpdatable,
        [classes.condensedCard]: isCondensed,
        [classes.widePane]: isWide,
        [classes.default]: !isScheduled
      })}
      variant={"outlined"}
      onClick={handleCardClick}
      data-test-element="CALENDAR_DAY"
      data-test-value={formatISO(date, { representation: "date" })}
      data-test-state={getBlockState()}
    >
      {isLoading && <Loader className={classes.loader} />}
      {cardType !== ScheduleCardType.Detailed && (
        <Typography className={classes.dayName}>
          {format(date, "E").toUpperCase()}
        </Typography>
      )}
      {workoutRequest && (
        <>
          {renderHeader()}

          <CardContent className={classes.cardContent}>
            <Box className={classes.dateWrapper}>
              {showMonthForDetailed && (
                <>
                  <Typography
                    className={clsx(classes.dateMonth, classes.downSmDateMonth)}
                  >
                    {format(date, "MMM")}
                  </Typography>
                  <Typography
                    className={clsx(classes.dateMonth, classes.upSmDateMonth)}
                  >
                    {format(date, "MMMM")}
                  </Typography>
                </>
              )}
              <Typography
                className={clsx(classes.date, {
                  [classes.squared]: dayType === ScheduleDayType.Today
                })}
              >
                {isWide ? format(date, "iii M/d") : date.getDate()}
              </Typography>
            </Box>
          </CardContent>

          {renderCardActions()}
        </>
      )}
      {cardEl && (
        <ScheduleDateMenu
          horse={horse}
          anchorEl={cardEl}
          workoutData={workoutRequest}
          onChange={handleWorkoutChange}
          onClose={handleMenuClose}
          onOpen={handleMenuOpen}
          isOpen={isMenuOpen}
        />
      )}
    </Card>
  );
});

export default React.memo(ScheduleDate);
