import React, { useState, useEffect } from "react";
import { racehorse360 } from "@tsg/1st-grpc-web";
import ExamLockerContext from "components/ExamLockerContext";
import ExamLockerCountdownModal from "components/ExamLockerCountdownModal";
import { useRacehorse360Api } from "hooks/api";
import VetExamProvider from "./VetExamStateProvider";
import VetExams from "./VetExams";
import { getVetExamRequestState } from "./helper";
import { createEmptyExamDetail, IVetExamLocalState } from "./options";
import {
  HORSE_OPTIONS,
  WORKOUT_EXAM_OPTIONS
} from "../../pages/HorseDetailsPage/Vet/helper";
import { SortOrder } from "interfaces/SortOrder";

interface IProps {
  examId?: string;
  refetchData: () => void;
  onClose: () => void;
  open?: boolean;
}

const VetExamOverlay = (props: IProps) => {
  const { examId, refetchData, onClose, open } = props;
  const [lockExpirationDate, setLockExpirationDate] = useState<Date>();
  const [currentExam, setCurrentExam] = useState<racehorse360.IWorkoutExam>();

  const { useCancelWorkoutExam, useEditWorkoutExam, useListWorkoutExams } =
    useRacehorse360Api();

  const { isLoading: isExamLoading } = useListWorkoutExams(
    {
      query: {
        ids: [examId]
      },
      getOptions: {
        select: [
          ...WORKOUT_EXAM_OPTIONS,
          ...HORSE_OPTIONS.map(item => `horse.${item}`),
          "horseId",
          "type",
          "highSpeedFurlongs",
          "workoutRequestId",
          "workoutRequest.id",
          "workoutRequest.facility.name",
          "workoutRequest.date",
          "workoutRequest.status"
        ],
        orderBy: [`updatedOn ${SortOrder.DESC}`]
      }
    },
    {
      enabled: Boolean(examId),
      onSuccess: result => {
        const currentExam = result?.workoutExams[0];
        if (!currentExam.workoutExamDetails.length) {
          currentExam["workoutExamDetails"] = [
            createEmptyExamDetail() as racehorse360.IWorkoutExamDetail
          ];
        }

        setCurrentExam(currentExam);
      }
    }
  );

  const { mutateAsync: cancelWorkoutExam } = useCancelWorkoutExam();
  const { mutateAsync: saveWorkoutExamState } = useEditWorkoutExam();

  const handleCancelExam = () =>
    cancelWorkoutExam({
      id: examId,
      getOptions: { select: ["id", "reason"] }
    }).then(() => refetchData());

  const handleExamLockExpiration = () =>
    handleCancelExam().then(() => {
      onClose();
    });

  const handleCountdownClose = () => {
    setLockExpirationDate(null);
    refetchData();
    onClose();
  };

  const handleLock = expirationDate => {
    setLockExpirationDate(expirationDate);
  };

  const handleClose = () => {
    setLockExpirationDate(null);
    onClose();
  };

  const saveWorkoutExam = (state: IVetExamLocalState) => {
    saveWorkoutExamState({
      workoutExam: getVetExamRequestState(currentExam, state)
    });
  };

  useEffect(() => {
    if (!examId) setCurrentExam(null);
  }, [examId]);

  if (!examId) return <></>;

  return (
    examId && (
      <>
        <ExamLockerContext
          recordId={examId}
          countdown={true}
          onExpire={handleExamLockExpiration} //TODO: set it using setState inside vet exam modal component
          onLock={handleLock}
        >
          <VetExamProvider>
            <>
              <VetExams
                open={open}
                refetchData={refetchData}
                onClose={handleClose}
                currentExam={currentExam}
                isExamLoading={isExamLoading}
              />
              {currentExam?.id && (
                <ExamLockerCountdownModal
                  expirationDate={lockExpirationDate}
                  onClose={handleCountdownClose}
                  onSaveWorkoutExam={saveWorkoutExam}
                />
              )}
            </>
          </VetExamProvider>
        </ExamLockerContext>
      </>
    )
  );
};

export default VetExamOverlay;
