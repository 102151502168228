import React, { useState, useEffect } from "react";
import { useHistory } from "react-router-dom";
import SwipeableViews from "react-swipeable-views";
import { useQueryClient } from "react-query";

import useMediaQuery from "@material-ui/core/useMediaQuery";
import { Theme } from "@material-ui/core/styles";
import Tabs from "@material-ui/core/Tabs";
import Tab from "@material-ui/core/Tab";
import Button from "@material-ui/core/Button";
import Typography from "@material-ui/core/Typography";
import Box from "@material-ui/core/Box";
import CircularProgress from "@material-ui/core/CircularProgress";
import Modal from "@material-ui/core/Modal";

import { useSelector, useDispatch } from "react-redux";

import { racehorse360 } from "@tsg/1st-grpc-web";
import Breakpoints from "common/breakpoints";
import { useLoggedInUser } from "components/LoggedInUserProvider";
import { useClickBlockerContext } from "components/BlockableClickContext";
import { useSnackbar } from "components/SnackbarContext/SnackbarContext";

import { getCoursesModelData, isFormChanged } from "../utils";
import useStyles from "./styles";

import Loader from "components/Loader";
import {
  listCoursesGetOptions,
  FacilityData,
  CourseData,
  ChangesData,
  APPS
} from "../constants";
import Course from "../Course";
import AllCoursesList from "../AllCoursesList";
import { LIST_ALL_ACTIVE_COURSES_QUERY_KEY } from "../AllCoursesList/AllCoursesList";
import { useRacehorse360Api } from "hooks/api";
import {
  setActiveLink as setStoreActiveLink,
  setStoreActiveLinkCandidate as setStoreClickedLink
} from "store/actions/sidebar";

interface Props {
  facilityData: FacilityData;
  selectedFacility: string;
  facilityIds: string[];
  setCurrentActiveApp: (appName: APPS) => void;
  onRemoveFromOpenState: (id: string) => void;
  onAddToOpenState: (id: string) => void;
  onChangesState: (changesState: ChangesData) => void;
  changesState: ChangesData;
  isOpenUnsavedModal: boolean;
  onOpenUnsavedModal: (isOpen: boolean) => void;
  onApplicationClick?: (appName: APPS, isSaveButtonClicked?: boolean) => void;
  onChangeSlide?: (appName: APPS) => void;
  openState: string[];
  currentActiveApp: APPS | null;
}

const LIST_ACTIVE_COURSES_BY_FACILITY_ID_QUERY_KEY = "listCourses";
enum ETab {
  FORM = "Form",
  ALL_TRACKS = "allTracks"
}

const TrackConditions = React.memo((props: Props) => {
  const {
    facilityData,
    selectedFacility,
    facilityIds,
    onRemoveFromOpenState,
    onAddToOpenState,
    onChangesState,
    changesState,
    isOpenUnsavedModal,
    onOpenUnsavedModal,
    onApplicationClick,
    onChangeSlide,
    openState
  } = props;

  const { currentUser } = useLoggedInUser();
  const isManagementMode = currentUser.isRacingOfficial || currentUser.isAdmin;
  const classes = useStyles();
  const { showSuccessSnack, showErrorSnack } = useSnackbar();
  const queryClient = useQueryClient();
  const isScreenSM = useMediaQuery((theme: Theme) =>
    theme.breakpoints.down(Breakpoints.SM_600)
  );
  const history = useHistory();
  const menuClickedLink = useSelector(
    (state: { sidebar }) => state?.sidebar.clickedLink
  );
  const dispatch = useDispatch();

  const [isUpdating, setIsUpdating] = useState<boolean>(false);

  const [originalCourses, setOriginalCourses] = useState<CourseData>({});
  const [courses, setCourses] = useState<CourseData>({});
  const [selectedTab, setSelectedTab] = useState<ETab>(
    isManagementMode ? ETab.FORM : ETab.ALL_TRACKS
  );

  const { useEditCurrentTrackCondition, useListCourses } = useRacehorse360Api();

  const { setBlockClick, setBlockClickCallback } = useClickBlockerContext();

  const { mutateAsync: editCurrentTrackCondition } =
    useEditCurrentTrackCondition();

  const {
    isLoading,
    isFetching,
    data: listCourses
  } = useListCourses(
    LIST_ACTIVE_COURSES_BY_FACILITY_ID_QUERY_KEY,
    {
      query: {
        facilityIds: facilityIds,
        isActive: {
          value: true
        },
        isFacilityActive: {
          value: true
        }
      },
      pagingOptions: {
        maxResults: 1000
      },
      getOptions: {
        select: listCoursesGetOptions
      }
    },
    {
      onSuccess: (result: racehorse360.IListCoursesResponse) => {
        setCoursesState(result);
      },
      onError: error => {
        console.error(error);
      },
      enabled: isManagementMode
    }
  );

  const setCoursesState = (courses: racehorse360.IListCoursesResponse) => {
    const { workingCourses, originalCourses, newChangesState } =
      getCoursesModelData(courses);

    setCourses(workingCourses);
    setOriginalCourses(originalCourses);
    onChangesState(newChangesState);
  };

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [selectedTab]);

  const showError = () => {
    showErrorSnack(
      <span>
        Track Conditions could not be saved. Please try again or{" "}
        <a href={"mailto:rh360@1st.com"} className={classes.errorMessageLink}>
          contact support
        </a>
        .
      </span>
    );
  };

  const handleClearNote = (courseId: string) => {
    const newData = { ...courses };
    newData[courseId].comment = "";

    const isNoteChanged = originalCourses[courseId].comment !== "";

    onChangesState({
      ...changesState,
      [courseId]: {
        ...changesState[courseId],
        isNoteChanged: isNoteChanged
      }
    });

    setCourses(newData);
    lockSideBarNavigation();
  };

  const handleChangeNote = (courseId: string, newNoteValue: string = "") => {
    const newData = { ...courses };

    newData[courseId].comment = newNoteValue.trim();

    const isNoteChanged =
      originalCourses[courseId].comment !== newNoteValue.trim();

    onChangesState({
      ...changesState,
      [courseId]: {
        ...changesState[courseId],
        isNoteChanged: isNoteChanged
      }
    });

    setCourses(newData);
    lockSideBarNavigation();
  };

  const handleChangeTrackCondition = (
    courseId: string,
    newTrackConditionValue: racehorse360.TrackCondition
  ) => {
    const newData = { ...courses };
    newData[courseId].trackCondition = newTrackConditionValue;

    const isTrackConditionChanged =
      originalCourses[courseId].trackCondition !== newTrackConditionValue;

    onChangesState({
      ...changesState,
      [courseId]: {
        ...changesState[courseId],
        isTrackConditionChanged
      }
    });

    setCourses(newData);
    isTrackConditionChanged
      ? lockSideBarNavigation()
      : unlockSideBarNavigation();
  };

  const handleTabChange = (event, value) => {
    setSelectedTab(value);
  };

  const openUnsavedModal = () => {
    onOpenUnsavedModal(true);
    setSelectedTab(ETab.FORM);
  };

  const handleSaveClick = () => {
    onOpenUnsavedModal(false);
    setIsUpdating(true);
    const editTrackConditionPromises = Object.keys(changesState).reduce(
      (acc, key) => {
        if (
          changesState[key].isNoteChanged ||
          changesState[key].isTrackConditionChanged
        ) {
          acc.push(
            editCurrentTrackCondition({
              id: key,
              getOptions: {
                select: listCoursesGetOptions
              },
              trackCondition: courses[key].trackCondition,
              comment: courses[key].comment
            })
          );
        }
        return acc;
      },
      []
    );

    Promise.all(editTrackConditionPromises)
      .then(() => {
        return Promise.all([
          queryClient.invalidateQueries(LIST_ALL_ACTIVE_COURSES_QUERY_KEY),
          queryClient.invalidateQueries(
            LIST_ACTIVE_COURSES_BY_FACILITY_ID_QUERY_KEY
          )
        ]);
      })
      .then(() => {
        showSuccessSnack("Track Conditions have been saved.");
      })
      .catch(() => {
        showError();
      })
      .finally(() => {
        setIsUpdating(false);
      });

    unlockSideBarNavigation();
  };

  const handleCancelButtonPress = () => {
    setCoursesState(listCourses);
    onApplicationClick(APPS.ApplicationSelector);
  };

  const handleCloseUnsavedChanges = () => {
    onOpenUnsavedModal(false);
  };

  const handleClearAndClose = () => {
    onOpenUnsavedModal(false);
    setCoursesState(listCourses);
    unlockSideBarNavigation();

    if (menuClickedLink) {
      dispatch(setStoreActiveLink(menuClickedLink));
      history.push(menuClickedLink);
      dispatch(setStoreClickedLink(""));
    } else {
      onChangeSlide(APPS.ApplicationSelector);
    }
  };

  const lockSideBarNavigation = () => {
    setBlockClick(true);
    setBlockClickCallback(() => () => onOpenUnsavedModal(true));
  };

  const unlockSideBarNavigation = () => {
    setBlockClick(false);
    setBlockClickCallback(null);
  };

  const renderTabs = () => {
    if (!Object.keys(facilityData).length) return null;

    let output;

    if (!isLoading) {
      output = (
        <Tabs
          className={classes.tabulator}
          value={selectedTab}
          onChange={handleTabChange}
          centered={true}
        >
          <Tab
            className={classes.tabItem}
            label={"Manage Tracks"}
            value={ETab.FORM}
            selected={selectedTab === ETab.FORM}
          />
          <Tab
            className={classes.tabItem}
            label={"All Tracks"}
            value={ETab.ALL_TRACKS}
            selected={selectedTab === ETab.ALL_TRACKS}
          />
        </Tabs>
      );
    }

    return output;
  };

  const renderTrackConditionsSubheader = () => {
    return isScreenSM ? renderTabs() : null;
  };

  const renderAllCoursesList = () => <AllCoursesList />;

  const renderModalFooter = () => {
    const hasChanges = isFormChanged(changesState);

    return (
      <Box className={classes.buttonsGroup}>
        <Button
          variant="outlined"
          className={classes.cancelButton}
          onClick={hasChanges ? openUnsavedModal : handleCancelButtonPress}
          disabled={isLoading || openState.length > 0}
        >
          <Typography className={classes.cancelButtonText}>
            &nbsp;CANCEL
          </Typography>
        </Button>
        <Button
          variant="outlined"
          className={classes.saveButton}
          onClick={handleSaveClick}
          disabled={isLoading || !hasChanges || openState.length > 0}
        >
          {isUpdating ? (
            <CircularProgress size={20} />
          ) : (
            <Typography className={classes.saveButtonText}>
              SAVE CONDITIONS
            </Typography>
          )}
        </Button>
      </Box>
    );
  };

  const renderTrackConditionsBody = () => {
    let output = null;

    if (!isManagementMode) {
      return renderAllCoursesList();
    }

    const relatedCourses = Object.keys(courses).map(it => courses[it]);
    const renderForm = () => (
      <>
        {isUpdating && <div className={classes.opacityOverlay} />}
        {isFetching || isLoading ? (
          <Loader />
        ) : relatedCourses.length ? (
          <div className={classes.contentBodyList}>
            {relatedCourses
              .filter(it => {
                return it.facility.id === selectedFacility;
              })
              .map((it, i) => {
                return (
                  <Course
                    key={i}
                    course={it}
                    originalTrackCondition={
                      originalCourses[it.id].trackCondition
                    }
                    handleChangeTrackCondition={handleChangeTrackCondition}
                    handleChangeNote={handleChangeNote}
                    clearNote={handleClearNote}
                    courseChanges={changesState[it.id]}
                    addToOpenState={onAddToOpenState}
                    removeFromOpenState={onRemoveFromOpenState}
                  />
                );
              })}
          </div>
        ) : (
          <div className={classes.noDataHolder}>
            <div className={classes.noDataHolderText}>No Data to Display</div>
          </div>
        )}
        {!isScreenSM && renderModalFooter()}
      </>
    );

    if (!isScreenSM) {
      output = (
        <>
          <div className={classes.contentBodyLeft}>{renderForm()}</div>
          <div className={classes.contentBodyRight}>
            {renderAllCoursesList()}
          </div>
        </>
      );
    } else {
      output = (
        <SwipeableViews
          index={selectedTab === ETab.FORM ? 0 : 1}
          className={classes.swipeContainer}
          slideClassName={classes.swipeElement}
          disabled={true}
        >
          {renderForm()}
          {renderAllCoursesList()}
        </SwipeableViews>
      );
    }

    return output;
  };

  const renderUnsavedChangesModal = () => (
    <Modal
      aria-labelledby="simple-modal-title"
      aria-describedby="simple-modal-description"
      className={classes.unsavedChangesModal}
      open={isOpenUnsavedModal}
      onClose={handleCloseUnsavedChanges}
    >
      <div className={classes.unsavedPaper}>
        <div className={classes.unsavedHeader}>Unsaved Changes</div>
        <div className={classes.unsavedBody}>
          Would you like to save the changes you made to the track conditions?
        </div>
        <div className={classes.unsavedFooter}>
          <div className={classes.unsavedFooterLeft}>
            <Button
              className={classes.unsavedButton}
              onClick={handleClearAndClose}
            >
              <Typography className={classes.unsavedDontSaveButtonText}>
                DON'T SAVE
              </Typography>
            </Button>
          </div>
          <div className={classes.unsavedFooterRight}>
            <Button
              className={classes.unsavedButton}
              onClick={handleCloseUnsavedChanges}
            >
              <Typography className={classes.unsavedCancelButtonText}>
                CANCEL
              </Typography>
            </Button>

            <Button className={classes.unsavedButton} onClick={handleSaveClick}>
              <Typography className={classes.unsavedSaveButtonText}>
                SAVE
              </Typography>
            </Button>
          </div>
        </div>
      </div>
    </Modal>
  );

  return (
    <div className={classes.trackConditionsContainer}>
      {isManagementMode && (
        <div className={classes.trackConditionsSubHeader}>
          {renderTrackConditionsSubheader()}
        </div>
      )}
      <div className={classes.trackConditionsBody}>
        {renderTrackConditionsBody()}
      </div>
      {isScreenSM && isManagementMode && renderModalFooter()}
      {isOpenUnsavedModal && renderUnsavedChangesModal()}
    </div>
  );
});

export default TrackConditions;
