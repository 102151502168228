import React, { useCallback, useEffect, useState } from "react";
import { useDispatch } from "react-redux";

import Button from "@material-ui/core/Button";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import IconButton from "@material-ui/core/IconButton";
import Switch from "@material-ui/core/Switch";

import { racehorse360 } from "@tsg/1st-grpc-web";

import AppSearch from "components/AppSearch";
import { AppSearchType } from "components/AppSearch/AppSearch";
import ErrorBoundary from "components/ErrorBoundary";
import FacilitySelect from "components/FacilitySelect";
import DownloadIcon from "components/Icons/Download";
import { useLoggedInUser } from "components/LoggedInUserProvider";
import { useDebouncedNameValue } from "hooks/useSearch";
import DateRange, { DateRangeISO, DateRangeType } from "interfaces/DateRange";
import {
  setAssignedOnly as setStoreAssignedOnly,
  setSelectedDateRange as setStoreSelectedDateRange,
  setSelectedFacility as setStoreSelectedFacility,
  setSearchQuery as setStoreSearchQuery
} from "store/actions/vetWorkoutsPage";
import DateRangeSelect from "./DateRangeSelect";
import WorkoutRequestReportDialog from "./WorkoutRequestReportDialog";
import { checkCurrentWorkoutsTableTab } from "../helper";
import { useVetWorkoutsStore } from "../useVetWorkoutsStore";

import useStyles from "./styles";

interface IProps {
  workoutRequestsQuery: racehorse360.IWorkoutRequestFilter;
  workoutExamsQuery: racehorse360.IWorkoutExamFilter;
  openAssignToVetDialog: () => void;
  openConfirmSelectionsDialog: (value: boolean) => void;
  clearSearch: boolean;
  onClearSearch: (value: boolean) => void;
}

const Header = (props: IProps) => {
  const {
    workoutRequestsQuery,
    workoutExamsQuery,
    openAssignToVetDialog,
    openConfirmSelectionsDialog,
    clearSearch,
    onClearSearch
  } = props;
  const classes = useStyles();
  const { currentUser } = useLoggedInUser();
  const dispatch = useDispatch();
  const {
    assignedOnly,
    selectedFacility,
    selectedTab,
    searchValue,
    examCheckedIds,
    passCheckedIds,
    assignCheckedIds,
    savingCheckedIds,
    convertedToDateSelectedDateRange
  } = useVetWorkoutsStore();

  const [query, setQuery] = useState<string>(searchValue);
  const searchQuery = useDebouncedNameValue(query, { timeout: 700 });
  const [
    shouldDisplayWorkoutRequestReportDialog,
    setShouldDisplayWorkoutRequestReportDialog
  ] = useState<boolean>(false);

  const { isRequestsTab, isExamsTab } = checkCurrentWorkoutsTableTab(
    selectedTab.value
  );

  useEffect(() => {
    dispatch(setStoreSearchQuery(searchQuery));
  }, [searchQuery]);

  const handleAppSearch = (value: string) => {
    setQuery(value);
  };

  const handleFacilityChange = useCallback(
    (facility: racehorse360.IFacility) => {
      dispatch(setStoreSelectedFacility(facility));
    },
    [dispatch]
  );

  const handleDateRangeChange = useCallback(
    (dateRange: DateRange) => {
      const storeDateRange: DateRangeISO = {
        Type: dateRange.Type,
        CustomDateRange: []
      };
      if (dateRange.Type === DateRangeType.Custom) {
        storeDateRange.CustomDateRange = dateRange.CustomDateRange.map(d =>
          d.toISOString()
        );
      }
      dispatch(setStoreSelectedDateRange(storeDateRange));
    },
    [dispatch]
  );

  const handleAssignToMeClick = () => {
    openAssignToVetDialog();
  };

  const handleAssignToVetClick = () => {
    openAssignToVetDialog();
  };

  const handleSaveSelectionsClick = () => {
    openConfirmSelectionsDialog(true);
  };

  const handleAssignedOnlyChange = useCallback(
    event => {
      dispatch(setStoreAssignedOnly(event.target.checked));
    },
    [dispatch]
  );

  const handleDownloadWorkoutRequestReport = () => {
    setShouldDisplayWorkoutRequestReportDialog(true);
  };

  const handleWorkoutRequestReportDialogClose = () => {
    setShouldDisplayWorkoutRequestReportDialog(false);
  };

  const renderAssignToVetButton = () => {
    const isCheckedAssignIds = Boolean(assignCheckedIds.length);
    let output;

    if (isExamsTab) {
      const buttonText = currentUser.isTrackVet
        ? "Assign to me"
        : "Assign to vet";
      const handleAssignToVetButtonClick = currentUser.isTrackVet
        ? handleAssignToMeClick
        : handleAssignToVetClick;

      output = (
        <div className={classes.saveSelectionsContainer}>
          <Button
            className={classes.saveSelections}
            disabled={!isCheckedAssignIds}
            onClick={handleAssignToVetButtonClick}
          >
            {buttonText}
          </Button>
        </div>
      );
    }

    return output;
  };

  const renderAssignedToMe = () => {
    let output;

    if (!isRequestsTab) {
      output = (
        <FormControlLabel
          className={classes.assignedToMe}
          control={
            <Switch
              size={"small"}
              checked={assignedOnly}
              onChange={handleAssignedOnlyChange}
            />
          }
          label="Assigned to Me"
        />
      );
    }

    return output;
  };

  const renderSaveSelectionButton = () => {
    const isCheckedExamPassIds = Boolean(
      examCheckedIds.length || passCheckedIds.length
    );
    let output;

    if (isRequestsTab) {
      output = (
        <div className={classes.saveSelectionsContainer}>
          <Button
            className={classes.saveSelections}
            disabled={!isCheckedExamPassIds || Boolean(savingCheckedIds.length)}
            onClick={handleSaveSelectionsClick}
            data-test={"save-selections"}
          >
            Save Selections
          </Button>
        </div>
      );
    }

    return output;
  };

  const renderDownloadButton = () => {
    return (
      <div className={classes.saveSelectionsContainer}>
        <IconButton
          className={classes.downloadButton}
          size="small"
          onClick={handleDownloadWorkoutRequestReport}
        >
          <DownloadIcon className={classes.downloadIcon} />
        </IconButton>
      </div>
    );
  };

  return (
    <>
      <div className={classes.primaryFilter}>
        <div className={classes.facility}>
          <ErrorBoundary>
            <FacilitySelect
              isActive={true}
              selectedFacility={selectedFacility}
              onChange={handleFacilityChange}
            />
          </ErrorBoundary>
        </div>
        {renderAssignedToMe()}
      </div>

      <div className={classes.secondaryFilter}>
        <div className={classes.searchFilter}>
          <ErrorBoundary>
            <AppSearch
              className={classes.searchFilterInput}
              isForceSearch
              placeholder={"Search Horses and Trainers"}
              searchType={AppSearchType.Both}
              onSearch={handleAppSearch}
              value={searchQuery}
              vetWorkoutRequestsFilter={
                workoutRequestsQuery?.statuses ? workoutRequestsQuery : null
              }
              vetWorkoutExamsFilter={
                workoutExamsQuery?.statuses ? workoutExamsQuery : null
              }
              clearSearch={clearSearch}
              onClearSearch={onClearSearch}
            />
          </ErrorBoundary>
        </div>

        <div className={classes.pickerFilter}>
          <div>
            <ErrorBoundary>
              <DateRangeSelect
                selectedDateRange={convertedToDateSelectedDateRange}
                onChange={handleDateRangeChange}
              />
            </ErrorBoundary>
          </div>
          <div className={classes.rightSideGroup}>
            {renderSaveSelectionButton()}
            {renderAssignToVetButton()}
            {renderDownloadButton()}
          </div>
        </div>

        {shouldDisplayWorkoutRequestReportDialog && (
          <WorkoutRequestReportDialog
            onClose={handleWorkoutRequestReportDialogClose}
            open
          />
        )}
      </div>
    </>
  );
};

export default Header;
