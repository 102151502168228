import { MuiTheme } from "theme";
import makeStyles from "@material-ui/core/styles/makeStyles";
import Breakpoints from "common/breakpoints";

export default makeStyles((theme: MuiTheme) => ({
  root: {
    display: "flex",
    flex: 1,
    flexDirection: "column"
  },
  header: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-between",
    padding: "10px 20px 20px",
    borderBottom: `1px solid ${theme.palette.divider}`,
    background: theme.palette.common.white,
    [theme.breakpoints.up("md")]: {
      padding: "20px 20px 20px"
    }
  },
  titleWrapper: {
    display: "flex",
    justifyContent: "space-between",
    paddingBottom: 10
  },
  title: {
    margin: 0,
    fontSize: 16
  },
  count: {
    margin: 0,
    fontSize: 16
  },
  search: {
    position: "relative",
    display: "flex",
    justifyContent: "space-between",
    flexWrap: "nowrap",
    flex: 1,
    gap: 16,
    [theme.breakpoints.down(Breakpoints.SM_600)]: {
      position: "static"
    }
  },
  searchIcon: {
    color: theme.palette.text.secondary,
    fontSize: 18
  },
  searchInput: {
    padding: "0 16px 0 0",
    height: 40,
    fontSize: 14
  },
  searchResults: {
    background: theme.palette.common.white
  },
  searchInputBase: {
    width: "100%",
    "&.validationError .MuiOutlinedInput-root": {
      border: "1px solid red"
    }
  },
  searchHamburgerButton: {
    padding: 0,
    width: 44,
    height: 40,
    background: theme.palette.common.white,
    minWidth: 44,
    color: theme.palette.common.black,
    "&:hover": {
      background: theme.palette.common.black,
      color: theme.palette.common.white
    }
  },
  searchHamburgerButtonActive: {
    background: theme.palette.common.black,
    color: theme.palette.common.white
  },
  searchHamburgerIcon: {
    fontSize: 32
  },
  body: {
    position: "relative",
    display: "flex",
    flex: 1
  },
  resultsWrapper: {
    display: "flex",
    flexDirection: "column",
    flex: 1
  },
  entries: {
    backgroundColor: "#f6f6f6",
    padding: 16
  },
  entry: {
    display: "flex",
    marginBottom: 16,
    padding: "10px 8px 10px 16px",
    alignItems: "center",
    justifyContent: "space-between"
  },
  entryBody: {
    display: "flex",
    flex: 1,
    alignItems: "center"
  },
  entryBodyList: {
    flex: 1,
    listStyle: "none",
    padding: 0,
    margin: 0,
    "& > li": {
      lineHeight: "16px"
    }
  },
  entryTitle: {
    fontSize: 14,
    color: theme.palette.common.black
  },
  entrySubtitle: {
    fontSize: 12,
    color: theme.palette.primary.dark
  },
  entryDate: {
    "&:hover": {
      cursor: "pointer"
    }
  },
  entryDateInput: {
    padding: "3px 0 2px 4px",
    color: theme.palette.secondary.dark,
    fontSize: 11,
    textTransform: "none",
    lineHeight: "16px",
    "&:hover": {
      cursor: "pointer"
    },
    "&::placeholder": {
      color: theme.palette.secondary.dark,
      opacity: 1
    }
  },
  entryAddDateButton: {
    color: theme.palette.secondary.dark,
    fontSize: 11,
    textTransform: "none",
    lineHeight: "16px",
    margin: "0px -4px -2px -4px",
    padding: "2px 4px",
    "&:hover": {
      backgroundColor: "transparent"
    },
    "& .MuiButton-startIcon": {
      marginLeft: 0,
      marginRight: 6
    }
  },
  entryAddDateButtonIcon: {
    "&.MuiSvgIcon-root": {
      fontSize: 12
    }
  },
  entryEditDateButton: {
    color: theme.palette.common.black
  },
  entryRemoveButton: {
    color: "#333333",
    width: 32,
    height: 32
  },
  iconFocused: {
    color: theme.palette.text.primary
  },
  searchResultsEntry: {
    padding: "8px 28px",
    background: theme.palette.common.white,
    borderBottom: "1px solid #d7d7d7",
    borderRadius: 0,
    height: 80,
    width: "100%",
    lineHeight: "20px",
    alignItems: "start",
    "&:hover": {
      background: theme.palette.common.white
    },
    "& .MuiButton-label": {
      display: "flex",
      justifyContent: "space-between",
      alignItems: "center",
      textTransform: "none"
    },
    "&:nth-child(odd)": {
      background: "#fafafa",
      "&:hover": {
        background: "#fafafa"
      }
    }
  },
  searchResultsEntrySelected: {
    background: "#eff9f5",
    "&:hover": {
      background: "#e7f3ee"
    },
    "&:nth-child(odd)": {
      background: "#eff9f5",
      "&:hover": {
        background: "#e7f3ee"
      }
    }
  },
  searchResultsEntryAddButton: {
    "&$searchResultsEntry": {
      alignItems: "center",
      background: theme.palette.common.white,
      borderBottom: "none",
      height: 120,
      "& .MuiButton-label": {
        flexDirection: "column"
      },
      "&:hover": {
        background: "#fafafa"
      }
    }
  },
  searchResultsEntryButtonIcon: {
    fontSize: 20,
    marginBottom: 4
  },
  searchResultsNoResults: {
    paddingTop: 60,
    textAlign: "center",
    color: theme.palette.primary.dark
  },
  horseInfoWrapper: {
    display: "flex",
    flexDirection: "column",
    alignItems: "flex-start"
  },
  horseName: {
    fontFamily: "SuisseIntlMedium, SuisseIntlRegular",
    color: theme.palette.common.black
  },
  horseNumber: {
    color: theme.palette.primary.dark
  },
  horseTrainerName: {
    color: theme.palette.primary.dark
  },
  addEntryDialogPaper: {
    maxHeight: 355
  },
  addEntryDialogTitle: {
    display: "flex",
    padding: "0 8px 0 16px",
    borderBottom: "1px solid #d7d7d7",
    height: 48,
    "& > .MuiTypography-root": {
      display: "flex",
      justifyContent: "space-between",
      alignItems: "center",
      flex: 1
    }
  },
  addEntryDialogCloseButton: {
    color: theme.palette.primary.dark
  },
  addEntryDialogCloseButtonIcon: {
    fontSize: 28
  },
  addEntryDialogContent: {
    display: "flex",
    flexDirection: "column",
    gap: 20,
    padding: 16
  },
  addEntryDialogFormControl: {
    flex: "0 100%"
  },
  addEntryDialogInputLabel: {
    fontSize: 14,
    color: theme.palette.primary.dark,
    position: "relative",
    transform: "none",
    transformOrigin: "none",
    paddingBottom: 8,
    whiteSpace: "nowrap",
    "&.MuiFormLabel-root.Mui-focused": {
      color: theme.palette.primary.dark
    }
  },
  addEntryDialogInput: {
    padding: "0 16px",
    height: 36,
    fontSize: 12
  },
  addEntryDialogButtons: {
    display: "flex",
    justifyContent: "right",
    gap: 20
  },
  addEntryDialogButtonsAdd: {
    color: theme.palette.secondary.dark
  },
  clearSearchIcon: {
    color: theme.palette.common.black
  },
  customDateButton: {
    width: 36,
    height: 36,
    minWidth: 36,
    borderRadius: "50%",
    background: theme.palette.common.black,
    color: theme.palette.common.white,
    "&.MuiButton-root:hover": {
      background: theme.palette.common.black
    }
  },
  validationError: {
    fontSize: 11,
    color: theme.palette.error.main,
    marginTop: 12,
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    gap: 8,
    height: 24,
    "&.withResults": {
      marginTop: 28
    }
  },
  validationIcon: {
    width: 17,
    height: 17,
    position: "relative",
    bottom: 2
  }
}));
