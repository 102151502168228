import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import clsx from "clsx";
import { useHistory } from "react-router-dom";
import { useQueryClient } from "react-query";
import { useFormik } from "formik";
import isEqual from "lodash/isEqual";

import Grid from "@material-ui/core/Grid";
import Divider from "@material-ui/core/Divider";
import FormControl from "@material-ui/core/FormControl";
import Button from "@material-ui/core/Button";
import MenuItem from "@material-ui/core/MenuItem";
import Select from "@material-ui/core/Select";
import InputLabel from "@material-ui/core/InputLabel";
import Autocomplete from "@material-ui/lab/Autocomplete";
import TextField from "@material-ui/core/TextField";
import CircularProgress from "@material-ui/core/CircularProgress";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import { Theme } from "@material-ui/core/styles";
import MuiDialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";

import { racehorse360 } from "@tsg/1st-grpc-web";

import Loader from "components/Loader";
import { useSnackbar } from "components/SnackbarContext/SnackbarContext";
import { useLoggedInUser } from "components/LoggedInUserProvider";
import { useClickBlockerContext } from "components/BlockableClickContext";
import Dialog from "components/Dialog";
import NumberInput from "components/NumberInput";
import Textarea from "components/Textarea";
import Form from "components/Form";
import { validationSchemaWorkOrder } from "utils/validationSchemas";
import { useRacehorse360Api } from "hooks/api";
import Breakpoints from "common/breakpoints";
import {
  setActiveLink as setStoreActiveLink,
  setStoreActiveLinkCandidate as setStoreClickedLink
} from "store/actions/sidebar";
import { requiredWorkOrderOptions } from "../helper";
import { phoneNumberMask } from "common/constants";
import { highlightWithAsteriskIfError } from "utils/error-utils";
import useStyles from "./styles";

interface Props {
  isOpen: boolean;
  onClose: () => void;
  facilityName: string | undefined;
  facilityId: string | undefined;
  selectedWorkOrder?: racehorse360.IWorkOrder;
  onUpdateEditedWorkOrder?: (workOrder: racehorse360.IWorkOrder) => void;
  isEdit?: boolean;
}

const styleDialogLG = {
  left: 220,
  top: 64
};

const styleDialogSX = {
  left: 84,
  top: 64
};

const styleDialogXS = {
  left: 0,
  top: 0
};

const EMPTY_BARN = {
  id: "none",
  name: ""
};

const format = (value: string, pattern: string) => {
  let i = 0;

  return pattern.replace(/#/g, () => value[i++]);
};

const WorkOrderOverlayForm = React.memo((props: Props) => {
  const {
    isOpen,
    onClose,
    facilityId,
    facilityName,
    selectedWorkOrder,
    isEdit,
    onUpdateEditedWorkOrder
  } = props;

  const classes = useStyles();
  const queryClient = useQueryClient();
  const { currentUser } = useLoggedInUser();
  const { showSuccessSnack, showErrorSnack } = useSnackbar();
  const [showDiscardConfirmation, setShowDiscardConfirmation] =
    useState<boolean>(false);
  const [focused, setFocused] = useState<boolean>(false);
  const [isSubmitInProcess, setIsSubmitInProcess] = useState(false);

  const history = useHistory();
  const menuClickedLink = useSelector(
    (state: { sidebar }) => state?.sidebar.activeLinkCandidate
  );

  const dispatch = useDispatch();

  const initialWorkOrder:
    | racehorse360.ICreateWorkOrderRequest
    | racehorse360.IEditWorkOrderRequest =
    isEdit && selectedWorkOrder
      ? {
          workOrderTypeId: selectedWorkOrder.workOrderType.id,
          description: selectedWorkOrder.description,
          barnId: selectedWorkOrder.barn?.id,
          stallNumber: selectedWorkOrder.stallNumber,
          fullName: `${selectedWorkOrder.fullName}`,
          phone: format(selectedWorkOrder.phone, phoneNumberMask),
          email: selectedWorkOrder.email
        }
      : {
          workOrderTypeId: null,
          description: "",
          barnId: EMPTY_BARN.id,
          stallNumber: "",
          fullName: "",
          phone: "",
          email: ""
        };

  const isScreenLG = useMediaQuery((theme: Theme) =>
    theme.breakpoints.up(Breakpoints.LG_1280)
  );
  const isScreenSX = useMediaQuery((theme: Theme) =>
    theme.breakpoints.up(Breakpoints.SX_720)
  );
  const isScreenUpSM = useMediaQuery((theme: Theme) =>
    theme.breakpoints.up(Breakpoints.SM_600)
  );

  const { setBlockClick, setBlockClickCallback } = useClickBlockerContext();
  const {
    useCreateWorkOrder,
    useEditWorkOrder,
    useListBarns,
    useGetUser,
    useListWorkOrderTypes
  } = useRacehorse360Api();

  const { isLoading: isUserLoading, isFetching: isUserFetching } = useGetUser(
    {
      id: currentUser.rh360Id,
      getOptions: {
        select: ["firstName", "lastName", "phoneNumber", "email"]
      }
    },
    {
      enabled: !!currentUser?.rh360Id && !isEdit,
      onSuccess: data => {
        const userFirstName = data?.firstName || "";
        const userLastName = data?.lastName || "";
        const userFullName = `${userFirstName} ${userLastName}`;

        formik
          .setValues(
            values => ({
              ...values,
              fullName: userFullName || initialWorkOrder.fullName,
              email: data?.email || initialWorkOrder.email,
              phone: data.phoneNumber || initialWorkOrder.phone
            }),
            true
          )
          .catch(error => {
            console.error(
              error?.message ||
                "There was an error attempting to auto-populate contact info in a work order."
            );
          });
      },
      onError: error => console.error(error)
    }
  );

  const { data: listWorkOrderTypes, isLoading: isWorkOrderTypesLoading } =
    useListWorkOrderTypes(
      {
        query: {},
        getOptions: { select: ["id", "name"] },
        pagingOptions: {
          maxResults: 999
        }
      },
      {
        onError: error => {
          console.error(
            error?.message ||
              "There was an error attempting to request list of workorder types."
          );
        }
      }
    );

  const workOrderTypes: racehorse360.IWorkOrderType[] | [] =
    listWorkOrderTypes?.workOrderTypes.sort((wot1, wot2) =>
      wot1.name.localeCompare(wot2.name)
    );

  const { data: listBarns, isLoading: isBarnsLoading } = useListBarns(
    {
      query: { facilityId },
      getOptions: { select: ["name"] },
      pagingOptions: {
        maxResults: 999
      }
    },
    {
      onError: error => {
        console.error(
          error?.message ||
            "There was an error attempting to request list of barns."
        );
      }
    }
  );

  const barns: racehorse360.IBarn[] | [] = [
    EMPTY_BARN,
    ...(listBarns?.barns.sort(
      (b1, b2) => Number.parseInt(b1.name) - Number.parseInt(b2.name)
    ) || [])
  ];

  const { mutateAsync: createWorkOrder, isLoading: isWorkOrderSubmitting } =
    useCreateWorkOrder();
  const { mutateAsync: editWorkOrder, isLoading: isWorkOrderUpdateSubmitting } =
    useEditWorkOrder();

  const isSubmitting = isWorkOrderSubmitting || isWorkOrderUpdateSubmitting;

  const resetFields = () => {
    formik.resetForm({
      values: { ...initialWorkOrder }
    });
  };

  const handleFormSubmit = (
    values: racehorse360.ICreateWorkOrderRequest,
    { resetForm }
  ) => {
    if (!isSubmitInProcess) {
      setIsSubmitInProcess(true);
      let submitWorkOrder = createWorkOrder;
      const phone = values.phone ? values.phone.replace(/\D/g, "") : null;
      const barnId =
        values.barnId && values.barnId !== EMPTY_BARN.id
          ? values.barnId
          : undefined;
      let newWorkOrder = {
        ...values,
        id: selectedWorkOrder?.id,
        workOrderTypeId: values.workOrderTypeId,
        barnId,
        facilityId: facilityId || null,
        phone,
        stallNumber: values.stallNumber || null
      };

      if (isEdit) {
        if (!isEqual(initialWorkOrder, { ...values, phone })) {
          submitWorkOrder = editWorkOrder;
          newWorkOrder = {
            ...newWorkOrder,
            getOptions: { ...requiredWorkOrderOptions }
          };
        } else {
          resetForm();
          onClose();
          return;
        }
      }

      submitWorkOrder(newWorkOrder)
        .then(async workOrder => {
          const promises = [
            queryClient.invalidateQueries(`${currentUser.rh360Id}-work-orders`),
            queryClient.invalidateQueries(
              `${currentUser.rh360Id}-infinite-work-orders`
            )
          ];
          if (selectedWorkOrder?.id) {
            promises.push(
              queryClient.invalidateQueries(
                `${selectedWorkOrder.id}-list-notes`
              )
            );
          }

          try {
            await Promise.all(promises);

            return workOrder;
          } catch (error) {
            throw new Error(error);
          }
        })
        .then(workOrder => {
          setIsSubmitInProcess(false);
          onUpdateEditedWorkOrder && onUpdateEditedWorkOrder(workOrder);
          resetForm();
          onClose();
          showSuccessSnack(
            isEdit
              ? "Work order changes have been saved."
              : "Your work order request has been submitted."
          );
        })
        .catch(error => {
          showErrorSnack(
            isEdit
              ? "Changes could not be saved, please try again."
              : "Work order failed to submit, please try again."
          );
          console.error(
            error?.message ||
              "There was an error attempting to create a work order."
          );
        });
    }
  };

  const formik = useFormik({
    initialValues: initialWorkOrder,
    onSubmit: handleFormSubmit,
    validationSchema: validationSchemaWorkOrder
  });
  const values = formik.values;
  const handleChange = formik.handleChange;
  const handleBlur = formik.handleBlur;
  const touchedField = formik.touched;
  const errors = formik.errors;

  const hasChanges = Object.keys(formik.initialValues).some(
    key =>
      String(formik.initialValues[key]).trim() !==
      String(formik.values[key]).trim()
  );

  const phoneLabel = highlightWithAsteriskIfError(
    "Phone Number",
    Boolean(!values.phone)
  );
  const emailAddressLabel = highlightWithAsteriskIfError(
    "Email Address",
    Boolean(!values.email)
  );
  const fullNameLabel = highlightWithAsteriskIfError(
    "Full Name",
    Boolean(!values.fullName)
  );

  const handleCancelClick = () => {
    unlockSideBarNavigation();
    resetFields();
    onClose();
  };

  const handleCloseOverlay = (event: React.MouseEvent, reason: string) => {
    if (hasChanges) {
      setShowDiscardConfirmation(true);
    } else {
      unlockSideBarNavigation();
      resetFields();

      if (reason !== "backdropClick" && onClose) {
        onClose();
      }
    }
  };

  const handleBarnChange = (
    event: React.ChangeEvent,
    value: racehorse360.IBarn
  ) => {
    formik
      .setFieldValue("barnId", value?.id ? value.id : value)
      .catch(error => console.error(error));
  };

  const handleSubmitClick = () => {
    if (!(formik.isValid && formik.dirty)) {
      const requiredFields = {
        workOrderType: true,
        description: true,
        fullName: true,
        phone: true,
        email: true
      };

      formik.setTouched(requiredFields).catch(error => console.error(error));
    }
  };

  const handleIssueDescriptionFocus = () => {
    setFocused(true);
  };

  const handleIssueDescriptionBlur = (event: React.ChangeEvent) => {
    handleBlur(event);
    setFocused(false);
  };

  const handleClickStayContinue = () => {
    setShowDiscardConfirmation(false);
    menuClickedLink && dispatch(setStoreClickedLink(""));
  };

  const handleClickLeaveDiscard = () => {
    unlockSideBarNavigation();
    resetFields();
    setShowDiscardConfirmation(false);
    onClose();

    if (menuClickedLink) {
      dispatch(setStoreActiveLink(menuClickedLink));
      history.push(menuClickedLink);
      dispatch(setStoreClickedLink(""));
    }
  };

  const lockSideBarNavigation = () => {
    setBlockClick(true);
    setBlockClickCallback(() => () => handleCloseOverlay(null, null));
  };

  const unlockSideBarNavigation = () => {
    setBlockClick(false);
    setBlockClickCallback(null);
  };

  const getDialogStyle = () => {
    if (isScreenLG || isScreenSX) {
      return isScreenLG ? styleDialogLG : styleDialogSX;
    }

    return styleDialogXS;
  };

  useEffect(() => {
    if (hasChanges) {
      lockSideBarNavigation();
    } else {
      unlockSideBarNavigation();
    }
  }, [hasChanges]);

  const renderForm = () => {
    return (
      <Form onSubmit={formik.handleSubmit}>
        <Grid container className={classes.formContent}>
          <Grid item xs={12} sm={8} md={6} className={classes.workOrderSection}>
            <div className={classes.workOrderFieldLabel}>Work Order Type</div>
            {isWorkOrderTypesLoading ? (
              <Loader size={24} className={classes.barnsLoader} />
            ) : (
              <>
                <FormControl variant="outlined">
                  <InputLabel
                    id="typeLabel"
                    className={classes.formLabel}
                    shrink={false}
                  >
                    {!values.workOrderTypeId ? "Select Order Type*" : ""}
                  </InputLabel>
                  <Select
                    className={classes.workOrderTypeSelect}
                    name="workOrderTypeId"
                    labelId="typeLabel"
                    displayEmpty
                    defaultValue={""}
                    value={values.workOrderTypeId || ""}
                    onChange={handleChange}
                    onBlur={handleBlur}
                  >
                    {workOrderTypes.map(
                      (workOrderType: racehorse360.IWorkOrderType) => (
                        <MenuItem
                          key={workOrderType.id}
                          value={workOrderType.id}
                          className={classes.formSelectItem}
                        >
                          {workOrderType.name}
                        </MenuItem>
                      )
                    )}
                  </Select>
                </FormControl>
              </>
            )}
          </Grid>
          <Divider className={classes.divider} />

          <Grid item xs={12} sm={8} md={6} className={classes.workOrderSection}>
            <div className={classes.workOrderFieldLabel}>Issue Description</div>
            <div className={classes.workOrderFieldTip}>
              Please describe what needs repaired in as much detail as you can.
            </div>
            <FormControl>
              <Textarea
                name="description"
                className={classes.textareaIssueDescription}
                classNameCounter={clsx(classes.counter, {
                  [classes.counterRequired]:
                    touchedField.description && errors.description
                })}
                placeholder={"Add a detailed description*"}
                isFormikUsed
                maxLength={1500}
                rows={isScreenUpSM ? 4 : 6}
                showCounter={
                  (isScreenUpSM && focused) ||
                  (isScreenUpSM && !!values.description)
                }
                enteredValue={values.description || ""}
                onChange={handleChange}
                onBlur={handleIssueDescriptionBlur}
                onFocus={handleIssueDescriptionFocus}
                helperText={
                  (touchedField.description && errors.description) || ""
                }
                error={touchedField.description && !!errors.description}
              />
            </FormControl>

            <div className={classes.textFieldsRow}>
              {isBarnsLoading ? (
                <Loader size={24} className={classes.barnsLoader} />
              ) : (
                <>
                  <FormControl>
                    <Autocomplete
                      id="barnId"
                      size="small"
                      value={
                        values.barnId
                          ? barns.find(b => b.id === values.barnId)
                          : barns.find(b => b.id === initialWorkOrder.barnId) ||
                            EMPTY_BARN
                      }
                      className={classes.autocomplete}
                      classes={{
                        option: classes.autocompleteOption,
                        noOptions: classes.autocompleteNoOptions,
                        popper: classes.autocompletePopper
                      }}
                      options={barns}
                      onChange={handleBarnChange}
                      getOptionLabel={option => option?.name || ""}
                      disableClearable
                      renderInput={params => (
                        <TextField
                          {...params}
                          label="Barn #"
                          variant="outlined"
                          helperText="Optional"
                          name="barnId"
                        />
                      )}
                    />
                  </FormControl>
                  <FormControl>
                    <TextField
                      id="stall"
                      label="Stall #"
                      variant="outlined"
                      name="stallNumber"
                      className={classes.stallTextField}
                      helperText={errors.stallNumber || "Optional"}
                      value={values.stallNumber}
                      onChange={handleChange}
                      onBlur={handleBlur}
                      inputProps={{
                        maxLength: 5
                      }}
                      error={!!errors.stallNumber}
                    />
                  </FormControl>
                </>
              )}
            </div>
          </Grid>
          <Divider className={classes.divider} />

          <Grid item xs={12} sm={8} md={6} className={classes.workOrderSection}>
            <div className={classes.workOrderFieldLabel}>
              Contact Information
            </div>
            {renderContactFields()}
          </Grid>
          <Divider className={classes.dividerInvisible} />

          <Grid
            item
            xs={12}
            sm={8}
            md={6}
            className={classes.workOrderSectionButtons}
          >
            <Button
              variant="outlined"
              className={classes.cancelButton}
              onClick={handleCancelClick}
            >
              CANCEL
            </Button>
            <Button
              //submit see in handleFormSubmit
              variant="outlined"
              className={clsx(classes.submitButton, {
                [classes.disabledButton]: !(formik.isValid && formik.dirty)
              })}
              type="submit"
              onClick={handleSubmitClick}
            >
              {isSubmitting ? (
                <CircularProgress size={20} />
              ) : (
                <span>{isEdit ? "SAVE" : "SUBMIT WORK ORDER"}</span>
              )}
            </Button>
          </Grid>
        </Grid>
      </Form>
    );
  };

  const renderContactFields = () => {
    const inputLoader = {
      startAdornment: (
        <>
          {isUserLoading || isUserFetching ? (
            <CircularProgress size={20} />
          ) : null}
        </>
      )
    };

    return (
      <>
        <div className={classes.textFieldsContacts}>
          <FormControl className={classes.formControlContacts}>
            <TextField
              label={fullNameLabel}
              variant="outlined"
              autoComplete="off"
              id="fullName"
              name="fullName"
              value={values.fullName}
              onChange={handleChange}
              onBlur={handleBlur}
              inputProps={{
                maxLength: 65
              }}
              InputProps={inputLoader}
              error={touchedField.fullName && Boolean(errors.fullName)}
              helperText={(touchedField.fullName && errors.fullName) || ""}
            />
          </FormControl>

          <FormControl className={classes.formControlContacts}>
            <TextField
              label={emailAddressLabel}
              variant="outlined"
              autoComplete="off"
              id="email"
              name="email"
              value={values.email}
              onChange={handleChange}
              onBlur={handleBlur}
              InputProps={inputLoader}
              error={touchedField.email && Boolean(errors.email)}
              helperText={(touchedField.email && errors.email) || ""}
            />
          </FormControl>
        </div>

        <FormControl className={classes.formControlContacts}>
          <NumberInput
            className={classes.numberInput}
            mask={phoneNumberMask}
            label={phoneLabel}
            variant="outlined"
            outlined
            autoComplete="off"
            id="phone"
            name="phone"
            value={values.phone}
            onTextChange={handleChange("phone")}
            onChange={handleChange}
            onBlur={handleBlur}
            InputProps={inputLoader}
            error={touchedField.phone && Boolean(errors.phone)}
            helperText={(touchedField.phone && errors.phone) || ""}
          />
        </FormControl>
      </>
    );
  };

  const dialogTitle =
    isEdit && selectedWorkOrder
      ? `Edit Work Order #${selectedWorkOrder.customerFacingId}`
      : "New Work Order";

  return (
    <Dialog
      title={dialogTitle}
      subtitle={facilityName?.toLowerCase() || ""}
      onClose={handleCloseOverlay}
      aria-labelledby="new-work-order"
      className={classes.overlay}
      disableEscapeKeyDown
      style={getDialogStyle()}
      isOpen={isOpen}
    >
      {renderForm()}
      {showDiscardConfirmation && (
        <MuiDialog
          className={classes.discardDialog}
          open={showDiscardConfirmation}
        >
          <DialogTitle className={classes.discardDialogTitle}>
            Discard Work Order?
          </DialogTitle>
          <DialogContent>
            This work order has not been submitted. Are you sure you want to
            leave and discard this work order?
          </DialogContent>
          <DialogActions>
            <div>
              <Button
                className={classes.buttonLeaveDiscard}
                size="medium"
                onClick={handleClickLeaveDiscard}
              >
                LEAVE & DISCARD
              </Button>
              <Button
                size="medium"
                className={classes.buttonStayContinue}
                onClick={handleClickStayContinue}
              >
                STAY & CONTINUE
              </Button>
            </div>
          </DialogActions>
        </MuiDialog>
      )}
    </Dialog>
  );
});

export default WorkOrderOverlayForm;
