import React from "react";
import clsx from "clsx";
import AutoSizer from "react-virtualized-auto-sizer";

import useMediaQuery from "@material-ui/core/useMediaQuery";
import TableSortLabel from "@material-ui/core/TableSortLabel";
import Box from "@material-ui/core/Box";
import ArrowDropUpIcon from "@material-ui/icons/ArrowDropUp";
import ArrowDropDownIcon from "@material-ui/icons/ArrowDropDown";
import useTheme from "@material-ui/core/styles/useTheme";

import { racehorse360 } from "@tsg/1st-grpc-web";

import routes from "common/routes";
import AbcCircle from "components/AbcCircle";
import AppPageTable from "components/AppPageTable";
import AppPageTableHeader from "components/AppPageTableHeader";
import AppPageTableContent from "components/AppPageTableContent";
import DaysOffDiagram from "components/DaysOffDiagram";
import Link from "components/Link";
import { useLoggedInUser } from "components/LoggedInUserProvider";
import VirtualList from "components/VirtualList";
import { useRacehorse360Api } from "hooks/api";
import { differenceInDaysOrNA } from "utils/date-utils";
import { getPersonFullName } from "utils/person";
import { checkSortingType } from "utils/sort-utils";
import {
  SortOrderType,
  SortOrderExtended,
  SortOrder
} from "interfaces/SortOrder";
import useStyles from "./styles";

export enum SortByFields {
  NAME = "name",
  TRAINER_FIRSTNAME = "trainer.firstName",
  TRAINER_LASTNAME = "trainer.lastName",
  HEALTH_WATCH_STATUS = "healthWatchStatus",
  HIGH_SPEED_FURLONGS = "highSpeedFurlongs",
  LAST_RACE_DATE = "lastRaceDate",
  LAST_WORK_DATE = "lastWorkDate",
  RISK_FACTORS = "riskFactorsCount"
  //Barn-Stall column is ready but still have no stall info. TBU
  // BARN = "barn.name",
  // STALL = "stall.name"
}

interface Cell {
  id: string;
  label: string;
  type?: racehorse360.HorseDetailPageDataColumn;
  orderBy: string[];
  align?: "left" | "right" | "center";
  className?: string;
  desktopOnly?: boolean;
  additionalClassName?: string;
  render: (isXlScreen?: boolean) => React.ReactNode;
}

export interface Props {
  onSortClick: (orderBy, order) => void;
  horses: racehorse360.IHorse[];
  order: SortOrderType;
  orderBy: string[];
  tableBodyRef: React.RefObject<HTMLDivElement>;
}

const HorseList = React.memo((props: Props) => {
  const { onSortClick, horses, order, orderBy, tableBodyRef } = props;
  const classes = useStyles();
  const theme = useTheme();
  const isXlScreen = useMediaQuery(theme.breakpoints.up("xl"), {
    noSsr: true
  });
  const matchesDownSm = useMediaQuery(theme.breakpoints.down("sm"), {
    noSsr: true
  });
  const { currentUser } = useLoggedInUser();
  const { useGetUser } = useRacehorse360Api();

  const { data } = useGetUser(
    `${currentUser.rh360Id}-user-settings`,
    {
      id: currentUser.rh360Id,
      getOptions: {
        select: ["preferences.horseDetailPageDataColumn"]
      }
    },
    {
      onError: error => {
        console.error(error);
      },
      enabled: matchesDownSm
    }
  );
  const horseDetailPageDataColumn =
    data?.preferences?.horseDetailPageDataColumn;

  // const getBarnStall = (horse: racehorse360.IHorse) => {
  //   if (horse.barn?.name) {
  //     if (horse.stall?.name) {
  //       return `${horse.barn.name}-${horse.stall.name}`;
  //     } else {
  //       return `${horse.barn.name}`;
  //     }
  //   }
  //   return "N/A";
  // };

  const getCells = (horse: racehorse360.IHorse): Cell[] => {
    return [
      {
        id: "name",
        label: "Horse",
        orderBy: [SortByFields.NAME],
        className: classes.tableCellLink,
        additionalClassName: classes.horseName,
        render: () => <span>{horse.name}</span>
      },
      {
        id: "trainer.firstName",
        label: "Trainer",
        orderBy: [
          SortByFields.TRAINER_LASTNAME,
          SortByFields.TRAINER_FIRSTNAME
        ],
        className: classes.tableCellLink,
        render: () => (
          <>
            <span className={classes.trainerName}>
              {getPersonFullName(horse.trainer, "%L %M %F")}
            </span>
            <span className={classes.trainerNameShort}>
              {getPersonFullName(horse.trainer, "%L %m %f")}
            </span>
          </>
        )
      },
      {
        id: "healthWatchStatus",
        label: "Risk Level",
        type: racehorse360.HorseDetailPageDataColumn
          .HORSE_DETAIL_PAGE_DATA_COLUMN_RISK_LEVEL,
        orderBy: [SortByFields.HEALTH_WATCH_STATUS],
        className: classes.tableCellLink,
        additionalClassName: classes.centerAligning,
        desktopOnly: true,
        render: () => <AbcCircle healthWatchStatus={horse.healthWatchStatus} />
      },
      {
        id: "highSpeedFurlongs",
        label: "HSF",
        type: racehorse360.HorseDetailPageDataColumn
          .HORSE_DETAIL_PAGE_DATA_COLUMN_HIGH_SPEED_FURLONGS,
        orderBy: [SortByFields.HIGH_SPEED_FURLONGS],
        className: classes.tableCellLink,
        additionalClassName: classes.centerAligning,
        desktopOnly: true,
        render: () => <>{horse.highSpeedFurlongs}</>
      },
      {
        id: "riskFactorsCount",
        label: "Risk Factors",
        type: racehorse360.HorseDetailPageDataColumn
          .HORSE_DETAIL_PAGE_DATA_COLUMN_RISK_FACTORS,
        orderBy: [SortByFields.RISK_FACTORS],
        className: classes.tableCellLink,
        additionalClassName: classes.centerAligning,
        desktopOnly: true,
        render: () => <>{horse.riskFactorsCount}</>
      },
      // {
      //   id: "barn.name",
      //   label: "Barn-Stall",
      //   type: racehorse360.HorseDetailPageDataColumn
      //     .HORSE_DETAIL_PAGE_DATA_COLUMN_BARN_STALL,
      //   orderBy: [SortByFields.BARN, SortByFields.STALL],
      //   align: "center",
      //   desktopOnly: true,
      //   className: classes.tableCellLink,
      //   additionalClassName: classes.centerAligning,
      //   render: () => <>{getBarnStall(horse)}</>
      // },
      {
        id: "lastRaceDate",
        label: matchesDownSm ? "DLR" : "Days Last Race",
        type: racehorse360.HorseDetailPageDataColumn
          .HORSE_DETAIL_PAGE_DATA_COLUMN_DAYS_LAST_RACE,
        orderBy: [SortByFields.LAST_RACE_DATE],
        className: classes.tableCellLink,
        additionalClassName: classes.centerAligning,
        desktopOnly: true,
        render: () => <>{differenceInDaysOrNA(horse.lastRaceDate)}</>
      },
      {
        id: "lastWorkDate",
        label: matchesDownSm ? "DLW" : "Days Last Work",
        type: racehorse360.HorseDetailPageDataColumn
          .HORSE_DETAIL_PAGE_DATA_COLUMN_DAYS_LAST_WORK,
        orderBy: [SortByFields.LAST_WORK_DATE],
        className: classes.tableCellLink,
        additionalClassName: classes.daysOffColumn,
        desktopOnly: true,
        render: isLineDiagram => (
          <DaysOffDiagram
            horse={horse}
            className={classes.daysOffDiagram}
            type={isLineDiagram ? "line" : "donut"}
            simplified
          />
        )
      }
    ];
  };

  const createSortHandler =
    (orderBy: string[]) => (event: React.MouseEvent<unknown>) => {
      handleSortClick(event, orderBy);
    };

  const handleSortClick = (
    event: React.MouseEvent<unknown>,
    newOrderBy: string[]
  ) => {
    const { isOneOfAsc } = checkSortingType(order);
    const isAscDirection =
      orderBy.toString() === newOrderBy.toString() && isOneOfAsc;
    let newOrder: SortOrderType;

    switch (newOrderBy.toString()) {
      case SortByFields.HEALTH_WATCH_STATUS:
        newOrder = isAscDirection
          ? SortOrderExtended.DESC_NULLS_LAST
          : SortOrderExtended.ASC_NULLS_FIRST;
        break;
      case SortByFields.LAST_WORK_DATE:
        newOrder = isAscDirection
          ? SortOrderExtended.DESC_NULLS_FIRST
          : SortOrderExtended.ASC_NULLS_LAST;
        break;
      case SortByFields.LAST_RACE_DATE:
        newOrder = isAscDirection
          ? SortOrderExtended.DESC_NULLS_FIRST
          : SortOrderExtended.ASC_NULLS_LAST;
        break;
      default:
        newOrder = isAscDirection ? SortOrder.DESC : SortOrder.ASC;
    }

    onSortClick(newOrderBy, newOrder);
  };

  const renderHeadCell = (headCell, forceDisplay = false) => {
    if (!headCell) {
      return <></>;
    }
    const isActive = orderBy.includes(headCell.id);
    let isActiveAsc: boolean;
    let isActiveDesc: boolean;

    if (isActive) {
      const orderBy = headCell.orderBy[0];

      switch (orderBy) {
        case SortByFields.LAST_WORK_DATE:
          isActiveAsc = order === SortOrderExtended.DESC_NULLS_FIRST;
          isActiveDesc = order === SortOrderExtended.ASC_NULLS_LAST;
          break;
        case SortByFields.LAST_RACE_DATE:
          isActiveAsc = order === SortOrderExtended.DESC_NULLS_FIRST;
          isActiveDesc = order === SortOrderExtended.ASC_NULLS_LAST;
          break;
        case SortByFields.HEALTH_WATCH_STATUS:
          isActiveAsc = order === SortOrderExtended.ASC_NULLS_FIRST;
          isActiveDesc = order === SortOrderExtended.DESC_NULLS_LAST;
          break;
        default:
          isActiveAsc = order === SortOrder.ASC;
          isActiveDesc = order === SortOrder.DESC;
      }
    }

    return (
      <Box
        className={clsx(classes.tableHeaderCell, {
          [classes.hideOnTablet]: headCell.desktopOnly && !forceDisplay
        })}
        key={headCell.id}
      >
        <TableSortLabel
          active={isActive}
          onClick={createSortHandler(headCell.orderBy)}
          hideSortIcon
        >
          {headCell.label}
          <Box className={classes.icons}>
            <Box
              className={clsx(classes.iconUp, {
                [classes.iconSort]: isActiveAsc
              })}
            >
              <ArrowDropUpIcon />
            </Box>
            <Box
              className={clsx(classes.iconDown, {
                [classes.iconSort]: isActiveDesc
              })}
            >
              <ArrowDropDownIcon />
            </Box>
          </Box>
        </TableSortLabel>
      </Box>
    );
  };

  const renderCells = () => {
    return getCells({}).map(cell => renderHeadCell(cell));
  };

  const renderRows = () =>
    horses.map((horse, index) => {
      const cells = getCells(horse);
      const additionalCell = horseDetailPageDataColumn
        ? cells.find(c => String(c.type) === String(horseDetailPageDataColumn))
        : null;
      return (
        <Link
          to={routes.horseDetails.path.replace(":horseId", horse.id)}
          className={clsx(
            classes.tableBodyRow,
            index % 2 ? classes.rowOdd : classes.rowEven,
            {
              [classes.customizedForMobile]: Boolean(horseDetailPageDataColumn)
            }
          )}
          key={horse.id}
          dataTestLabel={"vet-horse-list-item"}
        >
          {cells.map(cell => (
            <div
              role="cell"
              key={horse.id + cell.id}
              className={clsx(cell.className, cell.additionalClassName, {
                [classes.hideOnTablet]: cell.desktopOnly
              })}
            >
              {cell.id === SortByFields.LAST_WORK_DATE
                ? cell.render(isXlScreen)
                : cell.render()}
            </div>
          ))}
          {matchesDownSm && additionalCell ? (
            <div
              role="cell"
              key={horse.id + additionalCell.id}
              className={clsx(
                additionalCell.className,
                additionalCell.additionalClassName
              )}
            >
              {additionalCell.id === SortByFields.LAST_WORK_DATE
                ? additionalCell.render(isXlScreen)
                : additionalCell.render()}
            </div>
          ) : (
            <></>
          )}
        </Link>
      );
    });

  return (
    <AppPageTable>
      <AppPageTableHeader
        className={clsx(classes.appPageTableHeader, {
          [classes.customizedForMobile]: Boolean(horseDetailPageDataColumn)
        })}
        aria-label="enhanced table"
        aria-labelledby="tableTitle"
      >
        {renderCells()}
        {matchesDownSm &&
          horseDetailPageDataColumn &&
          renderHeadCell(
            getCells({}).find(
              c => String(c.type) === String(horseDetailPageDataColumn)
            ),
            true
          )}
      </AppPageTableHeader>

      <AppPageTableContent>
        <AutoSizer>
          {({ height, width }) => (
            <VirtualList
              className={classes.listVirtualized}
              itemClassName={classes.listVirtualizedItem}
              count={horses.length}
              itemHeight={55}
              height={height}
              width={width}
              scrollContainerRef={tableBodyRef}
              itemsBeyond={4}
            >
              {renderRows()}
            </VirtualList>
          )}
        </AutoSizer>
      </AppPageTableContent>
    </AppPageTable>
  );
});

export default HorseList;
