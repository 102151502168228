import React, { useState, useEffect } from "react";
import clsx from "clsx";
import Button from "@material-ui/core/Button";
import Paper from "@material-ui/core/Paper";
import { Theme } from "@material-ui/core/styles";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import Launch from "@material-ui/icons/Launch";
import WarningIcon from "@material-ui/icons/Warning";

import { racehorse360 } from "@tsg/1st-grpc-web";
import DaysOffDiagram from "components/DaysOffDiagram";
import Link from "components/Link";
import { useLoggedInUser } from "components/LoggedInUserProvider";
import WorkoutRequiredDialogDaysOff from "components/WorkoutRequiredDialogDaysOff";
import { getAlertStatus, AlertEnum, getWorkDaysOff } from "utils/horse";
import routes from "common/routes";
import useStyles from "./styles";

export interface Props {
  className?: string;
  horse: racehorse360.IHorse;
  showViewHorseButton?: boolean;
}

const DaysOff = (props: Props) => {
  const { className = "", horse, showViewHorseButton = true } = props;
  const classes = useStyles();
  const { currentUser } = useLoggedInUser();
  const [workOff, setWorkOff] = useState<string | number>(0);
  const [status, setStatus] = useState<AlertEnum>(AlertEnum.non);
  const [openWarning, setOpenWarning] = useState<boolean>(false);
  const isScreenSmall = useMediaQuery((theme: Theme) =>
    theme.breakpoints.down("sm")
  );
  const redLimit = horse.currentFacility?.workoutRequiredDaysOff || 90;
  const horseWorkoutsPath = routes.horseWorkouts.path.replace(
    ":horseId",
    String(horse.id)
  );

  useEffect(() => {
    const workOff = getWorkDaysOff(horse);
    const status = getAlertStatus(
      Number.isInteger(workOff) ? Number(workOff) : Number.NaN,
      redLimit
    );
    setWorkOff(workOff);
    setStatus(status);
  }, [horse.lastRaceDate, horse.lastWorkoutDate]);

  const handleContentClick = () => {
    if (!horse.dateOfDeath) {
      setOpenWarning(true);
    }
  };

  const handleWarningClose = () => {
    setOpenWarning(false);
  };

  return (
    <>
      <Paper className={clsx(classes.root, className, classes.extended)}>
        <div
          className={clsx(classes.content, {
            [classes.nonPointer]: horse.dateOfDeath
          })}
          onClick={handleContentClick}
        >
          <div
            className={clsx(classes.info, `${className}__info`, {
              [classes.infoTall]: currentUser.isVeterinarian
            })}
          >
            {isScreenSmall &&
              !horse.dateOfDeath &&
              !currentUser.isVeterinarian && (
                <Link className={classes.schedulingLink} to={horseWorkoutsPath}>
                  Schedule Workout{" "}
                  <Launch className={classes.schedulingLinkIcon} />
                </Link>
              )}
            <span className={clsx(classes.title, `${className}__title`)}>
              {"Race / Work"}
            </span>
            {(currentUser.isTrainer || currentUser.isRacingOfficial) && (
              <span
                className={clsx(
                  classes.warning,
                  status,
                  `${className}__warning`
                )}
              >
                <WarningIcon className={clsx(classes.warningIcon, status)} />
              </span>
            )}
          </div>

          <DaysOffDiagram
            horse={horse}
            className={clsx(classes.daysOffDiagram, {
              [classes.daysOffDiagramEnhanced]: !isScreenSmall
            })}
            type={isScreenSmall ? "line" : "text"}
            variant={isScreenSmall ? "bold" : "default"}
          />
        </div>
        {!isScreenSmall && !currentUser.isVeterinarian && (
          <>
            <Button
              component={Link}
              to={horseWorkoutsPath}
              className={clsx(classes.schedulingButton, status)}
              disabled={Boolean(horse.dateOfDeath)}
            >
              Schedule Workout
            </Button>
          </>
        )}
      </Paper>

      <WorkoutRequiredDialogDaysOff
        open={openWarning}
        daysOff={workOff}
        horse={horse}
        status={status}
        limit={redLimit}
        showViewHorseButton={showViewHorseButton}
        onClose={handleWarningClose}
      />
    </>
  );
};

export default DaysOff;
