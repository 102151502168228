import React, { useState } from "react";
import { useQueryClient } from "react-query";
import FormControl from "@material-ui/core/FormControl";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Radio from "@material-ui/core/Radio";
import RadioGroup from "@material-ui/core/RadioGroup";
import Typography from "@material-ui/core/Typography";

import { racehorse360 } from "@tsg/1st-grpc-web";
import Loader from "components/Loader";
import { useLoggedInUser } from "components/LoggedInUserProvider";
import SettingsPopover from "components/SettingsPopover";
import { useRacehorse360Api } from "hooks/api";
import useStyles from "./styles";

export const getHorseListSettingsQuery = userId => ({
  key: `${userId}-user-settings`,
  body: {
    id: userId,
    getOptions: {
      select: ["preferences.horseDetailPageDataColumn"]
    }
  }
});

const columns = [
  {
    key: `${racehorse360.HorseDetailPageDataColumn.HORSE_DETAIL_PAGE_DATA_COLUMN_RISK_LEVEL}`,
    label: "Risk Level"
  },
  {
    key: `${racehorse360.HorseDetailPageDataColumn.HORSE_DETAIL_PAGE_DATA_COLUMN_HIGH_SPEED_FURLONGS}`,
    label: "High Speed Furlongs"
  },
  {
    key: `${racehorse360.HorseDetailPageDataColumn.HORSE_DETAIL_PAGE_DATA_COLUMN_RISK_FACTORS}`,
    label: "Risk Factors"
  },
  {
    key: `${racehorse360.HorseDetailPageDataColumn.HORSE_DETAIL_PAGE_DATA_COLUMN_VET_LIST}`,
    label: "Vet List"
  },
  {
    key: `${racehorse360.HorseDetailPageDataColumn.HORSE_DETAIL_PAGE_DATA_COLUMN_AGE_SEX_COLOR}`,
    label: "Age - Sex - Color"
  },
  {
    key: `${racehorse360.HorseDetailPageDataColumn.HORSE_DETAIL_PAGE_DATA_COLUMN_BARN_STALL}`,
    label: "Barn - Stall"
  },
  {
    key: `${racehorse360.HorseDetailPageDataColumn.HORSE_DETAIL_PAGE_DATA_COLUMN_DAYS_LAST_RACE}`,
    label: "Days Last Race"
  },
  {
    key: `${racehorse360.HorseDetailPageDataColumn.HORSE_DETAIL_PAGE_DATA_COLUMN_DAYS_LAST_WORK}`,
    label: "Days Last Work"
  }
];

const HorseListSettings = () => {
  const classes = useStyles();
  const queryClient = useQueryClient();
  const { currentUser } = useLoggedInUser();
  const [column, setColumn] = useState<string>(
    String(
      racehorse360.HorseDetailPageDataColumn
        .HORSE_DETAIL_PAGE_DATA_COLUMN_INVALID
    )
  );

  const { useUpdateUserPreferences, useGetUser } = useRacehorse360Api();
  const query = getHorseListSettingsQuery(currentUser.rh360Id);

  const { data, isLoading: isPreferencesLoading } = useGetUser(
    query.key,
    query.body,
    {
      onError: error => {
        console.error(error);
      },
      onSuccess: data => {
        const storedColumn = data?.preferences?.horseDetailPageDataColumn;
        if (storedColumn) {
          setColumn(String(storedColumn));
        }
      }
    }
  );
  const preferences = data?.preferences;

  const { mutateAsync: updatePreferences, isLoading: isUpdating } =
    useUpdateUserPreferences();
  const isLoading =
    isUpdating ||
    isPreferencesLoading ||
    queryClient.getQueryState([Object.values(query)])?.isFetching;

  const handleRadioChange = event => {
    const newColumn = event.target.value;
    setColumn(newColumn);

    updatePreferences({
      userPreferences: {
        id: preferences?.id,
        horseDetailPageDataColumn: newColumn
      },
      updateMask: {
        paths: ["horseDetailPageDataColumn"]
      }
    }).then(() => queryClient.invalidateQueries([query.key]));
  };

  return (
    <SettingsPopover>
      <>
        {isLoading && <Loader overlay />}
        <Typography variant={"subtitle2"} color={"textPrimary"}>
          Data Column
        </Typography>
        <Typography variant={"caption"} color={"textSecondary"}>
          Choose the data to be displayed in the last column.
        </Typography>
        <FormControl component="fieldset" className={classes.radioGroup}>
          <RadioGroup
            aria-label="preferred-column"
            name="preferred-column"
            value={column}
            onChange={handleRadioChange}
          >
            {columns.map(c => (
              <FormControlLabel
                classes={{ label: classes.label, root: classes.radioItemRoot }}
                key={c.key}
                value={c.key}
                control={<Radio />}
                label={c.label}
              />
            ))}
          </RadioGroup>
        </FormControl>
      </>
    </SettingsPopover>
  );
};

export default HorseListSettings;
