import React, { ReactNode } from "react";

import { racehorse360 } from "@tsg/1st-grpc-web";

import MapDialog from "components/MapDialog";
import RiskLevel from "components/RiskLevel";
import { transformViewDate } from "utils/date-utils";
import { getHorseAgeSexColorValue, toTitleCase } from "utils/horse";
import { getPersonFullName } from "utils/person";
import { DASH } from "common/constants";
import HorseInfoTable, {
  IHorseInfoTableData
} from "../HorseInfoTable/HorseInfoTable";
import useStyles from "./styles";

interface IProps {
  horse: racehorse360.IHorse;
}

interface IGeneralInfoData extends IHorseInfoTableData {
  "Registration #": string;
  "Date of Birth": string;
  "Age-Sex-Color": string;
  Dam: string;
  Sire: string;
  "Risk Level (ABC)": ReactNode | string;
  "Chip ID #": string;
  Trainer: string;
  Owner: string;
  "Current Location": string;
  "Barn / Stall": ReactNode | string;
  "Last Workout": string;
  "Last Workout Track": string;
  "Last Race": string;
  "Last Race Track": string;
}

const GeneralInfo = (props: IProps) => {
  const { horse } = props;
  const classes = useStyles();

  const renderBarnStallValue = () => {
    const barn = horse.barn?.name || DASH;
    const stall = horse.stall?.name || DASH;

    return (
      <div>
        <span className={horse.barn?.name && classes.barnStall}>
          {`${barn} / ${stall}`}
        </span>
        {horse.barn?.name && <MapDialog horse={horse} />}
      </div>
    );
  };

  const tableData: IGeneralInfoData = {
    "Registration #": horse.registrationNumber || DASH,
    "Date of Birth": transformViewDate(horse.birthday, null, "M/d/yy") || DASH,
    "Age-Sex-Color": getHorseAgeSexColorValue(horse),
    Dam: horse.damName || DASH,
    Sire: horse.sireName || DASH,
    "Risk Level (ABC)": <RiskLevel status={horse.healthWatchStatus} />,
    "Chip ID #": horse.chipNumber || DASH,
    Trainer: getPersonFullName(horse.trainer, "%F %L") || DASH,
    Owner: horse.ownerName || DASH,
    "Current Location":
      toTitleCase(horse.currentFacility?.name.toLowerCase()) || DASH,
    "Barn / Stall": renderBarnStallValue(),
    "Last Workout":
      transformViewDate(horse.lastWorkoutDate, null, "M/d/yy") || DASH,
    "Last Workout Track": horse.lastWorkoutFacility?.name.toLowerCase() || DASH,
    "Last Race": transformViewDate(horse.lastRaceDate, null, "M/d/yy") || DASH,
    "Last Race Track": horse.lastRaceFacility?.name.toLowerCase() || DASH
  };

  return (
    <div className={classes.root}>
      <div className={classes.horseName}>{horse.name}</div>
      <HorseInfoTable data={tableData} />
    </div>
  );
};

export default GeneralInfo;
