import React, { SyntheticEvent, useState } from "react";
import clsx from "clsx";
import { useDispatch, useSelector } from "react-redux";
import { useQueryClient } from "react-query";

import Radio from "@material-ui/core/Radio";

import { racehorse360 } from "@tsg/1st-grpc-web";

import Loader from "components/Loader";
import { useRacehorse360Api } from "hooks/api";
import { setCurrentFacilityId } from "store/actions/queriesConfig";
import IQueriesConfigState from "interfaces/queriesConfigState";
import useStyles from "./styles";

interface IProps {
  horsesCountByFacilitySummary: racehorse360.IHorsesCountByFacilitySummary[];
  totalCount: number;
  isLoading: boolean;
  handleBlockableLinkClick: (e: SyntheticEvent) => boolean;
  setOpen: (state: boolean) => void;
}

export const ALL_LOCATIONS_ID = "all_Locations";

const FacilitySelector = React.memo((props: IProps) => {
  const {
    totalCount = 0,
    horsesCountByFacilitySummary = [],
    isLoading,
    handleBlockableLinkClick,
    setOpen
  } = props;
  const classes = useStyles();
  const allLocationsItem: racehorse360.IHorsesCountByFacilitySummary = {
    facilityId: ALL_LOCATIONS_ID,
    facilityName: "All Locations",
    horsesCount: totalCount
  };
  const dispatch = useDispatch();
  const queryClient = useQueryClient();
  const selectedFacilityId = useSelector(
    (state: { queriesConfig: IQueriesConfigState }) => {
      return state?.queriesConfig?.currentFacilityId;
    }
  );

  const [selectedFacility, setSelectedFacility] = useState(
    selectedFacilityId || allLocationsItem.facilityId
  );

  const { useSetHorsesFilterFacility } = useRacehorse360Api();

  const {
    mutateAsync: setHorsesFilterFacility,
    isLoading: isSetHorsesFilterFacility
  } = useSetHorsesFilterFacility();

  const handleChangeFacility = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (handleBlockableLinkClick(event)) {
      setOpen(false);
    } else {
      const selectedFacilityId =
        event.currentTarget.value === allLocationsItem.facilityId
          ? null
          : event.currentTarget.value;

      setHorsesFilterFacility({
        facilityId: selectedFacilityId,
        getOptions: {
          select: ["preferences.horsesFilterFacilityId"]
        }
      })
        .then(async res => {
          const filterFacilityId = res?.preferences?.horsesFilterFacilityId;

          dispatch(
            setCurrentFacilityId(filterFacilityId ? filterFacilityId : null)
          );
          setSelectedFacility(filterFacilityId || allLocationsItem.facilityId);
          await queryClient.invalidateQueries();
        })
        .catch((error: Error) => {
          console.error(error);
        });
    }
  };

  const renderFacilitiesList = () => {
    const preparedData = [allLocationsItem, ...horsesCountByFacilitySummary];
    const isShowLoader = isLoading || isSetHorsesFilterFacility;

    return isShowLoader ? (
      <Loader className={classes.loader} />
    ) : (
      preparedData.map(
        (facility: racehorse360.IHorsesCountByFacilitySummary) => {
          const { facilityId, facilityName, horsesCount } = facility;

          return (
            <label key={facilityId} className={classes.listItem}>
              <span>{`${facilityName.toLowerCase()} (${
                horsesCount || 0
              })`}</span>
              <Radio
                classes={{
                  root: classes.radioButton
                }}
                checked={selectedFacility === facilityId}
                color="default"
                value={facilityId}
                onChange={handleChangeFacility}
                size="small"
              />
            </label>
          );
        }
      )
    );
  };

  return (
    <div className={classes.facilitySelector}>
      <div className={clsx(classes.listItem, classes.header)}>
        Horses to Display
      </div>
      {renderFacilitiesList()}
    </div>
  );
});

export default FacilitySelector;
