import React, { useState } from "react";
import { useDispatch } from "react-redux";
import { useRouteMatch } from "react-router-dom";
import { goBack } from "connected-react-router";

import Grid from "@material-ui/core/Grid";
import IconButton from "@material-ui/core/IconButton";
import Typography from "@material-ui/core/Typography";
import CloseIcon from "@material-ui/icons/Close";
import CheckIcon from "@material-ui/icons/Check";
import Box from "@material-ui/core/Box";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import { Theme } from "@material-ui/core/styles";

import { racehorse360 } from "@tsg/1st-grpc-web";

import { useRacehorse360Api } from "hooks/api";
import Link from "components/Link";
import ErrorBoundary from "components/ErrorBoundary";
import Loader from "components/Loader";
import HorseWorkoutInfo from "components/HorseWorkoutInfo";
import AppPage from "components/AppPage";
import AppPageHeader from "components/AppPageHeader";
import AppPageContent from "components/AppPageContent";
import HorseWorkoutSchedule from "./HorseWorkoutSchedule";
import FlashMessage from "components/FlashMessage";
import routes from "common/routes";
import Breakpoints from "common/breakpoints";
import useStyles from "./styles";

const HorseWorkoutsPage = React.memo(() => {
  const { params } = useRouteMatch<{ horseId: string }>(
    routes.horseWorkouts.path
  );
  const classes = useStyles();
  const dispatch = useDispatch();
  const matchesDownXS420 = useMediaQuery((theme: Theme) =>
    theme.breakpoints.down(Breakpoints.XS_420)
  );
  const changesSavedText = matchesDownXS420 ? "Saved" : "Changes Saved";

  const [horseId, setHorseId] = useState(params.horseId || "");
  const [successFlashTimestamp, setSuccessFlashTimestamp] = useState<number>(0);

  const { useGetHorse } = useRacehorse360Api();

  const { data: horse, isLoading } = useGetHorse({
    id: horseId,
    getOptions: {
      select: [
        "last_race_date",
        "last_race_facility.code",
        "last_race_facility.name",
        "last_workout_date",
        "last_workout_facility.name",
        "last_workout_facility.code",
        "currentFacility.name",
        "currentFacility.code",
        "barn.name",
        "barn.facility.name",
        "barn.facility.code",
        "name"
      ]
    }
  });

  const showSuccessMessage = () => {
    setSuccessFlashTimestamp(Date.now());
  };

  const handleScheduleChange = (changedHorse: racehorse360.IHorse) => {
    setHorseId(changedHorse.id.toString());
    showSuccessMessage();
  };

  const handleCloseClick = () => {
    dispatch(goBack());
  };

  if (isLoading) {
    return <Loader />;
  }

  return (
    <AppPage>
      <AppPageHeader className={classes.appPageHeader}>
        <Box className={classes.horseBar}>
          <FlashMessage
            className={classes.changesSavedWrapper}
            startTimestamp={successFlashTimestamp}
          >
            <Typography className={classes.changesSavedText}>
              <CheckIcon className={classes.changesSavedIcon} />
              &nbsp;{changesSavedText}
            </Typography>
          </FlashMessage>
          <Link
            to={`${routes.horseDetails.path.replace(
              ":horseId",
              String(horse.id)
            )}`}
            className={classes.detailsLink}
          >
            <Typography component={"b"} className={classes.horseBarTitle}>
              {horse?.name}
            </Typography>
          </Link>
          <IconButton
            aria-label="close"
            className={classes.closeButton}
            onClick={handleCloseClick}
          >
            <CloseIcon className={classes.closeIcon} />
          </IconButton>
        </Box>
      </AppPageHeader>

      <AppPageContent className={classes.appPageContent}>
        <Grid className={classes.grid} container>
          <Grid className={classes.column} item xs={12} md={4}>
            <ErrorBoundary>
              <HorseWorkoutInfo horse={horse} />
            </ErrorBoundary>
          </Grid>
          <Grid className={classes.column} item xs={12} md={8}>
            <HorseWorkoutSchedule
              horse={horse}
              onChange={handleScheduleChange}
              onShowMessage={showSuccessMessage}
            />
          </Grid>
        </Grid>
      </AppPageContent>
    </AppPage>
  );
});

export default HorseWorkoutsPage;
