import React from "react";
import clsx from "clsx";
import { FixedSizeList as List } from "react-window";
import { AutoSizer } from "react-virtualized";

import { Box } from "@material-ui/core";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import CheckIcon from "@material-ui/icons/Check";

import AppPageTable from "components/AppPageTable";
import AppPageTableContent from "components/AppPageTableContent";

import { racehorse360 } from "@tsg/1st-grpc-web";
import { getHorseAge } from "utils/horse";
import { parseGender } from "utils/enum-parser";
import HorseIcon from "components/Icons/Horse";
import Link from "components/Link";
import routes from "common/routes";
import useStyles from "./styles";

export interface Props {
  activeHorseId: string;
  horses: racehorse360.IHorse[];
  tableBodyRef: React.RefObject<HTMLDivElement>;
}

const HorseList = React.memo((props: Props) => {
  const { horses, activeHorseId, tableBodyRef } = props;
  const classes = useStyles();

  const Row = ({ index, style }) => (
    <Link
      key={horses[index].id}
      className={classes.rowLink}
      style={style}
      to={routes.horseDetails.path.replace(
        ":horseId",
        String(horses[index].id)
      )}
    >
      <Grid
        container
        className={clsx(
          classes.row,
          index % 2 ? classes.rowOdd : classes.rowEven,
          {
            [classes.active]: activeHorseId === horses[index].id
          }
        )}
      >
        <Typography className={classes.name}>
          {activeHorseId === horses[index].id ? (
            <CheckIcon className={classes.icon} />
          ) : (
            <HorseIcon className={classes.icon} />
          )}
          {horses[index].name}
        </Typography>
        <Box>
          <Typography className={classes.age}>
            {getHorseAge(horses[index], true)}
          </Typography>
          <Typography className={classes.color}>
            {horses[index].colorDescription}{" "}
            {parseGender(horses[index]?.gender)}
          </Typography>
        </Box>
      </Grid>
    </Link>
  );

  return (
    <AppPageTable>
      <AppPageTableContent>
        <AutoSizer>
          {({ height, width }) => (
            <List
              className={classes.listVirtualized}
              itemCount={horses.length}
              itemSize={60}
              height={height}
              width={width}
              outerRef={tableBodyRef}
            >
              {Row}
            </List>
          )}
        </AutoSizer>
      </AppPageTableContent>
    </AppPageTable>
  );
});

export default HorseList;
