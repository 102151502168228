import React from "react";
import Divider from "@material-ui/core/Divider";
import { Theme } from "@material-ui/core/styles";
import useMediaQuery from "@material-ui/core/useMediaQuery";

import { racehorse360 } from "@tsg/1st-grpc-web";
import { useConfirmationContext } from "components/ConfirmationDialog/ConfirmationContext";
import Dialog from "components/Dialog";
import Loader from "components/Loader";
import { useLoggedInUser } from "components/LoggedInUserProvider";
import SwipeableTabs from "components/SwipeableTabs";
import { useRacehorse360Api } from "hooks/api";
import { SortOrder } from "interfaces/SortOrder";

import DeleteVaccination from "../DeleteVaccination";
import InjectionFormModal from "../InjectionFormModal";
import VaccinationActivity from "./VaccinationActivity";
import VaccineDetailsList from "./VaccineDetailsList";
import useStyles from "./styles";

export interface Props {
  horse: racehorse360.IHorse;
  open: boolean;
  onClose: () => void;
  injection: racehorse360.IInjection;
}

const VaccinationDetailsModal = (props: Props) => {
  const { horse, open, onClose, injection } = props;
  const classes = useStyles();
  const { currentUser } = useLoggedInUser();
  const matchesDownSm = useMediaQuery((theme: Theme) =>
    theme.breakpoints.down("sm")
  );
  const { confirmationRequired, openConfirmation } = useConfirmationContext();

  const { useListInjections, useListVaccinationComments } =
    useRacehorse360Api();

  const { data: listInjectionsData, isFetching: isListInjectionsFetching } =
    useListInjections(
      `${injection?.vaccination?.id}-list-boosters`,
      {
        query: {
          vaccinationIds: injection?.vaccination?.id
            ? [injection.vaccination.id]
            : [],
          medicineType: racehorse360.MedicineType.MEDICINE_TYPE_BOOSTER
        },
        pagingOptions: {
          maxResults: 9999,
          includeSummary: true
        },
        getOptions: {
          select: [
            "id",
            "medicineId",
            "medicine.name",
            "medicine.type",
            "medicine.expiredInDays",
            "vaccinationId",
            "injectionDate",
            "customExpirationDate",
            "dataSource",
            "comment",
            "manufacturer",
            "vaccination.horseId",
            "vaccination.expirationDate",
            "createdOn",
            "updatedOn",
            "createdBy.firstName",
            "createdBy.lastName",
            "updatedBy.firstName",
            "updatedBy.lastName"
          ],
          orderBy: [`createdOn ${SortOrder.ASC}`]
        }
      },
      {
        enabled: Boolean(injection?.vaccination?.id),
        initialData: {
          injections: []
        }
      }
    );
  const injections = listInjectionsData?.injections;
  const vaccineInjection = injections?.find(
    injection =>
      injection.medicine.type ===
      racehorse360.MedicineType.MEDICINE_TYPE_VACCINE
  );
  const boosters = injections?.filter(
    injection =>
      injection.medicine.type ===
      racehorse360.MedicineType.MEDICINE_TYPE_BOOSTER
  );

  const { data: listVaccinationCommentsData } = useListVaccinationComments(
    `${injection?.vaccination?.id}-list-vaccination-comments`,
    {
      query: {
        vaccinationIds: injection?.vaccination?.id
          ? [injection.vaccination.id]
          : []
      },
      pagingOptions: {
        maxResults: 9999,
        includeSummary: true
      },
      getOptions: {
        select: [
          "id",
          "body",
          "createdBy.firstName",
          "createdBy.lastName",
          "createdOn",
          "updatedOn"
        ],
        orderBy: [`createdOn ${SortOrder.ASC}`]
      }
    },
    {
      enabled: Boolean(injection?.vaccination?.id)
    }
  );
  const comments = listVaccinationCommentsData?.vaccinationComments;

  const handleClose = () => {
    if (confirmationRequired) {
      openConfirmation();
    } else {
      onClose();
    }
  };

  const renderMobile = () => {
    return (
      <SwipeableTabs
        classes={{
          root: classes.tabsWrapper,
          tabs: classes.tabs,
          content: classes.tabsContent,
          views: classes.tabsViews
        }}
        tabs={["Details", "Activity"]}
      >
        <div className={classes.detailsList}>
          <VaccineDetailsList injection={vaccineInjection} />
          {vaccineInjection?.createdBy?.id === currentUser.rh360Id && (
            <div className={classes.actions}>
              <DeleteVaccination
                horse={horse}
                injection={injection}
                onSuccess={handleClose}
              />
              <InjectionFormModal
                horse={horse}
                injection={vaccineInjection}
                type={racehorse360.MedicineType.MEDICINE_TYPE_VACCINE}
                isEdit={true}
              />
            </div>
          )}
        </div>
        <div>
          <VaccinationActivity
            horse={horse}
            injections={boosters}
            comments={comments}
            vaccineInjection={vaccineInjection}
            closeDetails={onClose}
          />
        </div>
      </SwipeableTabs>
    );
  };

  const renderDesktop = () => {
    return (
      <div className={classes.detailsWrapper}>
        <div className={classes.detailsList}>
          <VaccineDetailsList injection={vaccineInjection} />
          {(vaccineInjection?.createdBy?.id === currentUser.rh360Id ||
            currentUser.isSeniorVet ||
            currentUser.isChiefVet) && (
            <div className={classes.actions}>
              <DeleteVaccination
                horse={horse}
                injection={injection}
                onSuccess={handleClose}
              />
              <InjectionFormModal
                horse={horse}
                injection={vaccineInjection}
                type={racehorse360.MedicineType.MEDICINE_TYPE_VACCINE}
                isEdit={true}
              />
            </div>
          )}
        </div>
        <Divider orientation={"vertical"} />
        <div className={classes.detailsActivity}>
          <VaccinationActivity
            horse={horse}
            injections={boosters}
            comments={comments}
            vaccineInjection={vaccineInjection}
            closeDetails={onClose}
          />
        </div>
      </div>
    );
  };

  return (
    <>
      <Dialog
        title={
          <div className={classes.dialogTitleContent}>
            <span className={classes.dialogTitleLabel}>Vaccine Details</span>
            {matchesDownSm && (
              <InjectionFormModal
                horse={horse}
                injection={vaccineInjection}
                type={racehorse360.MedicineType.MEDICINE_TYPE_BOOSTER}
                isEdit={false}
              />
            )}
            <span className={classes.dialogTitleHorseName}>{horse.name}</span>
          </div>
        }
        TransitionProps={{
          mountOnEnter: true,
          unmountOnExit: true
        }}
        onClose={handleClose}
        classes={{
          title: classes.dialogTitle,
          paper: classes.dialogPaper,
          content: classes.dialogContent
        }}
        isOpen={open}
      >
        {isListInjectionsFetching && <Loader overlay />}
        {matchesDownSm ? renderMobile() : renderDesktop()}
      </Dialog>
    </>
  );
};

export default VaccinationDetailsModal;
