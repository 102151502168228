import React from "react";

import Dialog from "@material-ui/core/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import DialogActions from "@material-ui/core/DialogActions";
import IconButton from "@material-ui/core/IconButton";
import Button from "@material-ui/core/Button";
import CloseIcon from "@material-ui/icons/Close";

import { racehorse360 } from "@tsg/1st-grpc-web";

import Comment from "components/Comments/Comment";
import HorseIcon from "components/Icons/Horse";
import { ICommentData } from "components/Comments/Comment/Comment";
import { ECommentDisplayingVariant } from "components/Comments/Comment/helper";
import routes from "common/routes";
import history from "common/history";
import { getTypeLabel } from "../helper";
import useStyles from "./styles";

interface IProps {
  notification: racehorse360.INotification;
  onClose: () => void;
  onNextClick: () => void;
  onPreviousClick: () => void;
  disableNextButton: boolean;
  disablePreviousButton: boolean;
  onMarkNotificationsAsRead: (
    notification: racehorse360.INotification,
    areAllRead?: boolean
  ) => Promise<racehorse360.IMarkHorseAggregatedCommentsAsReadResponse | void>;
}

const getNotificationData = (
  notification: racehorse360.INotification
): ICommentData => {
  return {
    id: notification.id,
    createdOn: notification.createdOn,
    isRead: notification.isRead,
    commenterLastName: notification.createdBy?.lastName,
    commenterFirstName: notification.createdBy?.firstName,
    eventDate: notification.eventDate,
    eventType: notification.eventType,
    content: notification.body,
    modalIconType: notification.creatorRole
  };
};

const NotificationModal = (props: IProps) => {
  const {
    onClose,
    notification,
    onPreviousClick,
    onNextClick,
    disableNextButton,
    disablePreviousButton,
    onMarkNotificationsAsRead
  } = props;
  const notificationData: ICommentData = getNotificationData(notification);
  const classes = useStyles();

  const handleRedirectToHorseDetails = async () => {
    await onMarkNotificationsAsRead(notification);
    history.push(
      routes.horseDetails.path.replace(
        ":horseId",
        String(notification.horse.id)
      )
    );
  };

  return (
    <Dialog className={classes.modal} open={true} onClose={onClose}>
      <DialogTitle className={classes.modalHeader}>
        <div className={classes.modalTitle} data-test={"modal-title"}>
          {getTypeLabel(notification.type)}
        </div>
        <div className={classes.modalSubTitle}>
          <HorseIcon className={classes.horseIcon} />
          {notification.horse.name}
        </div>
        <IconButton
          aria-label="close"
          className={classes.closeButton}
          onClick={onClose}
        >
          <CloseIcon />
        </IconButton>
      </DialogTitle>
      <DialogContent
        className={classes.modalContent}
        data-test={"modal-content"}
      >
        <Comment
          shouldDisplayIsReadStatus={true}
          data={notificationData}
          variant={ECommentDisplayingVariant.MODAL}
        />
        <Button
          className={classes.horseDetailsLink}
          onClick={handleRedirectToHorseDetails}
        >
          VIEW HORSE
        </Button>
      </DialogContent>
      <DialogActions
        className={classes.modalActions}
        data-test={"modal-navigation-buttons"}
      >
        <Button
          className={classes.actionButton}
          disabled={disablePreviousButton}
          onClick={onPreviousClick}
        >
          PREVIOUS
        </Button>
        <Button
          className={classes.actionButton}
          disabled={disableNextButton}
          onClick={onNextClick}
        >
          NEXT
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default NotificationModal;
