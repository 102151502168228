import { racehorse360 } from "@tsg/1st-grpc-web";
import {
  SET_SELECTED_FACILITY,
  SelectedFacilityType
} from "store/actions/trainerHorseListPage";

interface TrainerHorseListPageReducer {
  selectedFacility: racehorse360.IFacility;
}

const initialState: TrainerHorseListPageReducer = {
  selectedFacility: null
};

export default (
  state = initialState,
  action: SelectedFacilityType
): TrainerHorseListPageReducer => {
  switch (action.type) {
    case SET_SELECTED_FACILITY:
      return {
        ...state,
        selectedFacility: action.selectedFacility
      };
    default:
      return state;
  }
};
