import { MuiTheme } from "theme";
import { makeStyles } from "@material-ui/core/styles";

export default makeStyles((theme: MuiTheme) => ({
  appPageHeader: {
    display: "flex",
    flexWrap: "wrap",
    padding: "0 24px",
    boxShadow: "none",
    zIndex: 1,
    [theme.breakpoints.down("sm")]: {
      minHeight: 107
    },
    "& >div": {
      display: "flex",
      [theme.breakpoints.up("xs")]: {
        alignItems: "center"
      }
    }
  },
  pageContent: {
    flexDirection: "column"
  },
  formName: {
    flexGrow: 2,
    [theme.breakpoints.down("xs")]: {
      justifyContent: "center"
    },
    "& >h3": {
      fontWeight: "normal"
    }
  },
  menu: {
    flexGrow: 1,
    display: "flex",
    justifyContent: "space-between",
    [theme.breakpoints.down("xs")]: {
      paddingBottom: 14
    }
  },
  search: {
    width: "100%"
  },
  link: {
    color: theme.palette.primary.dark,
    minWidth: 84,
    marginLeft: 24,

    [theme.breakpoints.down("sm")]: {
      marginLeft: 24
    }
  },
  tabs: {
    color: theme.palette.primary.dark,
    backgroundColor: "#fff",
    fontSize: "1rem",
    [theme.breakpoints.down("xs")]: {
      fontSize: 16,
      padding: "0 12px",
      minHeight: 40
    },
    "& .MuiTab-root.Mui-selected": {
      color: theme.palette.secondary.dark
    }
  },
  tabContent: {
    position: "relative",
    flex: 1,
    [theme.breakpoints.down("xs")]: {
      height: "100%",
      paddingBottom: 50
    }
  },
  activeTab: {
    backgroundColor: theme.palette.secondary.dark
  }
}));
