import React, { ChangeEvent, useState, useEffect } from "react";
import clsx from "clsx";

import InputLabel from "@material-ui/core/InputLabel";
import DialogContent from "@material-ui/core/DialogContent";
import Dialog from "@material-ui/core/Dialog";
import TextField from "@material-ui/core/TextField";
import Select from "@material-ui/core/Select";
import FormControl from "@material-ui/core/FormControl";
import Button from "@material-ui/core/Button";
import MenuItem from "@material-ui/core/MenuItem";

import Loader from "components/Loader";
import { EVetExamFields, IExamSelectOption } from "../options";
import { CUSTOM_VALUE } from "../helper";
import useStyles from "./styles";

interface IProps {
  options: IExamSelectOption[];
  label: string;
  inputLabel: string;
  selectedValue: number | string;
  onChangeSelectedValue: (value: number | string, customValue?: string) => void;
  name?: EVetExamFields;
  className?: string;
  hasCustomField?: boolean;
  dataTestLabel?: string;
  showInnerLoader?: boolean;
}

const otherField = {
  name: "Other...",
  value: "other"
};

const ExamSelect = (props: IProps) => {
  const {
    options,
    label = "",
    name,
    inputLabel = "",
    selectedValue,
    onChangeSelectedValue,
    className = "",
    hasCustomField = false,
    showInnerLoader = false,
    dataTestLabel
  } = props;
  const classes = useStyles();

  const [isShowLoader, setIsShowLoader] = useState<boolean>(false);
  const [isOpenSelect, setIsOpenSelect] = useState<boolean>(true);
  const [customValue, setCustomValue] = useState<string>("");
  const [shouldShowOtherPopup, setShouldShowOtherPopup] =
    useState<boolean>(false);

  const isLoading = [showInnerLoader, isShowLoader].every(Boolean);

  const handleChangeValue = (
    event: ChangeEvent<{ name?: EVetExamFields; value: string | number }>
  ) => {
    showInnerLoader && setIsShowLoader(true);
    const { value } = event.target;

    if (value === otherField.value) {
      setShouldShowOtherPopup(true);
      return;
    }

    setCustomValue("");
    onChangeSelectedValue(value);
  };

  const handleChangeCustomField = (
    event: ChangeEvent<HTMLTextAreaElement | HTMLInputElement>
  ) => {
    setCustomValue(event.target.value);
  };

  const handleCloseOtherPopup = () => {
    setShouldShowOtherPopup(false);
  };

  const handleSaveCustomValue = async () => {
    onChangeSelectedValue(CUSTOM_VALUE, customValue);
    setShouldShowOtherPopup(false);
  };

  const onFocusSelect = () => {
    setIsOpenSelect(true);
  };

  const onBlurSelect = () => {
    setIsOpenSelect(false);
  };

  const renderOptions = () => {
    let preparedOptions = [...options];

    if (hasCustomField) {
      preparedOptions = [...options, otherField];
    }

    return preparedOptions.map((item: IExamSelectOption) => {
      let className = "";

      if (item.value === CUSTOM_VALUE) {
        className = classes.customField;
      }

      return (
        <MenuItem
          key={`${item.value}_${item.name}`}
          className={className}
          value={item.value}
        >
          {item.name}
        </MenuItem>
      );
    });
  };

  useEffect(() => {
    const customOption = options.find(item => item.value === CUSTOM_VALUE);

    setCustomValue(customOption?.name || "");
    showInnerLoader && setIsShowLoader(false);
  }, [selectedValue]);

  return (
    <>
      <FormControl
        className={className}
        classes={{
          root: clsx(classes.rootFormControl, {
            ["selectedValue"]: selectedValue
          })
        }}
        variant="outlined"
        data-test={dataTestLabel}
      >
        {isLoading && <Loader size={19} className={classes.selectLoader} />}
        <InputLabel id="exam-select">
          {isOpenSelect || selectedValue ? label : inputLabel}
        </InputLabel>
        <Select
          classes={{
            root: classes.selectRoot
          }}
          labelId="exam-select"
          id="exam-select-filled"
          value={selectedValue}
          MenuProps={{
            classes: {
              list: classes.selectList,
              paper: classes.selectPaper
            },
            anchorOrigin: {
              vertical: "bottom",
              horizontal: "left"
            },
            getContentAnchorEl: null
          }}
          label={label}
          defaultValue={""}
          onChange={handleChangeValue}
          onFocus={onFocusSelect}
          onBlur={onBlurSelect}
          name={name}
        >
          {renderOptions()}
        </Select>
      </FormControl>

      {shouldShowOtherPopup && (
        <Dialog open={true} onClose={handleCloseOtherPopup}>
          <DialogContent className={classes.otherPopup}>
            <div className={classes.otherPopupHeader}>{label}</div>
            <TextField
              className={classes.popupTextField}
              label={null}
              variant="outlined"
              value={customValue}
              onChange={handleChangeCustomField}
              autoFocus={true}
              inputProps={{
                maxLength: 200
              }}
            />
            <div className={classes.popupActions}>
              <Button
                className={clsx(classes.popupButton)}
                onClick={handleCloseOtherPopup}
              >
                Cancel
              </Button>
              <Button
                className={clsx(classes.popupButton, classes.saveButton)}
                onClick={handleSaveCustomValue}
                disabled={!customValue.trim()}
              >
                Save
              </Button>
            </div>
          </DialogContent>
        </Dialog>
      )}
    </>
  );
};

export default ExamSelect;
