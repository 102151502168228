import {
  SET_ORDER,
  SET_ORDER_BY,
  SET_SEARCH_QUERY,
  SET_TRAINER_SEARCH_ITEM,
  VetHorseListPageType
} from "store/actions/vetHorseListPage";
import { SortOrder, SortOrderType } from "interfaces/SortOrder";
import { SearchTypeKey } from "interfaces/SearchType";

interface VetHorseListPageReducer {
  order: SortOrderType;
  orderBy: string[];
  searchQuery: string;
  searchTypeKey: SearchTypeKey;
  trainerSearchItem: any;
}

const initialState: VetHorseListPageReducer = {
  order: SortOrder.ASC,
  orderBy: ["name"],
  searchQuery: "",
  searchTypeKey: "horses",
  trainerSearchItem: null
};

export default (
  state = initialState,
  action: VetHorseListPageType
): VetHorseListPageReducer => {
  switch (action.type) {
    case SET_ORDER:
      return {
        ...state,
        order: action.order
      };
    case SET_ORDER_BY:
      return {
        ...state,
        orderBy: action.orderBy
      };
    case SET_SEARCH_QUERY:
      return {
        ...state,
        searchQuery: action.searchQuery
      };
    case SET_TRAINER_SEARCH_ITEM:
      return {
        ...state,
        trainerSearchItem: action.trainerSearchItem
      };
    default:
      return state;
  }
};
