import React, { useEffect, useState } from "react";
import { Link, useHistory, useLocation } from "react-router-dom";
import { useAuth0 } from "@auth0/auth0-react";
import { useDispatch } from "react-redux";
import clsx from "clsx";

import Box from "@material-ui/core/Box";
import Drawer from "@material-ui/core/Drawer";
import IconButton from "@material-ui/core/IconButton";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import ListItemText from "@material-ui/core/ListItemText";
import Typography from "@material-ui/core/Typography";

import routes from "common/routes";
import {
  withClickBlocker,
  WithClickBlockerProps
} from "components/BlockableClickContext";
import ProfileIcon from "components/Icons/Profile";
import SupportIcon from "components/Icons/Support";
import ExitIcon from "components/Icons/Exit";
import { useLoggedInUser } from "components/LoggedInUserProvider";
import { useRacehorse360Api } from "hooks/api";
import { setCurrentFacilityId } from "store/actions/queriesConfig";
import { Facilities } from "utils/facilities";
import { matchRoute } from "utils/routes";
import { DeveloperRoleSelector } from "./DeveloperRoleSelector";
import FacilitySelector from "./FacilitySelector";
import useStyles from "./styles";

const AccountPageSlider = React.memo((props: WithClickBlockerProps) => {
  const { handleBlockableLinkClick } = props;
  const classes = useStyles();
  const dispatch = useDispatch();
  const history = useHistory();
  const location = useLocation();
  const { pathname } = location;
  const { user, logout } = useAuth0();
  const [open, setOpen] = useState<boolean>(false);

  const { currentUser, updatePermissions } = useLoggedInUser();
  const trainerId = currentUser.isTrainer && currentUser.trainerId;
  const isProduction = process.env.NODE_ENV === "production";

  const { usePullHorsesCountByFacilitySummary, useGetUser } =
    useRacehorse360Api();

  const { data: userData, isLoading: isUserDataLoading } = useGetUser(
    {
      id: currentUser.rh360Id,
      getOptions: {
        select: ["firstName", "lastName", "preferences.horsesFilterFacilityId"]
      }
    },
    {
      enabled: Boolean(currentUser.rh360Id),
      onError: error => console.error(error)
    }
  );

  const initials =
    (userData?.firstName || "").charAt(0) +
    (userData?.lastName || "").charAt(0);

  const {
    data: horsesCountDataByFacilities,
    isLoading: isLoadingHorsesCountData,
    isFetching: isFetchingHorsesCountData
  } = usePullHorsesCountByFacilitySummary(
    `${trainerId}-horsesCountByFacility`,
    {
      facilityIds: Facilities
    },
    {
      enabled: Boolean(userData && currentUser.isTrainer && Facilities?.length)
    }
  );

  useEffect(() => {
    if (open && currentUser.isLoaded) {
      setOpen(false);
      history.push(routes.dashboard.path);
    }
  }, [currentUser.isLoaded, currentUser]);

  useEffect(() => {
    if (!isUserDataLoading) {
      const filterFacilityId =
        Boolean(currentUser.isTrainer) &&
        userData?.preferences?.horsesFilterFacilityId
          ? userData.preferences.horsesFilterFacilityId
          : null;

      dispatch(setCurrentFacilityId(filterFacilityId));
    }
  }, [isUserDataLoading]);

  const handleToggle = (state: boolean) => () => {
    setOpen(state);
  };

  const handleRedirect = e => {
    setOpen(false);
    handleBlockableLinkClick(e);
  };

  const handleLogout = () => {
    logout({ returnTo: window.location.origin });
  };

  const renderFacilitySelector = () => {
    const horsesCountByFacilitySummary =
      horsesCountDataByFacilities?.horsesCountByFacilitySummary;
    const totalCount = horsesCountDataByFacilities?.totalCount;
    const isLoadingFacilitySelectorData =
      isLoadingHorsesCountData ||
      isUserDataLoading ||
      isFetchingHorsesCountData;

    return (
      <FacilitySelector
        horsesCountByFacilitySummary={horsesCountByFacilitySummary || []}
        totalCount={totalCount}
        isLoading={isLoadingFacilitySelectorData}
        handleBlockableLinkClick={handleBlockableLinkClick}
        setOpen={setOpen}
      />
    );
  };

  return (
    <>
      <IconButton
        id="accountPageSliderButton"
        color="inherit"
        className={classes.iconButton}
        onClick={handleToggle(true)}
        aria-label="Profile Menu"
        aria-haspopup="true"
        aria-controls="accountPageSlider"
      >
        {initials.length === 0 ? (
          <ProfileIcon color="inherit" className={classes.icon} />
        ) : (
          <Box className={classes.accountCircle}>{initials}</Box>
        )}
      </IconButton>
      <Drawer
        open={open}
        onClose={handleToggle(false)}
        anchor="right"
        id="accountPageSlider"
        role="menu"
        aria-labelledby="accountPageSliderButton"
      >
        <section className={classes.root}>
          <section className={classes.wrapper}>
            <header className={classes.header}>
              <div className={classes.avatar}>
                {user.picture ? (
                  <img
                    src={user.picture}
                    className={classes.avatarPicture}
                    alt=""
                  />
                ) : (
                  <ProfileIcon color="inherit" className={classes.avatarIcon} />
                )}
              </div>
              <Typography
                variant="h6"
                color="inherit"
                noWrap
                className={classes.name}
              >
                {user.nickname}
              </Typography>
              {currentUser.roles.length === 0 && (
                <>
                  <Typography
                    color="textSecondary"
                    noWrap
                    className={classes.status}
                  >
                    Activation Pending
                  </Typography>
                  <Typography color="primary" noWrap className={classes.status}>
                    <button onClick={updatePermissions}>Refresh</button>
                  </Typography>
                </>
              )}
            </header>

            <section className={classes.content}>
              <List component="nav" disablePadding={true}>
                <Link
                  className={classes.link}
                  to={routes.account.path}
                  onClick={handleRedirect}
                >
                  <ListItem
                    button
                    divider={true}
                    selected={matchRoute(pathname, [routes.account])}
                    className={classes.listItem}
                    classes={{ selected: classes.listItemSelected }}
                  >
                    <ListItemIcon className={classes.listItemIcon}>
                      <ProfileIcon
                        className={clsx(classes.icon, {
                          [classes.listItemIconSelected]: matchRoute(pathname, [
                            routes.account
                          ])
                        })}
                      />
                    </ListItemIcon>
                    <ListItemText
                      primary="Account"
                      className={classes.listItemText}
                      color={"inherit"}
                      disableTypography={true}
                    />
                  </ListItem>
                </Link>
                <a className={classes.link} href="mailto:rh360@1st.com">
                  <ListItem
                    button
                    divider={true}
                    selected={false}
                    className={classes.listItem}
                    classes={{ selected: classes.listItemSelected }}
                  >
                    <ListItemIcon className={classes.listItemIcon}>
                      <SupportIcon
                        className={clsx(classes.icon, {
                          [classes.listItemIconSelected]: false
                        })}
                      />
                    </ListItemIcon>
                    <ListItemText
                      primary="Support"
                      className={classes.listItemText}
                      color={"inherit"}
                      disableTypography={true}
                    />
                  </ListItem>
                </a>
              </List>
            </section>
          </section>

          <div
            className={clsx(classes.scrollableContainer, {
              production: isProduction || !currentUser.isSystemDeveloper
            })}
          >
            {!isProduction && currentUser.isSystemDeveloper && (
              <DeveloperRoleSelector />
            )}

            {currentUser.isTrainer && renderFacilitySelector()}
          </div>

          <footer className={classes.footer}>
            <List component="nav" disablePadding={true}>
              <ListItem
                button
                divider={true}
                className={clsx([classes.listItem, classes.listItemFooter])}
                onClick={handleLogout}
              >
                <ListItemIcon className={classes.listItemIcon}>
                  <ExitIcon className={clsx(classes.icon)} />
                </ListItemIcon>
                <ListItemText
                  primary="Sign Out"
                  className={classes.listItemText}
                  disableTypography={true}
                />
              </ListItem>
            </List>
          </footer>
        </section>
      </Drawer>
    </>
  );
});

export default withClickBlocker(AccountPageSlider);
