import { racehorse360 } from "@tsg/1st-grpc-web";
import { SortOrder } from "interfaces/SortOrder";
import { format, parseISO } from "date-fns";
import { checkSortingType } from "utils/sort-utils";

type SortingFn = (
  applicant1: racehorse360.IStallApplication,
  applicant2: racehorse360.IStallApplication
) => number;

type getSorterFn = (order: SortOrder, orderBy: string) => SortingFn;

export enum EColumns {
  TRAINER_NAME = "trainerName",
  STALLS = "stalls",
  ARRIVAL_DATE = "arrivalDate",
  ROOMS = "rooms",
  FACILITY_PREFERENCE_1 = "choice1",
  FACILITY_PREFERENCE_2 = "choice2"
}

export interface IColumn {
  id: EColumns;
  label: string;
  value: EColumns;
  isNonSortable?: boolean;
  render?: (application: racehorse360.IStallApplication) => React.ReactNode;
}

const valueRenderers = {
  trainerName: application => application.contactFullName,
  stalls: application => application.stableEntriesCount,
  rooms: application => application.sleepingRoomsNumber,
  arrivalDate: application =>
    format(parseISO(application.arrivalDate), "MM/dd/yy"),
  choice1: application => application.facilityPreferences[0].code,
  choice2: application => application.facilityPreferences[1]?.code
};

const ApplicantsTabColumns: IColumn[] = [
  {
    id: EColumns.TRAINER_NAME,
    label: "Trainer Name",
    value: EColumns.TRAINER_NAME,
    render: valueRenderers[EColumns.TRAINER_NAME]
  },
  {
    id: EColumns.STALLS,
    label: "Stalls",
    value: EColumns.STALLS,
    render: valueRenderers[EColumns.STALLS]
  },
  {
    id: EColumns.ARRIVAL_DATE,
    label: "Arrival Date",
    value: EColumns.ARRIVAL_DATE,
    render: valueRenderers[EColumns.ARRIVAL_DATE]
  },
  {
    id: EColumns.ROOMS,
    label: "Rooms",
    value: EColumns.ROOMS,
    render: valueRenderers[EColumns.ROOMS]
  },
  {
    id: EColumns.FACILITY_PREFERENCE_1,
    label: "1st Choice",
    value: EColumns.FACILITY_PREFERENCE_1,
    render: valueRenderers[EColumns.FACILITY_PREFERENCE_1]
  },
  {
    id: EColumns.FACILITY_PREFERENCE_2,
    label: "2nd Choice",
    value: EColumns.FACILITY_PREFERENCE_2,
    render: valueRenderers[EColumns.FACILITY_PREFERENCE_2]
  }
];

export const tableColumnsSets = [
  ApplicantsTabColumns,
  ApplicantsTabColumns,
  ApplicantsTabColumns
];

export const getStallApplicantsSortFunction: getSorterFn = (order, orderBy) => {
  const { isAsc } = checkSortingType(order);
  const multiplier = isAsc ? 1 : -1;
  switch (orderBy) {
    case EColumns.TRAINER_NAME:
      return (applicant1, applicant2) =>
        applicant1.contactFullName.localeCompare(applicant2.contactFullName) *
        multiplier;
    case EColumns.STALLS:
      return (applicant1, applicant2) =>
        (applicant1.stableEntriesCount - applicant2.stableEntriesCount) *
        multiplier;
    case EColumns.ARRIVAL_DATE:
      return (applicant1, applicant2) =>
        applicant1.arrivalDate.localeCompare(applicant2.arrivalDate) *
        multiplier;
    case EColumns.ROOMS:
      return (applicant1, applicant2) =>
        (applicant1.sleepingRoomsNumber - applicant2.sleepingRoomsNumber) *
        multiplier;
    case EColumns.FACILITY_PREFERENCE_1:
      return (applicant1, applicant2) =>
        applicant1.facilityPreferences[0].code.localeCompare(
          applicant2.facilityPreferences[0].code
        ) * multiplier;
    case EColumns.FACILITY_PREFERENCE_2:
      return (applicant1, applicant2) =>
        applicant1.facilityPreferences[1]?.code.localeCompare(
          applicant2.facilityPreferences[1]?.code
        ) * multiplier;
    default:
      console.error("there is no such sorting key");
      return (applicant1, applicant2) =>
        applicant1.trainer.lastName.localeCompare(applicant2.trainer.lastName) *
        multiplier;
  }
};
