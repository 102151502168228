import React, { useEffect, useState } from "react";
import secondsToMinutes from "date-fns/secondsToMinutes";
import differenceInMilliseconds from "date-fns/differenceInMilliseconds";
import differenceInSeconds from "date-fns/differenceInSeconds";

import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
import Typography from "@material-ui/core/Typography";

import { useExamLockerContext } from "../ExamLockerContext/ExamLockerContext";
import { IVetExamLocalState } from "../VetExamOverlay/options";
import { useVetExamContext } from "../VetExamOverlay/VetExamStateProvider/VetExamProvider";
import useStyles from "./styles";

interface Props {
  expirationDate: Date;
  onClose: () => void;
  onSaveWorkoutExam: (state: IVetExamLocalState) => void;
}

export const ExamLockerCountdownModal = (props: Props) => {
  const { expirationDate, onClose, onSaveWorkoutExam } = props;
  const classes = useStyles();
  const { lockExam, unlockExam } = useExamLockerContext();
  const { state } = useVetExamContext();
  const [countdownInterval, setCountdownInterval] =
    useState<ReturnType<typeof setInterval>>();
  const [openTimeout, setOpenTimeout] =
    useState<ReturnType<typeof setTimeout>>();
  const [open, setOpen] = useState<boolean>(false);
  const [expired, setExpired] = useState<boolean>(false);
  const [countdownString, setCountdownString] = useState<string>();

  const clearTimers = () => {
    clearTimeout(openTimeout);
    clearInterval(countdownInterval);
  };

  const getCountdownString = (expiresAt: Date) => {
    const secondsLeft = differenceInSeconds(expiresAt, new Date());
    const minutesLeft = Math.floor(secondsToMinutes(secondsLeft));
    return minutesLeft
      ? `${minutesLeft}m ${secondsLeft % 60}s`
      : `${secondsLeft % 60}s`;
  };

  const tickCountdown = expiresAt => {
    if (Date.now() >= expiresAt.getTime()) {
      clearTimers();
      setExpired(true);
    } else {
      setCountdownString(getCountdownString(expiresAt));
    }
  };

  const handleOpenByTimeout = (expiresAt: Date) => {
    clearTimers();
    setOpen(true);
    setCountdownInterval(setInterval(tickCountdown, 1000, expiresAt));
    setCountdownString(getCountdownString(expiresAt));
  };

  const handleDialogClose = (e, reason) => {
    if (!["escapeKeyDown", "backdropClick"].includes(reason)) {
      clearTimers();
      unlockExam();
    }
  };

  const handleSaveClick = () => {
    onSaveWorkoutExam(state);
    clearTimers();
    setOpen(false);
    onClose();
  };

  const handleCloseClick = () => {
    clearTimers();
    setOpen(false);
    onClose();
  };

  const handleContinueClick = () => {
    lockExam();
    setOpen(false);
  };

  const handleExited = () => {
    clearTimers();
    setExpired(false);
  };

  useEffect(() => {
    clearTimers();
    if (expirationDate) {
      const milliseconds = differenceInMilliseconds(expirationDate, new Date());
      setOpen(false);
      setOpenTimeout(
        setTimeout(
          handleOpenByTimeout,
          milliseconds - 5 * 60 * 1000,
          expirationDate
        ) //5 mins before expiration
      );
    }
  }, [expirationDate]);

  useEffect(() => {
    return () => {
      clearTimers();
    };
  }, []);

  return (
    <Dialog
      className={classes.dialog}
      classes={{ paper: classes.dialogPaper }}
      open={open}
      onClose={handleDialogClose}
      TransitionProps={{
        unmountOnExit: true,
        onExited: handleExited
      }}
    >
      <DialogTitle className={classes.dialogTitle}>
        <Typography className={classes.dialogTitleTypography}>
          {expired ? "Exam Session Expired" : "Timeout Warning"}
        </Typography>
      </DialogTitle>
      <DialogContent className={classes.dialogContent}>
        {expired ? (
          <>Your exam session has expired due to inactivity.</>
        ) : (
          <>
            <Typography>Your exam session will expire in</Typography>
            <Typography className={classes.countdown}>
              {countdownString}
            </Typography>
          </>
        )}
      </DialogContent>
      <DialogActions>
        {expired ? (
          <Button variant={"text"} onClick={handleCloseClick}>
            CLOSE
          </Button>
        ) : (
          <>
            <Button variant={"text"} onClick={handleSaveClick}>
              SAVE & EXIT
            </Button>
            <Button
              variant={"text"}
              className={classes.buttonContinue}
              onClick={handleContinueClick}
            >
              CONTINUE EXAM
            </Button>
          </>
        )}
      </DialogActions>
    </Dialog>
  );
};

export default ExamLockerCountdownModal;
