import React, { useRef, useState, useEffect } from "react";
import clsx from "clsx";

import ClickAwayListener from "@material-ui/core/ClickAwayListener";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import useTheme from "@material-ui/core/styles/useTheme";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import SearchIcon from "@material-ui/icons/Search";
import CloseIcon from "@material-ui/icons/Close";
import CheckIcon from "@material-ui/icons/Check";

import TextInputBase from "components/TextInputBase";
import SearchNoResults from "components/SearchNoResults";
import Loader from "components/Loader";
import HorseIcon from "components/Icons/Horse";
import TrainerIcon from "@material-ui/icons/Person";
import { racehorse360 } from "@tsg/1st-grpc-web";
import { getHorseAge } from "utils/horse";
import { parseGender } from "utils/enum-parser";
import { useLoggedInUser } from "components/LoggedInUserProvider";
import { useRacehorse360Api } from "hooks/api";
import { SortOrderExtended, SortOrder } from "interfaces/SortOrder";
import { MuiTheme } from "theme";
import useStyles, {
  ITEM_HEIGHT,
  ITEM_HEIGHT_DOWN_XS,
  INPUT_BOTTOM_MARGIN,
  INPUT_BOTTOM_MARGIN_XS,
  IStylesProps
} from "./styles";

export enum AppSearchType {
  Horses,
  Trainers,
  Both
}

type HorsesAndTrainersMix = (racehorse360.IHorse | racehorse360.ITrainer)[];

interface AppSearchClassNames {
  root?: string;
  itemsList?: string;
  formContent?: string;
}

interface Props {
  placeholder?: string;
  value?: string;
  searchType: AppSearchType;
  isForceSearch?: boolean;
  onSearch?: (value: string) => void;
  onRedirect?: (horseId: string) => void;
  onForceSearch?: (
    value: string,
    item: racehorse360.ITrainer | racehorse360.IHorse | null
  ) => void;
  className?: string;
  classNames?: AppSearchClassNames;
  vetWorkoutRequestsFilter?: racehorse360.IWorkoutRequestFilter;
  vetWorkoutExamsFilter?: racehorse360.IWorkoutExamFilter;
  clearSearch?: boolean;
  onClearSearch?: (value: boolean) => void;
  isRedirect?: boolean;
  showFullList?: boolean;
  showListOpened?: boolean;
  isCloseOnClickAway?: boolean;
  activeHorse?: racehorse360.IHorse | null;
  onCloseDialog?: () => void;
  showClearSearchButton?: boolean;
  onItemClick?: (item: racehorse360.Horse | racehorse360.Trainer) => void;
}

//TODO: reduce complexity
//eslint-disable-next-line complexity
const AppSearch = (props: Props) => {
  const {
    placeholder,
    searchType,
    className,
    isForceSearch,
    onSearch,
    classNames,
    isRedirect = false,
    onRedirect,
    showFullList = false,
    showListOpened = false,
    isCloseOnClickAway = true,
    activeHorse = null,
    onCloseDialog,
    showClearSearchButton = false,
    vetWorkoutRequestsFilter,
    vetWorkoutExamsFilter,
    clearSearch,
    onClearSearch,
    onItemClick
  } = props;

  const [stylesProps, setStylesProps] = useState<IStylesProps>();
  const classes = useStyles(stylesProps);
  const theme = useTheme<MuiTheme>();
  const formRef = useRef<HTMLFormElement>();
  const listContainerRef = useRef(null);
  const matchesDownXs = useMediaQuery(theme.breakpoints.down("xs"), {
    noSsr: true
  });
  const [focused, setFocused] = useState<boolean>(false);
  const [horseItems, setHorseItems] = useState<racehorse360.IHorse[]>([]);
  const [trainerItems, setTrainerItems] = useState<racehorse360.ITrainer[]>([]);
  const [horseAndTrainerItems, setHorseAndTrainerItems] =
    useState<HorsesAndTrainersMix>([]);
  const [isListOpened, setIsListOpened] = useState<boolean>(showFullList);
  const [value, setValue] = useState<string>(props.value || "");
  const [searchValue, setSearchValue] = useState<string>(value);
  const { currentUser } = useLoggedInUser();
  const [activeItemIndex, setActiveItemIndex] = useState<number>(null);
  const itemHeight = matchesDownXs ? ITEM_HEIGHT_DOWN_XS : ITEM_HEIGHT;

  const {
    useListTrainers,
    useListHorses,
    useListWorkoutRequests,
    useListWorkoutExams,
    usePullHorsesOrTrainersByName
  } = useRacehorse360Api();

  let facilityIds: string[];

  if (currentUser.isVeterinarian) {
    facilityIds = currentUser.vetFacilityIds;
  } else if (currentUser.isRacingOfficial) {
    facilityIds = currentUser.racingOfficialFacilityIds;
  }

  const shouldRequestHorses: boolean =
    (searchValue.length > 2 &&
      (searchType === AppSearchType.Horses ||
        searchType === AppSearchType.Both)) ||
    showFullList;

  const shouldRequestTrainers: boolean =
    searchValue.length > 2 &&
    (searchType === AppSearchType.Trainers ||
      searchType === AppSearchType.Both);

  const shouldRequestHorsesAndTrainers: boolean =
    searchValue.length > 2 && searchType === AppSearchType.Both;

  const {
    isLoading: areTrainersAndHorsesLoading,
    isFetching: areTrainersAndHorsesFetching
  } = usePullHorsesOrTrainersByName(
    {
      query: {
        horseOrTrainerName: { similarOrContains: searchValue }
      },
      pagingOptions: {
        maxResults: 99
      }
    },
    {
      refetchOnWindowFocus: false,
      enabled:
        shouldRequestHorsesAndTrainers &&
        !vetWorkoutRequestsFilter &&
        !vetWorkoutExamsFilter,

      onSuccess: data => {
        const horseAndTrainers = data?.horsesOrTrainers.map(item =>
          item.horse ? item.horse : item.trainer
        );
        setHorseAndTrainerItems(horseAndTrainers);
      }
    }
  );

  const {
    isLoading: isWorkoutRequestsLoading,
    isFetching: isWorkoutRequestsFetching
  } = useListWorkoutRequests(
    {
      query: {
        horseOrTrainerName: { similarOrContains: searchValue },
        ...vetWorkoutRequestsFilter
      },
      pagingOptions: {
        maxResults: 99
      },
      getOptions: {
        select: [
          "horse.name",
          "horse.trainer.firstName",
          "horse.trainer.lastName",
          "horse.gender",
          "horse.birthday"
        ],
        orderBy: [
          `horseOrTrainerName ${SortOrderExtended.ASC_SIMILARITY}`,
          `horse.name ${SortOrder.ASC}`
        ]
      }
    },
    {
      refetchOnWindowFocus: false,
      enabled: shouldRequestHorses && Boolean(vetWorkoutRequestsFilter),

      onSuccess: result => {
        const items: racehorse360.IHorse[] = result?.workoutRequests
          .map((wr: racehorse360.IWorkoutRequest) => wr.horse)
          .reduce<racehorse360.IHorse[]>(
            (acc: racehorse360.IHorse[], cur: racehorse360.IHorse) =>
              !acc.some(h => h.id === cur.id) ? [...acc, cur] : acc,
            []
          );
        setHorseItems(items);
      }
    }
  );

  const {
    isLoading: isWorkoutExamsLoading,
    isFetching: isWorkoutExamsFetching
  } = useListWorkoutExams(
    {
      query: {
        horseOrTrainerName: { similarOrContains: searchValue },
        ...vetWorkoutExamsFilter
      },
      pagingOptions: {
        maxResults: 99
      },
      getOptions: {
        select: [
          "horse.name",
          "horse.trainer.firstName",
          "horse.trainer.lastName",
          "horse.gender",
          "horse.birthday"
        ],
        orderBy: [
          `horseOrTrainerName ${SortOrderExtended.ASC_SIMILARITY}`,
          `horse.name ${SortOrder.ASC}`
        ]
      }
    },
    {
      refetchOnWindowFocus: false,
      enabled: shouldRequestHorses && Boolean(vetWorkoutExamsFilter),

      onSuccess: result => {
        const items: racehorse360.IHorse[] = result?.workoutExams
          .map((we: racehorse360.IWorkoutExam) => we.horse)
          .reduce<racehorse360.IHorse[]>(
            (acc: racehorse360.IHorse[], cur: racehorse360.IHorse) =>
              !acc.some(h => h.id === cur.id) ? [...acc, cur] : [...acc],
            []
          );
        setHorseItems(items);
      }
    }
  );

  const { isLoading: isHorsesLoading, isFetching: isHorsesFetching } =
    useListHorses(
      {
        query: {
          name: searchValue.length ? { similarOrContains: searchValue } : null,
          trainerIds: currentUser.isTrainer ? currentUser.trainerIds : null,
          facilityIds
        },
        pagingOptions: {
          maxResults: 99
        },
        getOptions: {
          select: [
            "name",
            "trainer.firstName",
            "trainer.lastName",
            "gender",
            "birthday"
          ],
          orderBy: searchValue.length
            ? [
                `name ${SortOrderExtended.ASC_SIMILARITY}`,
                `name ${SortOrder.ASC}`
              ]
            : [`name ${SortOrder.ASC}`]
        }
      },
      {
        refetchOnWindowFocus: false,
        enabled:
          shouldRequestHorses &&
          !vetWorkoutRequestsFilter &&
          !vetWorkoutExamsFilter,

        onSuccess: result => {
          const items: racehorse360.IHorse[] = result?.horses || [];

          if (activeHorse && items.length && !value) {
            const horseActiveIndex = items.findIndex(
              horse => horse.id === activeHorse.id
            );

            horseActiveIndex !== -1 && items.splice(horseActiveIndex, 1);
            items.unshift(activeHorse);
          }
          setHorseItems(items);
        }
      }
    );

  const { isLoading: isTrainersLoading, isFetching: isTrainersFetching } =
    useListTrainers(
      {
        query: {
          fullName: { similarOrContains: searchValue },
          horseSubFilter: { facilityIds }
        },
        pagingOptions: {
          maxResults: 99
        },
        getOptions: {
          select: ["firstName", "lastName"],
          orderBy: [
            `fullName ${SortOrderExtended.ASC_SIMILARITY}`,
            `fullName ${SortOrder.ASC}`
          ]
        }
      },
      {
        refetchOnWindowFocus: false,
        enabled:
          shouldRequestTrainers &&
          !vetWorkoutRequestsFilter &&
          !vetWorkoutExamsFilter,

        onSuccess: result => {
          const items: racehorse360.ITrainer[] = result?.trainers || [];
          setTrainerItems(items);
        }
      }
    );

  const isLoading = [
    isWorkoutRequestsLoading,
    isWorkoutRequestsFetching,
    isWorkoutExamsLoading,
    isWorkoutExamsFetching,
    isHorsesLoading,
    isHorsesFetching,
    isTrainersLoading,
    isTrainersFetching,
    areTrainersAndHorsesLoading,
    areTrainersAndHorsesFetching
  ].some(item => item);

  const items = horseAndTrainerItems.length
    ? horseAndTrainerItems
    : [...horseItems, ...trainerItems];

  const selectItem = item => {
    let name: string;
    if (item instanceof racehorse360.Trainer) {
      name = item.firstName + " " + item.lastName;
    } else if (item instanceof racehorse360.Horse) {
      name = item.name;
    } else {
      name = item;
    }
    setValue(name);
    setIsListOpened(false);

    if (item.name?.length > 2 && isRedirect) {
      if (activeHorse?.id === item.id) {
        //Close pop up when click on the green active horse row
        setIsListOpened(false);
        onCloseDialog();
      } else {
        onRedirect(item.id);
      }
    } else {
      checkSearch(name, true);
    }
  };

  const checkSearch = (value: string, submit?: boolean) => {
    setSearchValue(value);

    if ((value !== searchValue && !isForceSearch) || submit) {
      onSearch && onSearch(value);
    }
  };

  const isItemInView = index => {
    const viewStart = listContainerRef.current.scrollTop;
    const viewEnd = viewStart + listContainerRef.current.offsetHeight;
    return (
      index * itemHeight + itemHeight * 1.5 < viewEnd &&
      index * itemHeight - itemHeight / 2 > viewStart
    );
  };

  const handleKeyDown = event => {
    if (isListOpened) {
      switch (event.key) {
        case "Up":
        case "ArrowUp": {
          event.preventDefault();
          const nextActive =
            activeItemIndex > 0 ? activeItemIndex - 1 : items.length - 1;
          if (!isItemInView(nextActive)) {
            listContainerRef.current.scrollTop =
              nextActive * itemHeight - itemHeight / 2;
          }
          setActiveItemIndex(nextActive);
          break;
        }
        case "Down":
        case "ArrowDown": {
          event.preventDefault();
          let nextActive = 0;
          if (activeItemIndex !== null) {
            nextActive =
              activeItemIndex < items.length - 1 ? activeItemIndex + 1 : 0;
          }
          if (!isItemInView(nextActive)) {
            listContainerRef.current.scrollTop =
              nextActive * itemHeight -
              listContainerRef.current.offsetHeight +
              itemHeight * 1.5;
          }
          setActiveItemIndex(nextActive);
          break;
        }
      }
    }
  };

  const handleValueChange = event => {
    const value = event.target.value;
    setValue(value);
    setActiveItemIndex(null);

    if (value.length > 2) {
      setIsListOpened(true);
      checkSearch(value);
    } else {
      setIsListOpened(false);
      checkSearch("");
    }
  };

  const handleClear = () => {
    setValue("");
    !showListOpened && setIsListOpened(false);
    checkSearch("", true);
  };

  const handleInputFocus = () => {
    setFocused(true);
  };

  const handleInputBlur = () => {
    setFocused(false);
  };

  const handleItemClick = item => () => {
    selectItem(item);
    onItemClick?.(item);
  };

  const handleClickAway = () => {
    isCloseOnClickAway && setIsListOpened(false);
  };

  const handleSubmit = () => {
    if (activeItemIndex !== null) {
      selectItem(items[activeItemIndex]);
    } else if (isForceSearch) {
      checkSearch(searchValue, true);
    }

    !showListOpened && setIsListOpened(false);
    setFocused(false);
  };

  useEffect(() => {
    clearSearch && handleClear();
    onClearSearch && onClearSearch(false);
  }, [clearSearch]);

  useEffect(() => {
    if (focused && value.length > 2) {
      setIsListOpened(true);
    }
  }, [focused]);

  useEffect(() => {
    if (isListOpened) {
      const listContainerRelativeTop =
        formRef.current.offsetHeight +
        (matchesDownXs ? INPUT_BOTTOM_MARGIN_XS : INPUT_BOTTOM_MARGIN);
      const listContainerAbsoluteTop =
        formRef.current.getBoundingClientRect().bottom +
        (matchesDownXs ? INPUT_BOTTOM_MARGIN_XS : INPUT_BOTTOM_MARGIN);
      setStylesProps({
        listContainerRelativeTop,
        listContainerAbsoluteTop
      });
    }
  }, [isListOpened]);

  const renderHighLightedItem = item => {
    const horseOrFullTrainerName =
      item instanceof racehorse360.Horse
        ? item.name
        : item.firstName + " " + item.lastName;

    const format = /[ !"#$%&'()*+,./:;<=>?@[\\\]^_`{|}~]/g;

    let safeSearchValue: string;

    if (format.test(searchValue)) {
      safeSearchValue = searchValue.replace(format, "\\$&");
    } else {
      safeSearchValue = searchValue;
    }

    const regexPattern = `(.*)(${safeSearchValue})(.*)`;
    const result = new RegExp(regexPattern, "i").exec(horseOrFullTrainerName);
    let output;

    if (result) {
      output = (
        <>
          {result[1]}
          <b>{result[2]}</b>
          {result[3]}
        </>
      );
    } else {
      output = horseOrFullTrainerName;
    }

    return output;
  };

  const renderHorse = (item: racehorse360.IHorse) => {
    const isActive = activeHorse?.id === item.id && !value;

    return (
      <div className={clsx(classes.item, { [classes.itemActive]: isActive })}>
        <div className={classes.itemHorseName}>
          {isActive ? (
            <CheckIcon className={classes.checkIcon} />
          ) : (
            <HorseIcon
              className={clsx(classes.itemIcon, classes.itemNotActive)}
            />
          )}
          <div className={classes.itemHorseNameTitle}>
            {renderHighLightedItem(item)}
          </div>
        </div>

        <div
          className={clsx(classes.itemHorseDetails, {
            [classes.itemHorseDetailsTrainer]: !currentUser.isTrainer
          })}
        >
          {currentUser.isTrainer ? (
            <>
              <div>{`${getHorseAge(item)} years old`}</div>
              <div className={classes.itemHorseDetailsGender}>
                {parseGender(item.gender)}
              </div>
            </>
          ) : (
            <>
              <div>
                {`${item.trainer?.firstName || "-"} ${
                  item.trainer?.lastName || "-"
                }`}
              </div>
              <TrainerIcon
                className={clsx(
                  classes.itemIcon,
                  classes.itemHorseTrainerIcon,
                  {
                    [classes.itemActive]: isActive,
                    [classes.itemNotActive]: !isActive
                  }
                )}
              />
            </>
          )}
        </div>
      </div>
    );
  };

  const renderTrainer = (item: racehorse360.ITrainer) => {
    return (
      <div className={classes.item}>
        <div className={classes.itemTrainerName}>
          <TrainerIcon
            className={clsx(classes.itemIcon, classes.itemTrainerIcon)}
          />
          <div className={classes.itemTrainerNameTitle}>
            {renderHighLightedItem(item)}
          </div>
        </div>
      </div>
    );
  };

  const renderList = () => {
    if (isLoading) {
      return <Loader className={classes.searchLoader} size={31} />;
    }

    return (
      <List
        component="ul"
        className={classes.dropDownSearchList}
        aria-label="drop-down search list"
        disablePadding
      >
        {renderListItems()}
      </List>
    );
  };

  const renderListItems = () => {
    if (items.length) {
      return items.map((item, index) => (
        <ListItem
          className={clsx(
            classes.dropDownSearchListItem,
            index % 2 ? classes.rowOdd : classes.rowEven,
            {
              [classes.activeDropDownSearchListItem]: index === activeItemIndex
            }
          )}
          key={index}
          component="li"
          divider
          onClick={handleItemClick(item)}
        >
          {item instanceof racehorse360.Trainer
            ? renderTrainer(item)
            : renderHorse(item)}
        </ListItem>
      ));
    }

    return (
      <ListItem
        className={clsx(classes.dropDownSearchListItem, {
          [classes.noSearchResultButton]: showClearSearchButton && !items.length
        })}
        component="li"
      >
        <SearchNoResults
          classname={!showClearSearchButton && classes.searchNoResults}
          value={value}
          onClick={handleClear}
          showClearSearchButton={showClearSearchButton}
        />
      </ListItem>
    );
  };

  return (
    <ClickAwayListener onClickAway={handleClickAway}>
      <div className={clsx(classes.appSearch, className, classNames?.root)}>
        <form
          ref={formRef}
          action={"#"}
          className={clsx(classes.formContent, classNames?.formContent, {
            [classes.formFocused]: focused
          })}
          onSubmit={handleSubmit}
        >
          <SearchIcon
            className={clsx(classes.searchIcon, {
              [classes.iconFocused]: focused
            })}
          />
          <TextInputBase
            className={classes.inputBase}
            classes={{
              input: clsx(classes.input, {
                [classes.inputBaseFocused]: focused
              })
            }}
            placeholder={placeholder}
            type="search"
            value={value}
            onChange={handleValueChange}
            onFocus={handleInputFocus}
            onBlur={handleInputBlur}
            onEnter={handleSubmit}
            onKeyDown={handleKeyDown}
          />
          {!!value.length && (
            <CloseIcon
              className={clsx(classes.closeIcon, {
                [classes.closeIconFocused]: focused || value.length
              })}
              onClick={handleClear}
            />
          )}
        </form>
        {isListOpened && (
          <div
            ref={listContainerRef}
            className={clsx(classes.items, classNames?.itemsList)}
          >
            {renderList()}
          </div>
        )}
      </div>
    </ClickAwayListener>
  );
};

export default React.memo(AppSearch);
