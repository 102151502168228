import React, { useEffect, useState } from "react";
import format from "date-fns/format";
import formatISO from "date-fns/formatISO";
import parseISO from "date-fns/parseISO";
import Comment from "@material-ui/icons/Comment";
import MoreHorizIcon from "@material-ui/icons/MoreHoriz";
import clsx from "clsx";

import { racehorse360 } from "@tsg/1st-grpc-web";
import Loader from "components/Loader";
import ScheduleDateMenu from "components/ScheduleDateMenu";
import { useRacehorse360Api } from "hooks/api";
import useStyles from "./styles";
import { useSnackbar } from "components/SnackbarContext/SnackbarContext";

export interface Props {
  isToday?: boolean;
  workoutRequestData: racehorse360.IWorkoutRequest;
  className?: string;
  onUpdate?: (
    id?: string,
    workoutRequest?: racehorse360.IWorkoutRequest
  ) => void;
  onUpdateError?: () => void;
  horse: racehorse360.IHorse;
  isWide?: boolean;
}

const ScheduledDate = React.memo((props: Props) => {
  const {
    className,
    isToday,
    workoutRequestData,
    horse,
    isWide,
    onUpdate,
    onUpdateError
  } = props;

  const classes = useStyles({
    backgroundColor: workoutRequestData?.facility?.backgroundColor,
    strokeColor: workoutRequestData?.facility?.strokeColor
  });
  const [cardEl, setCardEl] = useState<HTMLDivElement>(null);
  const date = parseISO(workoutRequestData?.date);
  const [isMenuOpen, setIsMenuOpen] = useState<boolean>(false);
  const [workoutRequest, setWorkoutRequest] =
    useState<racehorse360.IWorkoutRequest>(workoutRequestData);

  const { useCancelWorkoutRequest, useMoveFacilityWorkoutRequest } =
    useRacehorse360Api();

  const {
    isLoading: isCancelWorkoutLoading,
    mutateAsync: cancelWorkoutRequest
  } = useCancelWorkoutRequest();

  const {
    isLoading: isUpdateWorkoutLoading,
    mutateAsync: moveFacilityWorkoutRequest
  } = useMoveFacilityWorkoutRequest();

  const { showErrorSnack } = useSnackbar();

  const isLoading = isCancelWorkoutLoading || isUpdateWorkoutLoading;

  const facility = workoutRequest.facility;
  const facilityCode = facility?.code.trim();

  const handleCardClick = (event: React.MouseEvent<HTMLDivElement>) => {
    if (!isLoading) {
      if (!cardEl) {
        setCardEl(event.currentTarget);
      }
      setIsMenuOpen(true);
    }
  };

  const handleWorkoutChange = (workoutData: racehorse360.IWorkout) => {
    if (workoutData.facility) {
      moveFacilityWorkoutRequest({
        id: workoutData.id,
        facilityId: workoutData.facility.id,
        getOptions: {
          select: [
            "id",
            "facility.name",
            "facility.code",
            "facility.background_color",
            "facility.foreground_color",
            "facility.stroke_color",
            "date",
            "status",
            "hasComment"
          ]
        }
      })
        .then(response => {
          onUpdate(response.id, response);
          setWorkoutRequest(response);
        })
        .catch(error => {
          console.error(error);
          onUpdateError && onUpdateError();
        });
    } else {
      cancelWorkoutRequest({
        id: workoutData.id
      })
        .catch(error => showErrorSnack(error.toString()))
        .then(() => {
          onUpdate(workoutRequest.id, null);
          setWorkoutRequest({
            date: workoutData.date
          });
        })
        .catch(error => console.error(error));
    }
  };

  const handleMenuClose = () => {
    setIsMenuOpen(false);
  };

  const handleMenuOpen = () => {
    setIsMenuOpen(true);
  };

  useEffect(() => {
    setWorkoutRequest(workoutRequestData);
  }, [workoutRequestData]);

  return (
    <div
      className={clsx(classes.card, classes[facilityCode], className, {
        [classes.widePane]: isWide
      })}
      onClick={handleCardClick}
      data-test-element="CALENDAR_DAY"
      data-test-value={formatISO(date, { representation: "date" })}
      data-test-state={"SCHEDULED"}
    >
      {isLoading && <Loader className={classes.loader} />}
      {workoutRequest && (
        <>
          <div className={classes.cardHeader}>{facilityCode}</div>
          <div className={classes.cardContent}>
            <span
              className={clsx(classes.date, {
                [classes.squared]: isToday
              })}
            >
              {isWide ? format(date, "iii M/d") : date.getDate()}
            </span>
          </div>
          <div className={classes.cardActions}>
            <MoreHorizIcon className={classes.moreHorizIcon} />
            {workoutRequest.hasComment && (
              <Comment className={classes.commentIcon} />
            )}
          </div>
        </>
      )}
      {cardEl && (
        <ScheduleDateMenu
          horse={horse}
          anchorEl={cardEl}
          workoutData={workoutRequest}
          onChange={handleWorkoutChange}
          onClose={handleMenuClose}
          onOpen={handleMenuOpen}
          isOpen={isMenuOpen}
        />
      )}
    </div>
  );
});

export default React.memo(ScheduledDate);
