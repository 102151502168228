import React from "react";
import clsx from "clsx";

import FilterListIcon from "@material-ui/icons/FilterList";
import IconButton from "@material-ui/core/IconButton";
import Button from "@material-ui/core/Button";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import { Theme } from "@material-ui/core/styles";

import Breakpoints from "common/breakpoints";
import useStyles from "./styles";

interface IProps {
  onClearFilters: () => void;
  onOpenFilters: () => void;
  isOpenFilters: boolean;
  hasSavedFilters: boolean;
}

const FilterBar = (props: IProps) => {
  const classes = useStyles();
  const { onOpenFilters, isOpenFilters, onClearFilters, hasSavedFilters } =
    props;

  const matchesDownSX720 = useMediaQuery((theme: Theme) =>
    theme.breakpoints.down(Breakpoints.SX_720)
  );

  return (
    <div className={classes.filterBar}>
      {hasSavedFilters && !matchesDownSX720 && (
        <Button
          className={classes.resetButton}
          variant="outlined"
          onClick={onClearFilters}
        >
          Clear Filters
        </Button>
      )}

      <IconButton className={classes.filtersButton} onClick={onOpenFilters}>
        <FilterListIcon
          className={clsx(classes.filterListIcon, {
            [classes.filterListIconActive]: isOpenFilters
          })}
        />
        {hasSavedFilters && <div className={classes.filterMark} />}
      </IconButton>
    </div>
  );
};

export default FilterBar;
