import { makeStyles } from "@material-ui/core/styles";
import { MuiTheme } from "theme";
import Breakpoints from "common/breakpoints";

export default makeStyles((theme: MuiTheme) => ({
  pageHeader: {
    padding: "8px 24px",
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    [theme.breakpoints.down("xs")]: {
      minHeight: 50,
      padding: "8px 16px"
    }
  },
  facilitySelect: {
    "& .MuiTypography-root": {
      fontSize: 20,
      [theme.breakpoints.down("xs")]: {
        fontSize: 18
      }
    },
    [theme.breakpoints.down("xs")]: {
      padding: 0
    }
  },
  snackbar: {
    bottom: 60,
    display: "flex",
    justifyContent: "center",
    "& .MuiAlert-root": {
      padding: "6px 12px"
    },
    [theme.breakpoints.up(Breakpoints.SX_720)]: {
      width: `calc(100% - ${theme.widthTabletDrawer}px)`,
      left: "55%"
    },
    [theme.breakpoints.up(Breakpoints.LG_1280)]: {
      width: `calc(100% - ${theme.widthDesktopDrawer}px)`
    }
  },
  successSnack: {
    backgroundColor: "rgba(72, 155, 119, 1)"
  },
  successSnackMessage: {
    fontSize: 16
  },
  appPageContent: {
    zIndex: 1,
    [theme.breakpoints.up("sm")]: {
      padding: 24
    },
    [theme.breakpoints.down("xs")]: {
      "& .MuiGrid-spacing-xs-2": {
        width: "100%",
        margin: 0,
        "& .MuiGrid-item": {
          padding: 0
        }
      }
    }
  },
  columnPaper: {
    flex: "auto",
    minHeight: "100%",
    height: "100%",
    display: "flex"
  },
  detailsWrapper: {
    [theme.breakpoints.down("xs")]: {
      display: "none"
    }
  },
  hideDownXs: {
    [theme.breakpoints.down("xs")]: {
      display: "none"
    }
  },
  compactDownXs: {
    [theme.breakpoints.down("xs")]: {
      height: 30,
      maxWidth: 46,
      minWidth: 46,
      padding: "6px 12px 4px",
      "& $newWorkOrderButtonText": {
        display: "none"
      },
      "& $newOrderButtonIcon": {
        margin: 0
      }
    }
  }
}));
