import React from "react";
import clsx from "clsx";
import addDays from "date-fns/addDays";
import format from "date-fns/format";
import parseISO from "date-fns/parseISO";
import { ClassNameMap } from "@material-ui/core/styles/withStyles";
import Button from "@material-ui/core/Button";
import ChatBubbleOutlineIcon from "@material-ui/icons/ChatBubbleOutline";
import DeleteIcon from "@material-ui/icons/Delete";
import EditIcon from "@material-ui/icons/Edit";

import { racehorse360 } from "@tsg/1st-grpc-web";
import Vaccination from "components/Icons/Vaccination";
import { useLoggedInUser } from "components/LoggedInUserProvider";
import { getPersonFullName } from "utils/person";
import useStyles from "./styles";

interface Props {
  classes?: ClassNameMap<"root">;
  data: racehorse360.IInjection | racehorse360.IVaccinationComment;
  onDelete: (
    data: racehorse360.IInjection | racehorse360.IVaccinationComment
  ) => void;
  onUpdate: (
    data: racehorse360.IInjection | racehorse360.IVaccinationComment
  ) => void;
}

const VaccinationActivityEntry = (props: Props) => {
  const { classes, data, onDelete, onUpdate } = props;
  const ownClasses = useStyles();
  const { currentUser } = useLoggedInUser();

  const isInjection = data instanceof racehorse360.Injection;
  const isComment = data instanceof racehorse360.VaccinationComment;

  const formatDate = (dateString, pattern = "M/dd/yyyy") => {
    return dateString ? format(parseISO(dateString), pattern) : "N/A";
  };

  const formatDateWithOffset = (dateString, offset, pattern = "M/dd/yyyy") => {
    return dateString
      ? format(addDays(parseISO(dateString), offset), pattern)
      : "N/A";
  };

  const handleDeleteClick = () => {
    onDelete(data);
  };
  const handleEditClick = () => {
    onUpdate(data);
  };

  const renderIcon = () => {
    if (isInjection) {
      return <Vaccination className={ownClasses.vaccinationIcon} />;
    }
    if (isComment) {
      return <ChatBubbleOutlineIcon className={ownClasses.commentIcon} />;
    }
  };

  const renderContent = () => {
    if (isInjection) {
      const injection = data as racehorse360.Injection;
      return (
        <>
          <div className={ownClasses.entryHeader}>
            <div className={ownClasses.entryTitle}>
              {injection.medicine.name}
              <span className={ownClasses.manufacturer}>
                {injection.manufacturer ? (
                  <>&nbsp;-&nbsp;{injection.manufacturer}</>
                ) : (
                  ""
                )}
              </span>
            </div>
            <div className={ownClasses.entryButtons}>
              {(injection.createdBy.id === currentUser.rh360Id ||
                currentUser.isSeniorVet ||
                currentUser.isChiefVet) && (
                <>
                  <Button
                    variant={"outlined"}
                    className={ownClasses.entryButton}
                    onClick={handleDeleteClick}
                  >
                    <DeleteIcon />
                  </Button>
                  <Button
                    variant={"outlined"}
                    className={ownClasses.entryButton}
                    onClick={handleEditClick}
                  >
                    <EditIcon />
                  </Button>
                </>
              )}
            </div>
          </div>
          <div className={ownClasses.datesWrapper}>
            <div className={ownClasses.activityRange}>
              {formatDate(injection.injectionDate)} -{" "}
              {injection.medicine?.expiredInDays
                ? formatDateWithOffset(
                    injection.injectionDate,
                    injection.medicine.expiredInDays
                  )
                : formatDate(injection.customExpirationDate)}
            </div>
            <div className={ownClasses.reportDetails}>
              {injection.dataSource ===
              racehorse360.InjectionDataSource.INJECTION_DATA_SOURCE_RH360
                ? getPersonFullName(injection.createdBy, "%F %L")
                : "Incompass"}{" "}
              <span className={ownClasses.noWrap}>
                {formatDate(injection.updatedOn, "M/dd/yyyy - h:mm a")}
              </span>
            </div>
          </div>
          <div className={ownClasses.comment}>{injection.comment}</div>
        </>
      );
    }
    if (isComment) {
      const comment = data as racehorse360.VaccinationComment;
      const shouldShowActionsButtons =
        !currentUser.isRegulatoryVet &&
        comment.createdBy.id === currentUser.rh360Id;

      return (
        <>
          <div className={ownClasses.entryHeader}>
            <div
              className={clsx(ownClasses.entryTitle, ownClasses.commentTitle)}
            >
              <span>{getPersonFullName(comment.createdBy, "%F %L")}</span>
              <span>{formatDate(comment.updatedOn, "M/dd/yyyy - h:mm a")}</span>
            </div>
            <div className={ownClasses.entryButtons}>
              {shouldShowActionsButtons && (
                <>
                  <Button
                    variant={"outlined"}
                    className={ownClasses.entryButton}
                    onClick={handleDeleteClick}
                  >
                    <DeleteIcon />
                  </Button>
                  <Button
                    variant={"outlined"}
                    className={ownClasses.entryButton}
                    onClick={handleEditClick}
                  >
                    <EditIcon />
                  </Button>
                </>
              )}
            </div>
          </div>
          <div>{comment.body}</div>
        </>
      );
    }
  };

  return (
    <div className={clsx(ownClasses.root, classes?.root)}>
      <div className={ownClasses.iconWrapper}>{renderIcon()}</div>
      <div className={ownClasses.detailsWrapper}>{renderContent()}</div>
    </div>
  );
};

export default VaccinationActivityEntry;
