import React, { useRef, useState } from "react";
import clsx from "clsx";
import format from "date-fns/format";
import parseISO from "date-fns/parseISO";
import utcToZonedTime from "date-fns-tz/utcToZonedTime";

import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
import IconButton from "@material-ui/core/IconButton";
import Button from "@material-ui/core/Button";
import Table from "@material-ui/core/Table";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import { Theme } from "@material-ui/core/styles";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import CloseIcon from "@material-ui/icons/Close";
import EditIcon from "@material-ui/icons/Edit";
import EmailIcon from "@material-ui/icons/Email";
import PhoneIcon from "@material-ui/icons/Phone";
import PersonAddIcon from "@material-ui/icons/PersonAdd";
import CheckCircleIcon from "@material-ui/icons/CheckCircle";
import RefreshIcon from "@material-ui/icons/Refresh";

import { racehorse360 } from "@tsg/1st-grpc-web";

import Breakpoints from "common/breakpoints";
import ClosedDoor from "components/Icons/ClosedDoor";
import PersonEdit from "components/Icons/PersonEdit";
import ReOpen from "components/Icons/ReOpen";
import { useLoggedInUser } from "components/LoggedInUserProvider";
import SwipeableTabs from "components/SwipeableTabs";
import { parseWorkOrderStatus } from "utils/enum-parser";
import { convertMinutesToDaysHours } from "utils/date-utils";
import { useRacehorse360Api } from "hooks/api";
import { getPersonFullName } from "utils/person";
import WorkOrderPopover from "../WorkOrderPopover";
import { EPopoverType, getTabsStatuses } from "../helper";
import { getWorkOrderStatus } from "../../helper";
import WorkOrderOverlayForm from "../../WorkOrderOverlayForm";
import WorkOrderNotes from "./WorkOrderNotes";
import { SortOrder } from "interfaces/SortOrder";

import useStyles from "./styles";

interface IProps {
  selectedWorkOrder: racehorse360.IWorkOrder;
  onUpdateEditedWorkOrder: (workOrder: racehorse360.IWorkOrder) => void;
  onClose: () => void;
  onUpdate: (workOrder: racehorse360.IWorkOrder) => void;
  activeTab: racehorse360.WorkOrderStatus;
  isOpen: boolean;
}

enum EListContentTitles {
  STATUS = "Status",
  ORDER_TYPE = "Order Type",
  ID = "ID #",
  ASSIGNED_TO = "Assigned To",
  DATE_OPENED = "Date Opened",
  TIME_OPEN = "Time Open",
  DATE_ASSIGNED = "Date Assigned",
  TIME_ASSIGNED = "Time Assigned",
  DATE_COMPLETED = "Date Completed",
  DATE_CLOSED = "Date Closed"
}

interface IListContentItem {
  title: string;
  value: React.ReactNode | string | number;
  inactive?: boolean;
}

const getFormattedPhone = (phoneNumber: string) => {
  if (phoneNumber) {
    let formattedPhone: string = phoneNumber.trim();

    if (formattedPhone.length === 10) {
      formattedPhone = `(${formattedPhone.slice(0, 3)}) -
      ${formattedPhone.slice(3, 6)} - ${formattedPhone.slice(6)}`;
    }

    return formattedPhone;
  }

  return "-";
};

const MaintenanceWorkOrdersDetail = React.memo((props: IProps) => {
  const {
    onClose,
    selectedWorkOrder,
    onUpdateEditedWorkOrder,
    onUpdate,
    activeTab,
    isOpen
  } = props;
  const actionsRef = useRef();
  const classes = useStyles();
  const { currentUser } = useLoggedInUser();
  const matchesDownSM600 = useMediaQuery(
    (theme: Theme) => theme.breakpoints.down(Breakpoints.SM_600),
    { noSsr: true }
  );
  const [popoverType, setPopoverType] = useState<EPopoverType>(null);
  const [isEdit, setIsEdit] = useState<boolean>(false);
  const isEditable = currentUser.rh360Id === selectedWorkOrder.createdBy?.id;

  const { isCompleteTab } = getTabsStatuses(activeTab);

  const {
    isOpenedWorkOrder,
    isAssignedWorkOrder,
    isCompletedWorkOrder,
    isClosedWorkOrder,
    isCanceledWorkOrder
  } = getWorkOrderStatus(selectedWorkOrder);
  const isHideDetailsActionButton =
    currentUser.isMaintenanceStaff &&
    (isClosedWorkOrder || isCanceledWorkOrder);

  const { useListWorkOrderNotes } = useRacehorse360Api();

  const {
    isLoading: isNotesLoading,
    data: notesData,
    refetch: refetchNotes
  } = useListWorkOrderNotes(`${selectedWorkOrder.id}-list-notes`, {
    query: {
      workOrderId: selectedWorkOrder.id
    },
    pagingOptions: {
      maxResults: 9999
    },
    getOptions: {
      select: [
        "createdOn",
        "createdBy.firstName",
        "createdBy.lastName",
        "description",
        "isManual"
      ],
      orderBy: [`createdOn ${SortOrder.DESC}`]
    }
  });

  const handleActionClick = (popoverType: EPopoverType) => () => {
    setPopoverType(popoverType);
  };

  const handleEditClick = () => {
    setIsEdit(true);
  };

  const handleOnEditCancel = () => {
    setIsEdit(false);
  };

  const handlePopoverClose = () => {
    setPopoverType(null);
  };

  const handlePopoverConfirm = (workOrder: racehorse360.IWorkOrder) => {
    refetchNotes();
    onUpdate(workOrder);
    setPopoverType(null);
  };

  const renderAssignCell = () => {
    const canAssign = currentUser.hasPermission(
      "work_order:assign_to_other:at_facility",
      selectedWorkOrder?.facility.id
    );

    if (canAssign && isOpenedWorkOrder) {
      return (
        <Button
          className={clsx(classes.actionButton, classes.assignButton)}
          fullWidth
          startIcon={<PersonAddIcon />}
          onClick={handleActionClick(EPopoverType.ASSIGN)}
          disabled={!isOpenedWorkOrder}
        >
          Assign
        </Button>
      );
    }

    if (canAssign && isAssignedWorkOrder) {
      return (
        <>
          {getPersonFullName(selectedWorkOrder?.assignedUser, "%F %L")}{" "}
          <IconButton
            className={classes.reassignButton}
            onClick={handleActionClick(EPopoverType.REASSIGN)}
          >
            <PersonEdit className={classes.reassignButtonIcon} />
          </IconButton>
        </>
      );
    }

    return selectedWorkOrder?.assignedUser
      ? getPersonFullName(selectedWorkOrder?.assignedUser, "%F %L")
      : "-";
  };

  const renderListContent = () => {
    if (selectedWorkOrder) {
      let listValues: IListContentItem[] = [
        {
          title: EListContentTitles.STATUS,
          value: parseWorkOrderStatus(selectedWorkOrder.status)
        },
        {
          title: EListContentTitles.ORDER_TYPE,
          value: selectedWorkOrder.workOrderType.name
        },
        {
          title: EListContentTitles.ID,
          value: selectedWorkOrder.customerFacingId
        },
        {
          title: EListContentTitles.ASSIGNED_TO,
          value: renderAssignCell()
        },
        {
          title: EListContentTitles.DATE_OPENED,
          value: format(
            utcToZonedTime(
              parseISO(selectedWorkOrder.createdOn),
              selectedWorkOrder.facility.timezone
            ),
            "M/d/yy"
          )
        },
        {
          title: EListContentTitles.TIME_OPEN,
          value: convertMinutesToDaysHours(selectedWorkOrder?.minutesOpen),
          inactive: Boolean(selectedWorkOrder?.createdOn && !isOpenedWorkOrder)
        },
        {
          title: EListContentTitles.DATE_ASSIGNED,
          value: selectedWorkOrder.assignedOn
            ? format(
                utcToZonedTime(
                  parseISO(selectedWorkOrder.assignedOn),
                  selectedWorkOrder.facility.timezone
                ),
                "M/d/yy"
              )
            : "-"
        },
        {
          title: EListContentTitles.TIME_ASSIGNED,
          value:
            !selectedWorkOrder?.assignedOn && isOpenedWorkOrder
              ? "0 Days 0 Hours"
              : convertMinutesToDaysHours(selectedWorkOrder?.minutesAssigned),
          inactive: Boolean(
            (selectedWorkOrder?.assignedOn && !isAssignedWorkOrder) ||
              isOpenedWorkOrder
          )
        },
        {
          title: EListContentTitles.DATE_COMPLETED,
          value: selectedWorkOrder.completedOn
            ? format(
                utcToZonedTime(
                  parseISO(selectedWorkOrder.completedOn),
                  selectedWorkOrder.facility.timezone
                ),
                "M/d/yy"
              )
            : "-"
        },
        {
          title: EListContentTitles.DATE_CLOSED,
          value: selectedWorkOrder.closedOn
            ? format(
                utcToZonedTime(
                  parseISO(selectedWorkOrder.closedOn),
                  selectedWorkOrder.facility.timezone
                ),
                "M/d/yy"
              )
            : "-"
        }
      ];

      if (currentUser.isMaintenanceStaff) {
        listValues = listValues.filter(
          item => item.title !== EListContentTitles.ASSIGNED_TO
        );
      }

      return (
        <>
          {listValues.map((property: IListContentItem, index: number) => (
            <div className={classes.row} key={property.title + index}>
              <div className={classes.text}>{property.title}</div>
              <div
                className={clsx(classes.text, classes.rightColumnText, {
                  ["inactive"]: property.inactive
                })}
              >
                {property.value}
              </div>
            </div>
          ))}
        </>
      );
    }

    return null;
  };

  const renderTableContent = () => {
    const columnValues = [
      selectedWorkOrder?.facility?.name,
      selectedWorkOrder.barn?.name,
      selectedWorkOrder.stallNumber
    ];

    return (
      <Table>
        <TableHead>
          <TableRow key={Date.now()}>
            <TableCell className={classes.rightContentHeader}>Track</TableCell>
            {!isCompleteTab && (
              <TableCell
                align="center"
                width="74px"
                className={classes.rightContentHeader}
              >
                Barn #
              </TableCell>
            )}
            {!isCompleteTab && (
              <TableCell
                align="center"
                width="48px"
                className={classes.rightContentHeader}
              >
                Stall #
              </TableCell>
            )}
          </TableRow>
        </TableHead>
        <TableBody>
          <TableRow key={Date.now()}>
            {columnValues.map((value: string, index: number) => {
              if (isCompleteTab && index) {
                return;
              }

              return (
                <TableCell
                  key={value + index}
                  className={clsx(classes.tableCell, classes.tableContentText)}
                >
                  {value?.toLowerCase() || "-"}
                </TableCell>
              );
            })}
          </TableRow>
        </TableBody>
      </Table>
    );
  };

  const renderButtons = () => {
    const hasPermissionClose = currentUser.hasPermission(
      "work_order:close:at_facility",
      selectedWorkOrder?.facility.id
    );
    const hasPermissionComplete = currentUser.hasPermission(
      "work_order:complete:at_facility",
      selectedWorkOrder.facility.id
    );
    const hasPermissionReopen = currentUser.hasPermission(
      "work_order:reopen:at_facility",
      selectedWorkOrder.facility.id
    );

    return (
      <DialogActions ref={actionsRef} className={classes.dialogButtons}>
        {(isOpenedWorkOrder || isAssignedWorkOrder) && hasPermissionComplete && (
          <Button
            className={clsx(
              classes.actionButton,
              classes.completeActionButton,
              {
                [classes.actionButtonActive]:
                  popoverType === EPopoverType.COMPLETE
              }
            )}
            fullWidth
            startIcon={<CheckCircleIcon />}
            onClick={handleActionClick(EPopoverType.COMPLETE)}
          >
            {currentUser.isMaintenanceSupervisor ? "Complete" : "Mark Complete"}
          </Button>
        )}
        {isCompletedWorkOrder && (
          <Button
            className={clsx(classes.actionButton, classes.undoActionButton, {
              [classes.actionButtonActive]:
                popoverType === EPopoverType.UNDO_COMPLETE
            })}
            fullWidth
            startIcon={<RefreshIcon />}
            onClick={handleActionClick(EPopoverType.UNDO_COMPLETE)}
          >
            {currentUser.isMaintenanceSupervisor
              ? "Send Back"
              : "Undo Complete"}
          </Button>
        )}
        {(isClosedWorkOrder || isCanceledWorkOrder) && hasPermissionReopen && (
          <Button
            className={clsx(classes.actionButton, classes.reopenActionButton, {
              [classes.actionButtonActive]: popoverType === EPopoverType.REOPEN
            })}
            fullWidth
            startIcon={<ReOpen />}
            onClick={handleActionClick(EPopoverType.REOPEN)}
          >
            Reopen
          </Button>
        )}
        {hasPermissionClose && !isClosedWorkOrder && !isCanceledWorkOrder && (
          <Button
            className={clsx(classes.actionButton, classes.closeActionButton, {
              [classes.actionButtonActive]: popoverType === EPopoverType.CLOSE
            })}
            fullWidth
            startIcon={<ClosedDoor />}
            onClick={handleActionClick(EPopoverType.CLOSE)}
          >
            Close
          </Button>
        )}
      </DialogActions>
    );
  };

  const renderReport = () => (
    <>
      <div
        className={clsx(classes.orderStatus, classes[selectedWorkOrder.status])}
      >
        {parseWorkOrderStatus(selectedWorkOrder.status)}
      </div>
      <div className={classes.reporterInfo}>
        {isEditable && (
          <Button
            size={"small"}
            classes={{
              root: classes.editButton,
              label: classes.editButtonLabel,
              startIcon: classes.editButtonStartIcon
            }}
            startIcon={<EditIcon />}
            onClick={handleEditClick}
          >
            Edit
          </Button>
        )}
        <div className={classes.rightContentHeader}>Reported by</div>
        <div className={classes.reporterName}>
          {selectedWorkOrder?.fullName?.toLowerCase()}
        </div>
        <div className={classes.reporterContacts}>
          <PhoneIcon className={classes.icon} />
          <span className={classes.contactsText}>
            {getFormattedPhone(selectedWorkOrder?.phone)}
          </span>
        </div>
        <div className={classes.reporterContacts}>
          <EmailIcon className={classes.icon} />
          <span className={classes.contactsText}>
            {selectedWorkOrder?.email}
          </span>
        </div>
      </div>

      <div className={classes.orderDescription}>
        <div className={classes.descriptionWrap}>
          {selectedWorkOrder?.facility?.name && renderTableContent()}
          {matchesDownSM600 && (
            <div className={classes.orderType}>
              <div className={classes.rightContentHeader}>Order Type</div>
              <div
                className={clsx(classes.tableContentText, classes.leftAligning)}
              >
                {selectedWorkOrder?.workOrderType.name || "-"}
              </div>
            </div>
          )}
          <div className={classes.description}>
            <div className={classes.rightContentHeader}>Issue Description</div>
            <div className={classes.descriptionText}>
              {selectedWorkOrder?.description}
            </div>
          </div>
        </div>
      </div>

      {matchesDownSM600 && currentUser.isMaintenanceSupervisor && (
        <div className={classes.assignToBlock}>
          <div className={classes.rightContentHeader}>Assigned To</div>
          <div className={classes.tableContentText}>{renderAssignCell()}</div>
        </div>
      )}

      <WorkOrderNotes
        workOrderId={selectedWorkOrder.id}
        notes={notesData?.workOrderNotes}
        isLoading={isNotesLoading}
      />
    </>
  );

  const renderContent = () => {
    return matchesDownSM600 ? (
      <>
        <SwipeableTabs
          classes={{
            root: classes.swipeableTabsRoot,
            tabs: classes.swipeableTabsContent
          }}
          tabs={["Report", "Information"]}
        >
          {renderReport()}
          {renderListContent()}
        </SwipeableTabs>
        {!isHideDetailsActionButton && renderButtons()}
      </>
    ) : (
      <>
        <div className={classes.leftContentWrapper}>
          <div className={classes.leftContent}>
            {selectedWorkOrder && renderListContent()}
            {selectedWorkOrder && !isHideDetailsActionButton && renderButtons()}
          </div>
        </div>
        <div className={classes.rightContent}>{renderReport()}</div>
      </>
    );
  };

  return (
    <>
      <Dialog
        fullScreen={matchesDownSM600}
        open={!!selectedWorkOrder && isOpen}
        className={classes.dialogRoot}
      >
        <DialogTitle className={classes.dialogTitle}>
          <div className={classes.dialogTitleHeader}>
            {`Work Order #${selectedWorkOrder.customerFacingId}`}
          </div>
          <IconButton
            color="primary"
            className={classes.closeButton}
            onClick={onClose}
          >
            <CloseIcon />
          </IconButton>
        </DialogTitle>

        <DialogContent className={classes.dialogContent}>
          {renderContent()}
        </DialogContent>
      </Dialog>

      {isEditable && isEdit && (
        <WorkOrderOverlayForm
          selectedWorkOrder={selectedWorkOrder}
          onUpdateEditedWorkOrder={onUpdateEditedWorkOrder}
          isEdit
          isOpen={isEdit}
          onClose={handleOnEditCancel}
          facilityName={selectedWorkOrder.facility.name}
          facilityId={selectedWorkOrder.facility.id}
        />
      )}

      {Boolean(popoverType) && (
        <WorkOrderPopover
          className={classes.popover}
          type={popoverType}
          anchor={actionsRef?.current}
          workOrder={selectedWorkOrder}
          onSuccess={handlePopoverConfirm}
          onClose={handlePopoverClose}
          PopoverProps={{
            anchorOrigin: {
              vertical: "center",
              horizontal: "right"
            },
            transformOrigin: {
              vertical: "center",
              horizontal: "left"
            }
          }}
          isOpen
        />
      )}
    </>
  );
});

export default MaintenanceWorkOrdersDetail;
