import { makeStyles, Theme } from "@material-ui/core/styles";
import { MuiTheme } from "theme";
import Breakpoints from "common/breakpoints";

export const ITEM_HEIGHT = 47;
export const ITEM_HEIGHT_DOWN_XS = 60;
export const INPUT_HEIGHT = 30;
export const INPUT_BOTTOM_MARGIN = 8;
export const INPUT_BOTTOM_MARGIN_XS = 16;

export interface IStylesProps {
  listContainerRelativeTop: number;
  listContainerAbsoluteTop: number;
}

export default makeStyles<Theme, IStylesProps>((theme: MuiTheme) => ({
  appSearch: {
    maxWidth: 400,
    position: "relative",
    [theme.breakpoints.down(Breakpoints.SM_600)]: {
      position: "static"
    }
  },
  formContent: {
    position: "relative",
    flex: "auto"
  },
  searchIcon: {
    color: theme.palette.text.secondary,
    fontSize: 14,
    position: "absolute",
    top: theme.spacing(1),
    left: 10,
    zIndex: 1
  },
  inputBase: {
    width: "100%"
  },
  iconFocused: {
    color: theme.palette.text.primary
  },
  formFocused: {
    "& > input": {
      border: `1px solid ${theme.palette.common.black}`
    }
  },
  input: {
    width: "100%",
    height: INPUT_HEIGHT,
    borderRadius: 3,
    border: `1px solid ${theme.palette.text.hint}`,
    backgroundColor: "rgba(238, 238, 238, 0.25)",
    color: theme.palette.text.primary,
    padding: 0,
    paddingLeft: theme.spacing(4),
    paddingRight: theme.spacing(2),
    fontSize: 15,
    boxSizing: "border-box",
    transition: "all .2s",
    caretColor: theme.palette.secondary.dark,
    "&::-webkit-appearance": {
      display: "none"
    },
    "&::-webkit-search-decoration": {
      display: "none"
    },
    "&::-webkit-search-cancel-button": {
      display: "none"
    },
    "&::-webkit-search-results-button": {
      display: "none"
    },
    "&::-webkit-search-results-decoration": {
      display: "none"
    },
    "&::-webkit-clear-button": {
      display: "none"
    },
    "&[placeholder]": {
      textOverflow: "ellipsis"
    },
    "&::placeholder": {
      textOverflow: "ellipsis",
      fontSize: 14
    }
  },
  inputBaseFocused: {
    border: `1px solid ${theme.palette.common.black}`,
    backgroundColor: "#fff"
  },
  closeIcon: {
    display: "none",
    cursor: "pointer",
    fontSize: 20,
    color: theme.palette.text.primary,
    position: "absolute",
    top: 5,
    right: 6
  },
  closeIconFocused: {
    display: "block"
  },
  items: {
    position: "absolute",
    left: 0,
    top: (props: IStylesProps) => props.listContainerRelativeTop,
    width: "100%",
    marginTop: "-1px",
    overflow: "auto",
    backgroundColor: "#fff",
    zIndex: 5,
    boxShadow: "0px 0px 12px 0px rgb(0 0 0 / 40%)",
    borderRadius: 6,
    [theme.breakpoints.down(Breakpoints.SM_600)]: {
      position: "fixed",
      boxShadow: "none",
      borderRadius: 0,
      borderTop: "solid 1px #797979",
      minHeight: 250,
      top: (props: IStylesProps) => props.listContainerAbsoluteTop,
      height: (props: IStylesProps) =>
        `calc(100% - ${props.listContainerAbsoluteTop}px)`
    }
  },
  item: {
    width: "100%",
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center"
  },
  itemActive: {
    color: theme.palette.secondary.dark
  },
  itemNotActive: {
    color: theme.palette.text.hint
  },
  itemHorseName: {
    display: "flex",
    justifyContent: "flex-start",
    alignItems: "center"
  },
  itemHorseNameTitle: {
    fontFamily: "SuisseIntlMedium",
    fontSize: 13,
    [theme.breakpoints.down(Breakpoints.SM_600)]: {
      fontSize: 16
    }
  },
  itemTrainerName: {
    display: "flex",
    justifyContent: "flex-start",
    alignItems: "center"
  },
  itemTrainerNameTitle: {
    fontFamily: "SuisseIntlMedium",
    fontSize: 13,
    [theme.breakpoints.down(Breakpoints.SM_600)]: {
      fontSize: 14
    }
  },
  itemIcon: {
    fontSize: 14,
    marginRight: 10,
    [theme.breakpoints.down(Breakpoints.SM_600)]: {
      fontSize: 15
    }
  },
  checkIcon: {
    fontSize: 14,
    color: theme.palette.secondary.dark,
    marginRight: 10,
    [theme.breakpoints.down(Breakpoints.SM_600)]: {
      fontSize: 15
    }
  },
  itemHorseDetailsGender: {
    fontWeight: "bold"
  },
  itemTrainerIcon: {
    marginRight: 7,
    color: theme.palette.text.primary
  },
  itemHorseTrainerIcon: {
    marginLeft: 10,
    marginRight: 0
  },
  itemHorseDetails: {
    display: "flex",
    flexDirection: "column",
    fontSize: 12,
    textAlign: "right",
    [theme.breakpoints.down(Breakpoints.SM_600)]: {
      fontSize: 14
    }
  },
  itemHorseDetailsTrainer: {
    flexDirection: "row",
    alignItems: "center"
  },
  dropDownSearchList: {
    width: "100%",
    height: "auto",
    maxHeight: "293px",
    [theme.breakpoints.down(Breakpoints.SM_600)]: {
      maxHeight: "initial"
    }
  },
  dropDownSearchListItem: {
    cursor: "pointer",
    height: ITEM_HEIGHT,
    [theme.breakpoints.down(Breakpoints.SM_600)]: {
      height: ITEM_HEIGHT_DOWN_XS
    }
  },
  rowOdd: {
    backgroundColor: "#fafafa"
  },
  rowEven: {
    backgroundColor: theme.palette.common.white
  },
  searchNoResults: {
    width: "100%"
  },
  noSearchResultButton: {
    height: "70vh"
  },
  searchLoader: {
    paddingTop: 8,
    paddingBottom: 8
  },
  activeDropDownSearchListItem: {
    backgroundColor: "rgba(0, 0, 0, 0.04)"
  },
  loaderWrapper: {
    margin: 10
  }
}));
