import React, { useEffect } from "react";
import { useDispatch } from "react-redux";
import { useHistory } from "react-router-dom";

import Button, { ButtonProps } from "@material-ui/core/Button";

import { setActiveLink as setStoreActiveLink } from "store/actions/sidebar";
import { useLoggedInUser } from "components/LoggedInUserProvider";
import LogoIcon from "components/Icons/SapLogo";
import routes from "common/routes";

import useStyles from "./styles";

interface IProps extends ButtonProps {
  onClickLogo: (param: string) => (event) => void;
}

const LogoIconButton = (props: IProps) => {
  const { onClickLogo } = props;

  const classes = useStyles();
  const dispatch = useDispatch();
  const history = useHistory();
  const { currentUser } = useLoggedInUser();

  const getHomePath = () => {
    if (!currentUser.roles.length) return routes.account.path;
    if (currentUser.isVeterinarian) return routes.workoutRequests.path;
    if (currentUser.isRacingOfficial) return routes.workouts.path;
    return routes.dashboard.path;
  };

  useEffect(() => {
    if (currentUser.isLoaded) {
      const path = `/${location.pathname.split("/")[1]}`;
      dispatch(setStoreActiveLink(path === "/" ? getHomePath() : path));
    }
  }, [currentUser.isLoaded, history.location.pathname]);

  return (
    <Button
      className={classes.logoButton}
      onClick={onClickLogo(getHomePath())}
      data-path={getHomePath()}
    >
      <LogoIcon className={classes.logoIcon} />
    </Button>
  );
};

export default LogoIconButton;
