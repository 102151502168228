import { makeStyles } from "@material-ui/core/styles";
import { MuiTheme } from "theme";

export default makeStyles((theme: MuiTheme) => ({
  countSheetContainer: {
    paddingTop: 88
  },
  countSheetHeader: {
    display: "flex",
    padding: "8px 8px 8px 24px",
    flexDirection: "row",
    justifyContent: "space-between",
    alignItems: "center",
    boxShadow: "3px 1px 7px 0px rgba(0, 0, 0, 0.15)",
    color: theme.palette.text.primary
  },
  countSheetTitle: {
    fontFamily: "SuisseIntlMedium, SuisseIntlRegular",
    lineHeight: 1
  },
  countSheetDateBlock: {
    display: "flex",
    flexDirection: "column",
    overflow: "hidden"
  },
  countSheetDateBlockTitle: {
    fontFamily: "SuisseIntlMedium, SuisseIntlRegular",
    fontSize: 14,
    lineHeight: 1,
    marginBottom: 4,
    whiteSpace: "nowrap",
    textOverflow: "ellipsis",
    overflow: "hidden",
    width: "auto"
  },
  countSheetDateBlockValue: {
    fontSize: 14,
    lineHeight: 1,
    whiteSpace: "nowrap",
    textOverflow: "ellipsis",
    overflow: "hidden",
    width: "auto"
  },
  alignLeft: {
    textAlign: "left"
  },
  alignRight: {
    textAlign: "right"
  },
  width40P: {
    width: "40%",
    minWidth: "40%"
  },
  flex: {
    display: "flex",
    alignItems: "center"
  },
  flexGrow: {
    flex: "auto"
  },
  closeIconButton: {
    padding: 8,
    marginLeft: 16,
    minWidth: 48
  },
  closeIcon: {
    fontSize: 32,
    color: theme.palette.text.primary
  },
  hidden: {
    "&&": {
      display: "none"
    }
  },
  appPageContent: {
    padding: 24,
    [theme.breakpoints.down("xs")]: {
      padding: "20px 16px"
    },
    [theme.breakpoints.up("md")]: {
      height: "calc(100vh - 64px)"
    }
  },
  gridContainer: {
    width: "100%",
    display: "grid",
    gridTemplateColumns: "1fr 1fr 1fr 1fr",
    gridTemplateRows: "min-content auto 186px", //186 = 7-Day Forecast component height
    gap: "20px 20px",
    gridTemplateAreas: `
        "grid-info grid-info grid-info grid-info"
        "grid-notifications grid-notifications grid-days-off-tracker grid-next-3-days"
        "grid-forecast grid-forecast grid-forecast grid-next-3-days"
      `,
    [theme.breakpoints.down("sm")]: {
      gridTemplateColumns: "100%",
      gridTemplateRows:
        "min-content min-content min-content min-content min-content",
      gridTemplateAreas: `
        "grid-info"
        "grid-notifications"
        "grid-days-off-tracker"
        "grid-forecast"
        "grid-next-3-days"
        `
    }
  },
  gridForecast: {
    gridArea: "grid-forecast",
    [theme.breakpoints.down("xs")]: {
      display: "none"
    }
  },
  gridNotifications: {
    gridArea: "grid-notifications",
    width: "100%",
    [theme.breakpoints.up("md")]: {
      minHeight: 0
    }
  },
  gridDaysOffTracker: {
    gridArea: "grid-days-off-tracker",
    [theme.breakpoints.up("md")]: {
      minHeight: 0
    }
  },
  gridNext3Days: {
    gridArea: "grid-next-3-days",
    [theme.breakpoints.up("md")]: {
      minHeight: 0
    }
  },
  gridInfo: { gridArea: "grid-info" }
}));
