import React, { useEffect, useState } from "react";

import Dialog from "@material-ui/core/Dialog";
import DialogContent from "@material-ui/core/DialogContent";

import FiltersDialogHeader from "../FiltersDialogHeader";
import FiltersDialogActions from "../FiltersDialogActions";

import useStyles, { IStylesProps } from "./styles";

interface IProps {
  onClose: () => void;
  onResetFilters: () => void;
  onApplyFilters: () => void;
  title: string;
  disabledResetButton: boolean;
  disabledApplyButton: boolean;
  children: React.ReactNode;
  minHeightDialog?: number | string;
}

const FiltersDialog = (props: IProps) => {
  const {
    title = "",
    onResetFilters,
    onApplyFilters,
    onClose,
    disabledResetButton,
    disabledApplyButton,
    minHeightDialog,
    children
  } = props;

  const [stylesProps, setStylesProps] = useState<IStylesProps>();
  const classes = useStyles(stylesProps);

  useEffect(() => {
    minHeightDialog && setStylesProps({ minHeightDialog });
  }, [minHeightDialog]);

  return (
    <Dialog className={classes.dialog} onClose={onClose} open>
      <FiltersDialogHeader title={title} onClose={onClose} />

      <DialogContent className={classes.content}>{children}</DialogContent>

      <FiltersDialogActions
        onResetFilters={onResetFilters}
        onApplyFilters={onApplyFilters}
        disabledResetButton={disabledResetButton}
        disabledApplyButton={disabledApplyButton}
      />
    </Dialog>
  );
};

export default FiltersDialog;
