import React, { useState } from "react";
import clsx from "clsx";
import groupBy from "lodash/groupBy";
import orderBy from "lodash/orderBy";
import isToday from "date-fns/isToday";
import isTomorrow from "date-fns/isTomorrow";
import parseISO from "date-fns/parseISO";

import { Theme, useMediaQuery } from "@material-ui/core";

import { racehorse360 } from "@tsg/1st-grpc-web";
import { useRacehorse360Api } from "hooks/api";
import NextDaysActivitiesItem from "./NextDaysActivitiesItem";
import { differenceInDaysRoundedUp } from "utils/date-utils";
import useStyles from "./styles";

export interface Props {
  daysQuantity: number;
  upcomingWorkouts: racehorse360.IWorkoutRequest[];
  upcomingRaceHorses: racehorse360.IUpcomingRaceHorse[];
  className: string;
  refetchListWorkout: () => void;
  refetchWorkoutsTotalCount: () => void;
  refetchInfiniteWorkoutRequests: () => void;
}

const NextDaysActivities = (props: Props) => {
  const {
    className,
    daysQuantity,
    upcomingWorkouts,
    upcomingRaceHorses,
    refetchListWorkout,
    refetchWorkoutsTotalCount,
    refetchInfiniteWorkoutRequests
  } = props;
  const classes = useStyles();
  const isScreenDownSM = useMediaQuery((theme: Theme) =>
    theme.breakpoints.down("sm")
  );

  const [isWeatherServiceAvailable, setIsWeatherServiceAvailable] =
    useState<boolean>(true);

  //TODO: get facility info from API for next 3 days
  const selectedFacility = {
    id: "1",
    latitude: 34.140614,
    longitude: -118.044555,
    code: "SAP",
    backgroundColor: "07F3B2"
  };

  const { useDailyForecast } = useRacehorse360Api();

  const { isLoading: isWeatherLoading, data = { list: [] } } = useDailyForecast(
    {
      lat: selectedFacility?.latitude || 0,
      lon: selectedFacility?.longitude || 0,
      cnt: daysQuantity
    },
    {
      enabled: isScreenDownSM,
      onSuccess: () => setIsWeatherServiceAvailable(true),
      onError: () => setIsWeatherServiceAvailable(false)
    }
  );

  const weather = data.list.slice(0, daysQuantity);

  const renderItems = () => {
    const allUpcomingItems: (racehorse360.IUpcomingRaceHorse &
      racehorse360.IWorkoutRequest)[] = [
      ...upcomingRaceHorses,
      ...upcomingWorkouts
    ];

    const dateGroups = groupBy(
      allUpcomingItems,
      upcomingItem => upcomingItem.date || upcomingItem.raceDate
    );

    return orderBy(Object.keys(dateGroups)).map((dateGroup, index) => {
      const upcomingItems = dateGroups[dateGroup];
      const date = parseISO(dateGroup);
      const weatherDayIndex = differenceInDaysRoundedUp(date);
      let dateTitle;

      if (isToday(date)) {
        dateTitle = "(Today)";
      } else if (isTomorrow(date)) {
        dateTitle = "(Tomorrow)";
      } else {
        dateTitle = "";
      }

      return (
        <NextDaysActivitiesItem
          key={index}
          selectedFacility={selectedFacility}
          upcomingItems={upcomingItems}
          date={date}
          dateTitle={dateTitle}
          refetchListWorkout={refetchListWorkout}
          refetchWorkoutsTotalCount={refetchWorkoutsTotalCount}
          refetchInfiniteWorkoutRequests={refetchInfiniteWorkoutRequests}
          weather={weather[weatherDayIndex]}
          isWeatherLoading={isWeatherLoading}
          isWeatherServiceAvailable={isWeatherServiceAvailable}
        />
      );
    });
  };

  return (
    <div
      className={clsx(
        classes.nextDaysActivities,
        className,
        classes.maxHeightUpMd
      )}
    >
      <div className={classes.header}>
        <span>Next {daysQuantity} Days</span>
      </div>
      <section className={classes.list}>
        <div className={classes.scrollable}>{renderItems()}</div>
      </section>
    </div>
  );
};

export default React.memo(NextDaysActivities);
