import React, { useState } from "react";
import { useSelector } from "react-redux";
import { useQueryClient } from "react-query";
import clsx from "clsx";
import format from "date-fns/format";

import {
  Button,
  FormControlLabel,
  FormGroup,
  IconButton,
  Dialog,
  DialogContent,
  DialogActions,
  DialogTitle
} from "@material-ui/core";
import CloseIcon from "@material-ui/icons/Close";

import { racehorse360 } from "@tsg/1st-grpc-web";

import { getPersonFullName } from "utils/person";
import GreenCheckbox from "components/GreenCheckbox";
import Loader from "components/Loader";
import Textarea from "components/Textarea";
import { useLoggedInUser } from "components/LoggedInUserProvider";
import { useRacehorse360Api } from "hooks/api";
import VetWorkoutsPageState from "interfaces/VetWorkoutsPageState";
import useStyles from "./styles";

interface IProps {
  workoutRequest: racehorse360.IWorkoutRequest;
  isRowRejected: boolean;
  setIsRowRejected: (boolean) => void;
  disabled: boolean;
  uncheckExamPass: (string) => void;
}

const RejectRequestDialog = (props: IProps) => {
  const {
    workoutRequest,
    isRowRejected,
    setIsRowRejected,
    disabled,
    uncheckExamPass
  } = props;
  const { horse } = workoutRequest;
  const queryClient = useQueryClient();
  const { searchValue } = useSelector(
    (state: { vetWorkoutsPage: VetWorkoutsPageState }) => state?.vetWorkoutsPage
  );
  const { currentUser } = useLoggedInUser();

  const [isOpen, setIsOpen] = useState(false);
  const [note, setNote] = useState("");
  const [isDiagnosticsRequired, setIsDiagnosticsRequired] = useState(false);

  const classes = useStyles();

  const { useRejectWorkoutRequest } = useRacehorse360Api();

  const { mutateAsync: rejectWorkoutRequest, isLoading } =
    useRejectWorkoutRequest();

  const handleOpen = () => {
    uncheckExamPass(workoutRequest.id);
    setIsRowRejected(true);
    setIsOpen(true);
  };

  const handleClose = () => {
    setNote("");
    setIsRowRejected(false);
    setIsOpen(false);
  };

  const handleNoteChange = value => {
    setNote(value);
  };

  const handleCheckboxChange = event => {
    setIsDiagnosticsRequired(event.target.checked);
  };

  const handleSave = () => {
    rejectWorkoutRequest({
      id: workoutRequest.id,
      comment: note,
      isImagingDiagnosticsRequired: isDiagnosticsRequired
    }).then(() => {
      handleClose();
      return queryClient.invalidateQueries([
        `${currentUser.rh360Id}-${searchValue}-infinite-workout-requests`
      ]);
    });
  };

  return (
    <>
      <IconButton
        size={"small"}
        className={clsx(classes.menuButton, classes.rejectedButton, {
          [classes.rejectedButton__active]: isRowRejected
        })}
        onClick={handleOpen}
        disabled={disabled}
      >
        {isRowRejected && <CloseIcon className={classes.rejectedIcon} />}
      </IconButton>
      {isOpen && (
        <Dialog
          classes={{
            paper: classes.dialogPaper
          }}
          open={isOpen}
        >
          {isLoading && <Loader overlay />}
          <DialogTitle className={classes.dialogTitle}>
            Rejection Note
            <IconButton
              color="primary"
              onClick={handleClose}
              size={"small"}
              classes={{
                root: classes.dialogCloseButton
              }}
            >
              <CloseIcon className={classes.dialogCloseButtonIcon} />
            </IconButton>
          </DialogTitle>
          <DialogContent className={classes.dialogContent}>
            <span className={classes.horseName}>
              {horse.name.toUpperCase()}
            </span>
            <span className={classes.trainerName}>
              {getPersonFullName(horse.trainer)}
            </span>
            <Textarea
              className={classes.textarea}
              maxLength={90}
              label={format(new Date(), "MM/dd-yyyy - hh:mm aa")}
              placeholder={"Add Note"}
              rows={7}
              value={note}
              defaultValue={note}
              onChange={handleNoteChange}
            />
            <FormGroup row>
              <FormControlLabel
                control={
                  <GreenCheckbox
                    value={isDiagnosticsRequired}
                    onChange={handleCheckboxChange}
                    name="diagnosticsCheckbox"
                  />
                }
                label="Requires Imaging / Diagnostics"
                className={classes.diagnosticsLabel}
              />
            </FormGroup>
          </DialogContent>
          <DialogActions className={clsx(classes.dialogActions)}>
            <div>
              <Button className={classes.buttonCancel} onClick={handleClose}>
                CANCEL
              </Button>
              <Button
                className={classes.buttonSave}
                onClick={handleSave}
                disabled={!note.trim().length}
              >
                SAVE
              </Button>
            </div>
          </DialogActions>
        </Dialog>
      )}
    </>
  );
};

export default RejectRequestDialog;
