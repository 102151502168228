import React from "react";
import clsx from "clsx";

import Button, { ButtonProps } from "@material-ui/core/Button";

import { IButtonGroup } from "../ExamButtonsGroup";

import useStyles from "./styles";

export interface IButtonData extends IButtonGroup {
  name: string;
  isActive: boolean;
}

interface IProps extends ButtonProps {
  onClickButton: (
    event: React.MouseEvent<HTMLButtonElement, MouseEvent>
  ) => void;
  buttonData: IButtonData;
}

const ExamButtonsGroupItem = (props: IProps) => {
  const {
    buttonData: {
      id,
      value,
      name,
      description,
      activeColor,
      titleColor,
      borderColor,
      isActive
    },
    onClickButton,
    className,
    ...rest
  } = props;
  const classes = useStyles({ activeColor, titleColor, borderColor });

  return (
    <Button
      {...rest}
      className={clsx(classes.button, className, {
        [classes.activeButton]: isActive
      })}
      name={name}
      onClick={onClickButton}
      value={id}
    >
      <span>{value}</span>
      {description && (
        <span
          className={clsx(classes.description, {
            ["active"]: isActive
          })}
        >
          {description}
        </span>
      )}
    </Button>
  );
};

export default ExamButtonsGroupItem;
