import React from "react";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";

import { makeStyles } from "@material-ui/core";
import Accordion from "@material-ui/core/Accordion";
import AccordionDetails from "@material-ui/core/AccordionDetails";
import AccordionSummary from "@material-ui/core/AccordionSummary";
import Button from "@material-ui/core/Button";
import Typography from "@material-ui/core/Typography";

import { racehorse360 } from "@tsg/1st-grpc-web";

import { useApiClient, useRacehorse360Api } from "hooks/api";
import {
  approveAllPendingRoles,
  clearAllRolesAndRequests,
  setDeveloperUserRole
} from "hooks/api/developerActions";
import { useLoggedInUser } from "components/LoggedInUserProvider";
import { Facilities } from "utils/facilities";
import { Trainers } from "./helper";
import Loader from "../Loader";

const useStyles = makeStyles(() => ({
  facilityAccordionSummary: {
    alignItems: "center"
  },
  facilityAccordionSummaryContent: {
    whiteSpace: "nowrap",
    textOverflow: "ellipsis",
    overflow: "hidden",
    width: 152,
    fontSize: 14
  },
  facilityAccordionDetails: {
    flexDirection: "column"
  }
}));

export const DeveloperRoleSelector = () => {
  const { userClient } = useApiClient();
  const classes = useStyles();
  const { useListFacilities, useListRoles, useListTrainers } =
    useRacehorse360Api();
  const { currentUser, updatePermissions } = useLoggedInUser();

  const {
    data: { facilities }
  } = useListFacilities(
    {
      query: {
        isActive: { value: true }
      },
      pagingOptions: {
        maxResults: 20
      },
      getOptions: {
        select: [
          "name",
          "backgroundColor",
          "foregroundColor",
          "strokeColor",
          "code",
          "timezone"
        ],
        orderBy: ["name"]
      }
    },
    {
      onError: error => console.error(error),
      initialData: {
        facilities: []
      }
    }
  );

  // Roles
  const { data: rolesList, isLoading: isRolesLoading } = useListRoles(
    "ALL_USER_ROLES",
    {
      query: {},
      pagingOptions: {
        maxResults: 20 // arbitrary.. should be enough for now
      },
      getOptions: {
        select: ["name", "roleType"],
        orderBy: ["name"]
      }
    }
  );

  // Trainers
  const { data: trainersList, isLoading: isTrainersLoading } = useListTrainers(
    "TEST_TRAINERS",
    {
      query: {
        ids: Trainers
      },
      pagingOptions: {
        maxResults: 20
      },
      getOptions: {
        select: ["lastName", "firstName"],
        orderBy: ["lastName", "firstName"]
      }
    }
  );

  const handleAcceptRoleRequests = async () => {
    await approveAllPendingRoles(
      userClient,
      currentUser.rh360Id,
      "e78dcae2-490e-fada-aede-19466cff1ab5" // Bob Baffert
    );
  };

  const handleClearAll = async () => {
    await clearAllRolesAndRequests(userClient, currentUser.rh360Id);
    updatePermissions();
  };

  const handleUserRole =
    (userRole: racehorse360.IRole, facilityId?: string, trainerId?: string) =>
    async () => {
      await setDeveloperUserRole(userClient, currentUser.rh360Id, {
        id: userRole.id,
        facilityId,
        trainerId
      });

      // Update the access token with the new roles/permissions
      updatePermissions();
    };

  if (isRolesLoading || isTrainersLoading) {
    return <Loader data-test-element="DEVELOPER_ROLE_LIST" />;
  }

  // eslint-disable-next-line @typescript-eslint/no-extra-semi
  (window as any).CURRENT_USER = currentUser;

  return (
    <>
      <div data-test-element="DEVELOPER_ROLE_LIST">
        {/* For each test facility, list all facility-type roles */}
        {facilities
          .filter(f => Facilities.some(fId => fId === f.id))
          .map(facility => (
            <Accordion key={facility.id}>
              <AccordionSummary
                classes={{
                  content: classes.facilityAccordionSummary
                }}
                expandIcon={<ExpandMoreIcon />}
                aria-controls="panel1a-content"
                id="panel1a-header"
              >
                <Typography className={classes.facilityAccordionSummaryContent}>
                  {facility.name}
                </Typography>
              </AccordionSummary>
              <AccordionDetails className={classes.facilityAccordionDetails}>
                {rolesList?.roles
                  ?.filter(
                    r => r.roleType === racehorse360.RoleType.ROLE_TYPE_FACILITY
                  )
                  .map(role => (
                    <Button
                      key={role.id! + facility.id}
                      onClick={handleUserRole(role, facility.id)}
                      fullWidth
                    >
                      {currentUser.hasRoleAtFacility(role.id, facility.id)
                        ? "✅"
                        : null}
                      {role.name} ({facility.code})
                    </Button>
                  ))}
              </AccordionDetails>
            </Accordion>
          ))}
        {/* For each test trainer, list all trainer-type roles */}
        {trainersList.trainers.map(trainer =>
          rolesList?.roles
            ?.filter(
              r => r.roleType === racehorse360.RoleType.ROLE_TYPE_TRAINER
            )
            .map(role => (
              <Button
                key={role.id! + trainer.id}
                onClick={handleUserRole(role, undefined, trainer.id)}
                fullWidth
              >
                {currentUser.hasRoleForTrainer(role.id, trainer.id)
                  ? "✅"
                  : null}
                {role.name} ({trainer.lastName}, {trainer.firstName})
              </Button>
            ))
        )}
        {/* List all other role types */}
        {rolesList?.roles
          ?.filter(r => r.roleType === racehorse360.RoleType.ROLE_TYPE_NORMAL)
          .map(role => (
            <Button key={role.id!} onClick={handleUserRole(role)} fullWidth>
              {currentUser.hasRole(role.id) ? "✅" : null}
              {role.name}
            </Button>
          ))}
      </div>
      <hr />
      <Button onClick={handleAcceptRoleRequests} fullWidth>
        Approve Pending Requests
      </Button>
      <Button onClick={handleClearAll} fullWidth>
        Clear All
      </Button>
    </>
  );
};
