import React, { useState } from "react";
import { useQueryClient } from "react-query";
import Button from "@material-ui/core/Button";

import { racehorse360 } from "@tsg/1st-grpc-web";
import ConfirmationDialog from "components/ConfirmationDialog";
import Loader from "components/Loader";
import { useRacehorse360Api } from "hooks/api";
import useStyles from "./styles";

export interface Props {
  horse: racehorse360.IHorse;
  injection: racehorse360.IInjection;
  onSuccess: () => void;
}

const DeleteVaccination = (props: Props) => {
  const { horse, injection, onSuccess } = props;
  const classes = useStyles();
  const queryClient = useQueryClient();

  const [deleteConfirmationOpen, setDeleteConfirmationOpen] =
    useState<boolean>(false);

  const { useDeleteVaccination } = useRacehorse360Api();

  const {
    mutateAsync: deleteVaccination,
    isLoading: isDeleteVaccinationLoading
  } = useDeleteVaccination();

  const toggleDeleteConfirmationDialog = () => {
    setDeleteConfirmationOpen(!deleteConfirmationOpen);
  };

  const deleteEntry = () => {
    deleteVaccination({
      id: injection.vaccination.id
    })
      .then(() => {
        setDeleteConfirmationOpen(false);
        return Promise.all([
          queryClient.invalidateQueries([
            `${injection.vaccination?.id}-list-boosters`
          ]),
          queryClient.invalidateQueries([
            `${injection.vaccination?.id}-list-vaccination-comments`
          ]),
          queryClient.invalidateQueries([`${horse.id}-list-vaccines`])
        ]);
      })
      .then(() => {
        onSuccess();
      });
  };

  const handleDeleteEntryClick = () => {
    toggleDeleteConfirmationDialog();
  };

  return (
    <>
      <Button
        className={classes.buttonDeleteEntry}
        onClick={handleDeleteEntryClick}
      >
        Delete Entry
      </Button>
      <ConfirmationDialog
        open={deleteConfirmationOpen}
        onConfirm={deleteEntry}
        onCancel={toggleDeleteConfirmationDialog}
        title={"Confirm Entry Deletion"}
        positive={true}
      >
        {isDeleteVaccinationLoading && <Loader overlay />}
        <div>
          <p className={classes.description}>
            Are you sure you want to delete this vaccine record? All boosters
            and comments associated with this entry will also be deleted.
          </p>
          <p className={classes.confirmationInfoLabel}>Horse Name</p>
          <p className={classes.confirmationInfoText}>{horse.name}</p>
          <p className={classes.confirmationInfoLabel}>Vaccine</p>
          <p className={classes.confirmationInfoText}>
            {injection?.medicine?.name}
          </p>
        </div>
      </ConfirmationDialog>
    </>
  );
};

export default DeleteVaccination;
