import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useQueryClient } from "react-query";

import Grid from "@material-ui/core/Grid";
import Paper from "@material-ui/core/Paper";
import { Theme, useMediaQuery } from "@material-ui/core";

import AppPage from "components/AppPage";
import AppPageHeader from "components/AppPageHeader";
import AppPageContent from "components/AppPageContent";
import FacilitySelect from "components/FacilitySelect";

import { racehorse360 } from "@tsg/1st-grpc-web";
import { IWorkOrdersPageState } from "interfaces/WorkOrdersPageState";
import { SortOrder } from "interfaces/SortOrder";
import { setSelectedFacility } from "store/actions/workOrdersPage";
import { useRacehorse360Api } from "hooks/api";
import { useLoggedInUser } from "components/LoggedInUserProvider";
import Breakpoints from "common/breakpoints";
import WorkOrderOverlayForm from "../WorkOrderOverlayForm";
import MobileWorkOrderOverlay from "../MobileWorkOrderOverlay";
import CreateOrderButton from "../CreateOrderButton";
import WorkOrderList from "./WorkOrderList";
import WorkOrderDetail from "./WorkOrderDetail";
import useStyles from "./styles";

interface IProps {
  isNewWorkOrderOpen: boolean;
  onCloseNewOrderPopup: () => void;
  onOpenNewOrderPopup: () => void;
}

const DefaultWorkOrdersPage = (props: IProps) => {
  const { isNewWorkOrderOpen, onOpenNewOrderPopup, onCloseNewOrderPopup } =
    props;
  const classes = useStyles();
  const { currentUser } = useLoggedInUser();
  const queryClient = useQueryClient();
  const [isEditOrderOpen, setIsEditOrderOpen] = useState<boolean>(false);
  const [selectedWorkOrder, setSelectedWorkOrder] =
    useState<racehorse360.IWorkOrder | null>(null);
  const [order, setOrder] = useState<SortOrder>(SortOrder.DESC);
  const [orderBy, setOrderBy] =
    useState<keyof racehorse360.IWorkOrder>("createdOn");
  const [isOpenMobileWorkOrderDetail, setIsOpenMobileWorkOrderDetail] =
    useState<boolean>(false);

  const { useListWorkOrders } = useRacehorse360Api();

  const isScreenSX = useMediaQuery((theme: Theme) =>
    theme.breakpoints.down(Breakpoints.XS_420)
  );

  const { isLoading, data } = useListWorkOrders(
    `${currentUser.rh360Id}-work-orders`,
    {
      query: {
        createdById: { value: currentUser.rh360Id }
      },
      pagingOptions: {
        maxResults: 999,
        includeSummary: true
      },
      getOptions: {
        select: [
          "id",
          "assignedUser.firstName",
          "assignedUser.lastName",
          "workOrderType.id",
          "workOrderType.name",
          "facility.name",
          "facility.timezone",
          "createdOn",
          "fullName",
          "email",
          "phone",
          "closedOn",
          "customerFacingId",
          "status",
          "barn.id",
          "barn.name",
          "description",
          "stallNumber"
        ],
        orderBy: [`${orderBy} ${order}`]
      }
    }
  );
  const workOrders = data?.workOrders;

  const workOrdersPage = useSelector(
    (state: { workOrdersPage: IWorkOrdersPageState }) => state?.workOrdersPage
  );
  const selectedFacility = workOrdersPage?.selectedFacility;

  const dispatch = useDispatch();

  const handleChangeFacility = (facility: racehorse360.IFacility) => {
    dispatch(setSelectedFacility(facility));
  };

  const handleClose = () => {
    onCloseNewOrderPopup();
    setIsEditOrderOpen(false);
  };

  const handleWorkOrderClick = (workOrder: racehorse360.WorkOrder) => {
    setSelectedWorkOrder(workOrder);
    setIsOpenMobileWorkOrderDetail(true);
  };

  const handleCloseMobileWorkOrderDetail = () => {
    setIsOpenMobileWorkOrderDetail(false);
    setSelectedWorkOrder(null);
  };

  const handleWorkOrderUpdate = () => {
    setIsOpenMobileWorkOrderDetail(false);
    return queryClient.invalidateQueries([
      `${currentUser.rh360Id}-work-orders`
    ]);
  };

  const handleEditClick = () => {
    setIsEditOrderOpen(true);
  };

  const workOrder = workOrders?.find(wo => wo.id === selectedWorkOrder?.id);

  return (
    <AppPage>
      <AppPageHeader className={classes.pageHeader}>
        <FacilitySelect
          className={classes.facilitySelect}
          isActive={true}
          selectedFacility={selectedFacility}
          onChange={handleChangeFacility}
        />
        <CreateOrderButton
          onClick={onOpenNewOrderPopup}
          isCompactDownXs={true}
        />
      </AppPageHeader>
      <AppPageContent className={classes.appPageContent}>
        <Grid spacing={2} container>
          <Grid item sm={8} container>
            <Paper className={classes.columnPaper}>
              <WorkOrderList
                onItemClick={handleWorkOrderClick}
                selectedId={selectedWorkOrder?.id}
                order={order}
                orderBy={orderBy}
                onSetOrder={setOrder}
                onSetOrderBy={setOrderBy}
                isLoading={isLoading}
                workOrders={workOrders}
                onOpenNewOrderPopup={onOpenNewOrderPopup}
              />
            </Paper>
          </Grid>
          <Grid item sm={4} className={classes.hideDownXs}>
            <Paper className={classes.columnPaper}>
              <WorkOrderDetail
                hasWorkOrders={!!workOrders?.length}
                selectedWorkOrder={workOrder}
                onUpdate={handleWorkOrderUpdate}
                onEditClick={handleEditClick}
              />
            </Paper>
          </Grid>
        </Grid>
      </AppPageContent>
      {(isNewWorkOrderOpen || isEditOrderOpen) && (
        <WorkOrderOverlayForm
          selectedWorkOrder={workOrder}
          isEdit={isEditOrderOpen}
          isOpen={isNewWorkOrderOpen || isEditOrderOpen}
          onClose={handleClose}
          facilityName={selectedFacility?.name}
          facilityId={selectedFacility?.id}
        />
      )}

      {(isScreenSX || isEditOrderOpen) && (
        <MobileWorkOrderOverlay
          open={Boolean(
            isScreenSX && selectedWorkOrder && isOpenMobileWorkOrderDetail
          )}
          onClose={handleCloseMobileWorkOrderDetail}
          selectedWorkOrder={workOrder}
          track={selectedFacility?.name.toLowerCase()}
          onEditClick={handleEditClick}
          onUpdate={handleWorkOrderUpdate}
        />
      )}
    </AppPage>
  );
};

export default DefaultWorkOrdersPage;
