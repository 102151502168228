import React, { ChangeEvent, useState } from "react";
import { MuiTheme } from "theme";

import Accordion from "@material-ui/core/Accordion";
import Autocomplete from "@material-ui/lab/Autocomplete";
import TextField from "@material-ui/core/TextField";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import { Popper } from "@material-ui/core";

import Breakpoints from "common/breakpoints";
import { IFilterDataItem } from "components/Filters/filtersOptions";
import FilterAccordionDetails from "../FilterAccordionDetails";
import FilterAccordionSummary from "../FilterAccordionSummary";
import useStyles from "./styles";

export interface IFilterTitles {
  textFieldLabel?: string;
  filterSummaryTitle: string;
  searchTitle: string;
}

interface IProps {
  selectedValues: IFilterDataItem[];
  onChangeSelectedValues: (value: IFilterDataItem[]) => void;
  data: IFilterDataItem[];
  titles: IFilterTitles;
}

const CustomPopper = props => {
  const classes = useStyles();
  const matchesDownXS420 = useMediaQuery((theme: MuiTheme) =>
    theme.breakpoints.down(Breakpoints.XS_420)
  );

  return (
    <Popper
      {...props}
      className={classes.popper}
      placement={matchesDownXS420 ? "top" : "bottom"}
      modifiers={{
        flip: {
          enabled: !matchesDownXS420
        },
        preventOverflow: {
          enabled: !matchesDownXS420,
          boundariesElement: "scrollParent"
        }
      }}
    />
  );
};

const AutocompleteFilter = (props: IProps) => {
  const {
    selectedValues = [],
    onChangeSelectedValues,
    data = [],
    titles: { filterSummaryTitle, textFieldLabel = "", searchTitle }
  } = props;
  const classes = useStyles();

  const [isShowPlaceholder, setIsShowPlaceHolder] = useState<boolean>(true);
  const [isExpanded, setIsExpanded] = useState<boolean>(false);

  const handleChangeItems = (
    event: ChangeEvent<HTMLInputElement>,
    selectedItems: IFilterDataItem[]
  ) => {
    onChangeSelectedValues(selectedItems);
  };

  const renderPreviewItems = () => {
    if (!selectedValues.length) {
      return [];
    }

    return selectedValues.map((orderType: IFilterDataItem) => orderType.label);
  };

  const renderTextField = params => {
    return (
      <TextField
        classes={{
          root: classes.textField
        }}
        {...params}
        variant="outlined"
        label={textFieldLabel}
        placeholder={isShowPlaceholder ? textFieldLabel : ""}
      />
    );
  };

  const handleOpen = () => {
    setIsShowPlaceHolder(false);
  };

  const handleClose = () => {
    setIsShowPlaceHolder(true);
  };

  const handleChangeAccordionState = (
    event: ChangeEvent,
    expanded: boolean
  ) => {
    setIsExpanded(expanded);
  };

  const renderDetailsContent = () => {
    return (
      <div className={classes.searchDetails}>
        <div className={classes.title}>{searchTitle}</div>
        <Autocomplete
          classes={{
            root: classes.root,
            inputRoot: classes.inputRoot,
            endAdornment: classes.endAdornment,
            listbox: classes.listBox,
            option: classes.option,
            clearIndicator: selectedValues.length && classes.clearIndicator
          }}
          id={"autocomplete-id"}
          size="small"
          multiple
          value={selectedValues}
          onChange={handleChangeItems}
          options={data}
          getOptionLabel={option => option.label}
          filterSelectedOptions
          renderInput={renderTextField}
          onOpen={handleOpen}
          onClose={handleClose}
          PopperComponent={CustomPopper}
        />
      </div>
    );
  };

  return (
    <Accordion onChange={handleChangeAccordionState}>
      <FilterAccordionSummary
        maxWidthPreview={360}
        withLimit={true}
        title={filterSummaryTitle}
        previewItems={renderPreviewItems()}
        isExpanded={isExpanded}
      />
      <FilterAccordionDetails>{renderDetailsContent()}</FilterAccordionDetails>
    </Accordion>
  );
};

export default AutocompleteFilter;
