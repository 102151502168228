import React, { useCallback, useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { AutoSizer } from "react-virtualized";
import { useQueryClient } from "react-query";
import clsx from "clsx";

import Typography from "@material-ui/core/Typography";

import { racehorse360 } from "@tsg/1st-grpc-web";
import AppPageTable from "components/AppPageTable";
import AppPageTableContent from "components/AppPageTableContent";
import AppPageTableHeader from "components/AppPageTableHeader";
import ErrorBoundary from "components/ErrorBoundary";
import Loader from "components/Loader";
import { useLoggedInUser } from "components/LoggedInUserProvider";
import ScrollSync from "components/ScrollSync";
import SearchNoResults from "components/SearchNoResults";
import { useSnackbar } from "components/SnackbarContext/SnackbarContext";
import VetExamOverlay from "components/VetExamOverlay";
import { SortOrderExtended } from "interfaces/SortOrder";
import VetWorkoutsPageState from "interfaces/VetWorkoutsPageState";
import { checkCurrentWorkoutsTableTab } from "../helper";

import { useApiClient, useRacehorse360Api } from "hooks/api";
import { setIsExamActive } from "store/actions/vetExam";
import {
  setOrder as setStoreOrder,
  setOrderBy as setStoreOrderBy
} from "store/actions/vetWorkoutsPage";
import LockableRow from "./LockableRow";
import TableHeader from "./TableHeader";
import TrainerAccordion from "./TrainerAccordion";
import useStyles from "./styles";

interface IProps {
  workoutRequests: racehorse360.IWorkoutRequest[];
  isLoading: boolean;
  isFetching: boolean;
  isFetchingNextPage: boolean;
  tableBodyRef: React.RefObject<HTMLDivElement>;
  searchValue: string;
  hasDesiredHorse: boolean;
  onClearSearch: (value: boolean) => void;
}

const AVERAGE_CHAR_WIDTH = 7; // average width of one char "px"
const ADDITIONAL_RISK_WIDTH = 40; //paddings, borders, margins risk factors wrap and all items
const MIN_RISK_FACTORS_WIDTH = 120; // min column width
const TRAINER_LASTNAME_SORT = "horse.trainer.lastName";

const WorkoutsTable = (props: IProps) => {
  const {
    workoutRequests,
    isLoading,
    isFetching,
    isFetchingNextPage,
    tableBodyRef,
    searchValue,
    hasDesiredHorse,
    onClearSearch
  } = props;
  const { workoutExamClient } = useApiClient();
  const queryClient = useQueryClient();
  const { currentUser } = useLoggedInUser();
  const { showErrorSnack } = useSnackbar();
  const { useCreateWorkoutExam } = useRacehorse360Api();
  const [isExamAvailableLoading, setIsExamAvailableLoading] =
    useState<boolean>(false);
  const [isExamFormOpen, setIsExamFormOpen] = useState<boolean>(false);
  const [activeExamId, setActiveExamId] = useState<string>();
  const [showLoader, setShowLoader] = useState<boolean>(true);
  const [maxRiskFactorColumnWidth, setMaxRiskFactorColumnWidth] =
    useState<number>(null);

  const classes = useStyles({
    riskFactorColumnWidth: maxRiskFactorColumnWidth
  });
  const dispatch = useDispatch();

  const { selectedTab, order, orderBy } = useSelector(
    (state: { vetWorkoutsPage: VetWorkoutsPageState }) => state?.vetWorkoutsPage
  );
  const { isRequestsTab, isExamsTab } = checkCurrentWorkoutsTableTab(
    selectedTab.value
  );

  const shouldRenderTrainerAccordions =
    isExamsTab && orderBy === TRAINER_LASTNAME_SORT;

  type TTrainerEntries = Record<string, racehorse360.IWorkoutRequest[]>;

  const workoutRequestsMappedByTrainer = shouldRenderTrainerAccordions
    ? workoutRequests.reduce((acc, request) => {
        if (request.workoutExam) {
          acc[request.workoutExam.horse.trainer.id] = acc[
            request.workoutExam.horse.trainer.id
          ]
            ? [...acc[request.workoutExam.horse.trainer.id], request]
            : [request];
        }
        return acc;
      }, {} as TTrainerEntries)
    : null;

  const handleClearSearch = () => {
    onClearSearch(true);
  };

  const refetchData = async (
    shouldHideLoader: boolean = false
  ): Promise<void> => {
    shouldHideLoader && setShowLoader(false);

    await Promise.all([
      queryClient.invalidateQueries([
        `${currentUser.rh360Id}-${searchValue}-infinite-workout-requests`
      ]),
      queryClient.invalidateQueries([
        `${currentUser.rh360Id}-${searchValue}-infinite-exams`
      ])
    ]);

    shouldHideLoader && setShowLoader(true);
  };

  const { mutateAsync: createWorkoutExam } = useCreateWorkoutExam();

  const handleExamClick = (request: racehorse360.IWorkoutRequest) => {
    if (request.workoutExam) {
      setIsExamAvailableLoading(true);
      workoutExamClient
        .listWorkoutExams({
          query: {
            ids: [request.workoutExam.id]
          },
          getOptions: {
            select: ["status"]
          }
        })
        .then(result => {
          const isExamAvailable = [
            racehorse360.WorkoutExamStatus.WORKOUT_EXAM_STATUS_REQUESTED,
            racehorse360.WorkoutExamStatus.WORKOUT_EXAM_STATUS_IN_PROGRESS,
            racehorse360.WorkoutExamStatus.WORKOUT_EXAM_STATUS_PENDING
          ].includes(result.workoutExams[0].status);
          if (isExamAvailable) {
            setActiveExamId(request.workoutExam.id);
            setIsExamFormOpen(true);
            dispatch(setIsExamActive(true));
          } else {
            showErrorSnack("This exam has already been completed");
            return refetchData();
          }
        })
        .catch(error => {
          console.error(error);
        })
        .finally(() => {
          setIsExamAvailableLoading(false);
        });
    } else {
      const horseFacility =
        request.horse?.barn?.facility || request.horse?.currentFacility;
      const workoutExamPayload: racehorse360.ICreateWorkoutExamRequest = {
        facilityId: horseFacility?.id,
        horseId: request?.horse?.id
      };

      createWorkoutExam(workoutExamPayload).then(actualExam => {
        setActiveExamId(actualExam.id);
        setIsExamFormOpen(true);
        dispatch(setIsExamActive(true));
      });
    }
  };

  const handleExamUnlock = async () => {
    await refetchData();
  };

  const renderTrainerAccordions = (trainerEntries: TTrainerEntries) => {
    return (
      <div>
        {Object.keys(trainerEntries).map(trainerID => {
          const trainer =
            trainerEntries[trainerID][0].workoutExam.horse.trainer;
          return (
            <TrainerAccordion
              key={trainerID}
              trainerEntry={trainerEntries[trainerID]}
              trainer={trainer}
              renderRows={renderRows}
            />
          );
        })}
      </div>
    );
  };

  const renderRows = (requests: racehorse360.IWorkoutRequest[]) =>
    requests.map((workoutRequest, i) => {
      const key = workoutRequest?.workoutExam?.id || workoutRequest?.id;
      return (
        <LockableRow
          key={key}
          index={i}
          workoutRequest={workoutRequest}
          refetchWorkoutsAndExams={refetchData}
          riskFactorColumnWidth={maxRiskFactorColumnWidth}
          onExamClick={handleExamClick}
          onUnlock={handleExamUnlock}
        />
      );
    });

  const renderNoWorkouts = () => {
    const getSelectedTabOption = (tabValue: string, tabName: string) => {
      switch (tabValue) {
        case "requests":
          return "Workout Requests";
        case "exams":
          return "Exams";
        default:
          return `${tabName} Exams`;
      }
    };

    if (!isLoading) {
      if (!searchValue?.length || hasDesiredHorse) {
        return (
          <Typography paragraph className={classes.noWorkouts}>
            {`No ${getSelectedTabOption(
              selectedTab.value,
              selectedTab.name
            )} to Display for Date Range`}
          </Typography>
        );
      }

      return (
        <SearchNoResults value={searchValue} onClick={handleClearSearch} />
      );
    }
  };

  const handleSort = useCallback(
    (columnName: string, sortDirection: SortOrderExtended) => {
      dispatch(setStoreOrderBy(columnName));
      dispatch(setStoreOrder(sortDirection));
    },
    [dispatch]
  );

  const handleExamClose = () => {
    dispatch(setIsExamActive(false));
    setIsExamFormOpen(false);
    setActiveExamId(null);
  };

  useEffect(() => {
    isExamsTab && dispatch(setStoreOrderBy(TRAINER_LASTNAME_SORT));
  }, []);

  useEffect(() => {
    if (isRequestsTab || isExamsTab) {
      const maxLengthRiskFactor = workoutRequests
        .reduce((acc, cur) => {
          const horse = cur.horse || cur.workoutExam?.horse;
          if (horse.riskFactorsCount) {
            acc.push({
              risksCount: horse.riskFactorsCount,
              charsCount: JSON.parse(horse.riskFactors)
                .map(item => item.shortDescription)
                .toString().length
            });
          }
          return acc;
        }, [])
        .sort((l1, l2) => {
          return l2.charsCount - l1.charsCount || l2.risksCount - l1.risksCount;
        })[0];

      const maxRiskFactorsWidth =
        AVERAGE_CHAR_WIDTH * maxLengthRiskFactor?.charsCount +
        ADDITIONAL_RISK_WIDTH * maxLengthRiskFactor?.risksCount;
      const riskFactorColumnWidth = Math.max(
        maxRiskFactorsWidth,
        MIN_RISK_FACTORS_WIDTH
      );

      setMaxRiskFactorColumnWidth(riskFactorColumnWidth);
    }
  }, [workoutRequests]);

  const renderTableContent = () => {
    const shouldShowLoader =
      showLoader && (isLoading || (isFetching && !isFetchingNextPage));

    if (shouldShowLoader) {
      return (
        <Loader
          className={clsx(classes.infiniteLoader, classes.showOverlayLoader)}
        />
      );
    }

    if (workoutRequests?.length) {
      return (
        <>
          <ScrollSync enabled={true}>
            <ErrorBoundary>
              <AppPageTableHeader className={classes.appPageTableHeader}>
                <TableHeader
                  selectedTab={selectedTab.value}
                  riskFactorColumnWidth={maxRiskFactorColumnWidth}
                  order={order}
                  orderBy={orderBy}
                  onSort={handleSort}
                />
              </AppPageTableHeader>
            </ErrorBoundary>

            <ErrorBoundary>
              <AppPageTableContent className={classes.appPageTableContent}>
                <AutoSizer>
                  {({ height, width }) => (
                    <div
                      className={classes.scrollContainer}
                      style={{ height: height, width: width }}
                      ref={tableBodyRef}
                    >
                      {shouldRenderTrainerAccordions
                        ? renderTrainerAccordions(
                            workoutRequestsMappedByTrainer
                          )
                        : renderRows(workoutRequests)}
                    </div>
                  )}
                </AutoSizer>
              </AppPageTableContent>
            </ErrorBoundary>
          </ScrollSync>

          {isFetchingNextPage && (
            <Loader
              className={clsx(
                classes.infiniteLoader,
                classes.showInfiniteLoader
              )}
            />
          )}
        </>
      );
    }

    return renderNoWorkouts();
  };

  return (
    <AppPageTable className={classes.appPageTable}>
      {renderTableContent()}

      <VetExamOverlay
        open={isExamFormOpen}
        examId={activeExamId}
        refetchData={refetchData}
        onClose={handleExamClose}
      />

      {isExamAvailableLoading && <Loader overlay />}
    </AppPageTable>
  );
};

export default WorkoutsTable;
