import React, { useReducer, useEffect, useState, useCallback } from "react";
import { useDispatch } from "react-redux";
import { useQueryClient } from "react-query";
import isEqual from "lodash/isEqual";

import { racehorse360 } from "@tsg/1st-grpc-web";

import OverlayPage from "components/OverlayPage";
import { useClickBlockerContext } from "components/BlockableClickContext";
import UnsavedChangesDialog from "components/UnsavedChangesDialog";
import Loader from "components/Loader";
import StallApplicationHeader from "./StallApplicationHeader";
import StallApplicationControlPanel from "./StallApplicationControlPanel";
import StallApplicationFooter from "./StallApplicationFooter";
import { IReviewFormPopupData } from "./ReviewFormPopup/ReviewFormPopup";
import StableEntry from "./StableEntry";
import ReviewFormPopup from "./ReviewFormPopup";
import { reducer } from "./helper";
import { useRSStallApplicationReduxStore } from "../useRSStallApplicationReduxStore";
import { useRacehorse360Api } from "hooks/api";
import { transformViewDate } from "utils/date-utils";
import { setStallApplicationUnsavedChangesPopupState } from "store/actions/racingSecretaryStallApplication";
import useStyles from "./styles";

interface IProps {
  onClose: () => void;
  data: racehorse360.IStallApplication;
  isListStallApplicationsFetching: boolean;
}

const StallApplication = (props: IProps) => {
  const { data, onClose, isListStallApplicationsFetching } = props;
  const initialState = {
    stableEntries: data.stableEntries,
    shouldDisplayPPs: true
  };
  const trainerName = data.contactFullName;

  const classes = useStyles();
  const { setBlockClick, setBlockClickCallback } = useClickBlockerContext();
  const queryClient = useQueryClient();
  const dispatchToRedux = useDispatch();
  const { useSaveDraftStallApplication, useCompleteStallApplication } =
    useRacehorse360Api();

  const [state, dispatch] = useReducer(reducer, initialState);
  const [isReviewFormPopupOpen, setIsReviewFormPopupOpen] = useState(false);
  const {
    isRSStallApplicationUnsavedChangesPopupOpen,
    redirectToLinkCandidate,
    clearLinkCandidate
  } = useRSStallApplicationReduxStore();

  const {
    isLoading: isStallApplicationSaving,
    mutateAsync: saveAndCloseStallApplication
  } = useSaveDraftStallApplication();

  const {
    isLoading: isStallApplicationCompleting,
    mutateAsync: completeStallApplication
  } = useCompleteStallApplication();

  const lockNavigation = () => {
    setBlockClick(true);
    setBlockClickCallback(() => handleOpenUnsavedChangesPopup);
  };

  const unLockNavigation = () => {
    setBlockClick(false);
    setBlockClickCallback(null);
  };

  const areAllEntriesApproved = state.stableEntries.every(
    item =>
      item.status ===
      racehorse360.StableEntryStatus.STABLE_ENTRY_STATUS_APPROVED
  );
  const areAllEntriesDenied = state.stableEntries.every(
    item =>
      item.status === racehorse360.StableEntryStatus.STABLE_ENTRY_STATUS_DENIED
  );
  const areAllEntriesSelected = state.stableEntries.every(
    item =>
      item.status ===
        racehorse360.StableEntryStatus.STABLE_ENTRY_STATUS_APPROVED ||
      item.status === racehorse360.StableEntryStatus.STABLE_ENTRY_STATUS_DENIED
  );

  const reviewFormPopupData: IReviewFormPopupData = {
    trainerName,
    sleepingRoomsNumber: data.sleepingRoomsNumber,
    arrivalDate: transformViewDate(data.arrivalDate),
    stableEntries: state.stableEntries.map(item => ({
      horseName: item.customHorseName || item.horse.name,
      status: item.status
    }))
  };

  const save = async (method, forceRequest: boolean = false) => {
    if (!isEqual(data.stableEntries, state.stableEntries) || forceRequest) {
      try {
        await method({
          stallApplication: { ...data, stableEntries: state.stableEntries }
        });
        await queryClient.invalidateQueries("listStallApplications");
      } catch (error) {
        console.error(error);
      }
    }

    dispatchToRedux(setStallApplicationUnsavedChangesPopupState(false));
    redirectToLinkCandidate();
    onClose();
  };

  const handleCloseUnsavedChangesPopup = () => {
    dispatchToRedux(setStallApplicationUnsavedChangesPopupState(false));
    clearLinkCandidate();
  };

  const handleDiscardUnsavedChangesPopup = () => {
    dispatchToRedux(setStallApplicationUnsavedChangesPopupState(false));
    redirectToLinkCandidate();
    onClose();
  };

  const handleOpenUnsavedChangesPopup = () => {
    dispatchToRedux(setStallApplicationUnsavedChangesPopupState(true));
  };

  const handleCloseReviewFormPopup = () => {
    setIsReviewFormPopupOpen(false);
  };

  const handleOpenReviewFormPopup = useCallback(() => {
    setIsReviewFormPopupOpen(true);
  }, [setIsReviewFormPopupOpen]);

  const handleSaveAndClose = async () => {
    redirectToLinkCandidate();
    await save(saveAndCloseStallApplication);
  };

  const handleComplete = async () => {
    await save(completeStallApplication, true);
  };

  const renderUnsavedChangesContent = () => {
    return (
      <span className={classes.unsavedChangesContent}>
        <span>All application progress will be lost.</span>
        <span>
          Exit this prompt and click “Save and Close” if you wish for your
          progress to be saved.
        </span>
      </span>
    );
  };

  const renderEntries = () => {
    return state.stableEntries.map(item => (
      <StableEntry
        key={item.id}
        shouldDisplayPPs={state.shouldDisplayPPs}
        entry={item}
        dispatch={dispatch}
      />
    ));
  };

  useEffect(() => {
    lockNavigation();

    return () => {
      unLockNavigation();
    };
  }, []);

  const isLoading = [
    isListStallApplicationsFetching,
    isStallApplicationSaving,
    isStallApplicationCompleting
  ].some(Boolean);

  return (
    <OverlayPage classes={{ paper: classes.dialogPaper }}>
      <StallApplicationHeader
        title={data.stallApplicationForm.title}
        deadline={data.stallApplicationForm.deadlineDate}
        trainerName={trainerName}
      />

      <StallApplicationControlPanel
        shouldDisplayPPs={state.shouldDisplayPPs}
        areAllEntriesApproved={areAllEntriesApproved}
        areAllEntriesDenied={areAllEntriesDenied}
        entriesNumber={data.stableEntriesCount}
        dispatch={dispatch}
      />

      <div className={classes.stableEntries}>{renderEntries()}</div>

      <StallApplicationFooter
        canSubmit={areAllEntriesSelected}
        onOpenReviewForm={handleOpenReviewFormPopup}
        onSaveAndClose={handleSaveAndClose}
      />

      {isReviewFormPopupOpen && (
        <ReviewFormPopup
          onClose={handleCloseReviewFormPopup}
          onSubmit={handleComplete}
          data={reviewFormPopupData}
        />
      )}

      {isRSStallApplicationUnsavedChangesPopupOpen && (
        <UnsavedChangesDialog
          open={true}
          onCancel={handleCloseUnsavedChangesPopup}
          onSave={handleSaveAndClose}
          onDiscard={handleDiscardUnsavedChangesPopup}
          saveButtonLabel={"Save & Close"}
          cancelButtonLabel={"Back"}
          content={renderUnsavedChangesContent()}
          disallowClosePopupOutside={true}
        />
      )}

      {isLoading && <Loader className={classes.loader} />}
    </OverlayPage>
  );
};

export default React.memo(StallApplication);
