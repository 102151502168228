import React, { useState, useRef } from "react";
import clsx from "clsx";
import { useSelector } from "react-redux";

import Box from "@material-ui/core/Box";
import IconButton from "@material-ui/core/IconButton";
import CloseIcon from "@material-ui/icons/Close";
import Container from "@material-ui/core/Container";
import Typography from "@material-ui/core/Typography";

import { racehorse360 } from "@tsg/1st-grpc-web";
import { useRacehorse360Api } from "hooks/api";

import DaysOffTracker from "components/DaysOffTracker";
import Loader from "components/Loader";
import AppPage from "components/AppPage";
import AppPageContent from "components/AppPageContent";
import AppPageHeader from "components/AppPageHeader";
import NextDaysActivities from "components/NextDaysActivities";
import ErrorBoundary from "components/ErrorBoundary";
import { useLoggedInUser } from "components/LoggedInUserProvider";
import { currentTimeZoneString } from "utils/date-utils";
import { useInfiniteScroll } from "hooks/useInfiniteScroll";
import IQueriesConfigState from "interfaces/queriesConfigState";
import { SortOrder } from "interfaces/SortOrder";
import DashboardNotifications from "./DashboardNotifications";
import InfoBlocks from "./InfoBlocks";
import Forecast from "./Forecast";
import useStyles from "./styles";

const nextDaysQuantity = 3;
const nextXDaysDateFilter: racehorse360.IDateMatch = {
  range: {
    relative: {
      startDate: {
        value: 0
      },
      endDate: {
        value: nextDaysQuantity
      },
      timezone: currentTimeZoneString()
    }
  }
};

const DashboardPage = () => {
  const classes = useStyles();
  const [countSheetOpen, setCountSheetOpen] = useState<boolean>(false);
  const tableBodyRef = useRef<HTMLDivElement>(null);
  const rootRef = useRef(null);
  const { currentUser } = useLoggedInUser();

  const currentFacilityId = useSelector(
    (state: { queriesConfig: IQueriesConfigState }) =>
      state?.queriesConfig?.currentFacilityId
  );

  const {
    useListHorses,
    useListWorkoutRequests,
    useInfiniteListWorkoutRequests,
    useListRaceHistories,
    useListUpcomingRaceHorses,
    usePullTrainerHorsesWithMatchingRacesCount
  } = useRacehorse360Api();

  const { data: dataHorses, isLoading: isListHorsesLoading } = useListHorses(
    "daysOffTracker",
    {
      query: {
        trainerIds: currentUser.trainerIds
      },
      pagingOptions: {
        maxResults: 100
      },
      getOptions: {
        select: [
          "last_race_date",
          "name",
          "currentFacility.workout_required_days_off",
          "last_workout_date"
        ],
        orderBy: [`lastRaceDate ${SortOrder.ASC}`]
      }
    },
    { onError: error => console.error(error) }
  );

  const horses: racehorse360.IHorse[] = dataHorses?.horses || [];

  const workoutsQuery = {
    trainerIds: currentUser.trainerIds,
    date: {
      range: {
        relative: {
          startDate: {
            value: 0
          }
        }
      }
    },
    statuses: [
      racehorse360.WorkoutRequestStatus.WORKOUT_REQUEST_STATUS_REQUESTED,
      racehorse360.WorkoutRequestStatus.WORKOUT_REQUEST_STATUS_EXAM_PENDING,
      racehorse360.WorkoutRequestStatus.WORKOUT_REQUEST_STATUS_APPROVED
    ]
  };

  const { data: dataTotalWorkouts, refetch: refetchWorkoutsTotalCount } =
    useListWorkoutRequests({
      query: workoutsQuery,
      pagingOptions: {
        maxResults: 1, // to know total count of the WorkoutRequests
        includeSummary: true
      }
    });

  const totalWorkouts: number = dataTotalWorkouts?.pagingInfo?.totalResults;

  const {
    isLoading: isListWorkoutsLoading,
    data,
    isFetchingNextPage,
    hasNextPage,
    fetchNextPage,
    refetch: refetchInfiniteWorkoutRequests
  } = useInfiniteListWorkoutRequests(
    {
      query: workoutsQuery,
      pagingOptions: {
        maxResults: 25,
        includeSummary: true
      },
      getOptions: {
        select: [
          "id",
          "date",
          "horse.name",
          "facility.code",
          "facility.name",
          "facility.backgroundColor",
          "facility.foregroundColor",
          "facility.strokeColor"
        ],
        orderBy: ["date"]
      }
    },
    {
      enabled: Boolean(horses.length),
      onError: error => console.error(error)
    }
  );

  const workouts: racehorse360.IWorkoutRequest[] = data?.pages
    ? data.pages.flat()
    : [];

  useInfiniteScroll(
    tableBodyRef,
    fetchNextPage,
    hasNextPage,
    isFetchingNextPage,
    true,
    false
  );

  const {
    data: dataUpcomingWorkouts,
    isLoading: isUpcomingWorkoutsLoading,
    refetch: refetchListWorkout
  } = useListWorkoutRequests(
    {
      query: {
        trainerIds: currentUser.trainerIds,
        date: nextXDaysDateFilter,
        statuses: [
          racehorse360.WorkoutRequestStatus.WORKOUT_REQUEST_STATUS_REQUESTED,
          racehorse360.WorkoutRequestStatus.WORKOUT_REQUEST_STATUS_EXAM_PENDING,
          racehorse360.WorkoutRequestStatus.WORKOUT_REQUEST_STATUS_APPROVED
        ]
      },
      pagingOptions: {
        maxResults: 100
      },
      getOptions: {
        select: [
          "id",
          "date",
          "horse.name",
          "facility",
          "facility.backgroundColor",
          "facility.name",
          "facility.code",
          "facility.shut_off_period_in_hours",
          "facility.shut_off_hours",
          "facility.timezone",
          "status"
        ],
        orderBy: ["date"]
      }
    },
    {
      enabled: Boolean(horses.length),
      onError: error => console.error(error)
    }
  );

  const upcomingWorkouts: racehorse360.IWorkoutRequest[] =
    dataUpcomingWorkouts?.workoutRequests || [];

  const { data: dataLastRace, isLoading: isLastRaceLoading } =
    useListRaceHistories(
      {
        query: {
          trainerIds: currentUser.trainerIds,
          isRaceOfficial: { value: true },
          horseFacilityIds: currentFacilityId ? [currentFacilityId] : null
        },
        pagingOptions: {
          maxResults: 1
        },
        getOptions: {
          select: [
            "id",
            "horse",
            "earnings",
            "date",
            "facility.id",
            "facility.code",
            "facility.name",
            "raceNumber",
            "positionAtFinish"
          ],
          orderBy: [`date ${SortOrder.DESC}`]
        }
      },
      {
        enabled: Boolean(horses.length),
        onError: error => console.error(error)
      }
    );

  const recentRace: racehorse360.IRaceHistory =
    dataLastRace?.raceHistories[0] || null;

  const {
    data: dataUpcomingRaceHorses,
    isLoading: isUpcomingRaceHorsesLoading
  } = useListUpcomingRaceHorses(
    {
      query: {
        trainerIds: currentUser.trainerIds,
        raceDate: nextXDaysDateFilter
      },
      getOptions: {
        select: [
          "id",
          "horse",
          "horse.trainer.firstName",
          "horse.trainer.middleName",
          "horse.trainer.lastName",
          "raceDate",
          "facility.name",
          "facility.backgroundColor",
          "raceNumber"
        ],
        orderBy: [`raceDate ${SortOrder.ASC}`]
      }
    },
    {
      enabled: Boolean(horses.length),
      onError: error => console.error(error)
    }
  );

  const upcomingRaceHorses: racehorse360.IUpcomingRaceHorse[] =
    dataUpcomingRaceHorses?.upcomingRaceHorses || [];

  if (!currentUser.isTrainer) {
    return <></>;
  }

  const potentialMatches: string[] = [];

  const handlePotentialMatchesClick = () => {
    setCountSheetOpen(true);
  };

  const handleCountSheetClose = () => {
    setCountSheetOpen(false);
  };

  const { data: dataMatchingHorses, isLoading: isMatchingHorsesLoading } =
    usePullTrainerHorsesWithMatchingRacesCount(currentUser.trainerIds[0], {});

  const matchingHorses: racehorse360.IHorseWithMatchingRacesCount[] =
    dataMatchingHorses?.horsesWithMatchingRacesCount || [];

  const handleMatchingHorsesClick = () => {
    setCountSheetOpen(true);
  };

  const isLoading =
    isListHorsesLoading ||
    isListWorkoutsLoading ||
    isLastRaceLoading ||
    isUpcomingRaceHorsesLoading ||
    isUpcomingWorkoutsLoading ||
    isMatchingHorsesLoading;

  if (isLoading) {
    return <Loader />;
  }

  return (
    <AppPage>
      {countSheetOpen && (
        <AppPageHeader className={classes.countSheetHeader}>
          <Box
            className={clsx(
              classes.width40P,
              classes.flexGrow,
              classes.countSheetDateBlock,
              classes.alignLeft
            )}
          >
            <Typography className={classes.countSheetDateBlockTitle}>
              THIRTY-SIXTH DAY
            </Typography>
            <Typography className={classes.countSheetDateBlockValue}>
              Sunday, August 8, 2020
            </Typography>
          </Box>
          <Typography component={"b"} className={classes.countSheetTitle}>
            Count Sheet
          </Typography>
          <Box
            className={clsx(classes.width40P, classes.flex, classes.flexGrow)}
          >
            <Box
              className={clsx(
                classes.flexGrow,
                classes.countSheetDateBlock,
                classes.alignRight
              )}
            >
              <Typography className={classes.countSheetDateBlockTitle}>
                ENTRIES CLOSE ON
              </Typography>
              <Typography className={classes.countSheetDateBlockValue}>
                Thursday, August 5, 2020
              </Typography>
            </Box>
            <IconButton
              className={classes.closeIconButton}
              onClick={handleCountSheetClose}
            >
              <CloseIcon className={classes.closeIcon} />
            </IconButton>
          </Box>
        </AppPageHeader>
      )}
      <AppPageContent className={classes.appPageContent}>
        {countSheetOpen && (
          <Container
            ref={rootRef}
            maxWidth={false}
            className={classes.countSheetContainer}
          >
            {/*countSheetDataMock.map(csi => (
              <CountSheetItem key={csi.id} raceInfo={csi} />
            ))*/}
          </Container>
        )}
        <Box
          className={clsx(classes.gridContainer, {
            [classes.hidden]: countSheetOpen
          })}
        >
          <ErrorBoundary>
            <InfoBlocks
              className={classes.gridInfo}
              workouts={workouts}
              potentialMatches={potentialMatches}
              recentRace={recentRace}
              onPotentialMatchesClick={handlePotentialMatchesClick}
              tableBodyRef={tableBodyRef}
              totalWorkouts={totalWorkouts}
              isFetchingNextPage={isFetchingNextPage}
              matchingHorses={matchingHorses}
              onMatchingHorsesClick={handleMatchingHorsesClick}
            />
          </ErrorBoundary>

          <ErrorBoundary>
            <DashboardNotifications className={classes.gridNotifications} />
          </ErrorBoundary>

          <ErrorBoundary>
            <DaysOffTracker
              className={classes.gridDaysOffTracker}
              horses={horses}
            />
          </ErrorBoundary>

          <ErrorBoundary>
            <NextDaysActivities
              className={classes.gridNext3Days}
              daysQuantity={nextDaysQuantity}
              upcomingWorkouts={upcomingWorkouts}
              upcomingRaceHorses={upcomingRaceHorses}
              refetchListWorkout={refetchListWorkout}
              refetchWorkoutsTotalCount={refetchWorkoutsTotalCount}
              refetchInfiniteWorkoutRequests={refetchInfiniteWorkoutRequests}
            />
          </ErrorBoundary>

          <ErrorBoundary>
            <Forecast className={classes.gridForecast} />
          </ErrorBoundary>
        </Box>
      </AppPageContent>
    </AppPage>
  );
};

export default React.memo(DashboardPage);
