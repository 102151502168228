import React, { useEffect, useRef, useState } from "react";
import clsx from "clsx";

import AccordionSummary from "@material-ui/core/AccordionSummary";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import { Theme } from "@material-ui/core/styles";
import useMediaQuery from "@material-ui/core/useMediaQuery";

import Breakpoints from "common/breakpoints";
import useStyles from "./styles";

interface IProps {
  maxWidthPreview?: number;
  maxMobileWidthPreview?: number;
  withLimit?: boolean;
  title: string;
  previewItems: string[];
  isExpanded?: boolean;
}

const PREVIEW_ITEM_INDENTS_WIDTH = 16;
const AVERAGE_LETTER_WIDTH = 6;
const BASE_PREVIEW_WRAP_HEIGHT = 38;

const FilterAccordionSummary = (props: IProps) => {
  const {
    title,
    previewItems = [],
    withLimit = false,
    maxWidthPreview = Number.POSITIVE_INFINITY,
    maxMobileWidthPreview = 140,
    isExpanded
  } = props;
  const classes = useStyles();
  const accordionSummaryRef = useRef<HTMLDivElement>();
  const titleRef = useRef<HTMLSpanElement>();
  const previewWrapRef = useRef<HTMLDivElement>();
  const matchesDownXS420 = useMediaQuery((theme: Theme) =>
    theme.breakpoints.down(Breakpoints.XS_420)
  );

  const [previewWrapHeight, setPreviewWrapHeight] = useState<number>();

  const renderPreview = () => {
    if (!previewItems.length) {
      return (
        <>
          {renderTitle()}
          <span className={classes.markAll}>All</span>
        </>
      );
    }

    let previewWidth = 0;
    const limitedWidth = matchesDownXS420
      ? maxMobileWidthPreview
      : maxWidthPreview;
    const preparedPreviewItems = previewItems.filter(
      (item: string, index: number) => {
        previewWidth =
          previewWidth +
          item?.length * AVERAGE_LETTER_WIDTH +
          PREVIEW_ITEM_INDENTS_WIDTH;
        const isCutPreview = previewWidth <= limitedWidth;

        if ((isExpanded && matchesDownXS420) || !index) {
          return item;
        }

        return withLimit ? isCutPreview : item;
      }
    );
    const difference = previewItems.length - preparedPreviewItems.length;

    return (
      <div
        ref={previewWrapRef}
        className={clsx(classes.previewWrap, {
          ["mobileExpandedView"]: isExpanded && matchesDownXS420
        })}
      >
        {matchesDownXS420 && renderTitle()}
        {preparedPreviewItems.map((item: string, index: number) => {
          const accordionSummaryWidth =
            accordionSummaryRef?.current?.clientWidth;
          const titleWidth = titleRef?.current?.clientWidth;
          const anotherItemsWidth = previewItems.length > 1 ? 150 : 90;

          const previewItemStyles =
            !index && matchesDownXS420 && !isExpanded
              ? {
                  maxWidth:
                    accordionSummaryWidth - titleWidth - anotherItemsWidth
                }
              : null;

          return (
            <span
              style={previewItemStyles}
              key={item + index}
              className={clsx(classes.previewItem, {
                ["croppedPreviewItem"]: matchesDownXS420 && !isExpanded
              })}
            >
              {item}
            </span>
          );
        })}
        {Boolean(withLimit && difference) && (
          <span className={classes.nMore}>{`+${difference} More`}</span>
        )}
      </div>
    );
  };

  const renderTitle = () => {
    return (
      <span ref={titleRef} className={classes.title}>
        {title}
      </span>
    );
  };

  useEffect(() => {
    const currentPreviewWrapHeight = previewWrapRef?.current?.clientHeight;

    setPreviewWrapHeight(currentPreviewWrapHeight);
  }, [previewItems.length]);

  return (
    <AccordionSummary
      ref={accordionSummaryRef}
      className={clsx(classes.summary, {
        ["mobileExpandedView"]:
          matchesDownXS420 &&
          previewWrapHeight > BASE_PREVIEW_WRAP_HEIGHT &&
          isExpanded
      })}
      classes={{
        root: classes.sumRoot,
        expanded: classes.sumRootExpanded
      }}
      expandIcon={<ExpandMoreIcon />}
    >
      {Boolean(!matchesDownXS420 && previewItems.length) && renderTitle()}
      {renderPreview()}
    </AccordionSummary>
  );
};

export default FilterAccordionSummary;
