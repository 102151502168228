import React, { useEffect, useState } from "react";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableContainer from "@material-ui/core/TableContainer";

import { racehorse360 } from "@tsg/1st-grpc-web";
import Accordion from "components/Accordion";
import Loader from "components/Loader";
import Pagination from "components/Pagination";
import PaperCarousel from "components/PaperCarousel";
import { TabTable } from "components/TabTables/TabTables";
import { useRacehorse360Api } from "hooks/api";

import PastPerformancesItem from "./PastPerformancesItem";
import { SortOrder } from "interfaces/SortOrder";
import useStyles from "./styles";

export interface Props extends TabTable {
  horse: racehorse360.IHorse;
  rootRef: any;
  pagination?: boolean;
  isSliderOnMobile?: boolean;
  maxResults?: number;
}

const PAGE_SIZE = 3;
const SLIDE_SIZE = 5;

const PastPerformances = (props: Props) => {
  const { horse, pagination, rootRef, onLoad, isSliderOnMobile, maxResults } =
    props;
  const classes = useStyles();
  const pageSize = maxResults || PAGE_SIZE;
  const isSliderView = !pagination && isSliderOnMobile;
  const [isInitialLoad, setIsInitialLoad] = useState<boolean>(true);
  const [activePage, setActivePage] = useState<number>(1);
  const [activeSlide, setActiveSlide] = useState<number>(0);
  const [raceSlides, setRaceSlides] = useState<racehorse360.IRaceHistory[][]>(
    []
  );

  const { useListRaceHistories } = useRacehorse360Api();
  const { isLoading, data } = useListRaceHistories(
    {
      query: {
        horse: horse.id,
        isRaceOfficial: { value: true }
      },
      pagingOptions: {
        maxResults: isSliderView ? SLIDE_SIZE : pageSize,
        offset: isSliderView
          ? activeSlide * SLIDE_SIZE
          : (activePage - 1) * pageSize,
        includeSummary: true
      },
      getOptions: {
        select: [
          "comment",
          "date",
          "facility.code",
          "raceNumber",
          "isScratched",
          "scratchReason",
          "positionAtPointOfCall_1",
          "positionAtPointOfCall_2",
          "positionAtPointOfCall_3",
          "positionAtPointOfCall_4",
          "positionAtPointOfCall_5",
          "positionAtFinish",
          "lengthAheadAtPointOfCall1",
          "lengthAheadAtPointOfCall2",
          "lengthAheadAtPointOfCall5",
          "lengthAheadAtFinish",
          "lengthBehindAtPointOfCall1",
          "lengthBehindAtPointOfCall2",
          "lengthBehindAtPointOfCall5",
          "lengthBehindAtFinish",
          "medication",
          "postPosition",
          "race.id",
          "race.surface",
          "race.trackCondition",
          "race.courseType",
          "race.distance",
          "race.distanceUnit",
          "race.ageRestriction",
          "race.genderRestriction",
          "race.raceType",
          "race.raceNameAbbreviated",
          "race.raceConditions",
          "race.raceName",
          "race.numberOfRunners",
          "race.aboutDistanceIndicator",
          "race.fraction_1",
          "race.fraction_2",
          "race.fraction_3",
          "race.fraction_4",
          "race.fraction_5",
          "race.surface",
          "jockey.firstName",
          "jockey.lastName",
          "weightCarried",
          "medication",
          "equipment",
          "odds",
          "newOwner.firstName",
          "newOwner.lastName",
          "newTrainer.firstName",
          "newTrainer.middleName",
          "newTrainer.lastName",
          "isVoid",
          "voidReason"
        ],
        orderBy: [`date ${SortOrder.DESC}`]
      }
    },
    {
      onSuccess: ({ raceHistories, pagingInfo }) => {
        if (isSliderView) {
          const slides = isInitialLoad
            ? new Array(Math.ceil(pagingInfo.totalResults / SLIDE_SIZE)).fill(
                null
              )
            : [...raceSlides];

          slides[activeSlide] = raceHistories;

          setRaceSlides(slides);
          setIsInitialLoad(false);
        }
        onLoad && onLoad(raceHistories, pagingInfo);
      },
      onError: error => {
        console.error(error);
      }
    }
  );
  const { raceHistories = [], pagingInfo: { totalResults = 0 } = {} } =
    data || {};

  const from = pagination ? 0 : activeSlide * SLIDE_SIZE + 1;
  const to =
    !pagination && activeSlide * SLIDE_SIZE + SLIDE_SIZE < totalResults
      ? activeSlide * SLIDE_SIZE + SLIDE_SIZE
      : totalResults;

  const handleSlideChange = index => {
    setActiveSlide(index);
  };

  const renderRows = items => {
    let sliced;
    if (isSliderView) {
      sliced = items;
    } else {
      sliced = !pagination
        ? raceHistories.slice(
            (activePage - 1) * pageSize,
            (activePage - 1) * pageSize + pageSize
          )
        : raceHistories;
    }

    if (!sliced || (!isSliderView && isLoading)) {
      return <Loader className={classes.slideLoader} />;
    }
    return sliced.map(rh => (
      <PastPerformancesItem key={rh.id} raceHistoryItem={rh} />
    ));
  };

  const accordionProps = isSliderOnMobile
    ? {
        className: classes.accordion,
        hideBackground: true,
        hideShadow: true,
        roundCount: true,
        hideSubtitleWhenCollapsed: true,
        subtitle: raceHistories.length ? (
          `Showing ${from}-${to} of ${totalResults}`
        ) : (
          <Loader size={16} />
        )
      }
    : {};

  useEffect(() => {
    if (data?.raceHistories && onLoad) {
      onLoad(data?.raceHistories, data?.pagingInfo);
    }
  }, []);

  const renderTable = (items = null) => {
    return raceHistories.length || isLoading ? (
      <TableContainer className={classes.tableContainer}>
        <Table>
          <TableBody>{renderRows(items)}</TableBody>
        </Table>
      </TableContainer>
    ) : (
      <div className={classes.emptyDataHolder}>No Past Performances</div>
    );
  };

  if (isLoading && pagination) {
    return <Loader className={classes.slideLoader} />;
  }

  return (
    <>
      {pagination ? (
        <>
          {renderTable()}
          {Boolean(totalResults) && (
            <Pagination
              totalCount={totalResults}
              pageNumber={activePage}
              pageSize={pageSize}
              onPageChange={setActivePage}
            />
          )}
        </>
      ) : (
        <Accordion
          disabled={(!raceHistories?.length && !isLoading) || isLoading}
          count={totalResults}
          showCountLoader={isSliderView}
          isLoading={isLoading}
          name={"Past Performances"}
          rootRef={rootRef}
          {...accordionProps}
        >
          {isSliderOnMobile ? (
            <PaperCarousel
              items={raceSlides}
              itemRenderer={renderTable}
              onSlideChange={handleSlideChange}
            />
          ) : (
            renderTable(raceHistories)
          )}
        </Accordion>
      )}
    </>
  );
};

export default PastPerformances;
