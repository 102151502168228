import React, { useEffect, useState } from "react";
import { useQueryClient } from "react-query";
import { useDispatch } from "react-redux";
import { goBack } from "connected-react-router";
import { useFormik } from "formik";
import formatISO from "date-fns/formatISO";

import Button from "@material-ui/core/Button";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import useTheme from "@material-ui/core/styles/useTheme";

import Breakpoints from "common/breakpoints";
import AppPage from "components/AppPage";
import AppPageHeader from "components/AppPageHeader";
import AppPageContent from "components/AppPageContent";
import { useClickBlockerContext } from "components/BlockableClickContext";
import FormField from "components/FormField";
import Loader from "components/Loader";
import { useLoggedInUser } from "components/LoggedInUserProvider";
import { useSnackbar } from "components/SnackbarContext/SnackbarContext";
import UnsavedChangesDialog from "components/UnsavedChangesDialog";
import { useRacehorse360Api } from "hooks/api";
import { validationSchemaStallApplicationForm } from "utils/validationSchemas";
import { getModules, initialState, IState } from "./constants";
import Review from "./Review";
import useStyles from "./styles";

const StallApplicationNewFormPage = () => {
  const classes = useStyles();
  const { currentUser } = useLoggedInUser();
  const theme = useTheme();
  const matchesDownSm = useMediaQuery(
    theme.breakpoints.down(Breakpoints.SM_600),
    {
      noSsr: true
    }
  );
  const dispatch = useDispatch();
  const { showSuccessSnack, showErrorSnack } = useSnackbar();
  const { blockClick, setBlockClick, setBlockClickCallback } =
    useClickBlockerContext();
  const queryClient = useQueryClient();
  const [unsavedChangesDialogOpen, setUnsavedChangesDialogOpen] =
    useState<boolean>(false);

  const {
    useCreateStallApplicationForm,
    useListFacilities,
    useSetFacilityOptions
  } = useRacehorse360Api();

  const {
    data: { facilities } = { facilities: [] },
    isLoading: isListFacilitiesLoading
  } = useListFacilities(
    {
      query: {
        isActive: { value: true },
        ids: currentUser.facilityIdsListFromRoles
      },
      pagingOptions: {
        maxResults: 99
      },
      getOptions: {
        select: [
          "name",
          "type",
          "code",
          "training_facilities:name",
          "training_facilities:code",
          "training_facilities:type"
        ],
        orderBy: ["name"]
      }
    },
    {
      onError: error => console.error(error)
    }
  );

  const {
    mutateAsync: setFacilityOptions,
    isLoading: isFacilityOptionsSetting
  } = useSetFacilityOptions();

  const {
    mutateAsync: createStallApplicationForm,
    isLoading: isStallApplicationFormCreating
  } = useCreateStallApplicationForm();

  const saveAndClose = async () => {
    createStallApplicationForm({
      stallApplicationForm: {
        ...values,
        facilityOptions: undefined,
        startDate: formatISO(values.startDate, {
          representation: "date"
        }),
        closeDate: formatISO(values.closeDate, {
          representation: "date"
        }),
        deadlineDate: formatISO(values.deadlineDate, {
          representation: "date"
        })
      },
      termsFile:
        values.pdf &&
        (await new Promise<Uint8Array>(resolve => {
          const reader = new FileReader();
          reader.onload = () =>
            resolve(new Uint8Array(reader.result as ArrayBuffer));
          reader.readAsArrayBuffer(values.pdf);
        }))
    })
      .then(newForm =>
        setFacilityOptions({
          stallApplicationFormId: newForm.id,
          facilityIds: values.facilityOptions
        })
      )
      .then(() => queryClient.invalidateQueries(["listStallApplicationForms"]))
      .then(() => {
        unlockNavigation();
        dispatch(goBack());
        showSuccessSnack("Form created successfully.");
      })
      .catch(() => {
        showErrorSnack("Form could not be created. Please try again.");
      });
  };

  const formik = useFormik<IState>({
    initialValues: initialState,
    validationSchema: validationSchemaStallApplicationForm,
    validateOnMount: true,
    onSubmit: saveAndClose
  });
  const values = formik.values;

  const lockNavigation = () => {
    setBlockClick(true);
    setBlockClickCallback(() => () => {
      setUnsavedChangesDialogOpen(true);
    });
  };

  const unlockNavigation = () => {
    setBlockClick(false);
    setBlockClickCallback(null);
  };

  const handleChange = value => {
    formik.handleChange(value);
  };

  const handleValueChange = fieldName => async value => {
    if (!value.target) {
      await formik.setFieldValue(fieldName, value);
      return;
    }

    await formik.setFieldValue(fieldName, value.target.value);
  };

  const handleCreateNewForm = async () => {
    await saveAndClose();
  };

  const handleBackClick = () => {
    setUnsavedChangesDialogOpen(false);
  };

  const handleDiscardClick = () => {
    setUnsavedChangesDialogOpen(false);
    unlockNavigation();
    dispatch(goBack());
  };

  const handleCancelClick = () => {
    if (blockClick) {
      setUnsavedChangesDialogOpen(true);
    } else {
      dispatch(goBack());
    }
  };

  useEffect(() => {
    if (
      Object.values(formik.values).filter(v =>
        Boolean(Array.isArray(v) ? v.length : v)
      ).length
    ) {
      lockNavigation();
    } else {
      unlockNavigation();
    }
  }, [...Object.values(formik.values)]);

  useEffect(() => {
    return () => {
      unlockNavigation();
    };
  }, []);

  const renderUnsavedChangesPopupContent = () => (
    <div>
      You are about to discard any changes you have made to the new intake form.
      Are you sure you want to discard this form?
    </div>
  );

  const filledModules = getModules(facilities, values);

  return (
    <AppPage className={classes.root}>
      <AppPageHeader className={classes.pageHeader}>
        {matchesDownSm && (
          <>
            <Button
              className={classes.pageHeaderButtonCancel}
              onClick={handleCancelClick}
            >
              Cancel
            </Button>
            <span className={classes.pageHeaderTitle}>New Intake Form</span>
          </>
        )}
      </AppPageHeader>
      <AppPageContent className={classes.pageContent}>
        {isListFacilitiesLoading && <Loader overlay />}
        <div className={classes.content}>
          <div className={classes.form}>
            {filledModules.map(module => (
              <React.Fragment key={module.name}>
                <div className={classes.formTitle}>{module.name}</div>
                {module.fields.map(field => (
                  <FormField
                    key={field.name}
                    className={classes.formField}
                    field={field}
                    moduleName={module.name}
                    value={values[field.name]}
                    onChange={handleChange}
                    onChangeCustom={handleValueChange}
                    error={formik.errors[field.name]}
                  />
                ))}
              </React.Fragment>
            ))}
          </div>
        </div>
        <div className={classes.actions}>
          <Review
            values={formik.values}
            disabled={!formik.isValid}
            facilities={facilities}
            onConfirm={handleCreateNewForm}
            isLoading={
              isStallApplicationFormCreating ||
              isListFacilitiesLoading ||
              isFacilityOptionsSetting
            }
          />
        </div>
        <UnsavedChangesDialog
          onCancel={handleBackClick}
          onDiscard={handleDiscardClick}
          open={unsavedChangesDialogOpen}
          cancelButtonLabel={"BACK"}
          title={"Are you sure?"}
          content={renderUnsavedChangesPopupContent()}
        />
      </AppPageContent>
    </AppPage>
  );
};

export default StallApplicationNewFormPage;
