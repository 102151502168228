import { makeStyles } from "@material-ui/core/styles";

export default makeStyles({
  pastPerformances: {
    width: 860,
    paddingLeft: 20,
    fontFamily: "ArialNarrowBold, ArialNarrow",
    fontWeight: 600,
    fontSize: 12,
    letterSpacing: -1
  },
  lifeInfo: {
    display: "flex",
    justifyContent: "end",
    alignItems: "center",
    height: 20,
    paddingRight: 2
  },
  lifeInfoItem: {
    minWidth: 16,
    display: "flex",
    alignItems: "center",
    height: "100%"
  },
  lifeInfoTitle: {
    minWidth: 32
  },
  lastWorkouts: {
    display: "grid",
    gridTemplateColumns: "repeat(4, 1fr)",
    gridGap: 10,
    padding: "10px 0"
  },
  lastWorkout: {
    display: "flex",
    alignItems: "end",
    justifyContent: "center",
    backgroundColor: "#eeeeee",
    border: "1px solid #bababa",
    height: 20,
    borderRadius: 5,
    letterSpacing: -1
  }
});
