import { makeStyles } from "@material-ui/core/styles";
import { MuiTheme } from "theme";
import Breakpoints from "common/breakpoints";

export default makeStyles((theme: MuiTheme) => ({
  examReasonNote: {
    paddingLeft: 20,
    [theme.breakpoints.down(Breakpoints.MD_960)]: {
      width: "calc(100vw - 232px)"
    },
    [theme.breakpoints.down(Breakpoints.SM_600)]: {
      display: "flex",
      flexDirection: "column",
      width: "calc(100vw - 150px)"
    }
  },
  link: {
    fontSize: 13,
    textDecoration: "underline",
    cursor: "pointer"
  },
  linkReadOnly: {
    textDecoration: "none",
    cursor: "default"
  },
  date: {
    fontSize: 12
  },
  dateDash: {
    [theme.breakpoints.down(Breakpoints.SM_600)]: {
      display: "none"
    }
  },
  body: {
    fontSize: 12,
    padding: 0,
    margin: 0,
    wordBreak: "break-word"
  },
  dialogPaper: {
    width: 296
  },
  dialogTitle: {
    padding: "11px 20px",
    "& .MuiTypography-h6": {
      fontWeight: 700
    }
  },
  dialogContent: {
    display: "flex",
    flexDirection: "column",
    padding: "0 16px 16px 16px"
  },
  dialogActions: {
    display: "flex",
    gap: 4,
    justifyContent: "end",
    padding: "12px 16px",
    "& > div": {
      flex: 1,
      display: "flex",
      gap: 4,
      justifyContent: "end"
    }
  },
  dialogCloseButton: {
    position: "absolute",
    top: 8,
    right: 8
  },
  dialogCloseButtonIcon: {
    fontSize: 28
  },
  horseName: {
    padding: "0 4px",
    fontWeight: 700,
    marginBottom: 8,
    wordBreak: "break-word"
  },
  trainerName: {
    padding: "0 4px",
    marginBottom: 32,
    wordBreak: "break-word"
  },
  textarea: {
    "& label.MuiFormLabel-root": {
      color: theme.palette.primary.light
    },
    "& label.MuiFormLabel-root.Mui-focused": {
      color: theme.palette.secondary.dark
    },
    "&:hover label.MuiFormLabel-root": {
      color: theme.palette.secondary.dark
    },
    "& .MuiOutlinedInput-root fieldset": {
      borderColor: theme.palette.primary.light
    }
  },
  textareaLabel: {
    color: theme.palette.primary.light
  },
  buttonDelete: {
    color: theme.palette.warning.dark,
    fontWeight: 700,
    fontSize: 16
  },
  buttonCancel: {
    color: theme.palette.primary.dark,
    fontSize: 16
  },
  buttonSave: {
    color: theme.palette.secondary.dark,
    fontSize: 16
  }
}));
