import React from "react";
import clsx from "clsx";
import format from "date-fns/format";
import parseISO from "date-fns/parseISO";
import Divider from "@material-ui/core/Divider";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import Paper from "@material-ui/core/Paper";
import Typography from "@material-ui/core/Typography";
import { racehorse360 } from "@tsg/1st-grpc-web";
import useStyles from "./styles";

interface Props {
  horse: racehorse360.IHorse;
}

const Information = (props: Props) => {
  const { horse } = props;
  const classes = useStyles();

  return (
    <Paper className={classes.listWrapper}>
      <List className={classes.list}>
        <ListItem className={classes.listItem}>
          <Typography className={classes.listItemLabel}>Trainer</Typography>
          <Typography className={classes.listItemValue}>
            {horse.trainer?.firstName} {horse.trainer?.lastName}
          </Typography>
        </ListItem>
        <Divider light />
        <ListItem className={classes.listItem}>
          <Typography className={classes.listItemLabel}>Owner</Typography>
          <Typography className={classes.listItemValue}>
            {horse.ownerName}
          </Typography>
        </ListItem>
        <Divider light />
        <ListItem className={classes.listItem}>
          <Typography className={classes.listItemLabel}>
            Last Workout
          </Typography>
          <Typography
            className={clsx(
              { [classes.none]: !horse.lastWorkoutDate },
              classes.listItemValue
            )}
          >
            {horse.lastWorkoutDate
              ? format(parseISO(horse.lastWorkoutDate), "MMMM dd, yyyy")
              : "None"}
          </Typography>
        </ListItem>
        <Divider light />
        <ListItem className={classes.listItem}>
          <Typography className={classes.listItemLabel}>
            Last Workout Track
          </Typography>
          <Typography
            className={clsx(
              { [classes.none]: !horse.lastWorkoutFacility },
              classes.listItemValue
            )}
          >
            {horse.lastWorkoutFacility
              ? horse.lastWorkoutFacility.code
              : "None"}
          </Typography>
        </ListItem>
        <Divider light />
        <ListItem className={classes.listItem}>
          <Typography className={classes.listItemLabel}>Last Race</Typography>
          <Typography
            className={clsx(
              { [classes.none]: !horse.lastRaceDate },
              classes.listItemValue
            )}
          >
            {horse.lastRaceDate
              ? format(parseISO(horse.lastRaceDate), "MMMM dd, yyyy")
              : "None"}
          </Typography>
        </ListItem>
        <Divider light />
        <ListItem className={classes.listItem}>
          <Typography className={classes.listItemLabel}>
            Last Race Track
          </Typography>
          <Typography
            className={clsx(
              { [classes.none]: !horse.lastRaceFacility },
              classes.listItemValue
            )}
          >
            {horse.lastRaceFacility ? horse.lastRaceFacility.code : "None"}
          </Typography>
        </ListItem>
        <Divider light />
        <ListItem className={classes.listItem}>
          <Typography className={classes.listItemLabel}>
            Last Exam Date
          </Typography>
          <Typography
            className={clsx(
              { [classes.none]: !horse.lastExamDate },
              classes.listItemValue
            )}
          >
            {horse.lastExamDate
              ? format(parseISO(horse.lastExamDate), "MMMM dd, yyyy")
              : "None"}
          </Typography>
        </ListItem>
        <Divider light />
        <ListItem className={classes.listItem}>
          <Typography className={classes.listItemLabel}>
            Chip ID Number
          </Typography>
          <Typography className={classes.listItemValue}>{`${
            horse.chipNumber || "-"
          }`}</Typography>
        </ListItem>
      </List>
    </Paper>
  );
};

export default Information;
