import { makeStyles } from "@material-ui/core/styles";
import { MuiTheme } from "theme";
import Breakpoints from "common/breakpoints";
import EMediaDevices from "common/mediaDevices";

interface IStylesProps {
  activeColor?: string;
  titleColor?: string;
  borderColor?: string;
}

const DEFAULT_ACTIVE_TITLE_COLOR = "#fff";

export default makeStyles<MuiTheme, IStylesProps>((theme: MuiTheme) => {
  const { primary } = theme.palette;

  return {
    button: {
      borderRadius: 3,
      height: 45,
      transition: "background-color .25s",
      "& .MuiButton-label": {
        display: "flex",
        flexDirection: "column",
        textTransform: "capitalize",
        color: "rgba(0, 0, 0, 0.87)",
        fontSize: 12,
        lineHeight: "16px"
      },
      [theme.mediaDevices.hover(EMediaDevices.HOVER)]: {
        "&:hover": {
          backgroundColor: ({ activeColor }) => activeColor,
          opacity: 0.8,
          borderColor: ({ borderColor }) => borderColor || "transparent",
          "& span": {
            color: ({ titleColor }) => titleColor || DEFAULT_ACTIVE_TITLE_COLOR
          },
          "& + button": {
            borderLeftColor: ({ borderColor }) => borderColor || "transparent"
          }
        }
      },
      [theme.mediaDevices.pointer(EMediaDevices.ALL)]: {
        "&:hover": {
          backgroundColor: "transparent"
        }
      },
      [theme.breakpoints.down(Breakpoints.SM_600)]: {
        height: 35
      }
    },
    activeButton: {
      backgroundColor: ({ activeColor }) => activeColor,
      borderColor: ({ borderColor, activeColor }) => borderColor || activeColor,
      "&:hover": {
        opacity: 1
      },
      "&.MuiButton-root:hover": {
        backgroundColor: ({ activeColor }) => activeColor
      },
      "& .MuiButton-label": {
        color: ({ titleColor }) => titleColor || DEFAULT_ACTIVE_TITLE_COLOR
      },
      "& + button": {
        borderLeftColor: ({ borderColor }) => borderColor || "transparent"
      }
    },
    description: {
      fontSize: 10,
      color: primary.light,
      [theme.breakpoints.down(Breakpoints.SM_600)]: {
        display: "none"
      },
      "&.active": {
        color: ({ titleColor }) => titleColor || DEFAULT_ACTIVE_TITLE_COLOR
      }
    }
  };
});
