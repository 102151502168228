import React, { useEffect, useRef, useState } from "react";
import clsx from "clsx";

import { Theme } from "@material-ui/core/styles";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import Button from "@material-ui/core/Button";
import Typography from "@material-ui/core/Typography";
import ArrowBackIcon from "@material-ui/icons/ArrowBack";
import ArrowForwardIosIcon from "@material-ui/icons/ArrowForwardIos";
import CheckIcon from "@material-ui/icons/Check";
import ArrowDropDownIcon from "@material-ui/icons/ArrowDropDown";
import Dialog from "@material-ui/core/Dialog";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemText from "@material-ui/core/ListItemText";

import { useWindowSize } from "hooks/screen";
import {
  getDateRange,
  options,
  dialogViews,
  DIALOG_WIDTH,
  formatDate
} from "../helper";
import {
  Periods,
  ICoordinates,
  IConditionBooksSelectorProps
} from "../interfaces";
import useStyles from "./styles";

const ConditionBooksSelector = (props: IConditionBooksSelectorProps) => {
  const {
    className,
    selectedFacility,
    selectedPeriod,
    onSelectFilterOption,
    availableFacilities
  } = props;

  const facilities = availableFacilities;
  const classes = useStyles();
  const dialogRef = useRef(null);
  const bookNameRef = useRef(null);

  const [isOpen, setIsOpen] = useState<boolean>(false);
  const [coordinates, setCoordinates] = useState<ICoordinates>({
    top: 0,
    left: 0,
    right: 0
  });

  const [selectedView, setSelectedView] = useState<string>(
    dialogViews.dateFilterView
  );
  const [selectedFacilityId, setSelectedFacilityId] =
    useState<string>(selectedFacility);

  const matches = useMediaQuery((theme: Theme) => theme.breakpoints.up("lg"));
  const { width, height } = useWindowSize();

  const handleOpen = () => {
    setIsOpen(true);
  };

  const handleClose = () => {
    setSelectedFacilityId(selectedFacility);
    setIsOpen(false);
  };

  const handleFacilitySelect = (facilityId: string) => {
    setSelectedFacilityId(facilityId);
    setSelectedView(dialogViews.dateFilterView);
  };

  const handleDialogEntering = () => {
    countPosition();
  };

  const handleSelectOption = (period: Periods) => {
    onSelectFilterOption(selectedFacilityId, period);
    setIsOpen(false);
  };

  const countPosition = () => {
    if (!bookNameRef || !bookNameRef.current) {
      return;
    }

    const {
      top,
      left,
      right,
      height: nameHeight
    } = bookNameRef.current.getBoundingClientRect();

    let offsetTop = top + nameHeight;
    const offsetLeft = left + DIALOG_WIDTH < window.innerWidth ? left : "auto";
    const offsetRight =
      left + DIALOG_WIDTH > window.innerWidth
        ? window.innerWidth - right
        : "auto";

    if (dialogRef.current) {
      const dialogRect = dialogRef.current.getBoundingClientRect();
      if (offsetTop + dialogRect.height > window.innerHeight) {
        offsetTop = top - dialogRect.height;
      }
    }

    setCoordinates({ top: offsetTop, left: offsetLeft, right: offsetRight });
  };

  useEffect(() => {
    if (isOpen) {
      setSelectedView(dialogViews.dateFilterView);
      setSelectedFacilityId(selectedFacility);
    }
  }, [isOpen]);

  useEffect(() => {
    countPosition();
  }, [matches, width, height]);

  const renderDialogTitleContent = () => {
    let result = null;

    if (selectedView === dialogViews.facilityListView) {
      result = (
        <Typography className={clsx(classes.dialogMainTitle)}>
          Select Track
        </Typography>
      );
    }

    if (selectedView === dialogViews.dateFilterView) {
      const selectedFacilityName = facilities.find(
        fac => fac.id === selectedFacilityId
      )?.name;
      result = (
        <>
          <ArrowBackIcon
            className={clsx(classes.backArrowIcon, {
              [classes.arrowBackIconHidden]: availableFacilities.length < 2
            })}
            onClick={() => setSelectedView(dialogViews.facilityListView)}
          />
          <Typography className={clsx(classes.dialogMainTitle)}>
            {selectedFacilityName}
          </Typography>
        </>
      );
    }

    return result;
  };

  const renderDates = option => {
    const selectedFacility = facilities.find(
      fac => fac.id === selectedFacilityId
    );

    if (
      option.period === Periods.current &&
      selectedFacility?.earliestRaceDate &&
      selectedFacility?.latestRaceDate
    ) {
      return (
        <div className={classes.currentDateRange}>{`${formatDate(
          selectedFacility.earliestRaceDate,
          "MMM d, yyyy"
        )} - ${formatDate(
          selectedFacility.latestRaceDate,
          "MMM d, yyyy"
        )}`}</div>
      );
    }
  };

  const getButtonTitle = () => {
    const renderNoCurrentDateRange = () => {
      const result = getDateRange(selectedPeriod, props.currentDate);
      return (
        <span className={classes.selectedFilterDate}>
          {`${formatDate(result.startDateTime, "M/d/yy")} - ${formatDate(
            result.endDateTime,
            "M/d/yy"
          )}`}
        </span>
      );
    };

    const selectedFacilityData = facilities.find(
      fac => fac.id === selectedFacility
    );
    if (!selectedFacilityData) {
      return null;
    }
    const selectedFacilityCode = selectedFacilityData.code;
    const selectedFilterOption = options.find(
      opt => opt.period === selectedPeriod
    ).name;
    return (
      <>
        <span className={classes.facilityCode}>{selectedFacilityCode}</span>
        <span className={classes.selectedFilterOptionContainer}>
          <span className={classes.selectedFilterOption}>
            {selectedFilterOption}
          </span>
          {selectedPeriod === Periods.current &&
            selectedFacilityData.earliestRaceDate &&
            selectedFacilityData.latestRaceDate && (
              <span className={classes.selectedFilterDate}>
                {`${formatDate(
                  selectedFacilityData.earliestRaceDate,
                  "M/d/yy"
                )} - ${formatDate(
                  selectedFacilityData.latestRaceDate,
                  "M/d/yy"
                )}`}
              </span>
            )}
          {selectedPeriod !== Periods.current && renderNoCurrentDateRange()}
        </span>
      </>
    );
  };

  const renderDialogContent = () => {
    let result = null;

    if (selectedView === dialogViews.facilityListView) {
      result = (
        <List disablePadding={true} className={clsx(classes.dialogList)}>
          {facilities.map(facility => (
            <ListItem
              button
              key={facility.id}
              className={clsx(classes.listItem, {
                [classes.listItemSmall]: matches,
                [classes.listItemSelected]: facility.id === selectedFacility
              })}
              onClick={() => handleFacilitySelect(facility.id)}
            >
              <ListItemText
                primary={`${facility.name}`}
                className={clsx(classes.listItemText)}
              />
              <ArrowForwardIosIcon />
            </ListItem>
          ))}
        </List>
      );
    }

    if (selectedView === dialogViews.dateFilterView) {
      result = (
        <List disablePadding={true} className={clsx(classes.dialogList)}>
          {options.map(opt => (
            <ListItem
              button
              key={opt.id}
              className={clsx(classes.listItem, {
                [classes.listItemSmall]: matches,
                [classes.listItemSelected]:
                  opt.period === selectedPeriod &&
                  selectedFacilityId === selectedFacility
              })}
              onClick={() => handleSelectOption(opt.period)}
            >
              <ListItemText
                primary={
                  <div className={classes.listItemTextContent}>
                    <div>{`${opt.name}`}</div>
                    {renderDates(opt)}
                  </div>
                }
                className={clsx(classes.listItemText)}
              />
              {opt.period === selectedPeriod &&
                selectedFacilityId === selectedFacility && (
                  <CheckIcon className={classes.selectedItemIcon} />
                )}
            </ListItem>
          ))}
        </List>
      );
    }

    return result;
  };

  if (!availableFacilities.length || !selectedFacilityId) return null;

  return (
    <>
      <Button
        ref={bookNameRef}
        className={clsx(classes.conditionsBookButton, className)}
        onClick={handleOpen}
      >
        <Typography className={classes.selectedBookName}>
          {getButtonTitle()}
        </Typography>
        <ArrowDropDownIcon
          className={clsx(classes.selectedBookNameIcon, {
            [classes.selectedBookNameIconIsOpen]: isOpen
          })}
        />
      </Button>

      <Dialog
        fullWidth
        className={clsx(classes.root, classes.dialogRoot, {
          [classes.rootPopoverBack]: matches
        })}
        open={isOpen}
        TransitionProps={{
          onEntering: handleDialogEntering
        }}
        onClose={handleClose}
        classes={{
          paper: clsx({ [classes.rootPopover]: matches })
        }}
        PaperProps={{
          style: {
            maxWidth: DIALOG_WIDTH,
            margin: "0",
            left: matches ? coordinates.left : 0,
            top: matches ? coordinates.top : 0,
            right: matches ? coordinates.right : 0,
            overflow: "hidden"
          }
        }}
        hideBackdrop={matches}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <div className={clsx(classes.dialogContainer)} ref={dialogRef}>
          <DialogTitle
            disableTypography
            className={clsx(classes.dialogTitleHeader, {
              [classes.closable]: selectedView === dialogViews.facilityListView
            })}
          >
            {renderDialogTitleContent()}
          </DialogTitle>

          <DialogContent className={classes.dialogContent}>
            {renderDialogContent()}
          </DialogContent>
        </div>
      </Dialog>
    </>
  );
};

export default ConditionBooksSelector;
