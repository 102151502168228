import { makeStyles } from "@material-ui/core/styles";
import { MuiTheme } from "theme";
import Breakpoints from "common/breakpoints";

export default makeStyles((theme: MuiTheme) => {
  const { primary } = theme.palette;

  return {
    rowOdd: {
      [theme.breakpoints.down(Breakpoints.XS_420)]: {
        backgroundColor: "#fbfbfb"
      },
      [theme.breakpoints.up(Breakpoints.SM_600)]: {
        "&:not($rowExamChecked):not($rowPassChecked):not($rowAssignChecked):not($rowRejected)":
          {
            backgroundColor: "#fbfbfb",
            "& $menuButtons": {
              backgroundColor: "#fbfbfb"
            }
          }
      }
    },
    rowLink: {
      padding: "32px 16px 2px 16px",
      cursor: "pointer"
    },
    rowContent: {
      display: "grid"
    },
    rowCell: {
      display: "flex",
      alignItems: "center",
      padding: "0 4px",
      whiteSpace: "nowrap",
      height: 28,
      fontSize: 14,
      overflow: "hidden"
    },
    rowCellBold: {
      justifyContent: "center",
      fontWeight: "bold"
    },
    rowCellCentered: {
      justifyContent: "center"
    },
    trainerName: {
      overflow: "hidden",
      textOverflow: "ellipsis",
      width: "100%"
    },
    trainerComment: {
      width: "14px",
      height: "16px",
      marginLeft: "6px",
      "&:hover": {
        cursor: "pointer"
      }
    },
    fadedOut: {
      color: "#d8d8d8"
    },
    ellipsis: {
      display: "block",
      textOverflow: "ellipsis"
    },
    wrReqCommentsList: {
      maxHeight: 440,
      overflow: "auto"
    },
    wrReqCommentsContainer: {
      backgroundColor: "white",
      color: "black",
      borderRadius: 2,
      width: 320
    },
    wrReqCommentsListItem: {
      padding: "12px 16px",
      borderBottom: "1px solid #e6e6e6",
      "&:last-child": {
        borderBottom: "none"
      }
    },
    wrReqCommentsContainerHeader: {
      marginBottom: 8,
      fontSize: 13
    },
    wrReqCommentsContainerBody: {
      fontSize: 12
    },
    commentCreator: {
      fontWeight: 600,
      fontSize: 13
    },
    createDateTime: {
      color: primary.dark,
      fontSize: 13
    },
    menuButtonContent: {
      display: "flex",
      padding: "8px"
    },
    menuButtonContentChecked: {
      display: "flex"
    },
    menuButtonIcon: {
      width: "16px",
      height: "16px"
    },
    rowMenu: {
      position: "absolute",
      top: 0,
      right: 23,
      bottom: 0,
      padding: "0 4px",
      overflow: "hidden",
      zIndex: 1
    },
    menuButtons: {
      display: "flex",
      alignItems: "center",
      padding: 4,
      height: "100%",
      backgroundColor: "#fff",
      boxShadow: "0 0 4px 0 #ccc"
    },
    menuButton: {
      padding: 9,
      margin: "6px 8px",
      border: `1px solid ${primary.light}`,
      boxShadow: "0 0 4px 0 rgba(0, 0, 0, 0.15)",
      borderRadius: "4px",
      backgroundColor: "#fff",
      "&:hover": {
        backgroundColor: "#fff"
      }
    },
    menuButtonExamChecked: {
      backgroundColor: "rgba(240, 227, 191, 1.0)",
      "&:hover": {
        backgroundColor: "rgba(240, 227, 191, 1.0)"
      }
    },
    menuButtonPassChecked: {
      backgroundColor: "rgba(3, 243, 178, 1.0)",
      "&:hover": {
        backgroundColor: "rgba(3, 243, 178, 1.0)"
      }
    },
    menuButtonAssignChecked: {
      backgroundColor: "rgba(3, 243, 178, 1.0)",
      "&:hover": {
        backgroundColor: "rgba(3, 243, 178, 1.0)"
      }
    },
    rowExamChecked: {
      backgroundColor: "rgba(252, 249, 242, 1.0)"
    },
    rowPassChecked: {
      backgroundColor: "rgba(205, 253, 240, 1.0)"
    },
    rowAssignChecked: {
      backgroundColor: "rgba(205, 253, 240, 1.0)"
    },
    rowRejected: {
      backgroundColor: "#ffdbdc"
    },
    unassign: {
      color: "#f73d54"
    },
    exam: {
      color: "#21c37f",
      minWidth: 128,
      fontSize: 14,
      "& .MuiButton-iconSizeSmall > *:first-child": {
        fontSize: 20
      },
      "&.Mui-disabled": {
        color: primary.light,
        "& .MuiButton-iconSizeSmall > *:first-child": {
          color: primary.light
        }
      }
    },
    examIcon: {
      color: "#21c37f"
    },
    riskFactors: {
      overflow: "visible",
      paddingLeft: 4,
      [theme.breakpoints.up(Breakpoints.SM_600)]: {
        paddingLeft: 8
      }
    },
    activeVetLists: {
      display: "flex",
      justifyContent: "center",
      alignItems: "center"
    },
    activeVetListsContent: {
      textDecoration: "underline",
      cursor: "pointer",
      width: 30,
      height: 30,
      minWidth: 30,
      padding: 0,
      borderRadius: "50%",
      "&:hover": {
        textDecoration: "underline",
        cursor: "pointer"
      }
    },
    riskFactorsItem: {
      display: "flex",
      alignItems: "center",
      padding: "0 8px",
      marginLeft: 6,
      height: 21,
      border: `1px solid ${theme.palette.warning.main}`,
      color: theme.palette.warning.dark,
      [theme.breakpoints.up(Breakpoints.SM_600)]: {
        height: 24,
        padding: "0 11px"
      }
    },
    noRiskFactors: {
      color: "#d8d8d8",
      paddingLeft: 6
    },
    FOT: {
      color: "#ff4b4f",
      marginLeft: 4,
      fontSize: 12
    },
    FOTIcon: {
      color: "#ff4b4f",
      fontSize: 12,
      padding: 0
    },
    FOTButton: {
      zIndex: 1
    },
    rowHeader: {
      display: "flex",
      alignItems: "center",
      height: 32
    }
  };
});
