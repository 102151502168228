import React, { useState } from "react";
import { useRouteMatch } from "react-router";
import { Link, useLocation } from "react-router-dom";

import Tab from "@material-ui/core/Tab";
import Tabs from "@material-ui/core/Tabs";

import { racehorse360 } from "@tsg/1st-grpc-web";

import AppPage from "components/AppPage";
import AppPageContent from "components/AppPageContent";
import AppPageHeader from "components/AppPageHeader";
import AppSearch from "components/AppSearch";
import { AppSearchType } from "components/AppSearch/AppSearch";
import Loader from "components/Loader";
import NoContent from "../../components/NoContent";
import routes from "common/routes";
import StallApplicantsTable from "./StallApplicantsTable";
import { tableColumnsSets } from "./helpers";
import { useRacehorse360Api } from "hooks/api";
import { ILocationState } from "../StallApplicationFormPage/constants";
import useStyles from "./styles";

enum ETabs {
  applicants = 0,
  reviewed = 1,
  inProgress = 2
}

const StallApplicationFormViewPage = () => {
  const { params } = useRouteMatch<{ stallApplicationFormId: string }>(
    routes.stallApplicationFormView.path
  );
  const location = useLocation<ILocationState>();

  const [selectedTab, setSelectedTab] = useState(ETabs.applicants);

  const classes = useStyles();

  const getTableColumns = () => {
    return tableColumnsSets[selectedTab];
  };

  const { useGetStallApplicationForm, useListStallApplications } =
    useRacehorse360Api();

  const {
    data: stallApplicationForm,
    isLoading: isLoadingStallApplicationForm
  } = useGetStallApplicationForm({
    id: params.stallApplicationFormId,
    getOptions: {
      select: ["title"]
    }
  });

  const {
    data,
    isLoading,
    isFetching: isListStallApplicationsFetching
  } = useListStallApplications(
    "listStallApplications",
    {
      query: {
        stallApplicationFormId: params.stallApplicationFormId,
        statuses: [
          racehorse360.StallApplicationStatus.STALL_APPLICATION_STATUS_APPLIED
        ]
      },
      getOptions: {
        select: [
          "id",
          "arrivalDate",
          "sleepingRoomsNumber",
          "stallApplicationFormId",
          "status",
          "contactFullName",
          "stableEntriesCount",
          "stallApplicationForm.title",
          "stallApplicationForm.deadlineDate",
          "trainer.firstName",
          "trainer.lastName",
          "facilityPreferences:code",
          "stableEntries:customHorseName",
          "stableEntries:status",
          "stableEntries:raceReadyDate",
          "stableEntries:horse.name",
          "stableEntries:horse.lastTenPastPerformances"
        ]
      }
    },
    {
      onError: error => console.error(error)
    }
  );

  const getPathname = () => {
    return routes.stallApplicationTrainerForm.path.replace(
      ":stallApplicationFormId",
      String(params.stallApplicationFormId)
    );
  };

  const renderTabContent = () => {
    return data.stallApplications.length ? (
      <StallApplicantsTable
        stallApplications={data.stallApplications}
        tableColumns={getTableColumns()}
        isListStallApplicationsFetching={isListStallApplicationsFetching}
      />
    ) : (
      <NoContent message={"No Applicants"} />
    );
  };

  const handleTabChange = (event, value) => {
    // setSelectedTab(value);
  };

  return (
    <AppPage>
      <AppPageHeader className={classes.appPageHeader}>
        <div className={classes.formName}>
          {isLoadingStallApplicationForm ? (
            <Loader />
          ) : (
            <h3>{stallApplicationForm.title}</h3>
          )}
        </div>
        <div className={classes.menu}>
          <AppSearch
            className={classes.search}
            searchType={AppSearchType.Trainers}
          />
          <Link
            className={classes.link}
            to={{
              pathname: getPathname(),
              state: { facilityId: location.state.facilityId }
            }}
          >
            Manual Entry
          </Link>
        </div>
      </AppPageHeader>
      <AppPageContent className={classes.pageContent}>
        <Tabs
          value={selectedTab}
          className={classes.tabs}
          onChange={handleTabChange}
          TabIndicatorProps={{
            title: "indicator",
            className: classes.activeTab
          }}
        >
          <Tab label={"Applicants"} value={ETabs.applicants} />
          <Tab label={"Reviewed"} value={ETabs.reviewed} />
          <Tab label={"In-progress"} value={ETabs.inProgress} />
        </Tabs>
        <div className={classes.tabContent}>
          {isLoading && <Loader overlay />}
          {!isLoading && renderTabContent()}
        </div>
      </AppPageContent>
    </AppPage>
  );
};

export default StallApplicationFormViewPage;
