import { racehorse360 } from "@tsg/1st-grpc-web";

export enum EFlagPriority {
  HIGHEST = "highest",
  MEDIUM = "medium",
  LOWEST = "lowest",
  NONE = "none"
}

export const flagLevels = {
  [EFlagPriority.NONE]: "#7f7f7f",
  [EFlagPriority.LOWEST]: "#e35500",
  [EFlagPriority.MEDIUM]: "#d82f44",
  [EFlagPriority.HIGHEST]: "#9100ff"
};

export const getFlagPriority = (
  priority: racehorse360.FlagRequestPriority
): EFlagPriority => {
  switch (priority) {
    case racehorse360.FlagRequestPriority.FLAG_REQUEST_PRIORITY_LOWER: {
      return EFlagPriority.LOWEST;
    }
    case racehorse360.FlagRequestPriority.FLAG_REQUEST_PRIORITY_MEDIUM: {
      return EFlagPriority.MEDIUM;
    }
    case racehorse360.FlagRequestPriority.FLAG_REQUEST_PRIORITY_HIGHEST: {
      return EFlagPriority.HIGHEST;
    }
    default: {
      return EFlagPriority.NONE;
    }
  }
};

export const getRequestFlagPriority = (
  priority: EFlagPriority
): racehorse360.FlagRequestPriority => {
  switch (priority) {
    case EFlagPriority.LOWEST: {
      return racehorse360.FlagRequestPriority.FLAG_REQUEST_PRIORITY_LOWER;
    }
    case EFlagPriority.MEDIUM: {
      return racehorse360.FlagRequestPriority.FLAG_REQUEST_PRIORITY_MEDIUM;
    }
    case EFlagPriority.HIGHEST: {
      return racehorse360.FlagRequestPriority.FLAG_REQUEST_PRIORITY_HIGHEST;
    }
    default: {
      return racehorse360.FlagRequestPriority.FLAG_REQUEST_PRIORITY_INVALID;
    }
  }
};
