import { useSelector } from "react-redux";
import { parseISO } from "date-fns";

import DateRange, { DateRangeType } from "interfaces/DateRange";
import VetWorkoutsPageState from "interfaces/VetWorkoutsPageState";

interface ExtendedVetWorkoutsStore extends VetWorkoutsPageState {
  convertedToDateSelectedDateRange: DateRange;
}

export const useVetWorkoutsStore = (): ExtendedVetWorkoutsStore => {
  const {
    assignedOnly,
    selectedFacility,
    selectedTab,
    order,
    orderBy,
    selectedWorkoutRequest,
    searchValue,
    assignCheckedIds,
    savingCheckedIds,
    examCheckedIds,
    passCheckedIds,
    commentsPopoverAnchorEl,
    pageNumber,
    savingUnAssignIds,
    workoutRequests,
    savingUndoQuickPassIds,
    selectedDateRange
  } = useSelector(
    (state: { vetWorkoutsPage: VetWorkoutsPageState }) => state?.vetWorkoutsPage
  );

  const convertedToDateSelectedDateRange = useSelector(
    (state: { vetWorkoutsPage: VetWorkoutsPageState }) => {
      if (state?.vetWorkoutsPage?.selectedDateRange) {
        const range: DateRange = {
          Type: state.vetWorkoutsPage.selectedDateRange.Type
        };
        if (
          state?.vetWorkoutsPage?.selectedDateRange?.Type ===
          DateRangeType.Custom
        ) {
          range.CustomDateRange =
            state.vetWorkoutsPage.selectedDateRange.CustomDateRange.map(s =>
              parseISO(s)
            );
        }
        return range;
      }
      return {
        Type: DateRangeType.Next3Days
      };
    }
  );

  return {
    assignedOnly,
    selectedFacility,
    selectedTab,
    order,
    orderBy,
    selectedWorkoutRequest,
    searchValue,
    assignCheckedIds,
    savingCheckedIds,
    examCheckedIds,
    passCheckedIds,
    commentsPopoverAnchorEl,
    selectedDateRange,
    pageNumber,
    savingUnAssignIds,
    workoutRequests,
    savingUndoQuickPassIds,
    convertedToDateSelectedDateRange
  };
};
