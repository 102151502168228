import React, { useCallback } from "react";
import MuiDialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
import Typography from "@material-ui/core/Typography";
import Button from "@material-ui/core/Button";
import { UserProfileFormValues } from "../interfaces";
import useStyles from "./styles";

export interface Props {
  isOpen: boolean;
  onClose: () => void;
  onSubmit: () => void;
  userProfile: UserProfileFormValues;
}

const Dialog = (props: Props) => {
  const { isOpen, onClose, onSubmit, userProfile } = props;
  const classes = useStyles();

  const handleClose = useCallback(() => {
    onClose();
  }, [onClose]);

  const handleSubmit = useCallback(() => {
    onSubmit();
  }, [onSubmit]);

  return (
    <MuiDialog
      className={classes.component}
      open={isOpen}
      onClose={handleClose}
    >
      <DialogTitle disableTypography className={classes.title}>
        <Typography variant="h6" className={classes.titleCaption}>
          Confirm Submission
        </Typography>
        <Typography variant="subtitle2" className={classes.titleCaption2}>
          Does everything look right?
          <br />
          You will not be able to edit this information until an administrator
          has reviewed it.
        </Typography>
      </DialogTitle>
      <DialogContent className={classes.content}>
        {userProfile?.firstName && (
          <>
            <div className={classes.label}>First Name</div>
            <Typography paragraph className={classes.paragraph}>
              {userProfile.firstName}
            </Typography>
          </>
        )}

        {userProfile?.lastName && (
          <>
            <div className={classes.label}>Last Name</div>
            <Typography paragraph className={classes.paragraph}>
              {userProfile.lastName}
            </Typography>
          </>
        )}

        {userProfile?.email && (
          <>
            <div className={classes.label}>Email Address</div>
            <Typography paragraph className={classes.paragraph}>
              {userProfile.email}
            </Typography>
          </>
        )}

        {userProfile?.phoneNumber && (
          <>
            <div className={classes.label}>Phone Number</div>
            <Typography paragraph className={classes.paragraph}>
              {userProfile.phoneNumber}
            </Typography>
          </>
        )}

        {userProfile?.licenseNumber && (
          <>
            <div className={classes.label}>Commission License Number</div>
            <Typography paragraph className={classes.paragraph}>
              {userProfile.licenseNumber}
            </Typography>
          </>
        )}

        {userProfile?.role.name && (
          <>
            <div className={classes.label}>Requested User Role</div>
            <Typography paragraph className={classes.paragraph}>
              {userProfile.role.name}
            </Typography>
          </>
        )}

        {userProfile?.trainerName && (
          <>
            <div className={classes.label}>Primary Trainer Name</div>
            <Typography paragraph className={classes.paragraph}>
              {userProfile.trainerName}
            </Typography>
          </>
        )}

        {userProfile?.facility && (
          <>
            <div className={classes.label}>Facility</div>
            <Typography paragraph className={classes.paragraph}>
              {userProfile.facility.name}
            </Typography>
          </>
        )}
      </DialogContent>
      <DialogActions>
        <Button
          autoFocus
          className={classes.cancelButton}
          onClick={handleClose}
        >
          Go Back
        </Button>
        <Button className={classes.submitButton} onClick={handleSubmit}>
          Submit
        </Button>
      </DialogActions>
    </MuiDialog>
  );
};

export default Dialog;
