import { racehorse360 } from "@tsg/1st-grpc-web";
import { useState } from "react";
import { useQueryClient } from "react-query";
import { FormikConfig, useFormik } from "formik";
import pickBy from "lodash/pickBy";
import mapValues from "lodash/mapValues";

import { useRacehorse360Api } from "hooks/api";
import * as queries from "./queries";
import { editUserValidationSchema } from "./editUserValidationSchema";

const neverCallMe = () =>
  // This function only exists to use ReturnType<> on useFormik()
  useFormik(null as FormikConfig<EditUserFormValues>);
const neverCallMe2 = () =>
  // This function only exists to use ReturnType<> on useFormik()
  useFormik(null as FormikConfig<AddUserRoleFormValues>);

type EditUserFormikForm = ReturnType<typeof neverCallMe>;
type AddUserRoleFormikForm = ReturnType<typeof neverCallMe2>;

interface EditUserFormValues {
  firstName: string;
  lastName: string;
  phoneNumber: string;
  email: string;
  licenseNumber: string;
}

interface AddUserRoleFormValues {
  role: racehorse360.IFacility | null;
  facility: racehorse360.IFacility | null;
  trainer: racehorse360.ITrainer | null;
}

const INITIAL_VALUES: EditUserFormValues = {
  firstName: "",
  lastName: "",
  email: "",
  phoneNumber: "",
  licenseNumber: ""
};

const ADD_USER_ROLE_INITIAL_VALUES: AddUserRoleFormValues = {
  role: null,
  facility: null,
  trainer: null
};

interface PageStateHookReturnValue {
  // state
  trainerName: string;
  setTrainerName: (trainerName: string) => void;

  // data
  isLoading: boolean;
  user: racehorse360.IUser;
  userRoles: racehorse360.IUserRole[];
  facilities: racehorse360.IFacility[];
  roles: racehorse360.IRole[];
  trainers: racehorse360.ITrainer[];
  isUserEditing: boolean;

  // forms
  editUserForm: EditUserFormikForm;
  addUserRoleForm: AddUserRoleFormikForm;

  // random utilities
  requireFacility: typeof requireFacility;
  requireTrainer: typeof requireTrainer;

  // mutations
  editUser(changes: racehorse360.IEditUserRequest): Promise<racehorse360.IUser>;
  assignRole(
    newRole: racehorse360.IAssignRoleRequest
  ): Promise<racehorse360.IUser>;
  removeRole(
    newRole: racehorse360.IRemoveRoleRequest
  ): Promise<racehorse360.IUser>;
}

const requireTrainer = (role: racehorse360.IRole) =>
  Boolean(role && role.roleType === racehorse360.RoleType.ROLE_TYPE_TRAINER);

const requireFacility = (role: racehorse360.IRole) =>
  Boolean(role && role.roleType === racehorse360.RoleType.ROLE_TYPE_FACILITY);

export function usePageState(userId: string): PageStateHookReturnValue {
  const [trainerName, setTrainerName] = useState("");
  const {
    useGetUser,
    useEditUser,
    useAssignRole,
    useRemoveRole,
    useListRoles,
    useListUserRoles,
    useListFacilities,
    useListTrainers
  } = useRacehorse360Api();
  const { mutateAsync: editUser, isLoading: isUserEditing } = useEditUser();
  const { mutateAsync: assignRole } = useAssignRole({
    onSuccess: async (data, request) => {
      await queryCache.invalidateQueries("THIS_USER_ROLES");
      await addUserRoleForm.setValues(ADD_USER_ROLE_INITIAL_VALUES, false);
      await addUserRoleForm.setTouched(
        mapValues(ADD_USER_ROLE_INITIAL_VALUES, v => false) as any,
        true
      );
    }
  });
  const { mutateAsync: removeRole } = useRemoveRole({
    onSuccess: async (data, request) => {
      await queryCache.invalidateQueries("THIS_USER_ROLES");
    }
  });
  const queryCache = useQueryClient();

  const { isLoading: usersLoading, data: user } = useGetUser(
    queries.user(userId),
    {}
  );

  const { isLoading: userRolesLoading, data: userRoles } = useListUserRoles(
    "THIS_USER_ROLES",
    queries.userRoles(userId)
  );

  const { isLoading: facilitiesLoading, data: facilities } = useListFacilities(
    queries.facilities,
    {
      placeholderData: { facilities: [] }
    }
  );

  const { data: roles, isLoading: rolesLoading } = useListRoles(
    "ALL_USER_ROLES",
    queries.roles,
    {
      onError: error => console.error(error),
      placeholderData: { roles: [] }
    }
  );

  const { data: trainers } = useListTrainers(
    "TRAINER_SEARCH",
    queries.trainers(trainerName)
  );

  const editUserFormikConfig: FormikConfig<EditUserFormValues> = {
    initialValues: { ...INITIAL_VALUES, ...user },
    enableReinitialize: true,
    onSubmit: data =>
      editUser({
        ...data,
        id: userId
      }),
    validationSchema: editUserValidationSchema
  };

  const addUserRoleFormikConfig: FormikConfig<AddUserRoleFormValues> = {
    initialValues: { ...ADD_USER_ROLE_INITIAL_VALUES },
    enableReinitialize: true,
    onSubmit: data =>
      editUser({
        ...data,
        id: userId
      }),
    validate: values =>
      pickBy(
        {
          role: values.role ? undefined : "Role is required",
          facility:
            !requireFacility(values.role) || !!values.facility
              ? undefined
              : "Facility is required",

          trainer:
            !requireTrainer(values.role) || !!values.trainer
              ? undefined
              : "Trainer is required"
        },
        Boolean
      )
  };

  const editUserForm = useFormik(editUserFormikConfig);
  const addUserRoleForm = useFormik(addUserRoleFormikConfig);

  return {
    isLoading:
      usersLoading || userRolesLoading || facilitiesLoading || rolesLoading,
    user,
    userRoles: userRoles?.userRoles || [],
    facilities: facilities?.facilities || [],
    roles: roles?.roles || [],
    trainers: trainers?.trainers || [],
    editUser,
    isUserEditing,
    assignRole,
    removeRole,
    editUserForm,
    addUserRoleForm,
    requireFacility,
    requireTrainer,
    trainerName,
    setTrainerName
  };
}
