import { makeStyles } from "@material-ui/core/styles";
import { MuiTheme } from "theme";

export default makeStyles((theme: MuiTheme) => ({
  rootBorder: {
    borderLeft: `1px solid ${theme.palette.text.hint}`
  },
  pageContent: {
    display: "initial",
    height: "calc(100vh - 124px)",
    overflowY: "auto",
    [theme.breakpoints.down("sm")]: {
      height: "calc(100vh - 164px)"
    },
    [theme.breakpoints.down("xs")]: {
      height: "calc(100vh - 160px)"
    }
  },
  appPageContentContainer: {
    display: "flex",
    paddingTop: 20,
    paddingBottom: 20,
    minHeight: "100%",
    flex: "auto",
    background: "#f6fbfa",
    "&$isMapFullscreen": {
      display: "flex",
      padding: 0
    },
    [theme.breakpoints.down("sm")]: {
      paddingTop: 41
    }
  },
  mapHeader: {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    height: 60,
    boxShadow: "5px 1px 7px 0px #00000026",
    zIndex: 1,
    paddingLeft: 24,
    paddingRight: 8,
    [theme.breakpoints.down("sm")]: {
      paddingLeft: 42
    },
    [theme.breakpoints.down("xs")]: {
      paddingLeft: 8
    }
  },
  mapHeaderTitle: {
    [theme.breakpoints.down("sm")]: {
      display: "none"
    }
  },
  mapFacilitySelectWrapper: {
    flex: "auto",
    display: "flex",
    justifyContent: "center",
    [theme.breakpoints.down("xs")]: {
      justifyContent: "left",
      maxWidth: "calc(100% - 30px)"
    }
  },
  progress: {
    textAlign: "center"
  },
  blockTitle: {
    color: "#595a5a",
    fontSize: 16,
    lineHeight: 1
  },
  appPageHeaderTabs: {
    marginBottom: 0,
    position: "static"
  },
  daysOff: {
    [theme.breakpoints.up("sm")]: {
      height: 121,
      maxHeight: 121
    },
    "&$__title": {
      [theme.breakpoints.up("sm")]: {
        color: theme.palette.text.hint,
        lineHeight: "normal",
        textAlign: "center"
      }
    },
    "&$__info": {
      [theme.breakpoints.up("sm")]: {
        marginBottom: 4
      }
    },
    "&$__warning": {}
  },
  nextScheduledWorkoutWrapper: {
    maxWidth: "auto"
  },
  daysOffWrapper: {
    maxWidth: 200
  },
  appHeaderMapFullscreen: {
    border: "none",
    boxSizing: "border-box",
    borderBottom: `1px solid ${theme.palette.divider}`
  },
  noPaddings: {
    padding: 0
  },
  italicCell: {
    fontStyle: "italic"
  }
}));
