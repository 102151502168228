import isBefore from "date-fns/isBefore";
import addDays from "date-fns/addDays";
import endOfDay from "date-fns/endOfDay";
import startOfToday from "date-fns/startOfToday";

import { racehorse360 } from "@tsg/1st-grpc-web";

import { FIELD_TYPE, IField } from "components/FormField/constants";

export interface IState {
  title?: string;
  facilityOptions?: string[];
  startDate?: Date;
  deadlineDate?: Date;
  closeDate?: Date;
  pdf: File;
}

export interface IModule {
  name: string;
  title: string;
  fields: IField[];
}

export const getModules = (
  facilities: racehorse360.IFacility[],
  values: IState
): IModule[] => {
  return [
    {
      name: "Intake Form Details",
      title: "intakeFormDetails",
      fields: [
        {
          name: "title",
          label: "Form Title",
          type: FIELD_TYPE.TEXT,
          maxLength: 50,
          placeholder: "e.g. 22/23 Gulfstream Park Winter Meet"
        },
        {
          name: "facilityOptions",
          label: "Select Track and/or Training Facilities",
          type: FIELD_TYPE.MULTISELECT,
          placeholder: "Select One",
          options: facilities.reduce((acc, cur) => {
            return [
              ...acc,
              {
                id: cur.id,
                name: cur.name,
                type: cur.type,
                options: cur.trainingFacilities.map(tf => ({
                  id: tf.id,
                  name: tf.name,
                  type: tf.type
                }))
              }
            ];
          }, [])
        },
        {
          name: "startDate",
          label: "Start Date",
          type: FIELD_TYPE.DATEPICKER,
          min: startOfToday()
        },
        {
          name: "deadlineDate",
          label: "Deadline Date",
          type: FIELD_TYPE.DATEPICKER,
          min: addDays(values.startDate || startOfToday(), 1),
          disabled: !values.startDate
        },
        {
          name: "closeDate",
          label: "Close Date",
          type: FIELD_TYPE.DATEPICKER,
          min: values.deadlineDate || startOfToday(),
          disabled:
            !values.deadlineDate ||
            isBefore(endOfDay(values.deadlineDate), values.startDate)
        },
        {
          name: "pdf",
          label: "Terms & Conditions .PDF Upload (Max file size 100kB)",
          type: FIELD_TYPE.UPLOAD,
          placeholder: "Choose file"
        }
      ]
    }
  ];
};

export const initialState: IState = {
  title: "",
  facilityOptions: [],
  startDate: null,
  deadlineDate: null,
  closeDate: null,
  pdf: null
};
