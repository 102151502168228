import { useMemo } from "react";
import { useLocation } from "react-router";
import { useQuery } from "react-query";
import { useApiClient } from "./ApiClientContext";
import { makeHooksForApiClient } from "./auto-api-hook-wrapper";
import {
  GetDailyForecastWeatherRequest,
  GetDailyForecastWeatherResponse
  //GetOneCallWeatherRequest,
  //GetOneCallWeatherResponse
} from "interfaces/Weather";

const DAILY_FORECAST_URL = `https://${
  process.env.API_BASE_HOSTNAME || "dev.1stbet.com"
}/v1/weather/forecast/daily`;
/*const ONECALL_WEATHER_URL = `https://${
  process.env.API_BASE_HOSTNAME || "dev.1stbet.com"
}/v1/weather/onecall`*/

export const useRacehorse360Api = () => {
  const {
    applicationSettingClient,
    facilityBlackoutClient,
    facilityClient,
    horseClient,
    notificationClient,
    raceConditionClient,
    raceClient,
    regionClient,
    rolePermissionClient,
    trainerClient,
    userClient,
    vaccinationClient,
    stallApplicationClient,
    workOrderClient,
    workoutExamClient,
    workoutRequestClient,
    workoutClient,
    getAccessTokenSilently
  } = useApiClient();
  const location = useLocation();

  return useMemo(() => {
    /*const getOneCallWeather = async (
      key,
      request: GetOneCallWeatherRequest
    ): Promise<GetOneCallWeatherResponse> => {
      if (request.lat && request.lon) {
        const query = new URLSearchParams({
          lat: request.lat.toString(),
          lon: request.lon.toString(),
          units: request.units || "imperial",
          exclude: request.exclude || "current,minutely,hourly,alerts"
        })
        const token = await getAccessTokenSilently()
        return fetch(`${ONECALL_WEATHER_URL}?${query}`, {
          headers: {
            Authorization: `Bearer ${token}`
          }
        }).then(res => res.json())
      }
    }*/
    const getDailyForecast = async ({
      queryKey
    }: {
      queryKey: [string, GetDailyForecastWeatherRequest];
    }): Promise<GetDailyForecastWeatherResponse> => {
      const request = queryKey[1];
      if (request.lat && request.lon) {
        const query = new URLSearchParams({
          lat: request.lat.toString(),
          lon: request.lon.toString(),
          units: request.units || "imperial",
          ...(request.cnt ? { cnt: request.cnt.toString() } : {})
        });
        const token = await getAccessTokenSilently();
        return fetch(`${DAILY_FORECAST_URL}?${query}`, {
          headers: {
            Authorization: `Bearer ${token}`
          }
        }).then(res => res.json());
      }

      // Only used if we don't have a paid API token and need to retrieve forecast from "OneCall"
      // if (request.lon && request.lat) {
      //   const result = await getOneCallWeather(key, request)
      //   const { daily, lat, lon, timezone, timezone_offset } = result
      //   const response = {
      //     lat,
      //     lon,
      //     timezone,
      //     timezone_offset,
      //     list: daily
      //   }
      //   return response
      // }
    };

    return {
      ...makeHooksForApiClient(applicationSettingClient),
      ...makeHooksForApiClient(facilityBlackoutClient),
      ...makeHooksForApiClient(facilityClient),
      ...makeHooksForApiClient(horseClient),
      ...makeHooksForApiClient(notificationClient),
      ...makeHooksForApiClient(raceConditionClient),
      ...makeHooksForApiClient(raceClient),
      ...makeHooksForApiClient(regionClient),
      ...makeHooksForApiClient(rolePermissionClient),
      ...makeHooksForApiClient(trainerClient),
      ...makeHooksForApiClient(userClient),
      ...makeHooksForApiClient(vaccinationClient),
      ...makeHooksForApiClient(stallApplicationClient),
      ...makeHooksForApiClient(workOrderClient),
      ...makeHooksForApiClient(workoutExamClient),
      ...makeHooksForApiClient(workoutRequestClient),
      ...makeHooksForApiClient(workoutClient),
      useDailyForecast: (
        request: GetDailyForecastWeatherRequest,
        {
          enabled = true,
          onSuccess = (response: GetDailyForecastWeatherResponse) => {
            return;
          },
          onError = () => {
            return;
          }
        }
      ) =>
        useQuery(["dailyForecast", request], getDailyForecast, {
          enabled,
          onSuccess,
          onError
        })
      // no need for this but I put the code in if we wanted it
      // useOneCallWeather: (
      //   request: GetOneCallWeatherRequest,
      //   onSuccess = () => {}
      // ) =>
      //   useQuery(["oneCallWeather", request], getOneCallWeather, { onSuccess })
    };
  }, [
    applicationSettingClient,
    facilityBlackoutClient,
    facilityClient,
    horseClient,
    notificationClient,
    raceConditionClient,
    raceClient,
    regionClient,
    rolePermissionClient,
    trainerClient,
    userClient,
    vaccinationClient,
    stallApplicationClient,
    workOrderClient,
    workoutExamClient,
    workoutRequestClient,
    workoutClient,
    location.pathname
  ]);
};
