import React, { ChangeEvent, useState } from "react";
import clsx from "clsx";

import Accordion from "@material-ui/core/Accordion";
import Checkbox from "@material-ui/core/Checkbox";

import { IFilterDataItem } from "components/Filters/filtersOptions";
import FilterAccordionDetails from "../FilterAccordionDetails";
import FilterAccordionSummary from "../FilterAccordionSummary";
import useStyles from "./styles";

interface IProps {
  selectedValues: string[];
  onChangeSelectedValues: (value: string[]) => void;
  data: IFilterDataItem[];
  title: string;
  maxWidthPreview?: number;
  maxMobileWidthPreview?: number;
  withLimit?: boolean;
}

const CheckboxesFilter = React.memo((props: IProps) => {
  const {
    selectedValues = null,
    onChangeSelectedValues,
    data = [],
    title,
    withLimit,
    maxWidthPreview,
    maxMobileWidthPreview
  } = props;
  const classes = useStyles();
  const allValuesItemValue = data[0].value;

  const [isExpanded, setIsExpanded] = useState<boolean>(false);

  const handleChangeValue = (event: ChangeEvent<HTMLInputElement>) => {
    const currentValue = event.currentTarget.value;
    const isCheckedCurrentValue = event.currentTarget.checked;
    let newValues: string[];

    if (currentValue === allValuesItemValue) {
      newValues = isCheckedCurrentValue ? null : [];
    } else {
      if (selectedValues) {
        if (selectedValues.includes(currentValue)) {
          newValues = selectedValues.filter((item: string) => {
            return item !== currentValue && item !== allValuesItemValue;
          });
        } else {
          const areAllValuesSelected = data
            .filter((item: IFilterDataItem) => {
              return (
                item.value !== allValuesItemValue && item.value !== currentValue
              );
            })
            .every((item: IFilterDataItem) =>
              selectedValues.includes(item.value)
            );

          newValues = areAllValuesSelected
            ? null
            : [...selectedValues, currentValue];
        }
      } else {
        newValues = data
          .map((item: IFilterDataItem) => item.value)
          .filter((itemValue: string) => {
            return (
              itemValue !== currentValue && itemValue !== allValuesItemValue
            );
          });
      }
    }

    onChangeSelectedValues(newValues);
  };

  const handleChangeAccordionState = (
    event: ChangeEvent,
    expanded: boolean
  ) => {
    setIsExpanded(expanded);
  };

  const renderPreviewItems = () => {
    if (!selectedValues) {
      return [];
    }

    return data
      .filter((item: IFilterDataItem) => selectedValues.includes(item.value))
      .map((item: IFilterDataItem) => item.label);
  };

  const renderDetailsContent = () => {
    return data.map((item: IFilterDataItem, index: number) => {
      const isChecked = selectedValues
        ? selectedValues.includes(item.value)
        : true;

      return (
        <label
          key={`${item.label}-${item.value}`}
          className={clsx(classes.item, {
            ["active"]: isChecked
          })}
        >
          <span className={classes.label}>{item.label}</span>
          <Checkbox
            className={clsx(classes.checkbox, {
              ["allValues"]: !index
            })}
            checked={isChecked}
            value={item.value}
            name={item.label}
            onChange={handleChangeValue}
          />
        </label>
      );
    });
  };

  return (
    <Accordion onChange={handleChangeAccordionState}>
      <FilterAccordionSummary
        title={title}
        previewItems={renderPreviewItems()}
        withLimit={withLimit}
        maxWidthPreview={maxWidthPreview}
        maxMobileWidthPreview={maxMobileWidthPreview}
        isExpanded={isExpanded}
      />
      <FilterAccordionDetails>{renderDetailsContent()}</FilterAccordionDetails>
    </Accordion>
  );
});

export default CheckboxesFilter;
