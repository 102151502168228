import { makeStyles, Theme } from "@material-ui/core/styles";
import Breakpoints from "common/breakpoints";

export default makeStyles((theme: Theme) => {
  const { primary } = theme.palette;

  return {
    header: {
      display: "flex",
      alignItems: "center",
      height: 30,
      color: primary.dark,
      fontSize: 12,
      textTransform: "capitalize",
      [theme.breakpoints.down(Breakpoints.SM_600)]: {
        justifyContent: "space-between"
      }
    },
    title: {
      position: "relative"
    },
    loader: {
      display: "inline-flex",
      position: "absolute",
      right: "-25px",
      width: 20,
      padding: 0
    },
    valuesHint: {
      fontSize: 12,
      color: primary.light,
      display: "none",
      [theme.breakpoints.down(Breakpoints.SM_600)]: {
        display: "block"
      }
    }
  };
});
