import { makeStyles } from "@material-ui/core/styles";
import { MuiTheme } from "theme";

export default makeStyles((theme: MuiTheme) => {
  const { primary, secondary } = theme.palette;

  return {
    dialogPaper: {
      width: 296,
      minHeight: 359
    },
    dialogTitle: {
      padding: "11px 20px 11px 16px",
      "& .MuiTypography-h6": {
        fontWeight: 700
      }
    },
    dialogContent: {
      display: "flex",
      flexDirection: "column",
      padding: "0 16px 16px 16px"
    },
    dialogActions: {
      display: "flex",
      gap: 4,
      justifyContent: "end",
      padding: "12px 16px",
      "& > div": {
        flex: 1,
        display: "flex",
        gap: 4,
        justifyContent: "end"
      }
    },
    dialogCloseButton: {
      position: "absolute",
      top: 8,
      right: 8
    },
    dialogCloseButtonIcon: {
      fontSize: 28
    },
    horseName: {
      padding: 0,
      fontFamily: "SuisseIntlMedium, SuisseIntlRegular",
      marginBottom: 8,
      wordBreak: "break-word"
    },
    trainerName: {
      padding: 0,
      marginBottom: 24,
      wordBreak: "break-word"
    },
    textarea: {
      wordBreak: "break-word",
      "& label.MuiFormLabel-root": {
        color: secondary.dark
      },
      "& label.MuiFormLabel-root.Mui-focused": {
        color: secondary.dark
      },
      "&:hover label.MuiFormLabel-root": {
        color: secondary.dark
      },
      "& .MuiOutlinedInput-root fieldset": {
        borderColor: secondary.dark
      }
    },
    buttonCancel: {
      color: primary.dark,
      fontSize: 16
    },
    buttonSave: {
      color: secondary.dark,
      fontSize: 16
    },
    menuButton: {
      padding: 9,
      margin: "6px 8px",
      border: `1px solid ${primary.light}`,
      boxShadow: "0 0 4px 0 rgba(0, 0, 0, 0.15)",
      borderRadius: "4px",
      backgroundColor: "#fff",
      "&:hover": {
        backgroundColor: "#fff"
      }
    },
    rejectedIcon: {
      fontSize: 30
    },
    rejectedButton: {
      width: 35,
      height: 35,
      marginRight: 10
    },
    rejectedButton__active: {
      borderColor: "#8a1d2a",
      color: "#8a1d2a",
      backgroundColor: "rgba(255, 75, 79, 0.35)"
    },
    diagnosticsLabel: {
      marginTop: 10,
      "&>span": {
        fontSize: 15
      }
    }
  };
});
