import React from "react";
import parseISO from "date-fns/parseISO";

import { racehorse360 } from "@tsg/1st-grpc-web";
import Loader from "components/Loader";
import { useRacehorse360Api } from "hooks/api";
import useStyles from "./styles";

export interface Props {
  isToday?: boolean;
  date: string;
  className?: string;
  onUpdate?: (
    id?: string,
    workoutRequest?: racehorse360.IWorkoutRequest
  ) => void;
  onUpdateError?: () => void;
  selectedFacilityId: string;
  horseId: string;
}

const AvailableDate = React.memo((props: Props) => {
  const { selectedFacilityId, horseId, onUpdate, onUpdateError, date } = props;

  const classes = useStyles();

  const { useCreateWorkoutRequest } = useRacehorse360Api();

  const {
    isLoading: isCreateWorkoutLoading,
    mutateAsync: createWorkoutRequest
  } = useCreateWorkoutRequest();

  const isLoading = isCreateWorkoutLoading;

  const handleClick = () => {
    if (!isLoading) {
      createWorkoutRequest({
        date,
        facilityId: selectedFacilityId,
        horseId: horseId,
        getOptions: {
          select: [
            "id",
            "facility.name",
            "facility.code",
            "date",
            "status",
            "hasComment"
          ]
        }
      })
        .then(response => {
          onUpdate(response.id, response);
        })
        .catch(error => {
          console.error(error);
          onUpdateError && onUpdateError();
        });
    }
  };

  return (
    <div
      className={classes.root}
      onClick={handleClick}
      data-test-element="CALENDAR_DAY"
      data-test-state={"AVAILABLE"}
    >
      {isLoading && <Loader className={classes.loader} />}
      <p className={classes.date}>{parseISO(date).getDate()}</p>
    </div>
  );
});

export default AvailableDate;
