import React from "react";

import Textarea from "components/Textarea";
import { Props } from "components/Textarea/Textarea";
import Loader from "components/Loader";
import useStyles from "./styles";

interface IProps extends Partial<Props> {
  title: string;
  extraHeaderContent?: React.ReactNode;
  className?: string;
  textareaClassName?: string;
  textareaFontSize?: number;
  showInnerLoader?: boolean;
}

const ExamComment = React.memo((props: IProps) => {
  const {
    textareaClassName,
    extraHeaderContent,
    textareaFontSize,
    className,
    title,
    showInnerLoader = false,
    onChange,
    defaultValue,
    ...restProps
  } = props;
  const classes = useStyles({ textareaFontSize });

  const handleChange = newValue => {
    onChange && onChange(newValue);
  };

  return (
    <div className={className}>
      <div className={classes.header}>
        <span className={classes.headerContent}>
          <span>{title}&nbsp;</span>
          <span className={classes.optional}>(optional)</span>
          {showInnerLoader && <Loader size={15} className={classes.loader} />}
        </span>
        {extraHeaderContent}
      </div>
      <Textarea
        {...restProps}
        className={textareaClassName}
        maxLength={500}
        rows={3}
        maxRows={3}
        onChange={handleChange}
        showCounter={false}
        placeholder="Add Note"
        defaultValue={defaultValue}
      />
    </div>
  );
});

export default ExamComment;
