import React, { useContext, useEffect, useRef } from "react";
import { useSelector } from "react-redux";
import clsx from "clsx";
import AutoSizer from "react-virtualized-auto-sizer";

import { racehorse360 } from "@tsg/1st-grpc-web";
import Loader from "components/Loader";
import { useLoggedInUser } from "components/LoggedInUserProvider";
import ScrollSyncContext from "components/ScrollSync/ScrollSyncContext";
import VirtualList from "components/VirtualList";
import { useRacehorse360Api } from "hooks/api";
import { useInfiniteScroll } from "hooks/useInfiniteScroll";
import WorkoutsPageState from "interfaces/WorkoutsPageState";
import ICalendarDay from "interfaces/ICalendarDay";
import { SortOrder } from "interfaces/SortOrder";
import CondensedViewCalendar from "./CondensedViewCalendar";
import {
  ITEM_HEIGHT,
  ITEM_MARGIN_BOTTOM
} from "./CondensedViewCalendar/styles";
import useStyles from "./styles";

export interface Props {
  className?: string;
  facilityBlackoutsCalendar?: ICalendarDay[];
}

const PAGE_SIZE: number = 10;
const ROW_HEIGHT: number = ITEM_HEIGHT + ITEM_MARGIN_BOTTOM;
const ROW_OFFSET_LEFT: number = 26;

const CondensedView = (props: Props) => {
  const { className, facilityBlackoutsCalendar } = props;
  const { currentUser } = useLoggedInUser();
  const rootRef = useRef(null);
  const classes = useStyles();
  const listRef = useRef<HTMLDivElement>(null);
  const scrollSyncState = useContext(ScrollSyncContext);
  const trainerId = currentUser.trainerIds[0];

  const {
    selectedFacility,
    isCondensedView,
    scheduledDatesOnly,
    sortOrder,
    searchValue
  } = useSelector(
    (state: { workoutsPage: WorkoutsPageState }) => state?.workoutsPage
  );

  const { useInfiniteListHorses, useListHorseOnLists } = useRacehorse360Api();

  const query = currentUser.isTrainer
    ? {
        name: searchValue?.length ? { contains: searchValue } : undefined,
        ...currentUser.trainerHorseAndWorkoutFilter
      }
    : {
        horseOrTrainerName: searchValue?.length
          ? { contains: searchValue }
          : undefined,
        ...currentUser.trainerHorseAndWorkoutFilter
      };

  const { data, isFetching, fetchNextPage, hasNextPage, isFetchingNextPage } =
    useInfiniteListHorses(
      {
        query,
        getOptions: {
          select: ["name"],
          orderBy: [sortOrder && isCondensedView ? `name ${sortOrder}` : "name"]
        },
        pagingOptions: {
          maxResults: PAGE_SIZE
        }
      },
      {
        keepPreviousData: true
      }
    );

  const horses = data?.pages?.flat() || [];

  const { data: horseOnListsData } = useListHorseOnLists(
    `${trainerId}-horse-on-lists`,
    {
      query: {
        activeOnly: { value: true }
      },
      pagingOptions: {
        maxResults: 9999,
        offset: 0
      },
      getOptions: {
        select: ["horse.id", "horse.name", "list_type", "date_on"],
        orderBy: [`dateOn ${SortOrder.ASC}`]
      }
    },
    {
      select: data => {
        return data.horseOnLists.reduce((acc, cur) => {
          acc[cur.horse.id] = acc[cur.horse.id]
            ? [...acc[cur.horse.id], cur]
            : [cur];
          return acc;
        }, {});
      },
      initialData: racehorse360.ListHorseOnListsResponse.create()
    }
  );

  useInfiniteScroll(
    listRef,
    fetchNextPage,
    hasNextPage,
    isFetchingNextPage,
    true,
    true
  );

  useEffect(() => {
    const containerHeight = rootRef.current?.getBoundingClientRect().height;
    if (hasNextPage && horses.length * ROW_HEIGHT < containerHeight) {
      fetchNextPage();
    }
  }, [horses.length, hasNextPage]);

  useEffect(() => {
    if (listRef.current) {
      scrollSyncState.addPanel(listRef);
      return () => {
        scrollSyncState.removePanel(listRef);
      };
    }
  }, [listRef.current]);

  return (
    <div ref={rootRef} className={clsx(classes.root, className)}>
      {isFetching && !isFetchingNextPage && <Loader overlay />}
      {isFetchingNextPage && <Loader className={classes.nextPageLoader} />}
      <AutoSizer>
        {({ height, width }) => (
          <VirtualList
            className={clsx(classes.listVirtualized, {
              [classes.scheduledOnly]: scheduledDatesOnly
            })}
            count={horses.length}
            itemHeight={ROW_HEIGHT}
            height={height}
            width={width - ROW_OFFSET_LEFT}
            scrollContainerRef={listRef}
            offsetTop={24}
            offsetLeft={ROW_OFFSET_LEFT}
            itemsBeyond={PAGE_SIZE}
          >
            {horses.map(horse => (
              <CondensedViewCalendar
                key={horse.id}
                horse={horse}
                selectedFacility={selectedFacility}
                facilityBlackoutsCalendar={facilityBlackoutsCalendar}
                horseOnLists={horseOnListsData[horse.id]}
              />
            ))}
          </VirtualList>
        )}
      </AutoSizer>
    </div>
  );
};

export default CondensedView;
