import "./LogRocket-setup";
import React from "react";
import ReactDOM from "react-dom";
import { ConnectedRouter } from "connected-react-router";
import { Provider } from "react-redux";
import { QueryClient, QueryClientProvider } from "react-query";
import { Auth0Provider } from "@auth0/auth0-react";
import { ThemeProvider } from "@material-ui/core/styles";
import history from "common/history";
import App from "components/App";
import store from "./store";
import * as serviceWorker from "./serviceWorker";
import { cssVariables, theme, mediaDevices } from "./theme";
import { BlockableClickContextProvider } from "./components/BlockableClickContext";
import { ApiClientProvider } from "./hooks/api";
import Loader from "components/Loader";
import { persistStore } from "redux-persist";
import { PersistGate } from "redux-persist/integration/react";
import { LoggedInUserProvider } from "components/LoggedInUserProvider";
import { PermissionsProvider } from "components/PermissionsProvider";

const onRedirectCallback = appState => {
  history.push(
    appState && appState.returnTo ? appState.returnTo : window.location.pathname
  );
};

const persistor = persistStore(store);
const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      refetchOnWindowFocus: false
    }
  }
});

ReactDOM.render(
  <Provider store={store}>
    <ThemeProvider theme={{ ...theme, ...cssVariables, mediaDevices }}>
      <Auth0Provider
        domain={process.env.RH360_AUTH0_DOMAIN}
        clientId={process.env.RH360_AUTH0_CLIENT_ID}
        redirectUri={window.location.origin}
        onRedirectCallback={onRedirectCallback}
        cacheLocation="localstorage"
        audience={process.env.RH360_AUTH0_AUDIENCE}
        useRefreshTokens={true}
      >
        <LoggedInUserProvider>
          <QueryClientProvider client={queryClient}>
            <ApiClientProvider>
              <BlockableClickContextProvider>
                <PersistGate loading={<Loader />} persistor={persistor}>
                  <ConnectedRouter history={history as any}>
                    <PermissionsProvider>
                      <App />
                    </PermissionsProvider>
                  </ConnectedRouter>
                </PersistGate>
              </BlockableClickContextProvider>
            </ApiClientProvider>
          </QueryClientProvider>
        </LoggedInUserProvider>
      </Auth0Provider>
    </ThemeProvider>
  </Provider>,
  document.querySelector("#root")
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
void serviceWorker.unregister();
