import React from "react";
import { useDispatch, useSelector } from "react-redux";
import clsx from "clsx";

import Typography from "@material-ui/core/Typography";
import Paper from "@material-ui/core/Paper";

import { racehorse360 } from "@tsg/1st-grpc-web";

import TrainerDashboardPageForecastState from "interfaces/TrainerDashboardPageForecastState";
import { setSelectedFacility as setStoreSelectedFacility } from "store/actions/trainerDashboardPageForecast";
import ErrorBoundary from "components/ErrorBoundary";
import FacilitySelect from "components/FacilitySelect";
import Weather from "components/Weather/Weather";

import useStyles from "./styles";

export interface Props {
  className?: string;
}

const Forecast = (props: Props) => {
  const { className } = props;

  const classes = useStyles();

  const dispatch = useDispatch();
  const { selectedFacility } = useSelector(
    (state: {
      trainerDashboardPageForecast: TrainerDashboardPageForecastState;
    }) => state?.trainerDashboardPageForecast || { selectedFacility: null }
  );

  const handleFacilityChange = (facility: racehorse360.IFacility) => {
    dispatch(setStoreSelectedFacility(facility));
  };

  return (
    <Paper className={clsx(classes.root, className)}>
      <div className={classes.header}>
        <Typography className={classes.title}>7-Day Forecast</Typography>
        <ErrorBoundary>
          <FacilitySelect
            className={classes.facilitySelect}
            isActive={true}
            selectedFacility={selectedFacility}
            onChange={handleFacilityChange}
          />
        </ErrorBoundary>
      </div>
      <ErrorBoundary>
        <Weather
          className={classes.weather}
          selectedFacility={selectedFacility}
          dateFormat={"iii M/d"}
        />
      </ErrorBoundary>
    </Paper>
  );
};

export default Forecast;
