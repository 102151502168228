import React, { useState, memo } from "react";

import RemoveIcon from "@material-ui/icons/DeleteForever";
import IconButton from "@material-ui/core/IconButton";

import RiskLevelSelector from "components/RiskLevelSelector";
import ExamFormBlock from "../ExamFormBlock";
import ExamButtonsGroup from "../ExamButtonsGroup";
import ExamComment from "../ExamComment";
import AddEntryButton from "../AddEntryButton";
import { changeExamField, EVetExamFields } from "../options";
import { examResultsButtons } from "../options";
import { useVetExamContext } from "../VetExamStateProvider/VetExamProvider";
import { useExamLockerContext } from "../../ExamLockerContext/ExamLockerContext";
import useStyles from "./styles";

const ADD_TRAINER_COMMENT = "Add a Comment to the Trainer";

const ExamResults = () => {
  const classes = useStyles();
  const { state, dispatch } = useVetExamContext();
  const { lockExam } = useExamLockerContext();

  const { result, riskLevel, internalNote, commentToTrainer } = state;

  const [openTrainerComment, setOpenTrainerComment] = useState<boolean>(
    Boolean(commentToTrainer)
  );

  const handleSelectResult = (value: string) => {
    dispatch(changeExamField(value, EVetExamFields.RESULT));
    lockExam();
  };

  const handleSelectRiskLevel = (value: string) => {
    dispatch(changeExamField(value, EVetExamFields.RISK_LEVEL));
    lockExam();
  };

  const handleBlurNote = event => {
    dispatch(
      changeExamField(event.currentTarget.value, EVetExamFields.INTERNAL_NOTE)
    );
    lockExam();
  };

  const handleBlurComment = event => {
    dispatch(
      changeExamField(event.currentTarget.value, EVetExamFields.COMMENT)
    );
    lockExam();
  };

  const handleAddTrainerComment = () => {
    setOpenTrainerComment(true);
  };

  const handleRemoveTrainerComment = async () => {
    setOpenTrainerComment(false);
    dispatch(changeExamField("", EVetExamFields.COMMENT));
    lockExam();
  };

  const renderInternalNoteExtraHeaderContent = () => {
    return (
      <span className={classes.internalNote}>&#42;Not visible to trainers</span>
    );
  };

  const renderRemoveTrainerComment = () => {
    return (
      <IconButton
        className={classes.removeButton}
        onClick={handleRemoveTrainerComment}
      >
        <RemoveIcon />
      </IconButton>
    );
  };

  const renderCommentForTrainer = () => {
    if (openTrainerComment || commentToTrainer) {
      return (
        <ExamComment
          title={ADD_TRAINER_COMMENT}
          extraHeaderContent={renderRemoveTrainerComment()}
          textareaClassName={classes.textareaField}
          onBlur={handleBlurComment}
          defaultValue={commentToTrainer || ""}
          textareaFontSize={13}
          data-test={"exam-internal-note"}
          autoFocus={!commentToTrainer}
        />
      );
    }

    return (
      <AddEntryButton
        className={classes.addTrainerComment}
        title={ADD_TRAINER_COMMENT}
        onClick={handleAddTrainerComment}
        isOptional={true}
      />
    );
  };

  return (
    <ExamFormBlock
      className={classes.rootResults}
      title="Exam Results"
      isDarkBackground={true}
    >
      <ExamButtonsGroup
        className={classes.buttonsGroup}
        buttonClassName={classes.buttonsGroupItem}
        title="Result"
        buttons={examResultsButtons}
        selectedValue={result}
        onClickButton={handleSelectResult}
      />
      <RiskLevelSelector
        riskLevel={riskLevel}
        className={classes.buttonsGroup}
        onChange={handleSelectRiskLevel}
      />
      <ExamComment
        title="Internal note"
        extraHeaderContent={renderInternalNoteExtraHeaderContent()}
        textareaClassName={classes.textareaField}
        onBlur={handleBlurNote}
        defaultValue={internalNote}
      />
      {renderCommentForTrainer()}
    </ExamFormBlock>
  );
};

export default memo(ExamResults);
