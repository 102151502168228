import React, { useEffect, useState } from "react";
import clsx from "clsx";
import useStyles from "./styles";
import ChevronLeftIcon from "@material-ui/icons/ChevronLeft";
import ChevronRightIcon from "@material-ui/icons/ChevronRight";
import Button from "@material-ui/core/Button";
import ButtonGroup from "@material-ui/core/ButtonGroup";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import usePagination from "@material-ui/lab/Pagination/usePagination";

export interface Props {
  totalCount: number;
  pageSize: number;
  pageNumber?: number;
  legendChildren?: React.ReactNode;
  onPageChange: (page: number) => void;
  className?: string;
  showLegend?: boolean;
}

const Pagination = React.memo((props: Props) => {
  const {
    className,
    totalCount,
    legendChildren,
    onPageChange,
    pageNumber = 1,
    pageSize = 1,
    showLegend = true
  } = props;
  const classes = useStyles();
  const [activePage, setActivePage] = useState<number>(pageNumber);
  const pagination = usePagination({
    count: Math.ceil(totalCount / pageSize) || 1,
    page: pageNumber
  });

  const handlePageChange = paginationItem => () => {
    paginationItem.onClick && paginationItem.onClick();
    if (paginationItem.page) {
      setActivePage(paginationItem.page);
      onPageChange(paginationItem.page);
    }
  };

  const renderLegend = () => {
    const increment = totalCount ? 1 : 0;
    const start = (activePage - 1) * pageSize + increment;
    const end = (activePage - 1) * pageSize + pageSize;
    const endTotalCount = end < totalCount ? end : totalCount;

    return (
      <>
        Showing {start} {pageSize === 1 ? null : ` - ${endTotalCount}`} of{" "}
        {totalCount}
      </>
    );
  };

  const renderPagination = () => {
    const renderPageButtonContent = item => {
      if (item.type === "previous")
        return (
          <>
            <ChevronLeftIcon className={classes.paginationChevron} />
            &nbsp;Prev
          </>
        );
      if (item.type === "next")
        return (
          <>
            Next&nbsp;&nbsp;
            <ChevronRightIcon className={classes.paginationChevron} />
          </>
        );
      if (item.page === null) return "...";
      return item.page;
    };
    return pagination.items.map(item => (
      <Button
        size={"small"}
        style={{ fontWeight: item.selected ? "bold" : undefined }}
        className={clsx(classes.page, {
          [classes[item.type]]:
            item.type === "previous" || item.type === "next",
          [classes.pageSelected]: item.selected
        })}
        key={item.type + item.page}
        disabled={totalCount <= pageSize || item.disabled || item.page === null}
        onClick={handlePageChange(item)}
      >
        {renderPageButtonContent(item)}
      </Button>
    ));
  };

  useEffect(() => {
    setActivePage(pageNumber);
  }, [pageNumber]);

  return (
    <Grid
      container
      className={clsx(classes.pagination, className)}
      alignItems="center"
      justifyContent="space-between"
    >
      {showLegend && (
        <Grid item>
          <Typography className={classes.paginationLegend}>
            {renderLegend()}
          </Typography>
          {legendChildren && legendChildren}
        </Grid>
      )}
      <Grid item>
        <ButtonGroup color="primary" aria-label="outlined primary button group">
          {renderPagination()}
        </ButtonGroup>
      </Grid>
    </Grid>
  );
});

export default Pagination;
