import { MuiTheme } from "theme";
import makeStyles from "@material-ui/core/styles/makeStyles";

export default makeStyles((theme: MuiTheme) => ({
  tableContainer: {
    position: "relative",
    borderBottom: "1px solid #eeeeee",
    flexGrow: 1,
    [theme.breakpoints.up("md")]: {
      flex: "auto"
    }
  },
  slideLoader: {
    position: "absolute",
    top: 0,
    bottom: 0,
    left: 0,
    right: 0
  },
  emptyDataHolder: {
    flexGrow: 1,
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    color: "#c3c3c3",
    borderBottom: "1px solid #eeeeee",
    fontSize: 14
  },
  classicView: {
    fontSize: 12,
    marginTop: 8,
    alignItems: "center",
    "& .MuiSvgIcon-root": {
      fontSize: 14,
      width: 14,
      height: 14,
      lineHeight: 1,
      marginLeft: 4
    }
  },
  accordion: {
    "& .MuiCollapse-container": {
      padding: 8,
      margin: -8
    },
    paddingBottom: 10
  },
  loader: {
    flex: "auto"
  }
}));
