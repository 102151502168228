import React from "react";
import clsx from "clsx";

import useStyles from "./styles";

interface IProps {
  matchedHorsesCount: number;
  customStyles?: {
    [key: string]: string | number;
  };
  isSelected?: boolean;
  className?: string;
}

const MatchesCount = React.memo((props: IProps) => {
  const { matchedHorsesCount, isSelected, customStyles, className } = props;
  const classes = useStyles();

  return (
    <span
      className={clsx(classes.matchesCount, className, {
        ["disabled"]: !matchedHorsesCount,
        ["selected"]: isSelected
      })}
      style={customStyles}
      data-test={"number-indicator"}
    >
      {matchedHorsesCount}
    </span>
  );
});

export default MatchesCount;
