import React, { useCallback, useState, useEffect } from "react";
import clsx from "clsx";
import { useDispatch } from "react-redux";
import Button from "@material-ui/core/Button";
import TextField, { StandardTextFieldProps } from "@material-ui/core/TextField";
import { ClassNameMap } from "@material-ui/core/styles/withStyles";
import useStyles from "./styles";

export interface Props extends StandardTextFieldProps {
  maxLength: number;
  rows?: number;
  defaultValue?: string;
  onSave?: (value: string) => void;
  onCancel?: () => void;
  className?: string;
  classes?: Partial<ClassNameMap<"root">>;
  classNameCounter?: string;
  showControls?: boolean;
  placeholder?: string;
  isFormikUsed?: boolean;
  enteredValue?: string;
  controlsClasses?: Partial<
    ClassNameMap<"root" | "button" | "cancel" | "save">
  >;
  saveButtonText?: string;
  showCounter?: boolean;
  "data-form"?: string;
  "data-test"?: string;
}

const Textarea = (props: Props) => {
  const {
    className,
    classes,
    classNameCounter,
    label,
    maxLength,
    defaultValue = "",
    rows = 4,
    showControls,
    onSave,
    onCancel,
    onFocus,
    onBlur,
    onChange,
    placeholder,
    controlsClasses = {},
    isFormikUsed = false,
    enteredValue,
    showCounter = true,
    saveButtonText,
    "data-test": dataTestProp,
    "data-form": dataFormProp,
    ...restProps
  } = props;

  const ownClasses = useStyles();
  const [value, setValue] = useState<string>(defaultValue);
  const dispatch = useDispatch();

  const disabledSaveButton =
    !value.trim().length || value.trim().length > maxLength;

  useEffect(() => {
    setValue(defaultValue);
  }, [defaultValue]);

  const handleFocus = useCallback(
    event => {
      if (onFocus) {
        onFocus(event);
      }
    },
    [dispatch]
  );

  const handleBlur = useCallback(
    event => {
      if (onBlur) {
        onBlur(event);
      }
    },
    [dispatch]
  );

  const handleChange = e => {
    const newValue = e.currentTarget.value;

    if (newValue.length <= maxLength) {
      setValue(newValue);
      isFormikUsed ? onChange(e) : onChange(newValue);
    }
  };

  const handleSave = () => {
    onSave && onSave(value.trim());
  };
  const handleCancel = () => {
    onCancel && onCancel();
  };

  return (
    <>
      <div className={clsx(ownClasses.root, classes?.root)}>
        <TextField
          {...restProps}
          className={clsx(ownClasses.textfield, className)}
          variant="outlined"
          fullWidth
          label={label}
          margin="normal"
          InputLabelProps={{
            shrink: true
          }}
          inputProps={{
            ["data-form"]: dataFormProp,
            ["data-test"]: dataTestProp
          }}
          multiline
          minRows={rows}
          value={enteredValue || value}
          placeholder={placeholder}
          onFocus={handleFocus}
          onBlur={handleBlur}
          onChange={handleChange}
        />
        {showCounter && (
          <div className={clsx(ownClasses.counter, classNameCounter)}>
            {(enteredValue || value).length}&nbsp;/&nbsp;
            {maxLength}
          </div>
        )}
      </div>
      {showControls && (
        <div className={clsx(ownClasses.controls, controlsClasses.root)}>
          <Button
            className={clsx(
              ownClasses.controlsCancel,
              controlsClasses.button,
              controlsClasses.cancel
            )}
            onClick={handleCancel}
          >
            CANCEL
          </Button>
          <Button
            className={clsx(
              ownClasses.controlsSave,
              controlsClasses.button,
              controlsClasses.save
            )}
            color={"secondary"}
            onClick={handleSave}
            disabled={disabledSaveButton}
            key={`${disabledSaveButton}`}
          >
            {saveButtonText ? saveButtonText : "SAVE"}
          </Button>
        </div>
      )}
    </>
  );
};

export default Textarea;
