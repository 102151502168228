import { makeStyles } from "@material-ui/core/styles";
import { MuiTheme } from "theme";
import Breakpoints from "common/breakpoints";

export default makeStyles((theme: MuiTheme) => ({
  appSideBarRoot: {
    position: "sticky",
    top: 0,
    maxHeight: "100vh",
    whiteSpace: "nowrap",
    width: theme.widthDesktopDrawer,
    zIndex: theme.zIndex.app.sidebar,
    boxShadow: "1px 0px 7px 0px rgba(0, 0, 0, 0.15)",
    backgroundColor: theme.palette.common.white,
    [theme.breakpoints.down("md")]: {
      width: `calc(${theme.widthTabletDrawer}px)`
    },
    [theme.breakpoints.down(Breakpoints.SM_600)]: {
      display: "none"
    }
  },
  appSideBarRootShadowless: {
    boxShadow: "none"
  },
  appSideBarContent: {
    height: "100vh",
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-between",
    flex: "auto",
    [theme.breakpoints.down(Breakpoints.SM_600)]: {
      height: "initial"
    }
  },
  appSideBarLinks: {
    height: "100%",
    overflow: "auto",
    marginTop: 64
  },
  link: {
    textDecoration: "none",
    height: 68,
    width: "100%",
    display: "flex",
    justifyContent: "flex-start",
    alignItems: "center",
    paddingLeft: 27,
    borderBottom: "solid 1px #eee",
    borderRadius: 0,
    [theme.breakpoints.down("md")]: {
      height: 94,
      paddingLeft: 8
    },
    [theme.breakpoints.down("xs")]: {
      height: 52
    }
  },
  listItemSelected: {
    backgroundColor: "rgba(0, 0, 0, 0.04) !important",
    border: "none",
    outline: `1px solid ${theme.palette.text.hint}`,
    "& $icon": {
      color: theme.palette.text.primary
    }
  },
  listItemText: {
    fontSize: 16,
    lineHeight: 1,
    textTransform: "capitalize",
    marginLeft: 20,
    [theme.breakpoints.down("md")]: {
      display: "none"
    },
    [theme.breakpoints.down("xs")]: {
      display: "block",
      fontSize: 18,
      color: theme.palette.text.secondary
    }
  },
  icon: {
    fontSize: 22,
    color: theme.palette.text.hint,
    [theme.breakpoints.down("md")]: {
      fontSize: 26,
      minWidth: "auto",
      margin: "0 auto"
    },
    [theme.breakpoints.down("xs")]: {
      margin: "0 0 0 12px"
    }
  }
}));
