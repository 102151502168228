import React, { useEffect, useState, useRef } from "react";
import clsx from "clsx";
import { format, parseISO } from "date-fns";
import { useQueryClient } from "react-query";

import { Theme } from "@material-ui/core/styles";
import Typography from "@material-ui/core/Typography";
import Button from "@material-ui/core/Button";
import TextField from "@material-ui/core/TextField";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import Dialog from "@material-ui/core/Dialog";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
import WarningIcon from "@material-ui/icons/Warning";
import { racehorse360 } from "@tsg/1st-grpc-web";

import { useLoggedInUser } from "components/LoggedInUserProvider";
import { useRacehorse360Api } from "hooks/api";
import { useWindowSize } from "hooks/screen";

import useStyles from "./styles";

const MESSAGE_LIMIT = 60;
const DIALOG_WIDTH = 340;

interface Coordinates {
  top: number;
  left: number | string;
  right: number | string;
}

export interface WarnMessage {
  date: string;
  text: string;
  warningMessageUpdatedBy: null | racehorse360.IUser;
}

const modes = {
  viewMode: "viewMode",
  editMode: "editMode"
};

export interface Props {
  horse: racehorse360.IHorse;
  className?: string;
  warningMessage: racehorse360.IWarningMessage;
  isPullHorseWarningMessageFetching: boolean;
  refetchHorseWarningMessage: () => void;
}

const WarningMessageDialog = (props: Props) => {
  const {
    horse,
    className,
    warningMessage,
    isPullHorseWarningMessageFetching,
    refetchHorseWarningMessage
  } = props;
  const classes = useStyles();
  const dialogRef = useRef(null);
  const warnButtonRef = useRef(null);
  const { width, height } = useWindowSize();
  const { currentUser } = useLoggedInUser();
  const matches = useMediaQuery((theme: Theme) => theme.breakpoints.up("lg"), {
    noSsr: true
  });

  const { useDeleteWarningMessageHorse, useAssignWarningMessageHorse } =
    useRacehorse360Api();

  const {
    mutateAsync: deleteHorseWarning,
    isLoading: isDeleteHorseWarningLoading
  } = useDeleteWarningMessageHorse();

  const {
    mutateAsync: upsertHorseWarning,
    isLoading: isUpsertHorseWarningLoading
  } = useAssignWarningMessageHorse();

  const isWarnLoading =
    isDeleteHorseWarningLoading ||
    isUpsertHorseWarningLoading ||
    isPullHorseWarningMessageFetching;

  const saveWarnMessage = () => {
    upsertHorseWarning({
      id: horse.id,
      warningMessage: tempValue.trim()
    })
      .then(() => {
        refetchHorseWarningMessage();
      })
      .then(() => {
        handleCloseWarnDialog();
      })
      .catch(error => {
        console.log(error);
      });
  };

  const removeHorseWarning = () => {
    deleteHorseWarning({
      id: horse.id
    })
      .then(() => {
        refetchHorseWarningMessage();
      })
      .then(() => {
        handleCloseWarnDialog();
      })
      .catch(error => {
        console.log(error);
      });
  };

  const [tempValue, setTempValue] = useState<string>("");
  const [coordinates, setCoordinates] = useState<Coordinates>({
    top: 0,
    left: 0,
    right: 0
  });
  const [warnMessage, setWarnMessage] =
    useState<racehorse360.IWarningMessage>(warningMessage);
  const [isWarningOpen, setIsWarningOpen] = useState<boolean>(false);
  const [warningContentMode, setWarningContentMode] = useState<string>("");

  const handleWarningButtonClick = () => {
    setIsWarningOpen(true);
    const mode = warnMessage?.text?.trim().length
      ? modes.viewMode
      : modes.editMode;
    setWarningContentMode(mode);
  };

  const handleCloseWarnDialog = () => {
    setIsWarningOpen(false);
  };

  const handleRemoveWarning = () => {
    warningContentMode === modes.viewMode
      ? removeHorseWarning()
      : setTempValue("");
  };

  const handleChangeMessage = e => {
    setTempValue(e.target.value.slice(0, MESSAGE_LIMIT));
  };

  const handleCancel = () => {
    if (warnMessage?.text?.trim().length) {
      setWarningContentMode(modes.viewMode);
    } else {
      setIsWarningOpen(false);
    }
  };

  const handleEditClick = () => {
    setTempValue(warnMessage?.text?.trim());
    setWarningContentMode(modes.editMode);
  };

  const handleSaveClick = () => {
    if (warnMessage?.text?.trim() !== tempValue.trim()) {
      saveWarnMessage();
    }
  };

  const countPosition = () => {
    const {
      top,
      left,
      right,
      height: nameHeight
    } = warnButtonRef.current.getBoundingClientRect();
    let offsetTop = top + nameHeight;
    const offsetLeft = left + DIALOG_WIDTH < window.innerWidth ? left : "auto";
    const offsetRight =
      left + DIALOG_WIDTH > window.innerWidth
        ? window.innerWidth - right
        : "auto";

    if (dialogRef.current) {
      const dialogRect = dialogRef.current.getBoundingClientRect();
      if (offsetTop + dialogRect.height > window.innerHeight) {
        offsetTop = top - dialogRect.height;
      }
    }

    setCoordinates({ top: offsetTop, left: offsetLeft, right: offsetRight });
  };

  useEffect(() => {
    countPosition();
  }, [matches, width, height]);

  useEffect(() => {
    setWarnMessage(warningMessage);
    setWarningContentMode(
      warningMessage?.text?.trim().length ? modes.viewMode : modes.editMode
    );
    setTempValue(warningMessage?.text || "");
  }, [warningMessage, isWarningOpen]);

  const horseHasWarning = Boolean(warnMessage?.text?.trim().length);

  const isWarningActive = horseHasWarning || isWarningOpen;
  const isDisabledWarningButton =
    !horseHasWarning && currentUser.isRegulatoryVet;

  const renderWarnContent = () => {
    const isViewMode = warningContentMode === modes.viewMode;
    const isEditMode = warningContentMode === modes.editMode;
    return (
      <>
        <div className={classes.warnContentBody}>
          {isViewMode && (
            <>
              <div className={classes.infoBlock}>
                <Typography className={classes.infoBlockData}>
                  {warnMessage?.text}
                </Typography>
              </div>
              <div className={classes.infoBlock}>
                <Typography className={classes.infoBlockTitle}>
                  Vet Name
                </Typography>
                {
                  <Typography className={classes.infoBlockData}>
                    {`${warnMessage?.updatedBy?.firstName} ${warnMessage?.updatedBy?.lastName}`}
                  </Typography>
                }
                {warnMessage?.updatedOn && (
                  <Typography className={classes.infoBlockAdditional}>
                    {format(
                      parseISO(warnMessage.updatedOn),
                      "LLL dd, yyyy - hh:mm aa"
                    )}
                  </Typography>
                )}
              </div>
            </>
          )}
          {isEditMode && (
            <div className={classes.inputMessageContainer}>
              <TextField
                fullWidth={true}
                multiline={true}
                onChange={handleChangeMessage}
                classes={{
                  root: classes.customTextAreaRoot
                }}
                minRows={3}
                className={classes.customTextArea}
                value={tempValue}
                label="Warning Comment"
                variant="outlined"
              />
              <div className={classes.textCounter}>
                {`${tempValue.length} / ${MESSAGE_LIMIT}`}
              </div>
            </div>
          )}
        </div>
        {!currentUser.isRegulatoryVet && (
          <div className={classes.warnContentFooter}>
            <div className={clsx(classes.footerPart, "leftPart")}>
              <Button
                className={clsx(classes.warnButton, "remove")}
                onClick={handleRemoveWarning}
                disabled={warningContentMode === modes.editMode && !tempValue}
              >
                REMOVE
              </Button>
            </div>
            <div className={clsx(classes.footerPart, "rightPart")}>
              {isViewMode && (
                <Button
                  className={clsx(classes.warnButton, "edit")}
                  onClick={handleEditClick}
                >
                  EDIT
                </Button>
              )}
              {isEditMode && (
                <>
                  <Button
                    className={clsx(classes.warnButton, "save")}
                    onClick={handleSaveClick}
                    disabled={
                      (!tempValue.trim().length &&
                        !warnMessage?.text?.trim().length) ||
                      tempValue === warnMessage?.text
                    }
                  >
                    SAVE
                  </Button>
                  <Button
                    className={clsx(classes.warnButton, "cancel")}
                    onClick={handleCancel}
                  >
                    CANCEL
                  </Button>
                </>
              )}
            </div>
          </div>
        )}
      </>
    );
  };

  const renderWarningTitle = () =>
    warningContentMode === modes.viewMode
      ? "WARNING"
      : `${warningMessage ? "EDIT" : "ADD"} WARNING`;

  return (
    <>
      <Button
        ref={warnButtonRef}
        className={clsx(classes.warningButton, className, {
          [classes.warningButtonActive]: isWarningActive
        })}
        onClick={handleWarningButtonClick}
        disabled={isDisabledWarningButton}
      >
        <WarningIcon className={classes.warningButtonIcon} />
      </Button>
      <Dialog
        onClose={handleCloseWarnDialog}
        open={isWarningOpen}
        className={clsx(classes.warnDialog, {
          [classes.rootPopoverBack]: matches
        })}
        hideBackdrop={matches}
        classes={{
          paper: clsx(classes.warnDialogPaper, {
            [classes.rootPopover]: matches
          })
        }}
        PaperProps={{
          style: {
            maxWidth: DIALOG_WIDTH,
            margin: "0",
            left: matches ? coordinates.left : 0,
            top: matches ? coordinates.top : 0,
            right: matches ? coordinates.right : 0
          }
        }}
      >
        <div ref={dialogRef} className={classes.warnContent}>
          {isWarnLoading && <div className={classes.loadingOverlay} />}
          <DialogTitle
            disableTypography
            className={clsx(classes.warnDialogTitle, {
              [classes.borderBottom]: warningContentMode === modes.viewMode
            })}
          >
            {renderWarningTitle()}
          </DialogTitle>

          <DialogContent
            className={clsx(classes.warnDialogContent, {
              [classes.viewMode]: warningContentMode === modes.viewMode,
              [classes.editMode]: warningContentMode === modes.editMode
            })}
          >
            {renderWarnContent()}
          </DialogContent>
        </div>
      </Dialog>
    </>
  );
};

export default WarningMessageDialog;
