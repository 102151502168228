import React from "react";

import DialogTitle from "@material-ui/core/DialogTitle";
import IconButton from "@material-ui/core/IconButton";
import CloseIcon from "@material-ui/icons/Close";

import useStyles from "./styles";

interface IProps {
  title: string;
  onClose: () => void;
}

const FiltersDialogHeader = (props: IProps) => {
  const { title = "", onClose } = props;
  const classes = useStyles();

  return (
    <DialogTitle className={classes.header}>
      <span className={classes.title}>{title}</span>
      <IconButton color="primary" onClick={onClose}>
        <CloseIcon />
      </IconButton>
    </DialogTitle>
  );
};

export default FiltersDialogHeader;
