import React, { useState } from "react";
import clsx from "clsx";
import addDays from "date-fns/addDays";
import format from "date-fns/format";
import fromUnixTime from "date-fns/fromUnixTime";

import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import Typography from "@material-ui/core/Typography";

import { racehorse360 } from "@tsg/1st-grpc-web";

import Rain from "components/Icons/Rain";
import Loader from "components/Loader";
import { useRacehorse360Api } from "hooks/api";
import { WeatherDaily } from "interfaces/Weather";
import { getTypedDateRange } from "utils/date-utils";
import { getWeatherIconPath } from "utils/weather";

import useStyles from "./styles";

export interface Props {
  selectedFacility: racehorse360.IFacility;
  onChange?: () => void;
  dateFormat?: string;
  className?: string;
}

const Weather = ({
  selectedFacility,
  onChange,
  className,
  dateFormat = "eeee"
}: Props) => {
  const classes = useStyles();

  const [isWeatherServiceAvailable, setIsWeatherServiceAvailable] =
    useState<boolean>(true);

  const { useDailyForecast } = useRacehorse360Api();
  const { isLoading, data = { list: [] } } = useDailyForecast(
    {
      lat: selectedFacility?.latitude || 0,
      lon: selectedFacility?.longitude || 0,
      cnt: 7
    },
    {
      onSuccess: () => {
        setIsWeatherServiceAvailable(true);
        onChange && onChange();
      },
      onError: () => setIsWeatherServiceAvailable(false)
    }
  );

  if (isLoading) {
    return <Loader />;
  }

  const weather = data.list.slice(0, 7);

  const renderWeatherServiceUnavailable = () => {
    return (
      <>
        <List className={clsx(classes.root, className)} disablePadding={true}>
          {getTypedDateRange(new Date(), 7, item => item).map((date, i) => (
            <ListItem
              className={classes.listItem}
              disableGutters={true}
              key={i}
            >
              {renderWeatherDailyHeader(date)}
            </ListItem>
          ))}
        </List>
        <div className={classes.outOfServiceText}>
          Weather data is temporarily unavailable
        </div>
      </>
    );
  };

  const renderWeatherDailyHeader = (date: Date) => {
    return (
      <>
        <Typography className={classes.listItemText}>
          {format(date, dateFormat)}
        </Typography>
        <Typography className={classes.listItemTextShort}>
          {format(date, "eee")}
        </Typography>
      </>
    );
  };

  const renderWeatherDailyContent = (item: WeatherDaily) => {
    return (
      <>
        <div className={classes.picWrapper}>
          <img
            src={getWeatherIconPath(item.weather[0])}
            alt={item.weather[0].main}
          />
        </div>

        <div
          className={clsx(
            classes.temperature,
            `${className}__temperature-container`
          )}
        >
          <span className={classes.temperatureDay}>
            {Math.round(item.temp.day)}°
          </span>
          <span className={classes.temperatureNight}>
            {Math.round(item.temp.night)}°
          </span>
        </div>

        <div className={classes.humidity}>
          <Rain className={classes.humidityIcon} />
          <div className={classes.humidityText}>{item.humidity}%</div>
          <span
            className={classes.humidityPercent}
            style={{ width: `${item.humidity}%` }}
          />
        </div>
      </>
    );
  };

  const renderWeatherForecast = () => {
    return (
      <List className={clsx(classes.root, className)} disablePadding={true}>
        {weather.map((item: WeatherDaily, i) => (
          <ListItem className={classes.listItem} disableGutters={true} key={i}>
            {renderWeatherDailyHeader(fromUnixTime(item.dt))}
            {renderWeatherDailyContent(item)}
          </ListItem>
        ))}
      </List>
    );
  };

  return isWeatherServiceAvailable
    ? renderWeatherForecast()
    : renderWeatherServiceUnavailable();
};

export default Weather;
