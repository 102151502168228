import React, { useEffect, useState } from "react";
import SwipeableViews from "react-swipeable-views";
import clsx from "clsx";
import Tabs, { TabsTypeMap } from "@material-ui/core/Tabs";
import Tab from "@material-ui/core/Tab";
import useStyles from "./styles";

export interface Props {
  activeTab?: string;
  tabs: string[];
  children: React.ReactElement[];
  disabled?: boolean;
  disableLazyLoading?: boolean;
  onTabChange?: (tab: string) => void;
  variant?: TabsTypeMap["props"]["variant"];
  classes?: Partial<{
    root: string;
    tabs: string;
    tab: string;
    content: string;
    views: string;
    selected: string;
    indicator: string;
    slideClassName: string;
    flexContainer: string;
  }>;
}

const SwipeableTabs = (props: Props) => {
  const {
    activeTab,
    tabs,
    children,
    disableLazyLoading,
    disabled,
    variant,
    onTabChange,
    classes = {}
  } = props;
  const ownClasses = useStyles();
  const [selectedTab, setSelectedTab] = useState<string>(activeTab || tabs[0]);

  const handleTabChange = (
    event: React.ChangeEvent<{ value: string }>,
    value: string
  ) => {
    event.preventDefault();
    if (!disabled) {
      setSelectedTab(value);
      onTabChange?.(value);
    }
  };

  const handleChangeIndex = index => {
    setSelectedTab(tabs[index]);
    onTabChange?.(tabs[index]);
  };

  useEffect(() => {
    activeTab && setSelectedTab(activeTab);
  }, [activeTab]);

  return (
    <div className={clsx(ownClasses.root, classes.root)}>
      <Tabs
        className={clsx(ownClasses.tabs, classes.tabs)}
        classes={{
          indicator: classes.indicator,
          flexContainer: classes.flexContainer
        }}
        value={selectedTab}
        onChange={handleTabChange}
        indicatorColor="primary"
        textColor="primary"
        variant={variant || "fullWidth"}
      >
        {tabs.map(tab => (
          <Tab
            key={tab}
            classes={{
              selected: classes.selected
            }}
            className={clsx(ownClasses.tab, classes.tab)}
            label={tab}
            value={tab}
          />
        ))}
      </Tabs>
      <div className={classes.content}>
        <SwipeableViews
          className={clsx(ownClasses.views, classes.views)}
          index={tabs.indexOf(selectedTab)}
          onChangeIndex={handleChangeIndex}
          disableLazyLoading={disableLazyLoading}
          slideClassName={classes.slideClassName}
          disabled={disabled}
        >
          {children}
        </SwipeableViews>
      </div>
    </div>
  );
};

export default SwipeableTabs;
