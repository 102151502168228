import React from "react";
import clsx from "clsx";

import Button from "@material-ui/core/Button";
import Typography from "@material-ui/core/Typography";

import WrenchIcon from "components/Icons/Wrench";
import useStyles from "./styles";

interface IProps {
  isCompactDownXs?: boolean;
  onClick?: () => void;
  showSmall?: boolean;
  className?: string;
}

const CreateOrderButton = React.memo((props: IProps) => {
  const { onClick, showSmall, className, isCompactDownXs = false } = props;

  const classes = useStyles();

  return (
    <Button
      variant="outlined"
      className={clsx(classes.newWorkOrderButton, className, {
        [classes.compactDownXs]: isCompactDownXs,
        [classes.showSmall]: showSmall
      })}
      classes={{
        startIcon: classes.newOrderButtonIcon
      }}
      onClick={onClick}
      startIcon={<WrenchIcon />}
    >
      <Typography className={classes.newWorkOrderButtonText}>
        New work order
      </Typography>
    </Button>
  );
});

export default CreateOrderButton;
