import React, { forwardRef, PropsWithChildren, Ref } from "react";
import clsx from "clsx";

import Accordion from "@material-ui/core/Accordion";
import { Slider } from "@material-ui/core";
import { TransitionProps } from "@material-ui/core/transitions";

import FilterAccordionSummary from "../FilterAccordionSummary";
import FilterAccordionDetails from "../FilterAccordionDetails";
import Tooltip from "@material-ui/core/Tooltip";

import useStyles from "./styles";

interface IProps {
  selectedValues: number[];
  onChangeSelectedValues: (values: number[]) => void;
  filterTitle: string;
  maxValue: number;
  minValue: number;
  minDistance: number;
  subTitle?: string;
}

const ThumbComponent = forwardRef<unknown, TransitionProps>(function Transition(
  props: PropsWithChildren<TransitionProps>,
  ref: Ref<HTMLSpanElement>
) {
  return (
    <span {...props} ref={ref}>
      <span className="bar" />
      <span className="bar" />
      <span className="bar" />
    </span>
  );
});

const LabelComponent = props => {
  const { children, open, value } = props;
  const classes = useStyles();

  return (
    <Tooltip
      classes={{
        tooltip: classes.tooltip
      }}
      open={open}
      enterTouchDelay={0}
      placement="top"
      title={value}
    >
      {children}
    </Tooltip>
  );
};

const SliderFilter = (props: IProps) => {
  const {
    minValue,
    maxValue,
    minDistance,
    selectedValues,
    onChangeSelectedValues,
    filterTitle,
    subTitle
  } = props;
  const classes = useStyles();

  const renderPreviewItems = () => {
    const startValue = Math.min(...selectedValues);
    const endValue = Math.max(...selectedValues);

    if (!startValue && endValue === maxValue) {
      return [];
    } else if (startValue && endValue === maxValue) {
      return [`${startValue}+ Days`];
    } else if (!startValue && !endValue) {
      return ["0 Days"];
    } else {
      return [`${startValue} - ${endValue} Days`];
    }
  };

  const handleChangeTimeOpen = (e, values) => {
    if (Math.abs(values[0] - values[1]) < minDistance) {
      return;
    }

    onChangeSelectedValues(values);
  };

  const renderDetailsContent = () => {
    return (
      <>
        {subTitle && <div className={classes.subTitle}>{subTitle}</div>}
        <div className={classes.sliderWrap}>
          <span className={clsx(classes.label, classes.start)}>
            {`${minValue} Days`}
          </span>
          <Slider
            classes={{
              root: classes.root,
              thumb: classes.thumb,
              track: classes.track,
              rail: classes.rail,
              active: classes.active
            }}
            ValueLabelComponent={LabelComponent}
            ThumbComponent={ThumbComponent}
            value={selectedValues}
            min={minValue}
            max={maxValue}
            onChange={handleChangeTimeOpen}
          />
          <span className={clsx(classes.label, classes.end)}>
            {`${maxValue}+ Days`}
          </span>
        </div>
      </>
    );
  };

  return (
    <Accordion>
      <FilterAccordionSummary
        title={filterTitle}
        previewItems={renderPreviewItems()}
      />
      <FilterAccordionDetails>{renderDetailsContent()}</FilterAccordionDetails>
    </Accordion>
  );
};

export default SliderFilter;
