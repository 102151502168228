import React from "react";
import { useSelector, useDispatch } from "react-redux";
import { matchPath, useHistory } from "react-router-dom";
import clsx from "clsx";

import Button from "@material-ui/core/Button";
import DashboardIcon from "@material-ui/icons/Dashboard";
import Divider from "@material-ui/core/Divider";
import FormatListNumberedIcon from "@material-ui/icons/FormatListNumbered";
import PlaylistAdd from "@material-ui/icons/PlaylistAdd";
import SupervisorAccountIcon from "@material-ui/icons/SupervisorAccount";

import {
  withClickBlocker,
  WithClickBlockerProps
} from "components/BlockableClickContext";
import Conditions from "components/Conditions";
import { useLoggedInUser } from "components/LoggedInUserProvider";
import { usePermissions } from "components/PermissionsProvider";
import HorseIcon from "components/Icons/Horse";
import CalendarIcon from "components/Icons/Calendar";
import HorseShoeIcon from "components/Icons/HorseShoe";
import WrenchIcon from "components/Icons/Wrench";
import PreferredConditionsIcon from "components/Icons/PreferredConditions";
import InventoryAnalyticsIcon from "components/Icons/InventoryAnalyticsIcon";
import routes from "common/routes";
import { matchRoute } from "utils/routes";
import {
  setActiveLink as setStoreActiveLink,
  setStoreActiveLinkCandidate as setStoreClickedLink
} from "store/actions/sidebar";
import LogoIconButton from "./LogoIconButton";
import useStyles from "./styles";

export interface Props extends WithClickBlockerProps {
  className?: string;
  onLinkClick?: () => void;
}

const shouldHideShadow = path => {
  const shadowlessRoutes = [
    routes.stallApplication.path,
    routes.stallApplicationNewForm.path
  ];

  return shadowlessRoutes.some(route =>
    matchPath(path, {
      path: route,
      exact: true,
      strict: true
    })
  );
};

const AppSideBar = (props: Props) => {
  const { className, onLinkClick, handleBlockableLinkClick } = props;
  const classes = useStyles();
  const {
    isStallApplicationPageEnabled,
    isWorkOrdersPageEnabled,
    isInventoryAnalyticsPageEnabled
  } = usePermissions();
  const { currentUser } = useLoggedInUser();
  const history = useHistory();
  const dispatch = useDispatch();
  const activeLink = useSelector(
    (state: { sidebar }) => state?.sidebar.activeLink
  );

  const handleMenuClick = (route: string, state?: any) => event => {
    const isBlockedClick = handleBlockableLinkClick
      ? handleBlockableLinkClick(event)
      : false;

    if (!isBlockedClick) {
      dispatch(setStoreActiveLink(route));
      history.push(route, state);
      onLinkClick && onLinkClick();
    } else {
      dispatch(setStoreClickedLink(route));
    }
  };

  const isApprovedUser: boolean = currentUser.roles.length > 0;
  const shouldCalendarBeHidden: boolean = true; // this should be hidden for all users for now
  const shouldShowDashboard: boolean = currentUser.isTrainer;
  const shouldShowStallApplication: boolean =
    (currentUser.isTrainer || currentUser.isRacingOfficial) &&
    isStallApplicationPageEnabled;
  const shouldShowWorkoutRequests: boolean =
    !currentUser.isMaintenance && !currentUser.isRegulatoryVet;
  const shouldShowPreferredList: boolean =
    currentUser.isRacingOfficial || currentUser.isAdmin;
  const shouldShowConditionBook: boolean = !currentUser.isRegulatoryVet;
  const shouldShowWorkOrders: boolean =
    !currentUser.isRegulatoryVet && isWorkOrdersPageEnabled;
  const shouldShowAdmin: boolean = currentUser.isAdmin;
  const shouldShowHorseDetails: boolean = !currentUser.isMaintenance;
  const shouldShowInventoryAnalytics: boolean =
    currentUser.isRacingOfficial && isInventoryAnalyticsPageEnabled;
  //TODO: fix PreferredConditions page for basic roles and uncomment lines below
  // ||
  // currentUser.isAdmin ||
  // currentUser.isMaintenance;

  return (
    isApprovedUser && (
      <section
        className={clsx(classes.appSideBarRoot, className, {
          [classes.appSideBarRootShadowless]: shouldHideShadow(
            history.location.pathname
          )
        })}
      >
        <div className={classes.appSideBarContent}>
          <nav
            className={classes.appSideBarLinks}
            role="navigation"
            aria-label="main mailbox folders"
          >
            <LogoIconButton onClickLogo={handleMenuClick} />

            {shouldShowDashboard && (
              <Button
                className={clsx(classes.link, {
                  [classes.listItemSelected]: matchRoute(activeLink, [
                    routes.dashboard
                  ])
                })}
                onClick={handleMenuClick(routes.dashboard.path)}
                data-path={routes.dashboard.path}
                aria-label="Dashboard"
              >
                <DashboardIcon className={classes.icon} />
                <div className={classes.listItemText}>Dashboard</div>
              </Button>
            )}

            {shouldShowWorkoutRequests && (
              <Button
                className={clsx(classes.link, {
                  [classes.listItemSelected]: matchRoute(
                    activeLink,
                    [routes.workouts],
                    false
                  )
                })}
                onClick={handleMenuClick(routes.workouts.path)}
                data-path={routes.workouts.path}
                aria-label="Workouts and Exams"
              >
                <HorseIcon className={classes.icon} />
                <div className={classes.listItemText}>Workouts</div>
              </Button>
            )}

            {!shouldCalendarBeHidden && (
              <Button
                className={clsx(classes.link, {
                  [classes.listItemSelected]: matchRoute(
                    activeLink,
                    [routes.calendar],
                    false
                  )
                })}
                onClick={handleMenuClick(routes.calendar.path)}
                data-path={routes.calendar.path}
                aria-label="Calendar"
              >
                <CalendarIcon className={classes.icon} />
                <div className={classes.listItemText}>Calendar</div>
              </Button>
            )}

            {shouldShowHorseDetails && (
              <Button
                className={clsx(classes.link, {
                  [classes.listItemSelected]: matchRoute(
                    activeLink,
                    [routes.horseList],
                    false
                  )
                })}
                onClick={handleMenuClick(routes.horseList.path)}
                data-path={routes.horseList.path}
                aria-label="Horse Details"
              >
                <HorseShoeIcon className={classes.icon} />
                <div className={classes.listItemText}>Horse Details</div>
              </Button>
            )}

            {shouldShowWorkOrders && (
              <Button
                className={clsx(classes.link, {
                  [classes.listItemSelected]: matchRoute(
                    activeLink,
                    [routes.workOrders],
                    false
                  )
                })}
                onClick={handleMenuClick(routes.workOrders.path)}
                data-path={routes.workOrders.path}
                aria-label="Work Orders"
              >
                <WrenchIcon className={classes.icon} />
                <div className={classes.listItemText}>Work Orders</div>
              </Button>
            )}

            {shouldShowPreferredList && (
              <Button
                className={clsx(classes.link, {
                  [classes.listItemSelected]: matchRoute(
                    activeLink,
                    [routes.preferredConditions],
                    false
                  )
                })}
                onClick={handleMenuClick(routes.preferredConditions.path, {
                  resetMatchingHorsesSliderVisibility: true
                })}
                data-path={routes.preferredConditions.path}
                aria-label="Conditions List"
              >
                <PreferredConditionsIcon className={classes.icon} />
                <div className={classes.listItemText}>Conditions List</div>
              </Button>
            )}

            {shouldShowConditionBook && (
              <Button
                className={clsx(classes.link, {
                  [classes.listItemSelected]: matchRoute(
                    activeLink,
                    [routes.conditionBook],
                    false
                  )
                })}
                onClick={handleMenuClick(routes.conditionBook.path)}
                data-path={routes.conditionBook.path}
                aria-label="Condition Book"
              >
                <FormatListNumberedIcon className={classes.icon} />
                <div className={classes.listItemText}>Condition Book</div>
              </Button>
            )}

            {shouldShowStallApplication && (
              <Button
                className={clsx(classes.link, {
                  [classes.listItemSelected]: matchRoute(
                    activeLink,
                    [routes.stallApplication],
                    false
                  )
                })}
                onClick={handleMenuClick(routes.stallApplication.path)}
                data-path={routes.stallApplication.path}
                aria-label="Stall Application"
              >
                <PlaylistAdd className={classes.icon} />
                <div className={classes.listItemText}>Stall Application</div>
              </Button>
            )}

            {shouldShowInventoryAnalytics && (
              <Button
                className={clsx(classes.link, {
                  [classes.listItemSelected]: matchRoute(
                    activeLink,
                    [routes.inventoryAnalytics],
                    false
                  )
                })}
                onClick={handleMenuClick(routes.inventoryAnalytics.path)}
                data-path={routes.inventoryAnalytics.path}
                aria-label="Inventory Analytics"
              >
                <InventoryAnalyticsIcon className={classes.icon} />
                <div className={classes.listItemText}>Inventory Analytics</div>
              </Button>
            )}

            {shouldShowAdmin && (
              <Button
                className={clsx(classes.link, {
                  [classes.listItemSelected]: matchRoute(
                    activeLink,
                    [routes.admin],
                    false
                  )
                })}
                onClick={handleMenuClick(routes.admin.path)}
                data-path={routes.admin.path}
                aria-label="Admin"
              >
                <SupervisorAccountIcon className={classes.icon} />
                <div className={classes.listItemText}>Admin</div>
              </Button>
            )}
          </nav>
          <div>
            <Divider />
            <Conditions
              selected={matchRoute(activeLink, [routes.manageTracks], false)}
              openManageTracksPage={handleMenuClick(routes.manageTracks.path)}
              dataPath={routes.manageTracks.path}
            />
          </div>
        </div>
      </section>
    )
  );
};

export default withClickBlocker(AppSideBar);
