import React, { useEffect, useRef, useState } from "react";
import { useHistory } from "react-router";
import clsx from "clsx";

import Button from "@material-ui/core/Button";
import DeleteIcon from "@material-ui/icons/Delete";
import EditIcon from "@material-ui/icons/Edit";
import WarningIcon from "@material-ui/icons/Warning";

import { useClickBlockerContext } from "components/BlockableClickContext";
import {
  ECommentDisplayingVariant,
  EReportersIcons,
  getReporterRoleIcon
} from "components/Comments/Comment/helper";
import { ICommentData } from "components/Comments/Comment/Comment";
import UserIcon from "components/Icons/Profile";
import StethoscopeIcon from "components/Icons/Stethoscope";
import HorseHeadInCircleIcon from "components/Icons/HorseHeadInCircle";
import DeleteConfirmationDialog from "components/WorkoutComment/DeleteConfirmationDialog";
import Textarea from "components/Textarea";
import { useLoggedInUser } from "components/LoggedInUserProvider";
import UnsavedChangesDialog from "components/UnsavedChangesDialog";
import { transformViewDate } from "utils/date-utils";
import { getPersonFullName } from "utils/person";

import useStyles from "./styles";
import { racehorse360 } from "@tsg/1st-grpc-web";

interface IProps {
  isEditing: boolean;
  data: racehorse360.IHorseAggregatedComment;
  onSave: (
    commentId: string,
    commentText: string,
    warningMessage: string
  ) => void;
  onDelete: (commentId: string) => void;
  onEditStart: (commentId: string) => void;
  onEditFinish: () => void;
  className?: string;
}

const HorseGeneralVetComment = (props: IProps) => {
  const {
    isEditing,
    data: {
      commenterId,
      commenterFirstName,
      commenterLastName,
      createdOn,
      body: content,
      warningMessage,
      horseAggregatedCommentIconType: commentIconType,
      id
    },
    onSave,
    onDelete,
    onEditStart,
    onEditFinish,
    className
  } = props;
  const classes = useStyles();
  const { setBlockClick } = useClickBlockerContext();
  const commentInputRef = useRef<HTMLInputElement>(null);
  const warningCommentInputRef = useRef<HTMLInputElement>(null);
  const history = useHistory();

  const { currentUser } = useLoggedInUser();
  const comment = useRef<string>(null);
  const warningComment = useRef<string>(null);
  const [hasUnsavedChanges, setHasUnsavedChanges] = useState<boolean>(false);
  const [linkCandidate, setLinkCandidate] = useState<string>(null);
  const [shouldDisplayDeleteConfirmation, setShouldDisplayDeleteConfirmation] =
    useState<boolean>(false);
  const [
    shouldDisplayWarningMessageBlock,
    setShouldDisplayWarningMessageBlock
  ] = useState<boolean>(false);
  const [isSaveable, setIsSaveable] = useState<boolean>(false);

  const canDoActionsOnComments = currentUser.rh360Id === commenterId;

  const dataFormKey = `general-vet-comment-editor-form-${id}`;

  const renderReporterIcon = (type: EReportersIcons) => {
    switch (type) {
      case EReportersIcons.VET:
        return <StethoscopeIcon className={classes.icon} />;
      case EReportersIcons.TRAINER:
        return <HorseHeadInCircleIcon className={classes.icon} />;
      case EReportersIcons.OTHER_USERS:
        return <UserIcon className={classes.icon} />;
    }
  };

  const handleDeleteClick = () => {
    setShouldDisplayDeleteConfirmation(true);
  };

  const handleEditClick = () => {
    comment.current = content;
    warningComment.current = warningMessage;
    setShouldDisplayWarningMessageBlock(Boolean(warningMessage?.length));
    onEditStart(id);
  };

  const handleCancelComment = () => {
    onEditFinish();
  };

  const handleSaveComment = () => {
    if (
      comment.current?.trim() !== content ||
      warningComment.current?.trim() !== warningMessage
    ) {
      onEditFinish();
      onSave(id, comment.current, warningComment.current);
    }
  };

  const redirectToLinkCandidate = () => {
    if (linkCandidate === "backLink") {
      history.goBack();
    } else {
      history.push(linkCandidate);
    }
  };

  const unlockNavigation = () => {
    setBlockClick(false);
    setHasUnsavedChanges(false);
  };

  const handleChangeComment = value => {
    comment.current = value;
    setIsSaveable(comment.current?.trim() !== content);
  };

  const handleChangeWarningComment = value => {
    warningComment.current = value;
  };

  const handleBlurComment = event => {
    if (event.relatedTarget?.dataset?.form?.localeCompare(dataFormKey) === 0)
      return;

    if (
      comment.current?.trim() === content &&
      warningComment.current?.trim() === warningMessage
    ) {
      onEditFinish();
      return;
    }

    const path = event.relatedTarget?.dataset?.path;

    setBlockClick(true);
    setHasUnsavedChanges(true);
    path && setLinkCandidate(path);
  };

  const handleCancelUnsavedChangesModal = () => {
    setHasUnsavedChanges(false);
  };

  const handleDiscardChanges = () => {
    comment.current = content;
    warningComment.current = warningMessage;

    unlockNavigation();
    onEditFinish();
    linkCandidate && redirectToLinkCandidate();
  };

  const handleWarningButtonClick = () => {
    if (shouldDisplayWarningMessageBlock) {
      commentInputRef.current?.focus();
      warningComment.current = null;
    }
    setShouldDisplayWarningMessageBlock(!shouldDisplayWarningMessageBlock);
  };

  const handleDeleteConfirm = () => {
    onDelete(id);
    setShouldDisplayDeleteConfirmation(false);
  };

  const handleDeleteCancel = () => {
    setShouldDisplayDeleteConfirmation(false);
  };

  useEffect(() => {
    const input = warningCommentInputRef.current;
    if (isEditing && shouldDisplayWarningMessageBlock && input) {
      input?.focus();
      input.selectionStart = input.value.length;
    }
  }, [shouldDisplayWarningMessageBlock]);

  useEffect(() => {
    const input = commentInputRef.current;
    if (isEditing && !hasUnsavedChanges && input) {
      input?.focus();
      input.selectionStart = input.value.length;
    }
  }, [isEditing, hasUnsavedChanges]);

  const renderActionButtons = () => {
    return (
      canDoActionsOnComments &&
      !isEditing && (
        <div className={classes.commentActions}>
          <Button
            variant="outlined"
            className={classes.commentButton}
            onClick={handleDeleteClick}
          >
            <DeleteIcon className={classes.commentButtonIcon} />
          </Button>
          <Button
            variant="outlined"
            className={classes.commentButton}
            onClick={handleEditClick}
          >
            <EditIcon className={classes.commentButtonIcon} />
          </Button>
        </div>
      )
    );
  };

  const renderHeader = () => {
    return (
      <>
        <div className={classes.headerRow}>
          <span className={classes.commenterInfo}>
            {renderReporterIcon(
              getReporterRoleIcon(
                commentIconType,
                ECommentDisplayingVariant.COMMENT_FOR_VETERINARIAN
              )
            )}
            {getPersonFullName(
              { firstName: commenterFirstName, lastName: commenterLastName },
              "%f %M %L"
            )}
          </span>

          {renderActionButtons()}
        </div>
        <div className={classes.row}>
          <div className={classes.info}>
            <div className={classes.uppercase}>
              {transformViewDate(createdOn, null, "PP")}
            </div>
            <div className={classes.additionalSigns}>&nbsp;&ndash;&nbsp;</div>
            <div>{transformViewDate(createdOn, null, "p")}</div>
          </div>
        </div>
      </>
    );
  };

  const renderContent = () => {
    if (isEditing) {
      return (
        <div className={classes.editMode}>
          <Textarea
            className={classes.commentTextarea}
            inputRef={commentInputRef}
            maxLength={500}
            label="General Comment"
            rows={8}
            defaultValue={comment.current || ""}
            onChange={handleChangeComment}
            onBlur={handleBlurComment}
            data-form={dataFormKey}
          />
          {Boolean(
            shouldDisplayWarningMessageBlock || warningComment.current?.length
          ) && (
            <Textarea
              className={classes.commentTextarea}
              inputRef={warningCommentInputRef}
              maxLength={60}
              label="Warning Comment"
              rows={1}
              defaultValue={warningComment.current || ""}
              onChange={handleChangeWarningComment}
              onBlur={handleBlurComment}
              data-form={dataFormKey}
            />
          )}
          <div className={classes.buttons}>
            <Button
              className={clsx(classes.button, classes.warningButton, {
                [classes.warningButtonActive]: shouldDisplayWarningMessageBlock
              })}
              variant="outlined"
              startIcon={<WarningIcon className={classes.warningButtonIcon} />}
              onClick={handleWarningButtonClick}
              data-form={dataFormKey}
            >
              <span className={classes.warningButtonText}>
                {shouldDisplayWarningMessageBlock
                  ? "REMOVE WARNING"
                  : "ADD WARNING"}
              </span>
            </Button>
            <div>
              <Button
                className={classes.button}
                onMouseDown={handleCancelComment}
                data-form={dataFormKey}
              >
                Cancel
              </Button>
              <Button
                className={clsx(classes.button, classes.saveButton)}
                disabled={!isSaveable}
                onMouseDown={handleSaveComment}
                data-form={dataFormKey}
              >
                Save
              </Button>
            </div>
          </div>
        </div>
      );
    } else {
      return (
        <>
          {Boolean(warningMessage?.length) && (
            <div className={classes.warningComment}>
              <div className={classes.warningIconContainer}>
                <WarningIcon className={classes.warningIconColored} />
              </div>
              <div className={classes.warningMessage}>{warningMessage}</div>
            </div>
          )}
          <div className={classes.commentContent}>{content}</div>
        </>
      );
    }
  };

  const renderUnsavedChangesPopupContent = () => {
    return (
      <>
        <span className={classes.warningText}>
          Your comment has not been saved.
        </span>
        <span className={classes.warningText}>
          Are you sure you want to leave and discard your comment?
        </span>
      </>
    );
  };

  return (
    <div className={clsx(classes.commentRoot, className)} tabIndex={0}>
      {renderHeader()}
      {renderContent()}

      {shouldDisplayDeleteConfirmation && (
        <DeleteConfirmationDialog
          open={shouldDisplayDeleteConfirmation}
          onDelete={handleDeleteConfirm}
          onCancel={handleDeleteCancel}
        >
          {transformViewDate(createdOn, null, "iiii, MMMM dd - h:mm a")}
        </DeleteConfirmationDialog>
      )}

      {hasUnsavedChanges && (
        <UnsavedChangesDialog
          onSave={handleSaveComment}
          onCancel={handleCancelUnsavedChangesModal}
          onDiscard={handleDiscardChanges}
          open={hasUnsavedChanges}
          title={"Discard Comment?"}
          content={renderUnsavedChangesPopupContent()}
          className={classes.confirmationDialog}
          isSaveDisabled
        />
      )}
    </div>
  );
};

export default React.memo(HorseGeneralVetComment);
