import format from "date-fns/format";
import addDays from "date-fns/addDays";

import { racehorse360 } from "@tsg/1st-grpc-web";

import DateRange, { DateRangeType } from "interfaces/DateRange";
import {
  SortOrderExtended,
  SortOrder,
  SortOrderType
} from "interfaces/SortOrder";
import { currentTimeZoneString } from "utils/date-utils";
import { getPersonFullName } from "utils/person";
import { ISelectedHorsesWithRiskFactors } from "./ConfirmSelectionsDialog/ConfirmSelectionsDialog";

export enum WorkoutsTableTab {
  REQUESTS = "requests",
  EXAMS = "exams",
  PENDING = "pending",
  PASSED = "passed",
  FAILED = "failed"
}

export interface WorkoutsTabsStatuses {
  isRequestsTab: boolean;
  isExamsTab: boolean;
  isPendingTab: boolean;
  isPassedTab: boolean;
  isFailedTab: boolean;
}

export const checkCurrentWorkoutsTableTab = (
  tab: WorkoutsTableTab
): WorkoutsTabsStatuses => {
  return {
    isRequestsTab: tab === WorkoutsTableTab.REQUESTS,
    isExamsTab: tab === WorkoutsTableTab.EXAMS,
    isPendingTab: tab === WorkoutsTableTab.PENDING,
    isPassedTab: tab === WorkoutsTableTab.PASSED,
    isFailedTab: tab === WorkoutsTableTab.FAILED
  };
};

export const getDateRangeQuery = (
  dateRange: DateRange
): racehorse360.IDateMatch | null => {
  if (!dateRange) return null;
  switch (dateRange.Type) {
    case DateRangeType.Today:
      return {
        range: {
          relative: {
            startDate: {
              value: 0
            },
            endDate: {
              value: 1
            },
            timezone: currentTimeZoneString()
          }
        }
      };
    case DateRangeType.Tomorrow:
      return {
        range: {
          relative: {
            startDate: {
              value: 1
            },
            endDate: {
              value: 2
            },
            timezone: currentTimeZoneString()
          }
        }
      };
    case DateRangeType.Next3Days:
      return {
        range: {
          relative: {
            startDate: {
              value: 0
            },
            endDate: {
              value: 3
            },
            timezone: currentTimeZoneString()
          }
        }
      };
    case DateRangeType.Custom:
      return {
        range: {
          absolute: {
            onOrAfter: format(dateRange.CustomDateRange[0], "yyyy-MM-dd"),
            before: format(
              addDays(dateRange.CustomDateRange[1], 1),
              "yyyy-MM-dd"
            )
            // timezone: currentTimeZoneString()
          }
        }
      };
    default:
      return null;
  }
};

export const mapExamsToRequests = (
  exams: racehorse360.IWorkoutExam[]
): racehorse360.IWorkoutRequest[] => {
  return exams.map(exam => {
    const workoutRequest = exam.workoutRequest || {};
    workoutRequest.workoutExam = exam;
    return workoutRequest;
  });
};

export const getUniqueCheckedHorses = (
  workoutsRequests: racehorse360.IWorkoutRequest[]
): ISelectedHorsesWithRiskFactors[] => {
  const uniqueCheckedHorsesIds = new Set();

  return workoutsRequests
    .filter(workoutRequest => {
      const shouldPass =
        workoutRequest.horse.riskFactorsCount &&
        !uniqueCheckedHorsesIds.has(workoutRequest.horse.id);

      if (shouldPass) {
        uniqueCheckedHorsesIds.add(workoutRequest.horse.id);

        return true;
      }

      return false;
    })
    .map(workoutRequest => ({
      horseId: workoutRequest.horse.id,
      horseName: workoutRequest.horse.name,
      riskFactorsCount: workoutRequest.horse.riskFactorsCount,
      trainerName: getPersonFullName(
        {
          firstName: workoutRequest.horse.trainer?.firstName,
          lastName: workoutRequest.horse.trainer?.lastName
        },
        "%f %L"
      )
    }));
};

export const getOrderRules = (
  selectedTab: WorkoutsTableTab,
  orderByOptions: string[],
  order: SortOrderType
): string[] => {
  const { isRequestsTab } = checkCurrentWorkoutsTableTab(selectedTab);
  const requestsDefaultRules = [
    `horse.name ${SortOrder.ASC}`,
    `date ${SortOrder.ASC}`
  ];
  const examsDefaultRules = [
    `horse.name ${SortOrder.ASC}`,
    `workoutRequest.date ${SortOrder.ASC}`
  ];
  const mainRules = orderByOptions.map(opt => `${opt} ${order}`);
  let finalRules;

  if (isRequestsTab) {
    finalRules = [...mainRules, ...requestsDefaultRules];

    if (orderByOptions.includes("isFlagged")) {
      finalRules = [
        ...mainRules,
        `flagRequest.priority ${order}`,
        ...requestsDefaultRules
      ];
    }
  } else {
    finalRules = [...mainRules, ...examsDefaultRules];

    if (orderByOptions.includes("workoutRequest.isFlagged")) {
      finalRules = [
        ...mainRules,
        `workoutRequest.flagRequest.priority ${order}`,
        ...examsDefaultRules
      ];
    }

    if (orderByOptions.includes("horse.trainer.lastName")) {
      finalRules = [
        ...mainRules,
        `workoutRequest.isFlagged ${SortOrder.DESC}`,
        `workoutRequest.flagRequest.priority ${SortOrder.DESC}`,
        `horse.healthWatchStatus ${SortOrderExtended.DESC_NULLS_LAST}`,
        ...examsDefaultRules
      ];
    }
  }

  return finalRules;
};

export const getOrderByOptions = (
  options: string[] = [],
  orderBy: string
): string[] => {
  if (orderBy === "horse.name" || orderBy === "horse.trainer.lastName") {
    options = options.filter(item => item === orderBy);
  }

  return options;
};
