import React from "react";
import clsx from "clsx";

import { racehorse360 } from "@tsg/1st-grpc-web";

import WorkoutRequestPPsRow from "./WorkoutRequestPPsRow";
import WorkoutDetails from "components/VetActivity/Workouts/WorkoutDetails";
import { EWorkoutView } from "components/VetActivity/Workouts/WorkoutDetails/WorkoutDetails";
import { transformViewDate } from "utils/date-utils";
import useStyles from "./styles";

interface IProps {
  horse: racehorse360.IHorse;
}

const WorkoutRequestPPs = (props: IProps) => {
  const {
    lastPastPerformances,
    lifetime: lifetimeHorseRecords,
    lastWorkouts
  } = props.horse;

  const classes = useStyles();

  const renderLifetimeHorseRecords = () => {
    if (lifetimeHorseRecords) {
      const { starts, wins, place, show } = lifetimeHorseRecords;

      return [starts, wins, place, show].map((item, index) => (
        <span key={index} className={classes.lifeInfoItem}>
          {item || 0}
        </span>
      ));
    }
  };

  const renderPPsRows = () => {
    if (lastPastPerformances?.length) {
      return lastPastPerformances.map(pp => (
        <WorkoutRequestPPsRow key={pp.id} data={pp} />
      ));
    }
  };

  const renderLastWorkouts = () => {
    if (lastWorkouts?.length) {
      return lastWorkouts.map(workout => (
        <div key={workout.id} className={classes.lastWorkout}>
          <span>
            {transformViewDate(workout.date, null, "dd MMM yy")}&nbsp;
          </span>
          <span>{workout.track}</span>
          <WorkoutDetails
            workout={workout}
            view={EWorkoutView.WORKOUT_REQUEST_VIEW}
          />
        </div>
      ));
    }
  };

  return (
    <div className={classes.pastPerformances}>
      <div className={classes.lifeInfo}>
        <span className={clsx(classes.lifeInfoItem, classes.lifeInfoTitle)}>
          Life:
        </span>
        {renderLifetimeHorseRecords()}
      </div>

      {renderPPsRows()}

      <div className={classes.lastWorkouts}>{renderLastWorkouts()}</div>
    </div>
  );
};

export default WorkoutRequestPPs;
