import React, { useCallback, useContext, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { v4 } from "uuid";

import Snackbar from "@material-ui/core/Snackbar";
import MuiAlert from "@material-ui/lab/Alert";
import CheckCircleOutlineIcon from "@material-ui/icons/CheckCircleOutline";

import { ESnackType, SnackbarState, ISnackbar } from "store/reducers/snackbars";
import {
  acknowledgeSnackbar,
  removeSnackbar,
  pushSnackbar
} from "store/actions/snackbars";
import useStyles from "./styles";

export type ShowSnackbarFn = (message: string | React.ReactElement) => void;

export interface SnackbarContextModel {
  showSuccessSnack: ShowSnackbarFn;
  showErrorSnack: ShowSnackbarFn;
}

export const SnackbarContext = React.createContext<SnackbarContextModel>({
  showSuccessSnack: (message: string | React.ReactElement) => {
    return null;
  },
  showErrorSnack: (message: string | React.ReactElement) => {
    return null;
  }
});

export const useSnackbar = () => useContext(SnackbarContext);

export const SnackbarContextProvider = props => {
  const { children } = props;
  const classes = useStyles();
  const dispatch = useDispatch();
  const { snackbars } = useSelector(
    (state: { snackbars: SnackbarState }) => state
  );

  const [{ id: lastSnackId } = { id: 0 }] = snackbars.slice(-1);

  const addSnack = useCallback(
    (message: string, type: ESnackType) => {
      dispatch(
        pushSnackbar({
          id: v4(),
          timestamp: Date.now(),
          isAcknowledged: false,
          type,
          message
        })
      );
    },
    [dispatch]
  );

  const showSuccessSnack = useCallback(
    (message: string) => {
      addSnack(message, ESnackType.SUCCESS);
    },
    [lastSnackId]
  );

  const showErrorSnack = useCallback(
    (message: string) => {
      addSnack(message, ESnackType.ERROR);
    },
    [lastSnackId]
  );

  const handleSnackClose = useCallback(
    (snackbar: ISnackbar) => () => {
      dispatch(acknowledgeSnackbar(snackbar.id));
    },
    [dispatch]
  );

  const handleSnackCloseEnd = useCallback(
    (snackbar: ISnackbar) => () => {
      dispatch(removeSnackbar(snackbar.id));
    },
    [dispatch]
  );

  useEffect(() => {
    if (snackbars.length > 3) {
      snackbars.slice(0, -3).forEach(snack => {
        dispatch(acknowledgeSnackbar(snack.id));
      });
    }
  }, [dispatch, lastSnackId]);

  return (
    <SnackbarContext.Provider
      value={{
        showSuccessSnack,
        showErrorSnack
      }}
    >
      {children}
      {Boolean(snackbars) && (
        <div className={classes.snackbars}>
          {snackbars.map(snack => (
            <Snackbar
              key={snack.id}
              className={classes.snackbar}
              open={Boolean(!snack.isAcknowledged)}
              autoHideDuration={6000}
              ClickAwayListenerProps={{
                mouseEvent: false
              }}
              TransitionProps={{
                onExited: handleSnackCloseEnd(snack)
              }}
              onClose={handleSnackClose(snack)}
              data-test={"snackbar"}
            >
              <MuiAlert
                classes={{
                  message: classes.snackMessage
                }}
                elevation={6}
                variant="filled"
                severity={snack.type}
                onClose={handleSnackClose(snack)}
                icon={<CheckCircleOutlineIcon />}
                action={<></>}
                data-test={"snackbar__message"}
              >
                {snack.message}
              </MuiAlert>
            </Snackbar>
          ))}
        </div>
      )}
    </SnackbarContext.Provider>
  );
};

export default SnackbarContext;
