import React, { useEffect, useState } from "react";
import { useQueryClient } from "react-query";
import clsx from "clsx";
import format from "date-fns/format";
import parseISO from "date-fns/parseISO";

import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
import FormControl from "@material-ui/core/FormControl";
import IconButton from "@material-ui/core/IconButton";
import AddCircleIcon from "@material-ui/icons/AddCircle";
import DeleteForeverIcon from "@material-ui/icons/DeleteForever";

import { racehorse360 } from "@tsg/1st-grpc-web";
import { useClickBlockerContext } from "components/BlockableClickContext";
import Loader from "components/Loader";
import { useLoggedInUser } from "components/LoggedInUserProvider";
import Textarea from "components/Textarea";
import UnsavedChangesDialog from "components/UnsavedChangesDialog";
import { useRacehorse360Api } from "hooks/api";
import useStyles from "./styles";

interface IProps {
  workOrderId: string;
  notes: racehorse360.IWorkOrderNote[];
  isLoading: boolean;
}

const WorkOrderNotes = React.memo((props: IProps) => {
  const { workOrderId, notes, isLoading } = props;
  const classes = useStyles();
  const queryClient = useQueryClient();
  const { currentUser } = useLoggedInUser();
  const { setBlockClick, setBlockClickCallback } = useClickBlockerContext();
  const [unsavedChangesDialogOpen, setUnsavedChangesDialogOpen] =
    useState<boolean>(false);
  const [showNoteForm, setShowNoteForm] = useState<boolean>(false);
  const [note, setNote] = useState<string>("");
  const [deletingNoteId, setDeletingNoteId] = useState<string>();
  const mapped = notes?.reduce((days, note) => {
    const formattedDate = format(parseISO(note.createdOn), "M/d/yy");
    days[formattedDate] = days[formattedDate] || [];
    days[formattedDate].push(note);
    return days;
  }, {});

  const { useCreateWorkOrderNote, useDeleteWorkOrderNote } =
    useRacehorse360Api();

  const { mutateAsync: createNote, isLoading: isCreating } =
    useCreateWorkOrderNote();
  const { mutateAsync: deleteNote, isLoading: isDeleting } =
    useDeleteWorkOrderNote();

  const isNoteRemovable = (note: racehorse360.WorkOrderNote) => {
    return (
      note.isManual &&
      (currentUser.isMaintenanceSupervisor ||
        currentUser.rh360Id === note.createdBy?.id)
    );
  };

  const lockNavigation = () => {
    setBlockClick(true);
    setBlockClickCallback(() => () => {
      setUnsavedChangesDialogOpen(true);
    });
  };

  const unLockNavigation = () => {
    setBlockClick(false);
    setBlockClickCallback(null);
  };

  useEffect(() => {
    if (note) {
      lockNavigation();
    } else {
      unLockNavigation();
    }
  }, [note]);

  const handleAddNoteClick = () => {
    setShowNoteForm(true);
  };

  const handleNoteChange = value => {
    setNote(value);
  };

  const handleSaveClick = () => {
    setUnsavedChangesDialogOpen(false);
    createNote({
      workOrderId,
      note
    })
      .then(() => {
        return queryClient.invalidateQueries([`${workOrderId}-list-notes`]);
      })
      .then(() => {
        setNote("");
        setShowNoteForm(false);
      });
  };

  const handleCancelClick = () => {
    setNote("");
    setShowNoteForm(false);
    setUnsavedChangesDialogOpen(false);
  };

  const handleUnsavedCancel = () => {
    setUnsavedChangesDialogOpen(false);
  };

  const handleDeleteNoteClick = (noteId: string) => () => {
    setDeletingNoteId(noteId);
  };

  const handleConfirmationClose = () => {
    setDeletingNoteId(null);
  };

  const handleConfirmationAccept = () => {
    deleteNote({
      id: deletingNoteId
    })
      .then(() => {
        return queryClient.invalidateQueries([`${workOrderId}-list-notes`]);
      })
      .then(() => {
        setDeletingNoteId(null);
      });
  };

  return (
    <div className={classes.root}>
      <div className={classes.header}>
        <div className={classes.headerTitle}>Notes</div>
        <Button
          className={clsx(classes.addNoteButton, {
            [classes.addNoteButtonActive]: showNoteForm
          })}
          disabled={showNoteForm}
          variant="outlined"
          startIcon={<AddCircleIcon className={classes.addCircleIcon} />}
          onClick={handleAddNoteClick}
        >
          Add Note
        </Button>
      </div>
      {showNoteForm && (
        <FormControl fullWidth className={classes.noteForm}>
          {isCreating && <Loader overlay />}
          <Textarea
            autoFocus
            className={classes.noteTextarea}
            classNameCounter={classes.noteTextareaCounter}
            maxLength={200}
            rows={5}
            value={note}
            onChange={handleNoteChange}
            showControls
            controlsClasses={{
              button: classes.noteTextareaButton
            }}
            saveButtonText={"SAVE NOTE"}
            onSave={handleSaveClick}
            onCancel={handleCancelClick}
          />
        </FormControl>
      )}
      <div className={clsx(classes.notes)}>
        {isLoading && <Loader overlay />}
        {mapped &&
          Object.keys(mapped).map(formattedDate => (
            <div key={formattedDate} className={classes.notesGroup}>
              <div className={classes.notesGroupHeader}>{formattedDate}</div>
              {mapped[formattedDate].map(note => (
                <div key={note.id} className={classes.note}>
                  <span className={classes.noteTime}>
                    {format(parseISO(note.createdOn), "h:mm a")}
                  </span>
                  <span className={classes.noteBody}>
                    {Boolean(note.createdBy) && note.isManual && (
                      <span className={classes.noteAuthor}>
                        {note.createdBy.firstName}&nbsp;
                        {note.createdBy.lastName}:{" "}
                      </span>
                    )}
                    {note.description}
                  </span>
                  {isNoteRemovable(note) && (
                    <IconButton
                      className={classes.noteDeleteButton}
                      onClick={handleDeleteNoteClick(note.id)}
                    >
                      <DeleteForeverIcon
                        className={classes.noteDeleteButtonIcon}
                      />
                    </IconButton>
                  )}
                </div>
              ))}
            </div>
          ))}
      </div>
      <Dialog
        open={Boolean(deletingNoteId)}
        onClose={handleConfirmationClose}
        classes={{
          paper: classes.dialogPaper
        }}
      >
        {isDeleting && <Loader overlay />}
        <DialogTitle className={classes.dialogTitle}>Delete Note?</DialogTitle>
        <DialogContent className={classes.dialogContent}>
          Are you sure you want to permanently delete this note?
        </DialogContent>
        <DialogActions className={classes.dialogActions}>
          <Button className={classes.button} onClick={handleConfirmationClose}>
            CANCEL
          </Button>
          <Button
            className={clsx(classes.button, classes.delete)}
            onClick={handleConfirmationAccept}
          >
            DELETE NOTE
          </Button>
        </DialogActions>
      </Dialog>
      <UnsavedChangesDialog
        onSave={handleSaveClick}
        onCancel={handleUnsavedCancel}
        onDiscard={handleCancelClick}
        open={unsavedChangesDialogOpen}
      />
    </div>
  );
});

export default WorkOrderNotes;
