import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useLocation } from "react-router-dom";
import clsx from "clsx";
import { goBack } from "connected-react-router";

import SwipeableDrawer from "@material-ui/core/SwipeableDrawer";
import Toolbar from "@material-ui/core/Toolbar";
import IconButton from "@material-ui/core/IconButton";
import Typography from "@material-ui/core/Typography";
import AppBar from "@material-ui/core/AppBar";
import MenuIcon from "@material-ui/icons/Menu";
import Button from "@material-ui/core/Button";
import { useMediaQuery } from "@material-ui/core";
import { Theme } from "@material-ui/core/styles";

import routes from "common/routes";
import Breakpoints from "common/breakpoints";
import BackLink from "components/BackLink";
import {
  withClickBlocker,
  WithClickBlockerProps
} from "components/BlockableClickContext";
import AppSideBar from "components/AppSideBar";
import { useLoggedInUser } from "components/LoggedInUserProvider";
import ITrainerHorseDetailsPageState from "interfaces/TrainerHorseDetailsPageState";
import {
  IVetExamState,
  EModalConfirmationTypes
} from "interfaces/VetExamState";
import { IVetHorseDetailsPageState } from "interfaces/VetHorseDetailsPageState";
import { IRacingSecretaryStallApplicationState } from "interfaces/RacingSecretaryStallApplication";
import { matchRoute } from "utils/routes";
import { SidebarActiveLinkReducer } from "store/reducers/sidebar";
import { setStallApplicationUnsavedChangesPopupState } from "store/actions/racingSecretaryStallApplication";
import {
  setModalState as setVetExamModalState,
  setModalType as setVetExamModalType
} from "store/actions/vetExam";
import AccountPageSlider from "../AccountPageSlider/AccountPageSlider";
import useStyles from "./styles";

export interface Props extends WithClickBlockerProps {
  className?: string;
  rootClassName?: string;
  isProfilePage?: boolean;
  pathname?: string;
}

const AppHeader = (props: Props) => {
  const { className, rootClassName, handleBlockableLinkClick } = props;
  const classes = useStyles();
  const [menuOpen, setMenuOpen] = useState<boolean>(false);
  const { currentUser } = useLoggedInUser();
  const location = useLocation();
  const dispatch = useDispatch();
  const matchesDownSM600 = useMediaQuery((theme: Theme) =>
    theme.breakpoints.down(Breakpoints.SM_600)
  );
  const swipeAreaWidth = matchesDownSM600 ? 16 : 0;
  const {
    activeLink,
    isOpenVetExamPage,
    isOpenPreferredConditions,
    isOpenMapOnTrainerHorseDetails,
    isOpenMapOnVetHorseDetails,
    shouldBackArrowBeShownByCustomReason,
    isRacingSecretaryStallApplicationDetailsOpen
  } = useSelector(
    (state: {
      sidebar: SidebarActiveLinkReducer;
      trainerHorseDetailsPage: ITrainerHorseDetailsPageState;
      vetHorseDetailsPage: IVetHorseDetailsPageState;
      vetExam: IVetExamState;
      racingSecretaryStallApplication: IRacingSecretaryStallApplicationState;
    }) => {
      const {
        sidebar,
        trainerHorseDetailsPage,
        vetHorseDetailsPage,
        vetExam,
        racingSecretaryStallApplication
      } = state;

      return {
        activeLink: sidebar.activeLink,
        shouldBackArrowBeShownByCustomReason: sidebar.shouldBackArrowBeShown,
        isOpenPreferredConditions:
          trainerHorseDetailsPage.preferredConditionsState,
        isOpenMapOnTrainerHorseDetails: trainerHorseDetailsPage.mapPopupState,
        isOpenVetExamPage: vetExam.isExamActive,
        isOpenMapOnVetHorseDetails: vetHorseDetailsPage.mapPopupState,
        isRacingSecretaryStallApplicationDetailsOpen:
          racingSecretaryStallApplication.stallApplicationDetailsState
      };
    }
  );

  const hasOpenPopupOnHorseDetails = [
    isOpenVetExamPage,
    isOpenPreferredConditions,
    isOpenMapOnTrainerHorseDetails,
    isOpenMapOnVetHorseDetails
  ].some(item => item);

  const showBackButton =
    (!hasOpenPopupOnHorseDetails &&
      !(
        matchRoute(
          location.pathname,
          [
            { path: activeLink },
            routes.workoutRequests,
            routes.workoutExams,
            routes.workoutPending,
            routes.workoutPassed,
            routes.workoutFailed
          ],
          true
        ) || location.pathname === "/"
      )) ||
    location.pathname === routes.account.path ||
    shouldBackArrowBeShownByCustomReason;

  const handleOpenVetExamModal = () => {
    dispatch(setVetExamModalState(true));
    dispatch(setVetExamModalType(EModalConfirmationTypes.PAUSE_OR_CLOSE));
  };

  const handleCancelClick = e => {
    if (!handleBlockableLinkClick(e)) {
      dispatch(goBack());
    }
  };

  const handleCloseRacingSecretaryStallApplication = () => {
    dispatch(setStallApplicationUnsavedChangesPopupState(true));
  };

  const getTitle = (): string => {
    let title = "";

    for (const route in routes) {
      if (matchRoute(location.pathname, [routes[route]])) {
        title = routes[route].moduleName;
      }
    }

    return title;
  };

  const toggleHamburgerMenu =
    (isOpen: boolean) => (event: React.MouseEvent) => {
      setMenuOpen(isOpen);
      event && event.preventDefault();
    };

  const renderHeaderLeftSide = () => {
    if (isOpenVetExamPage) {
      return (
        <Button
          className={classes.closeVetExamPage}
          onClick={handleOpenVetExamModal}
        >
          Close
        </Button>
      );
    }

    if (isRacingSecretaryStallApplicationDetailsOpen) {
      return (
        <Button
          className={classes.cancelButton}
          onClick={handleCloseRacingSecretaryStallApplication}
        >
          Cancel
        </Button>
      );
    }

    if (
      matchRoute(location.pathname, [
        currentUser.isRacingOfficial
          ? routes.stallApplicationNewForm
          : routes.stallApplicationTrainerForm
      ])
    ) {
      return (
        <Button className={classes.cancelButton} onClick={handleCancelClick}>
          Cancel
        </Button>
      );
    }

    if (showBackButton) {
      return <BackLink className={classes.logoBackIcon} />;
    }

    return (
      <IconButton
        className={classes.hamburgerButton}
        onClick={toggleHamburgerMenu(true)}
      >
        <MenuIcon className={classes.hamburgerIcon} />
      </IconButton>
    );
  };

  return (
    <div className={clsx(rootClassName, classes.appHeader)}>
      <section
        className={clsx(className, { [classes.hasBackButton]: showBackButton })}
      >
        <AppBar position="static" className={classes.appBar} elevation={0}>
          <Toolbar className={classes.toolbar} disableGutters={true}>
            <div className={classes.header}>
              <div className={classes.headerLeftSideBlock}>
                {renderHeaderLeftSide()}
              </div>

              <Typography
                component="h1"
                variant="h6"
                color="inherit"
                noWrap
                className={classes.title}
              >
                {getTitle()}
              </Typography>

              <div className={classes.headerRightSideBlock}>
                <AccountPageSlider />
              </div>
            </div>
          </Toolbar>
        </AppBar>
      </section>
      <SwipeableDrawer
        swipeAreaWidth={swipeAreaWidth}
        open={menuOpen}
        onClose={toggleHamburgerMenu(false)}
        onOpen={toggleHamburgerMenu(true)}
        anchor="left"
      >
        <AppSideBar
          className={classes.appSideBar}
          onLinkClick={toggleHamburgerMenu(false)}
        />
      </SwipeableDrawer>
    </div>
  );
};

export default withClickBlocker(AppHeader);
