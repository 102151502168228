export enum DateRangeType {
  Today = 1,
  Tomorrow = 2,
  Next3Days = 3,
  Custom = 4,
  AllDates = 5
}

export interface DateRangeISO {
  Type: DateRangeType;
  CustomDateRange?: string[]; ///ISO dates
}

export default interface DateRange {
  Type: DateRangeType;
  CustomDateRange?: Date[];
}
