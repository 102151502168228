import React, { useState, useEffect } from "react";
import clsx from "clsx";
import differenceInDays from "date-fns/differenceInDays";
import parseISO from "date-fns/parseISO";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import { Theme } from "@material-ui/core/styles";
import { racehorse360 } from "@tsg/1st-grpc-web";

import CollapsedList from "components/CollapsedList";
import Loader from "components/Loader";
import WorkoutRequiredDialogDaysOff from "components/WorkoutRequiredDialogDaysOff";
import { useRacehorse360Api } from "hooks/api";
import { AlertEnum } from "utils/horse";
import DaysOffTrackerItem from "./DaysOffTrackerItem";
import { SortOrder } from "interfaces/SortOrder";
import useStyles from "./styles";

export interface Props {
  horses: racehorse360.IHorse[];
  className?: string;
}

interface IAlertConfig {
  horse: racehorse360.IHorse;
  status: AlertEnum;
  limit: number;
  nextWorkout: racehorse360.IWorkoutRequest | undefined;
  daysOff: number | string;
}

const DaysOffTracker = (props: Props) => {
  const { horses, className } = props;
  const classes = useStyles();

  const matchesUpMd = useMediaQuery((theme: Theme) =>
    theme.breakpoints.up("md")
  );
  const [sortedHorses, setSortedHorses] = useState<racehorse360.IHorse[]>([]);
  const [alertConfig, setAlertConfig] = useState<IAlertConfig | null>(null);
  const [nearestWorkoutRequest, setNearestWorkoutRequest] = useState<Record<
    string,
    racehorse360.IWorkoutRequest[] | undefined
  > | null>(null);

  const { useListWorkoutRequests } = useRacehorse360Api();

  const { isLoading, isFetching } = useListWorkoutRequests(
    "DaysOffTracker",
    {
      query: {
        horseIds: sortedHorses.map(horse => horse.id),
        date: {
          range: {
            relative: {
              startDate: { value: 0 }
            }
          }
        },
        statuses: [
          racehorse360.WorkoutRequestStatus.WORKOUT_REQUEST_STATUS_REQUESTED,
          racehorse360.WorkoutRequestStatus.WORKOUT_REQUEST_STATUS_EXAM_PENDING,
          racehorse360.WorkoutRequestStatus.WORKOUT_REQUEST_STATUS_APPROVED
        ]
      },
      pagingOptions: {
        maxResults: 500
      },
      getOptions: {
        select: [
          "id",
          "facility.name",
          "facility.code",
          "date",
          "status",
          "hasComment",
          "horse.id",
          "horse.name"
        ],
        orderBy: [`date ${SortOrder.ASC}`]
      }
    },
    {
      enabled: sortedHorses.length > 0,
      onSuccess: (data: racehorse360.IListWorkoutRequestsResponse) => {
        const nearestWorkoutRequest = sortedHorses.reduce((result, horse) => {
          result[horse.id] = data.workoutRequests.filter(
            request => request.horse.id === horse.id
          )[0];
          return result;
        }, {});
        setNearestWorkoutRequest(
          nearestWorkoutRequest as Record<
            string,
            racehorse360.IWorkoutRequest[] | undefined
          >
        );
      },
      onError: error => console.error(error)
    }
  );

  const setWarningData = (horse, status, limit, nextWorkout, daysOff) => {
    setAlertConfig({ horse, status, limit, nextWorkout, daysOff } as any);
  };

  useEffect(() => {
    if (!horses?.length) {
      return;
    }

    const sortedHorses = horses
      .filter(horse => {
        return horse.lastRaceDate;
      })
      .map(horse => {
        const lastRaceDate = parseISO(horse.lastRaceDate);
        const days = differenceInDays(new Date(), lastRaceDate);

        return {
          ...horse,
          lastRace: days
        };
      })
      .sort(
        (horse1, horse2) => Number(horse2.lastRace) - Number(horse1.lastRace)
      );

    setSortedHorses(sortedHorses);
  }, [horses]);

  return (
    <>
      <div
        className={clsx(classes.root, className, {
          [classes.maxHeight]: matchesUpMd
        })}
      >
        <header className={classes.header}>Days Since Last Race</header>
        <section className={clsx(classes.list)}>
          <section className={clsx({ [classes.scrollable]: matchesUpMd })}>
            {isLoading || isFetching ? (
              <Loader />
            ) : (
              <CollapsedList
                minToShow={5}
                maxToShow={10}
                simpleList={matchesUpMd}
              >
                {sortedHorses.map(horse => (
                  <DaysOffTrackerItem
                    horse={horse}
                    key={horse.id}
                    nextWorkout={nearestWorkoutRequest[horse.id]}
                    setWarningData={setWarningData}
                  />
                ))}
              </CollapsedList>
            )}
          </section>
        </section>
      </div>
      {alertConfig && (
        <WorkoutRequiredDialogDaysOff
          open={!!alertConfig}
          daysOff={alertConfig.daysOff}
          horse={alertConfig.horse}
          onClose={() => setAlertConfig(null)}
          status={alertConfig.status}
          limit={alertConfig.limit}
          nextWorkout={alertConfig.nextWorkout}
        />
      )}
    </>
  );
};

export default React.memo(DaysOffTracker);
