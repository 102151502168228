import React, { useCallback, useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Theme } from "@material-ui/core/styles";
import Grid from "@material-ui/core/Grid";
import useMediaQuery from "@material-ui/core/useMediaQuery";

import { useWindowSize } from "hooks/screen";
import { useDebouncedNameValue } from "hooks/useSearch";
import ErrorBoundary from "components/ErrorBoundary";
import FacilitySelect from "components/FacilitySelect";
import Weather from "components/Weather";
import WorkoutSettings from "components/WorkoutSettings";
import { racehorse360 } from "@tsg/1st-grpc-web";
import useStyles from "./styles";
import WorkoutsPageState from "interfaces/WorkoutsPageState";
import {
  setShowWeather,
  setWeatherHeight,
  setSearchValue
} from "store/actions/workoutsPage";
import { useLoggedInUser } from "components/LoggedInUserProvider";
import AppSearch from "components/AppSearch";
import { AppSearchType } from "components/AppSearch/AppSearch";

export interface Props {
  onFacilityChange: (facility: racehorse360.IFacility) => void;
  selectedFacility: racehorse360.IFacility;
  children?: React.ReactNode;
  clearSearch?: boolean;
  onClearSearch?: (value: boolean) => void;
}

const WorkoutHeader = (props: Props) => {
  const {
    onFacilityChange,
    selectedFacility,
    children,
    clearSearch,
    onClearSearch
  } = props;

  const classes = useStyles();
  const dispatch = useDispatch();

  const { showWeather, isCondensedView, scheduledDatesOnly, searchValue } =
    useSelector(
      (state: { workoutsPage: WorkoutsPageState }) => state?.workoutsPage
    );
  const handleShowWeatherChange = useCallback(
    show => {
      dispatch(setShowWeather(show));
    },
    [dispatch]
  );
  const headerRef = useRef(null);
  const mobileResultRef = React.useRef(null); // ref for portal
  const matchesXS = useMediaQuery((theme: Theme) =>
    theme.breakpoints.down("xs")
  );
  const [query, setQuery] = useState<string>(searchValue || "");
  const { width, height } = useWindowSize();
  const { currentUser } = useLoggedInUser();

  const facilityInstructions =
    "Set a track to schedule your workouts. Selecting a new track\n" +
    "will not affect your current schedule.";

  const searchQuery = useDebouncedNameValue(query, { timeout: 700 });

  const handleChangeFacility = facility => {
    onFacilityChange(facility);
  };

  const handleAppSearch = (value: string) => {
    setQuery(value);
    if (matchesXS && showWeather) {
      handleShowWeatherChange(!value?.length);
      recountHeight();
    }
  };

  const recountHeight = useCallback(() => {
    setTimeout(() => {
      const height = headerRef.current?.clientHeight;
      dispatch(setWeatherHeight(height));
    });
  }, [dispatch, headerRef]);

  useEffect(() => {
    dispatch(setSearchValue(searchQuery));
  }, [searchQuery]);

  useEffect(() => {
    recountHeight();
  }, [width, height, recountHeight, showWeather]);

  useEffect(() => {
    if (isCondensedView) {
      recountHeight();
    }
  }, [scheduledDatesOnly, recountHeight, isCondensedView]);

  return (
    <div ref={headerRef} className={classes.root}>
      <Grid
        container
        alignItems="center"
        classes={{
          root: classes.gridRoot
        }}
      >
        <Grid item xs={12} sm={12} md={4}>
          <ErrorBoundary>
            <FacilitySelect
              isActive={true}
              selectedFacility={selectedFacility}
              onChange={handleChangeFacility}
              instructions={facilityInstructions}
            />
          </ErrorBoundary>
        </Grid>

        <Grid item xs={10} sm={8} md={4} className={classes.appSearchCell}>
          <ErrorBoundary>
            <AppSearch
              isForceSearch
              placeholder={
                currentUser.isTrainer
                  ? `Search Horses`
                  : `Search Horses and Trainers`
              }
              searchType={
                currentUser.isTrainer
                  ? AppSearchType.Horses
                  : AppSearchType.Both
              }
              className={classes.appSearch}
              onSearch={handleAppSearch}
              clearSearch={clearSearch}
              onClearSearch={onClearSearch}
              value={searchQuery}
            />
          </ErrorBoundary>
        </Grid>

        <Grid item xs={2} sm={4} md={4}>
          <ErrorBoundary>
            <WorkoutSettings />
          </ErrorBoundary>
        </Grid>

        {/* PORTAL for search result */}
        {matchesXS && (
          <div ref={mobileResultRef} className={classes.searchResult} />
        )}

        {!isCondensedView && showWeather && (
          <Grid item xs={12} classes={{ root: classes.gridItemScrollable }}>
            <ErrorBoundary>
              <Weather
                selectedFacility={selectedFacility}
                onChange={recountHeight}
                className={classes.weather}
              />
            </ErrorBoundary>
          </Grid>
        )}
      </Grid>
      {Boolean(children) && <>{children}</>}
    </div>
  );
};

export default React.memo(WorkoutHeader);
