import React from "react";
import clsx from "clsx";

import Divider from "@material-ui/core/Divider";

import ScrollSyncPanel from "components/ScrollSync/ScrollSyncPanel";
import SortLabel from "components/SortLabel";
import { SortOrderType, SortOrder } from "interfaces/SortOrder";
import { checkSortingType } from "utils/sort-utils";
import { checkCurrentWorkoutsTableTab, WorkoutsTableTab } from "../../helper";
import useStyles from "./styles";
import useSharedStyle from "../styles";

interface IProps {
  riskFactorColumnWidth: number;
  selectedTab: WorkoutsTableTab;
  order: SortOrderType;
  orderBy: string;
  onSort: (columnName: string, sortDirection: SortOrderType) => void;
}

const TableHeader = (props: IProps) => {
  const { riskFactorColumnWidth, selectedTab, order, orderBy, onSort } = props;

  const classes = useStyles();
  const sharedClasses = useSharedStyle({ riskFactorColumnWidth });
  const { isFailedTab, isPendingTab, isPassedTab, isRequestsTab, isExamsTab } =
    checkCurrentWorkoutsTableTab(selectedTab);
  const shouldShowAssignedVetColumn = [isPassedTab, isFailedTab].some(Boolean);

  const getSortDirection = (columnName: string) => {
    const { isOneOfAsc } = checkSortingType(order);

    if (orderBy === columnName) {
      return isOneOfAsc ? SortOrder.ASC : SortOrder.DESC;
    }
  };

  const handleSort = (columnName: string, sortDirection: SortOrder) => {
    const { isAsc } = checkSortingType(sortDirection);
    const sortOrder = isAsc ? SortOrder.ASC : SortOrder.DESC;

    onSort(columnName, sortOrder);
  };

  return (
    <>
      <div className={classes.tableHeaderMenu}>
        {isRequestsTab && (
          <div className={classes.tableHeaderMenuContent}>
            <div className={classes.tableHeaderMenuColumns}>
              <div className={classes.tableHeaderMenuColumn}>Exam</div>
              <div className={classes.tableHeaderMenuColumn}>Pass</div>
              <div className={classes.tableHeaderMenuColumn}>Reject</div>
            </div>
          </div>
        )}
        {isExamsTab && (
          <div className={classes.tableHeaderMenuContent}>
            <div className={classes.tableHeaderMenuColumns}>
              <div className={classes.tableHeaderMenuColumn}>Assign</div>
            </div>
          </div>
        )}
      </div>

      <div className={classes.tableHeader}>
        <ScrollSyncPanel className={classes.tableHeaderContainer}>
          <div
            className={clsx(classes.tableHeaderContent, {
              [sharedClasses.rowContentRequests]: isRequestsTab,
              [sharedClasses.rowContentExams]: isExamsTab,
              [sharedClasses.rowContentPending]: isPendingTab,
              [sharedClasses.rowContentPassed]: isPassedTab,
              [sharedClasses.rowContentFailed]: isFailedTab
            })}
          >
            <div className={classes.tableHeaderCell}>
              <SortLabel
                columnName={"horse.name"}
                sortDirection={getSortDirection("horse.name")}
                onSort={handleSort}
              >
                Horse
              </SortLabel>
              <Divider
                className={classes.tableColumnDivider}
                orientation="vertical"
                flexItem
              />
              <SortLabel
                columnName={"horse.trainer.lastName"}
                sortDirection={getSortDirection("horse.trainer.lastName")}
                onSort={handleSort}
              >
                Trainer
              </SortLabel>
            </div>
            {(isRequestsTab || isExamsTab) && (
              <div
                className={clsx(
                  classes.tableHeaderCell,
                  classes.tableHeaderCellCentered
                )}
              >
                History
              </div>
            )}
            <div
              className={clsx(
                classes.tableHeaderCell,
                classes.tableHeaderCellCentered
              )}
            >
              <SortLabel
                columnName={"date"}
                sortDirection={getSortDirection("date")}
                onSort={handleSort}
              >
                Date
              </SortLabel>
            </div>
            <div
              className={clsx(
                classes.tableHeaderCell,
                classes.tableHeaderCellCentered
              )}
            >
              <SortLabel
                columnName={"horse.healthWatchStatus"}
                sortDirection={getSortDirection("horse.healthWatchStatus")}
                onSort={handleSort}
              >
                Risk Level
              </SortLabel>
            </div>
            {isExamsTab && (
              <div
                className={clsx(classes.tableHeaderCell, classes.riskFactors)}
              >
                <SortLabel
                  columnName={"isFlagged"}
                  sortDirection={getSortDirection("isFlagged")}
                  onSort={handleSort}
                >
                  Flag Request
                </SortLabel>
              </div>
            )}
            <div
              className={clsx(
                classes.tableHeaderCell,
                classes.tableHeaderCellCentered
              )}
            >
              <SortLabel
                columnName={"horse.barn.name"}
                sortDirection={getSortDirection("horse.barn.name")}
                onSort={handleSort}
              >
                Barn #
              </SortLabel>
            </div>
            {isPendingTab && (
              <div
                className={clsx(
                  classes.tableHeaderCell,
                  classes.tableHeaderCellCentered
                )}
              >
                Reason
              </div>
            )}
            {isPassedTab && (
              <div className={classes.tableHeaderCell}>Exam Type</div>
            )}
            {shouldShowAssignedVetColumn && (
              <div className={classes.tableHeaderCell}>Assigned Vet</div>
            )}
            <div
              className={clsx(
                classes.tableHeaderCell,
                classes.tableHeaderCellCentered
              )}
              data-test={"age-sex-column-header"}
            >
              C.S.A
            </div>
            <div
              className={clsx(
                classes.tableHeaderCell,
                classes.tableHeaderCellCentered
              )}
            >
              <SortLabel
                columnName={"highSpeedFurlongs"}
                sortDirection={getSortDirection("highSpeedFurlongs")}
                onSort={handleSort}
              >
                HSF
              </SortLabel>
            </div>
            <div
              className={clsx(
                classes.tableHeaderCell,
                classes.tableHeaderCellCentered
              )}
            >
              <SortLabel
                columnName={"horse.lastWorkoutDate"}
                sortDirection={getSortDirection("horse.lastWorkoutDate")}
                onSort={handleSort}
              >
                DLW
              </SortLabel>
            </div>
            <div
              className={clsx(
                classes.tableHeaderCell,
                classes.tableHeaderCellCentered
              )}
            >
              <SortLabel
                columnName={"horse.lastRaceDate"}
                sortDirection={getSortDirection("horse.lastRaceDate")}
                onSort={handleSort}
              >
                DLR
              </SortLabel>
            </div>
            <div
              className={clsx(
                classes.tableHeaderCell,
                classes.tableHeaderCellCentered
              )}
            >
              <SortLabel
                columnName={"horse.lastExamDate"}
                sortDirection={getSortDirection("horse.lastExamDate")}
                onSort={handleSort}
              >
                Last Exam
              </SortLabel>
            </div>
            {isRequestsTab && (
              <div
                className={clsx(classes.tableHeaderCell, classes.riskFactors)}
              >
                <SortLabel
                  columnName={"isFlagged"}
                  sortDirection={getSortDirection("isFlagged")}
                  onSort={handleSort}
                >
                  Flag Request
                </SortLabel>
              </div>
            )}
            {isRequestsTab && (
              <>
                <div
                  className={clsx(classes.tableHeaderCell, classes.riskFactors)}
                >
                  <SortLabel
                    columnName={"horse.activeVetListsCount"}
                    sortDirection={getSortDirection(
                      "horse.activeVetListsCount"
                    )}
                    onSort={handleSort}
                  >
                    Vet Lists
                  </SortLabel>
                </div>
                <div
                  className={clsx(classes.tableHeaderCell, classes.riskFactors)}
                >
                  <SortLabel
                    columnName={"horse.riskFactorsCount"}
                    sortDirection={getSortDirection("horse.riskFactorsCount")}
                    onSort={handleSort}
                  >
                    Risk Factors
                  </SortLabel>
                </div>
              </>
            )}
            {isExamsTab && (
              <>
                <div className={classes.tableHeaderCell}>Assigned Vet</div>
                <div
                  className={clsx(
                    classes.tableHeaderCell,
                    classes.tableHeaderCellCentered
                  )}
                >
                  Un-Assign
                </div>
                <div
                  className={clsx(
                    classes.tableHeaderCell,
                    classes.tableHeaderCellCentered
                  )}
                >
                  Exam
                </div>
                <div
                  className={clsx(classes.tableHeaderCell, classes.riskFactors)}
                >
                  <SortLabel
                    columnName={"horse.activeVetListsCount"}
                    sortDirection={getSortDirection(
                      "horse.activeVetListsCount"
                    )}
                    onSort={handleSort}
                  >
                    Vet Lists
                  </SortLabel>
                </div>
                <div
                  className={clsx(classes.tableHeaderCell, classes.riskFactors)}
                >
                  <SortLabel
                    columnName={"horse.riskFactorsCount"}
                    sortDirection={getSortDirection("horse.riskFactorsCount")}
                    onSort={handleSort}
                  >
                    Risk Factors
                  </SortLabel>
                </div>
              </>
            )}
            <div
              className={clsx(classes.tableHeaderCell, {
                [sharedClasses.lastTwoColumnsCell]: isRequestsTab,
                [sharedClasses.lastOneColumnCell]: isExamsTab
              })}
            >
              &nbsp;
            </div>
          </div>
        </ScrollSyncPanel>
      </div>

      <div className={classes.tableHeaderShadow} />
    </>
  );
};

export default TableHeader;
