import { makeStyles } from "@material-ui/core/styles";
import Breakpoints from "common/breakpoints";
import { MuiTheme } from "theme";

export default makeStyles((theme: MuiTheme) => {
  const {
    zIndex,
    palette: { secondary, text }
  } = theme;

  return {
    logoButton: {
      height: 64,
      width: 220,
      flex: "auto",
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
      backgroundColor: secondary.main,
      borderRadius: 0,
      borderBottom: `1px solid #333`,
      zIndex: zIndex.app.logo,
      position: "fixed",
      top: 0,
      "&:hover": {
        backgroundColor: secondary.main
      },
      [theme.breakpoints.down(Breakpoints.MD_960)]: {
        width: 84
      },
      [theme.breakpoints.down(Breakpoints.SM_600)]: {
        height: 134,
        width: "100%",
        backgroundColor: secondary.main,
        borderColor: "transparent"
      }
    },
    logoIcon: {
      width: 50,
      height: 49,
      color: text.primary,
      [theme.breakpoints.down(Breakpoints.SM_600)]: {
        fontSize: 107,
        width: "1em",
        height: "1em"
      }
    }
  };
});
