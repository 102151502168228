import React from "react";
import format from "date-fns/format";
import parseISO from "date-fns/parseISO";

import { CustomWorkoutRequest } from "interfaces/VetWorkoutsPageState";

import useStyles from "./styles";

interface IProps {
  selectedWorkoutRequest: CustomWorkoutRequest;
}

const PopoverBodyComments = (props: IProps) => {
  const { selectedWorkoutRequest } = props;
  const classes = useStyles();

  return (
    <div className={classes.wrReqCommentsContainer}>
      <div className={classes.wrReqCommentsList}>
        {selectedWorkoutRequest.commentsList?.map(comment => {
          const { id, updatedOn, createdBy, body } = comment;
          return (
            <div className={classes.wrReqCommentsListItem} key={id}>
              <div className={classes.wrReqCommentsContainerHeader}>
                <div className={classes.commentCreator}>
                  {`${createdBy.firstName} ${createdBy.lastName}`}
                </div>
                <div className={classes.createDateTime}>
                  {format(parseISO(updatedOn), "MMM d, yyyy - h:mm a")}
                </div>
              </div>
              <div className={classes.wrReqCommentsContainerBody}>{body}</div>
            </div>
          );
        })}
      </div>
    </div>
  );
};

export default PopoverBodyComments;
