import { createTheme, Theme } from "@material-ui/core/styles";
import { ZIndex } from "@material-ui/core/styles/zIndex";
import SuisseIntlRegular2 from "assets/fonts/SuisseIntl-Regular.woff2";
import SuisseIntlRegular from "assets/fonts/SuisseIntl-Regular.woff";
import SuisseIntlMedium2 from "assets/fonts/SuisseIntl-Medium.woff2";
import SuisseIntlMedium from "assets/fonts/SuisseIntl-Medium.woff";
import ArialNarrow from "assets/fonts/Arial-Narrow.woff";
import ArialNarrow2 from "assets/fonts/Arial-Narrow.woff2";
import ArialNarrowBold from "assets/fonts/Arial-Narrow-Bold.woff";
import ArialNarrowBold2 from "assets/fonts/Arial-Narrow-Bold.woff2";
import Breakpoints from "common/breakpoints";
import EMediaDevices from "common/mediaDevices";
import { AlertClassKey } from "@material-ui/lab/Alert";

declare module "@material-ui/core/styles/overrides" {
  export interface ComponentNameToClassKey {
    MuiAlert: AlertClassKey;
  }
}

export interface MuiZIndex extends ZIndex {
  app: {
    splashScreen: number;
    logo: number;
    footer: number;
    header: number;
    sidebar: number;
  };
  page: {
    overlay: number;
    header: number;
    content: number;
  };
}
export interface MuiMediaDevices {
  hover: (key: EMediaDevices) => string;
  pointer: (key: EMediaDevices) => string;
}
export interface MuiTheme extends Theme {
  widthDesktopDrawer: number;
  widthTabletDrawer: number;
  widthMobileDrawer: number;
  heightDesktopHeader: number;
  heightMobileHeader: number;
  heightDesktopPageHeader: number;
  heightFooter: number;
  zIndex: MuiZIndex;
  mediaDevices: MuiMediaDevices;
}

const palette = {
  primary: {
    main: "#000000",
    dark: "#7f7f7f",
    light: "#bababa"
  },
  secondary: {
    main: "#03f3b2",
    dark: "#61c69e"
  },
  text: {
    primary: "#000000",
    secondary: "#7f7f7f",
    disabled: "#bababa",
    hint: "#bababa"
  },
  error: {
    main: "#d82f44"
  },
  warning: {
    light: "#f0ac00",
    main: "#e35500",
    dark: "#d82f44",
    contrastText: "#e35500",
    accent: "#be4400"
  },
  divider: "#bababa"
};

const theme = createTheme({
  typography: {
    fontFamily: [
      "SuisseIntlRegular",
      "Roboto",
      "Helvetica Neue",
      "Arial",
      "sans-serif"
    ].join(",")
  },
  palette,
  overrides: {
    MuiSnackbar: {
      root: {
        left: 0,
        right: 0
      }
    },
    MuiAlert: {
      icon: {
        alignItems: "center"
      },
      message: {
        fontSize: 16
      },
      filledError: {},
      filledSuccess: {
        backgroundColor: "rgba(72, 155, 119, 1)"
      }
    },
    MuiGrid: {
      "spacing-xs-1": {
        width: "calc(100% + 10px)",
        margin: -5,
        "& > .MuiGrid-item": {
          padding: 5
        }
      },
      "spacing-xs-2": {
        width: "calc(100% + 20px)",
        margin: -10,
        "& > .MuiGrid-item": {
          padding: 10
        }
      }
    },
    MuiListItem: {
      divider: {
        borderBottomColor: "#EEE"
      }
    },
    MuiTypography: {
      root: {
        color: palette.text.primary
      },
      colorTextSecondary: {
        color: palette.text.secondary
      }
    },
    MuiPaper: {
      elevation1: {
        boxShadow: "0px 1px 7px 0 rgba(0,0,0,0.1)"
      },
      elevation24: {
        boxShadow: "0px 3px 24px 0px rgba(0, 0, 0, .3)"
      }
    },
    MuiInput: {
      input: {
        "&[type=number]": {
          "-moz-appearance": "textfield",
          "&::-webkit-inner-spin-button, &::-webkit-outer-spin-button": {
            "-webkit-appearance": "none"
          }
        }
      }
    },
    MuiInputBase: {
      input: {
        appearance: "none"
      },
      inputTypeSearch: {
        appearance: "none",
        borderRadius: 3
      }
    },
    MuiSwitch: {
      thumb: {
        boxShadow: "0px 1px 3px 0px rgba(0,0,0,0.25)"
      },
      switchBase: {
        color: palette.text.secondary
      },
      track: {
        backgroundColor: "#eeeeee",
        opacity: 1
      },
      colorPrimary: {
        color: palette.text.secondary
      },
      colorSecondary: {
        "&$checked": {
          color: "#489b77"
        }
      },
      root: {
        color: "inherit"
      }
    },
    MuiDivider: {
      root: {
        backgroundColor: "#bababa"
      },
      light: {
        backgroundColor: "#eeeeee"
      }
    },
    MuiAccordion: {
      root: {
        "&:before": {
          display: "none"
        },
        "&.Mui-expanded": {
          margin: 0
        }
      },
      rounded: {
        borderRadius: 3,
        "&:last-child": {
          borderBottomLeftRadius: 3,
          borderBottomRightRadius: 3
        }
      }
    },
    MuiAccordionSummary: {
      root: {
        minHeight: 38,
        "&.Mui-expanded": {
          minHeight: 38,
          borderBottom: "1px solid rgba(186, 186, 186, 1)"
        }
      },
      content: {
        minHeight: 38,
        margin: 0,
        "&.Mui-expanded": {
          margin: "0",
          marginBottom: -1
        }
      }
    },
    MuiTableRow: {
      root: {
        "&:last-child .MuiTableCell-root": {
          borderBottom: 0
        },
        "&:nth-child(2n)": {
          background: "rgba(238, 238, 238, 0.25)"
        },
        "&$hover:hover, &$selected:hover": {
          backgroundColor: "rgba(97, 198, 158, 0.15)"
        },
        "&$selected": {
          backgroundColor: "rgba(97, 198, 158, 0.15)",
          fontFamily: "SuisseIntlMedium, SuisseIntlRegular",
          "& .MuiTableCell-root": {
            fontFamily: "SuisseIntlMedium, SuisseIntlRegular",
            color: "#103827"
          }
        }
      }
    },
    MuiTableCell: {
      root: {
        borderBottom: "1px solid #eeeeee",
        padding: "8px 16px"
      }
    },

    MuiCssBaseline: {
      "@global": {
        "*": {
          boxSizing: "border-box",
          scrollbarWidth: "thin",
          scrollbarColor: "#bababa #eeeeee",
          "&:hover::-webkit-scrollbar": {
            visibility: "visible"
          },
          "&::-webkit-scrollbar": {
            visibility: "hidden",
            height: 7,
            width: 7,
            backgroundColor: "#eeeeee"
          },
          "&:hover::-webkit-scrollbar-thumb": {
            visibility: "visible"
          },
          "&::-webkit-scrollbar-thumb": {
            visibility: "hidden",
            borderRadius: 4,
            background: "rgba(186, 186, 186, 0.75)"
          }
        },
        "html, body": {
          height: "100%",
          minHeight: "100%",
          background: 'url("/rh360-main-bg.png")'
        },
        "#root": {
          height: "100%"
        },
        "@font-face": [
          {
            fontFamily: "SuisseIntlRegular",
            fontStyle: "normal",
            fontDisplay: "swap",
            fontWeight: 400,
            src: `
            local('SuisseIntlRegular'),
            local('SuisseIntl-Regular'),
            url(${SuisseIntlRegular2}) format('woff2'),
            url(${SuisseIntlRegular}) format('woff')
          `,
            unicodeRange:
              "U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF"
          },
          {
            fontFamily: "SuisseIntlMedium",
            fontStyle: "normal",
            fontDisplay: "swap",
            fontWeight: 400,
            src: `
            local('SuisseIntlMedium'),
            local('SuisseIntl-Medium'),
            url(${SuisseIntlMedium2}) format('woff2'),
            url(${SuisseIntlMedium}) format('woff')
          `,
            unicodeRange:
              "U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF"
          },
          {
            fontFamily: "ArialNarrow",
            fontStyle: "normal",
            fontDisplay: "swap",
            fontWeight: 400,
            src: `
            local('ArialNarrow'),
            local('Arial-Narrow'),
            url(${ArialNarrow2}) format('woff2'),
            url(${ArialNarrow}) format('woff')
          `,
            unicodeRange:
              "U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF"
          },
          {
            fontFamily: "ArialNarrowBold",
            fontStyle: "normal",
            fontDisplay: "swap",
            fontWeight: 400,
            src: `
            local('ArialNarrowBold'),
            local('Arial-Narrow-Bold'),
            url(${ArialNarrowBold2}) format('woff2'),
            url(${ArialNarrowBold}) format('woff')
          `,
            unicodeRange:
              "U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF"
          }
        ]
      }
    }
  }
});

type MediaBreakpoints = number | "xl" | "lg" | "md" | "sm" | "sx" | "xs" | "ss";

theme.breakpoints.down = (key: MediaBreakpoints) => {
  if (key === "lg" || key === Breakpoints.LG_1280) {
    return "@media (max-width: 1919px)";
  } else if (key === "md" || key === Breakpoints.MD_960) {
    return "@media (max-width: 1279px)";
  } else if (key === "sm" || key === Breakpoints.SX_720) {
    return "@media (max-width: 959px)";
  } else if (key === "sx" || key === Breakpoints.SM_600) {
    return "@media (max-width: 719px)";
  } else if (key === "xs" || key === Breakpoints.XS_420) {
    return "@media (max-width: 599px)";
  } else if (key === "ss" || key === Breakpoints.SS_320) {
    return "@media (max-width: 419px)";
  } else {
    return "@media (max-width: 319px)";
  }
};

theme.breakpoints.up = (key: MediaBreakpoints) => {
  if (key === "xl" || key === Breakpoints.XL_1920) {
    return "@media (min-width: 1920px)";
  } else if (key === "lg" || key === Breakpoints.LG_1280) {
    return "@media (min-width: 1280px)";
  } else if (key === "md" || key === Breakpoints.MD_960) {
    return "@media (min-width: 960px)";
  } else if (key === "sx" || key === Breakpoints.SX_720) {
    return "@media (min-width: 720px)";
  } else if (key === "sm" || key === Breakpoints.SM_600) {
    return "@media (min-width: 600px)";
  } else if (key === "xs" || key === Breakpoints.XS_420) {
    return "@media (min-width: 420px)";
  } else if (key === "ss" || key === Breakpoints.SS_320) {
    return "@media (min-width: 320px)";
  } else {
    return "@media (min-width: 0px)";
  }
};

const mediaDevices: MuiMediaDevices = {
  hover: (key: EMediaDevices) => {
    switch (key) {
      case EMediaDevices.HOVER:
        return "@media (hover: hover)";
      case EMediaDevices.NONE:
        return "@media (hover: none) ";
    }
  },
  pointer: (key: EMediaDevices) => {
    switch (key) {
      case EMediaDevices.COARSE:
        return "@media (pointer: coarse)";
      case EMediaDevices.FINE:
        return "@media (pointer: fine)";
      case EMediaDevices.NONE:
        return "@media (pointer: none)";
      case EMediaDevices.ALL:
        return "@media (hover: none), (pointer: coarse), (pointer: fine)";
    }
  }
};

const cssVariables = {
  widthDesktopDrawer: 220,
  widthTabletDrawer: 84,
  widthMobileDrawer: 54,
  heightDesktopHeader: 64,
  heightMobileHeader: 56,
  heightDesktopPageHeader: 60,
  heightFooter: 90,
  zIndex: {
    ...theme.zIndex,
    app: {
      splashScreen: theme.zIndex.drawer + 50,
      logo: theme.zIndex.drawer + 30,
      footer: theme.zIndex.drawer + 20,
      header: theme.zIndex.drawer + 20,
      sidebar: theme.zIndex.drawer + 10
    },
    page: {
      overlay: theme.zIndex.drawer + 40,
      header: theme.zIndex.drawer + 30,
      content: theme.zIndex.drawer + 15
    }
  }
};

export { theme, cssVariables, mediaDevices };
