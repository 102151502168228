import React, { useState } from "react";
import clsx from "clsx";

import { Theme } from "@material-ui/core/styles";
import useMediaQuery from "@material-ui/core/useMediaQuery";

import { racehorse360 } from "@tsg/1st-grpc-web";

import CommentsHeader from "components/Comments/CommentsHeader";
import { ICommentData } from "components/Comments/Comment/Comment";
import { ECommentDisplayingVariant } from "components/Comments/Comment/helper";
import Comment from "components/Comments/Comment";
import Loader from "components/Loader";
import Pagination from "components/Pagination";
import { useRacehorse360Api } from "hooks/api";
import HorseGeneralVetCommentEditor from "./HorseGeneralVetCommentEditor";
import HorseGeneralVetComment from "./HorseGeneralVetComment";
import HorseFOTComment from "./HorseFOTComment";
import { commentKinds } from "./helper";

import useStyles from "./styles";

interface Props {
  horse: racehorse360.IHorse;
  commentsList: racehorse360.IHorseAggregatedComment[];
  refetchHorseWarningMessage: () => void;
  refetchComments: () => void;
  isCommentsFetching: boolean;
  isFetchingNextPage?: boolean;
}

const PAGE_SIZE = 5;

const Comments = (props: Props) => {
  const {
    horse,
    isCommentsFetching,
    isFetchingNextPage = false,
    commentsList,
    refetchHorseWarningMessage,
    refetchComments
  } = props;
  const classes = useStyles();
  const matchesUpMd = useMediaQuery((theme: Theme) =>
    theme.breakpoints.up("md")
  );

  const [pageNumber, setPageNumber] = useState<number>(1);
  const [editingCommentId, setEditingCommentId] = useState<string>(null);

  const { useDeleteHorseGeneralVetComment, useUpdateHorseGeneralVetComment } =
    useRacehorse360Api();

  const {
    mutateAsync: deleteHorseGeneralVetComment,
    isLoading: isLoadingDeleteHorseGeneralVetComment
  } = useDeleteHorseGeneralVetComment({
    onError: error => {
      console.error(error);
    }
  });

  const {
    mutateAsync: updateHorseGeneralVetComment,
    isLoading: isLoadingUpdateHorseGeneralVetComment
  } = useUpdateHorseGeneralVetComment({
    onError: error => {
      console.error(error);
    }
  });

  const handleDeleteHorseGeneralVetComment = (commentId: string) => {
    deleteHorseGeneralVetComment({
      id: commentId
    })
      .then(() => {
        refetchHorseWarningMessage();
      })
      .then(() => {
        refetchComments();
      });
  };

  const handleUpdateHorseGeneralVetComment = (
    commentId: string,
    commentText: string,
    warningMessage: string
  ) => {
    if (commentText?.trim().length) {
      updateHorseGeneralVetComment({
        horseGeneralVetComment: {
          id: commentId,
          body: commentText?.trim(),
          warningMessage: warningMessage?.trim()
        },
        updateMask: {
          paths: ["body", "warningMessage"]
        }
      })
        .then(() => {
          const recentCommentWithWarning = commentsList.filter(
            comment => comment.warningMessage
          )[0];

          if (recentCommentWithWarning.warningMessage !== warningMessage) {
            refetchHorseWarningMessage();
          }
        })
        .then(() => {
          refetchComments();
        });
    }
  };

  const handleEditHorseGeneralVetCommentStart = (commentId: string) => {
    setEditingCommentId(commentId);
  };

  const handleEditHorseGeneralVetCommentFinish = () => {
    setEditingCommentId(null);
  };

  const renderComment = (
    comment: racehorse360.IHorseAggregatedComment,
    index: number
  ) => {
    const commentData: ICommentData = {
      id: comment.id,
      content: comment.body,
      warningMessage: comment.warningMessage,
      commentIconType: comment.horseAggregatedCommentIconType,
      isRead: comment.isRead,
      eventType: comment.eventType,
      commenterId: comment.commenterId,
      commenterLastName: comment.commenterLastName,
      commenterFirstName: comment.commenterFirstName,
      eventDate: comment.eventDate,
      createdOn: comment.createdOn
    };

    switch (comment.commentSourceKind) {
      case commentKinds.vetComment:
        return (
          <HorseGeneralVetComment
            key={comment.id}
            className={clsx(classes.comment, {
              [classes.oddComment]: index % 2
            })}
            isEditing={comment.id === editingCommentId}
            data={comment}
            onSave={handleUpdateHorseGeneralVetComment}
            onDelete={handleDeleteHorseGeneralVetComment}
            onEditStart={handleEditHorseGeneralVetCommentStart}
            onEditFinish={handleEditHorseGeneralVetCommentFinish}
          />
        );
      case commentKinds.FOTComment:
        return (
          <HorseFOTComment
            key={comment.id}
            className={clsx(classes.comment, {
              [classes.oddComment]: index % 2
            })}
            data={comment}
          />
        );
      default:
        return (
          <Comment
            key={comment.id}
            className={clsx(classes.comment, {
              [classes.oddComment]: index % 2
            })}
            shouldDisplayIsReadStatus={false}
            data={commentData}
            variant={ECommentDisplayingVariant.COMMENT_FOR_VETERINARIAN}
          />
        );
    }
  };

  const renderComments = (
    commentsList: racehorse360.IHorseAggregatedComment[]
  ) => {
    if (!commentsList?.length) {
      return (
        <section className={classes.noComments}>
          No Comments for {horse.name}
        </section>
      );
    }

    return commentsList.map(
      (comment: racehorse360.IHorseAggregatedComment, index: number) => {
        return renderComment(comment, index);
      }
    );
  };

  const renderContentUpMd = () => {
    const sliced = commentsList.slice(
      (pageNumber - 1) * PAGE_SIZE,
      (pageNumber - 1) * PAGE_SIZE + PAGE_SIZE
    );

    return (
      <>
        {renderComments(sliced)}

        {commentsList?.length > 0 && (
          <div className={classes.footer}>
            <Pagination
              totalCount={commentsList.length}
              onPageChange={setPageNumber}
              pageSize={PAGE_SIZE}
              pageNumber={pageNumber}
            />
          </div>
        )}
      </>
    );
  };

  const renderContentDownMd = () => {
    return (
      <div className={classes.scrollContainer}>
        {renderComments(commentsList)}
      </div>
    );
  };

  const renderLoader = () => {
    const isLoading = [
      isCommentsFetching,
      isFetchingNextPage,
      isLoadingDeleteHorseGeneralVetComment,
      isLoadingUpdateHorseGeneralVetComment
    ].some(Boolean);

    return (
      isLoading && (
        <Loader
          className={clsx(classes.loaderFetching, {
            [classes.showInfiniteLoader]: isFetchingNextPage
          })}
        />
      )
    );
  };

  return (
    <section
      className={clsx(classes.root, { [classes.rootSticky]: matchesUpMd })}
    >
      <CommentsHeader
        shouldDisplayUnreadCount={false}
        title={matchesUpMd ? "Activity" : "All Comments"}
        hasComments={Boolean(commentsList.length)}
        headerClassName={classes.header}
      />
      <div
        className={clsx(classes.list, { [classes.listSticky]: matchesUpMd })}
      >
        <HorseGeneralVetCommentEditor
          className={classes.horseCommentEditor}
          horseId={horse.id}
          refetchHorseWarningMessage={refetchHorseWarningMessage}
          refetchComments={refetchComments}
        />
        {renderLoader()}
        {matchesUpMd ? renderContentUpMd() : renderContentDownMd()}
      </div>
    </section>
  );
};

export default Comments;
