import { AccessTokenHelper } from "utils/token";
import { FIELD_TYPE, IField } from "components/FormField/constants";
import { racehorse360 } from "@tsg/1st-grpc-web";

export interface IState {
  arrivalDate?: Date;
  sleepingRoomsNumber?: number;
  contactFullName?: string;
  contactPhone?: string;
  contactEmail?: string;
  contactZipCode?: string;
  contactState?: string;
  contactCity?: string;
  contactStreetAddress?: string;
  contactStreetAddressOptional?: string;
  carrierName?: string;
  policyNumber?: string;
  policyExpirationDate?: Date;
  carrierZipCode?: string;
  carrierState?: string;
  carrierCity?: string;
  carrierStreetAddress?: string;
  carrierStreetAddressOptional?: string;
  preferencesFirst?: string;
  preferencesSecond?: string;
  isStablePlannedToSplit?: boolean;
  comment?: string;
}

export interface ILocationState {
  facilityId: string;
}

export const Tabs = ["Trainer Details", "Stable Entries"];

export interface IModule {
  name: string;
  title: string;
  fields: IField[];
}

export interface IPreviewModule {
  name: string;
  title: string;
  fields: {
    name: string;
    label: string;
    values?: string[][];
    type: FIELD_TYPE;
  }[];
}

export const STABLE_ENTRIES_INPUT = "stableEntriesInput";
export const USA_STATES = [
  {
    fullName: "Alabama",
    shortName: "AL"
  },
  {
    fullName: "Alaska",
    shortName: "AK"
  },
  {
    fullName: "Arizona",
    shortName: "AZ"
  },
  {
    fullName: "Arkansas",
    shortName: "AR"
  },
  {
    fullName: "American Samoa",
    shortName: "AS"
  },
  {
    fullName: "California",
    shortName: "CA"
  },
  {
    fullName: "Colorado",
    shortName: "CO"
  },
  {
    fullName: "Connecticut",
    shortName: "CT"
  },
  {
    fullName: "Delaware",
    shortName: "DE"
  },
  {
    fullName: "District of Columbia",
    shortName: "DC"
  },
  {
    fullName: "Florida",
    shortName: "FL"
  },
  {
    fullName: "Georgia",
    shortName: "GA"
  },
  {
    fullName: "Guam",
    shortName: "GU"
  },
  {
    fullName: "Hawaii",
    shortName: "HI"
  },
  {
    fullName: "Idaho",
    shortName: "ID"
  },
  {
    fullName: "Illinois",
    shortName: "IL"
  },
  {
    fullName: "Indiana",
    shortName: "IN"
  },
  {
    fullName: "Iowa",
    shortName: "IA"
  },
  {
    fullName: "Kansas",
    shortName: "KS"
  },
  {
    fullName: "Kentucky",
    shortName: "KY"
  },
  {
    fullName: "Louisiana",
    shortName: "LA"
  },
  {
    fullName: "Maine",
    shortName: "ME"
  },
  {
    fullName: "Maryland",
    shortName: "MD"
  },
  {
    fullName: "Massachusetts",
    shortName: "MA"
  },
  {
    fullName: "Michigan",
    shortName: "MI"
  },
  {
    fullName: "Minnesota",
    shortName: "MN"
  },
  {
    fullName: "Mississippi",
    shortName: "MS"
  },
  {
    fullName: "Missouri",
    shortName: "MO"
  },
  {
    fullName: "Montana",
    shortName: "MT"
  },
  {
    fullName: "Nebraska",
    shortName: "NE"
  },
  {
    fullName: "Nevada",
    shortName: "NV"
  },
  {
    fullName: "New Hampshire",
    shortName: "NH"
  },
  {
    fullName: "New Jersey",
    shortName: "NJ"
  },
  {
    fullName: "New Mexico",
    shortName: "NM"
  },
  {
    fullName: "New York",
    shortName: "NY"
  },
  {
    fullName: "North Carolina",
    shortName: "NC"
  },
  {
    fullName: "North Dacota",
    shortName: "ND"
  },
  {
    fullName: "Northern Mariana Islands",
    shortName: "MP"
  },
  {
    fullName: "Ohio",
    shortName: "OH"
  },
  {
    fullName: "Oklahoma",
    shortName: "OK"
  },
  {
    fullName: "Oregon",
    shortName: "OR"
  },
  {
    fullName: "Pennsylvania",
    shortName: "PA"
  },
  {
    fullName: "Puerto Rico",
    shortName: "PR"
  },
  {
    fullName: "Rhode Island",
    shortName: "RI"
  },
  {
    fullName: "South Carolina",
    shortName: "SC"
  },
  {
    fullName: "South Dakota",
    shortName: "SD"
  },
  {
    fullName: "Tennessee",
    shortName: "TN"
  },
  {
    fullName: "Texas",
    shortName: "TX"
  },
  {
    fullName: "Trust Territories",
    shortName: "TT"
  },
  {
    fullName: "Utah",
    shortName: "UT"
  },
  {
    fullName: "Vermont",
    shortName: "VT"
  },
  {
    fullName: "Virginia",
    shortName: "VA"
  },
  {
    fullName: "Virgin Islands",
    shortName: "VI"
  },
  {
    fullName: "Washington",
    shortName: "WA"
  },
  {
    fullName: "West Virginia",
    shortName: "WV"
  },
  {
    fullName: "Wisconsin",
    shortName: "WI"
  },
  {
    fullName: "Wyoming",
    shortName: "WY"
  }
];

export enum FormFiledName {
  ARRIVAL_DATE = "arrivalDate",
  SLEEPING_ROOMS_NUMBER = "sleepingRoomsNumber",
  CONTACT_FULL_NAME = "contactFullName",
  CONTACT_PHONE = "contactPhone",
  CONTACT_EMAIL = "contactEmail",
  CONTACT_STREET_ADDRESS = "contactStreetAddress",
  CONTACT_STREET_ADDRESS_OPTIONAL = "contactStreetAddressOptional",
  CONTACT_CITY = "contactCity",
  CONTACT_STATE = "contactState",
  CONTACT_ZIP_CODE = "contactZipCode",
  CARRIER_NAME = "carrierName",
  POLICY_EXPIRATION_DATE = "policyExpirationDate",
  POLICY_NUMBER = "policyNumber",
  CARRIER_STREET_ADDRESS = "carrierStreetAddress",
  CARRIER_STREET_ADDRESS_OPTIONAL = "carrierStreetAddressOptional",
  CARRIER_CITY = "carrierCity",
  CARRIER_STATE = "carrierState",
  CARRIER_ZIP_CODE = "carrierZipCode",
  PREFERENCES_FIRST = "preferencesFirst",
  PREFERENCES_SECOND = "preferencesSecond",
  IS_STABLE_PLANNED_TO_SPLIT = "isStablePlannedToSplit",
  COMMENT = "comment"
}

export const getModules = (
  user: AccessTokenHelper,
  form: racehorse360.IStallApplicationForm
): IModule[] => {
  return [
    {
      name: "accomodations",
      title: "Accomodations",
      fields: [
        {
          name: FormFiledName.ARRIVAL_DATE,
          label: "Estimated Arrival Date",
          previewLabel: "Est. Arrival Date",
          type: FIELD_TYPE.DATEPICKER,
          width: 40,
          isValidated: true
        },
        {
          name: FormFiledName.SLEEPING_ROOMS_NUMBER,
          label: "Number of Sleeping Rooms",
          previewLabel: "Sleeping Rooms",
          type: FIELD_TYPE.NUMBER,
          width: 60
        }
      ]
    },
    {
      name: "trainer",
      title: "Contact Information",
      fields: [
        {
          name: FormFiledName.CONTACT_FULL_NAME,
          label: "Full Name",
          type: FIELD_TYPE.TEXT,
          disabled: user.isTrainer || user.isAssistantTrainer,
          maxLength: 200,
          isValidated: true
        },
        {
          name: FormFiledName.CONTACT_PHONE,
          label: "Phone Number",
          type: FIELD_TYPE.PHONE,
          width: 40,
          isValidated: true
        },
        {
          name: FormFiledName.CONTACT_EMAIL,
          label: "Email Address",
          type: FIELD_TYPE.TEXT,
          width: 60,
          isValidated: true
        }
      ]
    },
    {
      name: "address",
      title: "Address",
      fields: [
        {
          name: FormFiledName.CONTACT_STREET_ADDRESS,
          label: "Street Address",
          type: FIELD_TYPE.TEXT,
          maxLength: 255,
          isValidated: true
        },
        {
          name: FormFiledName.CONTACT_STREET_ADDRESS_OPTIONAL,
          label: "Street Address Line 2",
          optional: true,
          type: FIELD_TYPE.TEXT,
          maxLength: 255
        },
        {
          name: FormFiledName.CONTACT_CITY,
          label: "City",
          type: FIELD_TYPE.TEXT,
          maxLength: 128,
          isValidated: true
        },
        {
          name: FormFiledName.CONTACT_STATE,
          label: "State",
          type: FIELD_TYPE.SELECT,
          mobileWidth: 40,
          width: 60,
          options: USA_STATES.map(state => ({
            id: state.fullName,
            name: state.shortName
          })),
          isValidated: true
        },
        {
          name: FormFiledName.CONTACT_ZIP_CODE,
          label: "Zip Code",
          type: FIELD_TYPE.TEXT,
          mobileWidth: 60,
          width: 40,
          maxLength: 32,
          isValidated: true
        }
      ]
    },
    {
      title: "Workers Compensation",
      name: "worker",
      fields: [
        {
          name: FormFiledName.CARRIER_NAME,
          label: "Carrier Name",
          type: FIELD_TYPE.TEXT,
          maxLength: 200,
          optional: true,
          isValidated: true
        },
        {
          name: FormFiledName.POLICY_EXPIRATION_DATE,
          label: "Policy Expiration",
          type: FIELD_TYPE.DATEPICKER,
          width: 40,
          optional: true,
          isValidated: true
        },
        {
          name: FormFiledName.POLICY_NUMBER,
          label: "Policy Number",
          type: FIELD_TYPE.TEXT,
          width: 60,
          maxLength: 20,
          optional: true,
          isValidated: true
        },
        {
          name: FormFiledName.CARRIER_STREET_ADDRESS,
          label: "Carrier Street Address",
          type: FIELD_TYPE.TEXT,
          maxLength: 255,
          optional: true,
          isValidated: true
        },
        {
          name: FormFiledName.CARRIER_STREET_ADDRESS_OPTIONAL,
          label: "Carrier Street Line 2",
          placeholder: "e.g. Unit, Apt. Number",
          optional: true,
          type: FIELD_TYPE.TEXT,
          maxLength: 255
        },
        {
          name: FormFiledName.CARRIER_CITY,
          label: "Carrier City",
          type: FIELD_TYPE.TEXT,
          maxLength: 128,
          optional: true,
          isValidated: true
        },
        {
          name: FormFiledName.CARRIER_STATE,
          label: "Carrier State",
          type: FIELD_TYPE.SELECT,
          mobileWidth: 40,
          width: 60,
          options: USA_STATES.map(state => ({
            id: state.fullName,
            name: state.shortName
          })),
          optional: true,
          isValidated: true
        },
        {
          name: FormFiledName.CARRIER_ZIP_CODE,
          label: "Carrier Zip Code",
          type: FIELD_TYPE.TEXT,
          mobileWidth: 60,
          width: 40,
          maxLength: 32,
          optional: true,
          isValidated: true,
          shouldApplyMobileStyles: true
        }
      ]
    },
    {
      title: "Stabling Location Preferences",
      name: "preferences",
      fields: [
        {
          name: FormFiledName.PREFERENCES_FIRST,
          label: "First Choice",
          type: FIELD_TYPE.SELECT,
          options:
            form?.facilityOptions?.map(facility => ({
              name: facility.name,
              id: facility.id,
              type: facility.type
            })) || [],
          disabled: form?.facilityOptions?.length === 1,
          isValidated: true
        },
        {
          name: FormFiledName.PREFERENCES_SECOND,
          label: "Second Choice",
          type: FIELD_TYPE.SELECT,
          options:
            form?.facilityOptions?.map(facility => ({
              name: facility.name,
              id: facility.id,
              type: facility.type
            })) || [],
          disabled: form?.facilityOptions?.length === 1,
          isValidated: true
        },
        {
          name: FormFiledName.IS_STABLE_PLANNED_TO_SPLIT,
          label: "Do you plan to split your stable?",
          type: FIELD_TYPE.BOOLCHOICE,
          isValidated: true
        }
      ]
    },
    {
      title: "Additional Information",
      name: "additional",
      fields: [
        {
          name: FormFiledName.COMMENT,
          label: "Comments",
          placeholder: "Add any information you'd like us to know",
          optional: true,
          type: FIELD_TYPE.TEXTAREA,
          maxLength: 500
        }
      ]
    }
  ];
};

export const reviewModules: IPreviewModule[] = [
  {
    name: "accomodations",
    title: "Accomodations",
    fields: [
      {
        label: "Est. Arrival Date",
        name: FormFiledName.ARRIVAL_DATE,
        type: FIELD_TYPE.DATEPICKER
      },
      {
        label: "Sleeping Rooms",
        name: FormFiledName.SLEEPING_ROOMS_NUMBER,
        type: FIELD_TYPE.NUMBER
      }
    ]
  },
  {
    name: "contactAndAddress",
    title: "Contact & Address",
    fields: [
      {
        name: FormFiledName.CONTACT_FULL_NAME,
        label: "Full Name",
        type: FIELD_TYPE.TEXT
      },
      {
        name: FormFiledName.CONTACT_PHONE,
        label: "Phone Number",
        type: FIELD_TYPE.TEXT
      },
      {
        name: FormFiledName.CONTACT_EMAIL,
        label: "Email Address",
        type: FIELD_TYPE.TEXT
      },
      {
        name: "contactAddress",
        values: [
          [
            FormFiledName.CONTACT_STREET_ADDRESS,
            FormFiledName.CONTACT_STREET_ADDRESS_OPTIONAL
          ],
          [
            FormFiledName.CONTACT_CITY,
            FormFiledName.CONTACT_STATE,
            FormFiledName.CONTACT_ZIP_CODE
          ]
        ],
        label: "Address",
        type: FIELD_TYPE.TEXT
      }
    ]
  },
  {
    title: "Workers Compensation",
    name: "worker",
    fields: [
      {
        name: FormFiledName.CARRIER_NAME,
        label: "Carrier Name",
        type: FIELD_TYPE.TEXT
      },
      {
        name: FormFiledName.POLICY_EXPIRATION_DATE,
        label: "Policy Expiration",
        type: FIELD_TYPE.DATEPICKER
      },
      {
        name: FormFiledName.POLICY_NUMBER,
        label: "Policy Number",
        type: FIELD_TYPE.TEXT
      },
      {
        name: "carrierStreetAddress",
        label: "Carrier Address",
        values: [
          [
            FormFiledName.CARRIER_STREET_ADDRESS,
            FormFiledName.CARRIER_STREET_ADDRESS_OPTIONAL
          ],
          [
            FormFiledName.CARRIER_CITY,
            FormFiledName.CARRIER_STATE,
            FormFiledName.CARRIER_ZIP_CODE
          ]
        ],
        type: FIELD_TYPE.TEXT
      }
    ]
  },
  {
    title: "Stabling Location Preferences",
    name: "preferences",
    fields: [
      {
        name: FormFiledName.PREFERENCES_FIRST,
        label: "First Choice",
        type: FIELD_TYPE.SELECT
      },
      {
        name: FormFiledName.PREFERENCES_SECOND,
        label: "Second Choice",
        type: FIELD_TYPE.SELECT
      },
      {
        name: FormFiledName.IS_STABLE_PLANNED_TO_SPLIT,
        label: "Split Stable?",
        type: FIELD_TYPE.BOOLCHOICE
      }
    ]
  },
  {
    title: "Additional Information",
    name: "additional",
    fields: [
      {
        name: FormFiledName.COMMENT,
        label: "Comments",
        type: FIELD_TYPE.TEXT
      }
    ]
  }
];

export const initialState = {
  arrivalDate: null,
  sleepingRoomsNumber: 0,
  contactFullName: "",
  contactPhone: "",
  contactEmail: "",
  contactZipCode: "",
  contactState: "",
  contactCity: "",
  contactStreetAddress: "",
  contactStreetAddressOptional: "",
  carrierName: "",
  policyNumber: "",
  policyExpirationDate: null,
  carrierZipCode: "",
  carrierState: "",
  carrierCity: "",
  carrierStreetAddress: "",
  carrierStreetAddressOptional: "",
  preferencesFirst: "",
  preferencesSecond: "",
  isStablePlannedToSplit: undefined,
  comment: ""
};

export const UNSAVED_CHANGES_TEXT =
  "You have unsaved changes to your stall application form. Would you like to keep or discard these changes?";
