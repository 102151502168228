import React, { useCallback } from "react";
import { useQueryClient } from "react-query";
import { useSelector } from "react-redux";
import clsx from "clsx";
import format from "date-fns/format";
import isToday from "date-fns/isToday";
import parseISO from "date-fns/parseISO";
import startOfToday from "date-fns/startOfToday";
import isEqual from "lodash/isEqual";

import Box from "@material-ui/core/Box";
import Typography from "@material-ui/core/Typography";
import CalendarIcon from "@material-ui/icons/CalendarToday";

import { racehorse360 } from "@tsg/1st-grpc-web";

import routes from "common/routes";
import HorseListsModal from "components/HorseListsModal";
import Link from "components/Link";
import Loader from "components/Loader";
import { useRacehorse360Api } from "hooks/api";
import ICalendarDay from "interfaces/ICalendarDay";
import WorkoutsPageState from "interfaces/WorkoutsPageState";
import { SortOrder } from "interfaces/SortOrder";
import {
  currentTimeZoneString,
  getCalendar,
  shouldShutOffDate
} from "utils/date-utils";
import AvailableDate from "./AvailableDate";
import DisabledDate from "./DisabledDate";
import ScheduledDate from "./ScheduledDate";
import useStyles from "./styles";

export interface Props {
  horse: racehorse360.IHorse;
  selectedFacility: racehorse360.IFacility;
  facilityBlackoutsCalendar?: ICalendarDay[];
  horseOnLists: racehorse360.IHorseOnList[];
}

const DAYS_PER_SCREEN: number = 35;

const getSchedule = (
  workoutRequests: racehorse360.IWorkoutRequest[],
  facilityBlackouts: ICalendarDay[]
) => {
  const schedule = getCalendar(startOfToday(), DAYS_PER_SCREEN);
  workoutRequests.forEach(workoutRequest => {
    const date = parseISO(workoutRequest.date);
    const scheduleKey = format(date, "yyyy-MM");
    if (schedule[scheduleKey]) {
      schedule[scheduleKey][date.getDate()] = workoutRequest;
    }
  });
  facilityBlackouts.forEach(calendarDay => {
    const scheduleKey = format(calendarDay.date, "yyyy-MM");
    if (schedule[scheduleKey]) {
      const dayKey = calendarDay.date.getDate();
      schedule[scheduleKey][dayKey] = {
        ...schedule[scheduleKey][dayKey],
        ...calendarDay,
        date: schedule[scheduleKey][dayKey].date
      };
    }
  });
  return Object.values(schedule).map(month => Object.values(month));
};

const CondensedViewCalendar = (props: Props) => {
  const { horse, selectedFacility, facilityBlackoutsCalendar, horseOnLists } =
    props;
  const classes = useStyles();
  const queryClient = useQueryClient();
  const scheduledDatesOnly = useSelector(
    (state: { workoutsPage: WorkoutsPageState }) =>
      state?.workoutsPage?.scheduledDatesOnly
  );

  const { useListWorkoutRequests } = useRacehorse360Api();
  const listWorkoutRequestsQueryKey = `${horse.id}-list-workout-requests`;

  const { isLoading, data } = useListWorkoutRequests(
    listWorkoutRequestsQueryKey,
    {
      query: {
        horseIds: [horse.id],
        date: {
          range: {
            relative: {
              startDate: { value: 0 },
              endDate: { value: DAYS_PER_SCREEN },
              timezone: currentTimeZoneString()
            }
          }
        },
        statuses: [
          racehorse360.WorkoutRequestStatus.WORKOUT_REQUEST_STATUS_REQUESTED,
          racehorse360.WorkoutRequestStatus.WORKOUT_REQUEST_STATUS_EXAM_PENDING,
          racehorse360.WorkoutRequestStatus.WORKOUT_REQUEST_STATUS_APPROVED
        ]
      },
      pagingOptions: {
        maxResults: 37
      },
      getOptions: {
        select: [
          "id",
          "facility.name",
          "facility.code",
          "facility.background_color",
          "facility.foreground_color",
          "facility.stroke_color",
          "facility.shut_off_period_in_hours",
          "facility.shut_off_hours",
          "facility.timezone",
          "date",
          "status",
          "hasComment"
        ],
        orderBy: [`date ${SortOrder.ASC}`]
      }
    },
    {
      onError: error => console.error(error)
    }
  );
  const schedule = getSchedule(
    data?.workoutRequests || [],
    facilityBlackoutsCalendar
  );

  const handleDateUpdate = useCallback(async () => {
    await queryClient.invalidateQueries(listWorkoutRequestsQueryKey);
  }, [listWorkoutRequestsQueryKey]);

  if (!horse) {
    return <Box className={classes.root} />;
  }

  const renderDate = (workoutRequest, mi, di) => {
    const isShutOffDate = shouldShutOffDate(
      parseISO(workoutRequest.date),
      selectedFacility
    );

    if (workoutRequest.id && workoutRequest.facility) {
      return (
        <ScheduledDate
          key={workoutRequest.date}
          className={classes.calendarDate}
          workoutRequestData={workoutRequest}
          horse={horse}
          onUpdate={handleDateUpdate}
          isToday={mi === 0 && di === 0}
        />
      );
    }

    if (workoutRequest.facilityBlackout || isShutOffDate) {
      return (
        <DisabledDate
          key={workoutRequest.date}
          date={parseISO(workoutRequest.date)}
        />
      );
    }

    return (
      <AvailableDate
        key={workoutRequest.date}
        date={workoutRequest.date}
        selectedFacilityId={selectedFacility.id}
        horseId={horse.id}
        onUpdate={handleDateUpdate}
      />
    );
  };

  return (
    <Box
      className={clsx(classes.root, {
        [classes.rootScheduledOnly]: scheduledDatesOnly
      })}
    >
      <Link
        to={routes.horseWorkouts.path.replace(":horseId", String(horse.id))}
        className={classes.nameWrapper}
      >
        <Typography
          className={clsx(classes.name, {
            [classes.alert]: horseOnLists?.length
          })}
        >
          {horse.name}
        </Typography>
        {isLoading ? (
          <Loader size={14} className={classes.loader} />
        ) : (
          <>
            <Typography className={classes.count}>
              &nbsp;
              {Boolean(data?.workoutRequests?.length) &&
                `(${data.workoutRequests.length})`}
            </Typography>
            {horseOnLists?.length && (
              <HorseListsModal
                horseName={horse.name}
                horseOnLists={horseOnLists}
              />
            )}
          </>
        )}
        <CalendarIcon className={classes.detailsLink} />
      </Link>
      <Box
        className={clsx(classes.calendar, {
          [classes.calendarScheduledOnly]: scheduledDatesOnly
        })}
      >
        {isLoading && <Box className={classes.fog} />}
        {scheduledDatesOnly ? (
          <Box className={clsx(classes.calendarMonth, classes.scheduledOnly)}>
            {data?.workoutRequests.map(workoutRequest => (
              <ScheduledDate
                className={classes.widePane}
                key={workoutRequest.date}
                workoutRequestData={workoutRequest}
                horse={horse}
                onUpdate={handleDateUpdate}
                isWide
                isToday={isToday(parseISO(workoutRequest.date))}
              />
            ))}
          </Box>
        ) : (
          schedule.map((month, mi) => (
            <Box key={month[0].date} className={classes.calendarMonth}>
              {month.map(
                (
                  workoutRequest: racehorse360.IWorkoutRequest & ICalendarDay,
                  di
                ) => renderDate(workoutRequest, mi, di)
              )}
            </Box>
          ))
        )}
      </Box>
    </Box>
  );
};

export default React.memo(CondensedViewCalendar, (prev, next) => {
  return (
    prev.horse.id === next.horse.id &&
    prev.selectedFacility.id === next.selectedFacility.id &&
    isEqual(prev.horseOnLists, next.horseOnLists)
  );
});
