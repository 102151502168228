import React from "react";

import JogInformationEntry from "./JogInformationEntry";
import { checkSelectingFieldsOneEntry } from "../helper";
import ExamFormBlock from "../ExamFormBlock";
import AddEntryButton from "../AddEntryButton";
import { MAX_ENTRIES_AMOUNT, IExamJog, addExamJog } from "../options";
import { useVetExamContext } from "../VetExamStateProvider/VetExamProvider";
import { useExamLockerContext } from "../../ExamLockerContext/ExamLockerContext";

const JogInformation = () => {
  const {
    state: { examJogs },
    dispatch
  } = useVetExamContext();
  const { lockExam } = useExamLockerContext();

  const handleCreateExamJog = async () => {
    dispatch(addExamJog());
    lockExam();
  };

  const renderAddEntry = () => {
    if (examJogs.length < MAX_ENTRIES_AMOUNT) {
      const isDisabled = !examJogs.every(checkSelectingFieldsOneEntry);

      return (
        <AddEntryButton
          title="Add another Jog Information Entry"
          onClick={handleCreateExamJog}
          disabled={isDisabled}
        />
      );
    }
  };

  const renderEntries = () => {
    return examJogs.map((jog: IExamJog, index: number) => (
      <JogInformationEntry key={jog.id} jog={jog} entryNumber={index + 1} />
    ));
  };

  return (
    <ExamFormBlock
      title="Jog Information"
      isDarkBackground={true}
      actionsComponent={renderAddEntry()}
    >
      {renderEntries()}
    </ExamFormBlock>
  );
};

export default JogInformation;
