import React from "react";
import clsx from "clsx";

import useMediaQuery from "@material-ui/core/useMediaQuery";
import { Theme } from "@material-ui/core/styles";

import { racehorse360 } from "@tsg/1st-grpc-web";

import UserIcon from "components/Icons/Profile";
import StethoscopeIcon from "components/Icons/Stethoscope";
import HorseHeadInCircleIcon from "components/Icons/HorseHeadInCircle";
import { transformViewDate } from "utils/date-utils";
import { getPersonFullName } from "utils/person";
import Breakpoints from "common/breakpoints";
import {
  checkDisplayingVariants,
  ECommentDisplayingVariant,
  EReportersIcons,
  getCommentTypeLabel,
  getReporterRoleIcon
} from "./helper";
import useStyles from "./styles";

export interface ICommentData {
  id: string;
  eventType: racehorse360.EventType;
  commenterId?: string;
  commenterFirstName: string;
  commenterLastName: string;
  content: string;
  warningMessage?: string;
  eventDate: string;
  isRead: boolean;
  createdOn: string;
  commentIconType?: racehorse360.HorseAggregatedCommentIconType;
  modalIconType?: racehorse360.NotificationCreatorRole;
}

interface IProps {
  shouldDisplayIsReadStatus: boolean;
  className?: string;
  data: ICommentData;
  variant: ECommentDisplayingVariant;
  onClickItem?: (id: string) => void;
}

const Comment = (props: IProps) => {
  const {
    shouldDisplayIsReadStatus,
    className,
    variant,
    onClickItem,
    data: {
      commenterFirstName,
      commenterLastName,
      createdOn,
      eventDate,
      eventType,
      content,
      commentIconType,
      modalIconType,
      isRead,
      id
    }
  } = props;
  const classes = useStyles();
  const { isModalVariant, isCommentForVetVariant } =
    checkDisplayingVariants(variant);
  const matchesDownSX720 = useMediaQuery((theme: Theme) =>
    theme.breakpoints.down(Breakpoints.SX_720)
  );
  const inlineDisplaying =
    isModalVariant || matchesDownSX720 || isCommentForVetVariant;
  const commenterNameTemplate = isModalVariant ? "%F %M %L" : "%f %M %L";
  const iconType = isModalVariant ? modalIconType : commentIconType;

  const handleClickComment = () => {
    onClickItem && onClickItem(id);
  };

  const renderReporterIcon = (type: EReportersIcons) => {
    switch (type) {
      case EReportersIcons.VET:
        return (
          <StethoscopeIcon
            className={clsx(classes.icon, { modalView: isModalVariant })}
          />
        );
      case EReportersIcons.TRAINER:
        return (
          <HorseHeadInCircleIcon
            className={clsx(classes.icon, { modalView: isModalVariant })}
          />
        );
      case EReportersIcons.OTHER_USERS:
        return (
          <UserIcon
            className={clsx(classes.icon, { modalView: isModalVariant })}
          />
        );
    }
  };

  return (
    <div
      className={clsx(classes.commentRoot, className, {
        modalView: isModalVariant
      })}
      role={onClickItem ? "button" : null}
      tabIndex={0}
      onClick={handleClickComment}
      data-test={!isModalVariant && "trainer-comments-list-element"}
    >
      <div className={clsx(classes.headerRow, { modalView: isModalVariant })}>
        <span
          className={clsx(classes.commenterInfo, { modalView: isModalVariant })}
        >
          {renderReporterIcon(getReporterRoleIcon(iconType, variant))}
          {getPersonFullName(
            { firstName: commenterFirstName, lastName: commenterLastName },
            commenterNameTemplate
          )}
        </span>
        {shouldDisplayIsReadStatus && (
          <span
            className={clsx(classes.mark, { unread: !isRead })}
            data-test={!isModalVariant && !isRead && "unread-dot-indicator"}
          />
        )}
      </div>
      <div className={clsx(classes.row, { modalView: isModalVariant })}>
        <div className={clsx(classes.info, { inlineDisplaying })}>
          <div className={classes.uppercase}>
            {transformViewDate(createdOn, null, "PP")}
          </div>
          <div className={clsx(classes.additionalSigns, { inlineDisplaying })}>
            &nbsp;&ndash;&nbsp;
          </div>
          <div>{transformViewDate(createdOn, null, "p")}</div>
        </div>
        <div
          className={clsx(classes.eventInfo, classes.info, {
            inlineDisplaying
          })}
        >
          <div>{getCommentTypeLabel(eventType)}</div>
          <div className={clsx(classes.additionalSigns, { inlineDisplaying })}>
            &nbsp;
          </div>
          <div>{transformViewDate(eventDate, null, "MM/dd/yy")}</div>
        </div>
      </div>
      <div
        className={clsx(classes.commentContent, { modalView: isModalVariant })}
      >
        {content}
      </div>
    </div>
  );
};

export default Comment;
