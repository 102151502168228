import { makeStyles } from "@material-ui/core/styles";
import { MuiTheme } from "theme";
import Breakpoints from "common/breakpoints";

export default makeStyles((theme: MuiTheme) => {
  return {
    dialogPaper: {
      backgroundColor: "#f6f6f6",
      position: "relative"
    },
    stableEntries: {
      maxHeight: "calc(100vh - 244px)",
      overflowY: "auto",
      display: "flex",
      flexDirection: "column",
      gap: 15,
      padding: "24px 24px 115px 24px",
      "&::-webkit-scrollbar": {
        width: 0
      }
    },
    cancelButton: {
      fontSize: 16,
      color: theme.palette.common.white,
      textTransform: "none",
      position: "fixed",
      left: 236,
      top: 12,
      [theme.breakpoints.down(Breakpoints.MD_960)]: {
        left: 100
      }
    },
    unsavedChangesContent: {
      display: "flex",
      flexDirection: "column",
      justifyContent: "space-between",
      fontSize: 16,
      height: 116
    },
    loader: {
      position: "absolute",
      height: "100%",
      backgroundColor: "#fff",
      opacity: 0.5,
      zIndex: 1300
    }
  };
});
