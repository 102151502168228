import React, { useEffect, useMemo, useRef, useState } from "react";
import AutoSizer from "react-virtualized-auto-sizer";
import clsx from "clsx";

import Box from "@material-ui/core/Box";
import Button from "@material-ui/core/Button";
import FormControl from "@material-ui/core/FormControl";
import FormHelperText from "@material-ui/core/FormHelperText";
import MenuItem from "@material-ui/core/MenuItem";
import OutlinedInput from "@material-ui/core/OutlinedInput";
import Popover, { PopoverProps } from "@material-ui/core/Popover";
import Select from "@material-ui/core/Select";
import Typography from "@material-ui/core/Typography";
import CheckCircleIcon from "@material-ui/icons/CheckCircle";
import CheckIcon from "@material-ui/icons/Check";
import { Theme } from "@material-ui/core/styles";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import Dialog from "@material-ui/core/Dialog";
import UndoCompleteIcon from "@material-ui/icons/Refresh";
import AccountBoxIcon from "@material-ui/icons/AccountBox";
import PersonAddIcon from "@material-ui/icons/PersonAdd";

import { racehorse360 } from "@tsg/1st-grpc-web";

import PersonEditIcon from "components/Icons/PersonEdit";
import ClosedDoor from "components/Icons/ClosedDoor";
import ReOpen from "components/Icons/ReOpen";
import Loader from "components/Loader";
import Textarea from "components/Textarea";
import { useSnackbar } from "components/SnackbarContext/SnackbarContext";
import { useLoggedInUser } from "components/LoggedInUserProvider";
import VirtualList from "components/VirtualList";
import Breakpoints from "common/breakpoints";
import { useRacehorse360Api } from "hooks/api";
import { parseWorkOrderReasonToClose } from "utils/enum-parser";
import { EPopoverType } from "../helper";
import { requiredWorkOrderOptions } from "../../helper";
import useStyles from "./styles";

interface IProps {
  className?: string;
  type: EPopoverType;
  anchor: Element;
  workOrder: racehorse360.IWorkOrder;
  onSuccess: (workOrder: racehorse360.IWorkOrder) => void;
  onClose: () => void;
  PopoverProps?: Partial<PopoverProps>;
  isOpen: boolean;
  width?: number;
}

interface IPopoverData {
  type: EPopoverType;
  header: {
    title: string;
    icon: React.ReactElement;
  };
  mutateWorkOrder: (
    queryOptions: Record<string, unknown>
  ) => Promise<racehorse360.IWorkOrder>;
  successMessage: string;
}

const nonUnassigned = {
  id: "NON UNASSIGNED ID",
  firstName: "None (Unassigned)",
  lastName: ""
};

const WorkOrderPopover = (props: IProps) => {
  const {
    anchor,
    className,
    workOrder,
    onSuccess,
    onClose,
    type,
    PopoverProps = {
      anchorOrigin: {
        vertical: "top",
        horizontal: "left"
      },
      transformOrigin: {
        vertical: "top",
        horizontal: "right"
      }
    },
    isOpen,
    width = [EPopoverType.ASSIGN, EPopoverType.REASSIGN].includes(type)
      ? 352
      : 320
  } = props;
  const classes = useStyles({ width });
  const { showSuccessSnack } = useSnackbar();
  const { currentUser } = useLoggedInUser();
  const isSupervisor = currentUser.isMaintenanceSupervisor;
  const [popoverData, setPopoverData] = useState<IPopoverData>();
  const [reason, setReason] = useState<
    racehorse360.WorkOrderReasonToClose | ""
  >("");
  const [note, setNote] = useState<string>("");
  const [assigneeId, setAssigneeId] = useState<string>(null);
  const [transitionActive, setTransitionActive] = useState<boolean>(true);
  const listRef = useRef<HTMLDivElement>();

  const matchesDownSM600 = useMediaQuery(
    (theme: Theme) => theme.breakpoints.down(Breakpoints.SM_600),
    { noSsr: true }
  );

  const {
    useAssignMaintenanceUserToWorkOrder,
    useCloseWorkOrder,
    useCompleteWorkOrder,
    useReopenWorkOrder,
    usePullMaintenanceUsers,
    useSendBackWorkOrder
  } = useRacehorse360Api();
  const {
    mutateAsync: assignMaintenanceUserToWorkOrder,
    isLoading: isAssignLoading
  } = useAssignMaintenanceUserToWorkOrder();
  const { mutateAsync: closeWorkOrder, isLoading: isCloseWorkOrderLoading } =
    useCloseWorkOrder();
  const {
    mutateAsync: completeWorkOrder,
    isLoading: isCompleteWorkOrderLoading
  } = useCompleteWorkOrder();
  const { mutateAsync: reOpenWorkOrder, isLoading: isReOpenWorkOrderLoading } =
    useReopenWorkOrder();
  const {
    mutateAsync: undoCompleteWorkOrder,
    isLoading: isUndoCompleteWorkOrderLoading
  } = useSendBackWorkOrder();
  const { isLoading: isStaffLoading, data } = usePullMaintenanceUsers(
    "pullMaintenanceUsers",
    {
      facilityId: workOrder?.facility.id
    },
    {
      enabled: Boolean(workOrder?.facility.id)
    }
  );

  let assignees = [];

  if (data) {
    assignees = [...data.maintenanceUsers];
    assignees.unshift(nonUnassigned);
  }

  const isLoading = [
    isAssignLoading,
    isCloseWorkOrderLoading,
    isCompleteWorkOrderLoading,
    isReOpenWorkOrderLoading,
    isUndoCompleteWorkOrderLoading,
    isStaffLoading
  ].some(Boolean);
  const isAssignAction = popoverData?.type === EPopoverType.ASSIGN;
  const isReassignAction = popoverData?.type === EPopoverType.REASSIGN;
  const isCloseAction = popoverData?.type === EPopoverType.CLOSE;
  const isReOpenAction = popoverData?.type === EPopoverType.REOPEN;
  const isUndoCompleteAction = popoverData?.type === EPopoverType.UNDO_COMPLETE;

  const getButtonTitle = useMemo((): string => {
    switch (popoverData?.type) {
      case EPopoverType.ASSIGN: {
        return "ASSIGN WORK ORDER";
      }
      case EPopoverType.REASSIGN: {
        return "SAVE CHANGES";
      }
      case EPopoverType.COMPLETE: {
        return "MARK ORDER COMPLETE";
      }
      case EPopoverType.REOPEN: {
        return "REOPEN WORK ORDER";
      }
      case EPopoverType.UNDO_COMPLETE: {
        return isSupervisor ? "SEND BACK" : "UNDO COMPLETE";
      }
      case EPopoverType.CLOSE: {
        return "CLOSE WORK ORDER";
      }
      default:
        return "";
    }
  }, [popoverData?.type, currentUser]);

  const getAssignSuccessMessage = (): string => {
    const assignee = assignees.find(
      (user: racehorse360.IUser) => user.id === assigneeId
    );

    if (assignee) {
      const assigneeName = `${assignee?.firstName} ${assignee?.lastName}`;

      return `Work order #${workOrder?.customerFacingId} assigned to ${assigneeName}`;
    }
  };

  const getSentBackSuccessMessage = (): string => {
    if (isSupervisor) {
      const assignee = assignees.find(
        (user: racehorse360.IUser) => user.id === assigneeId
      );
      const assigneeName = `${assignee?.firstName} ${assignee?.lastName}`;

      return assigneeId === nonUnassigned.id
        ? `Work order #${workOrder?.customerFacingId} sent back and marked open`
        : `Work order #${workOrder?.customerFacingId} sent back and assigned to ${assigneeName}`;
    }

    return `Work order #${workOrder?.customerFacingId} marked assigned`;
  };

  const resetPopover = () => {
    setReason("");
    setNote("");
  };

  const handleReasonChange = event => {
    setReason(event.target.value);
  };

  const handleChangeAssignee = event => {
    setAssigneeId(event.target.value);
  };

  const handleNoteChange = value => {
    setNote(value);
  };

  const handleClose = () => {
    onClose();
  };

  const handleTransitionExit = () => {
    resetPopover();
  };

  const handlePersonClick = (id: string) => () => {
    if (assigneeId !== id) {
      setAssigneeId(id);
    } else {
      setAssigneeId(null);
    }
  };

  const handleUnassignClick = () => {
    setAssigneeId(null);
  };

  const handleConfirm = () => {
    const queryOptions = {
      id: workOrder.id,
      userToAssignId: assigneeId !== "" ? { value: assigneeId } : undefined,
      reasonToClose: reason !== "" ? reason : undefined,
      note: note
        ? {
            value: note
          }
        : null,
      getOptions: {
        ...requiredWorkOrderOptions
      }
    };

    popoverData
      .mutateWorkOrder(queryOptions)
      .then((response: racehorse360.IWorkOrder) => {
        if (popoverData.successMessage) {
          showSuccessSnack(popoverData.successMessage);
        }

        onSuccess(response);
      })
      .catch(error => {
        console.error(error);
      });
  };

  useEffect(() => {
    if (type) {
      const popovers = {
        [EPopoverType.ASSIGN]: {
          type: EPopoverType.ASSIGN,
          header: {
            title: `Assign Work Order #${workOrder?.customerFacingId}`,
            icon: <PersonAddIcon className={classes.assignIcon} />
          },
          mutateWorkOrder: queryOptions =>
            assignMaintenanceUserToWorkOrder(queryOptions),
          successMessage: getAssignSuccessMessage()
        },
        [EPopoverType.REASSIGN]: {
          type: EPopoverType.REASSIGN,
          header: {
            title: `Edit Assignment #${workOrder?.customerFacingId}`,
            icon: <PersonEditIcon className={classes.reassignIcon} />
          },
          mutateWorkOrder: queryOptions =>
            assignMaintenanceUserToWorkOrder(queryOptions),
          successMessage: getAssignSuccessMessage()
        },
        [EPopoverType.CLOSE]: {
          type: EPopoverType.CLOSE,
          header: {
            title: `Close Work Order #${workOrder?.customerFacingId}?`,
            icon: <ClosedDoor className={classes.headerIcon} />
          },
          mutateWorkOrder: queryOptions => closeWorkOrder(queryOptions),
          successMessage: `Work order #${workOrder?.customerFacingId} has been closed`
        },
        [EPopoverType.COMPLETE]: {
          type: EPopoverType.COMPLETE,
          header: {
            title: `Complete Order #${workOrder?.customerFacingId}?`,
            icon: <CheckCircleIcon className={classes.completeIcon} />
          },
          mutateWorkOrder: queryOptions => completeWorkOrder(queryOptions),
          successMessage: `Work order #${workOrder?.customerFacingId} marked complete`
        },
        [EPopoverType.REOPEN]: {
          type: EPopoverType.REOPEN,
          header: {
            title: `Reopen Work Order #${workOrder?.customerFacingId}?`,
            icon: <ReOpen className={classes.reOpenIcon} />
          },
          mutateWorkOrder: queryOptions =>
            reOpenWorkOrder({
              ...queryOptions,
              userToAssignId: {
                value: assigneeId === nonUnassigned.id ? null : assigneeId
              }
            }),
          successMessage: `Work order #${workOrder?.customerFacingId} has been reopened`
        },
        [EPopoverType.UNDO_COMPLETE]: {
          type: EPopoverType.UNDO_COMPLETE,
          header: {
            title: `${
              isSupervisor ? "Send Back Order" : "Undo Complete for"
            } #${workOrder?.customerFacingId}?`,
            icon: <UndoCompleteIcon className={classes.undoCompleteIcon} />
          },
          mutateWorkOrder: queryOptions =>
            undoCompleteWorkOrder({
              ...queryOptions,
              userToAssignId: {
                value: assigneeId === nonUnassigned.id ? null : assigneeId
              }
            }),
          successMessage: getSentBackSuccessMessage()
        }
      };
      setPopoverData(popovers[type]);
    }
  }, [type, assigneeId]);

  const renderHeader = () => (
    <Typography className={classes.header}>
      {popoverData.header.icon}
      <span className={classes.headerTitle}>{popoverData.header.title}</span>
    </Typography>
  );

  useEffect(() => {
    if (!isLoading) {
      const assignee = data?.maintenanceUsers?.find(user => {
        return user.id === workOrder.assignedUser?.id;
      });

      if (!assignee) {
        setAssigneeId(
          isAssignAction || isReassignAction ? null : nonUnassigned.id
        );
      } else {
        setAssigneeId(assignee.id);
      }
    }
  }, [isLoading, workOrder, isAssignAction]);

  const renderReasons = () => {
    return Object.values(racehorse360.WorkOrderReasonToClose).map(
      (value: racehorse360.WorkOrderReasonToClose) => (
        <MenuItem
          key={value}
          className={clsx(classes.menuItem, classes.closedMenuItem, {
            [classes.noReason]: value === 0
          })}
          value={value}
        >
          {parseWorkOrderReasonToClose(value)}
        </MenuItem>
      )
    );
  };

  const renderReasonValue = (selected: racehorse360.WorkOrderReasonToClose) => {
    if (!selected) {
      return <span className={classes.reasonPlaceholder}>Select Reason</span>;
    }

    return parseWorkOrderReasonToClose(selected);
  };

  const renderAssignees = () => {
    return assignees?.map(user => {
      const isSelected = user.id === assigneeId;

      return (
        <MenuItem
          key={user.id}
          className={clsx(classes.menuItem, {
            ["selected"]: isSelected
          })}
          value={user.id}
        >
          <div className={classes.menuItemContent}>
            <span>{`${user.firstName} ${user.lastName}`}&nbsp;</span>
            {user.id !== nonUnassigned.id && (
              <span>{` (${user.workOrderAssignedCount || 0})`}</span>
            )}
          </div>
          {isSelected && <CheckIcon className={classes.checkIcon} />}
        </MenuItem>
      );
    });
  };

  const renderAssigneeValue = (selectedId: string) => {
    const currentUser = assignees.find(item => {
      return item.id === selectedId;
    });
    const isShowWorkOrderAssignedCount =
      selectedId !== nonUnassigned.id &&
      (isUndoCompleteAction || isReOpenAction);
    const workOrderAssignedCount = isShowWorkOrderAssignedCount
      ? `(${currentUser?.workOrderAssignedCount || 0})`
      : "";

    return `${currentUser?.firstName} ${currentUser?.lastName} ${workOrderAssignedCount}`;
  };

  const renderAssigneesList = () => {
    return (
      <div className={classes.listWrapper} ref={listRef}>
        <AutoSizer>
          {({ height, width }) => (
            <VirtualList
              className={classes.list}
              itemClassName={classes.listItem}
              count={data?.maintenanceUsers?.length}
              itemHeight={50}
              height={height}
              width={width}
              scrollContainerRef={listRef}
              itemsBeyond={4}
              enabled={transitionActive}
            >
              {data?.maintenanceUsers?.map(user => (
                <Button
                  classes={{
                    endIcon: classes.personCheckMark
                  }}
                  key={user.id}
                  variant={"outlined"}
                  className={clsx(classes.person, {
                    [classes.personSelected]: assigneeId === user.id
                  })}
                  startIcon={<AccountBoxIcon fontSize={"small"} />}
                  onClick={handlePersonClick(user.id)}
                  endIcon={assigneeId === user.id ? <CheckIcon /> : null}
                  data-test={"work-order-popup__person-button"}
                >
                  {user.firstName} {user.lastName}&nbsp;
                  <span className={classes.personWorkOrdersCount}>
                    ({user.workOrderAssignedCount || 0})
                  </span>
                </Button>
              ))}
            </VirtualList>
          )}
        </AutoSizer>
      </div>
    );
  };

  const renderPopoverContent = () => {
    return (
      Boolean(popoverData) && (
        <>
          {isLoading && <Loader overlay />}
          {renderHeader()}
          <div className={classes.content}>
            {(isAssignAction || isReassignAction) && renderAssigneesList()}
            {isCloseAction && (
              <FormControl fullWidth className={classes.reason}>
                <FormHelperText className={classes.formHelper}>
                  Reason for Closing&nbsp;
                  <span className={classes.formHelperSub}>(Optional)</span>
                </FormHelperText>
                <Select
                  displayEmpty
                  value={reason}
                  onChange={handleReasonChange}
                  classes={{
                    select: classes.selectText
                  }}
                  MenuProps={{
                    classes: {
                      list: classes.selectList
                    }
                  }}
                  input={
                    <OutlinedInput
                      classes={{ root: classes.reasonInputRoot }}
                    />
                  }
                  renderValue={renderReasonValue}
                >
                  {renderReasons()}
                </Select>
              </FormControl>
            )}
            {isReOpenAction && (
              <FormControl fullWidth className={classes.reason}>
                <FormHelperText className={classes.formHelper}>
                  Assignee
                </FormHelperText>
                <Select
                  value={assigneeId}
                  onChange={handleChangeAssignee}
                  classes={{
                    select: classes.selectText
                  }}
                  MenuProps={{
                    classes: {
                      list: classes.selectList
                    }
                  }}
                  input={
                    <OutlinedInput
                      classes={{ root: classes.reasonInputRoot }}
                    />
                  }
                  renderValue={renderAssigneeValue}
                >
                  {renderAssignees()}
                </Select>
              </FormControl>
            )}
            {isUndoCompleteAction && (
              <FormControl fullWidth className={classes.reason}>
                {isSupervisor ? (
                  <>
                    <FormHelperText className={classes.formHelper}>
                      Assignee
                    </FormHelperText>
                    <Select
                      value={assigneeId}
                      onChange={handleChangeAssignee}
                      classes={{
                        select: classes.selectText
                      }}
                      MenuProps={{
                        classes: {
                          list: classes.selectList
                        }
                      }}
                      input={
                        <OutlinedInput
                          classes={{ root: classes.reasonInputRoot }}
                        />
                      }
                      renderValue={renderAssigneeValue}
                    >
                      {renderAssignees()}
                    </Select>
                  </>
                ) : (
                  <div className={classes.formHelperText}>
                    The status for this work order will be set back to
                    "assigned" and will still be assigned to you.
                  </div>
                )}
              </FormControl>
            )}
            {!(isAssignAction || isReassignAction) && (
              <FormControl fullWidth className={classes.note}>
                <FormHelperText className={classes.formHelper}>
                  Note <span className={classes.formHelperSub}>(Optional)</span>
                </FormHelperText>
                <Textarea
                  className={classes.noteTextarea}
                  classNameCounter={classes.noteCounter}
                  maxLength={200}
                  rows={5}
                  value={note}
                  placeholder="Add note"
                  onChange={handleNoteChange}
                />
              </FormControl>
            )}
          </div>
          <Box className={classes.actions}>
            <Button
              className={classes.action}
              onClick={handleClose}
              data-test={"work-order-popup__cancel-button"}
            >
              CANCEL
            </Button>
            {isReassignAction && (
              <Button
                className={clsx(classes.action, classes.actionsUnassign)}
                onClick={handleUnassignClick}
                disabled={!assigneeId}
                data-test={"work-order-popup__unassign-button"}
              >
                UNASSIGN
              </Button>
            )}
            <Button
              className={clsx(
                classes.action,
                { [classes.actionsAlert]: isCloseAction },
                { [classes.actionsComplete]: !isCloseAction },
                { [classes.actionsUndoComplete]: isUndoCompleteAction },
                { [classes.actionsReopen]: isReOpenAction }
              )}
              disabled={
                (isAssignAction && !assigneeId) ||
                (isReassignAction && assigneeId === workOrder?.assignedUser?.id)
              }
              onClick={handleConfirm}
              data-test={"work-order-popup__confirm-button"}
            >
              {getButtonTitle}
            </Button>
          </Box>
        </>
      )
    );
  };

  const commonProps = {
    classes: {
      root: clsx(classes.rootPopover, {
        ["darkBackground"]: matchesDownSM600
      }),
      paper: classes.paper
    },
    className: className,
    open: isOpen,
    onClose: handleClose,
    TransitionProps: {
      onEnter: () => {
        setTransitionActive(true);
      },
      onExit: () => {
        setTransitionActive(false);
      },
      onExited: () => {
        handleTransitionExit();
      }
    }
  };

  return (
    <>
      {matchesDownSM600 ? (
        <Dialog {...commonProps}>{renderPopoverContent()}</Dialog>
      ) : (
        <Popover anchorEl={anchor} {...PopoverProps} {...commonProps}>
          {renderPopoverContent()}
        </Popover>
      )}
    </>
  );
};

export default WorkOrderPopover;
