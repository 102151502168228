import { MuiTheme } from "theme";
import { makeStyles } from "@material-ui/core/styles";
import Breakpoints from "common/breakpoints";
import { EFlagPriority, flagLevels } from "../helper";

export default makeStyles((theme: MuiTheme) => {
  const { primary } = theme.palette;
  const { lowest, medium, highest } = flagLevels;

  return {
    flagCell: {
      display: "flex",
      alignItems: "center"
    },
    flaggedButton: {
      fontSize: 12,
      width: 107,
      height: 24,
      borderRadius: 3,
      color: primary.dark,
      border: `1px solid ${primary.light}`,
      marginLeft: 10,
      "&.active": {
        color: "#fff"
      },
      "& .MuiButton-startIcon": {
        marginRight: 6
      },
      "& .MuiSvgIcon-root": {
        fontSize: 14,
        position: "relative",
        bottom: 1
      },
      [theme.breakpoints.down(Breakpoints.XS_420)]: {
        height: 21,
        width: 94
      }
    },
    [EFlagPriority.LOWEST]: {
      borderColor: "#be4400",
      backgroundColor: lowest,
      "&:hover": {
        backgroundColor: lowest
      }
    },
    [EFlagPriority.MEDIUM]: {
      borderColor: "#b00015",
      backgroundColor: medium,
      "&:hover": {
        backgroundColor: medium
      }
    },
    [EFlagPriority.HIGHEST]: {
      borderColor: "#4d008a",
      backgroundColor: highest,
      "&:hover": {
        backgroundColor: highest
      }
    },
    flaggedInfo: {
      marginLeft: 10,
      [theme.breakpoints.down(Breakpoints.XS_420)]: {
        fontSize: 12
      }
    },
    loader: {
      paddingLeft: 50,
      justifyContent: "start"
    }
  };
});
