import { makeStyles } from "@material-ui/core/styles";
import { MuiTheme } from "theme";

export default makeStyles((theme: MuiTheme) => {
  const {
    palette: { primary, secondary }
  } = theme;

  return {
    rootFormControl: {
      position: "relative",
      "& .MuiInputBase-root": {
        borderRadius: 3
      },
      "& .MuiSelect-outlined.MuiSelect-outlined": {
        paddingRight: 0
      },
      "& .MuiOutlinedInput-notchedOutline": {
        border: "#1px solid #ccc"
      },
      "&.selectedValue .MuiOutlinedInput-notchedOutline": {
        borderColor: primary.main
      },
      "& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline": {
        border: `1px solid ${secondary.dark}`
      },
      "&.expanded .MuiOutlinedInput-notchedOutline": {
        border: `1px solid ${secondary.dark}`
      },
      "&.selectedValue .MuiSelect-icon": {
        color: primary.main
      },
      "& .MuiOutlinedInput-root.Mui-focused .MuiSelect-icon": {
        color: secondary.dark
      },
      "& .MuiInputLabel-outlined": {
        color: "#2d2d2d",
        fontSize: 12,
        transform: "translate(14px, 12px)"
      },
      "& .MuiInputLabel-outlined.MuiInputLabel-shrink": {
        transform: "translate(14px, -6px) scale(0.9)"
      }
    },
    selectRoot: {
      display: "initial",
      fontSize: 12,
      padding: "8px 14px",
      width: "100%",
      borderRadius: 3,
      "&:focus": {
        backgroundColor: "transparent"
      },
      "&.MuiFormLabel-root": {
        top: -10,
        fontSize: 12,
        textTransform: "capitalize"
      },
      "& + .MuiSelect-nativeInput": {
        height: "100%"
      },
      "&.MuiSelect-outlined.MuiSelect-outlined": {
        paddingRight: 30
      }
    },
    selectLoader: {
      width: 30,
      padding: 0,
      position: "absolute",
      right: 28
    },
    selectList: {
      padding: 0,
      boxShadow: "0px 0px 12px 0px rgba(0, 0, 0, 0.25)",
      borderRadius: 3,
      "& .MuiListItem-root": {
        fontSize: 12,
        color: primary.main,
        minHeight: 39,
        borderBottom: "1px solid #eee",
        whiteSpace: "initial",
        "&.Mui-selected": {
          backgroundColor: "rgba(97, 198, 158, 0.15)"
        },
        "&:last-child": {
          borderBottom: "none"
        }
      }
    },
    selectPaper: {
      width: "min-content",
      "&.MuiPaper-elevation8": {
        boxShadow:
          "0px -5px 10px -3px rgb(0 0 0 / 20%), 0px 8px 10px 1px rgb(0 0 0 / 14%), 0px 3px 14px 2px rgb(0 0 0 / 12%)"
      }
    },
    otherField: {
      padding: 0
    },
    otherButton: {
      textTransform: "capitalize",
      padding: "0 16px",
      justifyContent: "flex-start",
      fontSize: 12,
      width: "100%",
      height: 39
    },
    customField: {
      display: "none"
    },
    otherPopup: {
      width: 302,
      height: 155,
      padding: "initial 20px 10px",
      borderRadius: 6,
      backgroundColor: "#fff",
      boxShadow: "0px 0px 12px 0px rgba(0, 0, 0, 0.4)"
    },
    otherPopupHeader: {
      color: "#333",
      fontSize: 20,
      fontWeight: 500,
      marginBottom: 15,
      lineHeight: "20px"
    },
    popupTextField: {
      width: "100%",
      marginBottom: 24,
      borderRadius: 3,
      "& .MuiInputBase-input": {
        padding: 10,
        fontSize: 13
      },
      "& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline": {
        border: `1px solid ${secondary.dark}`
      }
    },
    popupActions: {
      display: "flex",
      justifyContent: "flex-end"
    },
    popupButton: {
      marginLeft: 10,
      padding: "4px 8px",
      minWidth: 40,
      color: "#333",
      fontFamily: "SuisseIntlMedium, SuisseIntlRegular",
      "& .MuiButton-label": {
        height: 20
      }
    },
    saveButton: {
      color: secondary.dark,
      marginRight: -8
    }
  };
});
