import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import startOfToday from "date-fns/startOfToday";
import format from "date-fns/format";
import parseISO from "date-fns/parseISO";
import getMonth from "date-fns/getMonth";
import getYear from "date-fns/getYear";
import isSameDay from "date-fns/isSameDay";
import groupBy from "lodash/groupBy";
import first from "lodash/first";
import last from "lodash/last";

import clsx from "clsx";

import { racehorse360 } from "@tsg/1st-grpc-web";

import Typography from "@material-ui/core/Typography";
import IFacilityBlackoutDay from "interfaces/ICalendarDay";
import {
  currentTimeZoneString,
  getNextDates,
  mergeDates
} from "utils/date-utils";
import ScrollSyncPanel from "components/ScrollSync/ScrollSyncPanel";
import ScheduleHeader from "./ScheduleHeader";
import { useRacehorse360Api } from "hooks/api";
import WorkoutsPageState from "interfaces/WorkoutsPageState";
import Loader from "components/Loader";
import FreeDate from "./FreeDate";
import ScheduledDate from "./ScheduledDate";
import {
  WorkoutRequestOrPlaceHolder,
  isWorkoutRequest
} from "utils/workoutRequest";
import { usePrevious } from "hooks/state";
import { SortOrder } from "interfaces/SortOrder";
import useStyles from "./styles";

interface ScheduleMonth {
  days: WorkoutRequestOrPlaceHolder[];
  trackedDays: racehorse360.IWorkoutRequest[];
  firstTrackedDay: racehorse360.IWorkoutRequest;
  lastTrackedDay: racehorse360.IWorkoutRequest;
}

export interface Props {
  horseId: string;
  horseName: string;
  horseOnLists: racehorse360.IHorseOnList[];
  facilityBlackoutsCalendar: IFacilityBlackoutDay[];
  onDateClick: (
    horseId: string,
    workoutRequest: racehorse360.IWorkoutRequest,
    anchor: HTMLDivElement
  ) => void;
  updatingWorkoutRequests?: Record<string, boolean>;
}
const defaultResult = { workoutRequests: [] };

const getUpdatingCount = (
  updatingWorkoutRequests: Record<string, boolean> = {}
) => {
  return Object.values(updatingWorkoutRequests).filter(Boolean).length;
};

const DAYS_PER_SCREEN: number = 35;

const HorseWorkoutSchedule = (props: Props) => {
  const {
    horseId,
    horseName,
    horseOnLists,
    facilityBlackoutsCalendar,
    onDateClick,
    updatingWorkoutRequests
  } = props;
  const classes = useStyles();
  const today = startOfToday();
  const listWorkoutRequestsQueryKey = `${horseId}-list-workout-requests`;
  const updatingCount = getUpdatingCount(updatingWorkoutRequests);
  const prevUpdatingCount = usePrevious(updatingCount);

  const { scheduledDatesOnly: scheduledOnly, selectedFacility } = useSelector(
    (state: { workoutsPage: WorkoutsPageState }) => state?.workoutsPage
  );

  const [skipAnimation, setSkipAnimation] = useState<boolean>(scheduledOnly);
  const [successFlashCount, setSuccessFlashCount] = useState<number>(0);
  const nextDates = getNextDates(today, DAYS_PER_SCREEN).map(date => ({
    date
  }));

  const isFacilityBlackout = (day: WorkoutRequestOrPlaceHolder): boolean => {
    if (!facilityBlackoutsCalendar) {
      return false;
    }

    let date: Date;

    if (day instanceof racehorse360.WorkoutRequest) {
      date = parseISO(day.date);
    } else {
      date = parseISO(day.date);
    }

    return !!facilityBlackoutsCalendar.some(day => {
      return isSameDay(day.date, date) && day.facilityBlackout;
    });
  };

  const { useListWorkoutRequests } = useRacehorse360Api();

  const { isLoading, data, isFetching } = useListWorkoutRequests(
    listWorkoutRequestsQueryKey,
    {
      query: {
        horseIds: [horseId],
        date: {
          range: {
            relative: {
              // starting two days ago
              startDate: { value: -2 },
              timezone: currentTimeZoneString()
            }
          }
        },
        statuses: [
          racehorse360.WorkoutRequestStatus.WORKOUT_REQUEST_STATUS_REQUESTED,
          racehorse360.WorkoutRequestStatus.WORKOUT_REQUEST_STATUS_EXAM_PENDING,
          racehorse360.WorkoutRequestStatus.WORKOUT_REQUEST_STATUS_APPROVED
        ]
      },
      pagingOptions: {
        maxResults: 37
      },
      getOptions: {
        select: [
          "id",
          "facility.name",
          "facility.code",
          "facility.background_color",
          "facility.foreground_color",
          "facility.stroke_color",
          "facility.shut_off_period_in_hours",
          "facility.shut_off_hours",
          "facility.timezone",
          "date",
          "status",
          "hasComment",
          "horse.id"
        ],
        orderBy: [`date ${SortOrder.ASC}`]
      }
    },
    {
      keepPreviousData: true,
      staleTime: 5000,
      onError: error => console.error(error)
    }
  );

  const { workoutRequests } = data || defaultResult;

  const schedule = workoutRequests.length
    ? mergeDates(nextDates, workoutRequests)
    : nextDates;

  const scheduleGroups = groupBy(
    schedule,
    day => getYear(parseISO(day.date)) + "-" + getMonth(parseISO(day.date))
  );
  const scheduleGroupKeys = Object.keys(scheduleGroups);

  const scheduleMonths = scheduleGroupKeys.reduce(
    (result, scheduleGroupKey) => {
      const days = scheduleGroups[scheduleGroupKey];
      const trackedDays = days.filter(isWorkoutRequest);
      const firstTrackedDay = first(trackedDays);
      const lastTrackedDay = last(trackedDays);

      result.push({
        days,
        trackedDays,
        firstTrackedDay,
        lastTrackedDay
      });

      return result;
    },
    [] as ScheduleMonth[]
  );

  const hasTrackedDays = schedule.some(isWorkoutRequest);
  const hasNoWorkoutsTitle = scheduledOnly ? !hasTrackedDays : !schedule.length;

  const showSuccessMessage = () => {
    setSuccessFlashCount(successFlashCount + 1);
  };

  const handleDateClick = (
    workoutRequest: racehorse360.IWorkoutRequest,
    anchor: HTMLDivElement
  ) => {
    onDateClick(horseId, workoutRequest, anchor);
  };

  useEffect(() => {
    let timeoutId;
    if (scheduledOnly) {
      setSkipAnimation(false);
    } else {
      timeoutId = setTimeout(() => {
        setSkipAnimation(true);
      }, 300);
    }
    return () => {
      clearTimeout(timeoutId);
    };
  }, [scheduledOnly]);

  useEffect(() => {
    if (prevUpdatingCount > updatingCount) {
      showSuccessMessage();
    }
  }, [updatingCount]);

  return (
    <div
      className={clsx(classes.root, {
        [classes.scheduledOnly]: scheduledOnly,
        [classes.skipAnimation]: skipAnimation
      })}
      data-test-element="HORSE_ROW"
      data-test-value={horseId}
    >
      <ScheduleHeader
        horseId={horseId}
        horseName={horseName}
        schedule={workoutRequests}
        successFlashCount={successFlashCount}
        horseOnLists={horseOnLists}
      />
      {isLoading ? (
        <Loader className={classes.datesList} />
      ) : (
        <ScrollSyncPanel className={clsx(classes.datesList)}>
          {scheduleMonths?.map((scheduleMonth, scheduleMonthIndex) => {
            let hasMonthDivider;
            if (scheduledOnly) {
              if (scheduleMonth.trackedDays.length) {
                scheduleMonths
                  .slice(0, scheduleMonthIndex)
                  .forEach(prevMonth => {
                    hasMonthDivider =
                      hasMonthDivider || !!prevMonth.firstTrackedDay;
                  });
              }
            } else {
              hasMonthDivider = scheduleMonthIndex > 0;
            }

            return (
              <React.Fragment key={scheduleMonthIndex}>
                <div className={classes.monthGroup}>
                  {hasMonthDivider && (
                    <div className={classes.monthDivider}>
                      <div className={classes.monthDividerText}>
                        {format(
                          parseISO(scheduleMonth.days[0].date),
                          "MMMM"
                        ).toUpperCase()}
                      </div>
                    </div>
                  )}

                  {scheduleMonth.days.map((day, dayIndex) => {
                    const dataAttributes = {
                      ["data-test-element"]: "CALENDAR_DAY",
                      ["data-test-value"]: day.date
                    };

                    return isWorkoutRequest(day) ? (
                      <ScheduledDate
                        key={dayIndex}
                        workoutRequestData={day}
                        isToday={scheduleMonthIndex + dayIndex === 0}
                        isLoading={
                          updatingWorkoutRequests[`${horseId}-${day.date}`]
                        }
                        onClick={handleDateClick}
                        {...dataAttributes}
                      />
                    ) : (
                      <FreeDate
                        key={dayIndex}
                        className={clsx(classes.scheduleDate, classes.freeDate)}
                        date={day.date}
                        isToday={scheduleMonthIndex + dayIndex === 0}
                        isLoading={
                          updatingWorkoutRequests[
                            `${horseId}-${
                              (day as racehorse360.WorkoutRequest).date
                            }`
                          ]
                        }
                        isDisabled={isFacilityBlackout(day)}
                        onClick={handleDateClick}
                        isListFetching={isFetching}
                        selectedFacility={selectedFacility}
                        {...dataAttributes}
                      />
                    );
                  })}
                </div>
              </React.Fragment>
            );
          })}
          {hasNoWorkoutsTitle && (
            <Typography variant={"caption"} className={classes.noSchedule}>
              No Workouts Scheduled
            </Typography>
          )}
        </ScrollSyncPanel>
      )}
    </div>
  );
};

export default HorseWorkoutSchedule;
