import React, { ReactNode, Dispatch } from "react";

import { racehorse360 } from "@tsg/1st-grpc-web";

import Button from "@material-ui/core/Button";

import {
  checkStableEntryStatus,
  StallApplicationCheckboxType,
  TAction
} from "../helper";
import { transformViewDate } from "utils/date-utils";
import StableEntryStateCheckbox from "../StableEntryStateCheckbox/StableEntryStateCheckbox";
import PastPerformanceItem from "./PastPerformanceItem";

import useStyles from "./styles";

interface IProps {
  shouldDisplayPPs?: boolean;
  entry: racehorse360.IStableEntry;
  dispatch: Dispatch<TAction>;
}

const StableEntry = (props: IProps) => {
  const { shouldDisplayPPs, entry, dispatch } = props;
  const classes = useStyles();

  const horseName = entry.customHorseName || entry.horse.name;
  const { isApproved, isDenied } = checkStableEntryStatus(entry.status);
  const horseInfoContent = `${
    entry.raceReadyDate
      ? `- Race Ready ${transformViewDate(entry.raceReadyDate)}`
      : ""
  }`;

  const renderPPs = () => {
    if (!entry.horse) return;

    return entry.horse.lastTenPastPerformances?.map(pp => (
      <PastPerformanceItem key={pp.id} pastPerformance={pp} />
    ));
  };

  return (
    <div className={classes.root}>
      <div className={classes.stableEntry}>
        <div>
          <div className={classes.horseName}>{horseName}</div>
          <div className={classes.horseInfo}>
            <Button className={classes.viewHorseDetail} disableRipple={true}>
              View Details
            </Button>
            <span>&nbsp;{horseInfoContent}</span>
          </div>
        </div>

        <div className={classes.checkboxes}>
          <StableEntryStateCheckbox
            type={StallApplicationCheckboxType.DENY}
            checked={isDenied}
            entryId={entry.id}
            dispatch={dispatch}
          />
          <StableEntryStateCheckbox
            type={StallApplicationCheckboxType.APPROVE}
            checked={isApproved}
            entryId={entry.id}
            dispatch={dispatch}
          />
        </div>
      </div>

      {shouldDisplayPPs && <div>{renderPPs()}</div>}
    </div>
  );
};

export default React.memo(StableEntry);
