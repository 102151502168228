import React, { ChangeEvent, useRef } from "react";
import clsx from "clsx";
import isBefore from "date-fns/isBefore";
import DateFnsUtils from "@date-io/date-fns";

import Button from "@material-ui/core/Button";
import Checkbox from "@material-ui/core/Checkbox";
import FormControl from "@material-ui/core/FormControl";
import IconButton from "@material-ui/core/IconButton";
import InputLabel from "@material-ui/core/InputLabel";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import ListItemText from "@material-ui/core/ListItemText";
import MenuItem from "@material-ui/core/MenuItem";
import Select from "@material-ui/core/Select";
import TextField from "@material-ui/core/TextField";
import { Theme } from "@material-ui/core/styles";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import RadioButtonCheckedOutlinedIcon from "@material-ui/icons/RadioButtonCheckedOutlined";
import RadioButtonUncheckedOutlinedIcon from "@material-ui/icons/RadioButtonUncheckedOutlined";
import { DatePicker, MuiPickersUtilsProvider } from "@material-ui/pickers";

import { phoneNumberMask } from "common/constants";
import ButtonNumberInput from "components/ButtonNumberInput";
import Calendar4x3 from "components/Icons/Calendar4x3";
import FileUploadIcon from "components/Icons/FileUpload";
import NumberInput from "components/NumberInput";

import { FIELD_TYPE, IField, IOption } from "./constants";
import { getMultiselectPreviewString } from "./helpers";
import Breakpoints from "common/breakpoints";
import useStyles from "./styles";

interface IProps {
  field: IField;
  value: Date | string | number | boolean | string[] | File;
  moduleName: string;
  onChange: (event) => void;
  onChangeCustom?: (fieldName) => (value) => void;
  className?: string;
  error?: string;
  helperText?: string;
}

const FormField = (props: IProps) => {
  const {
    field,
    moduleName,
    value,
    className,
    onChange,
    onChangeCustom,
    error,
    helperText
  } = props;
  const classes = useStyles();
  const inputFileRef = useRef(null);
  const multipleSelectedValues = value as string[];

  const matchesDownSM600 = useMediaQuery((theme: Theme) =>
    theme.breakpoints.down(Breakpoints.SM_600)
  );

  const withFormControl = node => {
    const isMobileView = [
      field.shouldApplyMobileStyles,
      matchesDownSM600
    ].every(Boolean);

    return (
      <FormControl
        key={moduleName + field.name}
        className={clsx(classes.field, ...formControlClasses, className)}
        variant={"outlined"}
      >
        <InputLabel
          shrink={false}
          className={clsx(classes.textFieldLabel, {
            [classes.rightAligning]: isMobileView
          })}
          disableAnimation
        >
          {field.label}
          {field.optional && (
            <span className={classes.optional}>&nbsp;(Optional)</span>
          )}
        </InputLabel>
        {node}
      </FormControl>
    );
  };

  const formControlClasses = [];
  if (field.width) formControlClasses.push(classes[`field${field.width}`]);
  if (field.mobileWidth)
    formControlClasses.push(classes[`fieldMobile${field.mobileWidth}`]);

  const isSomeOfBranchSelected = (option: IOption) =>
    option.options.some(option => multipleSelectedValues.includes(option.id));

  const isEveryOfBranchSelected = (option: IOption) =>
    option.options.every(option => multipleSelectedValues.includes(option.id));

  const shouldDisableDate =
    (field: IField) =>
    (date: Date): boolean => {
      return isBefore(date, field.min);
    };

  const handleUploadFileChange = (event: ChangeEvent<HTMLInputElement>) => {
    const file = event.target.files[0];

    onChangeCustom?.(field.name)(file);
  };

  const handleCustomCheckboxClick = value => () => {
    onChangeCustom?.(field.name)(value);
  };

  const handleMultiselectChange = (event, child) => {
    const clickedOptionValue = child.props.value;
    const isClickedOptionSelected = child.props.selected;
    const rootOption = field.options.find(
      root =>
        root.id === clickedOptionValue ||
        root.options.find(option => option.id === clickedOptionValue)
    );
    const clickedOption =
      rootOption.id === clickedOptionValue
        ? rootOption
        : rootOption.options.find(option => option.id === clickedOptionValue);
    const hasChildren = Boolean(clickedOption.options?.length);
    const valuesSet = new Set(event.target.value);
    const internalOptions = rootOption.options.map(op => op.id);

    if (hasChildren) {
      if (isClickedOptionSelected) {
        valuesSet.clear();
      } else {
        internalOptions.forEach(op => valuesSet.add(op));
      }
    } else {
      if (isClickedOptionSelected) {
        valuesSet.delete(clickedOptionValue);
      } else {
        valuesSet.add(rootOption.id);
        valuesSet.add(clickedOptionValue);
      }
    }

    onChangeCustom?.(field.name)([...valuesSet]);
  };

  const renderMultiselectValue = (selected: string[] = []) => {
    if (!selected.length) {
      return <span className={classes.fieldSelectPlaceholder}>Select One</span>;
    }
    return getMultiselectPreviewString(selected, field.options);
  };

  const renderField = (field, moduleName) => {
    const hasError = [!field.optional, error, field.isValidated].every(Boolean);
    const isSelectDisabled = [
      field.disabled,
      value === field.options?.[0]?.id
    ].every(Boolean);

    switch (field.type) {
      case FIELD_TYPE.TEXT:
        return withFormControl(
          <TextField
            id={field.name}
            InputProps={{
              classes: {
                input: classes.textFieldInput,
                root: className
              }
            }}
            inputProps={{ maxLength: field.maxLength }}
            name={field.name}
            placeholder={field.placeholder}
            value={value}
            onChange={onChangeCustom(field.name)}
            disabled={field.disabled}
            variant="outlined"
            error={hasError}
            helperText={helperText}
          />
        );
      case FIELD_TYPE.UPLOAD:
        return withFormControl(
          <TextField
            id={field.name}
            InputProps={{
              classes: {
                input: classes.textFieldInput,
                root: className
              },
              endAdornment: (
                <label htmlFor="upload-file">
                  <input
                    id="upload-file"
                    hidden
                    type="file"
                    accept="application/pdf"
                    ref={inputFileRef}
                    disabled={field.disabled}
                    onChange={handleUploadFileChange}
                  />
                  <IconButton
                    className={classes.uploadFieldButton}
                    size={"small"}
                    disabled={field.disabled}
                    onClick={() => {
                      inputFileRef?.current.click();
                    }}
                  >
                    <FileUploadIcon className={classes.uploadFieldIcon} />
                  </IconButton>
                </label>
              ),
              readOnly: true
            }}
            inputProps={{ maxLength: field.maxLength }}
            name={field.name}
            placeholder={field.placeholder}
            value={((value as File) || { name: "" }).name}
            disabled={field.disabled}
            variant="outlined"
          />
        );
      case FIELD_TYPE.NUMBER:
        return withFormControl(
          <ButtonNumberInput
            onChange={onChangeCustom(field.name)}
            hideButtons={false}
            TextFieldProps={{
              id: field.name,
              InputProps: {
                classes: {
                  input: classes.textFieldInput
                }
              },
              name: field.name,
              placeholder: field.placeholder,
              value: value,
              onChange: onChange,
              variant: "outlined",
              error: hasError,
              helperText: helperText
            }}
          />
        );
      case FIELD_TYPE.DATEPICKER:
        return (
          <FormControl
            key={moduleName + field.name}
            className={clsx(classes.field, ...formControlClasses, className)}
          >
            <MuiPickersUtilsProvider utils={DateFnsUtils}>
              <InputLabel className={classes.textFieldLabel} disableAnimation>
                {field.label}
                {field.optional && (
                  <span className={classes.optional}>&nbsp;(Optional)</span>
                )}
              </InputLabel>
              <DatePicker
                autoOk
                disableToolbar
                format={"MM/dd/yyyy"}
                InputProps={{
                  name: field.name,
                  classes: {
                    input: classes.textFieldInput
                  },
                  endAdornment: (
                    <Calendar4x3 className={classes.datePickerIcon} />
                  )
                }}
                value={value as unknown as Date}
                onChange={onChangeCustom(field.name)}
                size={"small"}
                variant={"inline"}
                inputVariant={"outlined"}
                placeholder={"MM/DD/YYYY"}
                disablePast={true}
                minDate={field.min}
                minDateMessage={error}
                shouldDisableDate={shouldDisableDate(field)}
                initialFocusedDate={(value as unknown as Date) || field.min}
                disabled={field.disabled}
                error={hasError}
                helperText={helperText}
              />
            </MuiPickersUtilsProvider>
          </FormControl>
        );
      case FIELD_TYPE.TEXTAREA:
        return withFormControl(
          <TextField
            id={field.name}
            InputProps={{
              classes: {
                input: classes.textFieldInput,
                multiline: classes.textFieldInputMultiline
              }
            }}
            inputProps={{ maxLength: field.maxLength }}
            minRows={4}
            multiline
            name={field.name}
            placeholder={field.placeholder}
            value={value}
            onChange={onChange}
            disabled={field.disabled}
            variant="outlined"
            error={hasError}
            helperText={helperText}
          />
        );
      case FIELD_TYPE.SELECT:
        return withFormControl(
          <TextField
            name={field.name}
            classes={{
              root: classes.fieldSelectRoot
            }}
            InputProps={{
              classes: {
                input: classes.selectFieldInput
              }
            }}
            select
            variant={"outlined"}
            fullWidth={true}
            value={value}
            onChange={onChange}
            error={hasError}
            helperText={helperText}
            disabled={isSelectDisabled}
          >
            {field.options.map(option => (
              <MenuItem key={option.id} value={option.id}>
                <div>{option.name}</div>
              </MenuItem>
            ))}
          </TextField>
        );
      case FIELD_TYPE.MULTISELECT:
        return withFormControl(
          <Select
            name={field.name}
            classes={{
              root: classes.fieldMultipleSelectRoot,
              select: classes.fieldSelectSelect
            }}
            renderValue={renderMultiselectValue}
            multiple
            displayEmpty
            fullWidth={true}
            value={value}
            onChange={handleMultiselectChange}
            MenuProps={{
              getContentAnchorEl: null,
              anchorOrigin: {
                vertical: "bottom",
                horizontal: "center"
              },
              transformOrigin: {
                vertical: "top",
                horizontal: "center"
              },
              variant: "menu"
            }}
          >
            {field.options.map(option => {
              const selected = new Set(value as string[]);
              const isRootSelected = selected.has(option.id);
              const isIndeterminate =
                isSomeOfBranchSelected(option) &&
                !isEveryOfBranchSelected(option);

              if (option.options) {
                return [
                  <MenuItem
                    key={option.id}
                    value={option.id}
                    selected={isRootSelected}
                  >
                    <ListItemIcon>
                      <Checkbox
                        classes={{
                          checked: classes.fieldSelectCheckboxChecked,
                          indeterminate: classes.indeterminateIcon
                        }}
                        checked={isRootSelected}
                        indeterminate={isIndeterminate}
                      />
                    </ListItemIcon>
                    <ListItemText
                      classes={{ primary: classes.optionText }}
                      primary={option.name.toLowerCase()}
                    />
                  </MenuItem>,
                  ...option.options.map(subOption => {
                    const isSelected = selected.has(subOption.id);

                    return (
                      <MenuItem
                        className={classes.subOption}
                        key={subOption.id}
                        value={subOption.id}
                        selected={isSelected}
                      >
                        <ListItemIcon>
                          <Checkbox
                            classes={{
                              checked: classes.fieldSelectCheckboxChecked
                            }}
                            checked={isSelected}
                          />
                        </ListItemIcon>
                        <ListItemText
                          classes={{ primary: classes.optionText }}
                          primary={subOption.name.toLowerCase()}
                        />
                      </MenuItem>
                    );
                  })
                ];
              }

              return (
                <MenuItem
                  key={option.id}
                  value={option.id}
                  selected={isRootSelected}
                >
                  <ListItemIcon>
                    <Checkbox checked={isRootSelected} />
                  </ListItemIcon>
                  <ListItemText primary={option.name} />
                </MenuItem>
              );
            })}
          </Select>
        );
      case FIELD_TYPE.PHONE:
        return withFormControl(
          <NumberInput
            mask={phoneNumberMask}
            variant="outlined"
            outlined
            name={field.name}
            value={value}
            onTextChange={onChangeCustom(field.name)}
            InputProps={{
              classes: {
                input: classes.textFieldInput
              }
            }}
            error={hasError}
            helperText={helperText}
          />
        );
      case FIELD_TYPE.BOOLCHOICE:
        return withFormControl(
          <>
            <div className={classes.yesNoButtonWrapper}>
              <Button
                className={clsx({
                  [classes.yesNoButtonSelected]:
                    typeof value === "boolean" && value
                })}
                classes={{
                  root: classes.yesNoButton,
                  startIcon: clsx(classes.yesNoButtonStartIcon, {
                    [classes.validationError]: hasError
                  }),
                  label: classes.yesNoButtonLabel
                }}
                startIcon={
                  value ? (
                    <RadioButtonCheckedOutlinedIcon />
                  ) : (
                    <RadioButtonUncheckedOutlinedIcon />
                  )
                }
                onClick={handleCustomCheckboxClick(true)}
              >
                <span
                  className={clsx(classes.yesNoButtonText, {
                    [classes.validationError]: hasError
                  })}
                >
                  Yes
                </span>
              </Button>
              <Button
                className={clsx({
                  [classes.yesNoButtonSelected]:
                    typeof value === "boolean" && !value
                })}
                classes={{
                  root: classes.yesNoButton,
                  startIcon: clsx(classes.yesNoButtonStartIcon, {
                    [classes.validationError]: hasError
                  }),
                  label: classes.yesNoButtonLabel
                }}
                startIcon={
                  typeof value === "boolean" && !value ? (
                    <RadioButtonCheckedOutlinedIcon />
                  ) : (
                    <RadioButtonUncheckedOutlinedIcon />
                  )
                }
                onClick={handleCustomCheckboxClick(false)}
              >
                <span
                  className={clsx(classes.yesNoButtonText, {
                    [classes.validationError]: hasError
                  })}
                >
                  No
                </span>
              </Button>
            </div>
            {hasError && (
              <div className={classes.boolChoiceError}>{helperText}</div>
            )}
          </>
        );
      default:
        return <></>;
    }
  };

  return renderField(field, moduleName);
};

export default FormField;
