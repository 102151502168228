import React from "react";
import { push } from "connected-react-router";
import { useDispatch } from "react-redux";
import Button from "@material-ui/core/Button";

export interface Props {
  className?: string;
  to?: string;
  onClick?: (event: React.MouseEvent) => void;
  children?: React.ReactNode;
  "aria-label"?: string;
  style?: React.CSSProperties;
  button?: boolean;
  tag?: string;
  dataTestLabel?: string;
}

const Link = React.memo((props: Props) => {
  const {
    className,
    to,
    children,
    "aria-label": ariaLabel,
    onClick,
    style,
    button,
    tag = "div",
    dataTestLabel
  } = props;

  const dispatch = useDispatch();

  const handleClick = (event: React.MouseEvent) => {
    if (onClick) {
      onClick(event);
    }

    if (to) {
      dispatch(push(to));
    }
  };

  if (button)
    return (
      <Button
        className={className}
        onClick={handleClick}
        aria-label={ariaLabel}
        style={style}
      >
        {children}
      </Button>
    );

  return React.createElement(
    tag,
    {
      className,
      onClick: handleClick,
      "aria-label": ariaLabel,
      style,
      "data-test": dataTestLabel
    },
    children
  );
});

export default Link;
