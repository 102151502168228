import React from "react";

import Dialog from "@material-ui/core/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import ApproveIcon from "@material-ui/icons/Check";
import DenyIcon from "@material-ui/icons/Clear";
import Button from "@material-ui/core/Button";

import { racehorse360 } from "@tsg/1st-grpc-web";

import { checkStableEntryStatus } from "../helper";
import useStyles from "./styles";

export interface IReviewFormPopupData {
  trainerName: string;
  sleepingRoomsNumber: number;
  arrivalDate: string;
  stableEntries: {
    horseName: string;
    status: racehorse360.StableEntryStatus;
  }[];
}

interface IProps {
  onClose: () => void;
  onSubmit: () => void;
  data: IReviewFormPopupData;
}

const ReviewFormPopup = (props: IProps) => {
  const { onClose, onSubmit, data } = props;
  const classes = useStyles();

  const renderStableEntriesList = () => {
    return data.stableEntries.map((item, index) => {
      const { isDenied, isApproved } = checkStableEntryStatus(item.status);
      let decisionIcon;

      if (isApproved) {
        decisionIcon = <ApproveIcon className={classes.approveIcon} />;
      }
      if (isDenied) {
        decisionIcon = <DenyIcon className={classes.denyIcon} />;
      }

      return (
        <div key={index} className={classes.stableEntryRow}>
          <span>{item.horseName}</span>
          {decisionIcon}
        </div>
      );
    });
  };

  return (
    <Dialog open={true} onClose={null} classes={{ paper: classes.dialogPaper }}>
      <DialogTitle classes={{ root: classes.dialogTitle }}>
        Submit Application Decision?
      </DialogTitle>

      <DialogContent classes={{ root: classes.dialogContent }}>
        <div className={classes.dialogContentHeader}>
          Trainer | Rooms - Arrival
        </div>
        <div className={classes.trainerName}>{data.trainerName}</div>
        <div className={classes.stableEntriesInfo}>
          {`${data.sleepingRoomsNumber} Rooms - ${data.arrivalDate}`}
        </div>
        <div className={classes.stableEntriesCount}>
          {`Stable Entries (${data.stableEntries.length})`}
        </div>

        <div className={classes.stableEntriesContainer}>
          {renderStableEntriesList()}
        </div>
      </DialogContent>

      <DialogActions classes={{ root: classes.dialogActions }}>
        <Button className={classes.backButton} onClick={onClose}>
          Back
        </Button>
        <Button className={classes.submitButton} onClick={onSubmit}>
          Submit
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default ReviewFormPopup;
