import { makeStyles } from "@material-ui/core/styles";
import { MuiTheme } from "theme";
import { AlertEnum } from "utils/horse";

const getBackgroundColorStyles = backgroundColor => ({
  backgroundColor,
  "&:hover": {
    backgroundColor
  }
});
export default makeStyles((theme: MuiTheme) => ({
  root: {
    cursor: "pointer",
    [theme.breakpoints.down("sm")]: {
      background: "transparent",
      boxShadow: "none"
    },
    [theme.breakpoints.up("md")]: {
      display: "flex",
      flexDirection: "column",
      background: "#fff",
      height: "100%",
      padding: "8px 16px",
      "& $facility": {
        width: 64,
        height: 64
      },
      "& $slider": {},
      "& $warning": {
        display: "inline-block"
      }
    },
    "&$extended": {
      cursor: "default"
    }
  },
  nonPointer: {
    cursor: "default"
  },
  extended: {
    padding: 0,
    "& $daysOffDiagram": {
      height: "auto",
      lineHeight: "normal"
    },
    "& $content": {
      flexGrow: 1,
      padding: "8px 16px",
      "@media (max-width: 1199px)": {
        padding: 8
      },
      [theme.breakpoints.down("sm")]: {
        padding: 0
      }
    },
    [theme.breakpoints.down("sm")]: {
      "& $info": {
        flexDirection: "row-reverse",
        justifyContent: "start",
        position: "absolute",
        right: 0,
        top: 0
      },
      "& $title": {
        color: "#333333",
        fontSize: 20,
        marginRight: 4,
        fontFamily: "SuisseIntlMedium, SuisseIntlRegular",
        fontWeight: "bold",
        "&.highAlert": {
          color: theme.palette.warning.dark
        },
        "&.naAlert": {
          color: theme.palette.text.secondary
        }
      }
    }
  },
  content: {
    cursor: "pointer",
    position: "relative"
  },
  info: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    marginBottom: 10,
    [theme.breakpoints.up("md")]: {
      marginBottom: 10
    }
  },
  infoTall: {
    marginBottom: 22
  },
  title: {
    fontSize: 16,
    color: "#595a5a",
    [theme.breakpoints.up("md")]: {
      fontSize: 14,
      color: theme.palette.text.primary
    },
    [theme.breakpoints.down("sm")]: {
      display: "none"
    }
  },
  facility: {
    background: "#222222",
    height: 7,
    borderRadius: 75,
    position: "relative"
  },
  slider: {},
  warning: {
    display: "none",
    [`&.${AlertEnum.na}`]: {
      display: "none"
    },
    [`&.${AlertEnum.non}`]: {
      display: "none"
    }
  },
  warningIcon: {
    fontSize: 13,
    [`&.${AlertEnum.middle}`]: {
      color: theme.palette.warning.light
    },
    [`&.${AlertEnum.moderate}`]: {
      color: theme.palette.warning.main
    },
    [`&.${AlertEnum.high}`]: {
      color: theme.palette.warning.dark
    }
  },
  daysOffDiagram: {
    textAlign: "center",
    [theme.breakpoints.up("md")]: {
      height: 64,
      margin: "0 auto"
    },
    "& &__donutChartCenterText": {
      [theme.breakpoints.up("md")]: {
        fontSize: 20
      }
    },
    "&$__text": {
      fontSize: 24,
      fontFamily: "SuisseIntlMedium, SuisseIntlRegular",
      lineHeight: "22px",
      [theme.breakpoints.down("sm")]: {
        fontSize: 16,
        paddingBottom: 12,
        fontWeight: "normal",
        color: "#595a5a",
        textAlign: "left"
      }
    }
  },
  daysOffDiagramEnhanced: {
    "&$__text": {
      fontSize: 24,
      fontFamily: "SuisseIntlMedium, SuisseIntlRegular",
      lineHeight: "22px",
      fontWeight: "bold",
      "@media (max-width: 1395px)": {
        fontSize: 22
      },
      "@media (max-width: 1199px)": {
        fontSize: 18
      },
      [theme.breakpoints.down("sm")]: {
        fontSize: 20,
        paddingBottom: 12,
        fontWeight: "normal",
        color: "#595a5a",
        textAlign: "left"
      }
    }
  },
  schedulingButton: {
    fontSize: 12,
    height: 36,
    width: "100%",
    color: theme.palette.common.white,
    borderRadius: "0 0 4px 4px",
    backgroundColor: theme.palette.secondary.dark,
    "&:hover": {
      backgroundColor: theme.palette.secondary.dark,
      textDecoration: "none"
    },
    [`&.${AlertEnum.middle}`]: getBackgroundColorStyles(
      `${theme.palette.warning.light}`
    ),
    [`&.${AlertEnum.moderate}`]: getBackgroundColorStyles(
      `${theme.palette.warning.main}`
    ),
    [`&.${AlertEnum.high}`]: getBackgroundColorStyles(
      `${theme.palette.warning.dark}`
    ),
    "& .MuiButton-label": {
      textTransform: "none"
    }
  },
  schedulingLink: {
    display: "flex",
    alignItems: "center",
    gap: 10,
    fontSize: 12,
    color: theme.palette.text.secondary,
    marginLeft: "auto",
    textAlign: "right"
  },
  schedulingLinkIcon: {
    fontSize: 16,
    color: theme.palette.text.secondary
  }
}));
