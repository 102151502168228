import { racehorse360 } from "@tsg/1st-grpc-web";

export enum StallApplicationCheckboxType {
  APPROVE = "approve",
  DENY = "deny"
}

export interface ICheckStableEntryStatus {
  isApproved: boolean;
  isDenied: boolean;
  isAwaiting: boolean;
}

export const checkStableEntryStatus = (
  status: racehorse360.StableEntryStatus
): ICheckStableEntryStatus => {
  return {
    isApproved:
      status === racehorse360.StableEntryStatus.STABLE_ENTRY_STATUS_APPROVED,
    isDenied:
      status === racehorse360.StableEntryStatus.STABLE_ENTRY_STATUS_DENIED,
    isAwaiting:
      status === racehorse360.StableEntryStatus.STABLE_ENTRY_STATUS_AWAITING
  };
};

const SET_DISPLAY_PPS = "SET_DISPLAY_PPS";
const CHANGE_ENTRY_STATUS = "CHANGE_ENTRY_STATUS";
const DENY_ALL_ENTRIES = "DENY_ALL_ENTRIES";
const APPROVE_ALL_ENTRIES = "APPROVE_ALL_ENTRIES";
const CLEAR_ALL_ENTRIES = "CLEAR_ALL_ENTRIES";

export type TAction =
  | TSetDisplayPPs
  | TChangeEntryStatus
  | TApproveAllEntries
  | TDenyAllEntries
  | TClearAllEntries;

interface IStallApplicationRacingSecretaryReviewState {
  shouldDisplayPPs: boolean;
  stableEntries: racehorse360.IStableEntry[];
}

const initialState: IStallApplicationRacingSecretaryReviewState = {
  shouldDisplayPPs: true,
  stableEntries: []
};

export const reducer = (
  state: IStallApplicationRacingSecretaryReviewState = initialState,
  action: TAction
): IStallApplicationRacingSecretaryReviewState => {
  switch (action.type) {
    case SET_DISPLAY_PPS: {
      return {
        ...state,
        shouldDisplayPPs: action.shouldDisplayPPs
      };
    }
    case CHANGE_ENTRY_STATUS: {
      return {
        ...state,
        stableEntries: state.stableEntries.map(item => {
          if (item.id === action.entryId) {
            return { ...item, status: action.newStatus };
          }

          return item;
        })
      };
    }
    case DENY_ALL_ENTRIES: {
      return {
        ...state,
        stableEntries: state.stableEntries.map(item => {
          return {
            ...item,
            status: racehorse360.StableEntryStatus.STABLE_ENTRY_STATUS_DENIED
          };
        })
      };
    }
    case APPROVE_ALL_ENTRIES: {
      return {
        ...state,
        stableEntries: state.stableEntries.map(item => {
          return {
            ...item,
            status: racehorse360.StableEntryStatus.STABLE_ENTRY_STATUS_APPROVED
          };
        })
      };
    }
    case CLEAR_ALL_ENTRIES: {
      return {
        ...state,
        stableEntries: state.stableEntries.map(item => {
          return {
            ...item,
            status: racehorse360.StableEntryStatus.STABLE_ENTRY_STATUS_AWAITING
          };
        })
      };
    }
    default:
      return state;
  }
};

export const setDisplayPPs = (shouldDisplayPPs: boolean) =>
  ({
    type: SET_DISPLAY_PPS,
    shouldDisplayPPs
  } as const);
type TSetDisplayPPs = ReturnType<typeof setDisplayPPs>;

export const changeEntryStatus = (
  entryId: string,
  newStatus: racehorse360.StableEntryStatus
) =>
  ({
    type: CHANGE_ENTRY_STATUS,
    entryId,
    newStatus
  } as const);
type TChangeEntryStatus = ReturnType<typeof changeEntryStatus>;

export const approveAllEntries = () => ({ type: APPROVE_ALL_ENTRIES } as const);
type TApproveAllEntries = ReturnType<typeof approveAllEntries>;

export const denyAllEntries = () => ({ type: DENY_ALL_ENTRIES } as const);
type TDenyAllEntries = ReturnType<typeof denyAllEntries>;

export const clearAllEntries = () => ({ type: CLEAR_ALL_ENTRIES } as const);
type TClearAllEntries = ReturnType<typeof clearAllEntries>;
