import React, { useState } from "react";
import { useHistory } from "react-router-dom";
import clsx from "clsx";
import format from "date-fns/format";
import parseISO from "date-fns/parseISO";

import Box from "@material-ui/core/Box";
import CallMadeIcon from "@material-ui/icons/CallMade";
import FormatListNumberedIcon from "@material-ui/icons/FormatListNumbered";
import Grid from "@material-ui/core/Grid";
import Paper from "@material-ui/core/Paper";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableRow from "@material-ui/core/TableRow";
import Typography from "@material-ui/core/Typography";

import { racehorse360 } from "@tsg/1st-grpc-web";

import Link from "components/Link";
import Loader from "components/Loader";
import Dialog from "components/Dialog/Dialog";
import FacilityMarkerIndicator from "components/FacilityMarkerIndicator";
import CountIcon from "components/Icons/Count";
import CustomRaceIcon from "components/Icons/CustomRace";
import HorseIcon from "components/Icons/Horse";
import LastRaceIcon from "components/Icons/LastRace";
import RoundedRectangleSegments from "components/Icons/RoundedRectangleSegments";
import { useLoggedInUser } from "components/LoggedInUserProvider";
import MatchesCount from "components/MatchesCount";

import routes from "common/routes";
import ListsInfoBlock from "./ListsInfoBlock";

import useStyles from "./styles";

export interface Props {
  workouts: racehorse360.IWorkoutRequest[];
  potentialMatches: string[];
  recentRace: racehorse360.IRaceHistory;
  onPotentialMatchesClick: () => void;
  className?: string;
  tableBodyRef: React.RefObject<HTMLDivElement>;
  totalWorkouts: number;
  isFetchingNextPage: boolean;
  matchingHorses: racehorse360.IHorseWithMatchingRacesCount[];
  onMatchingHorsesClick: () => void;
}

type RecentRaceBlockProps = {
  className?: string;
  component?: React.FunctionComponent;
  to?: string;
};

const InfoBlocks = (props: Props) => {
  const {
    className,
    workouts,
    potentialMatches,
    recentRace,
    onPotentialMatchesClick,
    totalWorkouts,
    isFetchingNextPage,
    matchingHorses,
    tableBodyRef
  } = props;

  const classes = useStyles();

  const history = useHistory();

  const { currentUser } = useLoggedInUser();

  const [scheduledWorkoutsOpen, setScheduledWorkoutsOpen] =
    useState<boolean>(false);
  const [matchingHorsesOpen, setMatchingHorsesOpen] = useState<boolean>(false);

  const recentRaceBlockProps: RecentRaceBlockProps = {};
  if (recentRace?.horse) {
    recentRaceBlockProps.component = Link;
    recentRaceBlockProps.to = routes.horseDetails.path.replace(
      ":horseId",
      recentRace?.horse?.id
    );
    recentRaceBlockProps.className = classes.pointerAvailable;
  }

  const handleScheduledWorkoutsClick = () => {
    workouts?.length && setScheduledWorkoutsOpen(true);
  };

  const handleScheduledWorkoutsClose = () => {
    setScheduledWorkoutsOpen(false);
  };

  const handleRowClick = (workoutRequest: racehorse360.IWorkoutRequest) => {
    const horseId = workoutRequest?.horse.id;
    if (horseId) {
      history.push(routes.horseDetails.path.replace(":horseId", horseId));
    }
  };

  const handleMatchingHorseClick = (
    matchingHorse: racehorse360.IHorseWithMatchingRacesCount
  ) => {
    const horseId = matchingHorse.id;
    if (horseId) {
      history.push(routes.horseDetails.path.replace(":horseId", horseId));
    }
  };

  // const handleOpenVideoModalClick = () => {
  //   setVideoModalOpen(true)
  // }

  // const handleVideoModalClose = () => {
  //   setVideoModalOpen(false)
  // }

  const handlePotentialMatchesClick = () => {
    onPotentialMatchesClick();
  };

  const handleMatchingHorsesClick = () => {
    matchingHorses?.length && setMatchingHorsesOpen(true);
  };

  const handleMatchingHorsesClose = () => {
    setMatchingHorsesOpen(false);
  };

  const isTrainerOrRacingSecretary =
    currentUser.isTrainer || currentUser.isRacingOfficial;

  return (
    <>
      <Grid container className={clsx(classes.root, className)} spacing={2}>
        <Grid item xs={6} md={3}>
          <Grid
            component={Paper}
            container
            className={clsx(classes.infoPaper, {
              [classes.pointerAvailable]: Boolean(workouts?.length)
            })}
            onClick={handleScheduledWorkoutsClick}
          >
            <Grid item className={classes.badge}>
              <HorseIcon className={classes.badgeIcon} />
            </Grid>
            <Grid item>
              <Grid container className={classes.blockContent}>
                <Grid
                  component={Typography}
                  item
                  className={classes.count}
                  xs={12}
                >
                  {totalWorkouts}
                </Grid>
                <Grid
                  component={Typography}
                  item
                  className={classes.label}
                  xs={12}
                >
                  Scheduled Workouts
                </Grid>
              </Grid>
            </Grid>
            {Boolean(workouts?.length) && (
              <Box className={classes.corner}>
                <CallMadeIcon className={classes.cornerIcon} />
              </Box>
            )}
          </Grid>
        </Grid>
        <Grid item xs={6} md={3}>
          <ListsInfoBlock />
        </Grid>
        <Grid item xs={6} md={3}>
          {!isTrainerOrRacingSecretary && (
            <Grid component={Paper} container className={classes.infoPaper}>
              <Grid
                item
                className={clsx(classes.badge, classes.potentialMatchesBadge)}
              >
                <CountIcon className={classes.badgeIcon} />
              </Grid>
              <Grid item>
                <Grid container className={classes.blockContent}>
                  <Grid
                    component={Typography}
                    item
                    className={classes.count}
                    xs={12}
                  >
                    {potentialMatches?.length}
                  </Grid>
                  <Grid
                    component={Typography}
                    item
                    className={classes.label}
                    xs={12}
                  >
                    Potential Matches
                  </Grid>
                </Grid>
              </Grid>
              <Box
                className={classes.corner}
                onClick={handlePotentialMatchesClick}
              >
                <CallMadeIcon className={classes.cornerIcon} />
              </Box>
            </Grid>
          )}
          {isTrainerOrRacingSecretary && (
            <Grid
              container
              component={Paper}
              className={clsx(classes.infoPaper, {
                [classes.pointerAvailable]: matchingHorses?.length
              })}
              onClick={handleMatchingHorsesClick}
            >
              <Grid
                item
                className={clsx(classes.badge, classes.matchingHorsesBadge)}
              >
                <FormatListNumberedIcon className={classes.badgeIcon} />
              </Grid>
              <Grid item>
                <Grid container className={classes.blockContent}>
                  <Grid
                    component={Typography}
                    item
                    className={classes.count}
                    xs={12}
                  >
                    {matchingHorses?.length ?? 0}
                  </Grid>
                  <Grid
                    component={Typography}
                    item
                    className={classes.label}
                    xs={12}
                  >
                    Matching Horses
                  </Grid>
                </Grid>
              </Grid>
              {Boolean(matchingHorses?.length) && (
                <Box className={classes.corner}>
                  <CallMadeIcon className={classes.cornerIcon} />
                </Box>
              )}
            </Grid>
          )}
        </Grid>
        <Grid item xs={6} md={3}>
          <Grid container {...recentRaceBlockProps}>
            <Paper className={classes.infoPaper}>
              <Grid item className={classes.bgBadge}>
                <RoundedRectangleSegments className={classes.roundedSegments} />
              </Grid>
              <Grid item xs={12}>
                <Grid container className={classes.blockContent}>
                  <Grid
                    component={Typography}
                    item
                    className={classes.rrTitle}
                    xs={12}
                  >
                    {recentRace?.horse?.name}
                  </Grid>
                  <Grid item className={classes.label} xs={12}>
                    <Grid container className={classes.rrTable}>
                      <Grid
                        component={"div"}
                        item
                        className={classes.ellipsisWrapper}
                      >
                        <Typography
                          className={clsx(
                            classes.rrTableHeader,
                            classes.ellipsis
                          )}
                        >
                          Recent Race
                        </Typography>
                        <Typography
                          className={clsx(
                            classes.rrTableCell,
                            classes.ellipsis
                          )}
                        >
                          {recentRace?.date
                            ? `${format(parseISO(recentRace.date), "dMMMyy")}-${
                                recentRace?.raceNumber
                              }${recentRace?.facility?.code}`
                            : ""}
                        </Typography>
                      </Grid>
                      {recentRace && recentRace.positionAtFinish ? (
                        <Grid
                          component={"div"}
                          item
                          className={classes.ellipsisWrapper}
                        >
                          <Typography
                            className={clsx(
                              classes.rrTableHeader,
                              classes.ellipsis
                            )}
                          >
                            Finish
                          </Typography>
                          <Typography className={classes.rrTableCell}>
                            {recentRace?.positionAtFinish}
                          </Typography>
                        </Grid>
                      ) : null}
                      {/*Not MVP data*/}
                      {/*<Grid component={"div"} item>*/}
                      {/*  <Typography*/}
                      {/*    className={clsx(*/}
                      {/*      classes.rrTableHeader,*/}
                      {/*      classes.borderedE*/}
                      {/*    )}*/}
                      {/*  >*/}
                      {/*    E*/}
                      {/*  </Typography>*/}
                      {/*  <Typography className={classes.rrTableCell}>*/}
                      {/*    {recentRace?.earnings || 66}*/}
                      {/*    /!*isn't coming from API TODO: add after API will be checked *!/*/}
                      {/*  </Typography>*/}
                      {/*</Grid>*/}
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
              {/*Not MVP data*/}
              {/*<Box className={classes.corner} onClick={handleOpenVideoModalClick}>*/}
              {/*  <PlayArrowIcon className={classes.cornerIcon} />*/}
              {/*</Box>*/}
            </Paper>
          </Grid>
        </Grid>
      </Grid>

      <Dialog
        title={`${totalWorkouts} Scheduled Workouts`}
        onClose={handleScheduledWorkoutsClose}
        className={classes.scheduledWorkoutsModal}
        isOpen={scheduledWorkoutsOpen}
      >
        <TableContainer ref={tableBodyRef}>
          <Table>
            <TableBody>
              {workouts?.map((workout, i) => (
                <TableRow
                  key={workout.id + i}
                  className={classes.tableWorkoutRow}
                  onClick={() => handleRowClick(workout)}
                >
                  <TableCell
                    className={clsx(
                      classes.tableWorkoutCell,
                      classes.tableWorkoutName
                    )}
                  >
                    {workout.horse?.name}
                  </TableCell>
                  <TableCell
                    className={clsx(
                      classes.tableWorkoutCell,
                      classes.tableWorkoutFacility
                    )}
                  >
                    <div className={classes.tableWorkoutFacilityCodeBullet}>
                      <FacilityMarkerIndicator
                        facility={workout.facility}
                        size={5}
                      />
                    </div>
                    <span className={classes.tableWorkoutFacilityCode}>
                      {workout.facility?.code}
                    </span>
                  </TableCell>
                  <TableCell
                    className={clsx(
                      classes.tableWorkoutCell,
                      classes.tableWorkoutDate
                    )}
                  >
                    {format(parseISO(workout.date), "M/d/yy")}
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>

        {isFetchingNextPage && <Loader infinite />}
      </Dialog>

      <Dialog
        title={`${matchingHorses?.length} Horses Matching Races`}
        onClose={handleMatchingHorsesClose}
        className={classes.scheduledWorkoutsModal}
        isOpen={matchingHorsesOpen}
      >
        <TableContainer>
          <Table>
            <TableBody>
              {matchingHorses?.map(horse => (
                <TableRow
                  key={horse.id}
                  className={classes.tableMatchingHorseRow}
                  onClick={() => handleMatchingHorseClick(horse)}
                >
                  <TableCell
                    className={clsx(
                      classes.tableMatchingHorseCell,
                      classes.tableMatchingHorseRacesCount
                    )}
                  >
                    <MatchesCount
                      matchedHorsesCount={horse.matchingRacesCount}
                    />
                  </TableCell>
                  <TableCell
                    className={clsx(
                      classes.tableMatchingHorseCell,
                      classes.tableMatchingHorseName
                    )}
                  >
                    {horse.horseName}
                  </TableCell>
                  <TableCell
                    className={clsx(
                      classes.tableMatchingHorseCell,
                      classes.tableMatchingHorsePreferredConditionsType
                    )}
                  >
                    {horse.areLastRacePreferredConditionsSelected ? (
                      <LastRaceIcon className={classes.lastRaceIconDetails} />
                    ) : (
                      <CustomRaceIcon className={classes.customIconDetails} />
                    )}
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
        {isFetchingNextPage && <Loader infinite />}
      </Dialog>

      {/*TODO: video modal*/}
      {/*<Dialog*/}
      {/*  title={*/}
      {/*    <Box>*/}
      {/*      <span>*/}
      {/*        21Mar20- 9SA fst 7f 22<sup>1</sup> :45<sup>1</sup> 1:09*/}
      {/*        <sup>4</sup> 1:22<sup>3</sup>*/}
      {/*      </span>*/}
      {/*      <span>*/}
      {/*        Md Sp Wt 51k 99 5/10 6 5<sup>1 3/4</sup> 2<sup>hd</sup> 1*/}
      {/*        <sup>2</sup> 1<sup>5 1/2</sup> SmithME L120b*/}
      {/*      </span>*/}
      {/*    </Box>*/}
      {/*  }*/}
      {/*  onClose={handleVideoModalClose}*/}
      {/*  className={classes.videoModal}*/}
      {/*  isOpen={videoModalOpen}*/}
      {/*>*/}
      {/*  <Box>*/}
      {/*    <img*/}
      {/*      src={videoMock}*/}
      {/*      className={classes.videoMock}*/}
      {/*      alt={"video mock"}*/}
      {/*    />*/}
      {/*    <Typography className={classes.videoComment}>*/}
      {/*      Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do*/}
      {/*      eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim*/}
      {/*      ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut*/}
      {/*      aliquip ex ea commodo consequat. Duis aute irure dolor in*/}
      {/*      reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla*/}
      {/*      pariatur. Excepteur sint occaecat cupidatat non proident, sunt in*/}
      {/*      culpa qui officia deserunt mollit anim id est laborum.*/}
      {/*    </Typography>*/}
      {/*  </Box>*/}
      {/*</Dialog>*/}
    </>
  );
};

export default React.memo(InfoBlocks);
