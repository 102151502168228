import React from "react";
import clsx from "clsx";

import { racehorse360 } from "@tsg/1st-grpc-web";

import ExamButtonsGroup from "components/VetExamOverlay/ExamButtonsGroup";
import useStyles from "./styles";

interface Props {
  className?: string;
  riskLevel: string;
  onChange?: (healthWatchStatus: string) => void;
}

const RiskLevelSelector = (props: Props) => {
  const classes = useStyles();
  const { riskLevel, onChange, className } = props;

  return (
    <ExamButtonsGroup
      className={clsx(classes.buttonsGroup, className)}
      classes={{
        button: classes.button
      }}
      buttonClassName={classes.buttonsGroupItem}
      buttons={[
        {
          id: String(racehorse360.HealthWatchStatus.HEALTH_WATCH_STATUS_GREEN),
          value: "Low",
          borderColor: "#000",
          titleColor: "#1a1a1a",
          description: null,
          dataTestLabel: "low-level"
        },
        {
          id: String(racehorse360.HealthWatchStatus.HEALTH_WATCH_STATUS_YELLOW),
          value: "Medium",
          activeColor: "#FFE08F",
          titleColor: "#1a1a1a",
          description: null,
          dataTestLabel: "medium-level"
        },
        {
          id: String(racehorse360.HealthWatchStatus.HEALTH_WATCH_STATUS_RED),
          value: "High",
          activeColor: "#FF4B4F",
          description: null,
          dataTestLabel: "high-level"
        }
      ]}
      selectedValue={String(riskLevel)}
      onClickButton={onChange}
    />
  );
};

export default RiskLevelSelector;
