import React, { useState } from "react";
import { useQueryClient } from "react-query";

import Dialog from "@material-ui/core/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import DialogActions from "@material-ui/core/DialogActions";
import Button from "@material-ui/core/Button";
import Box from "@material-ui/core/Box";

import { racehorse360 } from "@tsg/1st-grpc-web";

import { useRacehorse360Api } from "hooks/api";
import RiskLevel from "components/RiskLevel";
import RiskLevelSelector from "components/RiskLevelSelector";
import { EVariantExamsHistory } from "components/VetExams/ExamHistoryItem/helper";
import { useLoggedInUser } from "components/LoggedInUserProvider";
import Loader from "components/Loader";
import { GET_HORSE_QUERY_PREFIX } from "../../VetHorseDetailsPage";
import useStyles from "./styles";

interface Props {
  horse: racehorse360.IHorse;
  isLoading: boolean;
}

const HealthRating = (props: Props) => {
  const { horse, isLoading } = props;
  const classes = useStyles();
  const queryClient = useQueryClient();
  const { currentUser } = useLoggedInUser();

  const [riskLevel, setRiskLevel] = useState<racehorse360.HealthWatchStatus>(
    horse.healthWatchStatus
  );
  const [open, setOpen] = useState<boolean>(false);
  const { useEditHealthWatchStatusHorse } = useRacehorse360Api();
  const { mutateAsync: editHealthWatchStatusHorse, isLoading: isUpdating } =
    useEditHealthWatchStatusHorse();

  const handleRiskButtonClick = () => {
    setOpen(true);
  };

  const handleChange = (newRiskLevel: racehorse360.HealthWatchStatus) => {
    setRiskLevel(newRiskLevel);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleConfirm = async () => {
    setOpen(false);
    await editHealthWatchStatusHorse({
      id: horse.id,
      healthWatchStatus: riskLevel
    });

    await queryClient.invalidateQueries(`${GET_HORSE_QUERY_PREFIX}${horse.id}`);
  };

  const renderRiskLevel = () => {
    if (currentUser.isRegulatoryVet && !horse.healthWatchStatus) return "-";

    return horse.healthWatchStatus ? (
      <RiskLevel
        status={horse.healthWatchStatus}
        onClick={handleRiskButtonClick}
        variant={EVariantExamsHistory.DETAILS}
      />
    ) : (
      <Button
        className={classes.healthRatingButton}
        onClick={handleRiskButtonClick}
      >
        +
      </Button>
    );
  };

  if (!horse) {
    return <></>;
  }

  return (
    <>
      <Box className={classes.healthRating}>
        {(isLoading || isUpdating) && <Loader size={16} />}
        {renderRiskLevel()}
      </Box>
      <Dialog
        className={classes.dialog}
        classes={{ paper: classes.dialogPaper }}
        open={open}
        onClose={handleClose}
      >
        <DialogTitle className={classes.dialogTitle}>
          <Box>{horse.name}</Box>
          <Box className={classes.dialogTitleDescription}>
            Select and confirm Risk Level for this horse below.
          </Box>
        </DialogTitle>

        <DialogContent className={classes.dialogContent}>
          <RiskLevelSelector riskLevel={riskLevel} onChange={handleChange} />
        </DialogContent>

        <DialogActions>
          <Button onClick={handleClose}>Cancel</Button>
          <Button className={classes.confirmButton} onClick={handleConfirm}>
            Confirm
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};

export default HealthRating;
