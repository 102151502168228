import React from "react";

import {
  addExamDetailObservation,
  clearExamDetail,
  deleteExamDetail,
  examDetailsFields,
  FormField
} from "../../options";
import { checkSelectingFieldsOneEntry } from "../../helper";
import DetailsObservation from "./DetailsObservation";
import EntryItem from "../../EntryItem";
import VetExamFormField from "../../VetExamFormField/VetExamFormField";
import AddEntryButton from "../../AddEntryButton";
import { IExamDetail } from "../../options";
import { useExamLockerContext } from "../../../ExamLockerContext/ExamLockerContext";
import { useVetExamContext } from "../../VetExamStateProvider/VetExamProvider";
import useParentsStyles from "components/VetExamOverlay/styles";
import useStyles from "./styles";

interface IProps {
  examDetailsItem: IExamDetail;
  index: number;
}

const ADD_OBSERVATION_ENTRY = "Add Another Observation";

const ExamDetails = (props: IProps) => {
  const { examDetailsItem, index } = props;
  const detailObservations =
    examDetailsItem.workoutExamDetailObservationEntries;

  const classes = useStyles();
  const parentsClasses = useParentsStyles();
  const {
    dispatch,
    state: { examDetails }
  } = useVetExamContext();
  const { lockExam } = useExamLockerContext();

  const handleDelete = () => {
    const actionCreator =
      examDetails.length === 1
        ? clearExamDetail()
        : deleteExamDetail(examDetailsItem.id);

    dispatch(actionCreator);
    lockExam();
  };

  const handleAddObservationEntry = () => {
    dispatch(addExamDetailObservation(examDetailsItem.id));
    lockExam();
  };

  const renderField = (field: FormField) => {
    return (
      <VetExamFormField
        key={field.key}
        field={field}
        entryState={examDetailsItem}
      />
    );
  };

  const renderObservations = () => {
    return detailObservations.map((item, index) => (
      <DetailsObservation
        key={item.id}
        index={index + 1}
        detailId={examDetailsItem.id}
        observationItem={item}
      />
    ));
  };

  const isDeleteDetailButtonDisabled =
    index === 1 &&
    !checkSelectingFieldsOneEntry(examDetailsItem) &&
    !detailObservations.some(checkSelectingFieldsOneEntry);
  const isAddObservationEntryDisabled = !detailObservations.every(
    checkSelectingFieldsOneEntry
  );

  return (
    <EntryItem
      entryNumber={index}
      onDeleteEntry={handleDelete}
      disabledRemoveButton={isDeleteDetailButtonDisabled}
    >
      <div className={parentsClasses.fieldsWrapper}>
        {examDetailsFields.map(renderField)}
        {renderObservations()}
        <AddEntryButton
          className={classes.addEntryButton}
          title={ADD_OBSERVATION_ENTRY}
          onClick={handleAddObservationEntry}
          disabled={isAddObservationEntryDisabled}
        />
      </div>
    </EntryItem>
  );
};

export default ExamDetails;
