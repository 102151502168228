import React from "react";
import clsx from "clsx";

import { racehorse360 } from "@tsg/1st-grpc-web";

import { transformViewDate } from "utils/date-utils";

import { getTypeLabel } from "./helper";
import useStyles from "./styles";

export interface IProps {
  notification: racehorse360.INotification;
  index: number;
  onClickNotification: () => void;
}

const DashboardNotificationItem = (props: IProps) => {
  const { notification, index, onClickNotification } = props;
  const classes = useStyles();
  const isUnread = !notification?.isRead;
  const dataTestIndicatorValue = isUnread
    ? "unread-dot-indicator"
    : "read-dot-indicator";

  return (
    <div
      role="button"
      tabIndex={0}
      className={clsx(classes.itemRoot, {
        [classes.itemRootOdd]: !(index % 2),
        [classes.unread]: isUnread
      })}
      onClick={onClickNotification}
    >
      <div className={classes.marker} data-test={dataTestIndicatorValue} />
      <div className={classes.rowsWrapper}>
        <div className={classes.rowHeader}>
          <span
            className={clsx(classes.notificationType, {
              [classes.unread]: isUnread
            })}
            data-test="notifications-list-element"
          >
            {getTypeLabel(notification?.type)}
          </span>
          <span>
            {transformViewDate(notification?.createdOn, null, "MM/dd/yy - p")}
          </span>
        </div>
        <div className={classes.rowContent}>
          {notification?.horse?.name || "No Name"}
        </div>
      </div>
    </div>
  );
};

export default DashboardNotificationItem;
