import React from "react";
import clsx from "clsx";

import Popover from "@material-ui/core/Popover";
import Button from "@material-ui/core/Button";
import PersonAddIcon from "@material-ui/icons/PersonAdd";
import RefreshIcon from "@material-ui/icons/Refresh";
import CheckCircleIcon from "@material-ui/icons/CheckCircle";

import { racehorse360 } from "@tsg/1st-grpc-web";

import ClosedDoorIcon from "components/Icons/ClosedDoor";
import OutLinkIcon from "components/Icons/Outlink";
import PersonEdit from "components/Icons/PersonEdit";
import ReOpenIcon from "components/Icons/ReOpen";
import { useLoggedInUser } from "components/LoggedInUserProvider";
import { IAnchorCoordinates } from "../MaintenanceWorkOrdersPage";
import { EPopoverType, getTabsStatuses, getUserPermissions } from "../helper";
import useStyles from "./styles";

interface IProps {
  open: boolean;
  onClose: () => void;
  onActionClick: (action: EPopoverType, wo: racehorse360.IWorkOrder) => void;
  onOpenDetails: (workOrder: racehorse360.IWorkOrder) => void;
  selectedWorkOrder: racehorse360.IWorkOrder;
  activeTab: racehorse360.WorkOrderStatus;
  anchorCoordinates: IAnchorCoordinates;
}

const ActionsButtonsPopover = React.memo((props: IProps) => {
  const {
    open,
    onClose,
    onActionClick,
    onOpenDetails,
    selectedWorkOrder,
    activeTab,
    anchorCoordinates
  } = props;
  const classes = useStyles();
  const { currentUser } = useLoggedInUser();
  const isSupervisor = currentUser.isMaintenanceSupervisor;
  const {
    isOpenTab,
    isAssignedTab,
    isCompleteTab,
    isClosedTab,
    isCanceledTab
  } = getTabsStatuses(activeTab);
  const {
    hasPermissionAssign,
    hasPermissionClose,
    hasPermissionComplete,
    hasPermissionReopen
  } = getUserPermissions(selectedWorkOrder, currentUser);

  const isShowAssignButton =
    hasPermissionAssign && (isAssignedTab || isOpenTab);
  const isShowCompleteButton =
    hasPermissionComplete && (isAssignedTab || isOpenTab);
  const isShowReopenButton =
    isSupervisor && hasPermissionReopen && (isClosedTab || isCanceledTab);
  const isShowSendBackButton = currentUser.isMaintenance && isCompleteTab;
  const isShowCloseButton =
    hasPermissionClose && !isClosedTab && !isCanceledTab;

  const handleAssignClick = () => {
    onActionClick(
      selectedWorkOrder?.assignedUser
        ? EPopoverType.REASSIGN
        : EPopoverType.ASSIGN,
      selectedWorkOrder
    );
  };

  const handleCloseClick = () => {
    onActionClick(EPopoverType.CLOSE, selectedWorkOrder);
  };

  const handleOpenDetails = () => {
    onOpenDetails(selectedWorkOrder);
  };

  const handleCompleteClick = () => {
    onActionClick(EPopoverType.COMPLETE, selectedWorkOrder);
  };

  const handleUndoCompleteClick = () => {
    onActionClick(EPopoverType.UNDO_COMPLETE, selectedWorkOrder);
  };

  const handleReOpenClick = () => {
    onActionClick(EPopoverType.REOPEN, selectedWorkOrder);
  };

  const renderButtons = () => {
    return (
      <>
        {isShowAssignButton && (
          <Button
            className={classes.button}
            onClick={handleAssignClick}
            startIcon={
              isAssignedTab ? (
                <PersonEdit
                  className={clsx(classes.icon, classes.assignIcon)}
                />
              ) : (
                <PersonAddIcon
                  className={clsx(classes.icon, classes.assignIcon)}
                />
              )
            }
            data-test={"actions-popover__assign-button"}
          >
            {isAssignedTab ? "Edit Assignment" : "Assign Work Order"}
          </Button>
        )}
        {isShowCompleteButton && (
          <Button
            className={classes.button}
            onClick={handleCompleteClick}
            startIcon={
              <CheckCircleIcon
                className={clsx(classes.icon, classes.completeIcon)}
              />
            }
            data-test={"actions-popover__complete-button"}
          >
            Complete Work Order
          </Button>
        )}
        {isShowReopenButton && (
          <Button
            className={classes.button}
            onClick={handleReOpenClick}
            startIcon={
              <ReOpenIcon className={clsx(classes.icon, classes.reopenIcon)} />
            }
            data-test={"actions-popover__reopen-button"}
          >
            Reopen Work Order
          </Button>
        )}
        {isShowSendBackButton && (
          <Button
            className={classes.button}
            onClick={handleUndoCompleteClick}
            startIcon={
              <RefreshIcon
                className={clsx(classes.icon, classes.undoCompleteIcon)}
              />
            }
            data-test={"actions-popover__undo-complete-button"}
          >
            {isSupervisor ? "Send Back Work Order" : "Undo Complete"}
          </Button>
        )}
        {isShowCloseButton && (
          <Button
            className={classes.button}
            onClick={handleCloseClick}
            startIcon={
              <ClosedDoorIcon
                className={clsx(classes.icon, classes.closeIcon)}
              />
            }
            data-test={"actions-popover__close-button"}
          >
            Close Work Order
          </Button>
        )}
        <Button
          className={classes.button}
          onClick={handleOpenDetails}
          startIcon={
            <OutLinkIcon className={clsx(classes.icon, classes.detailsIcon)} />
          }
          data-test={"actions-popover__details-button"}
        >
          View Details
        </Button>
      </>
    );
  };

  return (
    <Popover
      open={open}
      onClose={onClose}
      classes={{ paper: classes.paper }}
      anchorReference="anchorPosition"
      anchorPosition={{
        top: anchorCoordinates?.top || 0,
        left: anchorCoordinates?.left || 0
      }}
      transformOrigin={{
        vertical: "top",
        horizontal: "right"
      }}
    >
      {renderButtons()}
    </Popover>
  );
});

export default ActionsButtonsPopover;
