import React, { useState } from "react";
import { Link } from "react-router-dom";
import clsx from "clsx";

import Accordion from "@material-ui/core/Accordion";
import AccordionDetails from "@material-ui/core/AccordionDetails";
import AccordionSummary from "@material-ui/core/AccordionSummary";
import Button from "@material-ui/core/Button";
import useTheme from "@material-ui/core/styles/useTheme";
import useMediaQuery from "@material-ui/core/useMediaQuery";

import { racehorse360 } from "@tsg/1st-grpc-web";

import Breakpoints from "common/breakpoints";
import routes from "common/routes";
import AppPage from "components/AppPage";
import AppPageContent from "components/AppPageContent";
import SapLogo from "components/Icons/SapLogo";
import Horse from "components/Icons/Horse";
import Loader from "components/Loader";
import { useLoggedInUser } from "components/LoggedInUserProvider";
import MUIAccordionCustomExpandIcon from "components/MUIAccordionCustomExpandIcon";
import SwipeableTabs from "components/SwipeableTabs";
import { useRacehorse360Api } from "hooks/api";
import { transformViewDate } from "utils/date-utils";
import { mapFormsToFacilitiesWithForms } from "utils/stall-applications";
import useStyles from "./styles";

enum TABS {
  ALL_FORMS = "ALL FORMS",
  MY_APPLICATIONS = "MY APPLICATIONS"
}

const TrainerPage = () => {
  const classes = useStyles();
  const { currentUser } = useLoggedInUser();
  const [activeTab, setActiveTab] = useState<string>(TABS.ALL_FORMS);
  const theme = useTheme();
  const matchesDownSm600 = useMediaQuery(
    theme.breakpoints.down(Breakpoints.SM_600),
    {
      noSsr: true
    }
  );

  const {
    useListFacilities,
    useListStallApplicationForms,
    useListStallApplications
  } = useRacehorse360Api();

  const {
    data: { stallApplications } = { stallApplications: [] },
    isFetching: isListStallApplicationsFetching
  } = useListStallApplications("list-stall-applications", {
    query: {
      trainerIds: currentUser.trainerIds
    },
    getOptions: {
      select: [
        "arrival_date",
        "sleeping_rooms_number",
        "contact_full_name",
        "contact_phone",
        "contact_email",
        "contact_zip_code",
        "contact_state",
        "contact_city",
        "contact_street_address",
        "contact_street_address_optional",
        "carrier_name",
        "policy_number",
        "policy_expiration_date",
        "carrier_zip_code",
        "carrier_state",
        "carrier_city",
        "carrier_street_address",
        "carrier_street_address_optional",
        "is_stable_planned_to_split",
        "comment",
        "stall_application_form_id",
        "status",
        "stable_entries:id",
        "stable_entries:horseId",
        "stable_entries:stallApplicationId",
        "stable_entries:customHorseRegistrationNumber",
        "stable_entries:customHorseName",
        "stable_entries:raceReadyDate",
        "stable_entries:horse.id",
        "stable_entries:horse.name",
        "stable_entries:horse.registrationNumber",
        "stable_entries:horse.ownerName",
        "stallApplicationFormId",
        "trainer.id"
      ]
    }
  });

  const {
    data: { facilities } = { facilities: [] },
    isFetching: isListFacilitiesLoading
  } = useListFacilities(
    {
      query: {
        isActive: { value: true },
        isTrack: { value: true }
      },
      getOptions: {
        select: [
          "id",
          "name",
          "code",
          "backgroundColor",
          "foregroundColor",
          "strokeColor",
          "trainingFacilities:id",
          "trainingFacilities:name",
          "trainingFacilities:code"
        ],
        orderBy: ["name"]
      }
    },
    {
      onError: error => console.error(error)
    }
  );

  const {
    data: { stallApplicationForms } = { stallApplicationForms: [] },
    isFetching: isListStallApplicationFormsFetching
  } = useListStallApplicationForms(
    "listStallApplicationForms",
    {
      query: {},
      getOptions: {
        select: [
          "id",
          "title",
          "start_date",
          "deadline_date",
          "close_date",
          "facility_options:id",
          "facility_options:name",
          "facility_options:code",
          "facility_options:background_color",
          "facility_options:foreground_color",
          "facility_options:stroke_color",
          "facility_options:type",
          "facility_options:training_facilities"
        ]
      }
    },
    {
      onError: error => console.error(error)
    }
  );

  const handleTabChange = (tab: string) => {
    setActiveTab(tab);
  };

  const handleViewAllFormsClick = () => {
    setActiveTab(TABS.ALL_FORMS);
  };

  const facilitiesWithForms = mapFormsToFacilitiesWithForms(
    facilities,
    stallApplicationForms
  );

  const isLoading =
    isListStallApplicationsFetching ||
    isListStallApplicationFormsFetching ||
    isListFacilitiesLoading;

  const renderFacilityLogo = (code: string) => {
    switch (code) {
      case "SA":
        return <SapLogo />;
    }
    return <Horse />;
  };

  const renderApplication = (
    form: racehorse360.IStallApplicationForm,
    stallApplication: racehorse360.IStallApplication,
    facilityId?: string
  ) => {
    const pathname = routes.stallApplicationTrainerForm.path.replace(
      ":stallApplicationFormId",
      String(form.id)
    );
    let statusClassName = classes.stallApplicationFormStatusNotApplied;
    let buttonText = "Apply";
    let statusText = "Not Applied";
    switch (stallApplication?.status) {
      case racehorse360.StallApplicationStatus.STALL_APPLICATION_STATUS_APPLIED:
        buttonText = "VIEW/EDIT";
        statusText = "Applied";
        statusClassName = classes.stallApplicationFormStatusApplied;
        break;
      case racehorse360.StallApplicationStatus
        .STALL_APPLICATION_STATUS_IN_PROGRESS:
        buttonText = "Continue";
        statusText = "In Progress";
        statusClassName = classes.stallApplicationFormStatusInProgress;
        break;
      case racehorse360.StallApplicationStatus
        .STALL_APPLICATION_STATUS_COMPLETE:
        buttonText = "View";
        statusText = "Complete";
        statusClassName = classes.stallApplicationFormStatusComplete;
        break;
      case racehorse360.StallApplicationStatus
        .STALL_APPLICATION_STATUS_CANCELLED:
        buttonText = "View";
        statusText = "Cancelled";
        statusClassName = classes.stallApplicationFormStatusCancelled;
        break;
    }
    return (
      <div key={form.id} className={classes.stallApplicationForm}>
        <span>{form.title}</span>
        <span>Deadline: {transformViewDate(form.deadlineDate)}</span>
        <span
          className={clsx(classes.stallApplicationFormStatus, statusClassName)}
        >
          {statusText}
        </span>
        <Link to={{ pathname, state: { facilityId } }}>
          <Button
            variant={stallApplication ? "outlined" : "contained"}
            color={"primary"}
            disableElevation
            className={classes.stallApplicationFormViewButton}
          >
            {buttonText}
          </Button>
        </Link>
      </div>
    );
  };

  const renderApplicationUsingForm = (
    form: racehorse360.IStallApplicationForm,
    facilityId: string
  ) => {
    const stallApplication = stallApplications?.find(
      sa =>
        currentUser.trainerIds.includes(sa.trainer.id) &&
        sa.stallApplicationFormId === form.id
    );
    return renderApplication(form, stallApplication, facilityId);
  };

  const renderApplicationUsingApplication = (
    stallApplication: racehorse360.IStallApplication
  ) => {
    const form = stallApplicationForms.find(
      saf => saf.id === stallApplication.stallApplicationFormId
    );
    return renderApplication(form, stallApplication);
  };

  const facilityKeys = Object.keys(facilitiesWithForms);

  return (
    <AppPage className={classes.root}>
      <AppPageContent
        className={clsx(classes.pageContent, {
          [classes.noContent]: !facilityKeys.length
        })}
      >
        <SwipeableTabs
          activeTab={activeTab}
          onTabChange={handleTabChange}
          variant={matchesDownSm600 ? "fullWidth" : "standard"}
          classes={{
            root: classes.tabsRoot,
            content: classes.tabsContent,
            views: classes.tabsViews,
            tab: classes.tab,
            selected: classes.tabSelected,
            indicator: classes.tabIndicator,
            slideClassName: classes.tabsSlide,
            flexContainer: classes.tabsFlexContainer
          }}
          tabs={[TABS.ALL_FORMS, TABS.MY_APPLICATIONS]}
        >
          <div>
            {isLoading ? (
              <Loader />
            ) : facilityKeys.length ? (
              facilityKeys.map(facilityId => {
                const facility = facilities.find(f => f.id === facilityId);
                return (
                  <Accordion
                    key={facilityId}
                    className={classes.facility}
                    defaultExpanded={false}
                  >
                    <AccordionSummary
                      expandIcon={<MUIAccordionCustomExpandIcon />}
                      classes={{
                        root: classes.facilityHeader,
                        content: classes.facilityHeaderContent
                      }}
                    >
                      <div
                        className={classes.facilityCircle}
                        style={{
                          backgroundColor: `#${facility.backgroundColor}`
                        }}
                      >
                        {renderFacilityLogo(facility.code)}
                      </div>
                      {facility.name.toLowerCase()}
                    </AccordionSummary>
                    <AccordionDetails className={classes.formsGroup}>
                      {facilitiesWithForms[facilityId].map(item =>
                        renderApplicationUsingForm(item, facilityId)
                      )}
                    </AccordionDetails>
                  </Accordion>
                );
              })
            ) : (
              <div
                className={clsx(
                  classes.myApplications,
                  classes.emptyApplications
                )}
              >
                <div className={classes.noFormsMessage}>No Forms Found</div>
              </div>
            )}
          </div>
          <div>
            <div
              className={clsx(classes.myApplications, {
                [classes.emptyApplications]: !stallApplications.length
              })}
            >
              {isLoading ? (
                <Loader />
              ) : stallApplications.length ? (
                <div className={classes.formsGroup}>
                  {stallApplications.map(renderApplicationUsingApplication)}
                </div>
              ) : (
                <div className={classes.noApplicationsMessage}>
                  No Applications Found
                  <Button
                    className={classes.viewAllFormsButton}
                    variant={"outlined"}
                    onClick={handleViewAllFormsClick}
                  >
                    View All Forms
                  </Button>
                </div>
              )}
            </div>
          </div>
        </SwipeableTabs>
      </AppPageContent>
    </AppPage>
  );
};

export default TrainerPage;
