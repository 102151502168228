import React, { useState, useEffect } from "react";
import clsx from "clsx";

import Modal from "@material-ui/core/Modal";
import ListItem from "@material-ui/core/ListItem";
import Divider from "@material-ui/core/Divider";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import ListItemText from "@material-ui/core/ListItemText";
import CloseIcon from "@material-ui/icons/Close";

import { useRacehorse360Api } from "hooks/api";
import { useLoggedInUser } from "components/LoggedInUserProvider";
import TrackConditionBlack from "components/Icons/TrackConditionsBlack";
import AllCoursesList from "./AllCoursesList";
import { getDateBeforeMinutes } from "./utils";

import {
  listCoursesGetOptions,
  CHECK_NEW_CHANGES_INTERVAL_MINUTES
} from "./constants";

import useStyles from "./styles";

interface Props {
  openManageTracksPage: (event: any) => void;
  selected: boolean;
  dataPath?: string;
}

const Conditions = (props: Props) => {
  const { openManageTracksPage, selected, dataPath } = props;

  const classes = useStyles();
  const { currentUser } = useLoggedInUser();
  const isManagementMode =
    currentUser.isRacingOfficial ||
    currentUser.isSeniorVet ||
    currentUser.isChiefVet ||
    currentUser.isAdmin;

  const [isOpenConditions, setIsOpenConditions] = useState<boolean>(false);
  //Updates red indicator if was a new updates
  const [isNewMessages, setIsNewMessages] = useState<boolean>(false);
  const [currentDate, setCurrentDate] = useState<string>(
    getDateBeforeMinutes(CHECK_NEW_CHANGES_INTERVAL_MINUTES)
  );

  const { useListCourses } = useRacehorse360Api();

  useListCourses(
    "listCoursesForIndicationUpdate",
    {
      query: {
        facilityIds: [],
        isActive: {
          value: true
        },
        isFacilityActive: {
          value: true
        },
        updatedOn: {
          range: {
            absolute: {
              onOrAfter: currentDate,
              includeTime: {
                value: true
              }
            }
          }
        }
      },
      pagingOptions: {
        maxResults: 1000
      },
      getOptions: {
        select: listCoursesGetOptions
      }
    },
    {
      onSuccess: result => {
        if (result.courses.length && !isOpenConditions) {
          setIsNewMessages(true);
        }
      },
      onError: error => {
        console.error(error);
      },
      enabled: !isManagementMode
    }
  );

  useEffect(() => {
    if (!isManagementMode) {
      const interval = setInterval(() => {
        setCurrentDate(
          getDateBeforeMinutes(CHECK_NEW_CHANGES_INTERVAL_MINUTES)
        );
      }, CHECK_NEW_CHANGES_INTERVAL_MINUTES * 60 * 1000);
      return () => clearInterval(interval);
    }
  }, []);

  const handleOpenConditions = (event: React.MouseEvent) => {
    event.preventDefault();
    setIsNewMessages(false);

    if (isManagementMode) {
      openManageTracksPage(event);
    } else {
      setIsOpenConditions(true);
    }
  };

  const handleCloseConditionsBackdrop = (event: React.MouseEvent, reason) => {
    if (reason === "backdropClick") {
      return false;
    }

    if (reason === "escapeKeyDown") {
      return false;
    }

    event.preventDefault();
    setIsOpenConditions(false);
  };

  const handleCloseConditions = (event: React.MouseEvent) => {
    event.preventDefault();
    setIsOpenConditions(false);
  };

  const renderModalHeader = () => {
    return (
      <>
        <div className={classes.headerPartLeft}>
          <ListItemText
            primary="Track Conditions"
            classes={{ primary: classes.headerItemText }}
          />
        </div>
        <div
          className={classes.headerPartRight}
          onClick={handleCloseConditions}
        >
          <CloseIcon className={classes.closeIcon} />
        </div>
      </>
    );
  };

  const renderModal = () => {
    return (
      <div className={clsx(classes.paper, classes.shortLayout)}>
        <div className={classes.shortLayoutContentContainer}>
          <div className={classes.shortLayoutContentHeader}>
            {renderModalHeader()}
          </div>
          <Divider className={classes.dividerLight} />
          <div className={classes.shortLayoutContentBody}>
            <AllCoursesList />
          </div>
        </div>
      </div>
    );
  };

  return (
    <>
      <ListItem
        button
        divider={true}
        selected={isOpenConditions}
        className={clsx(classes.listItem, {
          [classes.itemSelected]: selected
        })}
        onClick={handleOpenConditions}
        data-path={dataPath}
      >
        <ListItemIcon className={classes.listItemIcon}>
          {isNewMessages ? (
            <div className={classes.updatesNotification} />
          ) : null}
          <TrackConditionBlack className={classes.icon} />
        </ListItemIcon>
        <ListItemText
          primary={isManagementMode ? "Manage Tracks" : "Conditions"}
          classes={{
            root: classes.listItemTextRoot,
            primary: classes.listItemText
          }}
        />
      </ListItem>

      {isOpenConditions && (
        <Modal
          aria-labelledby="simple-modal-title"
          aria-describedby="simple-modal-description"
          className={classes.modal}
          open={isOpenConditions}
          onClose={handleCloseConditionsBackdrop}
          BackdropProps={{ invisible: isManagementMode }}
        >
          {renderModal()}
        </Modal>
      )}
    </>
  );
};

export default Conditions;
