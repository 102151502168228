import { makeStyles } from "@material-ui/core/styles";
import { MuiTheme } from "theme";

export default makeStyles((theme: MuiTheme) => ({
  appPageTableHeader: {
    display: "grid",
    gridTemplateColumns:
      "minmax(200px, 1fr) minmax(200px, 1fr) 92px 52px 104px 128px 120px",
    columnGap: 8,
    backgroundColor: "#fff",
    borderBottom: "solid 1px #eee",
    zIndex: 1,
    [theme.breakpoints.down("sm")]: {
      gridTemplateColumns: "65% 35%",
      "&$customizedForMobile": {
        gridTemplateColumns: "minmax(190px, 45%) minmax(22%, 30%) 18%"
      }
    },
    paddingLeft: 24,
    paddingRight: 24,
    [theme.breakpoints.down("xs")]: {
      paddingLeft: 16,
      paddingRight: 16
    },
    "& > div:nth-last-child(-n+3)": {
      justifyContent: "center"
    },
    [theme.breakpoints.up("xl")]: {
      gridTemplateColumns:
        "minmax(200px, 1fr) minmax(200px, 1fr) 92px 52px 104px 128px 390px",
      "& > div:last-child": {
        justifyContent: "flex-start"
      }
    }
  },
  appPagetableContent: {
    display: "flex"
  },
  listVirtualized: {
    width: "100%"
  },
  listVirtualizedItem: {
    width: "100%"
  },
  tableHeaderCell: {
    display: "flex",
    height: 55,
    backgroundColor: theme.palette.common.white,
    fontSize: 14,
    color: "#232323",
    whiteSpace: "nowrap",
    "& .MuiTableSortLabel-icon": {
      display: "none"
    }
  },
  centerAligning: {
    justifyContent: "center"
  },
  icons: {
    display: "flex",
    flexDirection: "column",
    marginLeft: "4px"
  },
  iconUp: {
    position: "relative",
    width: "12px",
    height: "12px",
    opacity: "0.2",
    "& *": {
      position: "absolute",
      top: "-1px",
      left: "-4px",
      width: "20px",
      height: "20px"
    }
  },
  iconDown: {
    position: "relative",
    width: "12px",
    height: "12px",
    opacity: "0.2",
    "& *": {
      position: "absolute",
      top: "-7px",
      left: "-4px",
      width: "20px",
      height: "20px"
    }
  },
  iconSort: {
    opacity: "initial"
  },
  tableCellLink: {
    textDecoration: "none",
    alignItems: "center",
    display: "flex",
    fontSize: 14,
    color: "#232323",
    whiteSpace: "nowrap",
    textAlign: "inherit"
  },
  tableBodyRow: {
    display: "grid",
    cursor: "pointer",
    gridTemplateColumns:
      "minmax(200px, 1fr) minmax(200px, 1fr) 92px 52px 104px 128px 120px",
    borderBottom: "1px solid #eeeeee",
    [theme.breakpoints.up("xl")]: {
      gridTemplateColumns:
        "minmax(200px, 1fr) minmax(200px, 1fr) 92px 52px 104px 128px 390px"
    },
    columnGap: 8,
    height: 55,
    [theme.breakpoints.down("sm")]: {
      gridTemplateColumns: "65% 35%",
      "&$customizedForMobile": {
        gridTemplateColumns: "minmax(190px, 45%) minmax(22%, 30%) 18%"
      }
    },
    paddingLeft: 24,
    paddingRight: 24,
    [theme.breakpoints.down("xs")]: {
      paddingLeft: 16,
      paddingRight: 16
    }
  },
  customizedForMobile: {},
  rowOdd: {
    backgroundColor: "#fafafa"
  },
  rowEven: {
    backgroundColor: theme.palette.common.white
  },
  daysOffColumn: {
    justifyContent: "center",
    [theme.breakpoints.up("xl")]: {
      width: 397,
      minWidth: 397,
      flexWrap: "wrap"
    }
  },
  horseName: {
    fontFamily: "SuisseIntlMedium, SuisseIntlRegular",
    textTransform: "uppercase",
    color: theme.palette.text.primary
  },
  trainerName: {
    display: "none",
    [theme.breakpoints.down("xs")]: {
      textOverflow: "ellipsis",
      overflow: "hidden"
    },
    [theme.breakpoints.up("sm")]: {
      display: "block"
    }
  },
  trainerNameShort: {
    display: "block",
    textOverflow: "ellipsis",
    overflow: "hidden",
    [theme.breakpoints.up("sm")]: {
      display: "none"
    }
  },
  hideOnTablet: {
    [theme.breakpoints.down("sm")]: {
      display: "none"
    }
  },
  daysOffDiagram: {
    [theme.breakpoints.up("xl")]: {
      width: "100%",
      "& &__lineChartText": {
        display: "block",
        textAlign: "right"
      }
    },
    "&$__text": {
      display: "flex",
      justifyContent: "right"
    }
  }
}));
