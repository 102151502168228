import React, { useState } from "react";
import clsx from "clsx";
import { useQueryClient } from "react-query";
import { Theme } from "@material-ui/core/styles";
import useMediaQuery from "@material-ui/core/useMediaQuery";

import { racehorse360 } from "@tsg/1st-grpc-web";

import ConfirmationDialog from "components/ConfirmationDialog";
import { useConfirmationContext } from "components/ConfirmationDialog/ConfirmationContext";
import ExpandableTextarea from "components/ExpandableTextarea";
import Loader from "components/Loader";
import { useLoggedInUser } from "components/LoggedInUserProvider";
import { useRacehorse360Api } from "hooks/api";
import InjectionFormModal from "../../InjectionFormModal";
import VaccinationActivityEntry from "./VaccinationActivityEntry";
import useStyles from "./styles";

interface Props {
  horse: racehorse360.IHorse;
  injections: racehorse360.IInjection[];
  vaccineInjection: racehorse360.IInjection;
  comments: racehorse360.IVaccinationComment[];
  closeDetails: () => void;
}

const VaccinationActivity = (props: Props) => {
  const {
    horse,
    vaccineInjection,
    closeDetails,
    injections = [],
    comments = []
  } = props;
  const { currentUser } = useLoggedInUser();
  const classes = useStyles();
  const matchesDownSm = useMediaQuery((theme: Theme) =>
    theme.breakpoints.down("sm")
  );
  const {
    setIsLoading,
    setConfirmationRequired,
    setHandleConfirm,
    setHandleDiscard
  } = useConfirmationContext();

  const [comment, setComment] = useState<string>("");
  const [deleteConfirmationOpen, setDeleteConfirmationOpen] =
    useState<boolean>(false);
  const [injectionEditOpen, setInjectionEditOpen] = useState<boolean>(false);
  const [activeEntry, setActiveEntry] = useState<
    racehorse360.IInjection | racehorse360.IVaccinationComment
  >();
  const queryClient = useQueryClient();
  const entries = [...injections, ...comments].sort((e1, e2) =>
    e2.updatedOn.localeCompare(e1.updatedOn)
  );
  const vaccination = vaccineInjection?.vaccination;

  const {
    useDeleteVaccinationComment,
    useUpdateVaccinationComment,
    useDeleteInjection,
    useCreateVaccinationComment
  } = useRacehorse360Api();

  const {
    mutateAsync: createVaccinationComment,
    isLoading: isVaccinationCommentCreating
  } = useCreateVaccinationComment();

  const {
    mutateAsync: deleteVaccinationComment,
    isLoading: isDeleteVaccinationCommentLoading
  } = useDeleteVaccinationComment();

  const {
    mutateAsync: updateVaccinationComment,
    isLoading: isUpdateVaccinationCommentLoading
  } = useUpdateVaccinationComment();

  const { mutateAsync: deleteInjection, isLoading: isDeleteInjectionLoading } =
    useDeleteInjection();

  const isLoading = [
    isVaccinationCommentCreating,
    isDeleteVaccinationCommentLoading,
    isUpdateVaccinationCommentLoading,
    isDeleteInjectionLoading
  ].some(Boolean);

  const toggleDeleteConfirmationDialog = () => {
    setDeleteConfirmationOpen(!deleteConfirmationOpen);
  };

  const deleteEntry = () => {
    let deleteFn;
    setIsLoading(true);
    if (activeEntry instanceof racehorse360.Injection) {
      deleteFn = deleteInjection;
    }
    if (activeEntry instanceof racehorse360.VaccinationComment) {
      deleteFn = deleteVaccinationComment;
    }
    deleteFn({
      id: activeEntry.id
    }).then(() => {
      setDeleteConfirmationOpen(false);
      setActiveEntry(undefined);
      setComment("");
      resetConfirmation();
      return Promise.all([
        queryClient.invalidateQueries([`${vaccination?.id}-list-boosters`]),
        queryClient.invalidateQueries([
          `${vaccination?.id}-list-vaccination-comments`
        ]),
        queryClient.invalidateQueries([`${horse.id}-list-vaccines`])
      ]);
    });
  };

  const handleUpdateEntry = (
    data: racehorse360.IInjection | racehorse360.IVaccinationComment
  ) => {
    setActiveEntry(data);
    if (data instanceof racehorse360.Injection) {
      setInjectionEditOpen(true);
    }
    if (data instanceof racehorse360.VaccinationComment) {
      setComment(data.body);
    }
  };

  const handleCommentSave = (newComment: string) => {
    let promise;
    setComment(newComment);
    setIsLoading(true);
    if (activeEntry && activeEntry instanceof racehorse360.VaccinationComment) {
      promise = updateVaccinationComment({
        vaccinationComment: {
          ...activeEntry,
          body: newComment
        },
        updateMask: {
          paths: ["body"]
        }
      });
    } else {
      promise = createVaccinationComment({
        vaccinationComment: {
          vaccinationId: vaccination.id,
          body: newComment
        }
      });
    }
    return promise.then(() => {
      setActiveEntry(undefined);
      setComment("");
      resetConfirmation();
      return queryClient.invalidateQueries(
        `${vaccination.id}-list-vaccination-comments`
      );
    });
  };

  const resetConfirmation = () => {
    setIsLoading(false);
    setHandleConfirm(null);
    setHandleDiscard(null);
    setConfirmationRequired(false);
  };

  const handleCommentCancel = () => {
    resetConfirmation();
    setActiveEntry(undefined);
    setComment("");
  };

  const handleChangesConfirm = value => () => () => {
    handleCommentSave(value).then(() => {
      closeDetails();
    });
  };

  const handleCommentDiscard = () => () => {
    handleCommentCancel();
    closeDetails();
  };

  const handleCommentChange = newCommentValue => {
    const hasChanges = newCommentValue !== comment;
    setConfirmationRequired(hasChanges);
    if (hasChanges) {
      setHandleConfirm(handleChangesConfirm(newCommentValue));
      setHandleDiscard(handleCommentDiscard);
    }
  };

  const handleDeleteEntry = (
    data: racehorse360.IInjection | racehorse360.IVaccinationComment
  ) => {
    setActiveEntry(data);
    setDeleteConfirmationOpen(true);
    setInjectionEditOpen(false);
  };

  const handleInjectionEditClose = () => {
    setActiveEntry(undefined);
    setInjectionEditOpen(false);
  };

  const renderConfirmation = () => {
    const isInjectionConfirmation =
      activeEntry instanceof racehorse360.Injection;
    return (
      <>
        <ConfirmationDialog
          open={deleteConfirmationOpen}
          onConfirm={deleteEntry}
          onCancel={toggleDeleteConfirmationDialog}
          title={
            isInjectionConfirmation ? "Delete Booster?" : "Delete Comment?"
          }
          positive={false}
          confirmButtonText={"Delete"}
        >
          {isLoading && <Loader overlay />}
          {isInjectionConfirmation
            ? "Are you sure you want to delete this booster record?"
            : "Are you sure you want to delete this comment?"}
        </ConfirmationDialog>
      </>
    );
  };

  return (
    <>
      <div className={classes.root}>
        <div
          className={clsx(classes.header, {
            ["withoutPadding"]: currentUser.isRegulatoryVet
          })}
        >
          {!matchesDownSm && (
            <>
              Activity
              {!currentUser.isRegulatoryVet && (
                <InjectionFormModal
                  horse={horse}
                  injection={vaccineInjection}
                  type={racehorse360.MedicineType.MEDICINE_TYPE_BOOSTER}
                  isEdit={false}
                />
              )}
            </>
          )}
          {!currentUser.isRegulatoryVet && (
            <>
              <InjectionFormModal
                horse={horse}
                injection={injectionEditOpen ? activeEntry : undefined}
                type={racehorse360.MedicineType.MEDICINE_TYPE_BOOSTER}
                isEdit={true}
                isOpen={injectionEditOpen}
                onClose={handleInjectionEditClose}
              />
              <ExpandableTextarea
                label={"Add a comment..."}
                expandedProps={{
                  label: `${comment ? "Edit" : "Add"} Comment`
                }}
                onSave={handleCommentSave}
                onCancel={handleCommentCancel}
                onChange={handleCommentChange}
                maxLength={200}
                defaultValue={comment}
              />
            </>
          )}
        </div>
        <div>
          {entries.map(entry => (
            <VaccinationActivityEntry
              key={entry.id}
              data={entry}
              classes={{
                root: classes.listEntry
              }}
              onDelete={handleDeleteEntry}
              onUpdate={handleUpdateEntry}
            />
          ))}
        </div>
        {isLoading && <Loader overlay />}
      </div>
      {renderConfirmation()}
    </>
  );
};

export default VaccinationActivity;
