import { MuiTheme } from "theme";
import makeStyles from "@material-ui/core/styles/makeStyles";
import Breakpoints from "common/breakpoints";

const STABLING_ENTRIES_WIDTH = 460;

export default makeStyles((theme: MuiTheme) => ({
  root: {
    [theme.breakpoints.up(Breakpoints.SM_600)]: {
      borderLeft: `1px solid ${theme.palette.divider}`
    }
  },
  pageHeader: {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    fontSize: 18,
    paddingTop: 10,
    paddingLeft: 8,
    paddingRight: 8,
    maxHeight: 40,
    boxShadow: "none",
    borderBottom: "none",
    borderLeft: "none",
    [theme.breakpoints.down(Breakpoints.SM_600)]: {
      maxHeight: 76,
      height: 76,
      flexDirection: "column",
      justifyContent: "start",
      top: 0
    },
    [theme.breakpoints.up("md")]: {
      maxHeight: 64,
      height: 64,
      padding: "8px 8px 8px 24px",
      borderBottom: `1px solid ${theme.palette.divider}`
    }
  },
  pageHeaderInfo: {
    display: "flex",
    flexDirection: "column",
    flexGrow: 1,
    fontSize: 16
  },
  pageHeaderInfoName: {
    fontFamily: "SuisseIntlMedium, SuisseIntlRegular"
  },
  pageHeaderInfoDeadline: {
    fontSize: 14,
    color: theme.palette.primary.dark
  },
  pageHeaderButtonCancel: {
    position: "absolute",
    left: 10,
    textTransform: "none",
    fontSize: 16,
    height: 32,
    [theme.breakpoints.up(Breakpoints.SX_720)]: {
      display: "none"
    }
  },
  pageHeaderTitle: {
    flex: 1,
    textAlign: "center",
    [theme.breakpoints.down(Breakpoints.SM_600)]: {
      maxHeight: 30
    }
  },
  pageHeaderSubTitle: {
    fontSize: 14,
    color: "#333333"
  },
  pageHeaderButtonMore: {
    position: "absolute",
    right: 10
  },
  pageContent: {
    backgroundColor: theme.palette.common.white
  },
  pageFooter: {
    position: "sticky",
    bottom: 0,
    display: "flex",
    backgroundColor: theme.palette.common.white,
    flexWrap: "wrap",
    gap: 15,
    alignItems: "center",
    justifyContent: "right",
    padding: 16,
    borderTop: `1px solid ${theme.palette.divider}`,
    [theme.breakpoints.up("md")]: {
      minHeight: 88,
      maxHeight: 88,
      paddingLeft: 24,
      paddingRight: 24,
      justifyContent: "space-between"
    }
  },
  pageFooterButton: {
    height: 42,
    maxWidth: 164,
    flex: 1,
    [theme.breakpoints.down(Breakpoints.SM_600)]: {
      maxWidth: "50%"
    }
  },
  pageFooterButtonNext: {
    backgroundColor: theme.palette.secondary.dark,
    color: theme.palette.common.white,
    "&:hover": {
      backgroundColor: "#5db491"
    }
  },
  tabsRoot: {
    maxWidth: "100%",
    backgroundColor: "#f6f6f6",
    display: "flex",
    flexDirection: "column",
    flex: 1
  },
  tabs: {
    position: "sticky",
    top: 104,
    zIndex: theme.zIndex.page.header,
    backgroundColor: theme.palette.common.white,
    [theme.breakpoints.down(Breakpoints.SM_600)]: {
      top: 76
    },
    [theme.breakpoints.up("md")]: {
      display: "none"
    }
  },
  tabsContent: {
    display: "flex",
    flex: 1
  },
  tabsViews: {
    "& .react-swipeable-view-container": {
      display: "flex",
      flex: 1,
      height: "100%",
      "& > div": {
        flex: "auto"
      }
    },
    [theme.breakpoints.up("md")]: {
      display: "flex",
      flex: 1,
      "& div[aria-hidden='true']": {
        display: "block",
        height: "auto"
      }
    }
  },
  tabSlide: {
    display: "flex",
    [theme.breakpoints.up("md")]: {
      "&:first-child": {
        maxWidth: `calc(100% - ${STABLING_ENTRIES_WIDTH}px)`
      },
      "&:last-child": {
        display: "flex",
        height: "auto",
        background: theme.palette.common.white
      }
    }
  },
  tab: {
    height: 42,
    fontSize: 14,
    textTransform: "uppercase",
    "&$tabSelected": {
      color: theme.palette.secondary.dark
    }
  },
  tabSelected: {},
  tabsIndicator: {
    backgroundColor: theme.palette.secondary.dark
  },
  form: {
    width: "100%"
  },
  section: {
    padding: 0,
    borderTop: "1px solid #d7d7d7",
    boxShadow: "none",
    "&:nth-child(even)": {
      background: "#f6f6f6",
      "&$reviewSectionStables": {
        background: theme.palette.common.white
      }
    },
    "& .MuiCollapse-root": {
      paddingBottom: 12,
      [theme.breakpoints.up("md")]: {
        paddingBottom: 0
      },
      "&.MuiCollapse-hidden": {
        padding: 0
      },
      [theme.breakpoints.up("md")]: {
        paddingBottom: 24
      }
    },
    [theme.breakpoints.up("md")]: {
      "&:first-child": {
        borderTop: "none"
      },
      paddingLeft: 24
    }
  },
  sectionValidIcon: {
    width: 17,
    height: 17,
    marginBottom: 2
  },
  valid: {
    color: "#489B77"
  },
  invalid: {
    color: theme.palette.error.main
  },
  sectionHeader: {
    height: 64,
    "&$sectionHeaderExpanded": {
      borderBottomColor: "transparent"
    },
    [theme.breakpoints.up("md")]: {
      height: 55,
      paddingTop: 10,
      paddingLeft: 0,
      "& $sectionValidIcon": {
        marginLeft: 8
      }
    }
  },
  sectionHeaderExpanded: {},
  sectionHeaderContent: {
    alignItems: "center",
    fontSize: 16,
    [theme.breakpoints.up("md")]: {
      height: 45
    }
  },
  fieldsGroup: {
    display: "flex",
    gap: 20,
    flexWrap: "wrap",
    "& .MuiFormHelperText-root": {
      fontSize: 11,
      marginLeft: 0
    },
    [theme.breakpoints.up("md")]: {
      paddingLeft: 0,
      paddingRight: 20
    }
  },
  field: {
    flex: "0 100%"
  },
  field40: {
    [theme.breakpoints.up("md")]: {
      flex: "0 calc(40% - 10px);"
    }
  },
  field60: {
    [theme.breakpoints.up("md")]: {
      flex: "0 calc(60% - 10px);"
    }
  },
  fieldMobile40: {
    [theme.breakpoints.down("sm")]: {
      flex: "0 calc(40% - 10px);"
    }
  },
  fieldMobile60: {
    [theme.breakpoints.down("sm")]: {
      flex: "0 calc(60% - 10px);"
    }
  },
  fieldSelectRoot: {
    padding: "10px 12px",
    fontSize: 12,
    lineHeight: "normal"
  },
  textField: {},
  textFieldInput: {
    padding: "0 16px",
    height: 36,
    fontSize: 12,
    "&.Mui-disabled": {
      color: "#bababa",
      "-webkit-text-fill-color": "#bababa",
      "-webkit-opacity": 1,
      opacity: 1
    }
  },
  textFieldInputMultiline: {
    padding: "12px 0"
  },
  textFieldLabel: {
    fontSize: 14,
    color: theme.palette.primary.dark,
    position: "relative",
    transform: "none",
    transformOrigin: "none",
    paddingBottom: 8,
    whiteSpace: "nowrap",
    "&.MuiFormLabel-root.Mui-focused": {
      color: theme.palette.primary.dark
    },
    "&.MuiInputLabel-outlined.MuiInputLabel-shrink": {
      transform: "none"
    }
  },
  optional: {
    color: theme.palette.text.hint
  },
  datePickerIcon: {
    width: 17
  },
  yesNoButtonWrapper: {
    display: "flex",
    gap: 15
  },
  yesNoButton: {
    padding: 0,
    height: 46,
    flexGrow: 1,
    flex: 1
  },
  yesNoButtonSelected: {
    "& $yesNoButtonStartIcon": {
      color: "#103827",
      backgroundColor: "#c0e8d8",
      borderColor: "#103827"
    },
    "& $yesNoButtonText": {
      color: "#103827",
      backgroundColor: "#eff9f5",
      borderColor: "#103827"
    }
  },
  yesNoButtonLabel: {
    display: "flex",
    justifyContent: "space-between"
  },
  yesNoButtonStartIcon: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    width: 46,
    height: 46,
    margin: 0,
    border: "1px solid rgba(186, 186, 186, 1)",
    borderRadius: "4px 0 0 4px",
    color: theme.palette.primary.light
  },
  yesNoButtonText: {
    flexGrow: 1,
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    height: 46,
    border: "1px solid rgba(186, 186, 186, 1)",
    borderLeft: "none",
    borderRadius: "0 4px 4px 0",
    textTransform: "none"
  },
  preview: {
    flex: 1,
    display: "flex",
    flexDirection: "column",
    [theme.breakpoints.up("md")]: {
      flexDirection: "row"
    }
  },
  previewSection: {
    display: "flex",
    flexDirection: "column",
    width: "100%",
    padding: 0
  },
  previewGroup: {
    display: "flex",
    flex: "auto",
    flexDirection: "column"
  },
  previewGroupTitle: {
    display: "flex",
    alignItems: "center",
    margin: 0,
    fontSize: 13,
    height: 46,
    color: "rgba(72, 155, 119, 1)",
    padding: "0 16px",
    borderBottom: "1px solid #eeeeee"
  },
  previewGroupList: {
    display: "flex",
    flexDirection: "column"
  },
  previewRow: {
    display: "flex",
    fontSize: 12,
    minHeight: 32,
    alignItems: "center",
    padding: "4px 22px",
    margin: 0,
    borderBottom: "1px solid #eeeeee",
    "& > span:first-child": {
      width: 114,
      minWidth: 114
    },
    "&:nth-child(odd)": {
      background: "#f7f7f7"
    }
  },
  previewRowValue: {
    display: "flex",
    flexDirection: "column",
    wordBreak: "break-all",
    "& > span": {
      lineHeight: "14px"
    }
  },
  tncCheckbox: {
    whiteSpace: "nowrap",
    [theme.breakpoints.down("sm")]: {
      width: "100%"
    },
    "& .MuiSvgIcon-root": {
      fontSize: 40,
      strokeWidth: "1.5px",
      stroke: theme.palette.common.white
    }
  },
  tncCheckboxChecked: {
    color: theme.palette.common.white,
    "& .MuiSvgIcon-root": {
      color: "rgba(72, 155, 119, 1)",
      stroke: "transparent"
    }
  },
  stablingEntries: {
    display: "flex",
    flex: 1,
    [theme.breakpoints.up("md")]: {
      display: "flex",
      flexDirection: "column",
      minWidth: STABLING_ENTRIES_WIDTH,
      maxWidth: STABLING_ENTRIES_WIDTH,
      height: "100%",
      borderLeft: `1px solid ${theme.palette.divider}`
    }
  },
  pageFooterDesktopButtons: {
    display: "flex",
    gap: 20,
    flex: 1,
    justifyContent: "right"
  },
  pauseAndCloseButton: {
    width: 164
  },
  submitButton: {
    width: 176
  },
  deleteStallApplicationButton: {
    color: theme.palette.warning.dark
  },
  termsLink: {
    color: theme.palette.secondary.dark
  },
  reviewSectionDetails: {
    padding: 0,
    "& $sectionHeader": {
      [theme.breakpoints.up("md")]: {
        paddingLeft: 16
      }
    },
    [theme.breakpoints.up("md")]: {
      flex: 1,
      maxWidth: `calc(100% - ${STABLING_ENTRIES_WIDTH}px)`
    }
  },
  reviewSectionStables: {
    padding: 0,
    background: theme.palette.common.white,
    borderTop: "none",
    "& $sectionHeader": {
      background: theme.palette.common.white,
      paddingLeft: 16
    },
    "& $fieldsGroup": {
      padding: 0
    },
    [theme.breakpoints.up("md")]: {
      flex: 1,
      maxWidth: STABLING_ENTRIES_WIDTH,
      borderLeft: `1px solid ${theme.palette.divider}`
    }
  },
  stablingEntryTableHeadRow: {
    background: theme.palette.common.white,
    borderBottom: "1px solid #eeeeee",
    "& $stablingEntryTableCell": {
      padding: "4px 0px 4px 16px",
      color: theme.palette.primary.dark
    }
  },
  stablingEntryTableRow: {
    borderBottom: "1px solid #eeeeee",
    "&:nth-child(odd)": {
      background: theme.palette.common.white
    },
    "&:nth-child(even)": {
      background: "#f7f7f7"
    }
  },
  stablingEntryTableCell: {
    padding: "7px 0px 7px 16px",
    color: theme.palette.common.black,
    fontSize: 12,
    "&:first-child": {
      width: 32
    }
  }
}));
