import { racehorse360 } from "@tsg/1st-grpc-web";
import { DASH } from "common/constants";
import { DistanceUnit } from "interfaces/DistanceUnit";

export const parseDistanceUnit = (value: number): DistanceUnit => {
  switch (value) {
    case 1:
      return "f"; // Furlong
    case 2:
      return "m"; // Meter
    case 3:
      return "y"; // Yard
    default:
      return "";
  }
};

export const parseWorkoutType = (
  value: number,
  showFullText: boolean = false
): string => {
  switch (value) {
    case 1:
      return showFullText ? "Breezing" : "B"; // Breezing
    case 2:
      return showFullText ? "Driving" : "D"; // Driving
    case 3:
      return showFullText ? "Handily" : "H"; // Handily
    default:
      return "";
  }
};

export const parseInjuryType = (value: number): string => {
  switch (value) {
    case 0:
      return "Invalid";
    case 1:
      return "Racing";
    case 2:
      return "Workout";
    case 3:
      return "Other";
    default:
      return "";
  }
};

export const parseTrackCondition = (value: number): string => {
  switch (value) {
    case 1:
      return "ft"; // Fast
    case 2:
      return "fm"; // Firm
    case 3:
      return "fz"; // Frozen
    case 4:
      return "gd"; // Good
    case 5:
      return "hd"; // Hard
    case 6:
      return "hy"; // Heavy
    case 7:
      return "my"; // Muddy
    case 8:
      return "sy"; // Sloppy
    case 9:
      return "sl"; // Slow
    case 10:
      return "sf"; // Soft
    case 11:
      return "wf"; // WetFast
    case 12:
      return "yl"; // Yielding
    default:
      return "";
  }
};

export const parseInjuryTrackCondition = (value: number): string => {
  switch (value) {
    case 1:
      return "Fast";
    case 2:
      return "Firm";
    case 3:
      return "Frozen";
    case 4:
      return "Good";
    case 5:
      return "Hard";
    case 6:
      return "Heavy";
    case 7:
      return "Muddy";
    case 8:
      return "Sloppy";
    case 9:
      return "Slow";
    case 10:
      return "Soft";
    case 11:
      return "WetFast";
    case 12:
      return "Yielding";
    case 13:
      return "Standard";
    case 14:
      return "Wet";
    case 15:
      return "Dead";
    case 16:
      return "Easy";
    case 17:
      return "Good To Firm";
    case 18:
      return "Good To Soft";
    case 19:
      return "Unknown";
    default:
      return "";
  }
};

export const parseCourseSurface = (value: number): string => {
  switch (value) {
    case 1:
      return "E"; // AllWeatherTrack
    case 2:
      return "T"; // Turf
    case 3:
      return "S"; // Steeplechase
    case 4:
      return "N"; // InnerTrack
    case 5:
      return "O"; // OuterTurf
    case 6:
      return "W"; // WoodChips
    case 7:
      return "U"; // HuntOnTurf
    case 8:
      return "B"; // Timber
    case 9:
      return "D"; // Dirt
    case 10:
      return "G"; // TurfTraining
    case 11:
      return "J"; // Jump
    case 12:
      return "A"; // AllWeatherTraining
    case 13:
      return "V"; // Hunt
    case 14:
      return "C"; // DownhillTurf
    case 15:
      return "F"; // DirtTraining
    case 16:
      return "I"; // InnerTurf
    case 17:
      return "M"; // Hurdle
    default:
      return "";
  }
};

export const parseGender = (value: number): string => {
  switch (value) {
    case 1:
      return "Filly";
    case 2:
      return "Colt";
    case 3:
      return "Gelding";
    case 4:
      return "Mare";
    case 5:
      return "Horse";
    case 6:
      return "Spayed Mare";
    case 7:
      return "Ridgling";
    default:
      return "Unknown";
  }
};

export const parseCourseConditionForPC = (value: number): string => {
  switch (value) {
    case 0:
      return "Invalid";
    case 1:
      return "MCL";
    case 2:
      return "MSW";
    case 3:
      return "NXT";
    case 4:
      return "N2";
    case 5:
      return "N2X";
    case 6:
      return "N3";
    case 7:
      return "N3X";
    case 8:
      return "AOC";
    case 9:
      return "ALW";
    default:
      return "Unknown";
  }
};

export const parseCourseDistanceForPC = (value: number): string => {
  switch (value) {
    case 0:
      return "Invalid";
    case 1:
      return "Short";
    case 2:
      return "Long";
    default:
      return "Unknown";
  }
};

export const parseCourseSurfaceForPC = (value: number): string => {
  switch (value) {
    case 0:
      return "Invalid";
    case 1:
      return "Synthetic";
    case 2:
      return "Turf";
    case 3:
      return "Steeplechase";
    case 4:
      return "Inner track";
    case 5:
      return "Outer turf";
    case 6:
      return "Wood chips";
    case 7:
      return "Hunt on turf";
    case 8:
      return "Timber";
    case 9:
      return "Dirt";
    case 10:
      return "Turf training";
    case 11:
      return "Jump";
    case 12:
      return "All weather training";
    case 13:
      return "Hunt";
    case 14:
      return "Downhill turf";
    case 15:
      return "Dirt training";
    case 16:
      return "Inner turf";
    case 17:
      return "Hurdle";
    default:
      return "Unknown";
  }
};

export const parseVoidReason = (value: string): string => {
  switch (value) {
    case "A":
      return "Administrative";
    case "D":
      return "Deceased";
    case "O":
      return "Other";
    case "P":
      return "Positive Test";
    case "V":
      return "Vet";
    default:
      return "Unknown";
  }
};

export const parseHealthWatchStatus = (value: number): string => {
  switch (value) {
    case 1:
      return "A"; // Green
    case 2:
      return "B"; // Yellow
    case 3:
      return "C"; // Red
    default:
      return "";
  }
};

export const parseWorkOrderStatus = (
  value: racehorse360.WorkOrderStatus
): string => {
  switch (value) {
    case racehorse360.WorkOrderStatus.WORK_ORDER_STATUS_ASSIGNED:
      return "Assigned";
    case racehorse360.WorkOrderStatus.WORK_ORDER_STATUS_CANCELED:
      return "Canceled";
    case racehorse360.WorkOrderStatus.WORK_ORDER_STATUS_CLOSED:
      return "Closed";
    case racehorse360.WorkOrderStatus.WORK_ORDER_STATUS_COMPLETE:
      return "Complete";
    case racehorse360.WorkOrderStatus.WORK_ORDER_STATUS_OPEN:
      return "Open";
    default:
      return "";
  }
};

export const parseWorkOrderReasonToClose = (
  value: racehorse360.WorkOrderReasonToClose
): string => {
  switch (value) {
    case racehorse360.WorkOrderReasonToClose
      .WORK_ORDER_REASON_TO_CLOSE_WORK_COMPLETED:
      return "Work Completed";
    case racehorse360.WorkOrderReasonToClose
      .WORK_ORDER_REASON_TO_CLOSE_DUPLICATE_WORK_ORDER:
      return "Duplicate Work Order";
    case racehorse360.WorkOrderReasonToClose
      .WORK_ORDER_REASON_TO_CLOSE_WILL_NOT_FIX_NON_ISSUE:
      return "Won't Fix / Non-Issue";
    case racehorse360.WorkOrderReasonToClose.WORK_ORDER_REASON_TO_CLOSE_OTHER:
      return "Other";
    default:
      return "Select Reason";
  }
};

export const parseHorseGender = (status: racehorse360.HorseGender): string => {
  switch (status) {
    case racehorse360.HorseGender.HORSE_GENDER_FILLY:
      return "f";
    case racehorse360.HorseGender.HORSE_GENDER_COLT:
      return "c";
    case racehorse360.HorseGender.HORSE_GENDER_GELDING:
      return "g";
    case racehorse360.HorseGender.HORSE_GENDER_MARE:
      return "m";
    case racehorse360.HorseGender.HORSE_GENDER_HORSE:
      return "h";
    case racehorse360.HorseGender.HORSE_GENDER_SPAYED_MARE:
      return "s";
    case racehorse360.HorseGender.HORSE_GENDER_RIDGLING:
      return "r";
    default:
      return DASH;
  }
};

export default {
  parseDistanceUnit,
  parseWorkoutType,
  parseTrackCondition,
  parseCourseSurface,
  parseGender,
  parseCourseConditionForPC,
  parseCourseDistanceForPC,
  parseCourseSurfaceForPC,
  parseVoidReason,
  parseWorkOrderReasonToClose,
  parseWorkOrderStatus
};
