import { makeStyles, Theme } from "@material-ui/core/styles";
import { MuiTheme } from "theme";

interface IStylesProps {
  foregroundColor: string;
}

export default makeStyles<Theme, IStylesProps>((theme: MuiTheme) => {
  const { light, main } = theme.palette.primary;
  return {
    root: {
      margin: "0 auto"
    },
    rootPopover: {
      position: "fixed"
    },
    rootPopoverBack: {
      width: "100%"
    },
    facilityButton: {
      display: "flex",
      justifyContent: "left",
      cursor: "pointer",
      maxWidth: "100%",
      textTransform: "capitalize",
      fontSize: 20,
      color: ({ foregroundColor }) =>
        foregroundColor ? `#${foregroundColor}` : main,
      [theme.breakpoints.down("xs")]: {
        paddingTop: 0,
        paddingBottom: 0
      }
    },
    facilityNameText: {
      overflow: "hidden",
      textOverflow: "ellipsis",
      whiteSpace: "nowrap",
      color: "inherit",
      fontSize: "inherit",
      textTransform: "capitalize",
      fontFamily: "SuisseIntlMedium, SuisseIntlRegular"
    },
    facilityNameIcon: {
      marginLeft: 5,
      transition: "all .2s",
      verticalAlign: "middle"
    },
    facilityNameIconOpen: {
      transform: "rotate(180deg)"
    },
    header: {
      padding: "18px 20px 14px",
      borderBottom: `1px solid ${light}`,
      [theme.breakpoints.down("md")]: {
        padding: "18px 20px 16px",
        position: "sticky",
        top: 0,
        backgroundColor: "#fff",
        zIndex: 1
      }
    },
    closeButton: {
      position: "absolute",
      right: 0,
      top: -4,
      color: main
    },
    closeButtonIcon: {
      fontSize: 28
    },
    closeButtonHide: {
      display: "none"
    },
    title: {
      lineHeight: 1
    },
    titleSmall: {
      fontSize: 14,
      lineHeight: 1,
      marginBottom: 9
    },
    subTitle: {
      fontSize: 11
    },
    titleInstructions: {
      marginBottom: 15
    },
    content: {
      padding: 0,
      maxHeight: "calc(100vh - 300px)"
    },
    listItem: {
      backgroundColor: "transparent",
      padding: 16,
      paddingLeft: 20,
      paddingRight: 20,
      color: "inherit",
      [theme.breakpoints.down("md")]: {
        padding: 18
      }
    },
    listItemSmall: {
      padding: "11px 10px",
      paddingLeft: 20,
      paddingRight: 20
    },
    listItemIcon: {
      minWidth: 45
    },
    listItemText: {
      color: "inherit"
    },
    listItemSelected: {
      color: ({ foregroundColor }) =>
        foregroundColor ? `#${foregroundColor}` : main
    }
  };
});
