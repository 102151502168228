import { racehorse360 } from "@tsg/1st-grpc-web";
import pL from "js-regex-pl";
import startOfToday from "date-fns/startOfToday";
import * as Yup from "yup";

import { PHONE_NUMBER_REGEXP } from "common/regExp";

const WORK_ORDER_PHONE_NUMBER_ERROR =
  "Error: Phone Number is not in the correct format";
const PHONE_NUMBER_ERROR = "Phone Number is not valid";

export const validationSchemaWorkOrder = Yup.object({
  workOrderTypeId: Yup.string().required(),
  description: Yup.string()
    .trim()
    .min(1, "Error: Description must be at least 1 characters")
    .max(1500)
    .required("Required"),
  stallNumber: Yup.string().min(1).max(5).label("Stall #"),
  email: Yup.string()
    .trim()
    .email("Error: Please enter a valid email address")
    .label("Email Address*")
    .required("Required"),
  fullName: Yup.string()
    .min(1)
    .max(65)
    .matches(
      new RegExp(`^[${pL} '’,.-]+$`, "i"),
      "Error: Please enter valid full name"
    )
    .label("Full Name*")
    .required("Required"),
  phone: Yup.string()
    .matches(PHONE_NUMBER_REGEXP, WORK_ORDER_PHONE_NUMBER_ERROR)
    .trim()
    .label("Phone Number*")
    .required("Required")
});

export const requireTrainerName = (role: racehorse360.IRole): boolean =>
  role &&
  // all trainers
  role.roleType === racehorse360.RoleType.ROLE_TYPE_TRAINER &&
  // except the main Trainer role. That one doesn't need to specfy a name.
  // we'll assume it.
  role.name !== "Trainer";

export const requireFacility = (role: racehorse360.IRole): boolean =>
  Boolean(role && role.roleType === racehorse360.RoleType.ROLE_TYPE_FACILITY);

export const validationSchemaAccount = Yup.object().shape({
  firstName: Yup.string().min(1).max(32).trim().label("First Name").required(),
  lastName: Yup.string().min(1).max(32).trim().label("Last Name").required(),
  phoneNumber: Yup.string()
    .matches(PHONE_NUMBER_REGEXP, PHONE_NUMBER_ERROR)
    .trim()
    .label("Phone Number")
    .required(),
  licenseNumber: Yup.string().trim().label("License Number").max(20).required(),
  email: Yup.string().trim().email().label("Email").required(),
  role: Yup.object().nullable().label("Role").required(),
  trainerName: Yup.string()
    .label("Trainer Name")
    .test(
      "is-required",
      "Trainer Name is required",
      (value, context) => !requireTrainerName(context.parent.role) || !!value
    ),
  facility: Yup.object()
    .label("Facility")
    .nullable()
    .test(
      "is-required",
      "Facility is required",
      (value, context) => !requireFacility(context.parent.role) || !!value
    )
});

export const validationSchemaStallApplicationForm = Yup.object({
  title: Yup.string().min(1).max(50).trim().required(),
  facilityOptions: Yup.array().min(1).required(),
  startDate: Yup.date().min(startOfToday()).required(),
  deadlineDate: Yup.date()
    .required()
    .when("startDate", startDate => {
      if (startDate?.getTime()) {
        return Yup.date()
          .min(startDate, "Deadline Date should not be before Start Date")
          .typeError("Deadline Date is required");
      }
    }),
  closeDate: Yup.date()
    .required()
    .when("deadlineDate", deadlineDate => {
      if (deadlineDate?.getTime()) {
        return Yup.date()
          .min(deadlineDate, "End Date should not be before Deadline Date")
          .typeError("End Date is required");
      }
    }),
  //pdf: Yup.string().required(),
  pdf: Yup.mixed().required()
});
