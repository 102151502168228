import { makeStyles } from "@material-ui/core/styles";
import { MuiTheme } from "theme";

export default makeStyles((theme: MuiTheme) => {
  const { primary, secondary } = theme.palette;
  return {
    dialog: {
      width: 300,
      padding: "0 16px",
      color: primary.main,
      borderRadius: 3
    },
    dialogTitle: {
      padding: "0 16px 7px",
      margin: "0 -16px",
      borderBottom: `1px solid ${primary.light}`
    },
    title: {
      display: "flex",
      alignItems: "center",
      fontFamily: "SuisseIntlMedium, SuisseIntlRegular",
      fontSize: 20,
      padding: "12px 0 8px"
    },
    selections: {
      display: "flex",
      alignItems: "center",
      fontSize: 16,
      color: "#2a2525",
      height: 17,
      marginBottom: 2
    },
    selectionsNumber: {
      fontFamily: "SuisseIntlMedium, SuisseIntlRegular"
    },
    dialogContent: {
      padding: "0 0 20px",
      "&.withExtraBottomMargin": {
        padding: "0 0 30px"
      }
    },
    alertHeader: {
      padding: "10px 0 6px",
      display: "flex",
      alignItems: "center"
    },
    warningIcon: {
      color: "#e18400",
      fontSize: 20
    },
    alertTitle: {
      fontFamily: "SuisseIntlMedium, SuisseIntlRegular",
      fontSize: 20,
      color: primary.main,
      marginLeft: 10
    },
    warningMessage: {
      color: primary.dark,
      paddingBottom: 10,
      wordBreak: "break-word",
      letterSpacing: "-0.4px"
    },
    horsesList: {
      marginBottom: 22
    },
    horseItem: {
      display: "flex",
      alignItems: "center",
      justifyContent: "space-between",
      marginBottom: 12
    },
    horseProperty: {
      textTransform: "uppercase",
      height: 17
    },
    risksCount: {
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      paddingTop: 1,
      border: "1px solid #d82f44",
      textTransform: "uppercase",
      color: "#d82f44",
      height: 24,
      width: 82,
      fontSize: 12
    },
    messageAboutRestHorses: {
      fontFamily: "SuisseIntlMedium, SuisseIntlRegular",
      color: primary.main
    },
    dialogActions: {
      padding: "10px 0"
    },
    button: {
      padding: "0 10px",
      fontFamily: "SuisseIntlMedium, SuisseIntlRegular",
      fontSize: 16,
      height: 32,
      borderRadius: 0,
      color: primary.dark,
      letterSpacing: 0.5
    },
    confirmButton: {
      marginLeft: 10,
      color: secondary.dark
    },
    confirmSelectionsLoader: {
      zIndex: 1231
    }
  };
});
