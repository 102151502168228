import React from "react";
import FlagIcon from "@material-ui/icons/Flag";
import clsx from "clsx";

import { racehorse360 } from "@tsg/1st-grpc-web";

import useStyles from "./styles";
import { getFlagPriority } from "../helper";

interface IProps {
  priority: racehorse360.FlagRequestPriority;
  className?: string;
}

const FlagRequestPriorityIndicator = (props: IProps) => {
  const { priority, className } = props;
  const classes = useStyles();
  const flagPriorityClassName = getFlagPriority(priority);

  return (
    <FlagIcon
      className={clsx(
        classes.activeFlaggedIcon,
        classes[flagPriorityClassName],
        className
      )}
      data-test={"flagged-icon"}
    />
  );
};

export default FlagRequestPriorityIndicator;
