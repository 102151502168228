import { useEffect, useRef, useCallback } from "react";
import throttle from "lodash/throttle";

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
export const useThrottle = (
  cb: (...args) => void,
  delay: number,
  options: { leading: boolean; trailing: boolean }
) => {
  const cbRef = useRef(cb);

  useEffect(() => {
    cbRef.current = cb;
  });

  return useCallback(
    throttle((...args) => cbRef.current(...args), delay, options),
    [delay]
  );
};
