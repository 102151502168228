import React from "react";

import {
  CUSTOM_VALUE,
  createCustomOption,
  getActionType,
  mapButtons,
  mapOptions
} from "../helper";
import {
  changeExamDetailField,
  changeExamDetailObservationField,
  changeExamJogField,
  EFieldsTypes,
  EVetExamFields,
  FormField,
  IExamDetailObservation
} from "../options";
import ExamButtonsGroup from "../ExamButtonsGroup";
import ExamSelect from "../ExamSelect";
import ExamComment from "../ExamComment";
import MediaBasket from "../MediaBasket";
import { IExamDetail, IExamJog } from "../options";
import { useExamLockerContext } from "../../ExamLockerContext/ExamLockerContext";
import { useVetExamContext } from "../VetExamStateProvider/VetExamProvider";
import useStyles from "./styles";

interface IProps {
  field: FormField;
  entryState: IExamJog | IExamDetail | IExamDetailObservation;
  parentId?: string;
}

const getDataTestLabel = (key: EVetExamFields) => {
  switch (key) {
    case EVetExamFields.DETAIL_LIMB:
      return "exam-details-limb-dropdown";
    case EVetExamFields.DETAIL_LOCATION:
      return "exam-details-location-dropdown";
    case EVetExamFields.DETAIL_STRUCTURE:
      return "exam-details-struct-dropdown";
    case EVetExamFields.DETAIL_OBSERVATION:
      return "exam-details-observation-dropdown";
    case EVetExamFields.DETAIL_CONDITION:
      return "exam-details-condition-dropdown";
    case EVetExamFields.DETAIL_NOTE:
      return "exam-details-condition-note";
  }
};

const VetExamFormField = (props: IProps) => {
  const { field, entryState, parentId } = props;
  const classes = useStyles();
  const { lockExam } = useExamLockerContext();
  const { dispatch, formFieldsItems } = useVetExamContext();

  const customNameField = entryState[field.custom];
  const options = mapOptions(formFieldsItems[field.optionsName]);

  const changeFieldValue = (value: string, customValue: string = null) => {
    const { isJogField, isDetailField, isObservationField } = getActionType(
      field.key
    );
    let actionCreator;

    if (isJogField) {
      actionCreator = changeExamJogField(
        entryState.id,
        field.key,
        value,
        field.custom,
        customValue
      );
    }
    if (isDetailField) {
      actionCreator = changeExamDetailField(
        entryState.id,
        field.key,
        value,
        field.custom,
        customValue
      );
    }
    if (isObservationField && parentId) {
      actionCreator = changeExamDetailObservationField(
        parentId,
        entryState.id,
        field.key,
        value,
        field.custom,
        customValue
      );
    }

    dispatch(actionCreator);
    lockExam();
  };

  const handleButtonClick = (value: string) => {
    changeFieldValue(value);
  };

  const handleChangeSelect = (value: string, customValue: string) => {
    changeFieldValue(value, customValue);
  };

  const handleBlurComment = event => {
    changeFieldValue(event.currentTarget.value);
  };

  switch (field.type) {
    case EFieldsTypes.BUTTONS_GROUP:
      return (
        <ExamButtonsGroup
          key={field.key}
          className={classes.buttonGroup}
          title={field.title}
          name={field.key}
          buttons={mapButtons(formFieldsItems[field.optionsName], field.key)}
          selectedValue={entryState[field.key]}
          onClickButton={handleButtonClick}
        />
      );
    case EFieldsTypes.SELECT:
      if (entryState[field.key] === CUSTOM_VALUE) {
        options.push(createCustomOption(customNameField));
      }

      return (
        <ExamSelect
          key={field.key}
          className={classes.select}
          hasCustomField={true}
          options={options}
          label={field.title}
          name={field.key}
          inputLabel={field.title}
          selectedValue={entryState[field.key]}
          onChangeSelectedValue={handleChangeSelect}
          dataTestLabel={getDataTestLabel(field.key)}
        />
      );
    case EFieldsTypes.TEXT:
      return (
        <ExamComment
          key={field.key}
          title={field.title}
          className={classes.examComment}
          textareaClassName={classes.textareaField}
          onBlur={handleBlurComment}
          defaultValue={entryState[field.key] || ""}
          data-test={getDataTestLabel(field.key)}
        />
      );
    case EFieldsTypes.MEDIA:
      return <MediaBasket key={field.key} />;
  }

  return <></>;
};

export default VetExamFormField;
