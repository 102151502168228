import React, { useContext, useState, SyntheticEvent } from "react";

export interface NavigationContextModel {
  blockClick: boolean;
  setBlockClick: (block: boolean) => void;
  blockClickCallback: () => void;
  setBlockClickCallback: (callback: () => void) => void;
}

export interface WithClickBlockerProps {
  handleBlockableLinkClick: (e: SyntheticEvent) => boolean;
}

export const BlockableClickContext =
  React.createContext<NavigationContextModel>({
    blockClick: false,
    setBlockClick: (block: boolean) => {
      return null;
    },
    blockClickCallback: null,
    setBlockClickCallback: (callback: () => void) => {
      return null;
    }
  });

export const useClickBlockerContext = () => useContext(BlockableClickContext);

export const BlockableClickContextProvider = props => {
  const { children } = props;
  const [blockClick, setBlockClick] = useState<boolean>(false);
  const [blockClickCallback, setBlockClickCallback] =
    useState<() => void>(null);

  return (
    <BlockableClickContext.Provider
      value={{
        blockClick,
        setBlockClick,
        blockClickCallback,
        setBlockClickCallback
      }}
    >
      {children}
    </BlockableClickContext.Provider>
  );
};

export const withClickBlocker = Component => props => {
  const { blockClick, blockClickCallback } = useClickBlockerContext();

  const handleBlockableLinkClick = e => {
    if (blockClick && blockClickCallback) {
      e.preventDefault();
      blockClickCallback();
      return true;
    }
    return false;
  };

  return (
    <Component {...props} handleBlockableLinkClick={handleBlockableLinkClick} />
  );
};
