import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import clsx from "clsx";

import format from "date-fns/format";
import parseISO from "date-fns/parseISO";
import differenceInDays from "date-fns/differenceInDays";

import Button from "@material-ui/core/Button";
import Checkbox from "@material-ui/core/Checkbox";
import CheckIcon from "@material-ui/icons/Check";
import IconButton from "@material-ui/core/IconButton";
import RemoveCircleOutlineRoundedIcon from "@material-ui/icons/RemoveCircleOutlineRounded";
import AddCircleOutlineIcon from "@material-ui/icons/AddCircleOutline";
import ChatOutlinedIcon from "@material-ui/icons/ChatOutlined";

import { racehorse360 } from "@tsg/1st-grpc-web";

import routes from "common/routes";
import AppPageTableRow from "components/AppPageTableRow";
import AppPageTableRowHeader from "components/AppPageTableRowHeader";
import AppPageTableRowContent from "components/AppPageTableRowContent";
import AbcCircle from "components/AbcCircle";
import ErrorBoundary from "components/ErrorBoundary";
import CommentIcon from "components/Icons/Comment";
import Loader from "components/Loader";
import { useLoggedInUser } from "components/LoggedInUserProvider";
import { useExamLockerContext } from "components/ExamLockerContext/ExamLockerContext";
import VetWorkoutsPageState from "interfaces/VetWorkoutsPageState";
import { VetWorkoutsTab } from "interfaces/VetWorkoutsTab";
import { useRacehorse360Api } from "hooks/api";
import {
  setSelectedWorkoutRequest,
  setPassCheckedIds,
  setExamCheckedIds,
  setAssignCheckedIds,
  setCommentsPopoverAnchorEl,
  setSavingUndoQuickPassIds,
  setSavingUnAssignIds,
  setIsFOTCommentDisplayed
} from "store/actions/vetWorkoutsPage";
import { isBeforeDate } from "utils/date-utils";
import { getHorseAgeSexColorValue } from "utils/horse";
import { getPersonFullName } from "utils/person";
import WorkoutRequestPPs from "./WorkoutRequestPPs";
import UndoQuickPass from "./UndoQuickPass";
import ConfirmPopUp from "./ConfirmPopUp";
import FlagRequestContent from "./FlagRequestContent/FlagRequestContent";
import VetListsPopUp from "./VetListsPopUp";
import ExamReasonNote from "./ExamReasonNote";
import { checkCurrentWorkoutsTableTab } from "../../helper";
import LastExamNote from "./LastExamNote";
import RejectRequestDialog from "./RejectRequestDialog";
import WorkoutHistoryModal from "./WorkoutHistoryModal";
import FlagRequestPriorityIndicator from "./FlagRequestPriorityIndicator";

import useSharedStyles from "../styles";
import useStyles from "./styles";

const NotAccessible = "N/A";
const NoneValue = "None";

enum EColumn {
  TRAINER,
  HISTORY,
  DATE,
  RISK_LEVEL,
  BARN,
  CSA,
  HSF,
  DLW,
  DLR,
  LAST_EXAM_DATE,
  ASSIGNED_VET,
  UNASSIGN,
  EXAM,
  REASON,
  EXAM_TYPE,
  EXTENDER,
  RISK_FACTORS,
  FLAG_REQUEST,
  ACTIVE_VET_LISTS
}

interface IColumnData {
  render: (workoutRequest: racehorse360.IWorkoutRequest) => React.ReactNode;
}

interface ITableColumns {
  [key: string]: IColumnData;
}

interface IProps {
  index: number;
  workoutRequest: racehorse360.IWorkoutRequest;
  refetchWorkoutsAndExams: (value?: boolean) => Promise<void>;
  riskFactorColumnWidth: number;
  onExamClick?: (request: racehorse360.IWorkoutRequest) => void;
}

const Row = (props: IProps) => {
  const {
    index,
    workoutRequest,
    refetchWorkoutsAndExams,
    riskFactorColumnWidth,
    onExamClick
  } = props;
  const { isLocked, pullLocker } = useExamLockerContext();

  const classes = useStyles();

  const sharedClasses = useSharedStyles({ riskFactorColumnWidth });

  const dispatch = useDispatch();
  const history = useHistory();
  const { currentUser } = useLoggedInUser();

  const {
    selectedWorkoutRequest,
    selectedTab,
    examCheckedIds,
    passCheckedIds,
    assignCheckedIds,
    savingCheckedIds,
    savingUndoQuickPassIds,
    savingUnAssignIds
  } = useSelector(
    (state: { vetWorkoutsPage: VetWorkoutsPageState }) => state?.vetWorkoutsPage
  );

  const { isRequestsTab, isExamsTab, isPendingTab, isPassedTab, isFailedTab } =
    checkCurrentWorkoutsTableTab(selectedTab.value);
  const [isUnAssignDialogOpen, setIsUnAssignDialogOpen] =
    useState<boolean>(false);
  const [isVetListsDialogOpen, setIsVetListsDialogOpen] =
    useState<boolean>(false);

  const isOverdueRequest = isBeforeDate(
    parseISO(workoutRequest.date),
    new Date()
  );

  const isExamChecked = examCheckedIds?.includes(workoutRequest.id);
  const isPassChecked = passCheckedIds?.includes(workoutRequest.id);
  const isAssignChecked = assignCheckedIds?.includes(
    workoutRequest.workoutExam?.id
  );
  const [isRowRejected, setIsRowRejected] = useState(false);
  const isUndoQuickPassSaving = savingUndoQuickPassIds?.includes(
    workoutRequest.id
  );
  const isUnAssignSaving = savingUnAssignIds?.includes(workoutRequest.id);
  const isCheckBoxDisabled =
    savingCheckedIds?.includes(
      isRequestsTab ? workoutRequest.id : workoutRequest.workoutExam?.id
    ) ||
    (isOverdueRequest && !isExamsTab);

  const { useRevertQuickPassWorkoutExam, useUnassignWorkoutExam } =
    useRacehorse360Api();

  const { mutateAsync: revertQuickPassWorkoutExam } =
    useRevertQuickPassWorkoutExam();
  const { mutateAsync: unassignWorkoutExam } = useUnassignWorkoutExam();

  const tableColumns: ITableColumns = {
    [EColumn.TRAINER]: {
      render: val => {
        const trainer = val.horse?.trainer || val.workoutExam?.horse?.trainer;
        return (
          <div
            key={`${val.id}-trainer`}
            className={clsx(classes.rowCell, {
              [classes.rowCellBold]: isRequestsTab
            })}
          >
            <div className={classes.trainerName}>
              {getPersonFullName(trainer)}
            </div>
            {!!val.hasComment && (
              <CommentIcon
                className={classes.trainerComment}
                onClick={e => handleCommentIconClick(val, e)}
              />
            )}
          </div>
        );
      }
    },
    [EColumn.HISTORY]: {
      render: val => {
        const horse = val.horse || val.workoutExam?.horse;
        return (
          <div
            key={`${val.id}-history`}
            className={clsx(classes.rowCell, classes.rowCellCentered)}
          >
            {hasPastWorkouts && (
              <WorkoutHistoryModal horse={horse} sourceDate={val.date} />
            )}
          </div>
        );
      }
    },
    [EColumn.DATE]: {
      render: val => (
        <div
          key={`${val.id}-date`}
          className={clsx(classes.rowCell, classes.rowCellCentered, {
            [classes.rowCellBold]: isRequestsTab,
            [sharedClasses.greyColor]: !val.date
          })}
        >
          {val.date && format(parseISO(val.date), "MM/dd")}
        </div>
      )
    },
    [EColumn.RISK_LEVEL]: {
      render: val => {
        const healthWatchStatus =
          val.horse?.healthWatchStatus ||
          val.workoutExam?.horse?.healthWatchStatus;
        return (
          <div
            key={`${val.id}-risk-level`}
            className={clsx(classes.rowCell, classes.rowCellCentered, {
              [classes.rowCellBold]: isRequestsTab
            })}
          >
            <AbcCircle healthWatchStatus={healthWatchStatus} />
          </div>
        );
      }
    },
    [EColumn.BARN]: {
      render: val => {
        const barnName =
          val.horse?.barn?.name || val.workoutExam?.horse?.barn?.name;
        return (
          <div
            key={`${val.id}-barn`}
            className={clsx(classes.rowCell, classes.rowCellCentered, {
              [classes.rowCellBold]: isRequestsTab,
              [sharedClasses.greyColor]: !barnName
            })}
          >
            {barnName || NotAccessible}
          </div>
        );
      }
    },
    [EColumn.CSA]: {
      render: val => {
        const horse = val.horse || val.workoutExam?.horse;
        return (
          <div
            key={`${val.id}-csa`}
            className={clsx(classes.rowCell, classes.rowCellCentered, {
              [classes.rowCellBold]: isRequestsTab
            })}
          >
            {getHorseAgeSexColorValue(horse)}
          </div>
        );
      }
    },
    [EColumn.HSF]: {
      render: val => {
        const highSpeedFurlongs =
          val.highSpeedFurlongs || val.workoutExam?.highSpeedFurlongs;
        return (
          <div
            key={`${val.id}-hsf`}
            className={clsx(classes.rowCell, classes.rowCellCentered, {
              [classes.rowCellBold]: isRequestsTab
            })}
          >
            {highSpeedFurlongs}
          </div>
        );
      }
    },
    [EColumn.DLW]: {
      render: val => {
        const lastWorkoutDate =
          val.horse?.lastWorkoutDate || val.workoutExam?.horse?.lastWorkoutDate;
        return (
          <div
            key={`${val.id}-dlw`}
            className={clsx(classes.rowCell, classes.rowCellCentered, {
              [classes.rowCellBold]: isRequestsTab,
              [sharedClasses.greyColor]: !lastWorkoutDate
            })}
          >
            {lastWorkoutDate
              ? differenceInDays(new Date(), parseISO(lastWorkoutDate))
              : NotAccessible}
          </div>
        );
      }
    },
    [EColumn.DLR]: {
      render: val => {
        const lastRaceDate =
          val.horse?.lastRaceDate || val.workoutExam?.horse?.lastRaceDate;
        return (
          <div
            key={`${val.id}-dlr`}
            className={clsx(classes.rowCell, classes.rowCellCentered, {
              [classes.rowCellBold]: isRequestsTab,
              [sharedClasses.greyColor]: !lastRaceDate
            })}
          >
            {lastRaceDate
              ? differenceInDays(new Date(), parseISO(lastRaceDate))
              : NotAccessible}
          </div>
        );
      }
    },
    [EColumn.LAST_EXAM_DATE]: {
      render: val => {
        const lastExamDate =
          val.horse?.lastExamDate || val.workoutExam?.horse?.lastExamDate;
        return (
          <div
            key={`${val.id}-last-exam-date`}
            className={clsx(classes.rowCell, classes.rowCellCentered, {
              [classes.rowCellBold]: isRequestsTab,
              [sharedClasses.greyColor]: !lastExamDate
            })}
          >
            {lastExamDate ? format(parseISO(lastExamDate), "MM/dd") : ""}
          </div>
        );
      }
    },
    [EColumn.ASSIGNED_VET]: {
      render: val => (
        <div
          key={`${val.id}-assigned-vet`}
          className={clsx(classes.rowCell, {
            [classes.fadedOut]: !val.workoutExam?.assignedUser
          })}
        >
          <div className={classes.ellipsis}>
            {val.workoutExam?.assignedUser
              ? getPersonFullName(val.workoutExam?.assignedUser)
              : NoneValue}
          </div>
        </div>
      )
    },
    [EColumn.UNASSIGN]: {
      render: val => (
        <div
          key={`${val.id}-unassign`}
          className={clsx(classes.rowCell, classes.rowCellCentered)}
        >
          {shouldShowButton(val.workoutExam) && (
            <>
              {isUnAssignSaving ? (
                <Loader size={14} disableGutters={true} />
              ) : (
                <IconButton
                  size={"small"}
                  disabled={isUnassignDenied(val.workoutExam)}
                  onClick={handleUnAssignClick(val)}
                  className={classes.unassign}
                >
                  <RemoveCircleOutlineRoundedIcon fontSize={"small"} />
                </IconButton>
              )}
            </>
          )}
        </div>
      )
    },
    [EColumn.EXAM]: {
      render: val => (
        <div
          key={`${val.id}-exam`}
          className={clsx(classes.rowCell, classes.rowCellCentered)}
        >
          {shouldShowButton(val.workoutExam) && (
            <Button
              size={"small"}
              disabled={isExamLaunchDenied(val.workoutExam)}
              onClick={handleExamButtonClick(val)}
              startIcon={<AddCircleOutlineIcon className={classes.examIcon} />}
              className={classes.exam}
            >
              EXAM
            </Button>
          )}
        </div>
      )
    },
    [EColumn.REASON]: {
      render: val => (
        <div
          key={`${val.id}-reason`}
          className={clsx(classes.rowCell, classes.rowCellCentered)}
        >
          {/*TODO: reason*/}
          {/*{workoutRequest.reason}*/}
        </div>
      )
    },
    [EColumn.EXAM_TYPE]: {
      render: val => (
        <div key={`${val.id}-exam-type`} className={classes.rowCell}>
          {val.workoutExam?.type ===
            racehorse360.WorkoutExamType.WORKOUT_EXAM_TYPE_QUICK_PASS && (
            <>
              Quick Passed
              {isUndoQuickPassSaving ? (
                <Loader size={14} disableGutters={true} />
              ) : (
                <UndoQuickPass
                  workoutRequest={val}
                  onOpen={handleUndoQuickPassOpen}
                  onClose={handleUndoQuickPassClose}
                  onConfirm={handleUndoQuickPassConfirm}
                />
              )}
            </>
          )}
          {val.workoutExam?.type ===
            racehorse360.WorkoutExamType.WORKOUT_EXAM_TYPE_NORMAL && (
            <>Exam Passed</>
          )}
        </div>
      )
    },
    [EColumn.FLAG_REQUEST]: {
      render: value => (
        <FlagRequestContent
          key={`${value.id}-flag-request`}
          workoutRequest={value}
          userId={currentUser.rh360Id}
          refetchWorkouts={refetchWorkoutsAndExams}
        />
      )
    },
    [EColumn.ACTIVE_VET_LISTS]: {
      render: value => {
        const activeVetListsNumber =
          value.horse?.activeVetListsCount ||
          value.workoutExam?.horse?.activeVetListsCount;

        return (
          <div
            key={`${value.id}-active-vet-lists`}
            className={classes.activeVetLists}
          >
            {Boolean(activeVetListsNumber) && (
              <Button
                className={classes.activeVetListsContent}
                onClick={handleOpenVetListsPopUp}
              >
                {activeVetListsNumber}
              </Button>
            )}
          </div>
        );
      }
    },
    [EColumn.RISK_FACTORS]: {
      render: val => {
        const horse = val.horse || val.workoutExam?.horse;
        const risksShortDescription = JSON.parse(horse.riskFactors)
          .map(item => item.shortDescription)
          .sort((a, b) => a.localeCompare(b));

        return (
          <div
            key={`${val.id}-risk-factors`}
            className={clsx(classes.rowCell, classes.riskFactors)}
          >
            {risksShortDescription.length ? (
              risksShortDescription.map((item, index) => (
                <div
                  key={`${val.id}-risk-factors-${index}`}
                  className={classes.riskFactorsItem}
                >
                  {item}
                </div>
              ))
            ) : (
              <span className={classes.noRiskFactors}>{NoneValue}</span>
            )}
          </div>
        );
      }
    },
    [EColumn.EXTENDER]: {
      render: val => (
        <div
          key={`${val.id}-extender`}
          className={clsx(classes.rowCell, {
            [sharedClasses.lastTwoColumnsCell]: isRequestsTab,
            [sharedClasses.lastOneColumnCell]: isExamsTab
          })}
        >
          &nbsp;
        </div>
      )
    }
  };

  const createConfigTable = (activeTab: VetWorkoutsTab): EColumn[] => {
    switch (activeTab.value) {
      case "requests":
        return [
          EColumn.TRAINER,
          EColumn.HISTORY,
          EColumn.DATE,
          EColumn.RISK_LEVEL,
          EColumn.BARN,
          EColumn.CSA,
          EColumn.HSF,
          EColumn.DLW,
          EColumn.DLR,
          EColumn.LAST_EXAM_DATE,
          EColumn.FLAG_REQUEST,
          EColumn.ACTIVE_VET_LISTS,
          EColumn.RISK_FACTORS,
          EColumn.EXTENDER
        ];
      case "exams":
        return [
          EColumn.TRAINER,
          EColumn.HISTORY,
          EColumn.DATE,
          EColumn.RISK_LEVEL,
          EColumn.FLAG_REQUEST,
          EColumn.BARN,
          EColumn.CSA,
          EColumn.HSF,
          EColumn.DLW,
          EColumn.DLR,
          EColumn.LAST_EXAM_DATE,
          EColumn.ASSIGNED_VET,
          EColumn.UNASSIGN,
          EColumn.EXAM,
          EColumn.ACTIVE_VET_LISTS,
          EColumn.RISK_FACTORS,
          EColumn.EXTENDER
        ];
      case "pending":
        return [
          EColumn.TRAINER,
          EColumn.DATE,
          EColumn.RISK_LEVEL,
          EColumn.BARN,
          EColumn.REASON,
          EColumn.CSA,
          EColumn.HSF,
          EColumn.DLW,
          EColumn.DLR,
          EColumn.LAST_EXAM_DATE
        ];
      case "passed":
        return [
          EColumn.TRAINER,
          EColumn.DATE,
          EColumn.RISK_LEVEL,
          EColumn.BARN,
          EColumn.EXAM_TYPE,
          EColumn.ASSIGNED_VET,
          EColumn.CSA,
          EColumn.HSF,
          EColumn.DLW,
          EColumn.DLR,
          EColumn.LAST_EXAM_DATE
        ];
      case "failed":
        return [
          EColumn.TRAINER,
          EColumn.DATE,
          EColumn.RISK_LEVEL,
          EColumn.BARN,
          EColumn.ASSIGNED_VET,
          EColumn.CSA,
          EColumn.HSF,
          EColumn.DLW,
          EColumn.DLR,
          EColumn.LAST_EXAM_DATE
        ];
      default:
        return [];
    }
  };

  const horse = workoutRequest.horse || workoutRequest.workoutExam?.horse;

  const hasPastWorkouts = workoutRequest.pastWorkoutRequestsCount > 1;

  const horseDetailsUrl = routes.horseDetails.path.replace(
    ":horseId",
    String(horse?.id)
  );

  const shouldShowButton = (
    workoutExam: racehorse360.IWorkoutExam
  ): boolean => {
    return Boolean(workoutExam?.assignedUser?.id);
  };

  const isUnassignDenied = (
    workoutExam: racehorse360.IWorkoutExam
  ): boolean => {
    const isAssignedToCurrentTrackVet =
      currentUser.isTrackVet &&
      currentUser.rh360Id === workoutExam.assignedUser?.id;
    const isCurrentChiefOrSeniorVet =
      currentUser.isSeniorVet || currentUser.isChiefVet;
    const isRequested =
      workoutExam.status ===
      racehorse360.WorkoutExamStatus.WORKOUT_EXAM_STATUS_REQUESTED;

    return (
      !(isCurrentChiefOrSeniorVet || isAssignedToCurrentTrackVet) ||
      !isRequested ||
      (isOverdueRequest && !isExamsTab)
    );
  };

  const isReassignDenied = (
    workoutExam: racehorse360.IWorkoutExam
  ): boolean => {
    const isCurrentTrackVet = currentUser.isTrackVet;
    const isCurrentChiefOrSeniorVet =
      currentUser.isSeniorVet || currentUser.isChiefVet;
    const isAssignedToCurrentTrackVet =
      currentUser.rh360Id === workoutExam.assignedUser?.id;
    const isRequested =
      workoutExam.status ===
      racehorse360.WorkoutExamStatus.WORKOUT_EXAM_STATUS_REQUESTED;

    return (
      ((!isCurrentTrackVet || (!isAssignedToCurrentTrackVet && !isRequested)) &&
        !isCurrentChiefOrSeniorVet) ||
      (isOverdueRequest && !isExamsTab)
    );
  };

  const isExamLaunchDenied = (
    workoutExam: racehorse360.IWorkoutExam
  ): boolean => {
    const isAssignedToCurrentTrackVet =
      currentUser.isTrackVet &&
      currentUser.rh360Id === workoutExam.assignedUser?.id;
    const isCurrentChiefOrSeniorVet =
      currentUser.isSeniorVet || currentUser.isChiefVet;

    return (
      !(isAssignedToCurrentTrackVet || isCurrentChiefOrSeniorVet) ||
      isLocked ||
      (isOverdueRequest && !isExamsTab)
    );
  };

  const getCheckBoxIcon = () => {
    if (isCheckBoxDisabled) {
      return <Loader size={16} disableGutters={true} />;
    } else {
      return (
        <span className={classes.menuButtonContentChecked}>
          <CheckIcon className={classes.menuButtonIcon} />
        </span>
      );
    }
  };

  const setExamChecked = (workoutRequestId: string, checked: boolean) => {
    if (checked) {
      dispatch(setExamCheckedIds([...examCheckedIds, workoutRequestId]));
    } else {
      dispatch(
        setExamCheckedIds(
          examCheckedIds.filter(
            examCheckedId => examCheckedId !== workoutRequestId
          )
        )
      );
    }
  };

  const setPassChecked = (workoutRequestId: string, checked: boolean) => {
    if (checked) {
      dispatch(setPassCheckedIds([...passCheckedIds, workoutRequestId]));
    } else {
      dispatch(
        setPassCheckedIds(
          passCheckedIds.filter(
            passCheckedId => passCheckedId !== workoutRequestId
          )
        )
      );
    }
  };

  const setAssignChecked = (workoutExamId: string, checked: boolean) => {
    if (checked) {
      dispatch(setAssignCheckedIds([...assignCheckedIds, workoutExamId]));
    } else {
      dispatch(
        setAssignCheckedIds(
          assignCheckedIds.filter(
            assignCheckedId => assignCheckedId !== workoutExamId
          )
        )
      );
    }
  };

  const handleCommentIconClick = (
    workoutRequest: racehorse360.IWorkoutRequest,
    event: React.ChangeEvent<HTMLElement>
  ) => {
    event.stopPropagation();
    dispatch(setSelectedWorkoutRequest(workoutRequest));
    dispatch(setCommentsPopoverAnchorEl(event.target));
  };

  const handleFOTCommentIconClick = event => {
    event.stopPropagation();
    event.preventDefault();
    dispatch(setSelectedWorkoutRequest(workoutRequest));
    dispatch(setIsFOTCommentDisplayed(true));
    dispatch(setCommentsPopoverAnchorEl(event.target));
  };

  const handleExamCheckChange =
    (workoutRequestId: string) =>
    (event: React.ChangeEvent<HTMLInputElement>) => {
      const checked = event.target.checked;
      setExamChecked(workoutRequestId, checked);
      if (checked) {
        setPassChecked(workoutRequestId, false);
      }
    };

  const handlePassCheckChange =
    (workoutRequestId: string) =>
    (event: React.ChangeEvent<HTMLInputElement>) => {
      const checked = event.target.checked;
      setPassChecked(workoutRequestId, checked);
      if (checked) {
        setExamChecked(workoutRequestId, false);
      }
    };

  const uncheckExamPass = (workoutRequestId: string) => {
    setExamChecked(workoutRequestId, false);
    setPassChecked(workoutRequestId, false);
  };

  const handleExamClick = () => {
    history.push(horseDetailsUrl);
  };

  const handleExamButtonClick =
    (workoutRequest: racehorse360.IWorkoutRequest) => event => {
      event.stopPropagation();
      pullLocker().then(response => {
        if (!response.data.isLocked) {
          onExamClick(workoutRequest);
        }
      });
    };

  const handleUnAssignClick =
    (workoutRequest: racehorse360.IWorkoutRequest) => event => {
      event.preventDefault();
      event.stopPropagation();
      dispatch(setSelectedWorkoutRequest(workoutRequest));
      setIsUnAssignDialogOpen(true);
    };

  const handleUnAssignDialogConfirm = () => {
    handleUnAssignDialogClose();

    dispatch(
      setSavingUnAssignIds([...savingUnAssignIds, selectedWorkoutRequest.id])
    );

    const clearUnAssignIds = () => {
      dispatch(
        setSavingUnAssignIds(
          savingUnAssignIds.filter(savingUnAssignId => {
            return savingUnAssignId !== selectedWorkoutRequest.id;
          })
        )
      );
    };

    unassignWorkoutExam({
      id: selectedWorkoutRequest.workoutExam.id
    })
      .then(async () => {
        clearUnAssignIds();
        await refetchWorkoutsAndExams();
      })
      .catch(error => {
        console.error("ERROR", error);
        clearUnAssignIds();
      });
  };

  const handleUnAssignDialogClose = () => {
    setIsUnAssignDialogOpen(false);
  };

  const handleAssignCheckChange =
    (workoutExamId: string) => (event: React.ChangeEvent<HTMLInputElement>) => {
      const checked = event.target.checked;
      setAssignChecked(workoutExamId, checked);
    };

  const handleUndoQuickPassOpen = (
    workoutRequest: racehorse360.IWorkoutRequest
  ) => {
    setSelectedWorkoutRequest(workoutRequest);
  };

  const handleUndoQuickPassConfirm = (
    workoutRequest: racehorse360.IWorkoutRequest
  ) => {
    dispatch(
      setSavingUndoQuickPassIds([...savingUndoQuickPassIds, workoutRequest.id])
    );

    const clearUndoQuickPassIds = () => {
      dispatch(
        setSavingUndoQuickPassIds(
          savingUndoQuickPassIds.filter(savingUndoQuickPassId => {
            return savingUndoQuickPassId !== workoutRequest.id;
          })
        )
      );
    };

    revertQuickPassWorkoutExam({
      id: workoutRequest.workoutExam.id
    })
      .then(async () => {
        clearUndoQuickPassIds();
        await refetchWorkoutsAndExams();
      })
      .catch(error => {
        console.error(error);
        clearUndoQuickPassIds();
      });
  };

  const handleUndoQuickPassClose = () => {
    setSelectedWorkoutRequest(null);
  };

  const handleOpenVetListsPopUp = event => {
    event.stopPropagation();
    setIsVetListsDialogOpen(true);
  };

  const handleCloseVetListsPopUp = () => {
    setIsVetListsDialogOpen(false);
  };

  const renderRequestMenuButtons = () => (
    <div className={classes.rowMenu}>
      <div
        className={clsx(classes.menuButtons, {
          [classes.rowExamChecked]: isExamChecked,
          [classes.rowPassChecked]: isPassChecked,
          [classes.rowRejected]: isRowRejected
        })}
      >
        <Checkbox
          className={clsx(classes.menuButton, {
            [classes.menuButtonExamChecked]: isExamChecked
          })}
          disableRipple
          disabled={isCheckBoxDisabled}
          color="default"
          icon={<span className={classes.menuButtonContent} />}
          checkedIcon={getCheckBoxIcon()}
          checked={isExamChecked}
          onChange={handleExamCheckChange(workoutRequest.id)}
          data-test={"exam-checkbox"}
        />
        <Checkbox
          className={clsx(classes.menuButton, {
            [classes.menuButtonPassChecked]: isPassChecked
          })}
          disableRipple
          disabled={isCheckBoxDisabled}
          color="default"
          icon={<span className={classes.menuButtonContent} />}
          checkedIcon={getCheckBoxIcon()}
          checked={isPassChecked}
          onChange={handlePassCheckChange(workoutRequest.id)}
          data-test={"quick-pass-checkbox"}
        />
        <RejectRequestDialog
          workoutRequest={workoutRequest}
          isRowRejected={isRowRejected}
          setIsRowRejected={setIsRowRejected}
          disabled={isCheckBoxDisabled}
          uncheckExamPass={uncheckExamPass}
        />
      </div>
    </div>
  );

  const renderExamMenuButtons = () => (
    <div className={classes.rowMenu}>
      <div
        className={clsx(classes.menuButtons, {
          [classes.rowAssignChecked]: isAssignChecked
        })}
      >
        <Checkbox
          className={clsx(classes.menuButton, {
            [classes.menuButtonAssignChecked]: isAssignChecked
          })}
          disableRipple
          disabled={
            isCheckBoxDisabled || isReassignDenied(workoutRequest.workoutExam)
          }
          color="default"
          icon={<span className={classes.menuButtonContent} />}
          checkedIcon={getCheckBoxIcon()}
          checked={isAssignChecked}
          onChange={handleAssignCheckChange(workoutRequest.workoutExam?.id)}
        />
      </div>
    </div>
  );

  const key = workoutRequest?.workoutExam?.id || workoutRequest?.id;

  return (
    <AppPageTableRow
      key={key}
      className={clsx({
        [classes.rowOdd]: index % 2,
        [classes.rowExamChecked]: isExamChecked,
        [classes.rowPassChecked]: isPassChecked,
        [classes.rowAssignChecked]: isAssignChecked,
        [classes.rowRejected]: isRowRejected
      })}
      data-test={"table-row"}
    >
      <AppPageTableRowHeader>
        <span data-test={"horse-name"}>{horse?.name}</span>
        {workoutRequest.flagRequest && (isExamsTab || isRequestsTab) && (
          <FlagRequestPriorityIndicator
            priority={workoutRequest.flagRequest.priority}
          />
        )}
        {isRequestsTab && workoutRequest.horse.hasActualFlagOnTrack && (
          <>
            <span className={clsx(classes.FOT)}>FOT</span>
            <IconButton
              size={"small"}
              className={classes.FOTButton}
              onClick={handleFOTCommentIconClick}
            >
              <ChatOutlinedIcon className={classes.FOT} />
            </IconButton>
          </>
        )}
      </AppPageTableRowHeader>

      <AppPageTableRowContent>
        <div>
          <div
            role={"button"}
            className={classes.rowLink}
            onClick={handleExamClick}
          >
            <div
              className={clsx(classes.rowContent, {
                [sharedClasses.rowContentRequests]: isRequestsTab,
                [sharedClasses.rowContentExams]: isExamsTab,
                [sharedClasses.rowContentPending]: isPendingTab,
                [sharedClasses.rowContentPassed]: isPassedTab,
                [sharedClasses.rowContentFailed]: isFailedTab
              })}
            >
              {createConfigTable(selectedTab).map(col =>
                tableColumns[col].render(workoutRequest)
              )}
            </div>
          </div>
          {horse.lastCompletedWorkoutExamWithNote && (
            <LastExamNote horse={horse} />
          )}
          {(isRequestsTab || isExamsTab) && (
            <ExamReasonNote
              workoutRequest={workoutRequest}
              readOnly={isExamsTab}
              refetchWorkouts={refetchWorkoutsAndExams}
            />
          )}
          {isRequestsTab && horse && <WorkoutRequestPPs horse={horse} />}
        </div>
      </AppPageTableRowContent>

      {isRequestsTab && renderRequestMenuButtons()}
      {isExamsTab && renderExamMenuButtons()}

      <ErrorBoundary>
        <ConfirmPopUp
          title="CONFIRM UN-ASSIGN?"
          content="The horse exam will be placed back to un-assigned after confirming."
          open={isUnAssignDialogOpen}
          onClose={handleUnAssignDialogClose}
          onConfirm={handleUnAssignDialogConfirm}
          workoutRequest={selectedWorkoutRequest}
        />
      </ErrorBoundary>

      {isVetListsDialogOpen && (
        <ErrorBoundary>
          <VetListsPopUp horse={horse} onClose={handleCloseVetListsPopUp} />
        </ErrorBoundary>
      )}
    </AppPageTableRow>
  );
};

export default Row;
