export default {
  dashboard: {
    path: "/",
    moduleName: "Dashboard"
  },
  conditionBook: {
    path: "/condition-book",
    moduleName: "Condition Book"
  },
  account: {
    path: "/account",
    moduleName: "Account"
  },
  workouts: {
    path: "/workouts",
    moduleName: "Workout Requests"
  },
  workoutRequests: {
    path: "/workouts/requests",
    moduleName: "Workout Requests"
  },
  workoutExams: {
    path: "/workouts/exams",
    moduleName: "Workout Requests"
  },
  workoutPending: {
    path: "/workouts/pending",
    moduleName: "Workout Requests"
  },
  workoutPassed: {
    path: "/workouts/passed",
    moduleName: "Workout Requests"
  },
  workoutFailed: {
    path: "/workouts/failed",
    moduleName: "Workout Requests"
  },
  workoutComments: {
    path: "/workouts/:workoutId/comments",
    moduleName: "Workout Requests"
  },
  calendar: {
    path: "/calendar",
    moduleName: "Calendar"
  },
  horseList: {
    path: "/horses",
    moduleName: "Horse Details"
  },
  horseDetails: {
    path: "/horses/:horseId/details",
    moduleName: "Horse Details"
  },
  horseDetailsActivity: {
    path: "/horses/:horseId/details/activity",
    moduleName: "Horse Details"
  },
  horseDetailsComments: {
    path: "/horses/:horseId/details/comments",
    moduleName: "Horse Details"
  },
  horseWorkouts: {
    path: "/horses/:horseId/workouts",
    moduleName: "Horse Details"
  },
  admin: {
    path: "/admin",
    moduleName: "Admin"
  },
  adminEditUser: {
    path: "/admin/users/:userId/edit",
    moduleName: "Edit User"
  },
  preferredConditions: {
    path: "/preferred-conditions",
    moduleName: "Preferred Conditions"
  },
  manageTracks: {
    path: "/manage-tracks",
    moduleName: "Manage Tracks"
  },
  manageTracksFacility: {
    path: "/manage-tracks/facility",
    moduleName: "Manage Tracks"
  },
  manageTracksTrackConditions: {
    path: "/manage-tracks/facility/track-conditions",
    moduleName: "Manage Tracks"
  },
  manageTracksWorkoutScheduling: {
    path: "/manage-tracks/facility/workout-scheduling",
    moduleName: "Manage Tracks"
  },
  workOrders: {
    path: "/work-orders",
    moduleName: "Work Orders"
  },
  stallApplication: {
    path: "/stall-application",
    moduleName: "Horse Intake"
  },
  stallApplicationNewForm: {
    path: "/stall-application/new",
    moduleName: "Horse Intake"
  },
  stallApplicationFormView: {
    path: "/stall-application/:stallApplicationFormId/view",
    moduleName: "Horse Intake"
  },
  stallApplicationTrainerForm: {
    path: "/stall-application/:stallApplicationFormId/apply",
    moduleName: "Horse Intake"
  },
  inventoryAnalytics: {
    path: "/inventory-analytics",
    moduleName: "Inventory Analytics"
  },
  page404: {
    path: "/404",
    moduleName: "404"
  }
};
