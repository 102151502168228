import { makeStyles } from "@material-ui/core/styles";
import { MuiTheme } from "theme";
import Breakpoints from "common/breakpoints";

export default makeStyles((theme: MuiTheme) => ({
  appRoot: {
    minHeight: "100%",
    display: "flex",
    background: "#f6fbfa"
  },
  hideOnXs: {
    [theme.breakpoints.down("xs")]: {
      display: "none"
    }
  },
  hideOnSm: {
    [theme.breakpoints.down(Breakpoints.SM_600)]: {
      display: "none"
    }
  },
  appContent: {
    height: `calc(100% - ${theme.heightDesktopHeader + 0.5}px)`,
    position: "relative",
    [theme.breakpoints.down("xs")]: {
      height: `calc(100% - ${theme.heightMobileHeader + 0.5}px)`
    }
  },
  deniedAccess: {
    display: "flex",
    justifyContent: "center",
    paddingTop: "20px"
  },
  flashMessage: {
    position: "absolute",
    top: 0,
    left: 0,
    right: 0,
    display: "grid",
    gridTemplateColumns: "212px 1fr 64px",
    alignItems: "center",
    backgroundColor: "#fff",
    boxShadow: "0 0 8px 0 rgba(0, 0, 0, 0.15)",
    height: theme.heightDesktopHeader,
    zIndex: theme.zIndex.app.logo + 2,
    [theme.breakpoints.down(Breakpoints.XS_420)]: {
      height: theme.heightMobileHeader,
      gridTemplateColumns: "160px 1fr 64px"
    }
  },
  flashMessageText: {
    flex: "auto",
    display: "flex",
    justifyContent: "center",
    fontSize: "20px"
  },
  flexWrapper: {
    flex: "auto",
    width: `calc(100% - env(safe-area-inset-left))`,
    [theme.breakpoints.up("sm")]: {
      width: `calc(100% - ${theme.widthTabletDrawer}px - env(safe-area-inset-left))`
    },
    [theme.breakpoints.up("lg")]: {
      width: `calc(100% - ${theme.widthDesktopDrawer}px - env(safe-area-inset-left))`
    }
  },
  flexWrapperFullWidth: {
    width: `calc(100% - env(safe-area-inset-left))`,
    [theme.breakpoints.up("sm")]: {
      width: `calc(100% - env(safe-area-inset-left))`
    },
    [theme.breakpoints.up("lg")]: {
      width: `calc(100% - env(safe-area-inset-left))`
    }
  },
  staticHeader: {
    position: "static"
  },
  headerlessDownSM600: {
    [theme.breakpoints.down(Breakpoints.SM_600)]: {
      height: `100%`
    }
  }
}));
