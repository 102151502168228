import React, { SyntheticEvent } from "react";
import clsx from "clsx";

import { Popover, Radio, RadioGroup } from "@material-ui/core";
import UncheckedIcon from "@material-ui/icons/RadioButtonUnchecked";
import CheckedIcon from "@material-ui/icons/RadioButtonChecked";
import NoneIcon from "@material-ui/icons/NotInterested";

import { EFlagPriority } from "pages/WorkoutsPage/Vet/WorkoutsTable/Row/helper";
import useStyles from "./styles";

interface IProps {
  open?: boolean;
  anchorEl: HTMLElement;
  selectedValue: EFlagPriority;
  onClose: (event: SyntheticEvent) => void;
  onChange: (event: SyntheticEvent) => void;
}

const VERTICAL_SHIFT = 40;

const FlagRequestPriorityPopover = (props: IProps) => {
  const { onClose, anchorEl, open = true, selectedValue, onChange } = props;
  const classes = useStyles();

  const handleClickPopover = event => {
    event.stopPropagation();
  };

  const renderRadioButtons = () => {
    return Object.values(EFlagPriority).map(value => {
      const isSelected = value === selectedValue;

      if (value === EFlagPriority.NONE && isSelected) {
        return;
      }

      return (
        <Radio
          key={value}
          className={clsx(classes.icon, classes[value], {
            selected: isSelected
          })}
          value={value}
          name={value}
          size={"medium"}
          icon={isSelected ? <UncheckedIcon /> : <NoneIcon />}
          checkedIcon={isSelected && <CheckedIcon />}
          disabled={isSelected}
        />
      );
    });
  };

  return (
    <Popover
      open={open}
      anchorEl={anchorEl}
      onClose={onClose}
      onClick={handleClickPopover}
      classes={{
        paper: classes.paper
      }}
      anchorOrigin={{
        horizontal: "center",
        vertical: "top"
      }}
      transformOrigin={{
        horizontal: "center",
        vertical: VERTICAL_SHIFT
      }}
    >
      <div className={classes.popoverContent}>
        <RadioGroup value={selectedValue} onChange={onChange} row={true}>
          {renderRadioButtons()}
        </RadioGroup>
      </div>
    </Popover>
  );
};

export default FlagRequestPriorityPopover;
