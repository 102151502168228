import React from "react";
import clsx from "clsx";
import IconButton from "@material-ui/core/IconButton";
import RemoveIcon from "@material-ui/icons/DeleteForever";

import useStyles from "./styles";

interface IProps {
  entryNumber: number;
  onDeleteEntry: () => void;
  disabledRemoveButton?: boolean;
  children: React.ReactNode;
  className?: string;
  title?: string;
}

const EntryItem = (props: IProps) => {
  const {
    entryNumber,
    onDeleteEntry,
    disabledRemoveButton = false,
    children,
    className,
    title
  } = props;
  const classes = useStyles();
  const entryTitle = `${title || "Entry"} ${entryNumber}`;

  return (
    <div className={clsx(classes.entry, className)}>
      <div className={classes.entryHeader}>
        <span className={classes.title}>{entryTitle}</span>
        <IconButton
          className={classes.removeButton}
          onClick={onDeleteEntry}
          disabled={disabledRemoveButton}
        >
          <RemoveIcon />
        </IconButton>
      </div>
      {children}
    </div>
  );
};

export default EntryItem;
