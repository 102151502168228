import React from "react";

import EntryItem from "../../EntryItem";
import {
  IExamJog,
  jogInfoFields,
  clearExamJog,
  deleteExamJog
} from "../../options";
import VetExamFormField from "../../VetExamFormField";
import { FormField } from "../../options";
import { checkSelectingFieldsOneEntry } from "../../helper";
import { useVetExamContext } from "../../VetExamStateProvider/VetExamProvider";
import { useExamLockerContext } from "components/ExamLockerContext/ExamLockerContext";
import useStyles from "components/VetExamOverlay/styles";

interface IProps {
  jog: IExamJog;
  entryNumber: number;
}

const JogInformationEntry = (props: IProps) => {
  const { jog, entryNumber } = props;

  const classes = useStyles();
  const {
    dispatch,
    state: { examJogs }
  } = useVetExamContext();
  const { lockExam } = useExamLockerContext();

  const handleDeleteWorkoutExamJog = async () => {
    const actionCreator =
      examJogs.length === 1 ? clearExamJog() : deleteExamJog(jog.id);

    dispatch(actionCreator);
    lockExam();
  };

  const renderField = (field: FormField) => {
    return <VetExamFormField key={field.key} field={field} entryState={jog} />;
  };

  const isDeleteButtonDisabled =
    entryNumber === 1 && !checkSelectingFieldsOneEntry(jog);

  return (
    <EntryItem
      entryNumber={entryNumber}
      onDeleteEntry={handleDeleteWorkoutExamJog}
      disabledRemoveButton={isDeleteButtonDisabled}
    >
      <div className={classes.fieldsWrapper}>
        {jogInfoFields.map(renderField)}
      </div>
    </EntryItem>
  );
};
export default JogInformationEntry;
