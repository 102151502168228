import React, { useContext, useEffect, useState } from "react";
import parseISO from "date-fns/parseISO";
import differenceInMilliseconds from "date-fns/differenceInMilliseconds";

import { racehorse360 } from "@tsg/1st-grpc-web";
import { useApiClient, useRacehorse360Api } from "hooks/api";

export interface ExamLockerContextModel {
  isLocked: boolean;
  lockExam: () => void;
  unlockExam: () => void;
  pullLocker: () => Promise<{ data: racehorse360.PullPageLockerResponse }>;
}

interface ExamLockerContextProps {
  recordId: string;
  refetchInterval?: number;
  children: React.ReactNode;
  countdown: boolean;
  onExpire?: () => void;
  onLock?: (expirationDate: Date) => void;
  onPullSuccess?: (refetchResult: racehorse360.IPullPageLockerResponse) => void;
}

export const ExamLockerContext = React.createContext<ExamLockerContextModel>({
  isLocked: false,
  lockExam: () => {
    return null;
  },
  unlockExam: () => {
    return null;
  },
  pullLocker: () => {
    return null;
  }
});

export const useExamLockerContext = () => useContext(ExamLockerContext);

export const ExamLockerContextProvider = (props: ExamLockerContextProps) => {
  const {
    children,
    recordId,
    refetchInterval,
    countdown,
    onExpire,
    onLock,
    onPullSuccess
  } = props;
  const { userClient } = useApiClient();

  const { usePullPageLocker, useTriggerPageLocker, useReleasePageLocker } =
    useRacehorse360Api();
  const [expirationTimeout, setExpirationTimeout] =
    useState<ReturnType<typeof setTimeout>>();

  const { mutateAsync: triggerPageLocker } = useTriggerPageLocker();
  const { mutateAsync: releasePageLocker, isLoading: isReleasing } =
    useReleasePageLocker();

  const { data, refetch } = usePullPageLocker(
    {
      recordId,
      lockerPageType:
        racehorse360.LockerPageType.LOCKER_PAGE_TYPE_WORKOUT_EXAM_EDIT_PAGE,
      getOptions: {
        select: [
          "isLocked",
          "pageLocker.lockedById",
          "pageLocker.startDate",
          "pageLocker.expirationDate"
        ]
      }
    },
    {
      initialData: {
        isLocked: false
      },
      enabled: Boolean(recordId),
      refetchInterval,
      onSuccess: result => {
        onPullSuccess?.(result);
      }
    }
  );
  const { isLocked } = data;

  const handleExpiration = () => {
    unlockExam();
  };

  const lockExam = () => {
    clearTimeout(expirationTimeout);
    triggerPageLocker({
      recordId,
      lockerPageType:
        racehorse360.LockerPageType.LOCKER_PAGE_TYPE_WORKOUT_EXAM_EDIT_PAGE
    }).then(result => {
      if (countdown) {
        const expiresAt = parseISO(result.locker.expirationDate);
        const milliseconds = differenceInMilliseconds(expiresAt, new Date());
        onLock(expiresAt);
        clearTimeout(expirationTimeout);
        setExpirationTimeout(
          setTimeout(handleExpiration, milliseconds, expiresAt)
        );
      }
    });
  };

  const unlockExam = () => {
    clearTimeout(expirationTimeout);
    !isReleasing &&
      releasePageLocker({
        recordId,
        lockerPageType:
          racehorse360.LockerPageType.LOCKER_PAGE_TYPE_WORKOUT_EXAM_EDIT_PAGE
      });
  };

  const handleBeforeUnload = () => {
    userClient.releasePageLocker({
      recordId,
      lockerPageType:
        racehorse360.LockerPageType.LOCKER_PAGE_TYPE_WORKOUT_EXAM_EDIT_PAGE
    });
  };

  useEffect(() => {
    if (countdown) {
      window.addEventListener("beforeunload", handleBeforeUnload);
    }

    return () => {
      if (countdown) {
        window.removeEventListener("beforeunload", handleBeforeUnload);
      }
    };
  }, []);

  return (
    <ExamLockerContext.Provider
      value={{
        isLocked,
        lockExam,
        unlockExam,
        pullLocker: refetch
      }}
    >
      {children}
    </ExamLockerContext.Provider>
  );
};

export default ExamLockerContextProvider;
