import React, { useCallback, useEffect, useRef, useState } from "react";
import { useHistory } from "react-router-dom";
import { useDispatch } from "react-redux";
import { useQueryClient } from "react-query";

import { racehorse360 } from "@tsg/1st-grpc-web";

import routes from "common/routes";
import AppPage from "components/AppPage";
import AppPageContent from "components/AppPageContent";
import AppPageHeader from "components/AppPageHeader";
import ConfirmAssignmentDialog from "components/ConfirmAssignmentDialog";
import ErrorBoundary from "components/ErrorBoundary";
import { useLoggedInUser } from "components/LoggedInUserProvider";
import { useSnackbar } from "components/SnackbarContext/SnackbarContext";
import TabsComponent from "components/Tabs";
import { useRacehorse360Api } from "hooks/api";
import { useInfiniteScroll } from "hooks/useInfiniteScroll";
import { DateRangeISO, DateRangeType } from "interfaces/DateRange";
import { tabs, VetWorkoutsTab } from "interfaces/VetWorkoutsTab";

import {
  setSelectedDateRange as setStoreSelectedDateRange,
  setSelectedTab as setStoreSelectedTab,
  setExamCheckedIds,
  setPassCheckedIds,
  setAssignCheckedIds,
  setSavingCheckedIds,
  setSearchQuery
} from "store/actions/vetWorkoutsPage";
import {
  EXAMS_PER_PAGE,
  listWorkoutExamsSelectedFields,
  listWorkoutRequestCommentSelectedFields,
  listWorkoutRequestsSelectedFields,
  REQUESTS_PER_PAGE,
  columnSettings
} from "./WorkoutsTable/options";
import {
  checkCurrentWorkoutsTableTab,
  getDateRangeQuery,
  getOrderByOptions,
  getOrderRules,
  mapExamsToRequests
} from "./helper";
import Header from "./Header";
import WorkoutsTable from "./WorkoutsTable";
import ConfirmSelectionsDialog from "./ConfirmSelectionsDialog";
import { useVetWorkoutsStore } from "./useVetWorkoutsStore";
import VetWorkoutPopover from "./VetWorkoutPopover";
import useStyles from "./styles";

interface CommonFilter {
  facilityIds?: string[];
  trainerIds?: string[];
  horseIds?: string[];
  assignedVetIds?: string[];
  horseOrTrainerName?: racehorse360.IStringMatch;
}

const VetWorkoutsPage = () => {
  const classes = useStyles();
  const queryClient = useQueryClient();
  const dispatch = useDispatch();
  const history = useHistory();
  const tableBodyRef = useRef<HTMLDivElement>(null);
  const { currentUser } = useLoggedInUser();
  const { showSuccessSnack, showErrorSnack } = useSnackbar();
  const {
    assignedOnly,
    selectedFacility,
    selectedTab,
    order,
    orderBy,
    searchValue,
    assignCheckedIds,
    savingCheckedIds,
    examCheckedIds,
    passCheckedIds,
    convertedToDateSelectedDateRange
  } = useVetWorkoutsStore();

  const [clearSearch, setClearSearch] = useState<boolean>(false);
  const [requestsTotalResults, setRequestsTotalResults] = useState(0);

  const { isRequestsTab, isPendingTab } = checkCurrentWorkoutsTableTab(
    selectedTab.value
  );

  const [isAssignToVetDialogOpen, setIsAssignToVetDialogOpen] =
    useState<boolean>(false);
  const [isOpenConfirmSelectionsPopup, setIsOpenConfirmSelectionsPopup] =
    useState<boolean>(false);

  const {
    useBulkQuickPassWorkoutRequest,
    useBulkRequireWorkoutExam,
    useBulkAssignWorkoutExam,
    useListUsers,
    useInfiniteListWorkoutExams,
    useInfiniteListWorkoutRequests,
    useListWorkoutRequestComment,
    useListHorses
  } = useRacehorse360Api();

  const { mutateAsync: assignWorkoutExam } = useBulkAssignWorkoutExam();
  const { mutateAsync: quickPassExams } = useBulkQuickPassWorkoutRequest();
  const { mutateAsync: requireWorkoutExams } = useBulkRequireWorkoutExam();

  const possibleVetRoutes = [
    routes.workoutRequests.path,
    routes.workoutExams.path,
    routes.workoutPending.path,
    routes.workoutPassed.path,
    routes.workoutFailed.path
  ];

  useEffect(() => {
    if (!possibleVetRoutes.includes(history.location.pathname)) {
      history.push(possibleVetRoutes[0]);
    }
  }, [history.location.pathname]);

  const activeColumn = columnSettings.find(item =>
    item.columnNames.includes(orderBy)
  );

  const commonQuery: CommonFilter = {
    facilityIds: [selectedFacility?.id],
    assignedVetIds: assignedOnly && !isRequestsTab ? [currentUser.rh360Id] : [],
    horseOrTrainerName: searchValue?.length
      ? { contains: searchValue }
      : undefined
  };

  const workoutRequestsQuery: racehorse360.IWorkoutRequestFilter = {
    statuses: selectedTab.requestStatuses,
    date: getDateRangeQuery(convertedToDateSelectedDateRange),
    facilityIds: [selectedFacility?.id]
  };

  const workoutExamsQuery: racehorse360.IWorkoutExamFilter = {
    statuses: selectedTab.examStatuses,
    facilityIds: [selectedFacility?.id],
    workoutRequestDate: getDateRangeQuery(convertedToDateSelectedDateRange),
    workoutExamTypes:
      !isRequestsTab && !isPendingTab
        ? [
            racehorse360.WorkoutExamType.WORKOUT_EXAM_TYPE_QUICK_PASS,
            racehorse360.WorkoutExamType.WORKOUT_EXAM_TYPE_FULL_EXAM
          ]
        : [],
    results: selectedTab?.results,
    withWorkout: { value: true }
  };

  const requestsOrderBy = getOrderByOptions(
    activeColumn?.requestsOrderBy,
    orderBy
  );

  const {
    isLoading: isWorkoutRequestsLoading,
    isFetching: isWorkoutRequestsFetching,
    data: responseData,
    fetchNextPage: fetchNextRequestsPage,
    isFetchingNextPage: isFetchingNextRequestsPage,
    hasNextPage: hasNextRequestsPage
  } = useInfiniteListWorkoutRequests(
    `${currentUser.rh360Id}-${searchValue}-infinite-workout-requests`,
    {
      query: {
        ...commonQuery,
        ...workoutRequestsQuery
      },
      pagingOptions: {
        maxResults: REQUESTS_PER_PAGE,
        includeSummary: true
      },
      getOptions: {
        select: listWorkoutRequestsSelectedFields,
        orderBy:
          requestsOrderBy &&
          getOrderRules(selectedTab.value, requestsOrderBy, order)
      }
    },
    {
      enabled: isRequestsTab && Boolean(selectedFacility?.id),
      onSuccess: responseData => {
        setRequestsTotalResults(responseData?.["totalResults"]);
      }
    }
  );

  const workoutRequestsData = responseData?.pages?.flat() || [];

  const getTabsWithTotalResults = () => {
    if (isRequestsTab) {
      const newName = `Requests (${requestsTotalResults})`;
      return tabs.map(tab =>
        tab.name === "Requests" ? { ...tab, name: newName } : tab
      );
    } else {
      return tabs;
    }
  };

  useInfiniteScroll(
    tableBodyRef,
    fetchNextRequestsPage,
    hasNextRequestsPage,
    isFetchingNextRequestsPage,
    isRequestsTab,
    true
  );

  const commentedWorkoutRequests = workoutRequestsData.length
    ? workoutRequestsData.filter(workoutRequest => workoutRequest.hasComment)
    : [];

  const workoutRequestIds = commentedWorkoutRequests.map(request => request.id);

  const { data: listWorkoutRequestCommentsData } = useListWorkoutRequestComment(
    {
      query: {
        workoutRequestIds: workoutRequestIds,
        commentTypes: [
          racehorse360.WorkoutRequestCommentType
            .WORKOUT_REQUEST_COMMENT_TYPE_TRAINER_COMMUNICATION,
          racehorse360.WorkoutRequestCommentType
            .WORKOUT_REQUEST_COMMENT_TYPE_RACING_SECRETARY_COMMUNICATION
        ]
      },
      pagingOptions: {
        maxResults: 20
      },
      getOptions: {
        select: listWorkoutRequestCommentSelectedFields
      }
    },
    {
      enabled: !!commentedWorkoutRequests.length
    }
  );

  const mapToCommentsList = workoutRequests => {
    if (!workoutRequests || !listWorkoutRequestCommentsData) {
      return workoutRequests;
    }

    const comments = listWorkoutRequestCommentsData.workoutRequestComments;

    return workoutRequests.map(wr => {
      wr.commentsList = wr.hasComment
        ? comments.filter(comment => comment.workoutRequest.id === wr.id)
        : [];
      return wr;
    });
  };

  const examsOrderBy = getOrderByOptions(activeColumn?.examsOrderBy, orderBy);

  const {
    isLoading: isExamsLoading,
    isFetching: isExamsFetching,
    data: responseExamsData,
    fetchNextPage: fetchNextExamsPage,
    isFetchingNextPage: isFetchingNextExamsPage,
    hasNextPage: hasNextExamsPage
  } = useInfiniteListWorkoutExams(
    `${currentUser.rh360Id}-${searchValue}-infinite-exams`,
    {
      query: {
        ...commonQuery,
        ...workoutExamsQuery
      },
      pagingOptions: {
        maxResults: EXAMS_PER_PAGE,
        includeSummary: true
      },
      getOptions: {
        select: listWorkoutExamsSelectedFields,
        orderBy: getOrderRules(selectedTab.value, examsOrderBy, order)
      }
    },
    {
      enabled: !isRequestsTab && Boolean(selectedFacility?.id)
    }
  );

  const vetExams: racehorse360.IWorkoutExam[] =
    responseExamsData?.pages?.flat() || [];

  useInfiniteScroll(
    tableBodyRef,
    fetchNextExamsPage,
    hasNextExamsPage,
    isFetchingNextExamsPage,
    !isRequestsTab,
    true
  );

  const workoutRequests: racehorse360.IWorkoutRequest[] = isRequestsTab
    ? mapToCommentsList(workoutRequestsData)
    : mapExamsToRequests(vetExams);

  const { data: listHorsesData } = useListHorses(
    {
      query: {
        name: searchValue?.length ? { contains: searchValue } : null
      }
    },
    {
      enabled: [Boolean(searchValue?.length), !workoutRequests.length].every(
        Boolean
      )
    }
  );

  const existingHorses = listHorsesData?.horses || [];

  const { data: veterinariansData, isLoading: isVeterinariansLoading } =
    useListUsers(
      `${selectedFacility?.id}_veterinarians`,
      {
        query: {
          facilityIds: [selectedFacility?.id],
          roleGroup: racehorse360.RoleGroup.ROLE_GROUP_VETERINARIANS
        },
        pagingOptions: {
          maxResults: 99,
          offset: 0
        },
        getOptions: {
          select: ["firstName", "lastName"]
        }
      },
      {
        enabled: Boolean(assignCheckedIds.length)
      }
    );
  const veterinarians: racehorse360.IUser[] = veterinariansData?.users || [];

  const handleTabChange = useCallback(
    (tab: VetWorkoutsTab) => {
      if (!convertedToDateSelectedDateRange) {
        const date: DateRangeISO = {
          Type: DateRangeType.Next3Days
        };

        dispatch(setStoreSelectedDateRange(date));
      }

      dispatch(setStoreSelectedTab(tab));
      window.scrollTo(0, 0);
    },
    [dispatch]
  );

  const resetRequestsCache = () => {
    return queryClient.invalidateQueries([
      `${currentUser.rh360Id}-${searchValue}-infinite-workout-requests`
    ]);
  };

  const resetExamsCache = () => {
    return queryClient.invalidateQueries([
      `${currentUser.rh360Id}-${searchValue}-infinite-exams`
    ]);
  };

  const saveRequestsSelection = () => {
    const promises = [];

    if (passCheckedIds.length) {
      promises.push(quickPassExams({ ids: passCheckedIds }));
    }

    if (examCheckedIds.length) {
      promises.push(requireWorkoutExams({ ids: examCheckedIds }));
    }

    const checkedIds = [...passCheckedIds, ...examCheckedIds];

    dispatch(setSavingCheckedIds([...savingCheckedIds, ...checkedIds]));

    const clearSavingCheckedIds = () => {
      dispatch(
        setSavingCheckedIds(
          savingCheckedIds.filter(savingCheckedId => {
            return checkedIds.includes(savingCheckedId);
          })
        )
      );
    };

    Promise.all(promises)
      .then(() => {
        dispatch(setExamCheckedIds([]));
        dispatch(setPassCheckedIds([]));
        clearSavingCheckedIds();
        return resetRequestsCache();
      })
      .catch(error => {
        console.error("ERROR", error);
        clearSavingCheckedIds();
      });
  };

  const saveExamsSelection = (vetId: string, assignCheckedIds: string[]) => {
    dispatch(setSavingCheckedIds([...savingCheckedIds, ...assignCheckedIds]));

    const clearSavingCheckedIds = () => {
      dispatch(
        setSavingCheckedIds(
          savingCheckedIds.filter(savingCheckedId => {
            return assignCheckedIds.includes(savingCheckedId);
          })
        )
      );
    };

    assignWorkoutExam({
      ids: assignCheckedIds,
      assignedVetId: vetId
    })
      .then(() => {
        showSuccessSnack("Vet Assigned");
        dispatch(setAssignCheckedIds([]));
        clearSavingCheckedIds();
        return resetExamsCache();
      })
      .catch(error => {
        showErrorSnack(error.message);
        console.error(error);
        clearSavingCheckedIds();
      });
  };

  const openAssignToVetDialog = () => {
    setIsAssignToVetDialogOpen(true);
  };

  const handleAssignmentDialogConfirm = (vetId: string) => {
    setIsAssignToVetDialogOpen(false);
    saveExamsSelection(vetId, assignCheckedIds);
  };

  const handleAssignmentDialogClose = () => {
    setIsAssignToVetDialogOpen(false);
  };

  const handleClearSearch = (value: boolean) => {
    dispatch(setSearchQuery(""));
    setClearSearch(value);
  };

  const handleOpenConfirmSelectionsDialog = () => {
    setIsOpenConfirmSelectionsPopup(true);
  };

  const handleCloseConfirmSelectionsDialog = () => {
    setIsOpenConfirmSelectionsPopup(false);
  };

  const isLoading = [isWorkoutRequestsLoading, isExamsLoading].some(Boolean);
  const shouldHideTabs = [
    searchValue,
    !workoutRequests.length,
    !existingHorses.length
  ].every(Boolean);
  const isFetching = [isWorkoutRequestsFetching, isExamsFetching].some(Boolean);
  const isFetchingNextPage = [
    isFetchingNextRequestsPage,
    isFetchingNextExamsPage
  ].some(Boolean);

  return (
    <AppPage className={classes.appPage}>
      <AppPageHeader className={classes.appPageHeader}>
        <Header
          workoutRequestsQuery={workoutRequestsQuery}
          workoutExamsQuery={workoutExamsQuery}
          openAssignToVetDialog={openAssignToVetDialog}
          openConfirmSelectionsDialog={handleOpenConfirmSelectionsDialog}
          clearSearch={clearSearch}
          onClearSearch={handleClearSearch}
        />
        {!shouldHideTabs && (
          <TabsComponent
            className={classes.tabs}
            tabs={getTabsWithTotalResults()}
            selected={selectedTab}
            onChange={handleTabChange}
          />
        )}
      </AppPageHeader>

      <AppPageContent>
        <WorkoutsTable
          workoutRequests={workoutRequests}
          isLoading={isLoading}
          isFetching={isFetching}
          isFetchingNextPage={isFetchingNextPage}
          tableBodyRef={tableBodyRef}
          searchValue={searchValue}
          hasDesiredHorse={Boolean(existingHorses.length)}
          onClearSearch={handleClearSearch}
        />
      </AppPageContent>

      <VetWorkoutPopover />

      <ErrorBoundary>
        <ConfirmAssignmentDialog
          open={isAssignToVetDialogOpen}
          isLoading={isVeterinariansLoading}
          veterinarians={veterinarians}
          assignCheckedIds={assignCheckedIds}
          onClose={handleAssignmentDialogClose}
          onConfirm={handleAssignmentDialogConfirm}
        />
      </ErrorBoundary>

      {isOpenConfirmSelectionsPopup && (
        <ErrorBoundary>
          <ConfirmSelectionsDialog
            open={true}
            onClose={handleCloseConfirmSelectionsDialog}
            onSaveRequestsSelection={saveRequestsSelection}
            passCheckedIds={passCheckedIds}
            examCheckedIds={examCheckedIds}
            workoutRequestQuery={{
              ...commonQuery,
              ...workoutRequestsQuery
            }}
          />
        </ErrorBoundary>
      )}
    </AppPage>
  );
};

export default React.memo(VetWorkoutsPage);
