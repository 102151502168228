import * as Yup from "yup";
import { StringSchema } from "yup";
import {
  CITY_REGEXP,
  ONLY_NUMBERS_REGEXP,
  STREET_ADDRESS_REGEXP
} from "common/regExp";
import { FormFiledName } from "./constants";

export const STABLE_ENTRIES_ERROR = "Please add at least 1 stabling entry";
const CONTACT_FULL_NAME = "Please enter your full name";
const CONTACT_PHONE_ERROR = "Please enter a valid number";
const DATE_ERROR = "Please select a valid date";
const CONTACT_EMAIL_ERROR = "Please enter a valid email address";
const CONTACT_STREET_ADDRESS_ERROR = "Please enter a valid street address";
const CONTACT_CITY_ERROR = "Please enter a valid city";
const ZIPCODE_ERROR = "Please enter a valid ZIP Code";
const PREFERENCES_ERROR = "Please select an option";
const STATE_ERROR = "Please select a state";
const STABLE_PLANNED_TO_SPLIT_ERROR = "Please select an option";

const validateRules = (
  result: StringSchema,
  isRequired: boolean,
  errorMessage: string
) => (isRequired ? result.required(errorMessage) : result);

const setNameValidationRules = (errorMessage: string, isRequired = false) => {
  const result = Yup.string()
    .trim()
    .min(1, errorMessage)
    .max(200, errorMessage);

  return validateRules(result, isRequired, errorMessage);
};

const addressValidationRules = (isRequired = false) => {
  const result = Yup.string()
    .trim()
    .min(1, CONTACT_STREET_ADDRESS_ERROR)
    .max(255, CONTACT_STREET_ADDRESS_ERROR)
    .matches(STREET_ADDRESS_REGEXP, CONTACT_STREET_ADDRESS_ERROR);

  return validateRules(result, isRequired, CONTACT_STREET_ADDRESS_ERROR);
};

const cityValidationRules = (isRequired = false) => {
  const result = Yup.string()
    .trim()
    .min(1, CONTACT_CITY_ERROR)
    .max(128, CONTACT_CITY_ERROR)
    .matches(CITY_REGEXP, CONTACT_CITY_ERROR);

  return validateRules(result, isRequired, CONTACT_CITY_ERROR);
};

const zipcodeValidationRules = (isRequired = false) => {
  const result = Yup.string()
    .min(1, ZIPCODE_ERROR)
    .max(32, ZIPCODE_ERROR)
    .matches(ONLY_NUMBERS_REGEXP, ZIPCODE_ERROR);

  return validateRules(result, isRequired, ZIPCODE_ERROR);
};

export default Yup.object().shape({
  [FormFiledName.ARRIVAL_DATE]: Yup.date().required().typeError(DATE_ERROR),
  [FormFiledName.SLEEPING_ROOMS_NUMBER]: Yup.number()
    .min(0)
    .required()
    .default(0),
  [FormFiledName.CONTACT_FULL_NAME]: setNameValidationRules(
    CONTACT_FULL_NAME,
    true
  ),
  [FormFiledName.CONTACT_PHONE]: Yup.string()
    .trim()
    .min(18, CONTACT_PHONE_ERROR)
    .max(18, CONTACT_PHONE_ERROR)
    .required(CONTACT_PHONE_ERROR),
  [FormFiledName.CONTACT_EMAIL]: Yup.string()
    .email(CONTACT_EMAIL_ERROR)
    .required(CONTACT_EMAIL_ERROR),
  [FormFiledName.CONTACT_STREET_ADDRESS]: addressValidationRules(true),
  [FormFiledName.CONTACT_STREET_ADDRESS_OPTIONAL]: Yup.string().trim().max(255),
  [FormFiledName.CONTACT_CITY]: cityValidationRules(true),
  [FormFiledName.CONTACT_STATE]: Yup.string().required(STATE_ERROR),
  [FormFiledName.CONTACT_ZIP_CODE]: zipcodeValidationRules(true),
  [FormFiledName.PREFERENCES_FIRST]: Yup.string().required(PREFERENCES_ERROR),
  [FormFiledName.PREFERENCES_SECOND]: Yup.string().required(PREFERENCES_ERROR),
  [FormFiledName.IS_STABLE_PLANNED_TO_SPLIT]: Yup.boolean().required(
    STABLE_PLANNED_TO_SPLIT_ERROR
  ),
  [FormFiledName.COMMENT]: Yup.string().trim()
});
