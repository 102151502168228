import { makeStyles, Theme } from "@material-ui/core/styles";
import { MuiTheme } from "theme";
import Breakpoints from "common/breakpoints";

interface StylesProps {
  riskFactorColumnWidth: number;
}

const getRequestsTabTemplateColumns = ({
  riskFactorColumnWidth
}: StylesProps): string => {
  const riskFactorsWidth = riskFactorColumnWidth
    ? `${riskFactorColumnWidth}px`
    : "auto";
  // Horse+Trainer, History, Date, Risk Level, Barn, Age+Sex, HSF, DLW, DLR, LastExam, Flag Request, Vet Lists, Risk Factors
  return `180px 80px 80px 100px 80px 100px 80px 80px 80px 100px 170px 100px ${riskFactorsWidth} auto`;
};

const getExamTabTemplateColumns = ({
  riskFactorColumnWidth
}: StylesProps): string => {
  const riskFactorsWidth = riskFactorColumnWidth
    ? `${riskFactorColumnWidth}px`
    : "auto";
  // Horse+Trainer, History, Date, Risk Level, Flag Request, Barn, Age+Sex, HSF, DLW, DLR, LastExam, Assigned Vet, Un-Assign, Exam, Vet Lists, Risk Factors
  return `180px 80px 80px 100px 128px 80px 100px 80px 80px 80px 100px 180px 80px 140px 120px 120px ${riskFactorsWidth}`;
};

export default makeStyles<Theme, StylesProps | null>((theme: MuiTheme) => ({
  appPageTable: {
    position: "relative"
  },
  appPageTableHeader: {
    top: 202,
    [theme.breakpoints.up("sm")]: {
      borderLeft: "1px solid #eeeeee"
    },
    [theme.breakpoints.down("xs")]: {
      top: 194
    },
    [theme.breakpoints.down(Breakpoints.SS_320)]: {
      top: 238
    }
  },
  appPageTableContent: {
    display: "flex",
    flexDirection: "column"
  },
  rowContentRequests: {
    gridTemplateColumns: getRequestsTabTemplateColumns,
    "& $appPageTableContent:hover::-webkit-scrollbar": {
      backgroundColor: "rgba(252, 249, 242, 1.0)"
    }
  },
  rowContentExams: {
    gridTemplateColumns: getExamTabTemplateColumns
  },
  rowContentPending: {
    // Horse+Trainer, Date, Risk, Barn, Reason, Age+Sex, HSF, DLW, DLR, LastExam
    gridTemplateColumns:
      "180px 80px 100px 80px 140px 100px 80px 80px 80px 100px auto"
  },
  rowContentPassed: {
    // Horse+Trainer, Date, Risk, Barn, ExamType, AssignedVet, Age+Sex, HSF, DLW, DLR, LastExam
    gridTemplateColumns:
      "180px 80px 100px 80px 140px 180px 100px 80px 80px 80px 100px auto"
  },
  rowContentFailed: {
    // Horse+Trainer, Date, Risk, Barn, AssignedVet, Age+Sex, HSF, DLW, DLR, LastExam
    gridTemplateColumns:
      "180px 80px 100px 80px 180px 100px 80px 80px 80px 100px auto"
  },
  lastOneColumnCell: {
    minWidth: 116,
    [theme.breakpoints.down(Breakpoints.XS_420)]: {
      minWidth: 60
    }
  },
  lastTwoColumnsCell: {
    minWidth: 168,
    [theme.breakpoints.down(Breakpoints.XS_420)]: {
      minWidth: 120
    }
  },
  noWorkouts: {
    marginTop: "16px",
    textAlign: "center",
    backgroundColor: "initial",
    width: "100%"
  },
  infiniteLoader: {
    position: "absolute",
    width: 90,
    height: 80,
    margin: "0 auto",
    left: "calc(50% - 40px)"
  },
  showInfiniteLoader: {
    bottom: 20
  },
  showOverlayLoader: {
    position: "absolute",
    display: "flex",
    maxWidth: "100%",
    width: "100%",
    height: "calc(100vh - 210px)",
    margin: 0,
    left: 0,
    top: 0,
    right: 0,
    bottom: 0,
    paddingTop: "40px",
    backgroundColor: "transparent"
  },
  greyColor: {
    color: "#BABAC0"
  },
  scrollContainer: {
    position: "relative",
    overflowX: "auto",
    overflowY: "scroll",
    willChange: "transform",
    direction: "ltr",
    "&::-webkit-scrollbar": {
      width: 0
    }
  }
}));
