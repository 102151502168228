import React, { useCallback, useEffect, useRef, useState } from "react";
import { useHistory } from "react-router-dom";
import { useQueryClient } from "react-query";
import { useDispatch, useSelector } from "react-redux";
import addDays from "date-fns/addDays";
import startOfToday from "date-fns/startOfToday";
import format from "date-fns/format";
import formatISO from "date-fns/formatISO";
import AutoSizer from "react-virtualized-auto-sizer";

import Typography from "@material-ui/core/Typography";
import useTheme from "@material-ui/core/styles/useTheme";
import useMediaQuery from "@material-ui/core/useMediaQuery";

import { racehorse360 } from "@tsg/1st-grpc-web";

import AppPage from "components/AppPage";
import AppPageHeader from "components/AppPageHeader";
import AppPageContent from "components/AppPageContent";
import AppPageTableContent from "components/AppPageTableContent";
import AppPageTable from "components/AppPageTable";
import ErrorBoundary from "components/ErrorBoundary";
import Loader from "components/Loader";
import { useLoggedInUser } from "components/LoggedInUserProvider";
import ScrollSync from "components/ScrollSync";
import SearchNoResults from "components/SearchNoResults";
import VirtualList from "components/VirtualList";
import WorkoutsPageHeader from "components/WorkoutHeader";
import { useRacehorse360Api } from "hooks/api";
import { useInfiniteScroll } from "hooks/useInfiniteScroll";
import WorkoutsPageState from "interfaces/WorkoutsPageState";
import { matchRoute } from "utils/routes";
import { getNextDates } from "utils/date-utils";
import {
  getCalendarDay,
  getFacilityBlackoutsCalendar
} from "utils/facilityBlackouts";
import Breakpoints from "common/breakpoints";
import routes from "common/routes";
import {
  setSelectedFacility as setStoreSelectedFacility,
  setHorseName,
  setSelectedHorseId
} from "store/actions/workoutsPage";

import HorseWorkoutSchedule from "./HorseWorkoutSchedule";
import { SCHEDULING_ROW_HEIGHT } from "./HorseWorkoutSchedule/styles";
import CondensedView from "./CondensedView";
import CondensedViewHeader from "./CondensedViewHeader";
import DateMenu from "./DateMenu";
import {
  isWorkoutRequest,
  WorkoutRequestOrPlaceHolder
} from "utils/workoutRequest";
import { useMergeState } from "hooks/state";
import { SortOrder } from "interfaces/SortOrder";
import useStyles from "./styles";

const PAGE_SIZE: number = 20;
const HORSE_DAYS_COUNT: number = 35;
const startDate: Date = startOfToday();
const startDateText: string = format(startDate, "MMMM d");
const endDate: Date = addDays(startDate, HORSE_DAYS_COUNT - 1);
const endDateText: string = format(endDate, "MMMM d");
const calendar = getNextDates(startDate, HORSE_DAYS_COUNT).map(getCalendarDay);

const getHorseUpdatingWorkoutRequests = (
  updatingWorkoutRequests,
  horseId
): Record<string, boolean> => {
  return Object.keys(updatingWorkoutRequests).reduce((acc, cur) => {
    if (cur.startsWith(horseId)) {
      acc[cur] = updatingWorkoutRequests[cur];
    }
    return acc;
  }, {});
};

const WorkoutsPage = React.memo(() => {
  const { currentUser } = useLoggedInUser();
  const classes = useStyles();
  const [dateMenuAnchor, setDateMenuAnchor] = useState<HTMLDivElement>(null);
  const [isDateMenuOpen, setIsDateMenuOpen] = useState<boolean>(false);
  const [activeWorkoutRequest, setActiveWorkoutRequest] =
    useState<racehorse360.IWorkoutRequest>();
  const [updatingWorkoutRequests, setUpdatingWorkoutRequests] = useMergeState<
    Record<string, boolean>
  >({});

  const dispatch = useDispatch();
  const history = useHistory();
  const {
    shouldScrollSync,
    selectedFacility,
    isCondensedView,
    scheduledDatesOnly,
    searchValue
  } = useSelector(
    (state: { workoutsPage: WorkoutsPageState }) => state?.workoutsPage
  );
  const theme = useTheme();

  const queryClient = useQueryClient();

  const [clearSearch, setClearSearch] = useState<boolean>(false);

  const matchesUpMd = useMediaQuery(theme.breakpoints.up("md"));
  const matchesDownSS320 = useMediaQuery(
    theme.breakpoints.down(Breakpoints.SS_320)
  );
  const shouldShowCondensedView = matchesUpMd && isCondensedView;
  const tableBodyRef = useRef<HTMLDivElement>(null);

  const listBlackoutDatesKey = `blackout-${selectedFacility?.id}`;

  const {
    usePullFacilityBlackouts,
    useInfiniteListHorses,
    useListHorseOnLists,
    useCreateWorkoutRequest
  } = useRacehorse360Api();

  const { mutateAsync: createWorkoutRequest } = useCreateWorkoutRequest();

  const pagingOptions = {
    maxResults: PAGE_SIZE,
    includeSummary: true
  };

  const query = currentUser.isTrainer
    ? {
        query: {
          name: searchValue?.length ? { contains: searchValue } : undefined,
          ...currentUser.trainerHorseAndWorkoutFilter
        },
        getOptions: {
          select: ["name"],
          orderBy: ["name"]
        },
        pagingOptions: pagingOptions
      }
    : {
        query: {
          horseOrTrainerName: searchValue?.length
            ? { contains: searchValue }
            : undefined,
          ...currentUser.trainerHorseAndWorkoutFilter
        },
        getOptions: {
          select: ["name"],
          orderBy: ["name"]
        },
        pagingOptions: pagingOptions
      };

  const { data, isLoading, isFetchingNextPage, hasNextPage, fetchNextPage } =
    useInfiniteListHorses(query, {
      keepPreviousData: true,
      staleTime: 5000
    });

  const trainerId = currentUser.trainerIds[0];

  const horsesOnListQuery: racehorse360.IHorseOnListFilter = {
    activeOnly: { value: true }
  };

  if (currentUser.isRacingOfficial) {
    horsesOnListQuery.horseFacilityIds = currentUser.racingOfficialFacilityIds;
  }
  if (currentUser.isTrainer) {
    horsesOnListQuery.trainerId = { value: trainerId };
  }

  const { data: horseOnListsData, isLoading: isHorsesOnListLoading } =
    useListHorseOnLists(`${trainerId}-horse-on-lists`, {
      query: horsesOnListQuery,
      pagingOptions: {
        maxResults: 9999,
        offset: 0
      },
      getOptions: {
        select: ["horse.id", "horse.name", "list_type", "date_on"],
        orderBy: [`dateOn ${SortOrder.ASC}`]
      }
    });

  const horseOnLists = horseOnListsData?.horseOnLists;
  const horses: racehorse360.IHorse[] = data?.pages ? data.pages.flat() : [];

  const {
    isLoading: isFacilityBlackoutsLoading,
    data: facilityBlackoutsData = { facilityBlackouts: [] }
  } = usePullFacilityBlackouts(listBlackoutDatesKey, {
    facilityId: selectedFacility?.id,
    startDate: formatISO(addDays(startDate, -30), {
      representation: "date"
    }),
    endDate: formatISO(addDays(endDate, 30), {
      representation: "date"
    })
  });

  const facilityBlackoutsCalendar = getFacilityBlackoutsCalendar(
    facilityBlackoutsData.facilityBlackouts,
    calendar
  );

  const handleWorkoutUpdateError = () => {
    queryClient.invalidateQueries(listBlackoutDatesKey).catch(error => {
      console.error(error);
    });
  };

  const handleDateClick = (
    horseId: string,
    workoutRequest: WorkoutRequestOrPlaceHolder,
    anchor: HTMLDivElement
  ) => {
    if (isWorkoutRequest(workoutRequest)) {
      setActiveWorkoutRequest(workoutRequest);
      setDateMenuAnchor(anchor);
      setIsDateMenuOpen(true);
    } else {
      handleDateUpdateStart(horseId, workoutRequest.date);
      createWorkoutRequest({
        date: workoutRequest.date,
        facilityId: selectedFacility.id,
        horseId: horseId,
        getOptions: {
          select: [
            "id",
            "facility.name",
            "facility.code",
            "date",
            "status",
            "hasComment"
          ]
        }
      })
        .then(() => {
          handleDateUpdate(horseId, workoutRequest.date);
        })
        .catch(error => {
          console.error(error);
        })
        .finally(() => {
          setUpdatingWorkoutRequests({
            [`${horseId}-${workoutRequest.date}`]: false
          });
        });
    }
  };

  const handleFacilityChange = (facility: racehorse360.IFacility) => {
    dispatch(setStoreSelectedFacility(facility));
  };

  const handleResetSearch = useCallback(() => {
    dispatch(setHorseName(""));
    dispatch(setSelectedHorseId(""));
    setClearSearch(true);
  }, [dispatch, searchValue]);

  const handleDateUpdateStart = (horseId: string, date: string) => {
    setUpdatingWorkoutRequests({
      [`${horseId}-${date}`]: true
    });
  };

  const handleDateUpdate = (horseId: string, date: string) => {
    queryClient
      .refetchQueries([`${horseId}-list-workout-requests`])
      .catch(error => {
        console.error(error);
      })
      .finally(() => {
        setUpdatingWorkoutRequests({ [`${horseId}-${date}`]: false });
      });
  };

  const handleDateMenuClose = () => {
    setIsDateMenuOpen(false);
  };

  const renderListHeader = () => (
    <div className={classes.schedulingInfo}>
      <Typography color={"textSecondary"}>
        Scheduling at{" "}
        {matchesDownSS320 ? selectedFacility?.code : selectedFacility?.name}
      </Typography>
      <Typography
        className={classes.schedulingDates}
        color={"textSecondary"}
        align={"right"}
      >
        {startDateText} - {endDateText}
      </Typography>
    </div>
  );

  useInfiniteScroll(
    tableBodyRef,
    fetchNextPage,
    hasNextPage,
    isFetchingNextPage,
    true,
    true
  );

  useEffect(() => {
    if (
      !matchRoute(history.location.pathname, [
        routes.workoutComments,
        routes.workouts
      ])
    ) {
      history.push(routes.workouts.path);
    }
  }, [history.location.pathname]);

  const renderCondensedView = () => {
    return (
      <ScrollSync enabled={!scheduledDatesOnly}>
        <ErrorBoundary>
          <AppPageHeader className={classes.appPageHeader}>
            <WorkoutsPageHeader
              selectedFacility={selectedFacility}
              onFacilityChange={handleFacilityChange}
              clearSearch={clearSearch}
              onClearSearch={setClearSearch}
            >
              <CondensedViewHeader />
            </WorkoutsPageHeader>
          </AppPageHeader>
        </ErrorBoundary>

        <AppPageContent>
          <AppPageTable
            role="table"
            aria-labelledby="tableTitle"
            aria-label="enhanced table"
          >
            <AppPageTableContent
              className={classes.appPageTableContentCondensed}
            >
              {horses?.length ? (
                <CondensedView
                  facilityBlackoutsCalendar={facilityBlackoutsCalendar}
                />
              ) : (
                <>
                  <SearchNoResults
                    value={searchValue}
                    onClick={handleResetSearch}
                  />
                </>
              )}
            </AppPageTableContent>
          </AppPageTable>
        </AppPageContent>
      </ScrollSync>
    );
  };

  const renderStandardView = () => {
    return (
      <>
        <AppPageHeader className={classes.appPageHeader}>
          <ErrorBoundary>
            <WorkoutsPageHeader
              selectedFacility={selectedFacility}
              onFacilityChange={handleFacilityChange}
              clearSearch={clearSearch}
              onClearSearch={setClearSearch}
            />
          </ErrorBoundary>
        </AppPageHeader>

        <AppPageContent>
          <AppPageTable
            role="table"
            aria-labelledby="tableTitle"
            aria-label="enhanced table"
          >
            <AppPageTableContent className={classes.appPageTableContent}>
              <ErrorBoundary>
                <ScrollSync enabled={shouldScrollSync}>
                  <div className={classes.autoSizerContainer}>
                    {horses?.length ? (
                      <AutoSizer>
                        {({ height, width }) => (
                          <>
                            <VirtualList
                              count={horses?.length}
                              itemHeight={SCHEDULING_ROW_HEIGHT}
                              height={height}
                              width={width}
                              scrollContainerRef={tableBodyRef}
                              itemsBeyond={2}
                              header={renderListHeader()}
                              applyWidthToChild
                            >
                              {horses.map(horse => (
                                <HorseWorkoutSchedule
                                  key={horse.id}
                                  horseId={horse.id}
                                  horseName={horse.name}
                                  onDateClick={handleDateClick}
                                  horseOnLists={horseOnLists}
                                  facilityBlackoutsCalendar={
                                    facilityBlackoutsCalendar
                                  }
                                  updatingWorkoutRequests={getHorseUpdatingWorkoutRequests(
                                    updatingWorkoutRequests,
                                    horse.id
                                  )}
                                />
                              ))}
                            </VirtualList>
                          </>
                        )}
                      </AutoSizer>
                    ) : (
                      <>
                        {renderListHeader()}
                        <SearchNoResults
                          value={searchValue}
                          onClick={handleResetSearch}
                        />
                      </>
                    )}
                  </div>
                </ScrollSync>
                {(isLoading ||
                  isFacilityBlackoutsLoading ||
                  isHorsesOnListLoading) && <Loader overlay />}
              </ErrorBoundary>
            </AppPageTableContent>
          </AppPageTable>
        </AppPageContent>
      </>
    );
  };

  return (
    <AppPage>
      {shouldShowCondensedView ? renderCondensedView() : renderStandardView()}
      {dateMenuAnchor && (
        <DateMenu
          workoutRequest={activeWorkoutRequest}
          onUpdate={handleDateUpdate}
          onUpdateError={handleWorkoutUpdateError}
          anchor={dateMenuAnchor}
          open={isDateMenuOpen}
          onClose={handleDateMenuClose}
          onUpdateStart={handleDateUpdateStart}
        />
      )}
    </AppPage>
  );
});

export default WorkoutsPage;
