import React from "react";
import clsx from "clsx";
import format from "date-fns/format";
import parseISO from "date-fns/parseISO";
import utcToZonedTime from "date-fns-tz/utcToZonedTime";
import { AutoSizer } from "react-virtualized";

import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableFooter from "@material-ui/core/TableFooter";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import Typography from "@material-ui/core/Typography";
import PersonAddIcon from "@material-ui/icons/PersonAdd";
import CheckCircleIcon from "@material-ui/icons/CheckCircle";
import IconButton from "@material-ui/core/IconButton";
import Button from "@material-ui/core/Button";
import { Theme } from "@material-ui/core/styles";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import DotsIcon from "@material-ui/icons/MoreHoriz";
import RefreshIcon from "@material-ui/icons/Refresh";

import { racehorse360 } from "@tsg/1st-grpc-web";
import ClosedDoor from "components/Icons/ClosedDoor";
import Outlink from "components/Icons/Outlink";
import PersonEdit from "components/Icons/PersonEdit";
import ReOpen from "components/Icons/ReOpen";
import TableSortLabel from "components/TableSortLabel";
import Pagination from "components/Pagination";
import LogoIcon from "components/Icons/SapLogo";
import Loader from "components/Loader";
import VirtualList from "components/VirtualList";
import { useLoggedInUser } from "components/LoggedInUserProvider";
import { SortOrder } from "interfaces/SortOrder";
import Breakpoints from "common/breakpoints";
import { parseWorkOrderStatus } from "utils/enum-parser";
import { convertMinutesToDaysHours } from "utils/date-utils";
import { EPopoverType, getTabsStatuses, getUserPermissions } from "../helper";
import { MaintenanceWorkOrdersOrderBy } from "../MaintenanceWorkOrdersPage";
import useStyles, { tableRowHeightSM } from "./styles";

interface Props {
  onItemClick: (id) => void;
  onOpenDetails: (workOrder: racehorse360.IWorkOrder) => void;
  selectedId?: string;
  highlightedId?: string;
  onSortClick: (
    nextMaintenanceWorOrdersOrderBy: MaintenanceWorkOrdersOrderBy,
    nextOrder: SortOrder
  ) => void;
  workOrders: racehorse360.IWorkOrder[];
  totalCountWO: number;
  isLoading: boolean;
  isFetching: boolean;
  order: SortOrder;
  orderBy: MaintenanceWorkOrdersOrderBy;
  pageNumber: number;
  pageSize: number;
  onPageChange: (page: number) => void;
  tableContainerRef: React.RefObject<HTMLDivElement>;
  activeTab: racehorse360.WorkOrderStatus;
  onActionClick: (
    popoverType: EPopoverType,
    wo: racehorse360.IWorkOrder,
    anchor: Element
  ) => void;
  onOpenActionsMenu: (workOrder: racehorse360.IWorkOrder, el: Element) => void;
  isOpenActionsMenu: boolean;
  tableBodyRef: React.RefObject<HTMLDivElement>;
  onClearFilters: () => void;
  hasSavedFilters: boolean;
}

enum EPossibleColumns {
  id = "ID #",
  opened = "Opened",
  timeOpen = "Time Open",
  orderType = "Order Type",
  reportedBy = "Reported By",
  assigned = "Assigned",
  assignedTo = "Assigned To",
  completed = "Completed",
  timeAssigned = "Time Assigned",
  closed = "Closed",
  canceled = "Canceled",
  actions = "Actions"
}

enum EValueTypes {
  date = "date",
  time = "time",
  orderType = "orderType",
  actions = "actions",
  assignTo = "assignTo"
}

interface IColumnData {
  id: string;
  width: number;
  label: EPossibleColumns;
  value: string;
  sortable?: boolean;
  type?: EValueTypes;
  initialOrder?: SortOrder;
}

interface ITableColumns {
  [key: string]: IColumnData;
}

const tableColumns: ITableColumns = {
  [EPossibleColumns.id]: {
    id: "customerFacingId",
    width: 100,
    label: EPossibleColumns.id,
    value: "customerFacingId",
    initialOrder: SortOrder.ASC,
    sortable: true
  },
  [EPossibleColumns.opened]: {
    id: "createdOn",
    width: 150,
    label: EPossibleColumns.opened,
    value: "createdOn",
    type: EValueTypes.date,
    sortable: true
  },
  [EPossibleColumns.timeOpen]: {
    id: "minutesOpen",
    width: 200,
    label: EPossibleColumns.timeOpen,
    value: "minutesOpen",
    type: EValueTypes.time,
    sortable: true
  },
  [EPossibleColumns.orderType]: {
    id: "workOrderType.name",
    width: 200,
    label: EPossibleColumns.orderType,
    value: "workOrderType",
    sortable: true,
    initialOrder: SortOrder.ASC,
    type: EValueTypes.orderType
  },
  [EPossibleColumns.reportedBy]: {
    id: "fullName",
    width: 200,
    label: EPossibleColumns.reportedBy,
    value: "fullName",
    sortable: true,
    initialOrder: SortOrder.ASC
  },
  [EPossibleColumns.assigned]: {
    id: "assignedOn",
    width: 150,
    label: EPossibleColumns.assigned,
    value: "assignedOn",
    sortable: true,
    type: EValueTypes.date
  },
  [EPossibleColumns.timeAssigned]: {
    id: "minutesAssigned",
    width: 200,
    label: EPossibleColumns.timeAssigned,
    value: "minutesAssigned",
    type: EValueTypes.time,
    sortable: true
  },
  [EPossibleColumns.assignedTo]: {
    id: "assignedUser.firstName,assignedUser.lastName",
    width: 200,
    label: EPossibleColumns.assignedTo,
    value: "assignedUser",
    sortable: true,
    type: EValueTypes.assignTo,
    initialOrder: SortOrder.ASC
  },
  [EPossibleColumns.completed]: {
    id: "completedOn",
    width: 150,
    label: EPossibleColumns.completed,
    value: "completedOn",
    sortable: true,
    type: EValueTypes.date
  },
  [EPossibleColumns.closed]: {
    id: "closedOn",
    width: 150,
    label: EPossibleColumns.closed,
    value: "closedOn",
    sortable: true,
    type: EValueTypes.date
  },
  [EPossibleColumns.canceled]: {
    id: "canceledOn",
    width: 150,
    label: EPossibleColumns.canceled,
    value: "canceledOn",
    sortable: true,
    type: EValueTypes.date
  },
  [EPossibleColumns.actions]: {
    id: "Actions",
    width: 150,
    label: EPossibleColumns.actions,
    value: "",
    type: EValueTypes.actions
  }
};

const createConfigTable = (
  activeTab: racehorse360.WorkOrderStatus
): EPossibleColumns[] => {
  switch (activeTab) {
    case racehorse360.WorkOrderStatus.WORK_ORDER_STATUS_OPEN:
      return [
        EPossibleColumns.id,
        EPossibleColumns.opened,
        EPossibleColumns.timeOpen,
        EPossibleColumns.orderType,
        EPossibleColumns.reportedBy,
        EPossibleColumns.actions
      ];
    case racehorse360.WorkOrderStatus.WORK_ORDER_STATUS_ASSIGNED:
      return [
        EPossibleColumns.id,
        EPossibleColumns.assigned,
        EPossibleColumns.timeAssigned,
        EPossibleColumns.assignedTo,
        EPossibleColumns.orderType,
        EPossibleColumns.reportedBy,
        EPossibleColumns.actions
      ];
    case racehorse360.WorkOrderStatus.WORK_ORDER_STATUS_COMPLETE:
      return [
        EPossibleColumns.id,
        EPossibleColumns.assigned,
        EPossibleColumns.timeAssigned,
        EPossibleColumns.assignedTo,
        EPossibleColumns.completed,
        EPossibleColumns.orderType,
        EPossibleColumns.actions
      ];
    case racehorse360.WorkOrderStatus.WORK_ORDER_STATUS_CLOSED:
      return [
        EPossibleColumns.id,
        EPossibleColumns.timeOpen,
        EPossibleColumns.timeAssigned,
        EPossibleColumns.assignedTo,
        EPossibleColumns.reportedBy,
        EPossibleColumns.closed,
        EPossibleColumns.orderType,
        EPossibleColumns.actions
      ];
    case racehorse360.WorkOrderStatus.WORK_ORDER_STATUS_CANCELED:
      return [
        EPossibleColumns.id,
        EPossibleColumns.opened,
        EPossibleColumns.timeAssigned,
        EPossibleColumns.assignedTo,
        EPossibleColumns.reportedBy,
        EPossibleColumns.canceled,
        EPossibleColumns.orderType,
        EPossibleColumns.actions
      ];
    default:
      return [];
  }
};

const createConfigTableSM = (
  activeTab: racehorse360.WorkOrderStatus
): EPossibleColumns[] => {
  switch (activeTab) {
    case racehorse360.WorkOrderStatus.WORK_ORDER_STATUS_OPEN:
      return [
        EPossibleColumns.id,
        EPossibleColumns.opened,
        EPossibleColumns.timeOpen,
        EPossibleColumns.orderType,
        EPossibleColumns.actions
      ];
    case racehorse360.WorkOrderStatus.WORK_ORDER_STATUS_ASSIGNED:
      return [
        EPossibleColumns.id,
        EPossibleColumns.assigned,
        EPossibleColumns.assignedTo,
        EPossibleColumns.orderType,
        EPossibleColumns.actions
      ];
    case racehorse360.WorkOrderStatus.WORK_ORDER_STATUS_COMPLETE:
      return [
        EPossibleColumns.id,
        EPossibleColumns.assignedTo,
        EPossibleColumns.completed,
        EPossibleColumns.orderType,
        EPossibleColumns.actions
      ];
    case racehorse360.WorkOrderStatus.WORK_ORDER_STATUS_CLOSED:
      return [
        EPossibleColumns.id,
        EPossibleColumns.assignedTo,
        EPossibleColumns.closed,
        EPossibleColumns.orderType,
        EPossibleColumns.actions
      ];
    case racehorse360.WorkOrderStatus.WORK_ORDER_STATUS_CANCELED:
      return [
        EPossibleColumns.id,
        EPossibleColumns.reportedBy,
        EPossibleColumns.canceled,
        EPossibleColumns.orderType,
        EPossibleColumns.actions
      ];
    default:
      return [];
  }
};

const MaintenanceWorkOrderList = (props: Props) => {
  const {
    onItemClick,
    onOpenDetails,
    selectedId,
    onSortClick,
    workOrders,
    totalCountWO,
    isLoading,
    isFetching,
    order,
    orderBy,
    pageNumber,
    pageSize,
    onPageChange,
    onActionClick,
    highlightedId,
    tableContainerRef,
    activeTab,
    onOpenActionsMenu,
    isOpenActionsMenu,
    tableBodyRef,
    onClearFilters,
    hasSavedFilters
  } = props;

  const classes = useStyles();
  const { currentUser } = useLoggedInUser();
  const {
    isOpenTab,
    isAssignedTab,
    isCompleteTab,
    isClosedTab,
    isCanceledTab
  } = getTabsStatuses(activeTab);

  const matchesDownSX720 = useMediaQuery(
    (theme: Theme) => theme.breakpoints.down(Breakpoints.SX_720),
    { noSsr: true }
  );

  const handleRowClick = (id: string) => () => {
    onItemClick(id);
  };

  const handleActionClick =
    (popoverType: EPopoverType, wo: racehorse360.IWorkOrder) => e => {
      onActionClick(popoverType, wo, e.currentTarget);
    };

  const handleOpenDetails = (workOrder: racehorse360.IWorkOrder) => () => {
    onOpenDetails(workOrder);
  };

  const handleOpenActionsMenu = (workOrder: racehorse360.IWorkOrder) => e => {
    e.stopPropagation();
    onOpenActionsMenu(workOrder, e.currentTarget);
  };

  const renderActionButtons = (wo: racehorse360.IWorkOrder) => {
    const {
      hasPermissionAssign,
      hasPermissionClose,
      hasPermissionComplete,
      hasPermissionReopen
    } = getUserPermissions(wo, currentUser);

    return matchesDownSX720 ? (
      <IconButton
        className={clsx(classes.dotsButton, {
          ["active"]: wo.id === selectedId && isOpenActionsMenu
        })}
        onClick={handleOpenActionsMenu(wo)}
        size="small"
        data-test={"work-order-row__open-actions-button"}
      >
        <DotsIcon />
      </IconButton>
    ) : (
      <div className={classes.actionButtons}>
        {currentUser.isMaintenance && isCompleteTab && (
          <IconButton
            className={clsx(classes.actionButton, classes.undoButton, {
              [classes.actionButtonActive]: wo.id === highlightedId
            })}
            size="small"
            disableFocusRipple
            disableRipple
            onClick={handleActionClick(EPopoverType.UNDO_COMPLETE, wo)}
            data-test={"work-order-row__undo-complete-button"}
          >
            <RefreshIcon />
          </IconButton>
        )}

        {hasPermissionAssign && (isOpenTab || isAssignedTab) && (
          <IconButton
            className={clsx(classes.actionButton, classes.assignButton, {
              [classes.actionButtonActive]: wo.id === highlightedId
            })}
            size="small"
            disableFocusRipple
            disableRipple
            onClick={handleActionClick(
              wo.assignedUser ? EPopoverType.REASSIGN : EPopoverType.ASSIGN,
              wo
            )}
            data-test={"work-order-row__assign-button"}
          >
            {wo.assignedUser ? <PersonEdit /> : <PersonAddIcon />}
          </IconButton>
        )}

        {hasPermissionComplete && (isOpenTab || isAssignedTab) && (
          <IconButton
            className={clsx(classes.actionButton, classes.completeButton, {
              [classes.actionButtonActive]: wo.id === highlightedId
            })}
            size="small"
            disableFocusRipple
            disableRipple
            onClick={handleActionClick(EPopoverType.COMPLETE, wo)}
            data-test={"work-order-row__complete-button"}
          >
            <CheckCircleIcon />
          </IconButton>
        )}

        {hasPermissionClose && !isClosedTab && !isCanceledTab && (
          <IconButton
            className={clsx(classes.actionButton, classes.closeButton, {
              [classes.actionButtonActive]: wo.id === highlightedId
            })}
            size="small"
            disableFocusRipple
            disableRipple
            onClick={handleActionClick(EPopoverType.CLOSE, wo)}
            data-test={"work-order-row__close-button"}
          >
            <ClosedDoor />
          </IconButton>
        )}

        {currentUser.isMaintenanceSupervisor &&
          hasPermissionReopen &&
          (isClosedTab || isCanceledTab) && (
            <IconButton
              className={clsx(classes.actionButton, classes.reOpenButton, {
                [classes.actionButtonActive]: wo.id === highlightedId
              })}
              size="small"
              disableFocusRipple
              disableRipple
              onClick={handleActionClick(EPopoverType.REOPEN, wo)}
              data-test={"work-order-row__reopen-button"}
            >
              <ReOpen />
            </IconButton>
          )}

        <IconButton
          className={clsx(classes.actionButton, classes.detailsButton)}
          disableFocusRipple
          disableRipple
          size="small"
          onClick={handleOpenDetails(wo)}
          data-test={"work-order-row__open-details-button"}
        >
          <Outlink />
        </IconButton>
      </div>
    );
  };

  const renderCellData = (
    dataType: EValueTypes,
    value: any,
    workOrder: racehorse360.IWorkOrder
  ): string | JSX.Element => {
    if (dataType === EValueTypes.actions) {
      return renderActionButtons(workOrder);
    }

    if (value || dataType === EValueTypes.time) {
      const user = workOrder.assignedUser;

      switch (dataType) {
        case EValueTypes.date:
          return format(
            utcToZonedTime(parseISO(value), workOrder.facility.timezone),
            "M/d/yy"
          );
        case EValueTypes.time:
          return convertMinutesToDaysHours(value, matchesDownSX720);
        case EValueTypes.orderType:
          return workOrder.workOrderType.name;
        case EValueTypes.assignTo:
          return user ? `${user.firstName} ${user.lastName}` : "-";
        default:
          return value;
      }
    }

    return "-";
  };

  const renderTable = (columns: EPossibleColumns[]) => {
    const renderTableRows = (): JSX.Element | JSX.Element[] => {
      let output: JSX.Element | JSX.Element[];

      if (matchesDownSX720) {
        output = (
          <AutoSizer>
            {({ height, width }) => (
              <VirtualList
                itemClassName={classes.tableRowWrapper}
                count={workOrders.length}
                itemHeight={tableRowHeightSM}
                height={height}
                width={width}
                scrollContainerRef={tableBodyRef}
                itemsBeyond={4}
              >
                {workOrders.map(wo => (
                  <TableRow
                    key={wo.id}
                    className={clsx(classes.tableRow, {
                      [classes.mobileOpenTab]: isOpenTab
                    })}
                    component="div"
                    hover
                    selected={wo.id === selectedId || wo.id === highlightedId}
                    onClick={handleRowClick(wo.id)}
                    data-test={"work-order-row"}
                  >
                    {columns.map((column: EPossibleColumns, index: number) => {
                      const cell: IColumnData = tableColumns[column];

                      return (
                        <TableCell
                          key={cell.id}
                          className={clsx(classes.mobileTableCell, {
                            ["ellipsis"]: index === 2 || index === 3
                          })}
                          component="div"
                          align={
                            cell.type === EValueTypes.actions
                              ? "center"
                              : "left"
                          }
                        >
                          {renderCellData(cell.type, wo[cell.value], wo)}
                        </TableCell>
                      );
                    })}
                  </TableRow>
                ))}
              </VirtualList>
            )}
          </AutoSizer>
        );
      } else {
        output = (
          <>
            {workOrders.map((wo: racehorse360.WorkOrder) => (
              <TableRow
                key={wo.id}
                hover
                selected={wo.id === selectedId || wo.id === highlightedId}
                classes={{
                  root: classes.tableRow
                }}
                className={clsx(classes.tableRow)}
                onClick={handleRowClick(wo.id)}
                data-test={"work-order-row"}
              >
                {columns.map((column: EPossibleColumns) => {
                  const cell: IColumnData = tableColumns[column];
                  const tableCellClassName =
                    !matchesDownSX720 && cell.label === EPossibleColumns.actions
                      ? classes.actionsCell
                      : "";

                  return (
                    <TableCell
                      key={cell.id}
                      className={clsx(classes.tableCell, tableCellClassName)}
                      align="left"
                    >
                      {renderCellData(cell.type, wo[cell.value], wo)}
                    </TableCell>
                  );
                })}
              </TableRow>
            ))}
          </>
        );
      }

      return output;
    };

    return (
      <Table
        stickyHeader
        component={matchesDownSX720 ? "div" : "table"}
        className={classes.table}
      >
        <TableHead
          component={matchesDownSX720 ? "div" : "thead"}
          className={classes.tableHead}
        >
          <TableRow
            className={classes.tableHeaderRow}
            component={matchesDownSX720 ? "div" : "tr"}
          >
            {columns.map((column: EPossibleColumns) => {
              const cell: IColumnData = tableColumns[column];

              return (
                <TableCell
                  key={cell.id}
                  className={clsx(classes.tableHeadCell, {
                    [classes.dark]: Boolean(workOrders.length)
                  })}
                  component={matchesDownSX720 ? "div" : "th"}
                  align="left"
                  data-test={`work-order-list-header__${cell.value}`}
                >
                  <TableSortLabel
                    key={cell.id}
                    name={cell.id}
                    order={workOrders.length ? order : undefined}
                    orderBy={workOrders.length ? orderBy : undefined}
                    onSortClick={onSortClick}
                    disabled={!cell.sortable || !workOrders.length}
                    hideSortIcon={!cell?.sortable}
                    className={classes.sortIcon}
                    firstClickSort={cell.initialOrder}
                  >
                    {cell.label}
                  </TableSortLabel>
                </TableCell>
              );
            })}
          </TableRow>
        </TableHead>
        <TableBody
          className={classes.tableBody}
          component={matchesDownSX720 ? "div" : "tbody"}
        >
          {renderTableRows()}
          <TableRow
            component={matchesDownSX720 ? "div" : "tr"}
            className={classes.tableRowFiller}
          />
        </TableBody>
        {!matchesDownSX720 && (
          <TableFooter>
            <TableRow>
              <TableCell
                colSpan={columns.length}
                className={classes.tablePaginationCell}
              >
                <Pagination
                  className={classes.pagination}
                  totalCount={totalCountWO}
                  pageNumber={pageNumber}
                  pageSize={pageSize}
                  onPageChange={onPageChange}
                />
              </TableCell>
            </TableRow>
          </TableFooter>
        )}
      </Table>
    );
  };

  return (
    <div className={classes.root}>
      {isLoading && <Loader overlay />}
      {!isLoading && !workOrders.length && (
        <div className={classes.cta}>
          <LogoIcon className={classes.ctaBg} />
          <div className={classes.ctaContent}>
            <Typography className={classes.ctaTitle}>
              {hasSavedFilters
                ? `No ${parseWorkOrderStatus(
                    activeTab
                  )} Work Orders Matching Filter Selections`
                : `No ${parseWorkOrderStatus(activeTab)} work orders`}
            </Typography>
            {hasSavedFilters && (
              <Button
                className={classes.clearButton}
                onClick={onClearFilters}
                variant="outlined"
              >
                Clear Filters
              </Button>
            )}
          </div>
        </div>
      )}
      <TableContainer
        className={classes.tableContainer}
        ref={tableContainerRef}
      >
        {(isLoading || isFetching) && <Loader overlay />}

        {renderTable(
          matchesDownSX720
            ? createConfigTableSM(activeTab)
            : createConfigTable(activeTab)
        )}
      </TableContainer>
    </div>
  );
};

export default MaintenanceWorkOrderList;
