export interface IPerson {
  firstName?: string;
  middleName?: string;
  lastName?: string;
}

export const getPersonFullName = (
  person: IPerson,
  format: string = "%F %M %L"
): string => {
  const firstName = person?.firstName?.trim() || "";
  const middleName = person?.middleName?.trim() || "";
  const lastName = person?.lastName?.trim() || "";

  return format
    .replace(/%F/g, firstName)
    .replace(/%L/g, lastName)
    .replace(/%M/g, middleName)
    .replace(/%f/g, firstName ? firstName.substr(0, 1) + "." : "")
    .replace(/%l/g, lastName ? lastName.substr(0, 1) + "." : "")
    .replace(/%m/g, middleName ? middleName.substr(0, 1) + "." : "")
    .replace(/\s+/g, " ");
};
