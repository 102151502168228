import React from "react";

import { racehorse360 } from "@tsg/1st-grpc-web/dist";

import { transformViewDate } from "utils/date-utils";
import useStyles from "./styles";

interface IProps {
  comment: racehorse360.IHorseFlagOnTrackComment;
}

const PopupBodyFOT = (props: IProps) => {
  const { updatedBy, updatedOn, body } = props.comment;
  const classes = useStyles();

  return (
    <div className={classes.commentRoot}>
      <div className={classes.headerRow}>
        <span className={classes.commenterInfo}>
          {`${updatedBy.firstName} ${updatedBy.lastName}`}
        </span>
      </div>
      <div className={classes.row}>
        <div className={classes.info}>
          <div className={classes.uppercase}>
            {transformViewDate(updatedOn, null, "MMM, d, yyyy")}
          </div>
          <div className={classes.additionalSigns}>&nbsp;&ndash;&nbsp;</div>
          <div>{transformViewDate(updatedOn, null, "p")}</div>
        </div>
      </div>
      <div className={classes.commentContent}>{body}</div>
    </div>
  );
};

export default PopupBodyFOT;
