import React, { SyntheticEvent, useState } from "react";
import { useHistory, useRouteMatch } from "react-router-dom";
import { useQueryClient } from "react-query";
import { useDispatch, useSelector } from "react-redux";
import clsx from "clsx";
import { goBack } from "connected-react-router";
import formatISO from "date-fns/formatISO";
import parseISO from "date-fns/parseISO";
import { useFormik } from "formik";

import Accordion from "@material-ui/core/Accordion";
import AccordionDetails from "@material-ui/core/AccordionDetails";
import AccordionSummary from "@material-ui/core/AccordionSummary";
import Button from "@material-ui/core/Button";
import Checkbox from "@material-ui/core/Checkbox";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Link from "@material-ui/core/Link";
import { useTheme } from "@material-ui/core/styles";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import ArrowForwardIosIcon from "@material-ui/icons/ArrowForwardIos";
import ArrowBackIosIcon from "@material-ui/icons/ArrowBackIos";
import ValidIcon from "@material-ui/icons/CheckCircle";
import InvalidIcon from "@material-ui/icons/Warning";

import { racehorse360 } from "@tsg/1st-grpc-web";

import routes from "common/routes";
import AppPage from "components/AppPage";
import AppPageHeader from "components/AppPageHeader";
import AppPageContent from "components/AppPageContent";
import ErrorBoundary from "components/ErrorBoundary";
import Form from "components/Form";
import FormField from "components/FormField";
import Loader from "components/Loader";
import { useLoggedInUser } from "components/LoggedInUserProvider";
import MUIAccordionCustomExpandIcon from "components/MUIAccordionCustomExpandIcon";
import {
  setActiveLink,
  setStoreActiveLinkCandidate
} from "store/actions/sidebar";
import SwipeableTabs from "components/SwipeableTabs";
import { useSnackbar } from "components/SnackbarContext/SnackbarContext";
import { useRacehorse360Api } from "hooks/api";
import { transformViewDate } from "utils/date-utils";
import { getPersonFullName } from "utils/person";
import {
  FormFiledName,
  getModules,
  ILocationState,
  initialState,
  IState,
  STABLE_ENTRIES_INPUT,
  UNSAVED_CHANGES_TEXT,
  Tabs
} from "./constants";
import Review from "./Review";
import StablingEntries from "./StablingEntries";
import TermsAndConditionsPopup from "./TermsAndConditionsPopup";
import UnsavedChangesDialog from "components/UnsavedChangesDialog";
import { useClickBlockerContext } from "components/BlockableClickContext";
import validationSchema from "./validationSchema";
import {
  CITY_REGEXP,
  ONLY_NUMBERS_REGEXP,
  POLICY_NUMBER_REGEXP,
  STREET_ADDRESS_REGEXP
} from "common/regExp";
import useStyles from "./styles";

const StallApplicationFormPage = () => {
  const classes = useStyles();
  const theme = useTheme();
  const history = useHistory<ILocationState>();
  const match = useRouteMatch<{ stallApplicationFormId: string }>(
    routes.stallApplicationTrainerForm.path
  );
  const matchesDownSm = useMediaQuery(theme.breakpoints.down("sm"), {
    noSsr: true
  });
  const dispatch = useDispatch();
  const { currentUser } = useLoggedInUser();
  const queryClient = useQueryClient();
  const { showSuccessSnack, showErrorSnack } = useSnackbar();
  const linkCandidate = useSelector(
    (state: { sidebar }) => state?.sidebar.activeLinkCandidate
  );

  const [showValidationResults, setShowValidationResults] = useState(false);
  const [activeTab, setActiveTab] = useState<string>(Tabs[0]);
  const [showReview, setShowReview] = useState<boolean>(false);
  const [showTermsAndConditionsPopup, setShowTermsAndConditionsCPopup] =
    useState<boolean>(false);
  const [tncAgree, setTncAgree] = useState<boolean>(false);
  const [stateStablingEntries, setStateStablingEntries] = useState<
    racehorse360.IStableEntry[]
  >([]);
  const [filledModules, setFilledModules] = useState(
    getModules(currentUser, {})
  );
  const [hasUnsavedChanges, setHasUnsavedChanges] = useState(false);
  const [showUnsavedChangesPopup, setShowUnsavedChangesPopup] = useState(false);
  const [shouldValidateOnChange, setShouldValidateOnChange] = useState(false);

  const { setBlockClick, setBlockClickCallback } = useClickBlockerContext();
  const stallApplicationFormId = match.params.stallApplicationFormId;
  const hasEntriesValidationError = [
    !stateStablingEntries.length,
    shouldValidateOnChange
  ].every(Boolean);

  const redirectToLinkCandidate = () => {
    if (linkCandidate) {
      dispatch(setActiveLink(linkCandidate));
      dispatch(setStoreActiveLinkCandidate(""));
      history.push(linkCandidate);
    }
  };

  const clearLinkCandidate = () => {
    dispatch(setStoreActiveLinkCandidate(""));
  };

  const lockNavigation = () => {
    if (!hasUnsavedChanges) {
      setHasUnsavedChanges(true);
      setBlockClickCallback(() => () => setShowUnsavedChangesPopup(true));
      setBlockClick(true);
    }
  };

  const unlockNavigation = () => {
    setHasUnsavedChanges(false);
    setBlockClick(false);
    setBlockClickCallback(null);
    redirectToLinkCandidate();
  };

  const {
    useListTrainers,
    useCreateStallApplication,
    useUpdateStallApplication,
    useSubmitStallApplication,
    useListStallApplications,
    useGetStallApplicationForm,
    useSetFacilityPreferences,
    useCreateStableEntry,
    useDeleteStableEntry,
    useUpdateStableEntry
  } = useRacehorse360Api();

  const {
    mutateAsync: createStallApplication,
    isLoading: isStallApplicationCreating
  } = useCreateStallApplication();

  const {
    mutateAsync: updateStallApplication,
    isLoading: isStallApplicationUpdating
  } = useUpdateStallApplication();

  const {
    mutateAsync: submitStallApplication,
    isLoading: isSubmitStallApplicationLoading
  } = useSubmitStallApplication();

  const {
    isLoading: isCreateStableEntryLoading,
    mutateAsync: createStableEntry
  } = useCreateStableEntry();

  const {
    isLoading: isDeleteStableEntryLoading,
    mutateAsync: deleteStableEntry
  } = useDeleteStableEntry();

  const {
    isLoading: isUpdateStableEntryLoading,
    mutateAsync: updateStableEntry
  } = useUpdateStableEntry();

  const {
    isLoading: isSetFacilityPreferencesLoading,
    mutateAsync: setFacilityPreferences
  } = useSetFacilityPreferences();

  const listKey = "list-stall-applications";

  const scrollToError = (errors: string[]) => {
    let firstErrorElement: HTMLElement;

    if (errors.length) {
      firstErrorElement = document.querySelector(`[name=${errors[0]}]`);
    } else {
      firstErrorElement = document.querySelector(
        `[name=${STABLE_ENTRIES_INPUT}]`
      );
    }

    firstErrorElement?.scrollIntoView({ behavior: "smooth", block: "center" });
    firstErrorElement?.focus({ preventScroll: true });
  };

  const getQueryForUser = () => {
    const queryTrainer = {
      trainerIds: currentUser.trainerIds,
      stallApplicationFormId
    };
    const querySecretary = {
      createdByIds: [currentUser.rh360Id],
      stallApplicationFormId,
      statuses: [
        racehorse360.StallApplicationStatus.STALL_APPLICATION_STATUS_IN_PROGRESS
      ]
    };
    return currentUser.isTrainer ? queryTrainer : querySecretary;
  };

  const {
    data,
    isFetching: isListStallApplicationsFetching,
    isLoading: isListStallApplicationsLoading
  } = useListStallApplications(
    listKey,
    {
      query: getQueryForUser(),
      getOptions: {
        select: [
          "arrival_date",
          "sleeping_rooms_number",
          "contact_full_name",
          "contact_phone",
          "contact_email",
          "contact_zip_code",
          "contact_state",
          "contact_city",
          "contact_street_address",
          "contact_street_address_optional",
          "carrier_name",
          "policy_number",
          "policy_expiration_date",
          "carrier_zip_code",
          "carrier_state",
          "carrier_city",
          "carrier_street_address",
          "carrier_street_address_optional",
          "is_stable_planned_to_split",
          "comment",
          "facility_preferences",
          "stable_entries:id",
          "stable_entries:horseId",
          "stable_entries:stallApplicationId",
          "stable_entries:customHorseRegistrationNumber",
          "stable_entries:customHorseName",
          "stable_entries:raceReadyDate",
          "stable_entries:horse.id",
          "stable_entries:horse.name",
          "stable_entries:horse.registrationNumber",
          "stable_entries:horse.ownerName",
          "stall_application_form_id",
          "trainer.id"
        ]
      }
    },
    {
      enabled: Boolean(stallApplicationFormId),
      onSuccess: async data => {
        const stallApplication = data.stallApplications[0];
        if (stallApplication) {
          setStateStablingEntries(stallApplication.stableEntries || []);
          await formik
            .setValues({
              ...stallApplication,
              arrivalDate: stallApplication.arrivalDate
                ? parseISO(stallApplication.arrivalDate)
                : null,
              policyExpirationDate: stallApplication.policyExpirationDate
                ? parseISO(stallApplication.policyExpirationDate)
                : null,
              isStablePlannedToSplit:
                stallApplication.isStablePlannedToSplit?.value,
              preferencesFirst: stallApplication.facilityPreferences.length
                ? stallApplication.facilityPreferences[0].id
                : "",
              preferencesSecond:
                stallApplication.facilityPreferences.length > 1
                  ? stallApplication.facilityPreferences[1].id
                  : ""
            })
            .then(() => {
              unlockNavigation();
            });
        }
      }
    }
  );

  const { data: stallApplicationForm } = useGetStallApplicationForm(
    {
      id: stallApplicationFormId,
      getOptions: {
        select: [
          "title",
          "start_date",
          "deadline_date",
          "close_date",
          "facility_options:id",
          "facility_options:name",
          "facility_options:code",
          "facility_options:background_color",
          "facility_options:foreground_color",
          "facility_options:stroke_color",
          "facility_options:type",
          "facility_options:training_facilities"
        ]
      }
    },
    {
      onSuccess: async (form: racehorse360.IStallApplicationForm) => {
        setFilledModules(getModules(currentUser, form));

        //TODO: Need to rework after implementation story RAC-3528 (initial
        // preferences setting should be moved to initial state in useFormik hook)
        const mainFacilityId = history.location.state.facilityId;
        const hasMainFacilityId = form.facilityOptions.some(
          item => item.id === mainFacilityId
        );

        if (hasMainFacilityId) {
          await formik.setFieldValue("preferencesFirst", mainFacilityId);
          await formik.setFieldValue("preferencesSecond", mainFacilityId);
        }
      }
    }
  );

  const {
    isFetching: isListTrainersFetching,
    isLoading: isListTrainersLoading
  } = useListTrainers(
    {
      query: {
        ids: currentUser.trainerIds
      },
      getOptions: {
        select: ["firstName", "middleName", "lastName"]
      }
    },
    {
      enabled: currentUser.isTrainer || currentUser.isAssistantTrainer,
      onSuccess: async data => {
        const trainer = data.trainers[0];
        await formik.setFieldValue(
          "contactFullName",
          getPersonFullName(trainer)
        );
      }
    }
  );

  const isLoading = [
    isListStallApplicationsFetching,
    isListStallApplicationsLoading,
    isListTrainersLoading
  ].some(Boolean);

  const isFetching = [
    isListTrainersFetching,
    isCreateStableEntryLoading,
    isDeleteStableEntryLoading,
    isUpdateStableEntryLoading,
    isSubmitStallApplicationLoading,
    isStallApplicationCreating,
    isStallApplicationUpdating,
    isSetFacilityPreferencesLoading
  ].some(Boolean);

  const saveStablingEntries = stallApplication => {
    const promises = [];
    const newStableEntries = stateStablingEntries.filter(se => !se.id);
    const updatedStableEntries = stateStablingEntries.filter(se => se.id);
    const updatedStableEntriesIds = new Set(
      updatedStableEntries.map(se => se.id)
    );
    const deletedStableEntries = stallApplication.stableEntries.filter(
      se => !updatedStableEntriesIds.has(se.id)
    );

    newStableEntries.forEach(stableEntry => {
      promises.push(
        createStableEntry({
          stableEntry: {
            ...stableEntry,
            stallApplicationId: stallApplication.id,
            horseId: stableEntry.customHorseName
              ? undefined
              : stableEntry.horseId,
            horse: undefined
          }
        })
      );
    });

    updatedStableEntries.forEach(stableEntry => {
      promises.push(
        updateStableEntry({
          stableEntry,
          updateMask: {
            paths: ["raceReadyDate"]
          }
        })
      );
    });

    deletedStableEntries.forEach(stableEntry => {
      promises.push(
        deleteStableEntry({
          id: stableEntry.id
        })
      );
    });

    return Promise.all(promises);
  };

  const saveFacilityPreferences = stallApplication => {
    if (values.preferencesFirst || values.preferencesSecond) {
      return setFacilityPreferences({
        stallApplicationId: stallApplication.id,
        facilityIds: [values.preferencesFirst, values.preferencesSecond].filter(
          Boolean
        )
      });
    } else {
      return Promise.resolve(racehorse360.EmptyResponse.create({}));
    }
  };

  const saveAndClose =
    (isSubmit: boolean = false) =>
    () => {
      const stallApplication = {
        ...values,
        arrivalDate: values.arrivalDate
          ? formatISO(values.arrivalDate, {
              representation: "date"
            })
          : null,
        policyExpirationDate: values.policyExpirationDate
          ? formatISO(values.policyExpirationDate, {
              representation: "date"
            })
          : null,
        isStablePlannedToSplit: {
          value: values.isStablePlannedToSplit
        },
        trainerId: currentUser.trainerId,
        preferencesFirst: undefined,
        preferencesSecond: undefined,
        stallApplicationFormId
      };

      let stallApplicationId = data?.stallApplications[0]?.id;

      if (stallApplicationId) {
        updateStallApplication({
          stallApplication,
          updateMask: {
            paths: Object.keys(initialState).filter(
              path => !["preferencesFirst", "preferencesSecond"].includes(path)
            )
          }
        })
          .then(() =>
            Promise.all([
              saveFacilityPreferences(data?.stallApplications[0]),
              saveStablingEntries(data?.stallApplications[0])
            ])
          )
          .then(async () => {
            if (isSubmit) {
              await submitStallApplication({ stallApplicationId });
            }
            isSubmit && showSuccessSnack("Application submitted successfully.");
            history.push(routes.stallApplication.path);
          })
          .finally(() => {
            unlockNavigation();
            return queryClient.invalidateQueries([listKey]);
          })
          .catch(() => {
            isSubmit &&
              showErrorSnack(
                "Application could not be submitted. Please try again."
              );
          });
      } else {
        createStallApplication({
          stallApplication
        })
          .then(createdStallApplication => {
            stallApplicationId = createdStallApplication.id;
            return Promise.all([
              saveFacilityPreferences(createdStallApplication),
              saveStablingEntries({
                ...stallApplication,
                ...createdStallApplication
              })
            ]);
          })
          .then(async () => {
            if (isSubmit) {
              await submitStallApplication({ stallApplicationId });
              showSuccessSnack("Application submitted successfully.");
            }
            history.push(routes.stallApplication.path);
            return queryClient.invalidateQueries([listKey]);
          })
          .finally(() => {
            unlockNavigation();
          })
          .catch(() => {
            isSubmit &&
              showErrorSnack(
                "Application could not be submitted. Please try again."
              );
          });
      }
    };

  const formik = useFormik<IState>({
    initialValues: initialState,
    validationSchema: validationSchema,
    onSubmit: saveAndClose(false),
    validateOnMount: false,
    validateOnBlur: false,
    validateOnChange: shouldValidateOnChange
  });
  const values = formik.values || {};

  const handleValueChange = fieldName => async value => {
    if (!value.target) {
      await formik.setFieldValue(fieldName, value);
      return;
    }

    const currentValue = value.target.value;
    let actualRegExp = /./;

    if (
      fieldName === FormFiledName.CONTACT_STREET_ADDRESS ||
      fieldName === FormFiledName.CARRIER_STREET_ADDRESS
    ) {
      actualRegExp = STREET_ADDRESS_REGEXP;
    } else if (
      fieldName === FormFiledName.CONTACT_CITY ||
      fieldName === FormFiledName.CARRIER_CITY
    ) {
      actualRegExp = CITY_REGEXP;
    } else if (
      fieldName === FormFiledName.CONTACT_ZIP_CODE ||
      fieldName === FormFiledName.CARRIER_ZIP_CODE
    ) {
      actualRegExp = ONLY_NUMBERS_REGEXP;
    } else if (fieldName === FormFiledName.POLICY_NUMBER) {
      actualRegExp = POLICY_NUMBER_REGEXP;
    }

    if (!currentValue || actualRegExp.test(currentValue)) {
      await formik.setFieldValue(fieldName, currentValue);
    }

    lockNavigation();
  };

  const handleFormValueChange = e => {
    formik.handleChange(e);
    lockNavigation();
  };

  const handleStableEntriesChange = e => {
    setStateStablingEntries(e);
    lockNavigation();
  };

  const handleTabChange = (tab: string) => {
    setActiveTab(tab);
  };

  const handleNextClick = () => {
    setActiveTab(Tabs[1]);
  };

  const handleBackClick = () => {
    if (showReview) {
      setShowReview(false);
    } else {
      setActiveTab(Tabs[0]);
    }
  };

  const handleShowReviewClick = () => {
    formik
      .validateForm()
      .then(errorsObject => {
        const errors = Object.keys(errorsObject);

        if (!errors.length && stateStablingEntries.length) {
          setShowReview(true);
          setShouldValidateOnChange(false);
          return;
        }
        if (errors.length) {
          formik.setErrors(errorsObject);
          setShowValidationResults(true);
        }

        setShouldValidateOnChange(true);
        scrollToError(errors);
      })
      .catch(error => {
        console.error(error);
      });
  };

  const handleTncAgreeCheck = event => {
    setTncAgree(event.currentTarget.checked);
  };

  const handleDiscard = async () => {
    unlockNavigation();
  };

  const handleTermsAndConditionsLinkClick = (e: SyntheticEvent) => {
    e.preventDefault();
    setShowTermsAndConditionsCPopup(true);
  };

  const handleTermsAndConditionsPopupClose = () => {
    setShowTermsAndConditionsCPopup(false);
  };

  const handleCancelClick = () => {
    if (!hasUnsavedChanges) {
      dispatch(goBack());
    } else {
      setShowUnsavedChangesPopup(true);
    }
  };

  const handleSubmitApplication = () => {
    saveAndClose(true)();
  };

  const handleCancelUnsavedChanges = () => {
    clearLinkCandidate();
    setShowUnsavedChangesPopup(false);
  };

  const renderValidationResultsIcon = (moduleName: string) => {
    if (!showValidationResults) return null;

    const moduleFields = filledModules
      .find(m => m.name === moduleName)
      .fields.map(m => m.name);
    const hasError = moduleFields.some(f => formik.errors[f]);

    return hasError ? (
      <InvalidIcon
        className={clsx(classes.sectionValidIcon, classes.invalid)}
      />
    ) : (
      <ValidIcon className={clsx(classes.sectionValidIcon, classes.valid)} />
    );
  };

  const renderTrainerDetails = () => {
    return filledModules.map(module => (
      <Accordion
        defaultExpanded={true}
        key={module.name}
        className={classes.section}
      >
        <AccordionSummary
          expandIcon={<MUIAccordionCustomExpandIcon />}
          classes={{
            root: classes.sectionHeader,
            expanded: classes.sectionHeaderExpanded,
            content: classes.sectionHeaderContent
          }}
        >
          {module.title}
          {renderValidationResultsIcon(module.name)}
        </AccordionSummary>
        <AccordionDetails className={classes.fieldsGroup}>
          {module.fields.map(field => (
            <FormField
              key={field.name}
              field={field}
              moduleName={module.name}
              value={values[field.name]}
              onChange={handleFormValueChange}
              onChangeCustom={handleValueChange}
              error={formik.errors[field.name]}
              helperText={formik.errors[field.name] || ""}
            />
          ))}
        </AccordionDetails>
      </Accordion>
    ));
  };

  const renderSubmitAndBackButtons = () => (
    <>
      <Button
        variant={"outlined"}
        className={clsx(classes.pageFooterButton)}
        startIcon={<ArrowBackIosIcon />}
        onClick={handleBackClick}
      >
        BACK
      </Button>
      <Button
        className={clsx(classes.pageFooterButton, classes.pageFooterButtonNext)}
        variant={"contained"}
        disableElevation
        disabled={!formik.isValid || !stateStablingEntries.length || !tncAgree}
        onClick={handleSubmitApplication}
      >
        SUBMIT FORM
      </Button>
    </>
  );

  const renderFooter = () => {
    if (matchesDownSm) {
      return showReview ? (
        renderSubmitAndBackButtons()
      ) : (
        <>
          {activeTab === Tabs[0] ? (
            <>
              <Button
                variant={"outlined"}
                className={clsx(classes.pageFooterButton)}
                onClick={saveAndClose(false)}
              >
                SAVE & CLOSE
              </Button>
              <Button
                className={clsx(
                  classes.pageFooterButton,
                  classes.pageFooterButtonNext
                )}
                variant={"contained"}
                disableElevation
                endIcon={<ArrowForwardIosIcon />}
                onClick={handleNextClick}
              >
                NEXT
              </Button>
            </>
          ) : (
            <>
              <Button
                variant={"outlined"}
                className={clsx(classes.pageFooterButton)}
                startIcon={<ArrowBackIosIcon />}
                onClick={handleBackClick}
              >
                BACK
              </Button>
              <Button
                className={clsx(
                  classes.pageFooterButton,
                  classes.pageFooterButtonNext
                )}
                variant={"contained"}
                disableElevation
                onClick={handleShowReviewClick}
              >
                REVIEW FORM
              </Button>
            </>
          )}
        </>
      );
    } else {
      return (
        <>
          <div className={classes.pageFooterDesktopButtons}>
            {showReview ? (
              renderSubmitAndBackButtons()
            ) : (
              <>
                <Button
                  variant={"outlined"}
                  className={clsx(
                    classes.pageFooterButton,
                    classes.pauseAndCloseButton
                  )}
                  onClick={saveAndClose(false)}
                >
                  SAVE & CLOSE
                </Button>
                <Button
                  className={clsx(
                    classes.pageFooterButton,
                    classes.pageFooterButtonNext
                  )}
                  variant={"contained"}
                  disableElevation
                  onClick={handleShowReviewClick}
                >
                  REVIEW FORM
                </Button>
              </>
            )}
          </div>
        </>
      );
    }
  };

  return (
    <AppPage className={classes.root}>
      <AppPageHeader className={classes.pageHeader}>
        {matchesDownSm ? (
          <>
            <Button
              className={classes.pageHeaderButtonCancel}
              onClick={handleCancelClick}
            >
              Cancel
            </Button>
            <span className={classes.pageHeaderTitle}>Intake Form</span>
            <span className={classes.pageHeaderSubTitle}>
              {stallApplicationForm?.title}
            </span>
          </>
        ) : (
          <>
            <div className={classes.pageHeaderInfo}>
              <span className={classes.pageHeaderInfoName}>
                {stallApplicationForm?.title}
              </span>
              <span className={classes.pageHeaderInfoDeadline}>
                Deadline:{" "}
                {stallApplicationForm?.deadlineDate
                  ? transformViewDate(stallApplicationForm.deadlineDate)
                  : "N/A"}
              </span>
            </div>
          </>
        )}
      </AppPageHeader>
      {isLoading && <Loader overlay />}

      <AppPageContent className={classes.pageContent}>
        {isFetching && <Loader overlay />}
        {showReview ? (
          <Review
            values={values}
            stablingEntries={stateStablingEntries}
            facilityOptions={stallApplicationForm.facilityOptions}
          />
        ) : (
          <SwipeableTabs
            classes={{
              root: classes.tabsRoot,
              tabs: classes.tabs,
              tab: classes.tab,
              selected: classes.tabSelected,
              indicator: classes.tabsIndicator,
              views: classes.tabsViews,
              slideClassName: classes.tabSlide,
              content: classes.tabsContent
            }}
            activeTab={matchesDownSm ? activeTab : Tabs[0]}
            onTabChange={handleTabChange}
            disabled={!matchesDownSm}
            disableLazyLoading={true}
            tabs={Tabs}
          >
            <Form className={classes.form} onSubmit={formik.handleSubmit}>
              {renderTrainerDetails()}
            </Form>
            <div className={classes.stablingEntries}>
              <ErrorBoundary>
                <StablingEntries
                  pickedStables={stateStablingEntries}
                  stallApplication={data?.stallApplications[0]}
                  isStallApplicationFetching={isListStallApplicationsFetching}
                  onChange={handleStableEntriesChange}
                  hasEntriesValidationError={hasEntriesValidationError}
                />
              </ErrorBoundary>
            </div>
          </SwipeableTabs>
        )}
      </AppPageContent>
      <section className={classes.pageFooter}>
        {showReview && (
          <FormControlLabel
            className={classes.tncCheckbox}
            control={
              <Checkbox
                classes={{
                  checked: classes.tncCheckboxChecked
                }}
                color="primary"
                checked={tncAgree}
                onChange={handleTncAgreeCheck}
              />
            }
            label={
              <>
                I Agree to the{" "}
                <Link
                  className={classes.termsLink}
                  onClick={handleTermsAndConditionsLinkClick}
                >
                  Terms & Conditions
                </Link>
              </>
            }
            labelPlacement="end"
          />
        )}
        {renderFooter()}
      </section>
      {showTermsAndConditionsPopup && (
        <TermsAndConditionsPopup
          stallApplicationFormId={stallApplicationFormId}
          onClose={handleTermsAndConditionsPopupClose}
        />
      )}
      {hasUnsavedChanges && (
        <UnsavedChangesDialog
          onSave={saveAndClose(false)}
          onCancel={handleCancelUnsavedChanges}
          onDiscard={handleDiscard}
          open={showUnsavedChangesPopup}
          content={<span>{UNSAVED_CHANGES_TEXT}</span>}
          cancelButtonLabel={"Back"}
        />
      )}
    </AppPage>
  );
};

export default StallApplicationFormPage;
