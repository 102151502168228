export const phoneNumberMask = "(###) - ### - ####";

//user form fields constraints
export const MAX_FIRST_NAME_LENGTH = 32;
export const MAX_LAST_NAME_LENGTH = 32;
export const MAX_LICENSE_NUMBER_LENGTH = 20;

//Symbols
export const DASH = "-";
export const NOT_ACCESSIBLE = "N/A";
export const EMPTY_STRING = "";
