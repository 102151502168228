import React from "react";
import clsx from "clsx";

import { Theme } from "@material-ui/core/styles";
import useMediaQuery from "@material-ui/core/useMediaQuery";

import { racehorse360 } from "@tsg/1st-grpc-web";

import { useRacehorse360Api } from "hooks/api";
import { ICommentData } from "components/Comments/Comment/Comment";
import { ECommentDisplayingVariant } from "components/Comments/Comment/helper";
import CommentsHeader from "components/Comments/CommentsHeader";
import Comment from "components/Comments/Comment";
import Loader from "components/Loader";

import useStyles from "./styles";

interface Props {
  horse: racehorse360.IHorse;
  refetchComments: () => void;
  isCommentsFetching: boolean;
  commentsList: racehorse360.IHorseAggregatedComment[];
}

const getUnreadCommentsCount = (
  comments: racehorse360.IHorseAggregatedComment[] = []
): number => {
  return comments.reduce((acc, cur) => (cur.isRead ? acc : ++acc), 0);
};

const Comments = (props: Props) => {
  const { horse, commentsList, refetchComments, isCommentsFetching } = props;
  const classes = useStyles();
  const matches = useMediaQuery((theme: Theme) => theme.breakpoints.up("md"));

  const { useMarkHorseAggregatedCommentsAsRead } = useRacehorse360Api();

  const { mutateAsync: markCommentsAsReadRequest } =
    useMarkHorseAggregatedCommentsAsRead();

  const markCommentsAsRead = (
    comment: racehorse360.IHorseAggregatedComment,
    areAllRead: boolean = false
  ) => {
    if (areAllRead || (comment && !comment.isRead)) {
      const commentIds: Partial<racehorse360.MarkHorseAggregatedCommentsAsReadRequest> =
        {};

      if (comment) {
        if (
          comment.eventType ===
          racehorse360.EventType.EVENT_TYPE_WORKOUT_REQUEST
        ) {
          commentIds.workoutRequestCommentIds = [comment.id];
        } else if (
          comment.eventType === racehorse360.EventType.EVENT_TYPE_WORKOUT_EXAM
        ) {
          commentIds.workoutExamCommentIds = [comment.id];
        }
      }

      markCommentsAsReadRequest({
        ...commentIds,
        horseId: horse.id,
        markAllAsRead: areAllRead
      })
        .then(() => refetchComments())
        .catch(error => console.error(error));
    }
  };

  const handleMarkAllAsRead = () => {
    markCommentsAsRead(null, true);
  };

  const handleClickComment = (id: string) => {
    const comment = commentsList.find(comment => comment.id === id);

    comment && markCommentsAsRead(comment);
  };

  if (!commentsList?.length) {
    return (
      <section className={classes.empty}>No Comments for {horse.name}</section>
    );
  }

  const renderComments = () => {
    return commentsList.map(
      (comment: racehorse360.IHorseAggregatedComment, index: number) => {
        const commentData: ICommentData = {
          id: comment.id,
          content: comment.body,
          commentIconType: comment.horseAggregatedCommentIconType,
          isRead: comment.isRead,
          eventType: comment.eventType,
          commenterLastName: comment.commenterLastName,
          commenterFirstName: comment.commenterFirstName,
          eventDate: comment.eventDate,
          createdOn: comment.createdOn
        };

        return (
          <Comment
            key={comment.id}
            className={clsx(classes.comment, {
              [classes.oddComment]: index % 2
            })}
            shouldDisplayIsReadStatus={true}
            data={commentData}
            variant={ECommentDisplayingVariant.COMMENT_FOR_TRAINER}
            onClickItem={handleClickComment}
          />
        );
      }
    );
  };

  return (
    <section className={clsx(classes.root, { [classes.rootSticky]: matches })}>
      <CommentsHeader
        shouldDisplayUnreadCount={true}
        title="Comments"
        hasComments={Boolean(commentsList.length)}
        unreadCount={getUnreadCommentsCount(commentsList)}
        onMarkAllRead={handleMarkAllAsRead}
      />
      <section
        className={clsx(classes.list, { [classes.listSticky]: matches })}
      >
        {isCommentsFetching && <Loader className={classes.loaderFetching} />}
        {renderComments()}
      </section>
    </section>
  );
};

export default Comments;
