import React from "react";

import { useExamLockerContext } from "components/ExamLockerContext/ExamLockerContext";
import ExamDetails from "./ExamDetails";
import { checkSelectingFieldsOneEntry } from "../helper";
import ExamFormBlock from "../ExamFormBlock";
import AddEntryButton from "../AddEntryButton";
import { addExamDetail, MAX_ENTRIES_AMOUNT } from "../options";
import { IExamDetail } from "../options";
import { useVetExamContext } from "../VetExamStateProvider/VetExamProvider";
import useStyles from "./styles";

const ADD_DETAILS_ENTRY = "Add Another Exam Details Entry";

const ExamDetailsList = () => {
  const classes = useStyles();
  const { lockExam } = useExamLockerContext();
  const {
    state: { examDetails },
    dispatch
  } = useVetExamContext();

  const handleAddClick = () => {
    dispatch(addExamDetail());
    lockExam();
  };

  const renderAddEntry = () => {
    const isDisabled = !examDetails.every(checkSelectingFieldsOneEntry);

    return (
      <>
        {examDetails.length < MAX_ENTRIES_AMOUNT && (
          <AddEntryButton
            title={ADD_DETAILS_ENTRY}
            onClick={handleAddClick}
            disabled={isDisabled}
          />
        )}
      </>
    );
  };

  const renderEntries = () =>
    examDetails.map((item: IExamDetail, index: number) => (
      <ExamDetails key={item.id} examDetailsItem={item} index={index + 1} />
    ));

  return (
    <ExamFormBlock
      title="Exam Details"
      actionsComponent={renderAddEntry()}
      className={classes.root}
    >
      {renderEntries()}
    </ExamFormBlock>
  );
};

export default ExamDetailsList;
