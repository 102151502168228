import { v1 as uuid } from "uuid";
import { racehorse360 } from "@tsg/1st-grpc-web";
import {
  IButtonColors,
  IButtonGroup
} from "./ExamButtonsGroup/ExamButtonsGroup";

export interface IExamSelectOption {
  name: string;
  value: string | number;
}

export interface IExamJog {
  id: string;
  [EVetExamFields.JOG_SCORE]: string;
  [EVetExamFields.JOG_LIMB]: string;
  [ECustomFieldsValues.CUSTOM_JOG_LIMB]: string;
  [EVetExamFields.JOG_INFO]: string;
  [ECustomFieldsValues.CUSTOM_JOG_INFO]: string;
  [EVetExamFields.JOG_CONDITION]: string;
  [ECustomFieldsValues.CUSTOM_JOG_CONDITION]: string;
}

export interface IExamDetail {
  id: string;
  [EVetExamFields.DETAIL_LIMB]: string;
  [ECustomFieldsValues.CUSTOM_DETAIL_LIMB]: string;
  [EVetExamFields.DETAIL_STRUCTURE]: string;
  [ECustomFieldsValues.CUSTOM_DETAIL_STRUCTURE]: string;
  [EVetExamFields.DETAIL_LOCATION]: string;
  [ECustomFieldsValues.CUSTOM_DETAIL_LOCATION]: string;
  workoutExamDetailObservationEntries: IExamDetailObservation[];
}

export interface IExamDetailObservation {
  id?: string;
  [EVetExamFields.DETAIL_OBSERVATION]: string;
  [EVetExamFields.DETAIL_SEVERITY]: string;
  [EVetExamFields.DETAIL_NOTE]: string;
  [EVetExamFields.DETAIL_OBSERVATION]: string;
  [ECustomFieldsValues.CUSTOM_DETAIL_OBSERVATION]: string;
  [EVetExamFields.DETAIL_CONDITION]: string;
  [ECustomFieldsValues.CUSTOM_DETAIL_CONDITION]: string;
}

export interface IVetExamLocalState {
  id?: string;
  [EVetExamFields.REASON]: string;
  [EVetExamFields.RESULT]: string;
  [EVetExamFields.RISK_LEVEL]: string;
  [EVetExamFields.INTERNAL_NOTE]: string;
  [EVetExamFields.COMMENT]: string;
  examJogs: IExamJog[];
  examDetails: IExamDetail[];
}

export interface FormField {
  type: EFieldsTypes;
  key: EVetExamFields;
  title?: EFieldsTitles;
  custom?: string;
  optionsName?: EOptionsGroups;
}

export enum EVetExamFields {
  REASON = "reason",
  JOG_CONDITION = "jogConditionId",
  JOG_SCORE = "jogScoreId",
  JOG_INFO = "jogInfoId",
  JOG_LIMB = "jogLimbId",
  RESULT = "result",
  RISK_LEVEL = "riskLevel",
  INTERNAL_NOTE = "internalNote",
  COMMENT = "commentToTrainer",
  DETAIL_LIMB = "detailLimbId",
  DETAIL_LOCATION = "detailLocationId",
  DETAIL_STRUCTURE = "detailStructureId",
  DETAIL_OBSERVATION = "detailObservationId",
  DETAIL_SEVERITY = "detailSeverityId",
  DETAIL_CONDITION = "detailConditionId",
  DETAIL_NOTE = "notes",
  DETAILS_MEDIA = "media"
}

export enum ECustomFieldsValues {
  CUSTOM_JOG_LIMB = "customJogLimb",
  CUSTOM_JOG_INFO = "customJogInfo",
  CUSTOM_JOG_CONDITION = "customJogCondition",
  CUSTOM_DETAIL_LIMB = "customDetailLimb",
  CUSTOM_DETAIL_LOCATION = "customDetailLocation",
  CUSTOM_DETAIL_STRUCTURE = "customDetailStructure",
  CUSTOM_DETAIL_OBSERVATION = "customDetailObservation",
  CUSTOM_DETAIL_CONDITION = "customDetailCondition"
}

export enum EFieldsTypes {
  SELECT = "select",
  BUTTONS_GROUP = "buttonsGroup",
  TEXT = "text",
  MEDIA = "media"
}

export enum EFieldsTitles {
  LIMB = "Limb",
  JOG_SCORE = "Jog Score",
  JOG_INFO = "Jog Information",
  JOG_CONDITION = "Jog Condition",
  LOCATION = "Location",
  STRUCT = "Struct",
  OBSERVATION = "Observation",
  SEVERITY = "Severity",
  CONDITION = "Condition",
  CONDITION_NOTE = "Condition Note"
}

export enum EOptionsGroups {
  LIMBS = "limbs",
  DETAIL_LOCATIONS = "detailLocations",
  DETAILS_STRUCTURES = "detailStructures",
  DETAIL_OBSERVATIONS = "detailObservations",
  DETAILS_CONDITIONS = "detailConditions",
  DETAILS_SEVERITY = "detailSeverities",
  JOG_SCORES = "jogScores",
  JOG_INFOS = "jogInfos",
  JOG_CONDITIONS = "jogConditions"
}

export const FRONT_ID_PREFIX = "frontId-";
export const MAX_ENTRIES_AMOUNT = 25;

//Actions Types
export const CHANGE_EXAM_FIELD = "CHANGE_EXAM_FIELD";
export const RESET_EXAM = "RESET_EXAM";
export const SET_ENTIRE_EXAM = "SET_ENTIRE_EXAM";

export const ADD_EXAM_JOG = "ADD_EXAM_JOG";
export const DELETE_EXAM_JOG = "DELETE_EXAM_JOG";
export const CLEAR_EXAM_JOG = "CLEAR_EXAM_JOG";
export const CHANGE_EXAM_JOG_FIELD = "CHANGE_EXAM_JOG_FIELD";

export const ADD_EXAM_DETAIL = "ADD_EXAM_DETAIL";
export const DELETE_EXAM_DETAIL = "DELETE_EXAM_DETAIL";
export const CLEAR_EXAM_DETAIL = "CLEAR_EXAM_DETAIL";
export const CHANGE_EXAM_DETAIL_FIELD = "CHANGE_EXAM_DETAIL_FIELD";

export const ADD_EXAM_DETAIL_OBSERVATION = "ADD_EXAM_DETAIL_OBSERVATION";
export const DELETE_EXAM_DETAIL_OBSERVATION = "DELETE_EXAM_DETAIL_OBSERVATION";
export const CLEAR_EXAM_DETAIL_OBSERVATION = "CLEAR_EXAM_DETAIL_OBSERVATION";
export const CHANGE_EXAM_DETAIL_OBSERVATION_FIELD =
  "CHANGE_EXAM_DETAIL_OBSERVATION_FIELD";

export type TAction =
  | TChangeExamField
  | TResetExam
  | TSetEntireExam
  | TAddExamJog
  | TClearExamJog
  | TDeleteExamJog
  | TChangeExamJogField
  | TAddExamDetail
  | TClearExamDetail
  | TDeleteExamDetail
  | TChangeExamDetailField
  | TAddExamDetailObservation
  | TClearExamDetailObservation
  | TDeleteExamDetailObservation
  | TChangeExamDetailObservationField;

//Actions Creators types
type TChangeExamField = ReturnType<typeof changeExamField>;
type TResetExam = ReturnType<typeof resetExam>;
type TSetEntireExam = ReturnType<typeof setEntireExam>;

type TAddExamJog = ReturnType<typeof addExamJog>;
type TClearExamJog = ReturnType<typeof clearExamJog>;
type TDeleteExamJog = ReturnType<typeof deleteExamJog>;
type TChangeExamJogField = ReturnType<typeof changeExamJogField>;

type TAddExamDetail = ReturnType<typeof addExamDetail>;
type TClearExamDetail = ReturnType<typeof clearExamDetail>;
type TDeleteExamDetail = ReturnType<typeof deleteExamDetail>;
type TChangeExamDetailField = ReturnType<typeof changeExamDetailField>;

type TAddExamDetailObservation = ReturnType<typeof addExamDetailObservation>;
type TClearExamDetailObservation = ReturnType<
  typeof clearExamDetailObservation
>;
type TDeleteExamDetailObservation = ReturnType<
  typeof deleteExamDetailObservation
>;
type TChangeExamDetailObservationField = ReturnType<
  typeof changeExamDetailObservationField
>;

//Exam's Actions Creators
export const changeExamField = (value: string, fieldName: EVetExamFields) =>
  ({ type: CHANGE_EXAM_FIELD, value, fieldName } as const);
export const resetExam = () => ({ type: RESET_EXAM } as const);
export const setEntireExam = (newExam: IVetExamLocalState) =>
  ({ type: SET_ENTIRE_EXAM, newExam } as const);

//Jog's Actions Creators
export const addExamJog = () => ({ type: ADD_EXAM_JOG } as const);
export const clearExamJog = () => ({ type: CLEAR_EXAM_JOG } as const);
export const deleteExamJog = (jogId: string) =>
  ({ type: DELETE_EXAM_JOG, jogId } as const);
export const changeExamJogField = (
  jogId: string,
  fieldName: EVetExamFields,
  value: string,
  customFieldName?: string,
  customValue?: string
) =>
  ({
    type: CHANGE_EXAM_JOG_FIELD,
    jogId,
    fieldName,
    value,
    customValue,
    customFieldName
  } as const);

//Detail's Actions Creators
export const addExamDetail = () => ({ type: ADD_EXAM_DETAIL } as const);
export const clearExamDetail = () => ({ type: CLEAR_EXAM_DETAIL } as const);
export const deleteExamDetail = (detailId: string) =>
  ({ type: DELETE_EXAM_DETAIL, detailId } as const);
export const changeExamDetailField = (
  detailId: string,
  fieldName: EVetExamFields,
  value: string,
  customFieldName?: string,
  customValue?: string
) =>
  ({
    type: CHANGE_EXAM_DETAIL_FIELD,
    detailId,
    fieldName,
    value,
    customValue,
    customFieldName
  } as const);

//Observation's Actions Creators
export const addExamDetailObservation = (detailId: string) =>
  ({ type: ADD_EXAM_DETAIL_OBSERVATION, detailId } as const);
export const clearExamDetailObservation = (detailId: string) =>
  ({ type: CLEAR_EXAM_DETAIL_OBSERVATION, detailId } as const);
export const deleteExamDetailObservation = (
  detailId: string,
  observationId: string
) =>
  ({ type: DELETE_EXAM_DETAIL_OBSERVATION, detailId, observationId } as const);
export const changeExamDetailObservationField = (
  detailId: string,
  observationId: string,
  fieldName: EVetExamFields,
  value: string,
  customFieldName?: string,
  customValue?: string
) =>
  ({
    type: CHANGE_EXAM_DETAIL_OBSERVATION_FIELD,
    detailId,
    observationId,
    fieldName,
    value,
    customFieldName,
    customValue
  } as const);

export const createEmptyExamDetailObservation = (): IExamDetailObservation => ({
  id: `${FRONT_ID_PREFIX}${uuid()}`,
  [EVetExamFields.DETAIL_SEVERITY]: "",
  [EVetExamFields.DETAIL_NOTE]: "",
  [EVetExamFields.DETAIL_CONDITION]: "",
  [ECustomFieldsValues.CUSTOM_DETAIL_CONDITION]: "",
  [EVetExamFields.DETAIL_OBSERVATION]: "",
  [ECustomFieldsValues.CUSTOM_DETAIL_OBSERVATION]: ""
});

export const createEmptyExamJog = (): IExamJog => ({
  id: `${FRONT_ID_PREFIX}${uuid()}`,
  [EVetExamFields.JOG_SCORE]: "",
  [EVetExamFields.JOG_LIMB]: "",
  [ECustomFieldsValues.CUSTOM_JOG_LIMB]: "",
  [EVetExamFields.JOG_INFO]: "",
  [ECustomFieldsValues.CUSTOM_JOG_INFO]: "",
  [EVetExamFields.JOG_CONDITION]: "",
  [ECustomFieldsValues.CUSTOM_JOG_CONDITION]: ""
});

export const createEmptyExamDetail = (): IExamDetail => ({
  id: `${FRONT_ID_PREFIX}${uuid()}`,
  [EVetExamFields.DETAIL_LIMB]: "",
  [ECustomFieldsValues.CUSTOM_DETAIL_LIMB]: "",
  [EVetExamFields.DETAIL_STRUCTURE]: "",
  [ECustomFieldsValues.CUSTOM_DETAIL_STRUCTURE]: "",
  [EVetExamFields.DETAIL_LOCATION]: "",
  [ECustomFieldsValues.CUSTOM_DETAIL_LOCATION]: "",
  workoutExamDetailObservationEntries: [createEmptyExamDetailObservation()]
});

export const vetExamInitialState = {
  [EVetExamFields.REASON]: "",
  [EVetExamFields.RESULT]: "",
  [EVetExamFields.RISK_LEVEL]: "",
  [EVetExamFields.INTERNAL_NOTE]: "",
  [EVetExamFields.COMMENT]: "",
  examJogs: [createEmptyExamJog()],
  examDetails: [createEmptyExamDetail()]
};

// Exam Reasons block info
export const examReasons: IExamSelectOption[] = [
  {
    name: "Workout Request",
    value: String(
      racehorse360.WorkoutExamReason.WORKOUT_EXAM_REASON_WORKOUT_REQUEST
    )
  },
  {
    name: "Flagged Workout",
    value: String(
      racehorse360.WorkoutExamReason.WORKOUT_EXAM_REASON_FLAGGED_WORKOUT
    )
  },
  {
    name: "Trainer Requested",
    value: String(
      racehorse360.WorkoutExamReason.WORKOUT_EXAM_REASON_TRAINER_REQUESTED
    )
  }
];

export const examDetailsFormFields = [
  "id",
  "detailCondition",
  "customDetailCondition",
  "detailObservation",
  "customDetailObservation",
  "detailSeverity",
  "detailStructure",
  "customDetailStructure",
  "detailLimb",
  "customDetailLimb",
  "detailLocation",
  "customDetailLocation",
  "detailLasixCCs",
  "notes",
  "workoutExamId",
  "detailConditionId",
  "detailObservationId",
  "detailSeverityId",
  "detailStructureId",
  "detailLimbId",
  "detailLocationId",
  "detailLasixCCsId",
  "createdOn"
];

export const examJogsFormFields = [
  "id",
  "jogCondition",
  "jogConditionId",
  "customJogCondition",
  "jogScore",
  "jogScoreId",
  "jogInfo",
  "customJogInfo",
  "jogInfoId",
  "jogLimb",
  "customJogLimb",
  "jogLimbId",
  "createdOn",
  "workoutExamId"
];

export const buttonGroupColors: IButtonColors[] = [
  { activeColor: "#489B77" },
  { activeColor: "#4FA352" },
  { activeColor: "#82BE52" },
  { activeColor: "#BEBA49" },
  { activeColor: "#CD8848" },
  { activeColor: "#FF4A4E" }
];

// Exam Results block info
export const examResultsButtons: IButtonGroup[] = [
  {
    id: String(racehorse360.WorkoutExamResult.WORKOUT_EXAM_RESULT_PASSED),
    value: "Pass",
    activeColor: "#489B77",
    description: null,
    dataTestLabel: "exam-passed-button"
  },
  {
    id: String(racehorse360.WorkoutExamResult.WORKOUT_EXAM_RESULT_FAILED),
    value: "Fail",
    activeColor: "#FF4B4F",
    description: null,
    dataTestLabel: "exam-failed-button"
  }
];

export const jogInfoFields: FormField[] = [
  {
    title: EFieldsTitles.LIMB,
    type: EFieldsTypes.SELECT,
    key: EVetExamFields.JOG_LIMB,
    custom: ECustomFieldsValues.CUSTOM_JOG_LIMB,
    optionsName: EOptionsGroups.LIMBS
  },
  {
    title: EFieldsTitles.JOG_SCORE,
    type: EFieldsTypes.BUTTONS_GROUP,
    key: EVetExamFields.JOG_SCORE,
    optionsName: EOptionsGroups.JOG_SCORES
  },
  {
    title: EFieldsTitles.JOG_INFO,
    type: EFieldsTypes.SELECT,
    key: EVetExamFields.JOG_INFO,
    custom: ECustomFieldsValues.CUSTOM_JOG_INFO,
    optionsName: EOptionsGroups.JOG_INFOS
  },
  {
    title: EFieldsTitles.JOG_CONDITION,
    type: EFieldsTypes.SELECT,
    key: EVetExamFields.JOG_CONDITION,
    custom: ECustomFieldsValues.CUSTOM_JOG_CONDITION,
    optionsName: EOptionsGroups.JOG_CONDITIONS
  },
  {
    type: EFieldsTypes.MEDIA,
    key: EVetExamFields.DETAILS_MEDIA
  }
];

export const examDetailsFields: FormField[] = [
  {
    title: EFieldsTitles.LIMB,
    type: EFieldsTypes.SELECT,
    key: EVetExamFields.DETAIL_LIMB,
    custom: ECustomFieldsValues.CUSTOM_DETAIL_LIMB,
    optionsName: EOptionsGroups.LIMBS
  },
  {
    title: EFieldsTitles.LOCATION,
    type: EFieldsTypes.SELECT,
    key: EVetExamFields.DETAIL_LOCATION,
    custom: ECustomFieldsValues.CUSTOM_DETAIL_LOCATION,
    optionsName: EOptionsGroups.DETAIL_LOCATIONS
  },
  {
    title: EFieldsTitles.STRUCT,
    type: EFieldsTypes.SELECT,
    key: EVetExamFields.DETAIL_STRUCTURE,
    custom: ECustomFieldsValues.CUSTOM_DETAIL_STRUCTURE,
    optionsName: EOptionsGroups.DETAILS_STRUCTURES
  }
];

export const examDetailsObservationsFields: FormField[] = [
  {
    title: EFieldsTitles.OBSERVATION,
    type: EFieldsTypes.SELECT,
    key: EVetExamFields.DETAIL_OBSERVATION,
    custom: ECustomFieldsValues.CUSTOM_DETAIL_OBSERVATION,
    optionsName: EOptionsGroups.DETAIL_OBSERVATIONS
  },
  {
    title: EFieldsTitles.SEVERITY,
    type: EFieldsTypes.BUTTONS_GROUP,
    key: EVetExamFields.DETAIL_SEVERITY,
    optionsName: EOptionsGroups.DETAILS_SEVERITY
  },
  {
    title: EFieldsTitles.CONDITION,
    type: EFieldsTypes.SELECT,
    key: EVetExamFields.DETAIL_CONDITION,
    custom: ECustomFieldsValues.CUSTOM_DETAIL_CONDITION,
    optionsName: EOptionsGroups.DETAILS_CONDITIONS
  },
  {
    title: EFieldsTitles.CONDITION_NOTE,
    type: EFieldsTypes.TEXT,
    key: EVetExamFields.DETAIL_NOTE
  },
  {
    type: EFieldsTypes.MEDIA,
    key: EVetExamFields.DETAILS_MEDIA
  }
];
