import {
  UseMutationOptions,
  UseQueryResult,
  UseMutationResult,
  UseQueryOptions,
  useMutation,
  useQuery,
  useInfiniteQuery,
  UseInfiniteQueryOptions,
  UseInfiniteQueryResult,
  QueryKey,
  QueryFunction,
  QueryFunctionContext
} from "react-query";
import { useSelector } from "react-redux";
import { FirstApiError } from "./errors";
import store from "store";
import { pushSnackbar } from "store/actions/snackbars";
import { ESnackType } from "store/reducers/snackbars";
import { v1 as uuid } from "uuid";
import IQueriesConfigState from "interfaces/queriesConfigState";

// augment the react-query type to supply the 4th type argument to the options object
declare module "react-query" {
  function useInfiniteQuery<
    TQueryFnData = unknown,
    TError = unknown,
    TData = TQueryFnData,
    TQueryData = TQueryFnData
  >(
    queryKey: QueryKey,
    queryFn: QueryFunction<TQueryFnData>,
    options?: UseInfiniteQueryOptions<TQueryFnData, TError, TData, TQueryData>
  ): UseInfiniteQueryResult<TData, TError>;
}

type RawApiFn = (request: any) => any;
type RawApiClient = {
  [methodName: string]: RawApiFn;
};

interface GenericPagedApiPlaceHolder {
  pagingOptions: Record<string, unknown>;
}

// Generates a list of keys that match "list*" or "get*"
type IsQueryMethodKey<
  T,
  K extends keyof T = keyof T
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
> = K extends `pull${infer _end}`
  ? K
  : // eslint-disable-next-line @typescript-eslint/no-unused-vars
  K extends `list${infer _end}`
  ? K
  : // eslint-disable-next-line @typescript-eslint/no-unused-vars
  K extends `get${infer _end}`
  ? K
  : never;

// a list of all query functions
type QueryMethodKeys<T> = IsQueryMethodKey<T>;
// a list of all mutation function
type MutationMethodKeys<T> = Exclude<
  Extract<keyof T, string>,
  IsQueryMethodKey<T>
>;

type GetApiReturnTypeInterface<TMethod extends RawApiFn> = Partial<
  Omit<ThenArg<ReturnType<TMethod>>, "toJSON">
>;

export type ReactQueryHooks<
  TClient,
  TQueryMethodKeys extends Extract<
    keyof TClient,
    string
  > = QueryMethodKeys<TClient>,
  TMutationMethodKeys extends Extract<
    keyof TClient,
    string
  > = MutationMethodKeys<TClient>
> = {
  [TMethodName in TQueryMethodKeys as `use${Capitalize<TMethodName>}`]: TClient[TMethodName] extends RawApiFn
    ? ReactQueryApiQueryHook<
        Parameters<TClient[TMethodName]>[0],
        GetApiReturnTypeInterface<TClient[TMethodName]>
      >
    : never;
} &
  {
    [TMethodName in TQueryMethodKeys as `useInfinite${Capitalize<TMethodName>}`]: TClient[TMethodName] extends RawApiFn
      ? ReactQueryApiInfiniteQueryHook<
          TClient[TMethodName],
          Parameters<TClient[TMethodName]>[0],
          GetApiReturnTypeInterface<TClient[TMethodName]>,
          GetListMessageResourceType<
            TMethodName,
            GetApiReturnTypeInterface<TClient[TMethodName]>
          >
        >
      : never;
  } &
  {
    [TMethodName in TMutationMethodKeys as `use${Capitalize<TMethodName>}`]: TClient[TMethodName] extends RawApiFn
      ? ReactQueryApiMutationHook<
          Parameters<TClient[TMethodName]>[0],
          GetApiReturnTypeInterface<TClient[TMethodName]>
        >
      : never;
  };

type ThenArg<T> = T extends PromiseLike<infer U> ? U : T;
type ArrayItemType<T> = Exclude<
  T extends (infer U)[] ? U : T,
  undefined | null
>;

// Input: A method name like "listHorses"
// Output: The entity name as a type, e.g. "horses"
type GetEntityNameFromListMethodName<TMethodName, TListMessage> =
  TMethodName extends `list${infer TEntityName}`
    ? Lowercase<TEntityName> & keyof TListMessage
    : void;

type GetListMessageResourceType<
  TMethodName,
  TListMessage,
  TPropertyName extends keyof TListMessage = Exclude<
    GetEntityNameFromListMethodName<TMethodName, TListMessage>,
    void
  >
> = ArrayItemType<TListMessage[TPropertyName]>;

export interface ReactQueryApiInfiniteQueryHook<
  TMethodName,
  TRequest,
  TResult,
  TEntity
> {
  (
    request: TRequest,
    queryConfig?: UseInfiniteQueryOptions<
      TRequest,
      FirstApiError,
      TEntity,
      TResult
    >
  ): UseInfiniteQueryResult<TEntity, FirstApiError>;
  (
    key: string,
    request: TRequest,
    queryConfig?: UseInfiniteQueryOptions<
      TRequest,
      FirstApiError,
      TEntity,
      TResult
    >
  ): UseInfiniteQueryResult<TEntity, FirstApiError>;
}

export interface ReactQueryApiQueryHook<TRequest, TResult> {
  (
    request: TRequest,
    queryConfig?: UseQueryOptions<TResult, FirstApiError>
  ): UseQueryResult<TResult, FirstApiError>;
  (
    key: string,
    request: TRequest,
    queryConfig?: UseQueryOptions<TResult, FirstApiError>
  ): UseQueryResult<TResult, FirstApiError>;
}

export type ReactQueryApiMutationHook<TRequest, TResult> = (
  config?: UseMutationOptions<TResult, FirstApiError, TRequest>
) => UseMutationResult<TResult, FirstApiError, TRequest>;

function capitalize(input: string): string {
  return input.charAt(0).toUpperCase() + input.slice(1);
}

function addFacilityIdsToRequest<TRequest>(
  methodName: string,
  request: TRequest
) {
  const QUERY = "query";

  const methodsNeedingFilterFacilityIds = ["listHorses"];

  const methodsNeedingFilterHorseFacilityIds = [
    "listNotifications",
    "listUpcomingRaceHorses",
    "listWorkouts",
    "listWorkoutRequests",
    "listHorseOnLists"
  ];

  const methodsNeedingHorseFacilityIds = [
    "pullTrainerHorsesWithMatchingRacesCount",
    "pullHorsesFromListsCount"
  ];

  if (methodsNeedingFilterFacilityIds.includes(methodName) && request[QUERY]) {
    const currentFacilityId = useSelector(
      (state: { queriesConfig: IQueriesConfigState }) =>
        state?.queriesConfig?.currentFacilityId
    );

    if (currentFacilityId && !request[QUERY].facilityIds) {
      request[QUERY].facilityIds = [currentFacilityId];
    }
  }

  if (
    methodsNeedingFilterHorseFacilityIds.includes(methodName) &&
    request[QUERY]
  ) {
    const currentFacilityId = useSelector(
      (state: { queriesConfig: IQueriesConfigState }) =>
        state?.queriesConfig?.currentFacilityId
    );

    if (currentFacilityId && !request[QUERY].horseFacilityIds) {
      request[QUERY].horseFacilityIds = [currentFacilityId];
    }
  }

  if (methodsNeedingHorseFacilityIds.includes(methodName)) {
    const currentFacilityId = useSelector(
      (state: { queriesConfig: IQueriesConfigState }) =>
        state?.queriesConfig?.currentFacilityId
    );

    if (currentFacilityId) {
      request["horseFacilityIds"] = [currentFacilityId];
    }
  }

  return request;
}

function makeReactQueryQuery<
  TClient extends RawApiClient,
  TMethodName extends keyof TClient,
  TRequest = Parameters<TClient[TMethodName]>[0],
  // eslint-disable-next-line @typescript-eslint/ban-types
  TResult extends object = ThenArg<ReturnType<TClient[TMethodName]>>
>(
  apiClient: TClient,
  methodName: TMethodName
): ReactQueryApiQueryHook<TRequest, TResult> {
  return ((keyOrRequest: any, requestOrConfig: any, maybeConfig?: any) => {
    const onError = (error: FirstApiError) => {
      if (error.code !== 2) {
        store.dispatch(
          pushSnackbar({
            id: uuid(),
            type: ESnackType.ERROR,
            timestamp: Date.now(),
            message: error.message
          })
        );
      }
      if (maybeConfig?.onError) {
        maybeConfig.onError(error);
      } else {
        requestOrConfig?.onError && requestOrConfig.onError(error);
      }
    };

    const userConfig =
      typeof keyOrRequest === "string" ? maybeConfig : requestOrConfig;
    const queryKey =
      typeof keyOrRequest === "string" ? keyOrRequest : methodName;
    const request =
      typeof keyOrRequest === "string" ? requestOrConfig : keyOrRequest;
    const finalQueryConfig = {
      ...userConfig,
      // replace onError with our own
      onError
    };

    const transformedRequest = addFacilityIdsToRequest(methodName, request);

    return useQuery<TResult, FirstApiError>(
      [queryKey, transformedRequest],
      ({ queryKey }) => apiClient[methodName](queryKey[1]),
      finalQueryConfig
    );
  }) as ReactQueryApiQueryHook<TRequest, TResult>;
}

function camelize(input: string) {
  return input
    .split("_")
    .map(s => s.charAt(0).toLowerCase() + s.slice(1))
    .join("");
}

function getEntityArrayFromMessage<TMessage, TEntity>(
  methodName: string,
  propertyName: string,
  message: TMessage
): TEntity[] {
  if (!(propertyName in message)) {
    throw new Error(
      `Unexpected API result result. The response to ${methodName} is expected to contain a property named ${propertyName} but none was found. Check spelling and pluralization.`
    );
  }

  return message[propertyName] as TEntity[];
}

function makeReactQueryInfiniteQuery<
  TClient extends RawApiClient,
  TMethodName extends keyof TClient,
  TRequest = Parameters<TClient[TMethodName]>[0],
  // eslint-disable-next-line @typescript-eslint/ban-types
  TResult extends object = ThenArg<ReturnType<TClient[TMethodName]>>,
  TEntity = GetListMessageResourceType<TMethodName, TResult>
>(
  apiClient: TClient,
  methodName: TMethodName
): ReactQueryApiInfiniteQueryHook<TMethodName, TRequest, TResult, TEntity> {
  type Options = UseInfiniteQueryOptions<
    TRequest,
    FirstApiError,
    TEntity,
    TResult
  >;
  // given "listHorses", get "horses"
  const propertyName = camelize(methodName.slice(4));

  return ((
    keyOrRequest: string | TRequest,
    requestOrOptions: TRequest | Options,
    maybeOptions?: Options
  ) => {
    const onError = (error: FirstApiError) => {
      if (error.code !== 2) {
        store.dispatch(
          pushSnackbar({
            id: uuid(),
            type: ESnackType.ERROR,
            timestamp: Date.now(),
            message: error.message
          })
        );
      }
      if (maybeOptions?.onError) {
        maybeOptions.onError(error);
      } else {
        "onError" in requestOrOptions && requestOrOptions.onError(error);
      }
    };

    const options =
      typeof keyOrRequest === "string" ? maybeOptions : requestOrOptions;
    const queryKey =
      typeof keyOrRequest === "string" ? keyOrRequest : `${methodName}Infinite`;
    const request =
      typeof keyOrRequest === "string" ? requestOrOptions : keyOrRequest;

    // merge the options with some default values and overrides.
    const mergedOptions: UseInfiniteQueryOptions<
      TRequest,
      FirstApiError,
      TEntity,
      TResult
    > = {
      getNextPageParam: (lastPage: any, allPages: any[]) =>
        lastPage.pagingInfo.nextPageToken?.length > 0
          ? lastPage.pagingInfo.nextPageToken
          : undefined, // 'hasNextPage' property depends on getNextPageParam value: true on any value other then undefined
      select: result => ({
        pages: result.pages.map(
          getEntityArrayFromMessage.bind(null, methodName, propertyName)
        ),
        pageParams: result.pageParams,
        totalResults: result.pages[0]
          ? result.pages[0]["pagingInfo"].totalResults
          : 0
      }),
      ...options,
      // replace onError with our own
      onError
    };

    const transformedRequest = addFacilityIdsToRequest(methodName, request);

    // finally
    return useInfiniteQuery<TRequest, FirstApiError, TEntity, TResult>(
      [queryKey, transformedRequest],
      // call the api method, but automatically supply the next page token
      ({
        queryKey,
        pageParam
      }: QueryFunctionContext<[string, GenericPagedApiPlaceHolder]>) =>
        apiClient[methodName]({
          ...queryKey[1],
          pagingOptions: {
            ...queryKey[1].pagingOptions,
            pageToken: pageParam
          }
        }),
      mergedOptions
    );
  }) as ReactQueryApiInfiniteQueryHook<TMethodName, TRequest, TResult, TEntity>;
}

function makeReactQueryMutation<
  TClient extends RawApiClient,
  TMethodName extends keyof TClient,
  TRequest = Parameters<TClient[TMethodName]>[0],
  // eslint-disable-next-line @typescript-eslint/ban-types
  TResult extends object = ThenArg<ReturnType<TClient[TMethodName]>>
>(
  apiClient: TClient,
  methodName: TMethodName
): ReactQueryApiMutationHook<TRequest, TResult> {
  return (config?: UseMutationOptions<TResult, FirstApiError, TRequest>) => {
    const onError = (error, variables, context) => {
      if (error.code !== 2) {
        store.dispatch(
          pushSnackbar({
            id: uuid(),
            type: ESnackType.ERROR,
            timestamp: Date.now(),
            message: error.message
          })
        );
      }
      config?.onError && config.onError(error, variables, context);
    };
    return useMutation<TResult, FirstApiError, TRequest>(
      apiClient[methodName] as any,
      {
        ...config,
        onError
      }
    );
  };
}

export function makeHooksForApiClient<TClient>(
  apiClient: TClient
): ReactQueryHooks<TClient> {
  const hooks = {};

  for (const method of Object.keys(apiClient) as Extract<
    keyof TClient,
    string
  >[]) {
    if (/(list|get|pull)[A-Z].*/.test(method)) {
      hooks[`use${capitalize(method)}`] = makeReactQueryQuery(
        apiClient as any,
        method
      );
      hooks[`useInfinite${capitalize(method)}`] = makeReactQueryInfiniteQuery(
        apiClient as any,
        method
      );
    } else {
      hooks[`use${capitalize(method)}`] = makeReactQueryMutation(
        apiClient as any,
        method
      );
    }
  }

  return hooks as ReactQueryHooks<TClient>;
}
