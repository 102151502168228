import React, { useState, useEffect } from "react";
import clsx from "clsx";

import { ClassNameMap } from "@material-ui/core/styles/withStyles";
import ButtonGroup from "@material-ui/core/ButtonGroup";

import { racehorse360 } from "@tsg/1st-grpc-web";

import ExamButtonsGroupItem from "./ExamButtonsGroupItem";
import { EVetExamFields } from "../options";
import Loader from "components/Loader";
import useStyles from "./styles";

export interface IButtonColors {
  activeColor?: string;
  titleColor?: string;
  borderColor?: string;
}

export interface IButtonGroup
  extends Omit<Required<racehorse360.IWorkoutExamJogScore>, "value">,
    IButtonColors {
  value: number | string;
  dataTestLabel?: string;
}

interface IProps {
  title?: string;
  selectedValue: string;
  onClickButton: (value: number | string, fieldName: EVetExamFields) => void;
  buttons: IButtonGroup[];
  isDataLoading?: boolean;
  classes?: Partial<ClassNameMap<"root" | "button" | "header" | "title">>;
  className?: string;
  buttonClassName?: string;
  name?: EVetExamFields | string;
  showInnerLoader?: boolean;
}

const ExamButtonsGroup = (props: IProps) => {
  const {
    className = "",
    classes,
    buttonClassName = "",
    name = "",
    buttons,
    title,
    selectedValue,
    onClickButton,
    showInnerLoader = false,
    isDataLoading
  } = props;
  const ownClasses = useStyles();

  const [isShowLoader, setIsShowLoader] = useState<boolean>(false);

  const isLoading = [showInnerLoader, isDataLoading, isShowLoader].every(
    Boolean
  );

  const handleClickButton = (
    event: React.MouseEvent<HTMLButtonElement, MouseEvent>
  ) => {
    showInnerLoader && setIsShowLoader(true);
    const { value, name } = event.currentTarget;

    onClickButton(
      selectedValue !== value ? value : null,
      name as EVetExamFields
    );
  };

  const renderButtons = () => {
    return buttons.map((item: IButtonGroup) => {
      const isActive = item.id === selectedValue;
      const buttonData = { ...item, isActive, name };

      return (
        <ExamButtonsGroupItem
          className={clsx(buttonClassName, classes?.button)}
          key={item.id}
          buttonData={buttonData}
          onClickButton={handleClickButton}
          data-test={item.dataTestLabel}
          disabled={isShowLoader || isDataLoading}
        />
      );
    });
  };

  const renderValuesHint = () => {
    if (buttons.every(button => button.description)) {
      if (buttons.length === 1) {
        return `${buttons[0].value} ${buttons[0].description}`;
      }

      if (buttons.length > 1) {
        const firstItem = buttons[0];
        const lastItem = buttons[buttons.length - 1];

        return `${firstItem.value} ${firstItem.description} - ${lastItem.value} ${lastItem.description}`;
      }
    }
  };

  useEffect(() => {
    showInnerLoader && setIsShowLoader(false);
  }, [selectedValue]);

  return (
    <div className={clsx(className, classes?.root)}>
      {title && (
        <div className={clsx(ownClasses.header, classes?.header)}>
          <span className={clsx(ownClasses.title, classes?.title)}>
            {title}
            {isLoading && <Loader size={15} className={ownClasses.loader} />}
          </span>
          <span className={ownClasses.valuesHint}>{renderValuesHint()}</span>
        </div>
      )}
      <ButtonGroup fullWidth>{renderButtons()}</ButtonGroup>
    </div>
  );
};

export default ExamButtonsGroup;
