import React, { useState } from "react";
import clsx from "clsx";
import format from "date-fns/format";

import Button from "@material-ui/core/Button";
import CloseIcon from "@material-ui/icons/Close";
import Dialog from "@material-ui/core/Dialog";
import DialogContent from "@material-ui/core/DialogContent";
import DialogActions from "@material-ui/core/DialogActions";
import DialogTitle from "@material-ui/core/DialogTitle";
import IconButton from "@material-ui/core/IconButton";

import { racehorse360 } from "@tsg/1st-grpc-web";

import Loader from "components/Loader";
import Textarea from "components/Textarea";
import { useRacehorse360Api } from "hooks/api";
import { transformViewDate } from "utils/date-utils";
import { getPersonFullName } from "utils/person";

import useStyles from "./styles";

interface IProps {
  workoutRequest: racehorse360.IWorkoutRequest;
  readOnly: boolean;
  refetchWorkouts: (value?: boolean) => Promise<void>;
}

const ExamReasonNote = (props: IProps) => {
  const { workoutRequest, readOnly, refetchWorkouts } = props;
  const classes = useStyles();

  const [open, setOpen] = useState<boolean>(false);
  const [note, setNote] = useState<string>(
    workoutRequest.examReasonNote?.body || ""
  );

  const isNoteExists = workoutRequest.examReasonNote?.id;

  const {
    useCreateWorkoutRequestComment,
    useDeleteWorkoutRequestComment,
    useUpdateWorkoutRequestComment
  } = useRacehorse360Api();

  const onSuccess = async () => {
    await refetchWorkouts(true);
    onClose();
  };

  const {
    mutateAsync: createWorkoutRequestComment,
    isLoading: isCreateWorkoutRequestCommentLoading
  } = useCreateWorkoutRequestComment({ onSuccess });

  const {
    mutateAsync: deleteWorkoutRequestComment,
    isLoading: isDeleteWorkoutRequestCommentLoading
  } = useDeleteWorkoutRequestComment({ onSuccess });

  const {
    mutateAsync: updateWorkoutRequestComment,
    isLoading: isUpdateWorkoutRequestCommentLoading
  } = useUpdateWorkoutRequestComment({ onSuccess });

  const isLoading =
    isCreateWorkoutRequestCommentLoading ||
    isDeleteWorkoutRequestCommentLoading ||
    isUpdateWorkoutRequestCommentLoading;

  const onOpen = e => {
    e.preventDefault();
    setOpen(true);
  };

  const onClose = () => {
    setOpen(false);
    setNote("");
  };

  const handleNoteChange = value => {
    setNote(value);
  };

  const handleSave = () => {
    if (isNoteExists) {
      updateWorkoutRequestComment({
        workoutRequestComment: { ...workoutRequest.examReasonNote, body: note },
        updateMask: {
          paths: ["body"]
        },
        getOptions: {
          select: ["id", "body", "comment_type"]
        }
      });
    } else {
      createWorkoutRequestComment({
        workoutRequestId: workoutRequest.id,
        body: note.trim(),
        getOptions: {
          select: ["id", "body", "comment_type"]
        }
      });
    }
  };

  const handleDelete = () => {
    deleteWorkoutRequestComment({ id: workoutRequest.examReasonNote.id });
  };

  return (
    <div className={classes.examReasonNote}>
      {readOnly && isNoteExists && (
        <span className={clsx(classes.link, classes.linkReadOnly)}>
          Exam Reason Note
        </span>
      )}
      {!readOnly && (
        <a className={classes.link} onClick={onOpen}>
          {isNoteExists ? "Exam Reason Note" : "Add Exam Reason Note"}
        </a>
      )}
      {isNoteExists && (
        <>
          <span className={classes.date}>
            <span className={classes.dateDash}>{" - "}</span>
            {getPersonFullName(
              workoutRequest.examReasonNote.updatedBy,
              "%f %L"
            )}{" "}
            {transformViewDate(
              workoutRequest.examReasonNote.updatedOn,
              undefined,
              "MM/dd/yyyy - hh:mm aa"
            )}
          </span>
          <p className={classes.body}>{workoutRequest.examReasonNote.body}</p>
        </>
      )}
      {!readOnly && (
        <Dialog
          classes={{
            paper: classes.dialogPaper
          }}
          open={open}
        >
          {isLoading && <Loader overlay />}
          <DialogTitle className={classes.dialogTitle}>
            Exam Reason Note
            <IconButton
              color="primary"
              onClick={onClose}
              size={"small"}
              classes={{
                root: classes.dialogCloseButton
              }}
            >
              <CloseIcon className={classes.dialogCloseButtonIcon} />
            </IconButton>
          </DialogTitle>
          <DialogContent className={classes.dialogContent}>
            <b className={classes.horseName}>
              {workoutRequest.horse?.name.toUpperCase()}
            </b>
            <span className={classes.trainerName}>
              {getPersonFullName(workoutRequest.horse?.trainer)}
            </span>
            <Textarea
              className={classes.textarea}
              maxLength={90}
              label={format(new Date(), "MM/dd-yyyy - hh:mm aa")}
              placeholder={"Add Note"}
              rows={7}
              value={note}
              defaultValue={workoutRequest.examReasonNote?.body}
              onChange={handleNoteChange}
            />
          </DialogContent>
          <DialogActions className={classes.dialogActions}>
            {workoutRequest.examReasonNote?.id && (
              <Button className={classes.buttonDelete} onClick={handleDelete}>
                DELETE
              </Button>
            )}
            <div>
              <Button className={classes.buttonCancel} onClick={onClose}>
                CANCEL
              </Button>
              <Button
                className={classes.buttonSave}
                disabled={!note.trim().length}
                onClick={handleSave}
              >
                SAVE
              </Button>
            </div>
          </DialogActions>
        </Dialog>
      )}
    </div>
  );
};

export default ExamReasonNote;
