import React, { useEffect, useRef, useState } from "react";
import { Theme } from "@material-ui/core/styles";
import clsx from "clsx";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import Dialog from "@material-ui/core/Dialog";
import DialogContent from "@material-ui/core/DialogContent";
import ArrowDropDownIcon from "@material-ui/icons/ArrowDropDown";
import Button from "@material-ui/core/Button";
import useStyles from "./styles";

interface Props {
  children?: React.ReactNode;
  maxWidth?: string;
  openButtonText?: string;
  openButtonClassName?: string;
  dialogWidth: number;
  dialogClassName?: string;
  dialogContentClassName?: string;
  open?: boolean;
  onOpen?: () => void;
  onClose?: () => void;
}

interface Coordinates {
  top: number;
  left: number | string;
  right: number | string;
}

const DropDownDialog = React.memo((props: Props) => {
  const {
    children,
    dialogWidth,
    openButtonText,
    openButtonClassName,
    dialogClassName,
    dialogContentClassName,
    onOpen,
    onClose
  } = props;
  const classes = useStyles();

  const [open, setOpen] = useState<boolean>(!!props.open);

  const [coordinates, setCoordinates] = useState<Coordinates>({
    top: 0,
    left: 0,
    right: 0
  });

  const isScreenXL = useMediaQuery((theme: Theme) =>
    theme.breakpoints.up("xl")
  );
  const openButtonRef = useRef(null);

  const handleOpen = () => {
    setOpen(true);

    if (onOpen) {
      onOpen();
    }
  };

  const handleClose = () => {
    setOpen(false);

    if (onClose) {
      onClose();
    }
  };

  useEffect(() => {
    setOpen(!!props.open);
  }, [props.open]);

  useEffect(() => {
    const { top, left, right, height } =
      openButtonRef.current.getBoundingClientRect();
    const offsetTop = top + height;
    const offsetLeft = left + dialogWidth < window.innerWidth ? left : "auto";
    const offsetRight =
      left + dialogWidth > window.innerWidth
        ? window.innerWidth - right
        : "auto";

    setCoordinates({ top: offsetTop, left: offsetLeft, right: offsetRight });
  }, [isScreenXL, dialogWidth]);

  return (
    <>
      <Button
        ref={openButtonRef}
        className={clsx(classes.openButton, openButtonClassName)}
        onClick={handleOpen}
        data-test={"date-range-button"}
      >
        {openButtonText}
        <ArrowDropDownIcon
          className={clsx(classes.openButtonIcon, {
            [classes.openButtonIconOpened]: open
          })}
        />
      </Button>

      <Dialog
        className={classes.dialog}
        open={open}
        onClose={handleClose}
        classes={{
          paper: clsx(classes.dialogPaper, dialogClassName)
        }}
        PaperProps={{
          style: {
            top: isScreenXL ? coordinates.top : "auto",
            left: isScreenXL ? coordinates.left : "auto",
            right: isScreenXL ? coordinates.right : "auto"
          }
        }}
        hideBackdrop={isScreenXL}
      >
        <DialogContent
          className={clsx(classes.dialogContent, dialogContentClassName)}
        >
          {children}
        </DialogContent>
      </Dialog>
    </>
  );
});

export default DropDownDialog;
