import { racehorse360 } from "@tsg/1st-grpc-web";
import ITab from "./Tab";
import routes from "common/routes";
import { WorkoutsTableTab } from "../pages/WorkoutsPage/Vet/helper";

export const tabs: VetWorkoutsTab[] = [
  {
    name: "Requests",
    value: WorkoutsTableTab.REQUESTS,
    requestStatuses: [
      racehorse360.WorkoutRequestStatus.WORKOUT_REQUEST_STATUS_REQUESTED
    ],
    path: routes.workoutRequests.path
  },
  {
    name: "Exams",
    value: WorkoutsTableTab.EXAMS,
    path: routes.workoutExams.path,
    examStatuses: [
      racehorse360.WorkoutExamStatus.WORKOUT_EXAM_STATUS_REQUESTED,
      racehorse360.WorkoutExamStatus.WORKOUT_EXAM_STATUS_IN_PROGRESS
    ]
  },
  {
    name: "Pending",
    value: WorkoutsTableTab.PENDING,
    examStatuses: [racehorse360.WorkoutExamStatus.WORKOUT_EXAM_STATUS_PENDING],
    results: [racehorse360.WorkoutExamResult.WORKOUT_EXAM_RESULT_PASSED],
    path: routes.workoutPending.path
  },
  {
    name: "Passed",
    value: WorkoutsTableTab.PASSED,
    examStatuses: [
      racehorse360.WorkoutExamStatus.WORKOUT_EXAM_STATUS_COMPLETED
    ],
    results: [racehorse360.WorkoutExamResult.WORKOUT_EXAM_RESULT_PASSED],
    path: routes.workoutPassed.path
  },
  {
    name: "Failed",
    value: WorkoutsTableTab.FAILED,
    examStatuses: [
      racehorse360.WorkoutExamStatus.WORKOUT_EXAM_STATUS_COMPLETED
    ],
    results: [racehorse360.WorkoutExamResult.WORKOUT_EXAM_RESULT_FAILED],
    path: routes.workoutFailed.path
  }
];
export interface VetWorkoutsTab extends ITab<WorkoutsTableTab> {
  requestStatuses?: racehorse360.WorkoutRequestStatus[];
  examStatuses?: racehorse360.WorkoutExamStatus[];
  results?: racehorse360.WorkoutExamResult[];
}
