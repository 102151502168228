import { racehorse360 } from "@tsg/1st-grpc-web";
import n2f from "num2fraction";

import { parseDistanceUnit } from "./enum-parser";

export const SHORT_DISTANCE_THRESHOLD_IN_FURLONGS = 800;

export const parseDistanceToFurlongs = (distanceStr: string = ""): number => {
  const multiplier = distanceStr.includes("M") ? 8 : 1;
  return (
    multiplier *
    distanceStr
      .replace(/\s?([A-Z-])+/, "")
      .split(" ")
      .reduce((acc: number, cur: string) => {
        return acc + Number(cur ? eval(cur) : 0);
      }, 0)
  );
};

export const convertDistanceToFurlongs = (
  distance: number,
  distanceUnit: racehorse360.DistanceUnit
): number => {
  if (distanceUnit === racehorse360.DistanceUnit.DISTANCE_UNIT_METER)
    return Math.round((distance / 200) * 100);
  else if (distanceUnit === racehorse360.DistanceUnit.DISTANCE_UNIT_YARD)
    return Math.round((distance / 220) * 100);
  else if (distanceUnit === racehorse360.DistanceUnit.DISTANCE_UNIT_FURLONG)
    return distance;
};

export const getRaceDistanceFract = (distance: number): string => {
  if (distance < SHORT_DISTANCE_THRESHOLD_IN_FURLONGS) {
    if (distance % 100 === 50) {
      return "1/2";
    }
  } else if (distance >= SHORT_DISTANCE_THRESHOLD_IN_FURLONGS) {
    if (distance === 818) {
      return "40";
    } else if (distance === 832) {
      return "70";
    } else if (distance % SHORT_DISTANCE_THRESHOLD_IN_FURLONGS > 10) {
      return n2f(
        (distance % SHORT_DISTANCE_THRESHOLD_IN_FURLONGS) /
          SHORT_DISTANCE_THRESHOLD_IN_FURLONGS
      );
    }
  }
};

export const getLengthFract = (length: number): string => {
  const int = Math.trunc(length / 100);
  const frac = length % 100;

  let fracStr = null;
  switch (Math.round(frac / 25)) {
    case 3:
      fracStr = "3/4";
      break;
    case 2:
      fracStr = "1/2";
      break;
    case 1:
      fracStr = int ? "1/4" : "nk";
      break;
  }

  if (!int && !fracStr) {
    switch (Math.round(frac / 5)) {
      case 2:
        fracStr = "hd";
        break;
      case 1:
        fracStr = "ns";
        break;
    }
  }

  return fracStr;
};

export const getRaceDistanceUnitDesignation = (
  distance: number,
  unit: racehorse360.DistanceUnit
): string => {
  if (unit === racehorse360.DistanceUnit.DISTANCE_UNIT_FURLONG) {
    return distance < SHORT_DISTANCE_THRESHOLD_IN_FURLONGS ? "f" : "";
  }

  return parseDistanceUnit(unit);
};
