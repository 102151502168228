import React, { ChangeEvent } from "react";
import clsx from "clsx";

import Accordion from "@material-ui/core/Accordion";
import Radio from "@material-ui/core/Radio";

import FilterAccordionDetails from "../FilterAccordionDetails";
import FilterAccordionSummary from "../FilterAccordionSummary";
import useStyles from "./styles";

export interface IRadioButtonItem {
  label: string;
  value: number;
}

interface IProps {
  data: IRadioButtonItem[];
  selectedValue: number;
  onChangeSelectedValue: (value: number) => void;
  title: string;
}

const RadioButtonsFilter = (props: IProps) => {
  const { selectedValue = 0, onChangeSelectedValue, title, data = [] } = props;
  const classes = useStyles();

  const handleChangeValue = (event: ChangeEvent<HTMLInputElement>) => {
    const newValue = Number(event.currentTarget.value);

    onChangeSelectedValue(newValue);
  };

  const renderPreviewItems = () => {
    if (!selectedValue) {
      return [];
    }

    const currentRange = data.find(item => item.value === selectedValue);

    return [currentRange?.label];
  };

  const renderDetailsContent = () => {
    return data.map((range: IRadioButtonItem) => {
      return (
        <label
          key={`${range.label}-${range.value}`}
          className={clsx(classes.item, {
            ["active"]: range.value === selectedValue
          })}
        >
          <span className={classes.label}>{range.label}</span>
          <Radio
            className={classes.radio}
            checked={range.value === selectedValue}
            value={range.value}
            name={range.label}
            onChange={handleChangeValue}
          />
        </label>
      );
    });
  };

  return (
    <Accordion>
      <FilterAccordionSummary
        title={title}
        previewItems={renderPreviewItems()}
      />
      <FilterAccordionDetails>{renderDetailsContent()}</FilterAccordionDetails>
    </Accordion>
  );
};

export default RadioButtonsFilter;
