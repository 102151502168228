import React from "react";
import clsx from "clsx";

import Button from "@material-ui/core/Button";
import IconButton from "@material-ui/core/IconButton";
import { Theme } from "@material-ui/core/styles";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import InfoOutlinedIcon from "@material-ui/icons/InfoOutlined";
import Refresh from "@material-ui/icons/Refresh";

import { racehorse360 } from "@tsg/1st-grpc-web";

import Breakpoints from "common/breakpoints";
import WarningMessageDialog from "pages/HorseDetailsPage/Vet/Header/WarningMessageDialog";
import useStyles from "./styles";

interface IProps {
  horse: racehorse360.IHorse;
  isShowHorseInfoDesktop: boolean;
  isActiveReset: boolean;
  onOpenHorseInfoMobile: () => void;
  onOpenResetModal: () => void;
  warningMessage: racehorse360.IWarningMessage;
  isPullHorseWarningMessageFetching: boolean;
  refetchHorseWarningMessage: () => void;
}

const ExamFormHeader = (props: IProps) => {
  const {
    horse,
    isShowHorseInfoDesktop,
    onOpenHorseInfoMobile,
    onOpenResetModal,
    isActiveReset,
    warningMessage,
    isPullHorseWarningMessageFetching,
    refetchHorseWarningMessage
  } = props;
  const classes = useStyles();
  const matchesDownSM600 = useMediaQuery((theme: Theme) =>
    theme.breakpoints.down(Breakpoints.SM_600)
  );

  const renderRightExamHeaderSide = () => {
    return matchesDownSM600 ? (
      <IconButton
        className={classes.infoButton}
        onClick={onOpenHorseInfoMobile}
      >
        <InfoOutlinedIcon />
      </IconButton>
    ) : (
      <Button
        color="primary"
        className={classes.resetButton}
        startIcon={<Refresh />}
        onClick={onOpenResetModal}
        disabled={!isActiveReset}
        data-test={"reset-exam-button"}
      >
        Reset Exam
      </Button>
    );
  };

  return (
    <div
      className={clsx(classes.header, classes.examFormHeader, {
        ["hideRightPanel"]: isShowHorseInfoDesktop
      })}
    >
      <div className={classes.horseData}>
        {Boolean(horse) && (
          <WarningMessageDialog
            horse={horse}
            warningMessage={warningMessage}
            isPullHorseWarningMessageFetching={
              isPullHorseWarningMessageFetching
            }
            refetchHorseWarningMessage={refetchHorseWarningMessage}
          />
        )}
        <span className={classes.horseName}>{horse?.name || "Horse Name"}</span>
      </div>
      {renderRightExamHeaderSide()}
    </div>
  );
};

export default ExamFormHeader;
