import React, { useState } from "react";
import { useHistory } from "react-router-dom";

import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import Button from "@material-ui/core/Button";

import AppPage from "components/AppPage";
import AppPageHeader from "components/AppPageHeader";
import AppPageContent from "components/AppPageContent";
import AppSearch from "components/AppSearch";
import SearchNoResults from "components/SearchNoResults";
import Loader from "components/Loader/Loader";
import ErrorBoundary from "components/ErrorBoundary";
import HorseList from "./HorseList";
import { racehorse360 } from "@tsg/1st-grpc-web";
import useStyles from "./styles";
import { AppSearchType } from "components/AppSearch/AppSearch";
import { useLoggedInUser } from "components/LoggedInUserProvider";

export interface Props {
  isLoading: boolean;
  horses: racehorse360.IHorse[];
  activeHorseId: string;
  onSearchChange: (text: string) => void;
  horseName: string;
  isMoreLoading: boolean;
  tableBodyRef: React.RefObject<HTMLDivElement>;
}

const TrainerHorseList = (props: Props) => {
  const {
    activeHorseId,
    horses,
    onSearchChange,
    isLoading,
    horseName,
    isMoreLoading,
    tableBodyRef
  } = props;

  const classes = useStyles();
  const [clearSearch, setClearSearch] = useState<boolean>(false);

  const history = useHistory();
  const { currentUser } = useLoggedInUser();

  const handleCancelClick = () => {
    history.goBack();
  };

  const handleAppSearch = (value: string) => {
    onSearchChange(value);
  };

  const handleResetSearch = () => {
    onSearchChange("");
    setClearSearch(true);
  };

  return (
    <AppPage>
      <AppPageHeader className={classes.appPageHeader}>
        <Grid container className={classes.topBar}>
          <Grid item xs={12} sm={12} md={4}>
            <Typography className={classes.topBarTitle}>
              Select Horse
            </Typography>
          </Grid>

          <Grid item xs={12} sm={8} md={4}>
            <ErrorBoundary>
              <AppSearch
                isForceSearch
                placeholder={
                  currentUser.isTrainer
                    ? `Search Horses`
                    : `Search Horses and Trainers`
                }
                onSearch={handleAppSearch}
                searchType={
                  currentUser.isTrainer
                    ? AppSearchType.Horses
                    : AppSearchType.Both
                }
                clearSearch={clearSearch}
                onClearSearch={setClearSearch}
              />
            </ErrorBoundary>
          </Grid>

          <Grid
            item
            xs={12}
            sm={4}
            md={4}
            className={classes.cancelButtonWrapper}
          >
            {Boolean(activeHorseId) && (
              <Button
                className={classes.cancelButton}
                onClick={handleCancelClick}
              >
                CANCEL
              </Button>
            )}
          </Grid>
        </Grid>
      </AppPageHeader>
      {isLoading ? (
        <Loader />
      ) : (
        <AppPageContent className={classes.appPageContent}>
          <ErrorBoundary>
            {horses?.length ? (
              <HorseList
                activeHorseId={activeHorseId}
                horses={horses}
                tableBodyRef={tableBodyRef}
              />
            ) : (
              <SearchNoResults value={horseName} onClick={handleResetSearch} />
            )}
          </ErrorBoundary>
          {isMoreLoading && <Loader infinite />}
        </AppPageContent>
      )}
    </AppPage>
  );
};

export default TrainerHorseList;
