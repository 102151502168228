import React from "react";

import { racehorse360 } from "@tsg/1st-grpc-web";

import { transformViewDate } from "utils/date-utils";
import { getPersonFullName } from "utils/person";
import LastExamNotesModal from "./LastExamNotesModal";
import useStyles from "./styles";

const LAST_EXAM_NOTE_LIMIT = 90;
const ABC_LIST = "ABC List";
const LAST_EXAM_NOTES_HISTORY_MIN = 1;

interface IProps {
  horse: racehorse360.IHorse;
}

const LastExamNote = (props: IProps) => {
  const { horse } = props;
  const classes = useStyles();
  const shouldHistoryShow =
    horse.completedWorkoutExamsWithNoteCount > LAST_EXAM_NOTES_HISTORY_MIN;
  const note = horse.lastCompletedWorkoutExamWithNote.note;

  const noteContent =
    note.length > LAST_EXAM_NOTE_LIMIT
      ? `${note.slice(0, LAST_EXAM_NOTE_LIMIT)}...`
      : note;

  const noteDate = transformViewDate(
    horse.lastCompletedWorkoutExamWithNote.completedDate,
    null,
    "MM/dd/yy h:mm a"
  );

  const getAssignee = () =>
    horse.lastCompletedWorkoutExamWithNote.assignedUser
      ? getPersonFullName(
          horse.lastCompletedWorkoutExamWithNote.assignedUser,
          "%f %m %L"
        )
      : ABC_LIST;

  return (
    <>
      {note && (
        <div className={classes.lastWorkoutExamNoteContainer}>
          <div className={classes.lastWorkoutExamNoteHeader}>
            {"Last Exam Note"}
            {shouldHistoryShow && <LastExamNotesModal horse={horse} />}
            <span className={classes.margin}>{getAssignee()}</span>
            <span className={classes.margin}>{noteDate}</span>
          </div>
          <div>{noteContent}</div>
        </div>
      )}
    </>
  );
};

export default LastExamNote;
