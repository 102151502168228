import React from "react";
import clsx from "clsx";

import DialogActions from "@material-ui/core/DialogActions";
import Button from "@material-ui/core/Button";

import useStyles from "./styles";

interface IProps {
  disabledResetButton: boolean;
  disabledApplyButton: boolean;
  onResetFilters: () => void;
  onApplyFilters: () => void;
}

const FiltersDialogActions = (props: IProps) => {
  const {
    disabledApplyButton,
    disabledResetButton,
    onResetFilters,
    onApplyFilters
  } = props;
  const classes = useStyles();

  return (
    <DialogActions className={classes.actions}>
      <Button
        className={classes.actionButton}
        variant="outlined"
        onClick={onResetFilters}
        disabled={disabledResetButton}
        title="reset"
      >
        RESET FILTERS
      </Button>
      <Button
        className={clsx(classes.actionButton, classes.applyButton)}
        variant="contained"
        onClick={onApplyFilters}
        disabled={disabledApplyButton}
        title="apply"
      >
        APPLY FILTERS
      </Button>
    </DialogActions>
  );
};

export default FiltersDialogActions;
