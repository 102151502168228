import { racehorse360 } from "@tsg/1st-grpc-web";
import { DateRangeISO } from "interfaces/DateRange";
import { VetWorkoutsTab } from "interfaces/VetWorkoutsTab";
import { SortOrderExtended } from "interfaces/SortOrder";
import { CustomWorkoutRequest } from "interfaces/VetWorkoutsPageState";

const base = "VET_WORKOUTS_PAGE";

export const SET_SEARCH_VALUE = `${base}_SET_SEARCH_VALUE`;
export const SET_ASSIGNED_ONLY = `${base}_SET_ASSIGNED_ONLY`;
export const SET_SELECTED_DATE_RANGE = `${base}_SET_SELECTED_DATE_RANGE`;
export const SET_SELECTED_FACILITY = `${base}_SET_SELECTED_FACILITY`;
export const SET_SELECTED_TAB = `${base}_SET_SELECTED_TAB`;
export const SET_PAGE_NUMBER = `${base}_SET_PAGE_NUMBER`;
export const SET_ORDER = `${base}_SET_ORDER`;
export const SET_ORDER_BY = `${base}_SET_ORDER_BY`;
export const SET_SELECTED_WORKOUT_REQUEST = `${base}_SET_SELECTED_WORKOUT_REQUEST`;
export const SET_EXAM_CHECKED_IDS_BY = `${base}_SET_EXAM_CHECKED_IDS_BY`;
export const SET_PASS_CHECKED_IDS_BY = `${base}_SET_PASS_CHECKED_IDS_BY`;
export const SET_ASSIGN_CHECKED_IDS_BY = `${base}_SET_ASSIGN_CHECKED_IDS_BY`;
export const SET_SAVING_CHECKED_IDS_BY = `${base}_SET_SAVING_CHECKED_IDS_BY`;
export const SET_SAVING_UNDO_QUICK_PASS_IDS_BY = `${base}_SET_SAVING_UNDO_QUICK_PASS_IDS_BY`;
export const SET_SAVING_UNASSIGN_IDS_BY = `${base}_SET_SAVING_UNASSIGN_IDS_BY`;
export const SET_COMMENTS_POPOVER_ANCHOR_ELEMENT = `${base}_SET_COMMENTS_POPOVER_ANCHOR_ELEMENT`;
export const SET_IS_FOT_COMMENT_DISPLAYED = `${base}_SET_IS_FOT_COMMENT_DISPLAYED`;

export interface VetWorkoutsPageType {
  type: string;
  searchValue?: string;
  selectedFacility: racehorse360.IFacility;
  assignedOnly: boolean;
  selectedDateRange: DateRangeISO;
  selectedTab: VetWorkoutsTab;
  pageNumber: number;
  order: SortOrderExtended;
  orderBy: string;
  selectedWorkoutRequest: CustomWorkoutRequest;
  examCheckedIds: string[];
  passCheckedIds: string[];
  assignCheckedIds: string[];
  savingCheckedIds: string[];
  savingUndoQuickPassIds: string[];
  savingUnAssignIds: string[];
  commentsPopoverAnchorEl: HTMLElement;
  isFOTCommentDisplayed: boolean;
}

export const setSearchQuery = (
  searchValue: string
): Partial<VetWorkoutsPageType> => {
  return {
    type: SET_SEARCH_VALUE,
    searchValue
  };
};

export const setAssignedOnly = (
  assignedOnly: boolean
): Partial<VetWorkoutsPageType> => {
  return {
    type: SET_ASSIGNED_ONLY,
    assignedOnly
  };
};

export const setSelectedDateRange = (
  selectedDateRange: DateRangeISO
): Partial<VetWorkoutsPageType> => {
  return {
    type: SET_SELECTED_DATE_RANGE,
    selectedDateRange
  };
};

export const setSelectedFacility = (
  selectedFacility: racehorse360.IFacility
): Partial<VetWorkoutsPageType> => {
  return {
    type: SET_SELECTED_FACILITY,
    selectedFacility
  };
};

export const setSelectedTab = (
  selectedTab: VetWorkoutsTab
): Partial<VetWorkoutsPageType> => {
  return {
    type: SET_SELECTED_TAB,
    selectedTab
  };
};

export const setPageNumber = (
  pageNumber: number
): Partial<VetWorkoutsPageType> => {
  return {
    type: SET_PAGE_NUMBER,
    pageNumber
  };
};

export const setOrder = (
  order: SortOrderExtended
): Partial<VetWorkoutsPageType> => {
  return {
    type: SET_ORDER,
    order
  };
};

export const setOrderBy = (orderBy: string): Partial<VetWorkoutsPageType> => {
  return {
    type: SET_ORDER_BY,
    orderBy
  };
};

export const setSelectedWorkoutRequest = (
  selectedWorkoutRequest: racehorse360.IWorkoutRequest
): Partial<VetWorkoutsPageType> => {
  return {
    type: SET_SELECTED_WORKOUT_REQUEST,
    selectedWorkoutRequest
  };
};

export const setExamCheckedIds = (
  examCheckedIds: string[]
): Partial<VetWorkoutsPageType> => {
  return {
    type: SET_EXAM_CHECKED_IDS_BY,
    examCheckedIds
  };
};

export const setPassCheckedIds = (
  passCheckedIds: string[]
): Partial<VetWorkoutsPageType> => {
  return {
    type: SET_PASS_CHECKED_IDS_BY,
    passCheckedIds
  };
};

export const setAssignCheckedIds = (
  assignCheckedIds: string[]
): Partial<VetWorkoutsPageType> => {
  return {
    type: SET_ASSIGN_CHECKED_IDS_BY,
    assignCheckedIds
  };
};

export const setSavingCheckedIds = (
  savingCheckedIds: string[]
): Partial<VetWorkoutsPageType> => {
  return {
    type: SET_SAVING_CHECKED_IDS_BY,
    savingCheckedIds
  };
};

export const setSavingUndoQuickPassIds = (
  savingUndoQuickPassIds: string[]
): Partial<VetWorkoutsPageType> => {
  return {
    type: SET_SAVING_UNDO_QUICK_PASS_IDS_BY,
    savingUndoQuickPassIds
  };
};

export const setSavingUnAssignIds = (
  savingUnAssignIds: string[]
): Partial<VetWorkoutsPageType> => {
  return {
    type: SET_SAVING_UNASSIGN_IDS_BY,
    savingUnAssignIds
  };
};

export const setCommentsPopoverAnchorEl = (
  commentsPopoverAnchorEl: HTMLElement
): Partial<VetWorkoutsPageType> => {
  return {
    type: SET_COMMENTS_POPOVER_ANCHOR_ELEMENT,
    commentsPopoverAnchorEl
  };
};

export const setIsFOTCommentDisplayed = (
  isFOTCommentDisplayed: boolean
): Partial<VetWorkoutsPageType> => {
  return {
    type: SET_IS_FOT_COMMENT_DISPLAYED,
    isFOTCommentDisplayed
  };
};
