import React from "react";
import ArrowBackIcon from "@material-ui/icons/ArrowBack";
import IconButton from "@material-ui/core/IconButton";
import { goBack } from "connected-react-router";
import { useDispatch } from "react-redux";
import {
  withClickBlocker,
  WithClickBlockerProps
} from "../BlockableClickContext";

interface Props extends WithClickBlockerProps {
  className?: string;
}

const BackLink = (props: Props) => {
  const { className, handleBlockableLinkClick } = props;
  const dispatch = useDispatch();

  const handleClick = event => {
    const isBlocked = handleBlockableLinkClick(event);
    if (!isBlocked) {
      dispatch(goBack());
    }
  };

  return (
    <IconButton
      data-testid={"backLink"}
      data-path={"backLink"}
      size={"small"}
      className={className}
      onClick={handleClick}
    >
      <ArrowBackIcon />
    </IconButton>
  );
};

export default withClickBlocker(BackLink);
