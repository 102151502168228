import React, { useContext, useMemo } from "react";
import { grpc, racehorse360, auth } from "@tsg/1st-grpc-web";
import { useAuth0 } from "@auth0/auth0-react";
import { interceptErrors } from "./errors";

export const ApiClientContext = React.createContext<ApiClientContextInterface>(
  {} as any
);

interface GrpcClients {
  applicationSettingClient: racehorse360.ApplicationSettingServiceSimpleClient;
  facilityBlackoutClient: racehorse360.FacilityBlackoutServiceSimpleClient;
  facilityClient: racehorse360.FacilityServiceSimpleClient;
  horseClient: racehorse360.HorseServiceSimpleClient;
  notificationClient: racehorse360.NotificationServiceSimpleClient;
  raceConditionClient: racehorse360.RaceConditionServiceSimpleClient;
  raceClient: racehorse360.RaceServiceSimpleClient;
  regionClient: racehorse360.RegionServiceSimpleClient;
  rolePermissionClient: racehorse360.RolePermissionServiceSimpleClient;
  trainerClient: racehorse360.TrainerServiceSimpleClient;
  userClient: racehorse360.UserServiceSimpleClient;
  vaccinationClient: racehorse360.VaccinationServiceSimpleClient;
  stallApplicationClient: racehorse360.StallApplicationServiceSimpleClient;
  workOrderClient: racehorse360.WorkOrderServiceSimpleClient;
  workoutExamClient: racehorse360.WorkoutExamServiceSimpleClient;
  workoutRequestClient: racehorse360.WorkoutRequestServiceSimpleClient;
  workoutClient: racehorse360.WorkoutServiceSimpleClient;
}

export interface ApiClientContextInterface extends GrpcClients {
  authClient: auth.UserProfileServiceSimpleClient;
  getAccessTokenSilently: () => Promise<string>;
}

export const useApiClient: () => ApiClientContextInterface = () =>
  useContext(ApiClientContext);

export const ApiClientProvider = ({
  children,
  isApiMocked = process.env.RACEHORSE360_API_MOCKED === "1",
  enableGrpcWebDevTools = Boolean(window.__GRPCWEB_DEVTOOLS__)
}) => {
  const { getAccessTokenSilently } = useAuth0();

  const value = useMemo(() => {
    const clientOptions: grpc.CreateClientOptions = {
      auth: getAccessTokenSilently
    };

    const clients = Object.keys(racehorse360).reduce((acc, key) => {
      if (key.endsWith("ServiceSimpleClient")) {
        const client = grpc.createClient(
          racehorse360[key],
          racehorse360.packageName,
          clientOptions
        );
        interceptErrors(client);
        acc[
          key.charAt(0).toLowerCase() +
            key.substr(1).replace("ServiceSimple", "")
        ] = client;
      }
      return acc;
    }, {} as GrpcClients);

    const authClient = grpc.createClient(
      auth.UserProfileServiceSimpleClient,
      auth.packageName,
      clientOptions
    );
    interceptErrors(authClient);

    if (enableGrpcWebDevTools && !isApiMocked) {
      window.__GRPCWEB_DEVTOOLS__([...Object.values(clients), authClient]);
    }

    return {
      ...clients,
      authClient,
      getAccessTokenSilently
    };
  }, [isApiMocked, getAccessTokenSilently, enableGrpcWebDevTools]);

  return (
    <ApiClientContext.Provider value={value}>
      {children}
    </ApiClientContext.Provider>
  );
};
