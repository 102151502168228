import React, { useState, useRef, useEffect } from "react";
import { useHistory } from "react-router-dom";
import clsx from "clsx";

import Button from "@material-ui/core/Button";
import AddCircleOutlineIcon from "@material-ui/icons/AddCircleOutline";

import { racehorse360 } from "@tsg/1st-grpc-web";

import { useRacehorse360Api } from "hooks/api";
import { useClickBlockerContext } from "components/BlockableClickContext";
import UnsavedChangesDialog from "components/UnsavedChangesDialog";
import { useLoggedInUser } from "components/LoggedInUserProvider";
import Textarea from "components/Textarea";
import Loader from "components/Loader";
import useStyles from "./styles";

interface IProps {
  horseId: string;
}

const Notepad = (props: IProps) => {
  const { horseId } = props;
  const classes = useStyles();
  const { setBlockClick } = useClickBlockerContext();
  const inputRef = useRef<HTMLInputElement>(null);
  const { currentUser } = useLoggedInUser();
  const history = useHistory();

  const [isEditMode, setIsEditMode] = useState<boolean>(false);
  const [comment, setComment] = useState<string>(null);
  const [savedComment, setSavedComment] =
    useState<racehorse360.IHorseNotepadComment>(null);
  const [hasUnsavedChanges, setHasUnsavedChanges] = useState<boolean>(false);
  const [linkCandidate, setLinkCandidate] = useState<string>(null);

  const {
    useCreateHorseNotepadComment,
    useUpdateHorseNotepadComment,
    useListHorseNotepadComments
  } = useRacehorse360Api();

  const {
    mutateAsync: updateNotepadComment,
    isLoading: isLoadingUpdatingNotepadComment
  } = useUpdateHorseNotepadComment({
    onError: error => {
      console.error(error);
    }
  });

  const {
    mutateAsync: createNotepadComment,
    isLoading: isLoadingCreatingNotepadComment
  } = useCreateHorseNotepadComment({
    onError: error => {
      console.error(error);
    }
  });

  const {
    isLoading: isLoadingNotepadComments,
    isFetching: isFetchingNotepadComments
  } = useListHorseNotepadComments(
    {
      query: {
        userId: currentUser.rh360Id,
        horseId
      },
      getOptions: { select: ["id", "note"] }
    },
    {
      enabled: Boolean(currentUser.rh360Id && horseId),
      onSuccess: (data: racehorse360.IListHorseNotepadCommentResponse) => {
        const comments = data?.horseNotepadComments || [];

        if (comments.length) {
          const currentSavedNote = comments[0];

          setComment(currentSavedNote.note);
          setSavedComment(currentSavedNote);
        }
      },
      onError: error => console.error(error)
    }
  );

  const redirectToLinkCandidate = () => {
    if (linkCandidate === "backLink") {
      history.goBack();
    } else {
      history.push(linkCandidate);
    }
  };

  const handleBlurNotepad = event => {
    const path = event.relatedTarget?.dataset?.path;

    setBlockClick(true);
    setHasUnsavedChanges(true);
    path && setLinkCandidate(path);
  };

  const unlockNavigation = () => {
    setBlockClick(false);
    setHasUnsavedChanges(false);
  };

  const handleOpenEditMode = () => {
    setIsEditMode(true);
  };

  const handleChangeNote = value => {
    setComment(value);
  };

  const handleCancelNote = () => {
    setIsEditMode(false);
    setComment(savedComment?.note || null);
    unlockNavigation();
  };

  const handleSaveNote = async () => {
    if (savedComment?.note === comment?.trim()) {
      handleCancelNote();
      linkCandidate && redirectToLinkCandidate();
      return;
    }

    const horseNotepadComment: racehorse360.IHorseNotepadComment = {
      horseId: horseId,
      userId: currentUser.rh360Id,
      note: comment.trim()
    };
    let currentComment;

    if (savedComment) {
      currentComment = await updateNotepadComment({
        horseNotepadComment: {
          ...horseNotepadComment,
          id: savedComment.id
        },
        updateMask: { paths: ["note"] }
      });
    } else {
      currentComment = await createNotepadComment({ horseNotepadComment });
    }

    if (currentComment) {
      setComment(currentComment.note);
      setSavedComment(currentComment);
      setIsEditMode(false);
      unlockNavigation();
      linkCandidate && redirectToLinkCandidate();
    }
  };

  const handleCancelUnsavedChanges = () => {
    setHasUnsavedChanges(false);
  };

  const handleDiscardChanges = () => {
    setIsEditMode(false);
    setComment(savedComment?.note || null);
    unlockNavigation();
    linkCandidate && redirectToLinkCandidate();
  };

  const renderUnsavedChangesPopupContent = () => {
    return (
      <>
        <span className={classes.warningText}>
          You have unsaved changes to your notes.
        </span>
        <span className={classes.warningText}>
          Would you like to keep or discard your changes?
        </span>
      </>
    );
  };

  const renderContent = () => {
    const isLoading = [
      isLoadingCreatingNotepadComment,
      isLoadingUpdatingNotepadComment,
      isLoadingNotepadComments,
      isFetchingNotepadComments
    ].some(item => item);

    if (isLoading) return <Loader className={classes.loader} />;

    if (!comment && !isEditMode) {
      return (
        <div className={classes.emptyNotepad}>
          <Button
            className={classes.addButton}
            startIcon={<AddCircleOutlineIcon className={classes.addIcon} />}
            onClick={handleOpenEditMode}
          >
            Add Note
          </Button>
        </div>
      );
    }

    if (isEditMode) {
      return (
        <div className={classes.editMode}>
          <Textarea
            className={classes.notepadTextarea}
            maxLength={500}
            label="General Comment"
            rows={8}
            enteredValue={comment}
            onChange={handleChangeNote}
            inputRef={inputRef}
            onBlur={handleBlurNotepad}
          />
          <div className={classes.buttons}>
            <Button className={classes.button} onMouseDown={handleCancelNote}>
              Cancel
            </Button>
            <Button
              className={clsx(classes.button, classes.saveButton)}
              onMouseDown={handleSaveNote}
            >
              Save
            </Button>
          </div>
        </div>
      );
    }

    return (
      <div className={classes.existedComment}>
        <div className={classes.existedCommentContent}>{comment}</div>
        <Button
          className={clsx(classes.button, classes.editButton)}
          onClick={handleOpenEditMode}
        >
          Edit
        </Button>
      </div>
    );
  };

  useEffect(() => {
    const input = inputRef.current;

    if (isEditMode && !hasUnsavedChanges && input) {
      input?.focus();
      input.selectionStart = input.value.length;
    }
  }, [isEditMode, hasUnsavedChanges]);

  return (
    <div className={classes.root}>
      <div className={classes.header}>My Notepad</div>
      {renderContent()}

      {hasUnsavedChanges && (
        <UnsavedChangesDialog
          onSave={handleSaveNote}
          onCancel={handleCancelUnsavedChanges}
          onDiscard={handleDiscardChanges}
          open={hasUnsavedChanges}
          content={renderUnsavedChangesPopupContent()}
        />
      )}
    </div>
  );
};

export default Notepad;
