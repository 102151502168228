import React, { useState } from "react";
import { useSelector } from "react-redux";
import { useHistory } from "react-router";
import clsx from "clsx";
import { MuiTheme } from "theme";
import groupBy from "lodash/groupBy";
import format from "date-fns/format";
import isToday from "date-fns/isToday";
import isSameDay from "date-fns/isSameDay";
import isThisMonth from "date-fns/isThisMonth";
import isPast from "date-fns/isPast";
import addDays from "date-fns/addDays";
import addMonths from "date-fns/addMonths";
import isFuture from "date-fns/isFuture";
import startOfMonth from "date-fns/startOfMonth";
import startOfWeek from "date-fns/startOfWeek";
import parseISO from "date-fns/parseISO";

import { createStyles, makeStyles, useTheme } from "@material-ui/core/styles";
import Box from "@material-ui/core/Box";
import Paper from "@material-ui/core/Paper";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import MoreHorizIcon from "@material-ui/icons/MoreHoriz";
import Button from "@material-ui/core/Button";
import IconButton from "@material-ui/core/IconButton";
import Divider from "@material-ui/core/Divider";
import ChevronLeft from "@material-ui/icons/ChevronLeft";
import ChevronRight from "@material-ui/icons/ChevronRight";
import Typography from "@material-ui/core/Typography";

import { racehorse360 } from "@tsg/1st-grpc-web";

import { useRacehorse360Api } from "hooks/api";
import routes from "common/routes";
import FacilityMarkerIndicator from "components/FacilityMarkerIndicator";
import CommentIcon from "components/Icons/Comment";
import Loader from "components/Loader";
import Popover from "components/Popover";
import ScheduleDateMenu from "components/ScheduleDateMenu";
import { useSnackbar } from "components/SnackbarContext/SnackbarContext";
import { FacilitiesState } from "store/reducers/facilities";
import { gradientToBottom } from "utils/css-utils";
import { currentTimeZoneString, getNextDates } from "utils/date-utils";
import { SortOrder } from "interfaces/SortOrder";
import useStyles from "./styles";

type UpcomingEvent =
  | racehorse360.IWorkoutRequest
  | racehorse360.IUpcomingRaceHorse;

type ScheduleDay = UpcomingEvent | { date: string };

const isWorkoutRequestObject = (
  input: UpcomingEvent
): input is racehorse360.IWorkoutRequest =>
  input instanceof racehorse360.WorkoutRequest;

const isUpcomingRaceObject = (
  input: UpcomingEvent
): input is racehorse360.IUpcomingRaceHorse =>
  input instanceof racehorse360.UpcomingRaceHorse;

interface Props {
  horse: racehorse360.IHorse;
}

const splitToWeeks = (schedule: ScheduleDay[]) => {
  const weeksNumber = schedule.length / 7;
  const arr: ScheduleDay[][] = [];
  for (let i = 0; i < weeksNumber; i++) {
    arr.push(schedule.slice(i * 7, i * 7 + 7));
  }
  return arr;
};
const DATE_CELLS_NUMBER = 42;

const UpcomingEvents = React.memo((props: Props) => {
  const { horse } = props;

  const classes = useStyles();
  const theme = useTheme();
  const history = useHistory();
  const matchesUpMd = useMediaQuery(theme.breakpoints.up("md"));
  const [anchorEl, setAnchorEl] = useState(null);
  const [isScheduleDateMenuOpen, setIsScheduleDateMenuOpen] =
    useState<boolean>(false);
  const [datePopoverAnchorEl, setDatePopoverAnchorEl] = useState(null);
  const [activeEvent, setActiveEvent] = useState(null);
  const [startDate, setStartDate] = useState<Date>(
    startOfWeek(startOfMonth(new Date()))
  );
  const [editEventId, setEditEventId] = useState<string>("");
  const [activeMonth, setActiveMonth] = useState<Date>(new Date());

  const { facilities } = useSelector(
    (state: { facilities: FacilitiesState }) =>
      state?.facilities || { facilities: [] }
  );
  const useFacilityStyles = makeStyles((theme: MuiTheme) => {
    if (facilities && facilities.length) {
      return createStyles(
        Object.assign(
          {},
          ...facilities.map(facility => ({
            [`${facility.code}`]: {
              "&:after": {
                content: "''",
                backgroundColor: facility.backgroundColor
                  ? `#${facility.backgroundColor}`
                  : theme.palette.primary.main
              }
            },
            [`${facility.code}Gradient`]: {
              "&:before": {
                backgroundImage: gradientToBottom(
                  facility.backgroundColor,
                  facility.strokeColor,
                  5
                )
              }
            }
          }))
        )
      );
    }
  });
  const facilityStyleClasses = useFacilityStyles();

  const days = new Array(7)
    .fill(startDate)
    .map((day, i) => format(addDays(day, i), "iii"));

  function combineSchedule(
    days: { date: string }[],
    scheduleEvents: UpcomingEvent[]
  ) {
    return days.map(day => {
      const scheduleEvent = scheduleEvents.find(scheduleEvent => {
        if (isWorkoutRequestObject(scheduleEvent)) {
          return isSameDay(parseISO(scheduleEvent.date), parseISO(day.date));
        }
        if (isUpcomingRaceObject(scheduleEvent)) {
          return isSameDay(
            parseISO(scheduleEvent?.raceDate),
            parseISO(day.date)
          );
        }
        return false;
      });

      return scheduleEvent || day;
    });
  }

  const {
    useListUpcomingRaceHorses,
    useCancelWorkoutRequest,
    useMoveFacilityWorkoutRequest,
    useListWorkoutRequests
  } = useRacehorse360Api();

  const {
    mutateAsync: cancelWorkoutRequest,
    isLoading: isCancelWorkoutLoading
  } = useCancelWorkoutRequest();

  const {
    mutateAsync: moveFacilityWorkoutRequest,
    isLoading: isLoadingChangeFacility
  } = useMoveFacilityWorkoutRequest();

  const {
    isLoading: isUpcomingRacesLoading,
    data: { upcomingRaceHorses }
  } = useListUpcomingRaceHorses(
    {
      query: {
        horseIds: [horse?.id],
        raceDate: {
          range: {
            relative: {
              startDate: { value: 0 },
              endDate: { value: 40 },
              timezone: currentTimeZoneString()
            }
          }
        }
      },
      pagingOptions: {
        maxResults: 30
      },
      getOptions: {
        select: ["id", "facility.name", "facility.code", "raceDate"]
      }
    },
    {
      enabled: Boolean(horse?.id),
      initialData: racehorse360.ListUpcomingRaceHorsesResponse.fromObject({
        upcomingRaceHorses: []
      }),
      onError: error => console.error(error)
    }
  );

  const {
    isLoading: isLoadingWorkoutRequests,
    refetch: refetchWorkoutRequests,
    data: { workoutRequests }
  } = useListWorkoutRequests(
    {
      query: {
        horseIds: [horse?.id],
        date: {
          range: {
            absolute: {
              onOrAfter: format(startDate, "yyyy-MM-dd")
            }
          }
        },
        statuses: [
          racehorse360.WorkoutRequestStatus.WORKOUT_REQUEST_STATUS_REQUESTED,
          racehorse360.WorkoutRequestStatus.WORKOUT_REQUEST_STATUS_EXAM_PENDING,
          racehorse360.WorkoutRequestStatus.WORKOUT_REQUEST_STATUS_APPROVED
        ]
      },
      pagingOptions: {
        maxResults: 30
      },
      getOptions: {
        select: ["id", "facility.name", "facility.code", "date", "status"],
        orderBy: [`date ${SortOrder.ASC}`]
      }
    },
    {
      enabled: Boolean(horse?.id),
      initialData: racehorse360.ListWorkoutRequestsResponse.fromObject({
        workoutRequests: []
      }),
      onError: error => console.error(error)
    }
  );

  const upcomingEvents: UpcomingEvent[] = [
    ...workoutRequests,
    ...upcomingRaceHorses
  ];
  const schedule = combineSchedule(
    getNextDates(startDate, DATE_CELLS_NUMBER).map(date => ({ date })),
    upcomingEvents
  );

  const { showErrorSnack } = useSnackbar();

  const sortByDate = () => {
    const groupByDate = groupBy(upcomingEvents, upcomingEvent => {
      let date: string;

      if (
        Object.prototype.isPrototypeOf.call(
          racehorse360.WorkoutRequest,
          upcomingEvent
        )
      ) {
        date = (upcomingEvent as racehorse360.IWorkoutRequest).date;
      } else {
        date = (upcomingEvent as racehorse360.IUpcomingRaceHorse).raceDate;
      }

      return date;
    });

    return Object.keys(groupByDate)
      .map(sortedEvent => groupByDate[sortedEvent])
      .flat(2);
  };

  const handleWorkoutChange = (upcomingEvent: UpcomingEvent) => {
    setEditEventId(upcomingEvent?.id);

    if (upcomingEvent.facility) {
      moveFacilityWorkoutRequest({
        id: upcomingEvent.id,
        facilityId: upcomingEvent.facility.id
      })
        .then(() => refetchWorkoutRequests())
        .then(() => {
          setActiveEvent(null);
          setEditEventId("");
        })
        .catch(error => console.error(error));
    }

    if (!upcomingEvent.facility) {
      cancelWorkoutRequest({
        id: upcomingEvent.id
      })
        .catch(error => showErrorSnack(error.toString()))
        .then(() => refetchWorkoutRequests())
        .then(() => {
          setActiveEvent(null);
          setEditEventId("");
        })
        .catch(error => console.error(error));
    }
  };

  const handleMenuOpen =
    (upcomingEventId: string) => (event: React.MouseEvent) => {
      setActiveEvent(
        upcomingEvents.find(
          upcomingEvent => upcomingEvent.id === upcomingEventId
        )
      );
      setAnchorEl(event.currentTarget);
      setIsScheduleDateMenuOpen(true);
    };

  const handleMenuClose = () => {
    setIsScheduleDateMenuOpen(false);
  };

  const handlePopoverOpen =
    (upcomingEvent: UpcomingEvent) => (event: React.MouseEvent) => {
      if (upcomingEvent?.facility) {
        if (isWorkoutRequestObject(upcomingEvent)) {
          if (
            isToday(parseISO(upcomingEvent.date)) ||
            isFuture(parseISO(upcomingEvent.date))
          ) {
            setActiveEvent(upcomingEvent);
            setDatePopoverAnchorEl(event.currentTarget);
          }
        }

        if (isUpcomingRaceObject(upcomingEvent)) {
          if (
            isToday(parseISO(upcomingEvent.raceDate)) ||
            isFuture(parseISO(upcomingEvent.raceDate))
          ) {
            setActiveEvent(upcomingEvent);
            setDatePopoverAnchorEl(event.currentTarget);
          }
        }
      }
    };

  const handlePopoverClose = () => {
    setDatePopoverAnchorEl(null);
  };

  const handleMonthControlClick = (change: number) => () => {
    const newActiveMonth = addMonths(activeMonth, change);
    const newStartDate = startOfWeek(startOfMonth(newActiveMonth));

    setActiveMonth(newActiveMonth);
    setStartDate(newStartDate);

    refetchWorkoutRequests().catch(error => console.error(error));
  };

  const handleAddOrEditCommentsClick = (upcomingEventId: string) => e => {
    history.push(
      routes.workoutComments.path.replace(":workoutId", String(upcomingEventId))
    );
  };

  const renderUpcomingEvent = (upcomingEvent: UpcomingEvent) => {
    let eventDate: string;
    let eventDay: string;
    let eventName: string;

    if (isWorkoutRequestObject(upcomingEvent)) {
      eventDate = format(parseISO(upcomingEvent?.date), "MMM d");
      eventDay = format(parseISO(upcomingEvent?.date), "eeee");
      eventName = "Workout";
    }
    if (isUpcomingRaceObject(upcomingEvent)) {
      eventDate = format(parseISO(upcomingEvent?.raceDate), "MMM d");
      eventDay = format(parseISO(upcomingEvent?.raceDate), "eeee");
      eventName = "Race";
    }

    if (
      !isWorkoutRequestObject(upcomingEvent) &&
      !isUpcomingRaceObject(upcomingEvent)
    )
      return null;

    return (
      <li className={classes.item} key={upcomingEvent?.id}>
        <div
          className={clsx(classes.schedule, {
            [classes.scheduleOneItem]: upcomingEvents?.length === 1
          })}
        >
          <span className={classes.date}>{eventDate}</span>
          <span className={classes.day}>{eventDay}</span>
        </div>
        <Paper
          className={clsx(
            classes.details,
            facilityStyleClasses[
              `${upcomingEvent?.facility?.code.trim()}Gradient`
            ]
          )}
        >
          {(isLoadingChangeFacility || isCancelWorkoutLoading) &&
          editEventId === upcomingEvent?.id ? (
            <Loader />
          ) : (
            <>
              <div>
                <span className={classes.activityName}>{eventName}</span>
                <span className={classes.activityFacility}>
                  {upcomingEvent?.facility?.code.trim()}
                </span>
              </div>
              {isWorkoutRequestObject(upcomingEvent) && (
                <IconButton>
                  <MoreHorizIcon onClick={handleMenuOpen(upcomingEvent.id)} />
                </IconButton>
              )}
            </>
          )}
        </Paper>
      </li>
    );
  };

  const renderUpcomingEvents = () => {
    const sortedEvents = sortByDate();
    return sortedEvents
      .filter(event => !isPast(parseISO(event["date"] || event["raceDate"])))
      .slice(0, 3)
      .map(upcomingEvent => renderUpcomingEvent(upcomingEvent));
  };

  const renderPopover = () => {
    const id: string = activeEvent.id;
    let date: string;
    let dayName: string;
    let upcomingEvent: string;

    if (isWorkoutRequestObject(activeEvent)) {
      date = format(parseISO(activeEvent.date), "MMMM d");
      dayName = format(parseISO(activeEvent.date), "iiii");
      upcomingEvent = `Workout at ${activeEvent?.facility?.name}`;
    }
    if (isUpcomingRaceObject(activeEvent)) {
      date = format(parseISO(activeEvent.raceDate), "MMMM d");
      dayName = format(parseISO(activeEvent.raceDate), "iiii");
      upcomingEvent = `Race at ${activeEvent?.facility?.name}`;
    }

    if (
      !isWorkoutRequestObject(activeEvent) &&
      !isUpcomingRaceObject(activeEvent)
    )
      return null;

    return (
      <>
        <Box className={classes.popoverHeader}>
          <Typography className={classes.popoverDate}>{date}</Typography>
          <Typography className={classes.popoverDayName}>{dayName}</Typography>
        </Box>
        <Divider />
        <Box className={classes.popoverBody}>
          <Typography className={classes.popoverHorseName}>
            {horse?.name}
          </Typography>
          <div className={classes.popoverEventLine}>
            <FacilityMarkerIndicator
              facility={activeEvent?.facility}
              size={8}
              marginRight={8}
            />
            <Typography className={classes.popoverEvent}>
              {upcomingEvent}
            </Typography>
          </div>
        </Box>
        <Divider />
        <Box className={classes.popoverActionButtons}>
          <Button
            className={classes.addOrEditButton}
            onClick={handleAddOrEditCommentsClick(id)}
          >
            <CommentIcon className={classes.addOrEditButtonIcon} size="large" />
            Add or Edit Comments
          </Button>
        </Box>
      </>
    );
  };

  return (
    <Paper className={classes.root}>
      <header className={classes.header}>
        <span className={classes.headerTitle}>Upcoming Events</span>
      </header>

      {!matchesUpMd && (
        <section className={classes.events}>
          {isLoadingWorkoutRequests || isUpcomingRacesLoading ? (
            <Loader />
          ) : (
            <ul className={classes.list}>
              {upcomingEvents?.length
                ? renderUpcomingEvents()
                : `No Upcoming Events for ${horse?.name}`}
            </ul>
          )}
        </section>
      )}

      {matchesUpMd && (
        <section className={classes.calendar}>
          <Box className={classes.calendarMonthPicker}>
            <Typography className={classes.calendarMonth}>
              {format(activeMonth, "MMMM, yyyy")}
            </Typography>
            <Box>
              <IconButton
                size={"small"}
                className={classes.calendarMonthControl}
                disabled={isPast(startDate)}
                onClick={handleMonthControlClick(-1)}
              >
                <ChevronLeft className={classes.calendarMonthControlIcon} />
              </IconButton>
              <IconButton
                size={"small"}
                className={classes.calendarMonthControl}
                onClick={handleMonthControlClick(1)}
              >
                <ChevronRight className={classes.calendarMonthControlIcon} />
              </IconButton>
            </Box>
          </Box>
          <Box className={classes.calendarDayNames}>
            {days?.map(dayName => (
              <Typography key={dayName} className={classes.calendarDayName}>
                {dayName}
              </Typography>
            ))}
          </Box>
          <Box className={classes.calendarRowsWrapper}>
            {splitToWeeks(schedule)?.map((week, index) => (
              <Box key={index} className={classes.calendarDates}>
                {week.map((day, index) => {
                  const eventDate =
                    day instanceof racehorse360.UpcomingRaceHorse
                      ? day.raceDate
                      : day["date"];
                  const facility =
                    day instanceof racehorse360.WorkoutRequest ||
                    day instanceof racehorse360.UpcomingRaceHorse
                      ? day.facility
                      : null;
                  return (
                    <Button
                      key={index}
                      className={clsx(
                        classes.calendarDateButton,
                        {
                          [classes.calendarDateToday]: isToday(
                            parseISO(eventDate)
                          ),
                          [classes.outsideCurrentMonths]:
                            !isThisMonth(parseISO(eventDate)) && !facility
                        },
                        facility
                          ? [
                              classes.calendarDateEvent,
                              facilityStyleClasses[facility.code.trim()],
                              {
                                [classes.disabledCalendarEvent]:
                                  isPast(parseISO(eventDate)) &&
                                  !isToday(parseISO(eventDate))
                              }
                            ]
                          : null
                      )}
                      size={"small"}
                      onClick={facility && handlePopoverOpen(day)}
                    >
                      {parseISO(eventDate).getDate()}
                    </Button>
                  );
                })}
              </Box>
            ))}
          </Box>
        </section>
      )}

      <ScheduleDateMenu
        isOpen={isScheduleDateMenuOpen}
        horse={horse}
        onChange={handleWorkoutChange}
        anchorEl={anchorEl}
        workoutData={isWorkoutRequestObject(activeEvent) && activeEvent}
        onClose={handleMenuClose}
      />

      <Popover anchorEl={datePopoverAnchorEl} onClose={handlePopoverClose}>
        <Box className={classes.popover}>{activeEvent && renderPopover()}</Box>
      </Popover>
    </Paper>
  );
});

export default UpcomingEvents;
