import React, { useCallback } from "react";
import InputBase, { InputBaseProps } from "@material-ui/core/InputBase";

interface Props extends InputBaseProps {
  onEnter?: (value) => void;
}

const TextInputBase = (props: Props) => {
  const { onEnter, onFocus, onBlur, onKeyPress, ...restProps } = props;

  const handleFocus = useCallback(event => {
    if (onFocus) {
      onFocus(event);
    }
  }, []);

  const handleBlur = useCallback(event => {
    if (onBlur) {
      onBlur(event);
    }
  }, []);

  const handleKeyPress = event => {
    if (event.key === "Enter") {
      event.preventDefault();
      onEnter(event.target.value);
    }
    onKeyPress && onKeyPress(event);
  };

  return (
    <InputBase
      {...restProps}
      onFocus={handleFocus}
      onBlur={handleBlur}
      onKeyPress={handleKeyPress}
    />
  );
};

export default TextInputBase;
