import React from "react";
import useStyles from "./styles";
import clsx from "clsx";
import Box from "@material-ui/core/Box";

interface Props {
  children?: React.ReactNode;
  className?: string;
  role?: string;
}

const AppPageTableHeader = (props: Props) => {
  const { children, className } = props;
  const classes = useStyles();

  return (
    <Box className={clsx(classes.appPageTableHeader, className)} role="row">
      {children}
    </Box>
  );
};

export default AppPageTableHeader;
