import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import clsx from "clsx";

import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
import Button from "@material-ui/core/Button";
import IconButton from "@material-ui/core/IconButton";
import Refresh from "@material-ui/icons/Refresh";

import { racehorse360 } from "@tsg/1st-grpc-web";

import { useClickBlockerContext } from "components/BlockableClickContext";
import { useExamLockerContext } from "components/ExamLockerContext/ExamLockerContext";
import OverlayPage from "components/OverlayPage";
import HideMenu from "components/Icons/HideMenu";
import Loader from "components/Loader";

import {
  EModalConfirmationTypes,
  IVetExamState
} from "interfaces/VetExamState";
import { setModalState, setModalType } from "store/actions/vetExam";
import { useRacehorse360Api } from "hooks/api";
import { currentTimeZoneString } from "utils/date-utils";
import ExamSelect from "./ExamSelect";
import VetExamModal from "./VetExamModal";
import ExamFormBlock from "./ExamFormBlock";
import JogInformation from "./JogInformation";
import HorseInfoContainer from "./HorseInfoContainer";
import ExamResults from "./ExamResults";
import ExamFormHeader from "./ExamFormHeader";
import ExamDetailsList from "./ExamDetailsList/ExamDetailsList";
import {
  checkSelectingExamFormFields,
  getExamResult,
  getLocalStateExam,
  getVetExamRequestState
} from "./helper";
import {
  changeExamField,
  EVetExamFields,
  examReasons,
  resetExam,
  setEntireExam
} from "./options";
import { useVetExamContext } from "./VetExamStateProvider/VetExamProvider";
import { WORKOUT_EXAM_OPTIONS } from "../../pages/HorseDetailsPage/Vet/helper";
import { SortOrder } from "interfaces/SortOrder";
import useStyles from "./styles";

interface IProps {
  open: boolean;
  refetchData: () => void;
  onClose: () => void;
  currentExam: racehorse360.IWorkoutExam;
  isExamLoading: boolean;
}

const VetExams = (props: IProps) => {
  const { open, onClose, refetchData, currentExam, isExamLoading } = props;

  const classes = useStyles();
  const dispatchToRedux = useDispatch();
  const { lockExam, unlockExam } = useExamLockerContext();
  const { setBlockClick, setBlockClickCallback } = useClickBlockerContext();
  const { state, dispatch, isFormFieldsItemsLoading } = useVetExamContext();
  const { modalState, modalType } = useSelector(
    (state: { vetExam: IVetExamState }) => ({
      ...state.vetExam
    })
  );

  const { reason, result } = state;
  const [isShowHorseInfoDesktop, setIsShowHorseInfoDesktop] =
    useState<boolean>(false);
  const [isShowHorseInfoMobile, setIsShowHorseInfoMobile] =
    useState<boolean>(false);

  const {
    useListWorkoutRequests,
    usePullHorseWarningMessage,
    useCancelWorkoutExam,
    useCompleteWorkoutExam,
    useEditWorkoutExam,
    useListWorkoutExams
  } = useRacehorse360Api();

  //WorkoutsExams
  const pastExamsQuery = {
    horseIds: [currentExam?.horse?.id],
    statuses: [racehorse360.WorkoutExamStatus.WORKOUT_EXAM_STATUS_COMPLETED],
    workoutExamTypes: [racehorse360.WorkoutExamType.WORKOUT_EXAM_TYPE_FULL_EXAM]
  };
  const { data: pastExamsCountData } = useListWorkoutExams(
    {
      query: pastExamsQuery,
      pagingOptions: {
        maxResults: 1,
        includeSummary: true
      }
    },
    {
      enabled: Boolean(currentExam?.horse?.id)
    }
  );
  const pastExamsCount = pastExamsCountData?.pagingInfo?.totalResults || 0;

  const { data: pastExamsData, isLoading: isPastExamsLoading } =
    useListWorkoutExams(
      {
        query: pastExamsQuery,
        getOptions: {
          select: WORKOUT_EXAM_OPTIONS,
          orderBy: [`createdOn ${SortOrder.DESC}`]
        },
        pagingOptions: {
          maxResults: pastExamsCount
        }
      },
      {
        enabled: pastExamsCount > 0
      }
    );
  const completedExams = pastExamsData?.workoutExams || [];

  //WorkoutRequest requests
  const {
    data: upcomingWorkoutRequests,
    isLoading: isUpcomingWorkoutRequestsLoading
  } = useListWorkoutRequests(
    {
      query: {
        horseIds: currentExam?.horse.id ? [currentExam?.horse.id] : [],
        date: {
          range: {
            relative: {
              // Today and later
              startDate: { value: 0 },
              timezone: currentTimeZoneString()
            }
          }
        },
        statuses: [
          racehorse360.WorkoutRequestStatus.WORKOUT_REQUEST_STATUS_REQUESTED,
          racehorse360.WorkoutRequestStatus.WORKOUT_REQUEST_STATUS_EXAM_PENDING,
          racehorse360.WorkoutRequestStatus.WORKOUT_REQUEST_STATUS_APPROVED
        ]
      },
      getOptions: {
        select: [
          "date",
          "facility.name",
          "horse.trainer.firstName",
          "horse.trainer.middleName",
          "horse.trainer.lastName",
          "createdOn"
        ],
        orderBy: [`date ${SortOrder.ASC}`]
      }
    },
    {
      enabled: Boolean(currentExam?.horse.id)
    }
  );

  const nextWorkoutRequest = upcomingWorkoutRequests?.workoutRequests[0];

  //WarningMessage requests
  const {
    data: horseWarningMessageData,
    isFetching: isPullHorseWarningMessageFetching,
    refetch: refetchHorseWarningMessage
  } = usePullHorseWarningMessage(
    {
      id: currentExam?.horse?.id
    },
    {
      enabled: Boolean(currentExam?.horse?.id)
    }
  );
  const warningMessage: racehorse360.IWarningMessage =
    horseWarningMessageData?.warningMessage;

  const { mutateAsync: saveVetExam } = useEditWorkoutExam();

  //Discard workoutExam
  const { mutateAsync: cancelWorkoutExam } = useCancelWorkoutExam({});

  //Complete workoutExam
  const { mutateAsync: completeWorkoutExam } = useCompleteWorkoutExam({});

  const openSubmitModal = () => {
    dispatchToRedux(setModalState(true));
    dispatchToRedux(setModalType(EModalConfirmationTypes.SUBMIT));
  };

  const openCloseOrPauseModal = () => {
    dispatchToRedux(setModalState(true));
    dispatchToRedux(setModalType(EModalConfirmationTypes.PAUSE_OR_CLOSE));
  };

  const openResetModal = () => {
    dispatchToRedux(setModalState(true));
    dispatchToRedux(setModalType(EModalConfirmationTypes.RESET));
  };

  const lockNavigation = () => {
    setBlockClick(true);
    setBlockClickCallback(() => () => openCloseOrPauseModal());
  };

  const unLockNavigation = () => {
    setBlockClick(false);
    setBlockClickCallback(null);
  };

  const closeVetExamSavingState = () => {
    saveVetExam({
      workoutExam: getVetExamRequestState(currentExam, state)
    }).then(() => {
      refetchData();
      onClose();
    });
  };

  const handleCancelExam = () => {
    cancelWorkoutExam({ id: currentExam.id }).then(() => {
      refetchData();
      onClose();
    });
  };

  const handleCompleteExam = () => {
    completeWorkoutExam({
      workoutExam: getVetExamRequestState(currentExam, state)
    }).then(() => {
      refetchData();
      onClose();
    });
  };

  const handleResetWorkoutExam = () => {
    dispatch(resetExam());
  };

  const handleCloseModal = () => {
    dispatchToRedux(setModalState(false));
    dispatchToRedux(setModalType(null));
  };

  const handleChangeExamReason = (value: string) => {
    dispatch(changeExamField(value, EVetExamFields.REASON));
    lockExam();
  };

  const handleChangeRightPanelState = () => {
    setIsShowHorseInfoDesktop(!isShowHorseInfoDesktop);
  };

  const handleCloseHorseInfoMobile = () => {
    setIsShowHorseInfoMobile(false);
  };

  const handleOpenHorseInfoMobile = () => {
    setIsShowHorseInfoMobile(true);
  };

  const handlePauseAndCloseClick = () => {
    closeVetExamSavingState();
  };

  const renderActionsButtons = () => {
    return (
      <div className={classes.actionButtons}>
        <Button
          color="primary"
          className={clsx(classes.button, classes.pauseAndClose)}
          variant="contained"
          onClick={handlePauseAndCloseClick}
          data-test={"pause-and-close-button"}
        >
          Pause &#38; Close
        </Button>
        <Button
          color="primary"
          className={clsx(classes.button, classes.submit)}
          variant="contained"
          onClick={openSubmitModal}
          disabled={!result}
          data-test={"submit-exam-button"}
        >
          Submit Exam
        </Button>
      </div>
    );
  };

  useEffect(() => {
    if (currentExam) {
      const newLocalState = getLocalStateExam(currentExam);
      dispatch(setEntireExam(newLocalState));
    }
  }, [currentExam]);

  useEffect(() => {
    lockNavigation();
    lockExam();
    return () => {
      unLockNavigation();
      unlockExam();
    };
  }, []);

  const isLoading = [
    isFormFieldsItemsLoading,
    isUpcomingWorkoutRequestsLoading,
    isPullHorseWarningMessageFetching,
    isExamLoading,
    isPastExamsLoading,
    !currentExam
  ].some(Boolean);

  const isActiveReset = checkSelectingExamFormFields(state);

  return (
    <OverlayPage
      open={open}
      className={classes.overlayPage}
      onClose={openCloseOrPauseModal}
    >
      {isLoading ? (
        <Loader className={classes.pageLoader} />
      ) : (
        <>
          <DialogTitle className={classes.mobileHeader}>
            <Button
              className={classes.closeButton}
              onClick={openCloseOrPauseModal}
            >
              Close
            </Button>
            <span className={classes.mobileTitle}>Vet Exam</span>
            <IconButton disabled={!isActiveReset} onClick={openResetModal}>
              <Refresh />
            </IconButton>
          </DialogTitle>

          <DialogContent className={classes.root}>
            <IconButton
              className={clsx(classes.hideMenuButton, {
                ["revert"]: isShowHorseInfoDesktop
              })}
              onClick={handleChangeRightPanelState}
            >
              <HideMenu />
            </IconButton>

            <div
              className={clsx(classes.vetExamForm, {
                ["showRightPanel"]: isShowHorseInfoDesktop
              })}
            >
              <ExamFormHeader
                horse={currentExam?.horse}
                isActiveReset={isActiveReset}
                isShowHorseInfoDesktop={isShowHorseInfoDesktop}
                onOpenHorseInfoMobile={handleOpenHorseInfoMobile}
                onOpenResetModal={openResetModal}
                warningMessage={warningMessage}
                isPullHorseWarningMessageFetching={
                  isPullHorseWarningMessageFetching
                }
                refetchHorseWarningMessage={refetchHorseWarningMessage}
              />

              <div className={classes.scrollableContainer}>
                <ExamFormBlock title="Reason for Exam">
                  <ExamSelect
                    className={classes.reasonsSelect}
                    options={examReasons}
                    label="Reason"
                    inputLabel={"Select Reason"}
                    selectedValue={reason}
                    onChangeSelectedValue={handleChangeExamReason}
                    dataTestLabel={"exam-reason-dropdown"}
                  />
                </ExamFormBlock>

                <JogInformation />

                <ExamDetailsList />

                <ExamResults />

                {renderActionsButtons()}
              </div>
            </div>

            <HorseInfoContainer
              horse={currentExam?.horse}
              workoutRequest={nextWorkoutRequest}
              isShowHorseInfoDesktop={isShowHorseInfoDesktop}
              isShowHorseInfoMobile={isShowHorseInfoMobile}
              onCloseHorseInfoMobile={handleCloseHorseInfoMobile}
              completedExams={completedExams}
            />

            {modalState && (
              <VetExamModal
                onCloseModal={handleCloseModal}
                closeVetExamSavingState={closeVetExamSavingState}
                resetWorkoutExam={handleResetWorkoutExam}
                completeWorkoutExam={handleCompleteExam}
                discardWorkoutExam={handleCancelExam}
                type={modalType}
                horseName={currentExam?.horse?.name}
                examResult={getExamResult(Number(result))}
              />
            )}
          </DialogContent>
        </>
      )}
    </OverlayPage>
  );
};

export default React.memo(VetExams);
