import { makeStyles } from "@material-ui/core/styles";
import { MuiTheme } from "theme";

export default makeStyles((theme: MuiTheme) => {
  const { text, secondary } = theme.palette;

  return {
    dialogPaper: {
      width: 343,
      height: 454,
      maxHeight: 454,
      borderRadius: 6,
      boxShadow: "0px 0px 12px 0px rgba(0, 0, 0, 0.4)",
      overflowY: "hidden"
    },
    dialogTitle: {
      padding: "27px 24px 0",
      fontFamily: "SuisseIntlMedium, SuisseIntlRegular",
      "& .MuiTypography-root": {
        lineHeight: "25px"
      }
    },
    dialogContent: {
      paddingBottom: 0,
      overflowY: "hidden"
    },
    dialogContentHeader: {
      fontSize: 16,
      lineHeight: "38px"
    },
    trainerName: {
      fontSize: 14,
      fontFamily: "SuisseIntlMedium, SuisseIntlRegular",
      marginTop: 2,
      wordBreak: "break-all"
    },
    stableEntriesInfo: {
      fontSize: 13
    },
    stableEntriesCount: {
      fontSize: 16,
      marginTop: 14,
      lineHeight: "40px"
    },
    stableEntriesContainer: {
      maxHeight: 200,
      overflowY: "auto",
      margin: "0 -24px",
      paddingBottom: 4
    },
    stableEntryRow: {
      display: "flex",
      justifyContent: "space-between",
      alignItems: "center",
      height: 33,
      padding: "0 38px 0 24px",
      fontSize: 12,
      borderTop: "1px solid #eee",
      "&:nth-child(odd)": {
        backgroundColor: "#f7f7f7"
      }
    },
    approveIcon: {
      color: secondary.dark
    },
    denyIcon: {
      color: "#d82f44"
    },
    dialogActions: {
      display: "flex",
      justifyContent: "space-between",
      padding: "15px 14px 14px 10px",
      borderTop: "1px solid #eee"
    },
    backButton: {
      color: text.secondary
    },
    submitButton: {
      color: secondary.dark
    }
  };
});
