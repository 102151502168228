import React from "react";
import clsx from "clsx";
import format from "date-fns/format";
import parseISO from "date-fns/parseISO";
import utcToZonedTime from "date-fns-tz/utcToZonedTime";

import Box from "@material-ui/core/Box";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import BuildIcon from "@material-ui/icons/Build";

import { racehorse360 } from "@tsg/1st-grpc-web";
import LogoIcon from "components/Icons/SapLogo";
import Loader from "components/Loader";
import TableSortLabel from "components/TableSortLabel";
import { SortOrder } from "interfaces/SortOrder";
import CreateOrderButton from "pages/WorkOrdersPage/CreateOrderButton";
import { parseWorkOrderStatus } from "utils/enum-parser";
import { checkSortingType } from "utils/sort-utils";
import { Cell } from "../../interfaces";
import useStyles from "./styles";

interface Props {
  onItemClick: (workOrder: racehorse360.WorkOrder) => void;
  selectedId?: string;
  order: SortOrder;
  orderBy: keyof racehorse360.IWorkOrder;
  onSetOrder: (nextOrder: SortOrder) => void;
  onSetOrderBy: (nextOrderBy: keyof racehorse360.IWorkOrder) => void;
  isLoading: boolean;
  workOrders: racehorse360.IWorkOrder[];
  onOpenNewOrderPopup: () => void;
}

interface WorkOrderCell extends Cell {
  hideDownSX720?: boolean;
}

const headerCells: WorkOrderCell[] = [
  {
    id: "workOrderType.name",
    label: "Work Type",
    orderBy: "workOrderType.name",
    initialOrder: SortOrder.ASC
  },
  {
    id: "createdOn",
    label: "Opened",
    orderBy: "createdOn"
  },
  {
    id: "closedOn",
    label: "Closed",
    orderBy: "closedOn"
  },
  {
    id: "customerFacingId",
    label: "ID #",
    hideDownSX720: true
  },
  {
    id: "status",
    label: "Status",
    orderBy: "status"
  }
];

const sortWorkOrders = (
  workOrders: racehorse360.IWorkOrder[],
  orderBy: keyof racehorse360.IWorkOrder | keyof racehorse360.IWorkOrderType,
  order: SortOrder
) => {
  if (orderBy === "status") {
    const { isAsc } = checkSortingType(order);
    const parser: (arg: racehorse360.WorkOrderStatus) => string =
      parseWorkOrderStatus;
    return workOrders?.sort((wo1, wo2) => {
      return parser(isAsc ? wo1[orderBy] : wo2[orderBy]).localeCompare(
        parser(isAsc ? wo2[orderBy] : wo1[orderBy])
      );
    });
  } else if (orderBy === "workOrderType") {
    return workOrders?.sort((wo1, wo2) =>
      wo1.workOrderType.name.localeCompare(wo2.workOrderType.name)
    );
  }
  return workOrders;
};

const WorkOrderList = React.memo((props: Props) => {
  const {
    onItemClick,
    selectedId,
    order,
    orderBy,
    onSetOrder,
    onSetOrderBy,
    isLoading,
    workOrders,
    onOpenNewOrderPopup
  } = props;
  const classes = useStyles();

  const sortedWorkOrders = sortWorkOrders(workOrders, orderBy, order);
  const isActualOrder = (workOrder: racehorse360.IWorkOrder) => {
    return (
      workOrder.status ===
        racehorse360.WorkOrderStatus.WORK_ORDER_STATUS_OPEN ||
      workOrder.status ===
        racehorse360.WorkOrderStatus.WORK_ORDER_STATUS_ASSIGNED
    );
  };

  const handleSortClick =
    (initialOrder: SortOrder = SortOrder.DESC) =>
    (nextOrderBy: keyof racehorse360.IWorkOrder, nextOrder: SortOrder) => {
      if (orderBy !== nextOrderBy) {
        onSetOrder(initialOrder);
      } else {
        onSetOrder(nextOrder);
      }
      onSetOrderBy(nextOrderBy);
    };

  const handleRowClick = (workOrder: racehorse360.WorkOrder) => () => {
    onItemClick(workOrder);
  };

  return (
    <Box className={classes.root}>
      {isLoading && <Loader overlay />}
      {!isLoading && !sortedWorkOrders?.length && (
        <Box className={classes.cta}>
          <LogoIcon className={classes.ctaBg} />
          <Box className={classes.ctaContent}>
            <p className={classes.ctaTitle}>No work orders to display</p>
            <p className={classes.ctaSubTitle}>
              Create a work order request for the track maintenance team.
            </p>
            <CreateOrderButton onClick={onOpenNewOrderPopup} />
          </Box>
        </Box>
      )}
      <TableContainer className={classes.tableContainer}>
        <Table stickyHeader>
          <TableHead className={classes.tableHead}>
            <TableRow>
              {headerCells.map(cell => (
                <TableCell
                  className={clsx(classes.tableHeadCell, {
                    [classes.hideDownSX720]: cell.hideDownSX720,
                    [classes.dark]: Boolean(workOrders?.length)
                  })}
                  key={cell.id}
                  align="left"
                >
                  <TableSortLabel
                    key={cell.id}
                    name={cell.id}
                    order={workOrders?.length ? order : undefined}
                    orderBy={workOrders?.length ? orderBy : undefined}
                    onSortClick={handleSortClick(cell.initialOrder)}
                    disabled={!cell.orderBy || !workOrders?.length}
                    hideSortIcon={!cell.orderBy}
                  >
                    {cell.label}
                  </TableSortLabel>
                </TableCell>
              ))}
            </TableRow>
          </TableHead>
          <TableBody>
            {sortedWorkOrders?.map((wo: racehorse360.WorkOrder) => (
              <TableRow
                key={wo.id}
                hover
                selected={wo.id === selectedId}
                classes={{
                  root: classes.tableRow
                }}
                className={clsx(classes.tableRow, {
                  [classes.tableRowActive]: isActualOrder(wo)
                })}
                onClick={handleRowClick(wo)}
                data-test={"work-order-row"}
              >
                <TableCell
                  className={classes.noBreak}
                  align={"left"}
                  component="th"
                  scope="row"
                >
                  {wo.workOrderType.name}
                </TableCell>
                <TableCell className={classes.tableCellDate}>
                  {format(
                    utcToZonedTime(
                      parseISO(wo.createdOn),
                      wo.facility.timezone
                    ),
                    "M/d/yy"
                  )}
                </TableCell>
                <TableCell className={classes.tableCellDate}>
                  {wo.closedOn ? format(parseISO(wo.closedOn), "M/d/yy") : "-"}
                </TableCell>
                <TableCell align="left" className={classes.hideDownSX720}>
                  {wo.customerFacingId}
                </TableCell>
                <TableCell align="left" className={classes.tableCellStatus}>
                  <BuildIcon
                    className={clsx(
                      classes.wrench,
                      classes[`status-${wo.status}`]
                    )}
                  />
                  {parseWorkOrderStatus(wo.status)}
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </Box>
  );
});

export default WorkOrderList;
