import React, { Dispatch, ChangeEvent, useCallback } from "react";

import { Checkbox, FormControlLabel } from "@material-ui/core";

import StableEntryStateCheckbox from "../StableEntryStateCheckbox";
import {
  setDisplayPPs,
  denyAllEntries,
  approveAllEntries,
  clearAllEntries,
  StallApplicationCheckboxType,
  TAction
} from "../helper";
import useStyles from "./styles";

interface IProps {
  shouldDisplayPPs: boolean;
  areAllEntriesDenied: boolean;
  areAllEntriesApproved: boolean;
  entriesNumber: number;
  dispatch: Dispatch<TAction>;
}

const StallApplicationControlPanel = (props: IProps) => {
  const {
    shouldDisplayPPs,
    areAllEntriesDenied,
    areAllEntriesApproved,
    entriesNumber,
    dispatch
  } = props;
  const classes = useStyles();

  const handleDisplayPPs = useCallback(() => {
    dispatch(setDisplayPPs(!shouldDisplayPPs));
  }, [dispatch, shouldDisplayPPs]);

  const handleClearAllEntries = () => {
    dispatch(clearAllEntries());
  };

  const handleDenyAllEntries = useCallback(
    (event: ChangeEvent<HTMLInputElement>, checked: boolean) => {
      if (checked) {
        dispatch(denyAllEntries());
      } else {
        handleClearAllEntries();
      }
    },
    [dispatch]
  );

  const handleApproveAllEntries = useCallback(
    (event: ChangeEvent<HTMLInputElement>, checked: boolean) => {
      if (checked) {
        dispatch(approveAllEntries());
      } else {
        handleClearAllEntries();
      }
    },
    [dispatch]
  );

  const renderCheckBox = (isChecked: boolean) => (
    <Checkbox
      color={"default"}
      className={classes.checkbox}
      checked={isChecked}
    />
  );

  return (
    <div className={classes.root}>
      <div className={classes.header}>
        <div className={classes.title}>Entry Approvals</div>
        <div
          className={classes.entriesCount}
        >{`Entries: ${entriesNumber}`}</div>
      </div>

      <div className={classes.settings}>
        <div className={classes.viewSwitch}>
          <FormControlLabel
            classes={{ label: classes.formControlLabelLabel }}
            control={renderCheckBox(false)}
            label="Display Trainer Application"
          />
          <FormControlLabel
            classes={{ label: classes.formControlLabelLabel }}
            control={renderCheckBox(shouldDisplayPPs)}
            label="Display PPs"
            onChange={handleDisplayPPs}
          />
        </div>

        <div className={classes.selectAllBlock}>
          <div className={classes.selectAllText}>Select All</div>
          <div className={classes.selectAllCheckboxes}>
            <StableEntryStateCheckbox
              type={StallApplicationCheckboxType.DENY}
              checked={areAllEntriesDenied}
              dispatch={dispatch}
              onChange={handleDenyAllEntries}
            />
            <StableEntryStateCheckbox
              type={StallApplicationCheckboxType.APPROVE}
              checked={areAllEntriesApproved}
              dispatch={dispatch}
              onChange={handleApproveAllEntries}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default React.memo(StallApplicationControlPanel);
