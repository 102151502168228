import React, { useState, useRef, useEffect } from "react";
import debounce from "lodash/debounce";
import clsx from "clsx";

import Button from "@material-ui/core/Button";
import Popover from "@material-ui/core/Popover";
import ArrowDropDownIcon from "@material-ui/icons/ArrowDropDown";

import { racehorse360 } from "@tsg/1st-grpc-web";

import { IScrollPositions } from "../HorseInfoContainer";
import GeneralInfo from "./GeneralInfo";
import Performance from "./Performance";
import Health from "./Health";
import Summary from "./Summary";
import {
  checkSelectedMenuItem,
  EExamInfoMenuItems,
  EExamInfoTabs
} from "./helper";
import useStyles from "./styles";

interface IProps {
  horse: racehorse360.IHorse;
  workoutRequest: racehorse360.IWorkoutRequest;
  completedExams: racehorse360.IWorkoutExam[];
  currentMenuItem?: EExamInfoMenuItems;
  onSetCurrentMenuItem?: (value: EExamInfoMenuItems) => void;
  currentPerformanceTab?: EExamInfoTabs;
  onSetCurrentPerformanceTab?: (value: EExamInfoTabs) => void;
  currentHealthTab?: EExamInfoTabs;
  onSetCurrentHealthTab?: (value: EExamInfoTabs) => void;
  onSetTabPosition: (value: IScrollPositions) => void;
  tabPositions: IScrollPositions;
}

const menuItems: EExamInfoMenuItems[] = [
  EExamInfoMenuItems.SUMMARY,
  EExamInfoMenuItems.HEALTH,
  EExamInfoMenuItems.PERFORMANCE,
  EExamInfoMenuItems.GENERAL_INFO
];

const HorseInfo = (props: IProps) => {
  const {
    horse,
    workoutRequest,
    completedExams,
    onSetCurrentMenuItem,
    currentMenuItem,
    currentPerformanceTab,
    onSetCurrentPerformanceTab,
    currentHealthTab,
    onSetCurrentHealthTab,
    tabPositions,
    onSetTabPosition
  } = props;
  const classes = useStyles();
  const menuAnchorRef = useRef(null);
  const contentContainerRef = useRef<HTMLDivElement>(null);

  const [isOpenMenu, setIsOpenMenu] = useState<boolean>(false);
  const [selectedMenuItem, setSelectedMenuItem] = useState<EExamInfoMenuItems>(
    currentMenuItem || menuItems[0]
  );

  const { isGeneralInfo, isPerformance, isHealth, isSummary } =
    checkSelectedMenuItem(selectedMenuItem);

  const handleOpenMenu = () => {
    setIsOpenMenu(true);
  };

  const handleCloseMenu = () => {
    setIsOpenMenu(false);
  };

  const handleSelectMenuItem = (
    event: React.MouseEvent<HTMLButtonElement, MouseEvent>
  ) => {
    const value = event.currentTarget.value;

    setSelectedMenuItem(value as EExamInfoMenuItems);
    onSetCurrentMenuItem && onSetCurrentMenuItem(value as EExamInfoMenuItems);
    setIsOpenMenu(false);
  };

  const handleScrollContentContainer = () => {
    const scrollTop = contentContainerRef.current.scrollTop;

    isGeneralInfo &&
      onSetTabPosition({ ...tabPositions, generalInfoPosition: scrollTop });
    isSummary &&
      onSetTabPosition({ ...tabPositions, summaryPosition: scrollTop });
  };

  const renderMenuContainer = () => {
    return (
      <Popover
        className={classes.menu}
        open={isOpenMenu}
        onClose={handleCloseMenu}
        anchorEl={menuAnchorRef.current}
      >
        {renderMenuItems()}
      </Popover>
    );
  };

  const renderMenuItems = () => {
    return menuItems.map((item: EExamInfoMenuItems) => {
      return (
        <Button
          key={item}
          className={clsx(classes.menuItem, {
            ["selected"]: item === selectedMenuItem
          })}
          onClick={handleSelectMenuItem}
          value={item}
        >
          {item}
        </Button>
      );
    });
  };

  useEffect(() => {
    if (isGeneralInfo) {
      contentContainerRef.current.scrollTop =
        tabPositions?.generalInfoPosition || 0;
    } else if (isSummary) {
      contentContainerRef.current.scrollTop =
        tabPositions?.summaryPosition || 0;
    }
  }, [selectedMenuItem]);

  return (
    <>
      <div className={classes.header}>
        <Button
          ref={menuAnchorRef}
          className={classes.menuButton}
          endIcon={<ArrowDropDownIcon />}
          onClick={handleOpenMenu}
        >
          {selectedMenuItem}
        </Button>

        {renderMenuContainer()}
      </div>
      <div
        className={clsx(classes.content, {
          ["scrollableContainer"]: isSummary || isGeneralInfo
        })}
        ref={contentContainerRef}
        onScroll={debounce(handleScrollContentContainer, 500)}
      >
        {isGeneralInfo && <GeneralInfo horse={horse} />}
        {isPerformance && (
          <Performance
            horse={horse}
            currentTab={currentPerformanceTab}
            onSetCurrentTab={onSetCurrentPerformanceTab}
            tabPositions={tabPositions}
            onSetTabPosition={onSetTabPosition}
          />
        )}
        {isHealth && (
          <Health
            horse={horse}
            currentTab={currentHealthTab}
            onSetCurrentTab={onSetCurrentHealthTab}
            tabPositions={tabPositions}
            onSetTabPosition={onSetTabPosition}
          />
        )}
        {isSummary && (
          <Summary
            horse={horse}
            workoutRequest={workoutRequest}
            completedExams={completedExams}
          />
        )}
      </div>
    </>
  );
};

export default HorseInfo;
