import React from "react";
import { useSelector, useDispatch } from "react-redux";
import { useHistory } from "react-router-dom";
import clsx from "clsx";

import Dialog from "@material-ui/core/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import DialogActions from "@material-ui/core/DialogActions";
import Button from "@material-ui/core/Button";

import { EModalConfirmationTypes } from "interfaces/VetExamState";
import {
  setActiveLink,
  setStoreActiveLinkCandidate
} from "store/actions/sidebar";
import { checkTypeModal, EExamResult } from "../helper";
import useStyles from "./styles";

interface IProps {
  type: EModalConfirmationTypes;
  onCloseModal: () => void;
  closeVetExamSavingState: () => void;
  resetWorkoutExam?: () => void;
  completeWorkoutExam: () => void;
  discardWorkoutExam: () => void;
  horseName: string;
  examResult: EExamResult;
}

const VetExamModal = (props: IProps) => {
  const {
    type,
    onCloseModal,
    closeVetExamSavingState,
    discardWorkoutExam,
    resetWorkoutExam,
    horseName = "Horse Name",
    examResult,
    completeWorkoutExam
  } = props;
  const classes = useStyles();
  const history = useHistory();
  const dispatch = useDispatch();
  const { isSubmitModal, isPauseOrCloseModal, isResetModal } =
    checkTypeModal(type);

  const menuClickedLink = useSelector(
    (state: { sidebar }) => state?.sidebar.activeLinkCandidate
  );

  const redirectToLinkCandidate = () => {
    if (menuClickedLink) {
      dispatch(setActiveLink(menuClickedLink));
      dispatch(setStoreActiveLinkCandidate(""));
      history.push(menuClickedLink);
    }
  };

  const handleClickCancelButton = () => {
    isResetModal && resetWorkoutExam();
    isPauseOrCloseModal && discardWorkoutExam();
    onCloseModal();
    redirectToLinkCandidate();
  };

  const handleClickSuccessButton = () => {
    isSubmitModal && completeWorkoutExam();
    isPauseOrCloseModal && closeVetExamSavingState();
    onCloseModal();
    redirectToLinkCandidate();
  };

  const handleClickBackButton = () => {
    menuClickedLink && dispatch(setStoreActiveLinkCandidate(""));
    onCloseModal();
  };

  const renderContent = () => {
    if (isSubmitModal) {
      return (
        <>
          <div className={classes.contentLabel}>Horse Name</div>
          <div className={classes.contentValue}>{horseName}</div>
          <div className={classes.contentLabel}>Exam Decision</div>
          <div className={classes.contentValue}>{examResult}</div>
        </>
      );
    }

    return (
      <div className={classes.contentText}>
        {isPauseOrCloseModal &&
          "Would you like to pause this exam and continue at a later time?"}
        {isResetModal &&
          `Clear all fields and reset the exam form for ${horseName}?`}
      </div>
    );
  };

  return (
    <Dialog
      className={clsx(classes.modal, {
        ["submit"]: isSubmitModal,
        ["reset"]: isResetModal,
        ["pauseOrClose"]: isPauseOrCloseModal
      })}
      open={true}
      onClose={onCloseModal}
    >
      <DialogTitle className={classes.dialogTitle}>
        {isSubmitModal && "Confirm Submission"}
        {isResetModal && "Reset Exam Form"}
        {isPauseOrCloseModal && "Exam In Progress"}
      </DialogTitle>

      <DialogContent className={classes.dialogContent}>
        {renderContent()}
      </DialogContent>

      <DialogActions className={classes.dialogActions}>
        <Button
          className={classes.actionButton}
          onClick={handleClickBackButton}
          data-test={
            !isResetModal ? "submit-exam-back-button" : "reset-exam-back-button"
          }
        >
          Back
        </Button>

        {!isSubmitModal && (
          <Button
            className={clsx(classes.actionButton, classes.cancelButton)}
            onClick={handleClickCancelButton}
            data-test={
              isResetModal
                ? "reset-exam-reset-button"
                : "pause-or-close-exam-discard-button"
            }
          >
            {isResetModal && "Reset Exam"}
            {isPauseOrCloseModal && "Discard Exam"}
          </Button>
        )}

        {!isResetModal && (
          <Button
            className={clsx(classes.actionButton, classes.successButton)}
            onClick={handleClickSuccessButton}
            data-test={
              isSubmitModal
                ? "submit-exam-confirm-submit-button"
                : "pause-or-close-exam-pause-button"
            }
          >
            {isSubmitModal && "Submit Exam"}
            {isPauseOrCloseModal && "Pause Exam"}
          </Button>
        )}
      </DialogActions>
    </Dialog>
  );
};

export default VetExamModal;
