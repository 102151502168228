import { makeStyles } from "@material-ui/core/styles";
import { MuiTheme } from "theme";
import Breakpoints from "common/breakpoints";

export default makeStyles((theme: MuiTheme) => ({
  formBlock: {
    display: "flex",
    flexDirection: "column",
    gap: 20,
    padding: "4px 24px 10px",
    borderBottom: "1px solid #eee",
    [theme.breakpoints.down(Breakpoints.SM_600)]: {
      padding: "12px 16px 24px"
    }
  },
  darkBackground: {
    backgroundColor: "#f6f6f6"
  },
  formBlockHeader: {
    display: "flex",
    alignItems: "center",
    fontSize: 16,
    color: "#1a1a1a",
    height: 45,
    [theme.breakpoints.down(Breakpoints.SM_600)]: {
      fontSize: 14,
      height: 34,
      marginBottom: 4
    }
  },
  formBlockFooter: {
    height: 43,
    paddingLeft: 16,
    display: "flex",
    alignItems: "center",
    borderLeft: "1px solid #ddd"
  }
}));
