import React, { Dispatch, useContext, useReducer } from "react";

import { racehorse360 } from "@tsg/1st-grpc-web";

import { useRacehorse360Api } from "hooks/api";
import { TAction, IVetExamLocalState, vetExamInitialState } from "../options";
import { reducer } from "../helper";

interface VetExamStateContextModel {
  state: IVetExamLocalState;
  dispatch: Dispatch<TAction>;
  formFieldsItems: racehorse360.IPullWorkoutExamConditionsResponse;
  isFormFieldsItemsLoading: boolean;
}

export interface IProps {
  children: React.ReactChild;
}

const VetExamStateContext = React.createContext<VetExamStateContextModel>({
  state: vetExamInitialState,
  dispatch: () => null,
  formFieldsItems: {},
  isFormFieldsItemsLoading: false
});

export const useVetExamContext = () => useContext(VetExamStateContext);

const VetExamProvider = (props: IProps) => {
  const { children } = props;

  const [state, dispatch] = useReducer(reducer, vetExamInitialState);

  const { usePullWorkoutExamConditions } = useRacehorse360Api();

  const { data: formFieldsItems, isLoading: isFormFieldsItemsLoading } =
    usePullWorkoutExamConditions({});

  return (
    <VetExamStateContext.Provider
      value={{
        state,
        dispatch,
        formFieldsItems,
        isFormFieldsItemsLoading
      }}
    >
      {children}
    </VetExamStateContext.Provider>
  );
};

export default VetExamProvider;
