import React from "react";

import Box from "@material-ui/core/Box";
import Typography from "@material-ui/core/Typography/Typography";
import Divider from "@material-ui/core/Divider/Divider";

import { racehorse360 } from "@tsg/1st-grpc-web";
import LogoIcon from "components/Icons/SapLogo";
import WorkOrderActions from "../WorkOrderActions";
import WorkOrderDetailContent from "../WorkOrderDetailContent";
import useStyles from "./styles";

interface IProps {
  selectedWorkOrder: racehorse360.IWorkOrder;
  hasWorkOrders: boolean;
  onUpdate?: () => void;
  onEditClick?: () => void;
}

const WorkOrderDetail = React.memo((props: IProps) => {
  const { selectedWorkOrder, hasWorkOrders, onUpdate, onEditClick } = props;
  const classes = useStyles();

  return (
    <Box className={classes.workOrderDetail}>
      <Box className={classes.detailHeaderWrapper}>
        <Box className={classes.detailHeader}>
          <Typography className={classes.detailTitle}>
            Work Order Detail
          </Typography>
          <WorkOrderActions
            workOrder={selectedWorkOrder}
            onUpdate={onUpdate}
            onEditClick={onEditClick}
            type={"popover"}
          />
        </Box>
        <Divider />
      </Box>

      {!selectedWorkOrder && (
        <Box className={classes.noResultsBlock}>
          <LogoIcon className={classes.logoIcon} />
          {hasWorkOrders && (
            <Typography className={classes.noSelectedText}>
              Select an order from the table
            </Typography>
          )}
        </Box>
      )}

      {selectedWorkOrder && hasWorkOrders && (
        <WorkOrderDetailContent selectedWorkOrder={selectedWorkOrder} />
      )}
    </Box>
  );
});

export default WorkOrderDetail;
