import React, { useCallback, useRef, useState } from "react";
import clsx from "clsx";
import { round } from "lodash";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router";
import AutoSizer from "react-virtualized-auto-sizer";

import { Theme, useMediaQuery } from "@material-ui/core";

import AppPageContent from "components/AppPageContent";
import AppPageTable from "components/AppPageTable";
import AppPageTableContent from "components/AppPageTableContent";
import AppPageTableHeader from "components/AppPageTableHeader";
import AppPage from "components/AppPage";
import AppPageHeader from "components/AppPageHeader";
import Loader from "components/Loader";
import TableSortLabel from "components/TableSortLabel";
import FilterBar from "components/Filters/FilterBar";
import { getCheckboxesFilterValue } from "components/Filters/helper";

import { racehorse360 } from "@tsg/1st-grpc-web";
import Breakpoints from "common/breakpoints";
import { useRacehorse360Api } from "hooks/api";
import RacingOfficialPCPageState from "interfaces/RacingOfficialPCPageState";
import {
  setAreLastRacePreferredConditions as setStoreAreLastRacePreferredConditions,
  setOrder as setStoreOrder,
  setOrderBy as setStoreOrderBy
} from "store/actions/racingOfficialPCPage";
import {
  setIsOpened as setPreferredConditionsMatchingHorsesSliderIsOpened,
  setSelectedPreferredConditions
} from "store/actions/preferredConditionsMatchingHorsesSlider";
import { clearPreferredConditionsFilters } from "store/actions/racingOfficialPCPage";
import { parseCourseSurface, parseCourseSurfaceForPC } from "utils/enum-parser";
import { getSortFn, pcToString } from "./helpers";
import MatchTypeSelector from "./MatchTypeSelector/MatchTypeSelector";
import PreferredConditionsFilters from "./PreferredConditionsFilters";
import MatchingHorsesSlider from "./MatchingHorsesSlider";
import { useStatePreferredConditionFilters } from "./useStatePreferredConditionFilters";
import useStyles from "./styles";

enum EColumns {
  COUNT = "count",
  SURFACE = "surface",
  DISTANCE = "distance",
  RACE_TYPE = "raceType",
  CLAIMING_PRICE = "claimingPrice",
  AGE = "age",
  GENDER = "gender",
  STATE_BRED = "stateBred",
  CONDITION = "conditions"
}

interface IValueRenderer {
  id?: EColumns;
  renderUpSX720?: (
    data: string | racehorse360.IHorsesCountGroupedByPreferredCondition
  ) => React.ReactNode;
  renderDownSX720?: (
    data: string | racehorse360.IHorsesCountGroupedByPreferredCondition
  ) => React.ReactNode;
}

const valueRenderers: IValueRenderer[] = [
  {
    id: EColumns.COUNT
  },
  {
    id: EColumns.SURFACE,
    renderUpSX720: data => data && parseCourseSurfaceForPC(data as number),
    renderDownSX720: data => data && parseCourseSurface(data as number)
  },
  {
    id: EColumns.DISTANCE,
    renderUpSX720: (data: string) =>
      data && data.replace(/([\d\s/])?( )([A-Z])/, "$1$3"),
    renderDownSX720: (data: string) =>
      data && data.replace(/([\d\s/])?( )([A-Z])/, "$1$3")
  },
  {
    id: EColumns.RACE_TYPE
  },
  {
    id: EColumns.CLAIMING_PRICE,
    renderUpSX720: (data: string) =>
      data &&
      data.replaceAll(
        /\d+,\d{3}/g,
        val => `${round(Number(val.replace(",", "")) / 1000)}K`
      ),
    renderDownSX720: (data: string) =>
      data &&
      data.replaceAll(
        /\d+,\d{3}/g,
        val => `${round(Number(val.replace(",", "")) / 1000)}K`
      )
  },
  {
    id: EColumns.AGE,
    renderDownSX720: (data: string) =>
      data && data.replace(/(\d+)(yo)(.*)/, "$1$3")
  },
  {
    id: EColumns.GENDER
  },
  {
    id: EColumns.STATE_BRED,
    renderDownSX720: (data: string) => data && `${data} SB`
  }
];

interface ICell {
  id: EColumns;
  label: string;
  value: EColumns;
  isNonSortable?: boolean;
  render?: IValueRenderer;
}

const tableColumnsUpSX720: ICell[] = [
  {
    id: EColumns.COUNT,
    label: "Count",
    value: EColumns.COUNT,
    render: valueRenderers.find(vr => vr.id === EColumns.COUNT)
  },
  {
    id: EColumns.SURFACE,
    label: "Surface",
    value: EColumns.SURFACE,
    render: valueRenderers.find(vr => vr.id === EColumns.SURFACE)
  },
  {
    id: EColumns.DISTANCE,
    label: "Distance",
    value: EColumns.DISTANCE,
    render: valueRenderers.find(vr => vr.id === EColumns.DISTANCE)
  },
  {
    id: EColumns.RACE_TYPE,
    label: "Race Type",
    value: EColumns.RACE_TYPE,
    render: valueRenderers.find(vr => vr.id === EColumns.RACE_TYPE)
  },
  {
    id: EColumns.CLAIMING_PRICE,
    label: "Claiming Price",
    value: EColumns.CLAIMING_PRICE,
    render: valueRenderers.find(vr => vr.id === EColumns.CLAIMING_PRICE)
  },
  {
    id: EColumns.AGE,
    label: "Age",
    value: EColumns.AGE,
    render: valueRenderers.find(vr => vr.id === EColumns.AGE)
  },
  {
    id: EColumns.GENDER,
    label: "Gender",
    value: EColumns.GENDER,
    render: valueRenderers.find(vr => vr.id === EColumns.GENDER)
  },
  {
    id: EColumns.STATE_BRED,
    label: "State Bred",
    value: EColumns.STATE_BRED,
    render: valueRenderers.find(vr => vr.id === EColumns.STATE_BRED)
  }
];

const tableColumnsDownSX720: ICell[] = [
  {
    id: EColumns.COUNT,
    label: "#",
    value: EColumns.COUNT
  },
  {
    id: EColumns.CONDITION,
    label: "Condition",
    value: EColumns.CONDITION,
    isNonSortable: true,
    render: {
      renderDownSX720: (
        data: racehorse360.IHorsesCountGroupedByPreferredCondition
      ) =>
        valueRenderers
          .filter(
            renderer =>
              renderer.id !== EColumns.COUNT &&
              !!data[renderer.id] &&
              data[renderer.id] !== "-"
          )
          .map(renderer => (
            <div key={`${pcToString(data)}-${renderer.id}`}>
              {renderer.renderDownSX720
                ? renderer.renderDownSX720(data[renderer.id])
                : data[renderer.id]}
            </div>
          ))
    }
  }
];

interface ILocationState {
  resetMatchingHorsesSliderVisibility?: boolean;
}

const RacingSecretaryHorseList = () => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const history = useHistory<ILocationState>();
  const tableContainerRef = useRef<HTMLDivElement>(null);
  const { usePullHorsesCountGroupedByPreferredConditions } =
    useRacehorse360Api();

  const { order, orderBy, areLastRacePreferredConditions } = useSelector(
    (state: { racingOfficialPCPage: RacingOfficialPCPageState }) =>
      state?.racingOfficialPCPage
  );

  if (history.location.state?.resetMatchingHorsesSliderVisibility) {
    dispatch(setPreferredConditionsMatchingHorsesSliderIsOpened(false));
    dispatch(setSelectedPreferredConditions(null));
  }

  const matchTypeMenuLabel =
    areLastRacePreferredConditions === undefined
      ? "All Types"
      : areLastRacePreferredConditions
      ? "Last Race"
      : "Custom";

  const {
    hasSavedFilterValue,
    daysSinceLastRace,
    selectedSurface,
    selectedGender,
    selectedDistances,
    selectedRaceTypes,
    selectedClaimingPrices,
    selectedAge
  } = useStatePreferredConditionFilters();

  const [isOpenFilters, setIsOpenFilters] = useState<boolean>(false);

  const isScreenSM = useMediaQuery((theme: Theme) =>
    theme.breakpoints.down(Breakpoints.SM_600)
  );

  const tableColumns = !isScreenSM
    ? tableColumnsUpSX720
    : tableColumnsDownSX720;

  const { data, isFetching } = usePullHorsesCountGroupedByPreferredConditions(
    {
      areLastRacePreferredConditions:
        areLastRacePreferredConditions !== undefined
          ? {
              value: areLastRacePreferredConditions
            }
          : undefined,
      minDaysSinceLastRace: daysSinceLastRace[0],
      maxDaysSinceLastRace: daysSinceLastRace[1],
      selectedHorseGender: selectedGender,
      selectedCourseSurface: selectedSurface,
      selectedHorseAges:
        selectedAge && getCheckboxesFilterValue(selectedAge).map(age => +age),
      selectedRaceDistanceIds: getCheckboxesFilterValue(selectedDistances),
      selectedRaceTypeIds: getCheckboxesFilterValue(selectedRaceTypes),
      selectedClaimingPriceIds: getCheckboxesFilterValue(selectedClaimingPrices)
    },
    {
      initialData: {
        horsesCountGroupedByPreferredConditions: [],
        totalUniqueHorsesCount: null
      },
      select: response => {
        response.horsesCountGroupedByPreferredConditions.sort(
          getSortFn(orderBy, order)
        );
        return response;
      }
    }
  );

  const handleSortClick = useCallback(
    (newOrderBy, newOrder) => {
      dispatch(setStoreOrder(newOrder));
      dispatch(setStoreOrderBy(newOrderBy));
    },
    [dispatch]
  );

  const handleMatchTypeChange = useCallback(
    (areLastRacePreferredConditions: boolean) => {
      dispatch(
        setStoreAreLastRacePreferredConditions(areLastRacePreferredConditions)
      );
    },
    [dispatch]
  );

  const handleOpenFilters = () => {
    setIsOpenFilters(true);
  };

  const handleCloseFilters = () => {
    setIsOpenFilters(false);
  };

  const handleClearFilters = () => {
    dispatch(clearPreferredConditionsFilters());
  };

  const renderCell = (
    cell: ICell,
    pc: racehorse360.IHorsesCountGroupedByPreferredCondition
  ) => {
    if (!isScreenSM) {
      return cell.render?.renderUpSX720
        ? cell.render.renderUpSX720(pc[cell.id] as string)
        : pc[cell.id] || "-";
    } else {
      return cell.render?.renderDownSX720
        ? cell.render.renderDownSX720(pc)
        : pc[cell.id] || "-";
    }
  };

  const handleToggleMatchingHorsesSlider =
    (
      isOpen: boolean,
      pc?: racehorse360.IHorsesCountGroupedByPreferredCondition | undefined
    ) =>
    () => {
      dispatch(setPreferredConditionsMatchingHorsesSliderIsOpened(isOpen));
      dispatch(setSelectedPreferredConditions(pc));

      const state = { ...history.location.state };
      delete state.resetMatchingHorsesSliderVisibility;
      history.replace({ ...history.location, state });
    };

  return (
    <>
      <AppPage className={classes.root}>
        <AppPageHeader className={classes.appPageHeader}>
          <MatchTypeSelector
            onChange={handleMatchTypeChange}
            label={matchTypeMenuLabel}
            selectedValue={areLastRacePreferredConditions}
          />

          {Boolean(data?.totalUniqueHorsesCount) && (
            <span className={classes.displayingHorsesCount}>
              {!isScreenSM && "Displaying"}{" "}
              {data.totalUniqueHorsesCount
                .toString()
                .replace(/\B(?=(\d{3})+(?!\d))/g, ",")}{" "}
              Horses
            </span>
          )}

          <FilterBar
            onOpenFilters={handleOpenFilters}
            isOpenFilters={isOpenFilters}
            onClearFilters={handleClearFilters}
            hasSavedFilters={hasSavedFilterValue}
          />
        </AppPageHeader>
        <AppPageContent className={classes.appPageContent}>
          <AppPageTable>
            <AppPageTableHeader
              className={classes.appPageTableHeader}
              aria-label="enhanced table"
              aria-labelledby="tableTitle"
            >
              {tableColumns.map(headCell => (
                <div
                  className={clsx(classes.tableHeaderCell, {
                    [classes.cellRight]: headCell.id === EColumns.STATE_BRED
                  })}
                  key={headCell.id}
                >
                  <TableSortLabel
                    name={headCell.id}
                    order={order}
                    orderBy={orderBy}
                    onSortClick={handleSortClick}
                    hideSortIcon={headCell.isNonSortable}
                    disabled={headCell.isNonSortable}
                  >
                    {headCell.label}
                  </TableSortLabel>
                </div>
              ))}
            </AppPageTableHeader>

            <AppPageTableContent
              rootRef={tableContainerRef}
              className={classes.appPageTableContent}
            >
              {isFetching &&
                !data.horsesCountGroupedByPreferredConditions.length && (
                  <Loader overlay />
                )}
              <AutoSizer>
                {({ height, width }) => (
                  <div
                    className={classes.tableContentContainer}
                    style={{ height, width }}
                  >
                    {data.horsesCountGroupedByPreferredConditions.map(
                      (pc, index) => {
                        const keyBase = pcToString(pc);
                        return (
                          <div
                            className={clsx(
                              classes.tableBodyRow,
                              index % 2 ? classes.rowOdd : classes.rowEven
                            )}
                            key={keyBase}
                            onClick={handleToggleMatchingHorsesSlider(true, pc)}
                          >
                            {tableColumns.map(cell => (
                              <div
                                role="cell"
                                key={keyBase + cell.id}
                                className={clsx(classes.tableBodyCell, {
                                  [classes.cellRight]:
                                    cell.id === EColumns.STATE_BRED &&
                                    !isScreenSM
                                })}
                              >
                                {renderCell(cell, pc)}
                              </div>
                            ))}
                          </div>
                        );
                      }
                    )}
                  </div>
                )}
              </AutoSizer>
            </AppPageTableContent>
          </AppPageTable>
        </AppPageContent>

        {isOpenFilters && (
          <PreferredConditionsFilters onClose={handleCloseFilters} />
        )}

        <MatchingHorsesSlider />
      </AppPage>
    </>
  );
};

export default RacingSecretaryHorseList;
