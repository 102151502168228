import React from "react";
import clsx from "clsx";

import useStyles from "./styles";

interface IProps {
  title: string;
  children: React.ReactNode;
  actionsComponent?: React.ReactNode;
  isDarkBackground?: boolean;
  className?: string;
}

const ExamFormBlock = (props: IProps) => {
  const {
    title,
    children,
    actionsComponent,
    isDarkBackground = false,
    className
  } = props;
  const classes = useStyles();

  return (
    <div
      className={clsx(className, classes.formBlock, {
        [classes.darkBackground]: isDarkBackground
      })}
    >
      <div className={classes.formBlockHeader}>{title}</div>

      {children}

      {actionsComponent && (
        <div className={classes.formBlockFooter}>{actionsComponent}</div>
      )}
    </div>
  );
};

export default ExamFormBlock;
