import { racehorse360 } from "@tsg/1st-grpc-web";

import {
  PreferredConditionsPageType,
  SET_ORDER,
  SET_ORDER_BY,
  SET_PREFERRED_AGE,
  SET_PREFERRED_CONDITION,
  SET_PREFERRED_DISTANCE,
  SET_PREFERRED_GENDER,
  SET_PREFERRED_SURFACE,
  SET_PREFERRED_VALUES,
  SET_SEARCH_QUERY,
  SET_TRAINER_SEARCH_ITEM
} from "store/actions/preferredConditionsPage";
import { SortOrder } from "interfaces/SortOrder";
import { SearchTypeKey } from "interfaces/SearchType";

interface PreferredConditionsPageReducer {
  order: SortOrder;
  orderBy: string[];
  searchQuery: string;
  searchTypeKey: SearchTypeKey;
  trainerSearchItem: racehorse360.ITrainer | null;
  preferredDistance: string;
  preferredSurface: string;
  preferredCondition: string;
  preferredAge: string;
  preferredGender: string;
}

const initialState: PreferredConditionsPageReducer = {
  order: SortOrder.ASC,
  orderBy: ["name"],
  searchQuery: "",
  searchTypeKey: "horses",
  trainerSearchItem: null,
  preferredDistance: "",
  preferredSurface: "",
  preferredCondition: "",
  preferredAge: "",
  preferredGender: ""
};

export default (
  state = initialState,
  action: PreferredConditionsPageType
): PreferredConditionsPageReducer => {
  switch (action.type) {
    case SET_ORDER:
      return {
        ...state,
        order: action.order
      };
    case SET_ORDER_BY:
      return {
        ...state,
        orderBy: action.orderBy
      };
    case SET_PREFERRED_DISTANCE:
      return {
        ...state,
        preferredDistance: action.preferredDistance
      };
    case SET_PREFERRED_SURFACE:
      return {
        ...state,
        preferredSurface: action.preferredSurface
      };
    case SET_PREFERRED_CONDITION:
      return {
        ...state,
        preferredCondition: action.preferredCondition
      };
    case SET_PREFERRED_AGE:
      return {
        ...state,
        preferredAge: action.preferredAge
      };
    case SET_PREFERRED_GENDER:
      return {
        ...state,
        preferredGender: action.preferredGender
      };
    case SET_SEARCH_QUERY:
      return {
        ...state,
        searchQuery: action.searchQuery
      };
    case SET_TRAINER_SEARCH_ITEM:
      return {
        ...state,
        trainerSearchItem: action.trainerSearchItem
      };
    case SET_PREFERRED_VALUES:
      return {
        ...state,
        preferredAge: action.preferredAllParams["Age"],
        preferredGender: action.preferredAllParams["Sex"],
        preferredDistance: action.preferredAllParams["Distance"],
        preferredSurface: action.preferredAllParams["Surface"],
        preferredCondition: action.preferredAllParams["Condition"]
      };
    default:
      return state;
  }
};
