import React, { useState } from "react";
import clsx from "clsx";

import { useRouteMatch } from "react-router-dom";
import { useQueryClient } from "react-query";
import format from "date-fns/format";
import Button from "@material-ui/core/Button";
import CloseIcon from "@material-ui/icons/Close";
import Dialog from "@material-ui/core/Dialog";
import DialogContent from "@material-ui/core/DialogContent";
import DialogActions from "@material-ui/core/DialogActions";
import DialogTitle from "@material-ui/core/DialogTitle";
import IconButton from "@material-ui/core/IconButton";
import FlagIcon from "@material-ui/icons/Flag";
import CheckIcon from "@material-ui/icons/Check";

import { racehorse360 } from "@tsg/1st-grpc-web";

import Loader from "components/Loader";
import Textarea from "components/Textarea";
import { useRacehorse360Api } from "hooks/api";
import { useLoggedInUser } from "components/LoggedInUserProvider";
import { getPersonFullName } from "utils/person";
import NoteEdit from "components/Icons/NoteEdit";
import { GET_HORSE_QUERY_PREFIX } from "../../VetHorseDetailsPage";
import routes from "common/routes";
import useStyles from "./styles";

interface IProps {
  horse: racehorse360.IHorse;
  refetchComments: () => void;
}

const FlagOnTrack = (props: IProps) => {
  const { horse, refetchComments } = props;
  const isFlaggedOnTrack = horse.flagOnTrack;
  const commentAuthor = getPersonFullName(
    isFlaggedOnTrack?.flagOnTrackComment.updatedBy
  );
  const commentDate = isFlaggedOnTrack?.flagOnTrackComment.updatedOn;

  const classes = useStyles();
  const queryClient = useQueryClient();
  const [open, setOpen] = useState<boolean>(false);
  const [note, setNote] = useState<string>(
    isFlaggedOnTrack?.flagOnTrackComment.body || ""
  );
  const { currentUser } = useLoggedInUser();
  const match = useRouteMatch<{ horseId: string }>(routes.horseDetails.path);
  const paramsHorseId = match.params.horseId;

  const [editMode, setEditMode] = useState(false);
  const [isNewFlag, setIsNewFlag] = useState(false);

  const {
    useCreateHorseFlagOnTrack,
    useDeleteHorseFlagOnTrack,
    useEditHorseFlagOnTrack
  } = useRacehorse360Api();

  const {
    mutateAsync: createHorseFlagOnTrack,
    isLoading: isCreateHorseFlagOnTrackLoading
  } = useCreateHorseFlagOnTrack();

  const {
    mutateAsync: deleteHorseFlagOnTrack,
    isLoading: isDeleteHorseFlagOnTrackLoading
  } = useDeleteHorseFlagOnTrack();

  const {
    mutateAsync: editHorseFlagOnTrack,
    isLoading: isEditHorseFlagOnTrackLoading
  } = useEditHorseFlagOnTrack();

  const isLoading =
    isCreateHorseFlagOnTrackLoading ||
    isDeleteHorseFlagOnTrackLoading ||
    isEditHorseFlagOnTrackLoading;

  const getIsSameUser = () =>
    currentUser.rh360Id === isFlaggedOnTrack.flagOnTrackComment.createdBy.id;

  const onOpen = () => {
    if (isFlaggedOnTrack) {
      setNote(isFlaggedOnTrack.flagOnTrackComment.body);
    }
    setOpen(true);
  };

  const onClose = () => {
    setOpen(false);
    setEditMode(false);
    setNote("");
  };

  const handleNoteChange = value => {
    setNote(value);
  };

  const handleSave = () => {
    createHorseFlagOnTrack({
      horseId: horse.id,
      body: note.trim()
    }).then(() => {
      setNote("");
      setEditMode(false);
      setOpen(false);
      refetchComments();
      return queryClient.invalidateQueries([
        `${GET_HORSE_QUERY_PREFIX}${paramsHorseId}`
      ]);
    });
  };

  const handleUpdate = () => {
    editHorseFlagOnTrack({
      id: isFlaggedOnTrack.id,
      body: note.trim()
    }).then(() => {
      onClose();
      refetchComments();
      return queryClient.invalidateQueries([
        `${GET_HORSE_QUERY_PREFIX}${paramsHorseId}`
      ]);
    });
  };

  const handleDelete = () => {
    deleteHorseFlagOnTrack({
      id: isFlaggedOnTrack.id
    }).then(() => {
      return queryClient.invalidateQueries([
        `${GET_HORSE_QUERY_PREFIX}${paramsHorseId}`
      ]);
    });
  };

  const handleEditButton = () => {
    setEditMode(true);
  };

  const handleNewFlag = () => {
    setNote("");
    setIsNewFlag(true);
    setEditMode(true);
  };

  const renderSaveButton = () => {
    const currentSaveFunction = isNewFlag ? handleSave : handleUpdate;
    return (
      editMode && (
        <Button className={classes.buttonSave} onClick={currentSaveFunction}>
          SAVE
        </Button>
      )
    );
  };

  return (
    <>
      <Button
        className={clsx({
          [classes.FOTButton]: true,
          [classes.FOTButton__activeFOT]: isFlaggedOnTrack
        })}
        onClick={onOpen}
      >
        {isFlaggedOnTrack ? (
          <>
            <CheckIcon
              className={classes.activeFlaggedIcon}
              data-test={"checked-icon"}
            />
            FLAGGED (FOT)
          </>
        ) : (
          <>
            <FlagIcon
              className={classes.activeFlaggedIcon}
              data-test={"flagged-icon"}
            />
            FLAG ON TRACK
          </>
        )}
      </Button>
      <Dialog
        classes={{
          paper: classes.dialogPaper
        }}
        open={open}
      >
        {isLoading && <Loader overlay />}
        <DialogTitle className={classes.dialogTitle}>
          Flag On Track
          <IconButton
            color="primary"
            onClick={onClose}
            size={"small"}
            classes={{
              root: classes.dialogCloseButton
            }}
          >
            <CloseIcon className={classes.dialogCloseButtonIcon} />
          </IconButton>
        </DialogTitle>
        <DialogContent className={classes.dialogContent}>
          <>
            <b className={classes.horseName}>{horse.name.toUpperCase()}</b>
            <span className={classes.trainerName}>
              {getPersonFullName(horse.trainer)}
            </span>
            <div className={classes.flagCommentHeader}>
              Flag Comment
              {isFlaggedOnTrack && getIsSameUser() && !editMode && (
                <IconButton
                  className={classes.noteEditButton}
                  onClick={handleEditButton}
                >
                  <NoteEdit className={classes.noteEditIcon} />
                </IconButton>
              )}
            </div>
            {isFlaggedOnTrack && !editMode && (
              <div className={classes.textarea}>
                <p className={classes.authorDate}>
                  <span>{commentAuthor + " "}</span>
                  <span>
                    {format(new Date(commentDate), "MM/dd/yyyy - hh:mm aa")}
                  </span>
                </p>
                <p className={classes.flagComment}>{note}</p>
              </div>
            )}
            {(!isFlaggedOnTrack || editMode) && (
              <Textarea
                className={classes.textarea}
                maxLength={90}
                label={format(new Date(), "MM/dd-yyyy - hh:mm aa")}
                placeholder={"Add Note"}
                rows={7}
                value={note}
                defaultValue={note}
                onChange={handleNoteChange}
              />
            )}
          </>
        </DialogContent>
        <DialogActions
          className={clsx({
            [classes.dialogActions]: true,
            [classes.dialogActionsAlt]: isFlaggedOnTrack && !editMode
          })}
        >
          <div>
            {isFlaggedOnTrack && !editMode ? (
              <>
                <Button className={classes.buttonDelete} onClick={handleDelete}>
                  REMOVE
                </Button>
                <Button className={classes.buttonSave} onClick={handleNewFlag}>
                  NEW FLAG
                </Button>
              </>
            ) : (
              <Button className={classes.buttonCancel} onClick={onClose}>
                CANCEL
              </Button>
            )}
            {isFlaggedOnTrack ? (
              renderSaveButton()
            ) : (
              <Button className={classes.buttonSave} onClick={handleSave}>
                FLAG
              </Button>
            )}
          </div>
        </DialogActions>
      </Dialog>
    </>
  );
};

export default FlagOnTrack;
