import { racehorse360 } from "@tsg/1st-grpc-web";
import { useCallback, useState } from "react";
import { useQueryClient } from "react-query";
import { useRacehorse360Api } from "hooks/api";
import * as queries from "./queries";

interface PageStateHookReturnValue {
  trainerSearchDialogOpen: boolean;
  setTrainer: (trainer: racehorse360.ITrainer) => void;
  confirmTrainer: () => void;
  cancelApproveRole: () => void;
  isLoading: boolean;
  userRoleRequests: racehorse360.IUserRoleRequest[];
  approveUserRoleRequest: (
    userRoleRequest: racehorse360.IUserRoleRequest
  ) => Promise<racehorse360.IUserRoleRequest>;
  rejectUserRoleRequest: (
    userRoleRequest: racehorse360.IUserRoleRequest
  ) => Promise<racehorse360.IUserRoleRequest>;
  fetchNextPage: () => void;
  isFetchingNextPage: boolean;
  hasNextPage: boolean;
}

export function usePageState(userInfo: string): PageStateHookReturnValue {
  const {
    useInfiniteListUserRoleRequests,
    useApproveUserRoleRequest,
    useRejectUserRoleRequest
  } = useRacehorse360Api();
  const queryCache = useQueryClient();
  const [trainerSearchDialogOpen, setTrainerSearchDialogOpen] = useState(false);
  const [trainer, setTrainer] = useState<racehorse360.ITrainer | null>(null);
  const [userRoleRequestPending, setUserRoleRequestPending] =
    useState<racehorse360.IUserRoleRequest | null>(null);

  const request = {
    ...queries.pendingUserRoleRequests,
    query: {
      ...queries.pendingUserRoleRequests.query,
      userInfo: userInfo?.length >= 3 ? userInfo.trim() : undefined
    }
  };

  const {
    fetchNextPage,
    isFetchingNextPage,
    hasNextPage,
    isLoading: roleRequestsLoading,
    data
  } = useInfiniteListUserRoleRequests(
    "PENDING_USER_ROLE_REQUESTS",
    request,
    {}
  );

  const userRoleRequests: racehorse360.IUserRoleRequest[] = data?.pages
    ? data.pages.flat()
    : [];

  const { mutateAsync: approveMutation } = useApproveUserRoleRequest({
    onSuccess: async () => {
      setTrainer(null);
      setTrainerSearchDialogOpen(false);
      await queryCache.invalidateQueries("PENDING_USER_ROLE_REQUESTS");
    }
  });
  const { mutateAsync: rejectMutation } = useRejectUserRoleRequest({
    onSuccess: async () => {
      setTrainer(null);
      setTrainerSearchDialogOpen(false);
      await queryCache.invalidateQueries("PENDING_USER_ROLE_REQUESTS");
    }
  });

  const approveUserRoleRequest = useCallback(
    (userRoleRequest: racehorse360.IUserRoleRequest) => {
      if (
        userRoleRequest.role.roleType ===
        racehorse360.RoleType.ROLE_TYPE_TRAINER
      ) {
        setUserRoleRequestPending(userRoleRequest);
        setTrainerSearchDialogOpen(true);
      } else {
        return approveMutation({
          id: userRoleRequest.id
        });
      }
    },
    [approveMutation]
  );

  const rejectUserRoleRequest = useCallback(
    (userRoleRequest: racehorse360.IUserRoleRequest) => {
      return rejectMutation({
        id: userRoleRequest.id
      });
    },
    [rejectMutation]
  );

  const cancelApproveRole = useCallback(() => {
    setTrainerSearchDialogOpen(false);
    setTrainer(null);
  }, []);

  const confirmTrainer = useCallback(() => {
    return approveMutation({
      ...userRoleRequestPending,
      trainerId: trainer.id
    });
  }, [trainer, userRoleRequestPending, approveMutation]);

  return {
    trainerSearchDialogOpen,
    setTrainer,
    confirmTrainer,
    cancelApproveRole,
    isLoading: roleRequestsLoading,
    userRoleRequests,
    approveUserRoleRequest,
    rejectUserRoleRequest,
    fetchNextPage,
    isFetchingNextPage,
    hasNextPage
  };
}
