import React, { useState } from "react";
import format from "date-fns/format";
import parseISO from "date-fns/parseISO";
import Box from "@material-ui/core/Box";
import IconButton from "@material-ui/core/IconButton";
import Typography from "@material-ui/core/Typography";
import { racehorse360 } from "@tsg/1st-grpc-web";
import HorseShoe from "components/Icons/HorseShoe";
import Dialog from "components/Dialog";
import useStyles from "./styles";
import DialogContent from "@material-ui/core/DialogContent";
interface Props {
  horseName: string;
  horseOnLists: racehorse360.IHorseOnList[];
}

const HorseListsModal = React.memo((props: Props) => {
  const { horseName, horseOnLists } = props;
  const classes = useStyles();

  const [isListsOpen, setIsListsOpen] = useState<boolean>(false);

  const handleHorseshoeClick = e => {
    e.stopPropagation();
    setIsListsOpen(true);
  };

  const handleListsClose = e => {
    e.stopPropagation();
    setIsListsOpen(false);
  };

  const handleDialogClick = e => e.stopPropagation();

  return (
    <div onClick={handleDialogClick}>
      <IconButton
        onClick={handleHorseshoeClick}
        className={classes.horseshoeButton}
        data-test={"horseshoe-button"}
      >
        <HorseShoe className={classes.horseShoe} />
      </IconButton>
      {isListsOpen && (
        <Dialog
          title={horseName}
          onClose={handleListsClose}
          titleClassName={classes.modalTitleClassName}
          className={classes.listsModal}
          isOpen={isListsOpen}
        >
          <DialogContent>
            <Typography component={"p"} className={classes.detailsDialogText}>
              Active List Activity
            </Typography>
            {horseOnLists?.map(horseOnList => {
              return (
                <Box key={horseOnList.id} className={classes.activityLine}>
                  <Box>{horseOnList.listType}</Box>
                  <Box className={classes.detailsDialogDate}>
                    {format(parseISO(horseOnList.dateOn), "M/d/yy")}
                  </Box>
                </Box>
              );
            })}
          </DialogContent>
        </Dialog>
      )}
    </div>
  );
});

export default HorseListsModal;
