import React, { useCallback, useRef, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import clsx from "clsx";
import Grid from "@material-ui/core/Grid";
import { useTheme } from "@material-ui/core";
import useMediaQuery from "@material-ui/core/useMediaQuery";

import { racehorse360 } from "@tsg/1st-grpc-web";
import AppPage from "components/AppPage";
import AppPageHeader from "components/AppPageHeader";
import AppPageContent from "components/AppPageContent";
import AppSearch from "components/AppSearch";
import { AppSearchType } from "components/AppSearch/AppSearch";
import ErrorBoundary from "components/ErrorBoundary";
import HorseListSettings from "components/HorseListSettings";
import Loader from "components/Loader/Loader";
import { useLoggedInUser } from "components/LoggedInUserProvider";
import SearchNoResults from "components/SearchNoResults";
import { useSnackbar } from "components/SnackbarContext/SnackbarContext";
import { useRacehorse360Api } from "hooks/api";
import { useInfiniteScroll } from "hooks/useInfiniteScroll";
import VetHorseListPageState from "interfaces/VetHorseListPageState";
import { SortOrder } from "interfaces/SortOrder";
import { MuiTheme } from "theme";
import {
  setOrder as setStoreOrder,
  setOrderBy as setStoreOrderBy,
  setSearchQuery as setStoreSearchQuery,
  setTrainerSearchItem as setStoreTrainerSearchItem
} from "store/actions/vetHorseListPage";

import HorseList from "./HorseList";
import useStyles from "./styles";

const RECORDS_PER_PAGE = 75;

const VetHorseListPage = () => {
  const classes = useStyles();
  const theme = useTheme<MuiTheme>();
  const matchesDownSm = useMediaQuery(theme.breakpoints.down("sm"), {
    noSsr: true
  });
  const tableBodyRef = useRef<HTMLDivElement>(null);
  const dispatch = useDispatch();
  const { showErrorSnack } = useSnackbar();
  const { order, orderBy, searchQuery } = useSelector(
    (state: { vetHorseListPage: VetHorseListPageState }) =>
      state?.vetHorseListPage
  );

  const [clearSearch, setClearSearch] = useState<boolean>(false);

  const { useInfiniteListHorses } = useRacehorse360Api();
  const { currentUser } = useLoggedInUser();

  let currentFacilityIds;
  if (currentUser.isVeterinarian) {
    currentFacilityIds = currentUser.vetFacilityIds;
  } else if (currentUser.isRacingOfficial) {
    currentFacilityIds = currentUser.racingOfficialFacilityIds;
  }

  const query: racehorse360.IHorseFilter = {
    horseOrTrainerName: searchQuery?.length
      ? { contains: searchQuery }
      : undefined,
    facilityIds: currentFacilityIds
  };

  const {
    isLoading: horsesSearchLoading,
    fetchNextPage,
    isFetchingNextPage,
    hasNextPage,
    data
  } = useInfiniteListHorses(
    {
      query: query,
      pagingOptions: {
        maxResults: RECORDS_PER_PAGE,
        includeSummary: true
      },
      getOptions: {
        select: [
          "name",
          "trainer.firstName",
          "trainer.middleName",
          "trainer.lastName",
          "trainer.type",
          "stall.name",
          "barn.name",
          "highSpeedFurlongs",
          "currentFacility.workout_required_days_off",
          "lastRaceDate",
          "lastWorkoutDate",
          "lastWorkDate",
          "healthWatchStatus",
          "riskFactors",
          "riskFactorsCount"
        ],
        orderBy: orderBy.map(ob => `${ob} ${order}`)
      }
    },
    {
      onError: err => {
        showErrorSnack(err.message);
      }
    }
  );

  const horses: racehorse360.IHorse[] = data?.pages ? data.pages.flat() : [];

  useInfiniteScroll(
    tableBodyRef,
    fetchNextPage,
    hasNextPage,
    isFetchingNextPage,
    true,
    true
  );

  const handleSort = useCallback(
    (newOrderBy: string[], order: SortOrder) => {
      dispatch(setStoreOrder(order));
      dispatch(setStoreOrderBy(newOrderBy));
    },
    [dispatch, order, orderBy]
  );

  const handleClear = useCallback(() => {
    dispatch(setStoreSearchQuery(""));
    dispatch(setStoreTrainerSearchItem(null));
    setClearSearch(true);
  }, [dispatch]);

  const handleAppSearch = (value: string) => {
    dispatch(setStoreSearchQuery(value));
  };

  useEffect(() => {
    return () => {
      dispatch(setStoreSearchQuery(""));
    };
  }, []);

  return (
    <AppPage>
      <AppPageHeader className={classes.header}>
        <Grid
          container
          alignItems={"center"}
          justifyContent={"space-between"}
          classes={{
            root: classes.gridRoot,
            "spacing-xs-3": classes.gridItem
          }}
        >
          <Grid item md={3} />
          <Grid item xs={10} sm={8} md={6} lg={5}>
            <ErrorBoundary>
              <AppSearch
                isForceSearch
                className={classes.appSearch}
                placeholder="Search Horses and Trainers"
                onSearch={handleAppSearch}
                searchType={AppSearchType.Both}
                clearSearch={clearSearch}
                onClearSearch={setClearSearch}
              />
            </ErrorBoundary>
          </Grid>
          <Grid item xs={2} sm={4} md={3}>
            {matchesDownSm && (
              <ErrorBoundary>
                <HorseListSettings />
              </ErrorBoundary>
            )}
          </Grid>
        </Grid>
      </AppPageHeader>
      <AppPageContent className={classes.appPageContent}>
        <ErrorBoundary>
          {Boolean(horses?.length) && (
            <HorseList
              onSortClick={handleSort}
              horses={horses}
              order={order}
              orderBy={orderBy}
              tableBodyRef={tableBodyRef}
            />
          )}
          {!horses?.length && !horsesSearchLoading && (
            <SearchNoResults value={searchQuery} onClick={handleClear} />
          )}
        </ErrorBoundary>
        <Loader
          className={clsx(classes.infiniteLoader, {
            [classes.showInfiniteLoader]: isFetchingNextPage,
            [classes.showOverlayLoader]: horsesSearchLoading
          })}
        />
      </AppPageContent>
    </AppPage>
  );
};

export default VetHorseListPage;
