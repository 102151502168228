export enum EModalConfirmationTypes {
  SUBMIT = "SUBMIT",
  PAUSE_OR_CLOSE = "PAUSE_OR_CLOSE",
  RESET = "RESET"
}

export interface IVetExamState {
  modalState: boolean;
  isExamActive: boolean;
  modalType: EModalConfirmationTypes;
}
