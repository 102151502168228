import React from "react";
import { useDispatch, useSelector } from "react-redux";

import ErrorBoundary from "components/ErrorBoundary";
import Popover from "components/Popover";
import Loader from "components/Loader";
import PopupBodyFOT from "./PopupBodyFOT";
import {
  setCommentsPopoverAnchorEl,
  setIsFOTCommentDisplayed,
  setSelectedWorkoutRequest
} from "store/actions/vetWorkoutsPage";
import { useRacehorse360Api } from "hooks/api";
import VetWorkoutsPageState from "interfaces/VetWorkoutsPageState";
import PopoverBodyComments from "./PopoverBodyComments";
import useStyles from "./styles";

const VetWorkoutPopover = () => {
  const classes = useStyles();
  const dispatch = useDispatch();

  const {
    selectedWorkoutRequest,
    commentsPopoverAnchorEl,
    isFOTCommentDisplayed
  } = useSelector(
    (state: { vetWorkoutsPage: VetWorkoutsPageState }) => state?.vetWorkoutsPage
  );
  const flagOnTrackCommentId =
    selectedWorkoutRequest?.horse?.flagOnTrack?.flagOnTrackComment?.id;

  const { useGetHorseFlagOnTrackComment } = useRacehorse360Api();

  const {
    data: horseFlagOnTrackComment,
    isLoading: isGetHorseFlagOnTrackCommentLoading
  } = useGetHorseFlagOnTrackComment(
    {
      id: flagOnTrackCommentId,
      getOptions: {
        select: ["updatedOn", "updatedBy", "body"]
      }
    },
    {
      enabled: [isFOTCommentDisplayed, flagOnTrackCommentId].every(Boolean)
    }
  );

  const handlePopoverClose = () => {
    dispatch(setCommentsPopoverAnchorEl(null));
    dispatch(setSelectedWorkoutRequest(null));
    dispatch(setIsFOTCommentDisplayed(false));
  };

  const renderTooltipContent = () => {
    if (isFOTCommentDisplayed) {
      if (isGetHorseFlagOnTrackCommentLoading) {
        return <Loader className={classes.popoverLoader} />;
      }
      return <PopupBodyFOT comment={horseFlagOnTrackComment} />;
    } else {
      return (
        <PopoverBodyComments selectedWorkoutRequest={selectedWorkoutRequest} />
      );
    }
  };
  return (
    <ErrorBoundary>
      {selectedWorkoutRequest && (
        <Popover
          anchorEl={commentsPopoverAnchorEl}
          onClose={handlePopoverClose}
        >
          {renderTooltipContent()}
        </Popover>
      )}
    </ErrorBoundary>
  );
};

export default VetWorkoutPopover;
