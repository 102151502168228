import { racehorse360 } from "@tsg/1st-grpc-web";
import { SortOrderType } from "interfaces/SortOrder";
import VetHorseListPageState from "interfaces/VetHorseListPageState";

const base = "VET_HORSE_LIST_PAGE";
export const SET_ORDER = `${base}_SET_ORDER`;
export const SET_ORDER_BY = `${base}_SET_ORDER_BY`;
export const SET_SEARCH_QUERY = `${base}_SET_SEARCH_QUERY`;
export const SET_TRAINER_SEARCH_ITEM = `${base}_SET_TRAINER_SEARCH_ITEM`;

export interface VetHorseListPageType extends VetHorseListPageState {
  type: string;
}

export const setOrder = (
  order: SortOrderType
): Partial<VetHorseListPageType> => {
  return {
    type: SET_ORDER,
    order
  };
};

export const setOrderBy = (
  orderBy: string[]
): Partial<VetHorseListPageType> => {
  return {
    type: SET_ORDER_BY,
    orderBy
  };
};

export const setSearchQuery = (
  searchQuery: string
): Partial<VetHorseListPageType> => {
  return {
    type: SET_SEARCH_QUERY,
    searchQuery
  };
};

export const setTrainerSearchItem = (
  trainerSearchItem: racehorse360.IHorse | racehorse360.ITrainer
): Partial<VetHorseListPageType> => {
  return {
    type: SET_TRAINER_SEARCH_ITEM,
    trainerSearchItem
  };
};
