import React from "react";
import clsx from "clsx";
import format from "date-fns/format";
import parseISO from "date-fns/parseISO";

import Accordion from "@material-ui/core/Accordion";
import AccordionDetails from "@material-ui/core/AccordionDetails";
import AccordionSummary from "@material-ui/core/AccordionSummary";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";

import { racehorse360 } from "@tsg/1st-grpc-web";

import MUIAccordionCustomExpandIcon from "components/MUIAccordionCustomExpandIcon";
import { FIELD_TYPE } from "components/FormField/constants";
import { DASH } from "common/constants";
import { IState, reviewModules } from "../constants";
import useStyles from "../styles";

interface IProps {
  values: IState;
  stablingEntries: racehorse360.IStableEntry[];
  facilityOptions?: racehorse360.IFacility[];
}

const Review = (props: IProps) => {
  const { values, stablingEntries, facilityOptions } = props;
  const classes = useStyles();

  const renderFieldPreview = field => {
    let value = "";

    switch (field.type) {
      case FIELD_TYPE.TEXTAREA:
      case FIELD_TYPE.PHONE:
      case FIELD_TYPE.TEXT:
        if (field.values) {
          value = field.values.map((properties, i) => (
            <span key={i}>
              {properties
                .map(propertyName => values[propertyName] || DASH)
                .join(", ")}
            </span>
          ));
        } else {
          value = values[field.name] || DASH;
        }
        break;
      case FIELD_TYPE.DATEPICKER:
        value = values[field.name]
          ? format(values[field.name], "M/d/yy")
          : DASH;
        break;
      case FIELD_TYPE.SELECT:
        value = facilityOptions?.find(f => f.id === values[field.name])?.name;
        break;
      case FIELD_TYPE.BOOLCHOICE:
        value = values[field.name] ? "Yes" : "No";
        break;
      case FIELD_TYPE.NUMBER:
        value = values[field.name];
        break;
    }

    return (
      <p key={field.name} className={classes.previewRow}>
        <span>{field.previewLabel || field.label}</span>
        <span className={classes.previewRowValue}>{value}</span>
      </p>
    );
  };

  const renderStablingEntry = (stablingEntry, i) => (
    <TableRow
      key={stablingEntry.horseId || stablingEntry.horse?.id}
      className={classes.stablingEntryTableRow}
    >
      <TableCell className={clsx(classes.stablingEntryTableCell)} align="left">
        {i + 1}
      </TableCell>
      <TableCell className={clsx(classes.stablingEntryTableCell)} align="left">
        {stablingEntry.horse?.name || stablingEntry.customHorseName}
      </TableCell>
      <TableCell className={clsx(classes.stablingEntryTableCell)} align="left">
        {stablingEntry.raceReadyDate
          ? format(parseISO(stablingEntry.raceReadyDate), "M/dd/yyyy")
          : DASH}
      </TableCell>
    </TableRow>
  );

  return (
    <div className={classes.preview}>
      <Accordion
        defaultExpanded={true}
        className={clsx(classes.section, classes.reviewSectionDetails)}
      >
        <AccordionSummary
          expandIcon={<MUIAccordionCustomExpandIcon />}
          classes={{
            root: classes.sectionHeader,
            expanded: classes.sectionHeaderExpanded,
            content: classes.sectionHeaderContent
          }}
        >
          Trainer Details
        </AccordionSummary>
        <AccordionDetails className={classes.previewSection}>
          {reviewModules.map(module => (
            <div key={module.name} className={classes.previewGroup}>
              <p className={classes.previewGroupTitle}>{module.title}</p>
              <div className={classes.previewGroupList}>
                {module.fields.map(field => renderFieldPreview(field))}
              </div>
            </div>
          ))}
        </AccordionDetails>
      </Accordion>
      <Accordion
        defaultExpanded={true}
        className={clsx(classes.section, classes.reviewSectionStables)}
      >
        <AccordionSummary
          expandIcon={<MUIAccordionCustomExpandIcon />}
          classes={{
            root: classes.sectionHeader,
            expanded: classes.sectionHeaderExpanded,
            content: classes.sectionHeaderContent
          }}
        >
          Stable Entries
        </AccordionSummary>
        <AccordionDetails className={classes.fieldsGroup}>
          <Table>
            <TableHead>
              <TableRow className={classes.stablingEntryTableHeadRow}>
                {["#", "Name", "Race Ready"].map(label => {
                  return (
                    <TableCell
                      key={label}
                      className={classes.stablingEntryTableCell}
                      align="left"
                    >
                      {label}
                    </TableCell>
                  );
                })}
              </TableRow>
            </TableHead>
            <TableBody>{stablingEntries?.map(renderStablingEntry)}</TableBody>
          </Table>
        </AccordionDetails>
      </Accordion>
    </div>
  );
};

export default Review;
