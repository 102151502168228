import { MuiTheme } from "theme";
import { makeStyles } from "@material-ui/core/styles";

export default makeStyles((theme: MuiTheme) => ({
  tableWrapper: {
    position: "absolute",
    top: 0,
    bottom: 0,
    left: 0,
    right: 0,
    overflow: "auto"
  },
  appPageTable: {
    [theme.breakpoints.down("xs")]: {
      maxWidth: "200%",
      width: "200%"
    },
    fontSize: 12
  },
  appPageTableHeader: {
    padding: "0 24px",
    display: "grid",
    gridTemplateColumns: "repeat(6, 1fr)",
    backgroundColor: "#fff",
    borderBottom: "1px solid #ccc",
    borderTop: "1px solid #ccc",
    top: 0,
    zIndex: 1
  },
  tableHeaderCell: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    height: 45,
    whiteSpace: "nowrap",
    "& .MuiSvgIcon-root": {
      width: 16
    },
    "&:first-child": {
      justifyContent: "flex-start",
      paddingLeft: "1em"
    }
  },
  tableBodyRow: {
    minHeight: "50px",
    display: "grid",
    gridTemplateColumns: "repeat(6, 1fr)",
    cursor: "pointer",
    padding: "0 24px",
    borderBottom: "1px solid #eeeeee",
    backgroundColor: "#fff",
    "&:nth-child(odd)": {
      backgroundColor: "#fbfbfb"
    }
  },
  tableBodyCell: {
    display: "flex",
    flexWrap: "wrap",
    alignItems: "center",
    justifyContent: "center",
    wordBreak: "break-word",
    padding: "12px 0",
    "&:first-child": {
      justifyContent: "flex-start",
      paddingLeft: "1em"
    }
  }
}));
