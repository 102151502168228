import React, { SyntheticEvent, useContext, useRef, useState } from "react";
import clsx from "clsx";

import Button from "@material-ui/core/Button";
import CheckIcon from "@material-ui/icons/Check";
import FlagIcon from "@material-ui/icons/Flag";
import { Theme } from "@material-ui/core/styles";
import useMediaQuery from "@material-ui/core/useMediaQuery";

import { racehorse360 } from "@tsg/1st-grpc-web";

import Loader from "components/Loader";
import ScrollSyncContext from "components/ScrollSync/ScrollSyncContext";
import FlagRequestPriorityPopover from "./FlagRequestPriorityPopover";
import { useRacehorse360Api } from "hooks/api";
import { transformViewDate } from "utils/date-utils";
import {
  EFlagPriority,
  getFlagPriority,
  getRequestFlagPriority
} from "../helper";
import useStyles from "./styles";

interface IProps {
  workoutRequest: racehorse360.IWorkoutRequest;
  userId: string;
  refetchWorkouts: (value?: boolean) => Promise<void>;
}

const MOBILE_LOADER_SIZE = 16;
const DESKTOP_LOADER_SIZE = 20;

const FlagRequestContent = (props: IProps) => {
  const { workoutRequest, userId, refetchWorkouts } = props;

  const classes = useStyles();
  const flagRequestRef = useRef<HTMLButtonElement>(null);
  const flagRequest = workoutRequest.flagRequest;
  const selectedPriority = getFlagPriority(flagRequest?.priority);
  const scrollSyncState = useContext(ScrollSyncContext);
  const matchesDownXS420 = useMediaQuery((theme: Theme) =>
    theme.breakpoints.down("xs")
  );

  const [isOpenPopover, setIsOpenPopover] = useState<boolean>(false);
  const [showLoader, setShowLoader] = useState<boolean>(false);

  const { useCreateFlagRequest, useDeleteFlagRequest, useUpdateFlagRequest } =
    useRacehorse360Api();

  const onSuccess = async () => {
    await refetchWorkouts(true);
    scrollSyncState.forceRecalculateScroll();
    setShowLoader(false);
  };

  const { mutateAsync: createFlagRequest } = useCreateFlagRequest({
    onSuccess
  });
  const { mutateAsync: updateFlagRequest } = useUpdateFlagRequest({
    onSuccess
  });
  const { mutateAsync: deleteFlagRequest } = useDeleteFlagRequest({
    onSuccess
  });

  const handleChangePriority = event => {
    const priority = getRequestFlagPriority(event.currentTarget.value);
    handleClosePopover(event);
    setShowLoader(true);

    if (!flagRequest) {
      createFlagRequest({
        flagRequest: {
          workoutRequestId: workoutRequest.id,
          priority
        }
      });
      return;
    }

    if (
      priority ===
      racehorse360.FlagRequestPriority.FLAG_REQUEST_PRIORITY_INVALID
    ) {
      deleteFlagRequest({ id: flagRequest.id });
      return;
    }

    updateFlagRequest({
      flagRequest: { ...flagRequest, priority },
      updateMask: { paths: ["priority"] }
    });
  };

  const handleOpenPopover = event => {
    event.stopPropagation();
    setIsOpenPopover(true);
  };

  const handleClosePopover = (event?: SyntheticEvent) => {
    event && event.stopPropagation();
    setIsOpenPopover(false);
  };

  const renderContent = () => {
    const isOwner = userId === flagRequest?.createdBy?.id;
    const buttonTitle = flagRequest ? "Flagged" : "Flag";

    if (flagRequest && !isOwner) {
      return (
        <span className={classes.flaggedInfo} data-test={"flagged-info"}>
          {`Dr. ${flagRequest.createdBy.lastName}
            ${transformViewDate(
              flagRequest.createdOn,
              workoutRequest.facility?.timezone
            )}`}
        </span>
      );
    }

    return (
      <Button
        ref={flagRequestRef}
        className={clsx(classes.flaggedButton, classes[selectedPriority], {
          active: selectedPriority !== EFlagPriority.NONE
        })}
        startIcon={flagRequest ? <CheckIcon /> : <FlagIcon />}
        onClick={handleOpenPopover}
        data-test={`${buttonTitle.toLowerCase()}-button`}
      >
        {buttonTitle}
      </Button>
    );
  };

  if (showLoader) {
    return (
      <Loader
        className={classes.loader}
        size={matchesDownXS420 ? MOBILE_LOADER_SIZE : DESKTOP_LOADER_SIZE}
      />
    );
  }

  return (
    <div className={classes.flagCell}>
      {renderContent()}
      {isOpenPopover && (
        <FlagRequestPriorityPopover
          onClose={handleClosePopover}
          anchorEl={flagRequestRef.current}
          selectedValue={selectedPriority}
          onChange={handleChangePriority}
        />
      )}
    </div>
  );
};

export default FlagRequestContent;
