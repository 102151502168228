import React, {
  Reducer,
  useMemo,
  useReducer,
  useState,
  useEffect
} from "react";
import { useSelector, useDispatch } from "react-redux";
import { useHistory } from "react-router-dom";
import { useQueryClient } from "react-query";
import clsx from "clsx";
import { v1 as uuid } from "uuid";
import isEqual from "lodash/isEqual";
import cloneDeep from "lodash/cloneDeep";

import Box from "@material-ui/core/Box";
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import DialogActions from "@material-ui/core/DialogActions";
import Divider from "@material-ui/core/Divider";
import Typography from "@material-ui/core/Typography";
import { Theme } from "@material-ui/core/styles";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import { IconButton } from "@material-ui/core";

import { racehorse360 } from "@tsg/1st-grpc-web";

import {
  setActiveLink as setStoreActiveLink,
  setStoreActiveLinkCandidate
} from "store/actions/sidebar";
import Breakpoints from "common/breakpoints";
import Loader from "components/Loader";
import CustomRaceIcon from "components/Icons/CustomRace";
import LastRaceIcon from "components/Icons/LastRace";
import ConditionsHint from "components/Icons/ConditionsHint";
import OverlayPage from "components/OverlayPage";
import { useClickBlockerContext } from "components/BlockableClickContext";
import { useRacehorse360Api } from "hooks/api";
import { parseCourseSurfaceForPC } from "utils/enum-parser";
import PreferredConditionsMatchingHorsesSliderState from "interfaces/PreferredConditionsMatchingHorsesSliderState";
import { getSurfaceName } from "utils/preferred-conditions";
import { removeHorseIdFromSelectedPreferredConditions } from "store/actions/preferredConditionsMatchingHorsesSlider";
import NoteBlock from "../NoteBlock";
import OverlayHeader from "../OverlayHeader";
import useStyles from "./styles";

interface Props {
  open: boolean;
  horse: racehorse360.IHorse;
  onClose: () => void;
  refetchMatchedRaces: () => void;
  assignedPreferredConditions: racehorse360.IHorsePreferredConditions;
  calculatedLastRacePreferredConditions: racehorse360.IHorsePreferredConditions;
  isLastRacePreferredConditions: boolean;
  conditionNote: string;
  isPCLoading: boolean;
  shouldUpdatePreferredConditions: boolean;
  setShouldUpdatePreferredConditions: (value: boolean) => void;
}

interface IRaceTypeButton {
  name: string;
  raceTypes: racehorse360.RaceType[];
}

interface IForm {
  surface: racehorse360.CourseSurface;
  raceDistances: string[];
  raceTypes: IRaceTypeButton[];
  claimingPrices: string[];
  conditionNote: string;
}

interface IRegionData {
  [key: number]: {
    [key: string]: racehorse360.RaceDistance;
  };
}

interface IClaimingPriceButton extends racehorse360.IClaimingPrice {
  raceTypeIds: string[];
}

interface IState {
  form: IForm;
  regionData: IRegionData;
  buttons: {
    surfaces: racehorse360.CourseSurface[];
    raceTypes: IRaceTypeButton[];
    raceDistances: racehorse360.IRaceDistance[];
    claimingPrices: IClaimingPriceButton[];
  };
}

interface IAction {
  type: string;
  payload: Record<string, unknown> | string[] | string;
}

const reducer = (state, action) => {
  switch (action.type) {
    case "RESET":
      return {
        form: action.payload,
        buttons: state.buttons,
        regionData: state.regionData
      };
    case "CHANGE_SURFACE":
      return {
        ...state,
        form: {
          ...state.form,
          surface: action.payload,
          raceDistances: [],
          raceTypes: [],
          claimingPrices: []
        }
      };
    case "CHANGE_FORM_FIELD":
      return {
        ...state,
        form: {
          ...state.form,
          [action.payload.name]: action.payload.value
        }
      };
    case "LOAD":
      return {
        ...state,
        ...action.payload
      };
    case "SET_PREFERRED_CONDITIONS_FORM":
      return {
        ...state,
        form: action.payload.value
      };
    default:
      return state;
  }
};

export const groupRaceTypes = (raceTypes: racehorse360.IRaceType[]) => {
  const knownGroup = "HCP/OST/STK";
  const groups = raceTypes.reduce((acc, cur) => {
    if (knownGroup.split("/").includes(cur.name)) {
      acc[knownGroup]
        ? acc[knownGroup].raceTypes.push(cur)
        : (acc[knownGroup] = {
            name: knownGroup,
            raceTypes: [cur]
          });
    } else {
      acc[cur.name] = {
        name: cur.name,
        raceTypes: [cur]
      };
    }
    return acc;
  }, {});

  return Object.values(groups).sort((rt1, rt2) =>
    rt1.name.localeCompare(rt2.name)
  );
};

const raceTypeGroupsToIds = raceTypeGroups => {
  return raceTypeGroups.reduce(
    (acc, cur) => [...acc, ...cur.raceTypes.map(rt => rt.id)],
    []
  );
};

//TODO need to reduce component complexity
/* eslint-disable complexity */
const OverlayForm = React.memo((props: Props) => {
  const {
    open,
    horse,
    onClose,
    assignedPreferredConditions = {
      surface: null,
      raceDistances: [],
      raceTypes: [],
      claimingPrices: []
    },
    calculatedLastRacePreferredConditions,
    isLastRacePreferredConditions,
    conditionNote,
    isPCLoading,
    refetchMatchedRaces,
    setShouldUpdatePreferredConditions,
    shouldUpdatePreferredConditions
  } = props;
  const history = useHistory();
  const dispatchToRedux = useDispatch();
  const classes = useStyles();
  const queryCache = useQueryClient();
  const { blockClick, setBlockClick, setBlockClickCallback } =
    useClickBlockerContext();

  const { selectedPreferredConditions } = useSelector(
    (state: {
      preferredConditionsMatchingHorsesSlider: PreferredConditionsMatchingHorsesSliderState;
    }) => state?.preferredConditionsMatchingHorsesSlider
  );

  const [tempConditionNote, setTempConditionNote] =
    useState<string>(conditionNote);
  const [shouldUpdateNotesBlockValue, setShouldUpdateNotesBlockValue] =
    useState<string | null>(null);
  const [unsavedChangesIsOpen, setUnsavedChangesIsOpen] =
    useState<boolean>(false);
  const [hasChanges, setHasChanges] = useState<boolean>(false);
  const [isUseLastRacePC, setIsUseLastRacePC] = useState<boolean>(
    isLastRacePreferredConditions
  );
  const [isShowConditionsHint, setIsShowConditionsHint] =
    useState<boolean>(false);

  const emptyForm: IForm = {
    surface: 0,
    raceDistances: [],
    raceTypes: [],
    claimingPrices: [],
    conditionNote: tempConditionNote
  };
  //TODO need create a separate ‘init’ function and pass it to useReducer as third
  // parameter and reuse it in handleCancel
  // and handleSetLastRaceConditions (useReducer lazy init ref
  const initialState = {
    form: {
      surface: null,
      raceDistances: [],
      raceTypes: [],
      claimingPrices: [],
      conditionNote: null
    },
    regionData: {},
    buttons: {
      surfaces: [],
      raceDistances: [],
      raceTypes: [],
      claimingPrices: []
    }
  };
  const [state, dispatch] = useReducer<Reducer<IState, IAction>>(
    reducer,
    initialState
  );

  const candidate = useSelector(
    (store: { sidebar }) => store?.sidebar.activeLinkCandidate
  );

  const isScreenSM = useMediaQuery((theme: Theme) =>
    theme.breakpoints.down(Breakpoints.SM_600)
  );
  const isScreenSX = useMediaQuery((theme: Theme) =>
    theme.breakpoints.down(Breakpoints.SX_720)
  );
  const isScreenMD = useMediaQuery((theme: Theme) =>
    theme.breakpoints.down(Breakpoints.MD_960)
  );

  const withoutControls = !isScreenSM && !isScreenSX && !isScreenMD;

  const lockNavigation = () => {
    setBlockClick(true);
    setBlockClickCallback(() => () => setUnsavedChangesIsOpen(true));
  };

  const unlockNavigation = () => {
    setBlockClick(false);
    setBlockClickCallback(null);
  };

  useEffect(() => {
    return () => {
      unlockNavigation();
    };
  }, []);

  //TODO check: is need here useMemo?
  const isRaceDistancesChanged = useMemo(() => {
    return !isEqual(
      assignedPreferredConditions.raceDistances?.map(item => item.id).sort(),
      state.form.raceDistances.sort()
    );
  }, [isLastRacePreferredConditions, state.form.raceDistances]);

  const isArrayChanged = (propertyName, decorator = v => v) => {
    return !isEqual(
      assignedPreferredConditions[propertyName]?.map(item => item.id).sort(),
      decorator(state.form[propertyName]).sort()
    );
  };

  const isRaceTypesChanged = useMemo(() => {
    return isArrayChanged("raceTypes", raceTypeGroupsToIds);
  }, [isLastRacePreferredConditions, state.form.raceTypes]);

  const isSurfaceChanged = useMemo(() => {
    return assignedPreferredConditions.surface !== state.form.surface;
  }, [isLastRacePreferredConditions, state.form.surface]);

  const isClaimingPriceChanged = useMemo(() => {
    return isArrayChanged("claimingPrices");
  }, [isLastRacePreferredConditions, state.form.claimingPrices]);

  useEffect(() => {
    if (selectedPreferredConditions && assignedPreferredConditions) {
      const { surface, raceDistances, raceTypes, claimingPrices } =
        assignedPreferredConditions;
      const {
        surface: selectedSurface,
        raceType: selectedRaceType,
        distance: selectedDistance,
        claimingPrice: selectedPrice
      } = selectedPreferredConditions;

      const isSurfaceMatched = getSurfaceName(surface) === selectedSurface;
      const isDistanceMatched = raceDistances.some(distance => {
        return selectedDistance === distance.description;
      });
      const isRaceTypeMatched =
        selectedRaceType.split("/").length > 1
          ? raceTypes.some(race => selectedRaceType.includes(race.name))
          : raceTypes.some(race => selectedRaceType === race.name);
      const isClaimingPriceMatched =
        selectedPrice === "-"
          ? true
          : claimingPrices.some(price => {
              return selectedPrice.replace("-", "to") === price.description;
            });

      const arePreferredConditionsMatched = [
        isSurfaceMatched,
        isDistanceMatched,
        isRaceTypeMatched,
        isClaimingPriceMatched
      ].every(item => item);

      if (!arePreferredConditionsMatched && shouldUpdatePreferredConditions) {
        dispatchToRedux(removeHorseIdFromSelectedPreferredConditions(horse.id));
        setShouldUpdatePreferredConditions(false);
      }
    }
  }, [assignedPreferredConditions, shouldUpdatePreferredConditions]);

  useEffect(() => {
    if (open) {
      const isMatchTypeChanged =
        isUseLastRacePC !== isLastRacePreferredConditions;
      const noteHasChanges = withoutControls
        ? conditionNote !== state.form.conditionNote
        : conditionNote !== tempConditionNote;

      const hasChanges = Boolean(
        isMatchTypeChanged ||
          isSurfaceChanged ||
          isRaceDistancesChanged ||
          isRaceTypesChanged ||
          isClaimingPriceChanged ||
          noteHasChanges
      );

      setHasChanges(hasChanges);

      if (hasChanges && !isUseLastRacePC && !blockClick) {
        lockNavigation();
      }
      if (!hasChanges || isUseLastRacePC) {
        unlockNavigation();
      }
    }
  }, [
    assignedPreferredConditions,
    state.form,
    tempConditionNote,
    isUseLastRacePC
  ]);

  const {
    usePullRegionPreferredConditions,
    useAssignPreferredConditionsHorse
  } = useRacehorse360Api();

  const { mutateAsync: updateHorsePreferredConditions, isLoading: isUpdating } =
    useAssignPreferredConditionsHorse();

  const currentFacilityRegionId =
    horse.currentFacility?.region?.id || undefined;

  const { isLoading: isRegionPCDataLoading } = usePullRegionPreferredConditions(
    {
      id: {
        value: currentFacilityRegionId
      }
    },
    {
      enabled: Boolean(horse),
      onSuccess: data => {
        const reduced =
          data.regionPreferredConditions.surfaceRaceDistances.reduce(
            (acc, cur) => {
              acc.surfaces.push(cur.surface);

              if (!acc.regionData[cur.surface]) {
                acc.regionData[cur.surface] = {};
              }

              cur.raceDistances.forEach(distance => {
                if (
                  !acc.raceDistances[distance.id] ||
                  acc.raceDistances[distance.id]?.disabled
                ) {
                  acc.raceDistances[distance.id] = {
                    ...distance,
                    disabled: cur.surface !== state.form.surface
                  };
                }
                acc.regionData[cur.surface][distance.id] = distance;
              });
              return acc;
            },
            {
              surfaces: [],
              raceDistances: {},
              regionData: {}
            }
          );

        const claimingPricesButtons =
          data.regionPreferredConditions.raceTypeClaimingPrices.reduce(
            (acc, cur) => {
              cur.claimingPrices.forEach(cp => {
                acc[cp.id] = acc[cp.id] ? { ...acc[cp.id], ...cp } : cp;
                acc[cp.id].raceTypeIds = acc[cp.id].raceTypeIds
                  ? [...acc[cp.id].raceTypeIds, cur.raceTypeId]
                  : [cur.raceTypeId];
              });
              return acc;
            },
            {}
          );

        const regionSurfaces = reduced.surfaces.sort(
          (s1: racehorse360.CourseSurface, s2: racehorse360.CourseSurface) =>
            parseCourseSurfaceForPC(s1).localeCompare(
              parseCourseSurfaceForPC(s2)
            )
        );
        const regionRaceDistances = Object.values(reduced.raceDistances).sort(
          (rd1: racehorse360.IRaceDistance, rd2: racehorse360.IRaceDistance) =>
            rd1.distance - rd2.distance
        );
        const regionRaceTypes = groupRaceTypes(
          data.regionPreferredConditions.raceTypes
        );
        const regionClaimingPrices = Object.values(claimingPricesButtons).sort(
          (
            cp1: racehorse360.IClaimingPrice,
            cp2: racehorse360.IClaimingPrice
          ) => (cp1.minPrice || 0) - (cp2.minPrice || 0)
        );

        const preferredConditions = isLastRacePreferredConditions
          ? calculatedLastRacePreferredConditions
          : assignedPreferredConditions;
        const preferredSurface = reduced.surfaces.some(
          s => s === preferredConditions?.surface
        )
          ? preferredConditions.surface
          : null;
        const preferredRaceDistances =
          preferredConditions?.raceDistances.map(rd => rd.id) || [];
        const filteredPreferredRaceDistances = preferredRaceDistances.filter(
          rdId => regionRaceDistances.some(rrd => rdId === rrd.id)
        );
        const preferredRaceTypes = preferredConditions?.raceTypes || [];
        const filteredPreferredRaceTypes = groupRaceTypes(
          preferredRaceTypes.filter(rt =>
            data.regionPreferredConditions.raceTypes.some(
              rrt => rt.id === rrt.id
            )
          ) || []
        );
        const preferredClaimingPrices =
          preferredConditions?.claimingPrices.map(cp => cp.id) || [];
        const filteredPreferredClaimingPrices = preferredClaimingPrices.filter(
          cpId => regionClaimingPrices.some(rcp => cpId === rcp.id)
        );

        dispatch({
          type: "LOAD",
          payload: {
            form: {
              surface: preferredSurface,
              raceDistances: filteredPreferredRaceDistances,
              raceTypes: filteredPreferredRaceTypes,
              claimingPrices: filteredPreferredClaimingPrices,
              conditionNote: tempConditionNote
            },
            regionData: reduced.regionData,
            buttons: {
              surfaces: regionSurfaces,
              raceDistances: regionRaceDistances,
              raceTypes: regionRaceTypes,
              claimingPrices: regionClaimingPrices
            }
          }
        });
      }
    }
  );

  const handleCloseUnsavedChanges = () => {
    dispatchToRedux(setStoreActiveLinkCandidate(""));
    setUnsavedChangesIsOpen(false);
  };

  const handleDontSave = () => {
    if (candidate) {
      dispatchToRedux(setStoreActiveLink(candidate));
      dispatchToRedux(setStoreActiveLinkCandidate(""));
      history.push(candidate);
    }
    setUnsavedChangesIsOpen(false);
  };

  const handleCancel = () => {
    const newForm: IForm = cloneDeep(emptyForm);
    newForm.conditionNote = conditionNote;

    if (
      isLastRacePreferredConditions &&
      calculatedLastRacePreferredConditions
    ) {
      newForm.surface = calculatedLastRacePreferredConditions?.surface;
      newForm.raceDistances =
        calculatedLastRacePreferredConditions.raceDistances.map(rd => rd.id);
      newForm.raceTypes = groupRaceTypes(
        calculatedLastRacePreferredConditions.raceTypes
      );
      newForm.claimingPrices =
        calculatedLastRacePreferredConditions.claimingPrices.map(cp => cp.id);
    } else {
      newForm.surface = assignedPreferredConditions.surface;
      newForm.raceDistances = assignedPreferredConditions.raceDistances.map(
        rd => rd.id
      );
      newForm.raceTypes = groupRaceTypes(assignedPreferredConditions.raceTypes);
      newForm.claimingPrices = assignedPreferredConditions.claimingPrices.map(
        cp => cp.id
      );
    }

    setTempConditionNote(conditionNote);
    dispatch({
      type: "SET_PREFERRED_CONDITIONS_FORM",
      payload: {
        value: newForm
      }
    });

    const isLastRaceValue = calculatedLastRacePreferredConditions
      ? isLastRacePreferredConditions
      : false;

    setIsUseLastRacePC(isLastRaceValue);
    onClose();
  };

  const handleSave = async () => {
    const currentConditionNote = withoutControls
      ? state.form.conditionNote
      : tempConditionNote;

    await updateHorsePreferredConditions({
      id: horse.id,
      raceDistanceIds: state.form.raceDistances,
      raceTypeIds: raceTypeGroupsToIds(state.form.raceTypes),
      surface: state.form.surface,
      claimingPriceIds: state.form.claimingPrices,
      conditionNote: currentConditionNote
        ? { value: currentConditionNote.trim() }
        : null,
      areLastRacePreferredConditionsSelected: isUseLastRacePC
    });
    await queryCache.invalidateQueries(`${horse.id}-PC`);
    setShouldUpdatePreferredConditions(true);

    if (candidate) {
      dispatchToRedux(setStoreActiveLink(candidate));
      dispatchToRedux(setStoreActiveLinkCandidate(""));
      history.push(candidate);
    }
    refetchMatchedRaces();
    onClose();
  };

  const handleResetIconClick = () => {
    setIsUseLastRacePC(isLastRacePreferredConditions);

    dispatch({
      type: "RESET",
      payload: {
        surface: assignedPreferredConditions.surface,
        raceDistances: assignedPreferredConditions.raceDistances.map(
          rd => rd.id
        ),
        raceTypes: groupRaceTypes(assignedPreferredConditions.raceTypes),
        claimingPrices: assignedPreferredConditions.claimingPrices.map(
          cp => cp.id
        ),
        conditionNote: conditionNote
      }
    });

    setTempConditionNote(conditionNote);
    setShouldUpdateNotesBlockValue(uuid());
  };

  const handleSurfaceClick = surface => () => {
    if (surface === state.form.surface) {
      return;
    }
    if (isUseLastRacePC && calculatedLastRacePreferredConditions) {
      dispatch({
        type: "SET_PREFERRED_CONDITIONS_FORM",
        payload: {
          value: {
            surface,
            raceDistances: calculatedLastRacePreferredConditions.raceDistances
              .map(rd => rd.id)
              .filter(rdID => rdID in state.regionData[surface]),
            raceTypes: groupRaceTypes(
              calculatedLastRacePreferredConditions.raceTypes
            ),
            claimingPrices:
              calculatedLastRacePreferredConditions.claimingPrices.map(
                cp => cp.id
              ),
            conditionNote: tempConditionNote
          }
        }
      });
    } else {
      dispatch({ type: "CHANGE_SURFACE", payload: surface });
    }

    isUseLastRacePC && setIsUseLastRacePC(!isUseLastRacePC);
  };

  const handleDistanceClick = distance => () => {
    dispatch({
      type: "CHANGE_FORM_FIELD",
      payload: {
        name: "raceDistances",
        value: state.form.raceDistances.includes(distance.id)
          ? state.form.raceDistances.filter(d => d !== distance.id)
          : [...state.form.raceDistances, distance.id]
      }
    });

    isUseLastRacePC && setIsUseLastRacePC(!isUseLastRacePC);
  };

  const handleRaceTypeClick = raceType => () => {
    const activeRaceTypes: IRaceTypeButton[] = state.form.raceTypes.some(
      rtg => rtg.name === raceType.name
    )
      ? state.form.raceTypes.filter(rtg => rtg.name !== raceType.name)
      : [...state.form.raceTypes, raceType];

    dispatch({
      type: "CHANGE_FORM_FIELD",
      payload: {
        name: "raceTypes",
        value: activeRaceTypes
      }
    });

    const activeRaceTypesIds = activeRaceTypes.reduce((acc, cur) => {
      acc.push(...getRaceTypeIds(cur));
      return acc;
    }, []);

    if (
      !state.buttons.claimingPrices
        .filter(cp => state.form.claimingPrices.some(cpId => cpId === cp.id))
        .some(cp =>
          cp.raceTypeIds.some(rtId => activeRaceTypesIds.includes(rtId))
        )
    ) {
      dispatch({
        type: "CHANGE_FORM_FIELD",
        payload: {
          name: "claimingPrices",
          value: []
        }
      });
    }

    isUseLastRacePC && setIsUseLastRacePC(!isUseLastRacePC);
  };

  const handleClaimingPriceClick = claimingPriceButtonId => () => {
    dispatch({
      type: "CHANGE_FORM_FIELD",
      payload: {
        name: "claimingPrices",
        value: state.form.claimingPrices.some(
          cpId => cpId === claimingPriceButtonId
        )
          ? state.form.claimingPrices.filter(
              cpId => cpId !== claimingPriceButtonId
            )
          : [...state.form.claimingPrices, claimingPriceButtonId]
      }
    });

    isUseLastRacePC && setIsUseLastRacePC(!isUseLastRacePC);
  };

  const handleConditionNoteChange = (name, value) => {
    dispatch({
      type: "CHANGE_FORM_FIELD",
      payload: {
        name: "conditionNote",
        value: value
      }
    });
  };

  const handleChangeTempValue = value => {
    setTempConditionNote(value);
  };

  const handleSetLastRaceConditions = () => {
    const newForm: IForm = cloneDeep(emptyForm);
    newForm.surface = calculatedLastRacePreferredConditions.surface;
    newForm.raceDistances =
      calculatedLastRacePreferredConditions.raceDistances.map(rd => rd.id);
    newForm.raceTypes = groupRaceTypes(
      calculatedLastRacePreferredConditions.raceTypes
    );
    newForm.claimingPrices =
      calculatedLastRacePreferredConditions.claimingPrices.map(cp => cp.id);

    dispatch({
      type: "SET_PREFERRED_CONDITIONS_FORM",
      payload: {
        value: newForm
      }
    });
    setIsUseLastRacePC(true);
  };

  const handleSetCustomRaceConditions = () => {
    const newForm: IForm = cloneDeep(emptyForm);

    if (!isLastRacePreferredConditions) {
      newForm.surface = assignedPreferredConditions.surface;
      newForm.raceDistances = assignedPreferredConditions.raceDistances.map(
        rd => rd.id
      );
      newForm.raceTypes = groupRaceTypes(assignedPreferredConditions.raceTypes);
    }

    dispatch({
      type: "SET_PREFERRED_CONDITIONS_FORM",
      payload: {
        value: newForm
      }
    });
    setIsUseLastRacePC(false);
  };

  const handleShowHint = () => {
    setIsShowConditionsHint(true);
  };

  const handleCloseConditionsHint = () => {
    setIsShowConditionsHint(false);
  };

  const isLoading = isUpdating || isRegionPCDataLoading || isPCLoading;

  const isResetAvailable = hasChanges;

  const renderSurfacesButtons = () => {
    return state.buttons.surfaces.map(surface => {
      const isActiveSurfaceButton =
        surface === state.form.surface && !isUseLastRacePC;
      const isActiveLastRaceSurfaceButton =
        surface === state.form.surface && isUseLastRacePC;

      return (
        <Button
          className={clsx(classes.surfaceButton, {
            [classes.active]: isActiveSurfaceButton,
            [classes.lastRaceActive]: isActiveLastRaceSurfaceButton
          })}
          key={surface}
          onClick={handleSurfaceClick(surface)}
        >
          {parseCourseSurfaceForPC(surface)}
        </Button>
      );
    });
  };

  const renderRaceDistancesButtons = () => {
    return state.buttons.raceDistances.map(distance => {
      const disabledButton =
        !state.form.surface ||
        !state.regionData[state.form.surface][distance.id];
      const isActiveDistanceButton =
        state.form.surface &&
        state.form.raceDistances.includes(distance.id) &&
        state.regionData[state.form.surface][distance.id] &&
        !isUseLastRacePC;
      const isActiveLastRaceDistanceButton =
        state.form.surface &&
        state.form.raceDistances.includes(distance.id) &&
        state.regionData[state.form.surface][distance.id] &&
        isUseLastRacePC;

      return (
        <Button
          classes={{
            disabled: classes.disabled
          }}
          className={clsx(classes.distanceButton, {
            [classes.active]: isActiveDistanceButton,
            [classes.lastRaceActive]: isActiveLastRaceDistanceButton
          })}
          key={distance.id + disabledButton}
          onClick={handleDistanceClick(distance)}
          disabled={disabledButton}
        >
          {distance.description}
        </Button>
      );
    });
  };

  const renderRaceTypesButtons = () => {
    return state.buttons.raceTypes.map(raceTypesGroup => {
      const disabledButton = !state.form.surface;
      const isActiveConditionButton =
        state.form.surface &&
        state.form.raceTypes.some(rt => rt.name === raceTypesGroup.name) &&
        !isUseLastRacePC;
      const isActiveLastRaceConditionButton =
        state.form.surface &&
        state.form.raceTypes.some(rt => rt.name === raceTypesGroup.name) &&
        isUseLastRacePC;

      return (
        <Button
          classes={{
            disabled: classes.disabled
          }}
          className={clsx(classes.conditionButton, {
            [classes.active]: isActiveConditionButton,
            [classes.lastRaceActive]: isActiveLastRaceConditionButton
          })}
          key={raceTypesGroup.name + disabledButton}
          onClick={handleRaceTypeClick(raceTypesGroup)}
          disabled={disabledButton}
        >
          {raceTypesGroup.name}
        </Button>
      );
    });
  };

  const getRaceTypeIds = (obj): string[] => {
    if (!obj.raceTypes) {
      return [obj.id];
    }
    return obj.raceTypes.reduce((acc, cur) => {
      acc.push(...getRaceTypeIds(cur));
      return acc;
    }, []);
  };

  const renderClaimingPriceButtons = () => {
    const activeRaceTypeIds = getRaceTypeIds(state.form);
    return state.buttons.claimingPrices.map(claimingPriceButton => {
      state.form.raceTypes;
      const isDisabled =
        !state.form.surface ||
        !claimingPriceButton.raceTypeIds.some(id =>
          activeRaceTypeIds.some(artId => artId === id)
        );
      const isActive =
        state.form.surface &&
        state.form.claimingPrices.some(cpId => cpId === claimingPriceButton.id);

      return (
        <Button
          classes={{
            disabled: classes.disabled
          }}
          className={clsx(classes.conditionButton, classes.claimingPrice, {
            [classes.active]: isActive,
            [classes.lastRaceActive]: isActive && isUseLastRacePC
          })}
          key={claimingPriceButton.id}
          onClick={handleClaimingPriceClick(claimingPriceButton.id)}
          disabled={isDisabled}
        >
          {claimingPriceButton.description.toLowerCase().replace("to", "-")}
        </Button>
      );
    });
  };

  return (
    <>
      {open && (
        <OverlayPage onClose={handleCancel}>
          <DialogTitle
            disableTypography={true}
            className={classes.conditionsTitleRoot}
          >
            <OverlayHeader
              horse={horse}
              onClose={handleCancel}
              onResetClick={handleResetIconClick}
              isResetAvailable={isResetAvailable}
            />
          </DialogTitle>
          <DialogContent className={classes.conditionsContentRoot}>
            {isLoading && <Loader overlay />}
            {!!calculatedLastRacePreferredConditions && (
              <Box className={classes.block}>
                <Box className={classes.conditionSwitchHeader}>
                  <Typography
                    className={clsx(
                      classes.title,
                      classes.conditionSwitchTitle
                    )}
                  >
                    Condition Matching
                  </Typography>

                  <IconButton
                    className={classes.conditionHintButton}
                    onClick={handleShowHint}
                    size="small"
                  >
                    <ConditionsHint className={classes.customRaceIcon} />
                  </IconButton>
                </Box>

                <Box className={classes.conditionSwitch}>
                  <Box className={classes.condition}>
                    <IconButton
                      className={clsx(classes.switchButton, {
                        [classes.lastRaceActiveButton]: isUseLastRacePC
                      })}
                      onClick={handleSetLastRaceConditions}
                      size="small"
                    >
                      <LastRaceIcon
                        className={clsx(classes.lastRaceIcon, {
                          ["active"]: isUseLastRacePC
                        })}
                      />
                    </IconButton>
                    <Typography
                      className={clsx(classes.switchButtonTitle, {
                        [classes.switchButtonActiveTitle]: isUseLastRacePC
                      })}
                    >
                      Use Last Race Conditions
                    </Typography>
                  </Box>
                  <Box className={classes.condition}>
                    <IconButton
                      className={clsx(classes.switchButton, {
                        [classes.customRaceActiveButton]: !isUseLastRacePC
                      })}
                      onClick={handleSetCustomRaceConditions}
                      size="small"
                    >
                      <CustomRaceIcon
                        className={clsx(classes.customRaceIcon, {
                          ["active"]: !isUseLastRacePC
                        })}
                      />
                    </IconButton>
                    <Typography
                      className={clsx(classes.switchButtonTitle, {
                        [classes.switchButtonActiveTitle]: !isUseLastRacePC
                      })}
                    >
                      Custom Race Conditions
                    </Typography>
                  </Box>
                </Box>
              </Box>
            )}
            <Divider light className={classes.divider} />
            <Box className={classes.block}>
              <Typography className={classes.title}>Track Surface</Typography>
              <Box className={classes.surfaceButtons}>
                {renderSurfacesButtons()}
              </Box>
            </Box>
            <Divider light className={classes.divider} />
            <Box className={classes.block}>
              <Typography className={classes.title}>Race Distances</Typography>
              <Box
                className={clsx(
                  classes.buttonsContainer,
                  classes.raceDistancesButtons
                )}
              >
                {renderRaceDistancesButtons()}
              </Box>
            </Box>
            <Divider light className={classes.divider} />
            <Box className={classes.block}>
              <Typography className={classes.title}>Conditions</Typography>
              <Box
                className={clsx(
                  classes.buttonsContainer,
                  classes.conditionsButtons
                )}
              >
                {renderRaceTypesButtons()}
              </Box>
            </Box>
            <Divider light className={classes.divider} />
            <Box className={classes.block}>
              <Typography className={classes.title}>Claiming Price</Typography>
              <Box className={clsx(classes.claimingPriceButtons)}>
                {renderClaimingPriceButtons()}
              </Box>
            </Box>
            <Divider light className={classes.divider} />
            <Box className={classes.block}>
              <NoteBlock
                name={"conditionNote"}
                placeholder={`Enter condition note for ${horse.name}...`}
                defaultValue={state.form.conditionNote}
                onChange={handleConditionNoteChange}
                changeTempValue={handleChangeTempValue}
                className={classes.conditionsNoteBlock}
                withoutControls={withoutControls}
                disabled={!state.form.surface}
                hasNoteChanges={
                  withoutControls
                    ? conditionNote !== state.form.conditionNote
                    : conditionNote !== tempConditionNote
                }
                shouldUpdateNotesBlockValue={shouldUpdateNotesBlockValue}
              />
            </Box>
          </DialogContent>
          <DialogActions className={classes.conditionsActionsRoot}>
            <Box className={classes.controlsButtonsContainer}>
              <Button
                className={clsx(classes.overlayControl, classes.overlayCancel)}
                onClick={handleCancel}
              >
                CANCEL
              </Button>
              <Button
                className={clsx(classes.overlayControl, classes.overlaySave)}
                onClick={handleSave}
                disabled={!hasChanges || !state.form.surface}
                classes={{
                  disabled: classes.disabledSaveButton
                }}
                key={`${hasChanges}`}
              >
                SAVE CONDITIONS
              </Button>
            </Box>
          </DialogActions>
        </OverlayPage>
      )}
      <Dialog
        open={unsavedChangesIsOpen}
        onClose={handleCloseUnsavedChanges}
        className={classes.confirmationDialog}
      >
        <DialogTitle>
          <Typography className={classes.dialogTitleTypography}>
            Unsaved Changes
          </Typography>
        </DialogTitle>
        <DialogContent>
          <Typography className={classes.dialogContentText}>
            You have unsaved changes to the condition preferences for&nbsp;
            <Typography component={"b"} className={classes.dialogContentBold}>
              {horse.name}
            </Typography>
            . Would you like to keep or discard your changes?
          </Typography>
        </DialogContent>
        <DialogActions className={classes.confirmationDialogActions}>
          <Box className={classes.actionButtonsContainer}>
            <Box>
              <Button
                className={clsx(classes.button, classes.cancel)}
                onClick={handleCloseUnsavedChanges}
                color="primary"
              >
                CANCEL
              </Button>
            </Box>
            <Box>
              <Button
                className={clsx(classes.button, classes.reset)}
                onClick={handleDontSave}
                color="primary"
              >
                DISCARD
              </Button>
              <Button
                className={clsx(classes.button, classes.saveConditions)}
                onClick={handleSave}
              >
                SAVE CHANGES
              </Button>
            </Box>
          </Box>
        </DialogActions>
      </Dialog>
      <Dialog
        open={isShowConditionsHint}
        onClose={handleCloseConditionsHint}
        className={classes.conditionsHint}
      >
        <DialogTitle className={classes.conditionsHintHeader}>
          Preferred Conditions Matching
        </DialogTitle>
        <DialogContent className={classes.conditionsHintContent}>
          <p>
            This page allows you to set the preferred conditions you want to see
            in future races for your horse.
          </p>
          <p>
            The condition of the last race your horse ran in will be used by
            default. Choose the "Custom Race Conditions" option if you'd prefer
            to select your own conditions.
          </p>
          <p>
            The options you choose here will be used to match your horse to
            races on the Horse Details and Condition Book pages.
          </p>
        </DialogContent>
        <DialogActions className={classes.conditionsHintFooter}>
          <Button
            className={classes.button}
            onClick={handleCloseConditionsHint}
            color="primary"
          >
            CLOSE
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
});

export default OverlayForm;
