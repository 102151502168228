import React, { useState } from "react";
import format from "date-fns/format";
import parseISO from "date-fns/parseISO";
import {
  Dialog,
  DialogContent,
  DialogTitle,
  IconButton
} from "@material-ui/core";
import CloseIcon from "@material-ui/icons/Close";
import RestoreIcon from "@material-ui/icons/Restore";

import { racehorse360 } from "@tsg/1st-grpc-web";

import Loader from "components/Loader";
import { getPersonFullName } from "utils/person";
import { useRacehorse360Api } from "hooks/api";
import useStyles from "./styles";

const LastExamNotesModal = props => {
  const { horse } = props;
  const classes = useStyles();

  const [isModalOpen, setIsModalOpen] = useState(false);

  const { useListWorkoutExams } = useRacehorse360Api();

  const { isLoading: isExamsLoading, data: examsData } = useListWorkoutExams(
    {
      query: {
        horseIds: [horse.id],
        statuses: [
          racehorse360.WorkoutExamStatus.WORKOUT_EXAM_STATUS_COMPLETED
        ],
        workoutExamTypes: [
          racehorse360.WorkoutExamType.WORKOUT_EXAM_TYPE_FULL_EXAM
        ],
        withNoteOnly: true
      },
      getOptions: {
        select: [
          "note",
          "assignedUser.firstName",
          "assignedUser.lastName",
          "completedDate"
        ],
        orderBy: ["completedDate desc"]
      }
    },
    {
      enabled: isModalOpen
    }
  );

  const handleOpen = e => {
    e.preventDefault();
    setIsModalOpen(true);
  };

  const handleClose = () => {
    setIsModalOpen(false);
  };

  return (
    <>
      <IconButton
        size={"small"}
        onClick={handleOpen}
        className={classes.historyButton}
      >
        <RestoreIcon className={classes.lastExamsIcon} />
      </IconButton>
      {isModalOpen && (
        <Dialog
          classes={{
            paper: classes.dialogPaper
          }}
          open={isModalOpen}
          onClose={handleClose}
        >
          {isExamsLoading && <Loader overlay />}
          <DialogTitle className={classes.dialogTitle}>
            Exam Note History
            <IconButton
              color="primary"
              onClick={handleClose}
              size={"small"}
              classes={{
                root: classes.dialogCloseButton
              }}
            >
              <CloseIcon className={classes.dialogCloseButtonIcon} />
            </IconButton>
          </DialogTitle>
          <DialogContent className={classes.examNotesContainer}>
            <div className={classes.examNotesContainerHeader}>
              <div className={classes.horseName}>
                {horse.name.toUpperCase()}
              </div>
              <div className={classes.trainerName}>
                {getPersonFullName(horse.trainer)}
              </div>
            </div>
            {!isExamsLoading &&
              examsData.workoutExams.slice(0, 5).map(workoutExam => {
                const { id, completedDate, assignedUser } = workoutExam;
                return (
                  <div className={classes.examNotesListItem} key={id}>
                    <div>
                      <span className={classes.commentCreator}>
                        {`${getPersonFullName(assignedUser, "%f %m %L")} `}
                      </span>
                      <span className={classes.createDateTime}>
                        {format(parseISO(completedDate), "MM/dd/yy h:mm a")}
                      </span>
                    </div>
                    <div className={classes.examNotesContainerBody}>
                      {workoutExam.note}
                    </div>
                  </div>
                );
              })}
          </DialogContent>
        </Dialog>
      )}
    </>
  );
};

export default LastExamNotesModal;
