import Box from "@material-ui/core/Box";
import React, { useState } from "react";
import { useQueryClient } from "react-query";
import clsx from "clsx";
import addMonths from "date-fns/addMonths";
import addDays from "date-fns/addDays";
import differenceInCalendarDays from "date-fns/differenceInCalendarDays";
import format from "date-fns/format";
import formatISO from "date-fns/formatISO";
import parseISO from "date-fns/parseISO";
import isSameDay from "date-fns/isSameDay";
import isBefore from "date-fns/isBefore";
import startOfWeek from "date-fns/startOfWeek";
import startOfMonth from "date-fns/startOfMonth";
import Button from "@material-ui/core/Button";
import IconButton from "@material-ui/core/IconButton";
import { useTheme } from "@material-ui/core/styles";
import Typography from "@material-ui/core/Typography";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import ChevronLeftIcon from "@material-ui/icons/ChevronLeft";
import ChevronRightIcon from "@material-ui/icons/ChevronRight";
import { racehorse360 } from "@tsg/1st-grpc-web";
import ICalendarDay from "interfaces/ICalendarDay";
import Breakpoints from "common/breakpoints";
import { FacilityData } from "components/Conditions/constants";
import Loader from "components/Loader";
import { useRacehorse360Api } from "hooks/api";
import { getNextDates } from "utils/date-utils";
import {
  getCalendarDay,
  getFacilityBlackoutsCalendar,
  IDateRange,
  isInRange
} from "utils/facilityBlackouts";
import BlackoutDatePopover, { EnableOption } from "./BlackoutDatePopover";
import useStyles from "./styles";

interface Props {
  facilityData: FacilityData;
  selectedFacility: string;
}

const WorkoutScheduling = (props: Props) => {
  const { facilityData, selectedFacility } = props;

  const classes = useStyles();
  const theme = useTheme();
  const matchesDownSm600 = useMediaQuery(
    theme.breakpoints.down(Breakpoints.SM_600),
    {
      noSsr: true
    }
  );
  const queryClient = useQueryClient();
  const today = new Date();
  const [activeMonthStart, setActiveMonthStart] = useState<Date>(
    startOfMonth(today)
  );
  const [calendarStart, setCalendarStart] = useState<Date>(
    startOfWeek(activeMonthStart)
  );
  const [calendar, setCalendar] = useState(
    getNextDates(calendarStart, 35).map(getCalendarDay)
  );
  const [facilityBlackouts, setFacilityBlackouts] = useState<
    racehorse360.IFacilityBlackout[]
  >([]);
  const [selectedDay, setSelectedDay] = useState<ICalendarDay>(null);
  const [selectedRange, setSelectedRange] = useState<IDateRange>(null);
  const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null);
  const [dateIndex, setDateIndex] = useState<number>(0);
  const [isPopoverOpen, setIsPopoverOpen] = useState<boolean>(false);

  const facilityName = selectedFacility
    ? facilityData[selectedFacility].name.toLowerCase()
    : "";
  const calendarTitle = format(activeMonthStart, "MMMM yyyy");
  const listBlackoutDatesKey = `blackout-${selectedFacility}-${calendarTitle}`;

  const firstCalendarDay = calendar[0];
  const lastCalendarDay = calendar[calendar.length - 1];

  const {
    usePullFacilityBlackouts,
    useCreateFacilityBlackout,
    useCancelFacilityBlackout,
    useExcludeSingleFacilityBlackoutDate,
    useSetRepeatToForFacilityBlackout
  } = useRacehorse360Api();

  const { isLoading: isCreating, mutateAsync: createBlackoutDate } =
    useCreateFacilityBlackout();
  const { isLoading: isDeleting, mutateAsync: deleteBlackoutDate } =
    useCancelFacilityBlackout();
  const { isLoading: isExcluding, mutateAsync: excludeBlackoutDate } =
    useExcludeSingleFacilityBlackoutDate();
  const { isLoading: isModifying, mutateAsync: modifyBlackoutDate } =
    useSetRepeatToForFacilityBlackout();
  const { isLoading: isListLoading, isFetching: isListFetching } =
    usePullFacilityBlackouts(
      listBlackoutDatesKey,
      {
        facilityId: selectedFacility,
        startDate: formatISO(addDays(firstCalendarDay.date, -30), {
          representation: "date"
        }),
        endDate: formatISO(addDays(lastCalendarDay.date, 30), {
          representation: "date"
        })
      },
      {
        onSuccess: ({ facilityBlackouts }) => {
          const newCalendar = getFacilityBlackoutsCalendar(
            facilityBlackouts,
            calendar
          );

          setFacilityBlackouts(facilityBlackouts);
          setCalendar(newCalendar);
        }
      }
    );

  const handleMonthChange = offset => () => {
    const newMonth = addMonths(activeMonthStart, offset);
    const newStart = startOfWeek(newMonth);
    setActiveMonthStart(newMonth);
    setCalendarStart(newStart);
    setCalendar(getNextDates(newStart, 35).map(getCalendarDay));
  };

  const handleDateClick =
    (day: ICalendarDay, index) =>
    (event: React.MouseEvent<HTMLButtonElement>) => {
      setSelectedDay(day);
      setSelectedRange({
        startDate: day.date,
        endDate: day.date,
        repeatance:
          racehorse360.FacilityBlackoutRepeatance
            .FACILITY_BLACKOUT_REPEATANCE_DOES_NOT_REPEAT
      });
      setAnchorEl(event.currentTarget);
      setIsPopoverOpen(true);
      setDateIndex(index);
    };

  const handlePopoverClose = () => {
    setIsPopoverOpen(false);
    setSelectedDay(null);
    setSelectedRange(null);
  };

  const handleDateRangeChange = range => {
    setSelectedRange(range);
  };

  const handleDisable = (
    repeatance: racehorse360.FacilityBlackoutRepeatance,
    startDate: Date,
    endDate: Date
  ) => {
    createBlackoutDate({
      facilityId: selectedFacility,
      dateFrom: formatISO(startDate, { representation: "date" }),
      dateTo: formatISO(endDate, { representation: "date" }),
      repeatance
    })
      .catch(error => {
        console.error(error);
      })
      .then(() => {
        return queryClient.invalidateQueries(listBlackoutDatesKey);
      })
      .catch(error => {
        console.error(error);
      })
      .finally(() => {
        setIsPopoverOpen(false);
        setSelectedDay(null);
        setSelectedRange(null);
      });
  };

  const handleEnable = (enableOption: EnableOption) => {
    const facilityBlackout = selectedDay.facilityBlackout;

    const isNoRepeat =
      facilityBlackout.repeatance ===
      racehorse360.FacilityBlackoutRepeatance
        .FACILITY_BLACKOUT_REPEATANCE_DOES_NOT_REPEAT;
    let promise;

    if (enableOption === EnableOption.Serie) {
      promise = deleteBlackoutDate({
        id: facilityBlackout.id
      });
    } else {
      if (isNoRepeat) {
        const dateFrom = parseISO(facilityBlackout.dateFrom);
        const dateTo = parseISO(facilityBlackout.dateTo);
        if (facilityBlackout.dateFrom !== facilityBlackout.dateTo) {
          const isLastDayInRange =
            differenceInCalendarDays(dateTo, dateFrom) -
              facilityBlackout.facilityBlackoutExcludedDates.length ===
            0;
          if (isLastDayInRange) {
            promise = deleteBlackoutDate({
              id: facilityBlackout.id
            });
          } else {
            promise = excludeBlackoutDate({
              id: facilityBlackout.id,
              date: formatISO(selectedDay.date, { representation: "date" })
            });
          }
        } else {
          promise = deleteBlackoutDate({
            id: facilityBlackout.id
          });
        }
      } else if (enableOption === EnableOption.DayAndAfter) {
        const blackoutDateFrom = parseISO(facilityBlackout.dateFrom);
        const blackoutDateRepeatTo = addDays(selectedDay.date, -1);

        if (isBefore(blackoutDateRepeatTo, blackoutDateFrom)) {
          promise = deleteBlackoutDate({
            id: facilityBlackout.id
          });
        } else {
          promise = modifyBlackoutDate({
            id: facilityBlackout.id,
            date: formatISO(blackoutDateRepeatTo, { representation: "date" })
          });
        }
      } else if (enableOption === EnableOption.DayOnly) {
        promise = excludeBlackoutDate({
          id: facilityBlackout.id,
          date: formatISO(selectedDay.date, { representation: "date" })
        });
      } else {
        return;
      }
    }

    promise
      .catch(error => {
        // Primary request error
        console.error(error);
      })
      .then(() => {
        if (enableOption === EnableOption.Serie) {
          const singleFacilityBlackout = facilityBlackouts.find(
            facilityBlackout => {
              return (
                isSameDay(
                  parseISO(facilityBlackout.dateFrom),
                  selectedDay.date
                ) &&
                facilityBlackout.repeatance ===
                  racehorse360.FacilityBlackoutRepeatance
                    .FACILITY_BLACKOUT_REPEATANCE_DOES_NOT_REPEAT
              );
            }
          );

          if (singleFacilityBlackout) {
            return deleteBlackoutDate({
              id: singleFacilityBlackout.id
            });
          }
        }
      })
      .catch(error => {
        // Delete single blackout date error
        console.error(error);
      })
      .then(() => {
        return queryClient.invalidateQueries(listBlackoutDatesKey);
      })
      .catch(error => {
        // Invalidate request error
        console.error(error);
      })
      .finally(() => {
        setIsPopoverOpen(false);
        setSelectedDay(null);
        setSelectedRange(null);
      });
  };

  const isLoading =
    isListLoading ||
    isListFetching ||
    isCreating ||
    isDeleting ||
    isExcluding ||
    isModifying;

  return (
    <>
      <Box className={classes.container}>
        <Box className={classes.header}>
          <Typography className={classes.headerTitle}>
            {facilityName}
          </Typography>
          <Box className={classes.controls}>
            <Box>
              <IconButton
                size={"small"}
                onClick={handleMonthChange(-1)}
                className={classes.control}
              >
                <ChevronLeftIcon className={classes.controlIcon} />
              </IconButton>
              <IconButton
                size={"small"}
                onClick={handleMonthChange(1)}
                className={classes.control}
              >
                <ChevronRightIcon className={classes.controlIcon} />
              </IconButton>
            </Box>
            <Typography className={classes.controlLabel}>
              {calendarTitle}
            </Typography>
          </Box>
        </Box>
        <Box className={classes.dayNames}>
          {calendar.slice(0, 7).map(day => (
            <Typography key={day.date.getTime()} className={classes.dayName}>
              {matchesDownSm600
                ? format(day.date, "iii").charAt(0)
                : format(day.date, "iii")}
            </Typography>
          ))}
        </Box>
        <Box className={classes.calendarWrapper}>
          {isListLoading && <Loader overlay />}
          <Box className={classes.calendar}>
            {calendar.map((day, index) => (
              <Button
                key={day.date.getTime()}
                classes={{
                  disabled: classes.pastDateBox
                }}
                className={clsx(classes.dateButton, {
                  [classes.dateButtonActive]:
                    selectedDay && isInRange(day.date, selectedRange),
                  [classes.blackout]: !!day.facilityBlackout
                })}
                disabled={day.isPast}
                onClick={handleDateClick(day, index)}
              >
                <Typography
                  className={clsx(classes.date, {
                    [classes.today]: day.isToday,
                    [classes.pastDate]: day.isPast
                  })}
                >
                  {day.label}
                </Typography>
                {!!day.facilityBlackout && (
                  <Typography
                    className={clsx(classes.disabledMarker, {
                      [classes.disabledMarkerOutlined]:
                        day.facilityBlackout.repeatance ===
                        racehorse360.FacilityBlackoutRepeatance
                          .FACILITY_BLACKOUT_REPEATANCE_WEEKLY
                    })}
                  >
                    Disabled
                  </Typography>
                )}
              </Button>
            ))}
          </Box>
        </Box>
      </Box>
      {selectedDay && (
        <BlackoutDatePopover
          selectedDay={selectedDay}
          anchorEl={anchorEl}
          facilityName={facilityName}
          isOpen={isPopoverOpen}
          isLoading={isLoading}
          onDateRangeChange={handleDateRangeChange}
          onClose={handlePopoverClose}
          onDisable={handleDisable}
          onEnable={handleEnable}
          options={{
            anchorOrigin: {
              vertical: "center",
              horizontal: dateIndex % 7 < 3 ? "right" : "left"
            },
            transformOrigin: {
              vertical: "center",
              horizontal: dateIndex % 7 < 3 ? "left" : "right"
            }
          }}
        />
      )}
    </>
  );
};

export default WorkoutScheduling;
