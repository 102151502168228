import { racehorse360 } from "@tsg/1st-grpc-web";
import { SortOrder } from "interfaces/SortOrder";
import { parseDistanceToFurlongs } from "utils/distance-utils";
import { checkSortingType } from "utils/sort-utils";

type TSortFn = (
  p1: racehorse360.HorsesCountGroupedByPreferredCondition,
  p2: racehorse360.HorsesCountGroupedByPreferredCondition
) => number;

const parseClaimingPrice = cp => {
  return Number(cp?.match(/\d+/)?.shift() || -1);
};

export const getSortFn = (
  orderBy: keyof racehorse360.HorsesCountGroupedByPreferredCondition,
  order: SortOrder
): TSortFn => {
  const { isAsc } = checkSortingType(order);
  const multiplier = isAsc ? 1 : -1;
  switch (orderBy) {
    case "count":
      return (
        pcg1: racehorse360.HorsesCountGroupedByPreferredCondition,
        pcg2
      ) => (pcg1.count - pcg2.count) * multiplier;
    case "surface":
      return (pcg1, pcg2) => (pcg1.surface - pcg2.surface) * multiplier;
    case "distance":
      return (pcg1, pcg2) =>
        (parseDistanceToFurlongs(pcg1.distance) -
          parseDistanceToFurlongs(pcg2.distance)) *
        multiplier;
    case "raceType":
      return (pcg1, pcg2) =>
        pcg1.raceType?.localeCompare(pcg2.raceType) * multiplier;
    case "claimingPrice":
      return (pcg1, pcg2) =>
        (parseClaimingPrice(pcg1.claimingPrice) -
          parseClaimingPrice(pcg2.claimingPrice)) *
        multiplier;
    case "age":
      return (pcg1, pcg2) => pcg1.age?.localeCompare(pcg2.age) * multiplier;
    case "gender":
      return (pcg1, pcg2) =>
        pcg1.gender?.localeCompare(pcg2.gender) * multiplier;
    case "stateBred":
      return (pcg1, pcg2) => (pcg1.stateBred - pcg2.stateBred) * multiplier;
  }
};

export const pcToString = (
  pc: racehorse360.IHorsesCountGroupedByPreferredCondition
): string => {
  return Object.values(pc).join("-");
};
