import React, { useEffect, useRef, useState } from "react";
import { useSelector } from "react-redux";
import clsx from "clsx";

import { Theme } from "@material-ui/core/styles";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import Dialog from "@material-ui/core/Dialog";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ArrowDropDownIcon from "@material-ui/icons/ArrowDropDown";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import ListItemText from "@material-ui/core/ListItemText";
import Typography from "@material-ui/core/Typography";
import IconButton from "@material-ui/core/IconButton";
import Button from "@material-ui/core/Button";
import CloseIcon from "@material-ui/icons/Close";

import { racehorse360 } from "@tsg/1st-grpc-web";

import FacilityIcon from "components/Icons/Facility";
import { useLoggedInUser } from "components/LoggedInUserProvider";
import { useWindowSize } from "hooks/screen";
import IQueriesConfigState from "interfaces/queriesConfigState";
import { FacilitiesState } from "store/reducers/facilities";

import useStyles from "./styles";

export interface Props {
  className?: string;
  selectedFacility?: racehorse360.IFacility;
  onChange: (facility: racehorse360.IFacility) => void;
  isActive?: boolean;
  instructions?: string;
}

interface Coordinates {
  top: number;
  left: number | string;
  right: number | string;
}

const DIALOG_WIDTH = 300; // max dialog width

const FacilitySelect = React.memo((props: Props) => {
  const { className, onChange, instructions } = props;
  const { currentUser } = useLoggedInUser();

  const { width, height } = useWindowSize();
  const [open, setOpen] = useState<boolean>(false);
  const [coordinates, setCoordinates] = useState<Coordinates>({
    top: 0,
    left: 0,
    right: 0
  });
  const [selectedFacility, setSelectedFacility] =
    useState<racehorse360.IFacility>(props.selectedFacility);
  const classes = useStyles({
    foregroundColor: selectedFacility?.foregroundColor
  });
  const matches = useMediaQuery((theme: Theme) => theme.breakpoints.up("lg"));
  const facilityNameRef = useRef(null);
  const dialogRef = useRef(null);
  const { facilities } = useSelector(
    (state: { facilities: FacilitiesState }) =>
      state?.facilities || { facilities: [] }
  );
  const trainerSelectedFacilityId = useSelector(
    (state: { queriesConfig: IQueriesConfigState }) => {
      return state?.queriesConfig?.currentFacilityId;
    }
  );

  const currentFacilityId = currentUser.isTrainer
    ? trainerSelectedFacilityId
    : currentUser.facilityIdsListFromRoles[0];

  const handleFacilityChanged = (facility: racehorse360.IFacility) => {
    if (facility?.id !== selectedFacility?.id) {
      setSelectedFacility(facility);
      onChange(facility);
    }
  };

  const handleOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleFacilitySelect = (facility: racehorse360.IFacility) => {
    handleFacilityChanged(facility);
    setOpen(false);
  };

  const handleDialogEntering = () => {
    countPosition();
  };

  const countPosition = () => {
    const {
      top,
      left,
      right,
      height: nameHeight
    } = facilityNameRef.current.getBoundingClientRect();
    let offsetTop = top + nameHeight;
    const offsetLeft = left + DIALOG_WIDTH < window.innerWidth ? left : "auto";
    const offsetRight =
      left + DIALOG_WIDTH > window.innerWidth
        ? window.innerWidth - right
        : "auto";

    if (dialogRef.current) {
      const dialogRect = dialogRef.current.getBoundingClientRect();
      if (offsetTop + dialogRect.height > window.innerHeight) {
        offsetTop = top - dialogRect.height;
      }
    }

    setCoordinates({ top: offsetTop, left: offsetLeft, right: offsetRight });
  };

  useEffect(() => {
    countPosition();
  }, [matches, width, height]);

  useEffect(() => {
    const facilityId = props.selectedFacility
      ? props.selectedFacility.id
      : currentFacilityId;
    let defaultFacility: racehorse360.IFacility;

    if (facilities?.length) {
      if (facilityId) {
        defaultFacility =
          facilities.find(facility => facility.id === facilityId) ||
          facilities[0];
      } else {
        defaultFacility =
          facilities.find(facility => facility.code === "SA") || facilities[0];
      }
    }

    handleFacilityChanged(defaultFacility);
  }, [props.selectedFacility, facilities, currentFacilityId]);

  return (
    <>
      <Button
        ref={facilityNameRef}
        className={clsx(classes.facilityButton, className)}
        onClick={handleOpen}
      >
        {Boolean(selectedFacility) && (
          <>
            <span className={classes.facilityNameText}>
              {selectedFacility.name.toLowerCase()}
            </span>
            <ArrowDropDownIcon
              className={clsx(classes.facilityNameIcon, {
                [classes.facilityNameIconOpen]: open
              })}
            />
          </>
        )}
      </Button>

      <Dialog
        fullWidth
        className={clsx(classes.root, {
          [classes.rootPopoverBack]: matches
        })}
        open={open}
        TransitionProps={{
          onEntering: handleDialogEntering
        }}
        onClose={handleClose}
        classes={{
          paper: clsx({ [classes.rootPopover]: matches })
        }}
        PaperProps={{
          style: {
            maxWidth: DIALOG_WIDTH,
            margin: "0",
            left: matches ? coordinates.left : 0,
            top: matches ? coordinates.top : 0,
            right: matches ? coordinates.right : 0
          }
        }}
        hideBackdrop={matches}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <div ref={dialogRef}>
          <DialogTitle disableTypography className={classes.header}>
            <Typography
              variant="h6"
              className={clsx(
                classes.title,
                { [classes.titleSmall]: matches },
                { [classes.titleInstructions]: instructions }
              )}
            >
              Track Selection
            </Typography>
            {instructions && (
              <Typography className={classes.subTitle} color="textSecondary">
                {instructions}
              </Typography>
            )}
            <IconButton
              aria-label="close"
              onClick={handleClose}
              className={clsx(classes.closeButton, {
                [classes.closeButtonHide]: matches
              })}
            >
              <CloseIcon className={classes.closeButtonIcon} />
            </IconButton>
          </DialogTitle>

          <DialogContent className={classes.content}>
            <List disablePadding>
              {facilities?.map(facility => (
                <ListItem
                  button
                  divider
                  key={facility.id}
                  className={clsx(classes.listItem, {
                    [classes.listItemSmall]: matches
                  })}
                  onClick={() => handleFacilitySelect(facility)}
                >
                  <ListItemIcon className={classes.listItemIcon}>
                    <FacilityIcon
                      selected={facility.id === selectedFacility?.id}
                      code={facility?.code.trim()}
                      backgroundColor={facility?.backgroundColor}
                      strokeColor={facility?.strokeColor}
                    />
                  </ListItemIcon>
                  <ListItemText
                    primary={`${facility.name} (${facility.code.trim()})`}
                    classes={{ primary: classes.listItemText }}
                    className={clsx({
                      [classes.listItemSelected]:
                        facility.id === selectedFacility?.id
                    })}
                  />
                </ListItem>
              ))}
            </List>
          </DialogContent>
        </div>
      </Dialog>
    </>
  );
});

export default FacilitySelect;
