import React, { useEffect, useState } from "react";
import {
  matchPath,
  Route,
  Switch,
  Redirect,
  useLocation
} from "react-router-dom";
import { useDispatch } from "react-redux";
import clsx from "clsx";

import CssBaseline from "@material-ui/core/CssBaseline";

import { useAuth0 } from "@auth0/auth0-react";

import routes from "common/routes";
import AppSideBar from "components/AppSideBar";
import AppHeader from "components/AppHeader";
import ErrorBoundary from "components/ErrorBoundary";
import { useLoggedInUser } from "components/LoggedInUserProvider";
import LogRocket from "components/LogRocket";
import { usePermissions } from "components/PermissionsProvider";
import { SnackbarContextProvider } from "components/SnackbarContext/SnackbarContext";
import SplashScreen from "components/SplashScreen";
import { useRacehorse360Api } from "hooks/api";
import AccountPage from "pages/AccountPage";
import AdminPage from "pages/Admin/";
import EditUserPage from "pages/Admin/EditUserPage";
import CalendarPage from "pages/Calendar/CalendarPage";
import ConditionBookPage from "pages/ConditionBookPage";
import DashboardPage from "pages/DashboardPage";
import HorseDetailsPage from "pages/HorseDetailsPage";
import HorseListPage from "pages/HorseListPage";
import HorseWorkoutCommentsPage from "pages/HorseWorkoutCommentsPage";
import HorseWorkoutsPage from "pages/HorseWorkoutsPage";
import ManageTracksPage from "pages/ManageTracksPage";
import NotFoundPage from "pages/NotFoundPage";
import PreferredConditionPage from "pages/PreferredConditionPage";
import WorkoutsPage from "pages/WorkoutsPage";
import WorkOrdersPage from "pages/WorkOrdersPage";
import StallApplicationPage from "pages/StallApplicationPage";
import StallApplicationFormViewPage from "pages/StallApplicationFormViewPage";
import StallApplicationNewFormPage from "pages/StallApplicationNewFormPage";
import StallApplicationFormPage from "pages/StallApplicationFormPage";
import InventoryAnalytics from "pages/InventoryAnalyticsPage";
import { setFacilities } from "store/actions/facilities";

import useStyles from "./styles";

const shouldHideHeader = (path, breakpoint: "xs" | "sm", currentUser) => {
  const mobileHeadlessRoutes = {
    xs: [
      routes.workoutComments.path,
      currentUser.isRacingOfficial
        ? routes.stallApplicationNewForm.path
        : routes.stallApplicationTrainerForm.path
    ],
    sm: [
      currentUser.isRacingOfficial
        ? routes.stallApplicationNewForm.path
        : routes.stallApplicationTrainerForm.path
    ]
  };

  return mobileHeadlessRoutes[breakpoint].some(route =>
    matchPath(path, {
      path: route,
      exact: true,
      strict: true
    })
  );
};

const checkProfilePage = path => {
  const profilePageRoutes = [routes.account.path];

  return profilePageRoutes.some(route =>
    matchPath(path, {
      path: route,
      exact: true,
      strict: true
    })
  );
};

const App = () => {
  const classes = useStyles();
  const dispatch = useDispatch();

  const { isLoading, isAuthenticated, loginWithRedirect, user } = useAuth0();
  const location = useLocation();
  const { currentUser } = useLoggedInUser();
  const {
    isLoading: arePermissionsLoading,
    isStallApplicationPageEnabled,
    isWorkOrdersPageEnabled,
    isInventoryAnalyticsPageEnabled
  } = usePermissions();

  const isStaticHeader = [
    location.pathname === routes.conditionBook.path,
    location.pathname === routes.manageTracks.path,
    location.pathname === routes.manageTracksFacility.path,
    location.pathname === routes.manageTracksTrackConditions.path,
    location.pathname === routes.manageTracksWorkoutScheduling.path
  ].some(item => item);

  const { useListFacilities } = useRacehorse360Api();

  const hideHeaderOnXs = shouldHideHeader(location.pathname, "xs", currentUser);
  const hideHeaderOnSm = shouldHideHeader(location.pathname, "sm", currentUser);

  const [isProfilePage, setIsProfilePage] = useState(
    checkProfilePage(location.pathname)
  );

  useListFacilities(
    {
      query: {
        isActive: { value: true },
        ids: !currentUser.isTrainer
          ? currentUser.facilityIdsListFromRoles
          : null
      },
      pagingOptions: {
        maxResults: 20
      },
      getOptions: {
        select: [
          "name",
          "latitude",
          "longitude",
          "backgroundColor",
          "foregroundColor",
          "strokeColor",
          "code",
          "timezone",
          "shutOffPeriodInHours",
          "shutOffHours"
        ],
        orderBy: ["name"]
      }
    },
    {
      onSuccess: response => {
        dispatch(setFacilities(response.facilities));
      },
      onError: error => console.error(error)
    }
  );

  useEffect(() => {
    const fn = async () => {
      await loginWithRedirect();
    };

    if (!isAuthenticated && !isLoading) {
      void fn();
    }
  }, [isLoading, isAuthenticated, loginWithRedirect]);

  useEffect(() => {
    setIsProfilePage(checkProfilePage(location.pathname));
  }, [location.pathname]);

  const getPageComponent =
    (
      component: React.ReactNode,
      accessTest: () => boolean,
      redirectToOnAccessFailure: string
    ) =>
    () => {
      if (!currentUser.isLoaded || arePermissionsLoading) {
        // don't redirect until we know who the user is
        // and application settings loaded
        // otherwise deep linking breaks.
        return <></>;
      }

      return accessTest() ? (
        <React.Fragment>{component}</React.Fragment>
      ) : (
        <Redirect to={redirectToOnAccessFailure} />
      );
    };

  const getDashboardComponent = (component: React.ReactNode) => () => {
    // don't redirect until we know who the user is
    // otherwise deep linking breaks.
    if (!currentUser.isLoaded) {
      return <></>;
    }

    // No access yet? Go to the profile page
    if (!currentUser.roles.length) {
      return <Redirect to={routes.account.path} />;
    }

    // Now determine correct dashboard for this user
    if (currentUser.isTrainer) {
      return <React.Fragment>{component}</React.Fragment>;
    } else if (currentUser.isRegulatoryVet) {
      return <Redirect to={routes.horseList.path} />;
    } else if (currentUser.isVeterinarian) {
      return <Redirect to={routes.workoutRequests.path} />;
    } else if (currentUser.isRacingOfficial) {
      return <Redirect to={routes.workouts.path} />;
    } else if (currentUser.isMaintenance) {
      return <Redirect to={routes.workOrders.path} />;
    } else if (currentUser.isAdmin) {
      return <Redirect to={routes.admin.path} />;
    } else {
      return <></>;
    }
  };

  return (
    <>
      <LogRocket />
      <div className={classes.appRoot}>
        <CssBaseline />
        <SplashScreen />
        {Boolean(user) && (
          <>
            {!isProfilePage && <AppSideBar />}
            <SnackbarContextProvider>
              <div
                className={clsx(classes.flexWrapper, {
                  [classes.flexWrapperFullWidth]: isProfilePage
                })}
              >
                <AppHeader
                  rootClassName={clsx({
                    [classes.staticHeader]: isStaticHeader
                  })}
                  className={clsx({
                    [classes.hideOnXs]: hideHeaderOnXs,
                    [classes.hideOnSm]: hideHeaderOnSm
                  })}
                  isProfilePage={isProfilePage}
                />
                <main
                  className={clsx(classes.appContent, {
                    [classes.headerlessDownSM600]: hideHeaderOnSm
                  })}
                >
                  <ErrorBoundary key={location.pathname}>
                    <Switch>
                      {/*Route property render/component. There is a difference in the use of these properties. */}
                      {/*component: React.createElement(CreatePage, props) be called because of React.createElement(component, props) from source code. The instantiation would cause to be remounting.*/}
                      {/*render: It saves you runtime because no lifecycle methods are run*/}
                      {/*https://reactrouter.com/web/api/Route/component*/}
                      <Route
                        exact
                        path={routes.dashboard.path}
                        render={getDashboardComponent(<DashboardPage />)}
                      />
                      <Route
                        path={routes.account.path}
                        component={AccountPage}
                      />
                      <Route
                        path={routes.workoutComments.path}
                        render={getPageComponent(
                          <HorseWorkoutCommentsPage />,
                          () =>
                            currentUser.isTrainer ||
                            currentUser.isRacingOfficial,
                          routes.dashboard.path
                        )}
                      />
                      <Route
                        path={routes.workouts.path}
                        render={getPageComponent(
                          <WorkoutsPage />,
                          () =>
                            !currentUser.isMaintenance &&
                            !currentUser.isRegulatoryVet,
                          routes.dashboard.path
                        )}
                      />
                      <Route
                        path={routes.horseWorkouts.path}
                        render={getPageComponent(
                          <HorseWorkoutsPage />,
                          () =>
                            currentUser.isTrainer ||
                            currentUser.isRacingOfficial,
                          routes.dashboard.path
                        )}
                      />
                      <Route
                        path={routes.calendar.path}
                        component={getPageComponent(
                          <CalendarPage />,
                          () => currentUser.roles.length > 0,
                          routes.account.path
                        )}
                      />
                      <Route
                        path={routes.manageTracks.path}
                        render={getPageComponent(
                          <ManageTracksPage />,
                          () =>
                            currentUser.isRacingOfficial ||
                            currentUser.isChiefVet ||
                            currentUser.isSeniorVet ||
                            currentUser.isAdmin,
                          routes.dashboard.path
                        )}
                      />
                      <Route
                        path={routes.horseDetails.path}
                        render={getPageComponent(
                          <HorseDetailsPage />,
                          () =>
                            currentUser.isAdmin ||
                            currentUser.isTrainer ||
                            currentUser.isRacingOfficial ||
                            currentUser.isVeterinarian,
                          routes.dashboard.path
                        )}
                      />
                      <Route
                        exact
                        path={routes.horseList.path}
                        render={getPageComponent(
                          <HorseListPage />,
                          () =>
                            currentUser.isAdmin ||
                            currentUser.isTrainer ||
                            currentUser.isRacingOfficial ||
                            currentUser.isVeterinarian,
                          routes.dashboard.path
                        )}
                      />
                      <Route
                        path={routes.preferredConditions.path}
                        render={getPageComponent(
                          <PreferredConditionPage />,
                          () =>
                            currentUser.isRacingOfficial || currentUser.isAdmin,
                          //TODO: fix PreferredConditions page for basic roles and uncomment lines below
                          // ||
                          // currentUser.isAdmin ||
                          // currentUser.isMaintenance,
                          routes.workouts.path
                        )}
                      />
                      <Route
                        exact
                        path={routes.admin.path}
                        component={getPageComponent(
                          <AdminPage />,
                          () => currentUser.isAdmin,
                          routes.dashboard.path
                        )}
                      />
                      <Route
                        exact
                        path={routes.conditionBook.path}
                        component={getPageComponent(
                          <ConditionBookPage />,
                          () => !currentUser.isRegulatoryVet,
                          routes.account.path
                        )}
                      />
                      <Route
                        exact
                        path={routes.adminEditUser.path}
                        component={getPageComponent(
                          <EditUserPage />,
                          () => currentUser.isAdmin,
                          routes.dashboard.path
                        )}
                      />
                      <Route
                        exact
                        path={routes.workOrders.path}
                        component={getPageComponent(
                          <WorkOrdersPage />,
                          () =>
                            !currentUser.isRegulatoryVet &&
                            isWorkOrdersPageEnabled,
                          routes.page404.path
                        )}
                      />
                      <Route
                        exact
                        path={routes.stallApplication.path}
                        component={getPageComponent(
                          <StallApplicationPage />,
                          () =>
                            (currentUser.isTrainer ||
                              currentUser.isRacingOfficial) &&
                            isStallApplicationPageEnabled,
                          routes.dashboard.path
                        )}
                      />
                      <Route
                        exact
                        path={routes.stallApplicationNewForm.path}
                        component={getPageComponent(
                          <StallApplicationNewFormPage />,
                          () => currentUser.isRacingOfficial,
                          routes.dashboard.path
                        )}
                      />
                      <Route
                        exact
                        path={routes.stallApplicationTrainerForm.path}
                        component={getPageComponent(
                          <StallApplicationFormPage />,
                          () =>
                            currentUser.isTrainer ||
                            currentUser.isAssistantTrainer ||
                            currentUser.isRacingOfficial,
                          routes.dashboard.path
                        )}
                      />
                      <Route
                        exact
                        path={routes.stallApplicationFormView.path}
                        render={getPageComponent(
                          <StallApplicationFormViewPage />,
                          () => currentUser.isRacingOfficial,
                          routes.dashboard.path
                        )}
                      />
                      <Route
                        path={routes.inventoryAnalytics.path}
                        render={getPageComponent(
                          <InventoryAnalytics />,
                          () =>
                            currentUser.isRacingOfficial &&
                            isInventoryAnalyticsPageEnabled,
                          routes.workouts.path
                        )}
                      />
                      <Route
                        component={getPageComponent(
                          <NotFoundPage />,
                          () => currentUser.roles.length > 0,
                          routes.account.path
                        )}
                      />
                    </Switch>
                  </ErrorBoundary>
                </main>
              </div>
            </SnackbarContextProvider>
          </>
        )}
      </div>
    </>
  );
};

export default App;
