import { makeStyles } from "@material-ui/core/styles";
import { MuiTheme } from "theme";
import Breakpoints from "common/breakpoints";

export default makeStyles((theme: MuiTheme) => ({
  appHeader: {
    position: "sticky",
    top: 0,
    backgroundColor: "silver",
    zIndex: theme.zIndex.app.header
  },
  appBar: {
    display: "flex",
    width: "auto",
    backgroundImage: `url("/rh360-main-bg.png")`,
    backgroundRepeat: "no-repeat",
    backgroundSize: "cover",
    backgroundPositionY: "63.5%",
    boxShadow: "5px 1px 7px 0px #00000026"
  },
  toolbar: {
    [theme.breakpoints.down("xs")]: {
      minHeight: theme.heightMobileHeader
    }
  },
  hamburgerButton: {
    flex: "auto",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    flexGrow: 0,
    [theme.breakpoints.up(Breakpoints.SX_720)]: {
      display: "none"
    }
  },
  hamburgerIcon: {
    width: 27,
    height: 27,
    color: "white"
  },
  header: {
    paddingLeft: 24,
    paddingRight: 24,
    width: "100%",
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    [theme.breakpoints.up("sm")]: {
      paddingLeft: 16
    },
    [theme.breakpoints.down("xs")]: {
      paddingLeft: 8,
      paddingRight: 16
    }
  },
  headerLeftSideBlock: {
    width: "30%"
  },
  headerRightSideBlock: {
    width: "30%",
    display: "flex",
    justifyContent: "flex-end"
  },
  title: {
    width: "100%",
    marginLeft: 8,
    fontSize: 20,
    textAlign: "center"
  },
  icon: {
    fontSize: 26,
    [theme.breakpoints.down("xs")]: {
      fontSize: 20
    }
  },
  hasBackButton: {
    "& $hamburgerButton": {
      [theme.breakpoints.down("xs")]: {
        display: "none"
      }
    },
    "& $title": {
      fontSize: 20
    }
  },
  logoBackIcon: {
    color: theme.palette.common.white,
    "& .MuiSvgIcon-root": {
      fontSize: 30
    }
  },
  appSideBar: {
    height: "100vh",
    width: 222,
    display: "flex",
    position: "relative",
    top: 0,
    zIndex: 0,
    boxShadow: "none",
    [theme.breakpoints.down("xs")]: {
      display: "flex"
    },
    "& .MuiListItem-root": {
      height: 52,
      paddingLeft: 18,
      paddingRight: 20,
      "&.Mui-selected": {
        "& .MuiListItemText-root .MuiTypography-root": {
          color: theme.palette.text.primary
        }
      },
      "& .MuiListItemIcon-root": {
        minWidth: "auto",
        color: "rgba(127, 127, 127, 1)"
      },
      "& .MuiListItemText-root .MuiTypography-root": {
        paddingLeft: 12,
        fontSize: 18,
        color: theme.palette.text.secondary,
        [theme.breakpoints.down("md")]: {
          display: "block"
        }
      }
    }
  },
  closeVetExamPage: {
    fontSize: 18,
    color: "#eee",
    textTransform: "capitalize"
  },
  cancelButton: {
    fontSize: 16,
    color: theme.palette.common.white,
    textTransform: "none"
  }
}));
