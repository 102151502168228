import React from "react";
import clsx from "clsx";
import Typography from "@material-ui/core/Typography";
import CloseIcon from "@material-ui/icons/Close";
import MuiDialog from "@material-ui/core/Dialog";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
import IconButton from "@material-ui/core/IconButton";
import useStyles from "./styles";
import { TransitionProps } from "@material-ui/core/transitions";

export interface Props {
  alert?: boolean;
  isOpen: boolean;
  title?: string | React.ReactNode;
  subtitle?: string;
  children: React.ReactNode;
  className?: string;
  onClose: (
    event?: React.MouseEvent | React.KeyboardEvent,
    reason?: string
  ) => void;
  titleClassName?: string;
  hideHeader?: boolean;
  hideXButton?: boolean;
  disableEscapeKeyDown?: boolean;
  style?: React.CSSProperties;
  classes?: Record<string, string>;
  TransitionProps?: TransitionProps;
}

const Dialog = (props: Props) => {
  const {
    className = "",
    title,
    subtitle,
    children,
    isOpen,
    onClose,
    alert,
    titleClassName,
    hideHeader = false,
    hideXButton = false,
    disableEscapeKeyDown = false,
    style,
    TransitionProps,
    classes = {}
  } = props;
  const ownClasses = useStyles();

  const handleClose = e => {
    onClose(e);
  };

  return (
    <MuiDialog
      className={className}
      classes={{
        paper: clsx({ [ownClasses.noTitle]: hideHeader }, classes.paper)
      }}
      onClose={handleClose}
      aria-labelledby="customized-dialog-title"
      open={isOpen}
      disableEscapeKeyDown={disableEscapeKeyDown}
      style={style}
      TransitionProps={TransitionProps}
    >
      {!hideHeader && (
        <DialogTitle className={classes.title} disableTypography>
          <Typography
            className={clsx({ [ownClasses.alert]: alert }, titleClassName)}
            variant="h6"
          >
            {title}
          </Typography>
          {subtitle && <Typography variant="caption">{subtitle}</Typography>}
          {!hideXButton && (
            <IconButton
              aria-label="close"
              className={ownClasses.closeButton}
              onClick={handleClose}
            >
              <CloseIcon />
            </IconButton>
          )}
        </DialogTitle>
      )}
      <DialogContent className={classes.content} dividers>
        {children}
      </DialogContent>
    </MuiDialog>
  );
};

export default Dialog;
