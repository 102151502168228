import { MuiTheme } from "theme";
import { makeStyles } from "@material-ui/core/styles";
import Breakpoints from "common/breakpoints";

export default makeStyles((theme: MuiTheme) => ({
  root: {
    [theme.breakpoints.up(Breakpoints.SM_600)]: {
      borderLeft: `1px solid ${theme.palette.divider}`
    }
  },
  pageContent: {
    backgroundColor: theme.palette.common.white,
    display: "flex",
    flexDirection: "column",
    paddingBottom: 16
  },
  noContent: {
    justifyContent: "center",
    alignItems: "center",
    "& $addNewFormButton": {
      marginTop: 16
    }
  },
  facility: {
    boxShadow: "none",
    borderTop: "1px solid #dddddd",
    "&:first-child": {
      borderTop: "none"
    }
  },
  facilityCircle: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    width: 36,
    height: 36,
    borderRadius: "50%",
    "& > svg": {
      fontSize: 20
    }
  },
  facilityHeader: {
    height: 84,
    padding: "0 24px",
    "&.MuiAccordionSummary-root.Mui-expanded": {
      borderBottom: "none"
    },
    [theme.breakpoints.down(Breakpoints.SM_600)]: {
      padding: "0 16px"
    }
  },
  facilityHeaderContent: {
    display: "flex",
    alignItems: "center",
    gap: 20,
    textTransform: "capitalize"
  },
  formsGroup: {
    display: "flex",
    flexWrap: "wrap",
    gap: 20,
    padding: "0 24px 24px",
    [theme.breakpoints.down(Breakpoints.SM_600)]: {
      padding: "0 16px 20px",
      gap: 16
    }
  },
  stallApplicationForm: {
    position: "relative",
    display: "flex",
    flex: "0 calc(50% - 10px)",
    maxWidth: "calc(50% - 10px)",
    flexDirection: "column",
    gap: 8,
    padding: "10px 12px",
    minHeight: 96,
    lineHeight: "normal",
    border: `1px solid ${theme.palette.divider}`,
    backgroundColor: "#f6f6f6",
    borderRadius: 6,
    [theme.breakpoints.down(Breakpoints.SM_600)]: {
      flex: "100%",
      maxWidth: "100%"
    },
    "& > span:nth-child(1)": {
      fontFamily: "SuisseIntlMedium, SuisseIntlRegular",
      fontSize: 14,
      color: "#1a1a1a",
      whiteSpace: "nowrap",
      textOverflow: "ellipsis",
      overflow: "hidden"
    },
    "& > span:nth-child(2)": {
      fontSize: 12,
      color: "#1a1a1a"
    },
    "& > span:nth-child(3)": {
      fontSize: 12,
      color: theme.palette.primary.dark
    }
  },
  stallApplicationFormViewButton: {
    position: "absolute",
    right: 13,
    bottom: 13,
    width: 112,
    height: 32,
    borderRadius: 16
  },
  addNewFormButton: {
    marginTop: 20,
    height: 34,
    width: 176,
    alignSelf: "center",
    padding: "4px 15px",
    borderRadius: 17,
    lineHeight: "normal",
    fontSize: 13
  },
  noFormsMessage: {
    fontSize: 14,
    color: theme.palette.primary.dark
  },

  tabsRoot: {
    display: "flex",
    flex: 1,
    flexDirection: "column",
    width: "100%"
  },
  tabsContent: {
    display: "flex",
    flex: 1,
    flexDirection: "column"
  },
  tabsViews: {
    display: "flex",
    flex: 1,
    flexDirection: "column",
    "& .react-swipeable-view-container": {
      display: "flex",
      flex: 1,
      flexDirection: "column"
    }
  },
  tab: {
    height: 60,
    whiteSpace: "nowrap",
    minWidth: 174,
    padding: "0 24px",
    [theme.breakpoints.down(Breakpoints.SM_600)]: {
      height: 48,
      fontSize: 14
    }
  },
  tabsFlexContainer: {
    "&:after": {
      content: "''",
      display: "block",
      height: 1,
      width: "100%",
      left: 0,
      right: 0,
      bottom: 0,
      zIndex: 0,
      position: "absolute",
      backgroundColor: theme.palette.divider
    }
  },
  tabsSlide: {
    "& > div": {
      flex: 1,
      height: "100%",
      alignItems: "center",
      justifyContent: "center"
    }
  },
  tabSelected: {
    "&$tab": {
      color: theme.palette.secondary.dark
    }
  },
  tabIndicator: {
    backgroundColor: theme.palette.secondary.dark
  },
  stallApplicationFormStatus: {
    color: "#1a1a1a",
    display: "flex",
    gap: 8,
    alignItems: "center"
  },
  stallApplicationFormStatusNotApplied: {
    color: theme.palette.primary.dark
  },
  stallApplicationFormStatusApplied: {
    "&:before": {
      content: "''",
      display: "block",
      width: 10,
      height: 10,
      borderRadius: 5,
      backgroundColor: theme.palette.secondary.dark
    }
  },
  stallApplicationFormStatusInProgress: {
    "&:before": {
      content: "''",
      display: "block",
      width: 10,
      height: 10,
      borderRadius: 5,
      backgroundColor: "#f47844"
    }
  },
  stallApplicationFormStatusComplete: {},
  stallApplicationFormStatusCancelled: {},
  emptyApplications: {
    display: "flex",
    height: "100%"
  },
  myApplications: {
    padding: "24px 0",
    justifyContent: "center",
    alignItems: "center",
    [theme.breakpoints.down(Breakpoints.SM_600)]: {
      padding: "16px 0"
    }
  },
  noApplicationsMessage: {
    fontSize: 14,
    color: theme.palette.primary.dark,
    display: "flex",
    flexDirection: "column"
  },
  viewAllFormsButton: {
    color: theme.palette.primary.dark,
    fontSize: 13,
    padding: "11px 22px",
    marginTop: 16
  }
}));
