import React, { useEffect, useState } from "react";
import clsx from "clsx";
import { v4 } from "uuid";
import format from "date-fns/format";
import formatISO from "date-fns/formatISO";
import parseISO from "date-fns/parseISO";

import { racehorse360 } from "@tsg/1st-grpc-web";

import Button from "@material-ui/core/Button";
import FormControl from "@material-ui/core/FormControl";
import IconButton from "@material-ui/core/IconButton";
import InputLabel from "@material-ui/core/InputLabel";
import TextField from "@material-ui/core/TextField";
import CancelIcon from "@material-ui/icons/Cancel";
import CloseIcon from "@material-ui/icons/Close";
import EditIcon from "@material-ui/icons/Edit";
import Paper from "@material-ui/core/Paper";
import Dialog from "@material-ui/core/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import InputAdornment from "@material-ui/core/InputAdornment";
import SearchIcon from "@material-ui/icons/Search";
import AddCircleOutlineIcon from "@material-ui/icons/AddCircleOutline";
import ReorderIcon from "@material-ui/icons/Reorder";
import InvalidIcon from "@material-ui/icons/Warning";

import CalendarAdd from "components/Icons/CalendarAdd";
import Loader from "components/Loader";
import { useLoggedInUser } from "components/LoggedInUserProvider";
import { useRacehorse360Api } from "hooks/api";
import { useDebouncedNameValue } from "hooks/useSearch";
import { SortOrderExtended, SortOrder } from "interfaces/SortOrder";
import DatePopup from "./DatePopup";
import { STABLE_ENTRIES_ERROR } from "../validationSchema";
import { STABLE_ENTRIES_INPUT } from "../constants";
import useStyles from "./styles";

interface Props {
  stallApplication: racehorse360.IStallApplication;
  isStallApplicationFetching: boolean;
  onChange: (stableEntries: racehorse360.IStableEntry[]) => void;
  pickedStables?: racehorse360.IStableEntry[];
  hasEntriesValidationError: boolean;
}

type StableEntriesMap = Record<string, racehorse360.IStableEntry>;

const mapStablingEntries = (
  stablingEntries: racehorse360.IStableEntry[] = []
): StableEntriesMap => {
  return (
    stablingEntries.reduce((acc, cur) => {
      const horseId = cur.horseId || v4();
      return {
        ...acc,
        [horseId]: { ...cur, horseId }
      } as StableEntriesMap;
    }, {} as StableEntriesMap) || {}
  );
};

const StablingEntries = (props: Props) => {
  const {
    stallApplication,
    isStallApplicationFetching,
    onChange,
    pickedStables = [],
    hasEntriesValidationError
  } = props;
  const classes = useStyles();
  const { currentUser } = useLoggedInUser();
  const [stateStablingEntries, setStateStablingEntries] =
    useState<StableEntriesMap>(
      mapStablingEntries(pickedStables || stallApplication?.stableEntries)
    );
  const [searchQuery, setSearchQuery] = useState<string>("");
  const [horseName, setHorseName] = useState<string>("");
  const [horseRegistrationNumber, setHorseRegistrationNumber] =
    useState<string>("");
  const [showResults, setShowResults] = useState<boolean>(false);
  const [isDatePopupOpen, setIsDatePopupOpen] = useState<boolean>(false);
  const [isCollapseMode, setIsCollapseMode] = useState<boolean>(false);
  const [activeStablingEntry, setActiveStablingEntry] =
    useState<racehorse360.IStableEntry>();
  const [isOpenEntryModal, setIsOpenEntryModal] = useState<boolean>(false);
  const debouncedSearchQuery = useDebouncedNameValue(searchQuery, {
    timeout: 500,
    startFrom: 2
  });
  const { useListHorses } = useRacehorse360Api();
  const stablingEntries = Object.values(stateStablingEntries).filter(Boolean);

  const { data: dataByName, isLoading: isDataByNameLoading } = useListHorses(
    {
      query: {
        name: { similarOrContains: debouncedSearchQuery },
        trainerIds: currentUser.trainerIds
      },
      pagingOptions: {
        maxResults: 99
      },
      getOptions: {
        select: ["id", "name", "registrationNumber", "trainer.fullName"],
        orderBy: [`name ${SortOrderExtended.ASC_SIMILARITY}`]
      }
    },
    {
      enabled: debouncedSearchQuery?.length > 2,
      initialData: {
        horses: []
      }
    }
  );

  const { data: dataByRegNumber, isLoading: isDataByRegNumberLoading } =
    useListHorses(
      {
        query: {
          registrationNumber: { contains: debouncedSearchQuery },
          trainerIds: currentUser.trainerIds
        },
        pagingOptions: {
          maxResults: 99
        },
        getOptions: {
          select: ["id", "name", "registrationNumber", "ownerName"],
          orderBy: [`registrationNumber ${SortOrder.ASC}`]
        }
      },
      {
        enabled: debouncedSearchQuery?.length > 2,
        initialData: {
          horses: []
        }
      }
    );

  const horses = [
    ...new Set([...dataByName?.horses, ...dataByRegNumber?.horses])
  ];

  const isSearching = isDataByNameLoading || isDataByRegNumberLoading;

  const toggleDatePopupOpen = () => {
    setIsDatePopupOpen(!isDatePopupOpen);
  };

  const handleSearchQueryChange = event => {
    const value = event.target.value;
    setSearchQuery(value);
  };

  const handleSearchFocus = () => {
    setShowResults(true);
  };

  const handleHorseClick = (horse: racehorse360.IHorse) => () => {
    const newValue = {
      ...stateStablingEntries,
      [horse.id]: {
        stallApplicationId: stallApplication?.id,
        horseId: horse.id,
        horse
      }
    };
    setStateStablingEntries(newValue);
    selectAndCloseSearch(newValue);
  };

  const handleUnselectClick = (horseId: string) => e => {
    e.stopPropagation();
    const newStateStablingEntries = {
      ...stateStablingEntries,
      [horseId]: undefined
    };
    setStateStablingEntries(newStateStablingEntries);
    onChange(Object.values(newStateStablingEntries).filter(Boolean));
  };

  const handleDeleteEntryClick = (horseId: string) => () => {
    const newStateStablingEntries = {
      ...stateStablingEntries,
      [horseId]: undefined
    };
    setStateStablingEntries(newStateStablingEntries);
    onChange(Object.values(newStateStablingEntries).filter(Boolean));
  };

  const handleAddCustomHorseClick = () => {
    setHorseName(searchQuery.replace(/[^a-z]/gi, "").substring(0, 18));
    setHorseRegistrationNumber("");
    setIsOpenEntryModal(true);
  };

  const handleAddEntryDialogClose = () => {
    setIsOpenEntryModal(false);
  };

  const handleNameChange = event => {
    const value = event.target.value.replace(/[^a-z]/gi, "");
    if (/^[A-Za-z]*$/.test(value)) {
      setHorseName(value);
    }
  };

  const handleIDChange = event => {
    const value = event.target.value;
    if (/^\d*$/.test(value)) {
      setHorseRegistrationNumber(value);
    }
  };

  const handleAddHorseClick = () => {
    const horse = {
      id: v4(),
      name: horseName,
      registrationNumber: horseRegistrationNumber
    };
    const newStateStablingEntries = {
      ...stateStablingEntries,
      [horse.id]: {
        stallApplicationId: stallApplication?.id,
        horseId: horse.id,
        customHorseName: horse.name,
        customHorseRegistrationNumber: horse.registrationNumber
      }
    };
    setStateStablingEntries(newStateStablingEntries);
    setIsOpenEntryModal(false);
    setSearchQuery("");
    setShowResults(false);
    onChange(Object.values(newStateStablingEntries).filter(Boolean));
  };

  const handleAddHorseCancelClick = () => {
    setIsOpenEntryModal(false);
  };

  const handleReadyDateChange = (
    stablingEntry: racehorse360.IStableEntry,
    date: Date
  ) => {
    const newStateStableingEntries = {
      ...stateStablingEntries,
      [stablingEntry.horseId]: {
        ...stablingEntry,
        raceReadyDate: date
          ? formatISO(date, {
              representation: "date"
            })
          : undefined
      }
    };
    setStateStablingEntries(newStateStableingEntries);
    onChange(Object.values(newStateStableingEntries).filter(Boolean));
  };

  const handleSearchClose = () => {
    setSearchQuery("");
    setShowResults(false);
  };

  const selectAndCloseSearch = (newValue: StableEntriesMap) => {
    onChange(Object.values(newValue).filter(Boolean));
    handleSearchClose();
  };

  const handleDateClick = entry => () => {
    setActiveStablingEntry(entry);
    toggleDatePopupOpen();
  };

  const handleCollapseModeToggle = () => {
    setIsCollapseMode(!isCollapseMode);
  };

  useEffect(() => {
    if (!isStallApplicationFetching) {
      setStateStablingEntries(
        mapStablingEntries(pickedStables || stallApplication?.stableEntries)
      );
    }
  }, [isStallApplicationFetching]);

  return (
    <div className={classes.root}>
      <div className={classes.header}>
        <div className={classes.titleWrapper}>
          <span className={classes.title}>Stabling Entries</span>
          <span className={classes.count}>
            My Entries: {stablingEntries.length}
          </span>
        </div>
        <div className={classes.search}>
          <TextField
            className={classes.searchInputBase}
            placeholder={"Horse Name or Reg. #"}
            name={STABLE_ENTRIES_INPUT}
            value={searchQuery}
            onChange={handleSearchQueryChange}
            InputProps={{
              classes: {
                input: classes.searchInput
              },
              startAdornment: (
                <InputAdornment position="start">
                  <SearchIcon className={classes.searchIcon} />
                </InputAdornment>
              ),
              endAdornment: showResults ? (
                <IconButton size="small" onClick={handleSearchClose}>
                  <CloseIcon className={classes.clearSearchIcon} />
                </IconButton>
              ) : null
            }}
            onFocus={handleSearchFocus}
            variant="outlined"
            error={hasEntriesValidationError}
          />
          <Button
            className={clsx(classes.searchHamburgerButton, {
              [classes.searchHamburgerButtonActive]: isCollapseMode
            })}
            onClick={handleCollapseModeToggle}
          >
            <ReorderIcon className={classes.searchHamburgerIcon} />
          </Button>
        </div>
      </div>
      <div
        className={clsx(
          classes.body,
          showResults ? classes.searchResults : classes.entries
        )}
      >
        <DatePopup
          stablingEntry={activeStablingEntry}
          isOpen={isDatePopupOpen}
          onClose={toggleDatePopupOpen}
          onChange={handleReadyDateChange}
        />
        <Dialog
          classes={{
            paper: classes.addEntryDialogPaper
          }}
          open={isOpenEntryModal}
          onClose={handleAddEntryDialogClose}
        >
          <DialogTitle className={classes.addEntryDialogTitle}>
            Add Unlisted Horse
            <IconButton
              color="primary"
              onClick={handleAddEntryDialogClose}
              size={"small"}
              classes={{
                root: classes.addEntryDialogCloseButton
              }}
            >
              <CloseIcon className={classes.addEntryDialogCloseButtonIcon} />
            </IconButton>
          </DialogTitle>
          <DialogContent className={classes.addEntryDialogContent}>
            <span>
              If you were unable to find your horse, fill out the information
              below to create your stable entry.
            </span>
            <FormControl
              className={classes.addEntryDialogFormControl}
              variant={"outlined"}
            >
              <InputLabel
                className={classes.addEntryDialogInputLabel}
                disableAnimation
              >
                Horse Name
              </InputLabel>
              <TextField
                InputProps={{
                  classes: {
                    input: classes.addEntryDialogInput
                  }
                }}
                autoFocus
                inputProps={{ maxLength: 18 }}
                value={horseName}
                onChange={handleNameChange}
                variant="outlined"
              />
            </FormControl>
            <FormControl
              className={classes.addEntryDialogFormControl}
              variant={"outlined"}
            >
              <InputLabel
                className={classes.addEntryDialogInputLabel}
                disableAnimation
              >
                Horse Registration Number
              </InputLabel>
              <TextField
                InputProps={{
                  classes: {
                    input: classes.addEntryDialogInput
                  }
                }}
                inputProps={{ maxLength: 12 }}
                placeholder={"e.g. 12345678"}
                value={horseRegistrationNumber}
                onChange={handleIDChange}
                variant="outlined"
              />
            </FormControl>
            <div className={classes.addEntryDialogButtons}>
              <Button onClick={handleAddHorseCancelClick}>CANCEL</Button>
              <Button
                className={classes.addEntryDialogButtonsAdd}
                disabled={!horseName || !horseRegistrationNumber}
                onClick={handleAddHorseClick}
              >
                ADD HORSE
              </Button>
            </div>
          </DialogContent>
        </Dialog>

        {(isSearching || isStallApplicationFetching) && <Loader overlay />}
        <div className={classes.resultsWrapper}>
          {hasEntriesValidationError && (
            <div
              className={clsx(classes.validationError, {
                withResults: showResults
              })}
            >
              <InvalidIcon className={classes.validationIcon} />
              {STABLE_ENTRIES_ERROR}
            </div>
          )}
          {showResults && searchQuery ? (
            <>
              {horses.map(horse => (
                <Button
                  key={horse.id}
                  className={clsx(classes.searchResultsEntry, {
                    [classes.searchResultsEntrySelected]: Boolean(
                      stateStablingEntries[horse.id]
                    )
                  })}
                  onClick={handleHorseClick(horse)}
                >
                  <div className={classes.horseInfoWrapper}>
                    <div className={classes.horseName}>{horse.name}</div>
                    <div className={classes.horseNumber}>
                      {horse.registrationNumber}
                    </div>
                    <div className={classes.horseTrainerName}>
                      {horse.ownerName || "-"}
                    </div>
                  </div>
                  {Boolean(stateStablingEntries[horse.id]) && (
                    <IconButton
                      component={"div"}
                      className={classes.entryRemoveButton}
                      onClick={handleUnselectClick(horse.id)}
                      size={"small"}
                    >
                      <CancelIcon />
                    </IconButton>
                  )}
                </Button>
              ))}
              <Button
                className={clsx(
                  classes.searchResultsEntry,
                  classes.searchResultsEntryAddButton
                )}
                onClick={handleAddCustomHorseClick}
              >
                <AddCircleOutlineIcon
                  className={classes.searchResultsEntryButtonIcon}
                />
                Add Unlisted Horse
              </Button>
              {!isSearching &&
                debouncedSearchQuery.length > 2 &&
                !horses.length && (
                  <div className={classes.searchResultsNoResults}>
                    No Results for "{debouncedSearchQuery}"
                  </div>
                )}
            </>
          ) : (
            stablingEntries.map(entry => (
              <Paper key={entry.horseId} className={classes.entry}>
                <div className={classes.entryBody}>
                  <ul className={classes.entryBodyList}>
                    <li className={classes.entryTitle}>
                      {entry.horse?.name || entry.customHorseName}
                    </li>
                    {!isCollapseMode && (
                      <>
                        <li className={classes.entrySubtitle}>
                          {entry.horse?.registrationNumber ||
                            entry.customHorseRegistrationNumber}
                        </li>
                        <li className={classes.entrySubtitle}>
                          {entry.horse?.ownerName || "-"}
                        </li>
                      </>
                    )}
                    <li>
                      <Button
                        className={clsx(classes.entryAddDateButton, {
                          [classes.entryEditDateButton]: entry.raceReadyDate
                        })}
                        startIcon={
                          entry.raceReadyDate ? (
                            <EditIcon
                              className={classes.entryAddDateButtonIcon}
                            />
                          ) : (
                            <CalendarAdd
                              className={classes.entryAddDateButtonIcon}
                            />
                          )
                        }
                        onClick={handleDateClick(entry)}
                      >
                        {entry.raceReadyDate
                          ? format(parseISO(entry.raceReadyDate), "MM/dd/yy")
                          : "Add race ready date"}
                      </Button>
                    </li>
                  </ul>
                  <IconButton
                    className={classes.entryRemoveButton}
                    onClick={handleDeleteEntryClick(entry.horseId)}
                    size={"small"}
                  >
                    <CancelIcon />
                  </IconButton>
                </div>
              </Paper>
            ))
          )}
        </div>
      </div>
    </div>
  );
};

export default StablingEntries;
