import React, { useState } from "react";
import { useDispatch } from "react-redux";

import { racehorse360 } from "@tsg/1st-grpc-web";

import AppPageTable from "components/AppPageTable";
import AppPageTableContent from "components/AppPageTableContent";
import AppPageTableHeader from "components/AppPageTableHeader";
import TableSortLabel from "components/TableSortLabel";
import { SortOrder } from "interfaces/SortOrder";
import { setRacingSecretaryStallApplicationState } from "store/actions/racingSecretaryStallApplication";
import StallApplication from "../StallApplication";
import { useRSStallApplicationReduxStore } from "../useRSStallApplicationReduxStore";
import { EColumns, getStallApplicantsSortFunction, IColumn } from "../helpers";

import useStyles from "./styles";

interface IProps {
  tableColumns: IColumn[];
  stallApplications: racehorse360.IStallApplication[];
  isListStallApplicationsFetching: boolean;
}

const StallApplicantsTable = (props: IProps) => {
  const { tableColumns, stallApplications, isListStallApplicationsFetching } =
    props;
  const classes = useStyles();
  const dispatch = useDispatch();

  const [orderBy, setOrderBy] = useState(EColumns.TRAINER_NAME);
  const [order, setOrder] = useState(SortOrder.ASC);
  const [currentStallApplication, setCurrentStallApplication] =
    useState<racehorse360.IStallApplication>(null);

  const { isRSStallApplicationDetailsOpen } = useRSStallApplicationReduxStore();

  const handleSortClick = (newOrderBy, newOrder) => {
    setOrder(newOrder);
    setOrderBy(newOrderBy);
  };

  const handleOpenStallApplication =
    (stallApplication: racehorse360.IStallApplication) => () => {
      setCurrentStallApplication(stallApplication);
      dispatch(setRacingSecretaryStallApplicationState(true));
    };

  const handleCloseStallApplication = () => {
    dispatch(setRacingSecretaryStallApplicationState(false));
  };

  const sortedStallApplications = stallApplications.sort(
    getStallApplicantsSortFunction(order, orderBy)
  );

  return (
    <div className={classes.tableWrapper}>
      <AppPageTable className={classes.appPageTable}>
        <AppPageTableHeader
          className={classes.appPageTableHeader}
          aria-label="stall-application-form-applicants-table"
          aria-labelledby="tableTitle"
        >
          {tableColumns.map(headCell => (
            <div className={classes.tableHeaderCell} key={headCell.id}>
              <TableSortLabel
                name={headCell.id}
                order={order}
                orderBy={orderBy}
                onSortClick={handleSortClick}
                hideSortIcon={headCell.isNonSortable}
                disabled={headCell.isNonSortable}
                firstClickSort={SortOrder.ASC}
              >
                {headCell.label}
              </TableSortLabel>
            </div>
          ))}
        </AppPageTableHeader>
        <AppPageTableContent>
          {sortedStallApplications.map(application => (
            <div
              key={application.id}
              className={classes.tableBodyRow}
              role={"button"}
              tabIndex={0}
              onClick={handleOpenStallApplication(application)}
            >
              {tableColumns.map(column => (
                <div key={column.id} className={classes.tableBodyCell}>
                  {column.render(application)}
                </div>
              ))}
            </div>
          ))}

          {isRSStallApplicationDetailsOpen && (
            <StallApplication
              data={currentStallApplication}
              onClose={handleCloseStallApplication}
              isListStallApplicationsFetching={isListStallApplicationsFetching}
            />
          )}
        </AppPageTableContent>
      </AppPageTable>
    </div>
  );
};

export default StallApplicantsTable;
