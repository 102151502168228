import React, { useEffect, useRef, useState } from "react";
import { useDispatch } from "react-redux";
import { useQueryClient } from "react-query";
import clsx from "clsx";

import MobileTab from "@material/react-tab";
import MobileTabBar from "@material/react-tab-bar";
import "@material/react-tab-bar/dist/tab-bar.css";
import "@material/react-tab-scroller/dist/tab-scroller.css";
import "@material/react-tab/dist/tab.css";
import "@material/react-tab-indicator/dist/tab-indicator.css";

import Grid from "@material-ui/core/Grid";
import Paper from "@material-ui/core/Paper";
import { Theme } from "@material-ui/core/styles";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import { PopoverProps } from "@material-ui/core/Popover";

import AppPage from "components/AppPage";
import AppPageHeader from "components/AppPageHeader";
import AppPageContent from "components/AppPageContent";
import { useLoggedInUser } from "components/LoggedInUserProvider";
import Loader from "components/Loader";
import Breakpoints from "common/breakpoints";
import { SortOrder } from "interfaces/SortOrder";
import CreateOrderButton from "pages/WorkOrdersPage/CreateOrderButton";
import MaintenanceWorkOrderList from "./WorkOrderList";
import MaintenanceWorkOrdersDetail from "./MaintenanceWorkOrdersDetail";
import WorkOrderPopover from "./WorkOrderPopover";
import ActionsButtonsPopover from "./ActionsButtonsPopover";
import WorkOrdersFiltersDialog from "./WorkOrdersFiltersDialog";

import { racehorse360 } from "@tsg/1st-grpc-web";

import { useRacehorse360Api } from "hooks/api";
import { useInfiniteScroll } from "hooks/useInfiniteScroll";
import {
  clearOpenFilters,
  clearAssignedFilters,
  clearCompleteFilters,
  clearClosedFilters,
  clearCanceledFilters
} from "store/actions/maintenanceWorkOrdersPage";
import FilterBar from "components/Filters/FilterBar";
import { getCheckboxesFilterValue } from "components/Filters/helper";
import { parseWorkOrderStatus } from "utils/enum-parser";
import { MAINTENANCE } from "utils/token";
import {
  getTabsStatuses,
  getFilterDateOnValue,
  getFilterUsersIdsValue,
  getTimeFilterValues,
  ETimeFilterState,
  EPopoverType
} from "./helper";
import { useLocalStateFilters } from "./useLocalStateFilters";
import {
  paginationRowHeight,
  tableHeadRowHeight,
  tableRowHeight
} from "./WorkOrderList/styles";
import { useReduxStateFilters } from "./useReduxStateFilters";
import WorkOrderOverlayForm from "../WorkOrderOverlayForm";
import { requiredWorkOrderOptions } from "../helper";
import { checkSortingType } from "utils/sort-utils";
import useStyles from "./styles";

export type MaintenanceWorkOrdersOrderBy = keyof racehorse360.IWorkOrder;

export interface IAnchorCoordinates {
  left?: number;
  top?: number;
}

interface IProps {
  isNewWorkOrderOpen: boolean;
  onCloseNewOrderPopup: () => void;
  onOpenNewOrderPopup: () => void;
}

const getDefaultSortParam = (activeTab): MaintenanceWorkOrdersOrderBy => {
  switch (activeTab) {
    case racehorse360.WorkOrderStatus.WORK_ORDER_STATUS_ASSIGNED:
      return "assignedOn";
    case racehorse360.WorkOrderStatus.WORK_ORDER_STATUS_COMPLETE:
      return "completedOn";
    case racehorse360.WorkOrderStatus.WORK_ORDER_STATUS_CLOSED:
      return "closedOn";
    case racehorse360.WorkOrderStatus.WORK_ORDER_STATUS_CANCELED:
      return "canceledOn";
    default:
      return "createdOn";
  }
};

const getStatusByIndex = (
  index: number,
  unavailableTabs: racehorse360.WorkOrderStatus[]
): racehorse360.WorkOrderStatus => {
  return Object.values(racehorse360.WorkOrderStatus).filter(
    status => !unavailableTabs.includes(status as racehorse360.WorkOrderStatus)
  )[index] as racehorse360.WorkOrderStatus;
};

const getTabIndexByStatus = (
  status: racehorse360.WorkOrderStatus,
  unavailableTabs: racehorse360.WorkOrderStatus[]
): number => {
  return Object.values(racehorse360.WorkOrderStatus)
    .filter(
      status =>
        !unavailableTabs.includes(status as racehorse360.WorkOrderStatus)
    )
    .indexOf(status);
};

const countPageSize = (
  tableContainerRef: React.RefObject<HTMLDivElement>
): number => {
  if (tableContainerRef?.current) {
    const tableContainerHeight = tableContainerRef?.current?.clientHeight;
    const tableContentHeight =
      tableContainerHeight - tableHeadRowHeight - paginationRowHeight;

    return Math.floor(tableContentHeight / (tableRowHeight + 1)); //+1 for borderBottom
  }
  return 0;
};
// TODO: need to reduce component complexity
// eslint-disable-next-line complexity
const MaintenanceWorkOrdersPage = (props: IProps) => {
  const { isNewWorkOrderOpen, onCloseNewOrderPopup, onOpenNewOrderPopup } =
    props;
  const classes = useStyles();
  const queryClient = useQueryClient();
  const dispatch = useDispatch();
  const { currentUser } = useLoggedInUser();
  const [unavailableTabs] = useState<racehorse360.WorkOrderStatus[]>(
    currentUser.isMaintenanceStaff
      ? [
          racehorse360.WorkOrderStatus.WORK_ORDER_STATUS_INVALID,
          racehorse360.WorkOrderStatus.WORK_ORDER_STATUS_OPEN
        ]
      : [racehorse360.WorkOrderStatus.WORK_ORDER_STATUS_INVALID]
  );
  const mobileAnchorRef = useRef<HTMLDivElement>(null);
  const tableBodyRef = useRef<HTMLDivElement>(null);
  const [selectedWorkOrderId, setSelectedWorkOrderId] = useState<string>();
  const [isOpenFilters, setIsOpenFilters] = useState<boolean>(false);
  const [isDetailsOpen, setIsDetailsOpen] = useState<boolean>(false);
  const [activeTab, setActiveTab] = useState<racehorse360.WorkOrderStatus>(
    currentUser.isMaintenanceStaff
      ? racehorse360.WorkOrderStatus.WORK_ORDER_STATUS_ASSIGNED
      : racehorse360.WorkOrderStatus.WORK_ORDER_STATUS_OPEN
  );
  const [activeTabIndex, setActiveTabIndex] = useState<number>(
    getTabIndexByStatus(
      currentUser.isMaintenanceStaff
        ? racehorse360.WorkOrderStatus.WORK_ORDER_STATUS_ASSIGNED
        : racehorse360.WorkOrderStatus.WORK_ORDER_STATUS_OPEN,
      unavailableTabs
    )
  );
  const [order, setOrder] = useState<SortOrder>(SortOrder.ASC);
  const [orderBy, setMaintenanceWorkOrdersOrderBy] =
    useState<MaintenanceWorkOrdersOrderBy>("createdOn");
  const [activePage, setActivePage] = useState<number>(1);
  const [popoverType, setPopoverType] = useState<EPopoverType>();
  const [popoverAnchor, setPopoverAnchor] = useState<Element>();
  const [popoverWorkOrder, setPopoverWorkOrder] =
    useState<racehorse360.IWorkOrder>();
  const [selectedWorkOrder, setSelectedWorkOrder] =
    useState<racehorse360.IWorkOrder | null>(null);
  const [isOpenActionsMenu, setIsOpenActionsMenu] = useState<boolean>(false);
  const [anchorCoordinates, setAnchorCoordinates] =
    useState<IAnchorCoordinates>();

  const isSupervisor = currentUser.isMaintenanceSupervisor;
  const tableContainerRef = useRef<HTMLDivElement>(null);
  const [pageSize, setPageSize] = useState(countPageSize(tableContainerRef));
  const [hasSavedFilters, setHasSavedFilters] = useState(false);
  const {
    openFiltersState: { openDate, openOrderTypes, openReporters, timeOpen },
    assignedFiltersState: {
      assignedDate,
      assignedTo,
      assignedOrderTypes,
      assignedReporters,
      timeAssigned
    },
    completeFiltersState: {
      completeOrderTypes,
      completeAssignedTo,
      completeAssignedDate,
      completedDate,
      completeTimeAssigned
    },
    closedFiltersState: {
      closedOrderTypes,
      closedAssignedTo,
      closedDate,
      closedReporters,
      closedTimeOpen,
      closedTimeAssigned
    },
    canceledFiltersState: {
      canceledOrderTypes,
      canceledDate,
      canceledAssignedTo,
      canceledOpenDate,
      canceledReporters,
      canceledTimeAssigned
    }
  } = useLocalStateFilters();
  const {
    hasSavedOpenFilters,
    hasSavedAssignedFilters,
    hasSavedCompleteFilters,
    hasSavedClosedFilters,
    hasSavedCanceledFilters
  } = useReduxStateFilters();
  const {
    isOpenTab,
    isAssignedTab,
    isCompleteTab,
    isClosedTab,
    isCanceledTab
  } = getTabsStatuses(activeTab);

  const matchesDownXS420 = useMediaQuery((theme: Theme) =>
    theme.breakpoints.down(Breakpoints.XS_420)
  );

  const popoverProps: Partial<PopoverProps> = matchesDownXS420
    ? {
        anchorOrigin: {
          vertical: "center",
          horizontal: "center"
        },
        transformOrigin: {
          vertical: "center",
          horizontal: "center"
        }
      }
    : undefined;
  const userRole = currentUser?.roles.find(role =>
    [MAINTENANCE.SUPERVISOR, MAINTENANCE.STAFF].includes(role.id as MAINTENANCE)
  );
  const userFacilityId = userRole?.facilityId;

  const getAssignedUserIds = (filters: string[]): string[] => {
    return isSupervisor ? filters : [currentUser.rh360Id];
  };

  const setWorkOrdersFilters = (): Partial<racehorse360.IWorkOrderFilter> => {
    let filters = {};

    if (isOpenTab) {
      filters = {
        createdOn: getFilterDateOnValue(openDate),
        workOrderTypeIds: getCheckboxesFilterValue(openOrderTypes),
        createdByIds: getFilterUsersIdsValue(openReporters),
        ...getTimeFilterValues(timeOpen, ETimeFilterState.OPEN)
      };
    } else if (isAssignedTab) {
      filters = {
        assignedOn: getFilterDateOnValue(assignedDate),
        assignedUserIds: getAssignedUserIds(
          getCheckboxesFilterValue(assignedTo)
        ),
        workOrderTypeIds: getCheckboxesFilterValue(assignedOrderTypes),
        createdByIds: getFilterUsersIdsValue(assignedReporters),
        ...getTimeFilterValues(timeAssigned, ETimeFilterState.ASSIGNED)
      };
    } else if (isCompleteTab) {
      filters = {
        assignedOn: getFilterDateOnValue(completeAssignedDate),
        completedOn: getFilterDateOnValue(completedDate),
        assignedUserIds: getAssignedUserIds(
          getCheckboxesFilterValue(completeAssignedTo)
        ),
        workOrderTypeIds: getCheckboxesFilterValue(completeOrderTypes),
        ...getTimeFilterValues(completeTimeAssigned, ETimeFilterState.ASSIGNED)
      };
    } else if (isClosedTab) {
      filters = {
        createdByIds: getFilterUsersIdsValue(closedReporters),
        assignedUserIds: getAssignedUserIds(
          getCheckboxesFilterValue(closedAssignedTo)
        ),
        closedOn: getFilterDateOnValue(closedDate),
        workOrderTypeIds: getCheckboxesFilterValue(closedOrderTypes),
        ...getTimeFilterValues(closedTimeAssigned, ETimeFilterState.ASSIGNED),
        ...getTimeFilterValues(closedTimeOpen, ETimeFilterState.OPEN)
      };
    } else if (isCanceledTab) {
      filters = {
        createdByIds: getFilterUsersIdsValue(canceledReporters),
        createdOn: getFilterDateOnValue(canceledOpenDate),
        assignedUserIds: getAssignedUserIds(
          getCheckboxesFilterValue(canceledAssignedTo)
        ),
        canceledOn: getFilterDateOnValue(canceledDate),
        workOrderTypeIds: getCheckboxesFilterValue(canceledOrderTypes),
        ...getTimeFilterValues(canceledTimeAssigned, ETimeFilterState.ASSIGNED)
      };
    }

    return filters;
  };

  const {
    useListWorkOrders,
    useGetFacility,
    usePullWorkOrderSummary,
    useInfiniteListWorkOrders
  } = useRacehorse360Api();

  const { data: userFacility } = useGetFacility(
    {
      id: userFacilityId,
      getOptions: {
        select: ["name"]
      }
    },
    {
      enabled: Boolean(userFacilityId)
    }
  );

  const { data: workOrdersStatusesCount, refetch: refetchSummary } =
    usePullWorkOrderSummary(
      `${currentUser.rh360Id}-work-orders-counts`,
      { facilityId: userFacilityId },
      {
        enabled: Boolean(userFacilityId),
        onError: error =>
          console.error(
            error?.message ||
              "There was an error attempting to request pull of work orders count."
          )
      }
    );

  const getSortParams = (): string[] => {
    const { isAsc } = checkSortingType(order);
    const reverseOrder = isAsc ? SortOrder.DESC : SortOrder.ASC;

    switch (orderBy) {
      case "minutesOpen":
        return [`${orderBy} ${reverseOrder}`];
      case "minutesAssigned":
        return [`${orderBy} ${reverseOrder}`];
      default:
        return orderBy.split(",").map(ob => `${ob} ${order}`);
    }
  };

  const listWorkOrderInfiniteQueryParams = [
    {
      query: {
        facilityId: { value: userFacilityId },
        workOrderStatuses: [activeTab],
        ...setWorkOrdersFilters()
      },
      pagingOptions: {
        maxResults: pageSize
      },
      getOptions: {
        ...requiredWorkOrderOptions,
        orderBy: getSortParams()
      }
    },
    {
      keepPreviousData: true,
      enabled: Boolean(userFacilityId && matchesDownXS420 && pageSize),
      onError: error =>
        console.error(
          error?.message ||
            "There was an error attempting to request list of work orders."
        )
    }
  ];

  const {
    isLoading: isInfiniteLoading,
    fetchNextPage,
    isFetchingNextPage,
    hasNextPage,
    refetch: infiniteRefetch,
    data: infiniteData
  } = useInfiniteListWorkOrders(
    `${currentUser.rh360Id}-infinite-work-orders`,
    { ...listWorkOrderInfiniteQueryParams[0] },
    { ...listWorkOrderInfiniteQueryParams[1] }
  );

  useInfiniteScroll(
    tableBodyRef,
    fetchNextPage,
    hasNextPage,
    isFetchingNextPage,
    true,
    true
  );

  const {
    isLoading: isWorkOrdersLoading,
    isFetching,
    data,
    refetch
  } = useListWorkOrders(
    `${currentUser.rh360Id}-work-orders`,
    {
      ...listWorkOrderInfiniteQueryParams[0],
      query: {
        ...listWorkOrderInfiniteQueryParams[0].query
      },
      pagingOptions: {
        ...listWorkOrderInfiniteQueryParams[0].pagingOptions,
        offset: (activePage - 1) * pageSize,
        includeSummary: true
      }
    },
    {
      ...listWorkOrderInfiniteQueryParams[1],
      enabled: Boolean(userFacilityId && !matchesDownXS420 && pageSize),
      initialData: {
        workOrders: [],
        pagingInfo: {}
      },
      onError: error => console.error(error)
    }
  );

  const workOrders = matchesDownXS420
    ? infiniteData?.pages.flat() || []
    : data.workOrders;

  const totalCountWO = data.pagingInfo.totalResults || 0;

  const refresh = () => {
    Promise.all([
      queryClient.invalidateQueries(`${currentUser.rh360Id}-work-orders`),
      queryClient.invalidateQueries(
        `${currentUser.rh360Id}-infinite-work-orders`
      ),
      queryClient.invalidateQueries(
        `${currentUser.rh360Id}-work-orders-counts`
      ),
      queryClient.invalidateQueries("pullMaintenanceUsers")
    ])
      .then(() => {
        setPopoverType(null);
        setPopoverAnchor(null);
        setPopoverWorkOrder(null);
      })
      .then(() => {
        return Promise.all([refetchSummary(), refetch(), infiniteRefetch()]);
      })
      .catch(error => {
        console.error(error);
      });
  };

  const handleWorkOrderClick = workOrderId => {
    setSelectedWorkOrderId(workOrderId);
  };

  const handleTabChange = (activeIndex: number) => {
    const status = getStatusByIndex(activeIndex, unavailableTabs);
    const { isClosedTab, isCanceledTab } = getTabsStatuses(status);
    let defaultSortOrder: SortOrder = SortOrder.ASC;

    setPageSize(countPageSize(tableContainerRef));
    setActiveTabIndex(activeIndex);
    setActiveTab(status);
    setMaintenanceWorkOrdersOrderBy(getDefaultSortParam(status));
    setActivePage(1);

    if (matchesDownXS420 && tableBodyRef) {
      tableBodyRef.current.scrollTop = 0;
    }

    if (isClosedTab || isCanceledTab) {
      defaultSortOrder = SortOrder.DESC;
    }

    setOrder(defaultSortOrder);
  };

  const handleSortClick = (
    nextMaintenanceWorOrdersOrderBy: MaintenanceWorkOrdersOrderBy,
    nextOrder: SortOrder
  ) => {
    setMaintenanceWorkOrdersOrderBy(nextMaintenanceWorOrdersOrderBy);
    setOrder(nextOrder);
  };

  const handleOpenWorkOrdersDetail = (workOrder: racehorse360.IWorkOrder) => {
    setSelectedWorkOrder(workOrder);
    setIsDetailsOpen(true);
    setIsOpenActionsMenu(false);
  };

  const handleCloseWorkOrdersDetail = () => {
    setSelectedWorkOrder(null);
    setIsDetailsOpen(false);
  };

  const handleActionClick = (
    popoverType: EPopoverType,
    workOrder: racehorse360.IWorkOrder,
    anchor?: Element
  ) => {
    setPopoverAnchor(anchor);
    setPopoverType(popoverType);
    setPopoverWorkOrder(workOrder);
    setIsOpenActionsMenu(false);
  };

  const handlePopoverClose = () => {
    setPopoverType(null);
    setPopoverAnchor(null);
  };

  const handlePopoverConfirm = () => {
    refresh();
  };

  const handleWorkOrderUpdate = (workOrder = null) => {
    refresh();
    setSelectedWorkOrder(workOrder);
  };

  const handlePageChange = pageNumber => {
    const newOffset = (pageNumber - 1) * pageSize;
    if (newOffset > totalCountWO) {
      setActivePage(Math.ceil(totalCountWO / pageSize));
    } else {
      setActivePage(pageNumber);
    }
  };

  const handleOpenActionsMenu = (
    workOrder: racehorse360.IWorkOrder,
    anchor: any
  ) => {
    const { left, top, height, width } = anchor.getBoundingClientRect();
    const leftValue = left + width;
    const topValue = top + height;

    setAnchorCoordinates({ left: leftValue, top: topValue });
    setSelectedWorkOrderId(workOrder.id);
    setIsOpenActionsMenu(true);
  };

  const handleCloseActionsMenu = () => {
    setIsOpenActionsMenu(false);
  };

  const handleOpenFilters = () => {
    setIsOpenFilters(true);
  };

  const handleCloseFilters = () => {
    setIsOpenFilters(false);
  };

  const handleClearFilters = () => {
    isOpenTab && dispatch(clearOpenFilters);
    isAssignedTab && dispatch(clearAssignedFilters);
    isCompleteTab && dispatch(clearCompleteFilters);
    isClosedTab && dispatch(clearClosedFilters);
    isCanceledTab && dispatch(clearCanceledFilters);

    setIsOpenFilters(false);
  };

  const getWorkOrdersCount = (
    status: racehorse360.WorkOrderStatus
  ): number | "" => {
    switch (status) {
      case racehorse360.WorkOrderStatus.WORK_ORDER_STATUS_OPEN:
        return workOrdersStatusesCount?.openCount || 0;
      case racehorse360.WorkOrderStatus.WORK_ORDER_STATUS_ASSIGNED:
        return workOrdersStatusesCount?.assignedCount || 0;
      case racehorse360.WorkOrderStatus.WORK_ORDER_STATUS_COMPLETE:
        return workOrdersStatusesCount?.completeCount || 0;
      case racehorse360.WorkOrderStatus.WORK_ORDER_STATUS_CLOSED:
        return workOrdersStatusesCount?.closedCount || 0;
      case racehorse360.WorkOrderStatus.WORK_ORDER_STATUS_CANCELED:
        return workOrdersStatusesCount?.canceledCount || 0;
      default:
        return "";
    }
  };

  const renderTabsContent = () => {
    return Object.values(racehorse360.WorkOrderStatus)
      .filter(
        status =>
          !unavailableTabs.includes(status as racehorse360.WorkOrderStatus)
      )
      .map((status: racehorse360.WorkOrderStatus) => {
        let count;

        if (
          status === racehorse360.WorkOrderStatus.WORK_ORDER_STATUS_CLOSED ||
          status === racehorse360.WorkOrderStatus.WORK_ORDER_STATUS_CANCELED ||
          matchesDownXS420
        ) {
          count = "";
        } else {
          const workOrdersCount = getWorkOrdersCount(status);
          count = `(${workOrdersCount})`;
        }

        return (
          <MobileTab key={status} data-index={`index-${status}`}>
            <span className="mdc-tab__text-label">
              {`${parseWorkOrderStatus(status)} ${count}`}
            </span>
          </MobileTab>
        );
      });
  };

  const renderHeaderContent = () => {
    const countWorkOrders = getWorkOrdersCount(activeTab);
    const ordersText = countWorkOrders !== 1 ? "Orders" : "Order";

    return (
      <>
        <MobileTabBar
          className={classes.tabBar}
          activeIndex={activeTabIndex}
          handleActiveIndexUpdate={handleTabChange}
        >
          {renderTabsContent()}
        </MobileTabBar>
        <div className={classes.mainHeader}>
          {matchesDownXS420 && (
            <div
              className={clsx(classes.workOrdersInfo, {
                ["hasWorkOrders"]: workOrders.length > 0
              })}
            >
              {`${countWorkOrders} ${parseWorkOrderStatus(
                activeTab
              )} Work ${ordersText}`}
            </div>
          )}
          <div className={classes.buttons}>
            <FilterBar
              isOpenFilters={isOpenFilters}
              hasSavedFilters={hasSavedFilters}
              onClearFilters={handleClearFilters}
              onOpenFilters={handleOpenFilters}
            />

            <CreateOrderButton
              className={classes.createWorkOrderButton}
              showSmall
              onClick={onOpenNewOrderPopup}
            />
          </div>
        </div>
      </>
    );
  };

  useEffect(() => {
    if (!pageSize && tableContainerRef?.current) {
      setPageSize(countPageSize(tableContainerRef));
    }
  }, [tableContainerRef]);

  useEffect(() => {
    isOpenTab && setHasSavedFilters(hasSavedOpenFilters);
    isAssignedTab && setHasSavedFilters(hasSavedAssignedFilters);
    isCompleteTab && setHasSavedFilters(hasSavedCompleteFilters);
    isClosedTab && setHasSavedFilters(hasSavedClosedFilters);
    isCanceledTab && setHasSavedFilters(hasSavedCanceledFilters);
    setActivePage(1);
  }, [
    hasSavedOpenFilters,
    hasSavedAssignedFilters,
    hasSavedCompleteFilters,
    hasSavedClosedFilters,
    hasSavedCanceledFilters,
    activeTab
  ]);

  return (
    <AppPage>
      <AppPageHeader className={classes.pageHeader}>
        {renderHeaderContent()}
      </AppPageHeader>
      <AppPageContent className={classes.appPageContent}>
        <Grid container>
          <Paper className={classes.columnPaper}>
            <MaintenanceWorkOrderList
              onItemClick={handleWorkOrderClick}
              onOpenDetails={handleOpenWorkOrdersDetail}
              selectedId={selectedWorkOrderId}
              highlightedId={popoverWorkOrder?.id}
              onSortClick={handleSortClick}
              workOrders={workOrders}
              totalCountWO={totalCountWO}
              isLoading={isWorkOrdersLoading || isInfiniteLoading}
              isFetching={isFetching}
              order={order}
              orderBy={orderBy}
              pageNumber={activePage}
              pageSize={pageSize}
              onPageChange={handlePageChange}
              tableContainerRef={tableContainerRef}
              onActionClick={handleActionClick}
              activeTab={activeTab}
              onOpenActionsMenu={handleOpenActionsMenu}
              isOpenActionsMenu={isOpenActionsMenu}
              tableBodyRef={tableBodyRef}
              onClearFilters={handleClearFilters}
              hasSavedFilters={hasSavedFilters}
            />
            <Loader
              className={clsx(classes.infiniteLoader, {
                [classes.showInfiniteLoader]: isFetchingNextPage,
                [classes.showOverlayLoader]: isInfiniteLoading
              })}
            />
          </Paper>
        </Grid>
      </AppPageContent>

      {isNewWorkOrderOpen && (
        <WorkOrderOverlayForm
          selectedWorkOrder={selectedWorkOrder}
          onClose={onCloseNewOrderPopup}
          facilityName={userFacility?.name}
          facilityId={userFacility?.id}
          isOpen
        />
      )}

      {isDetailsOpen && (
        <MaintenanceWorkOrdersDetail
          selectedWorkOrder={selectedWorkOrder}
          onUpdateEditedWorkOrder={setSelectedWorkOrder}
          onClose={handleCloseWorkOrdersDetail}
          onUpdate={handleWorkOrderUpdate}
          activeTab={activeTab}
          isOpen
        />
      )}

      <div className={classes.mobileAnchor} ref={mobileAnchorRef} />

      {Boolean(popoverType) && (
        <WorkOrderPopover
          type={popoverType}
          anchor={matchesDownXS420 ? mobileAnchorRef.current : popoverAnchor}
          workOrder={popoverWorkOrder}
          onSuccess={handlePopoverConfirm}
          onClose={handlePopoverClose}
          PopoverProps={popoverProps}
          isOpen
        />
      )}

      {isOpenActionsMenu && (
        <ActionsButtonsPopover
          onClose={handleCloseActionsMenu}
          anchorCoordinates={anchorCoordinates}
          onActionClick={handleActionClick}
          onOpenDetails={handleOpenWorkOrdersDetail}
          selectedWorkOrder={workOrders.find(
            item => item.id === selectedWorkOrderId
          )}
          activeTab={activeTab}
          open
        />
      )}

      {isOpenFilters && (
        <WorkOrdersFiltersDialog
          onClose={handleCloseFilters}
          activeTab={activeTab}
        />
      )}
    </AppPage>
  );
};

export default MaintenanceWorkOrdersPage;
