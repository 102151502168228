export const REQUESTS_PER_PAGE = 12;
export const EXAMS_PER_PAGE = 50;

export interface ColumnSettings {
  columnNames: string[];
  requestsOrderBy: string[];
  examsOrderBy?: string[];
}

export const columnSettings: ColumnSettings[] = [
  {
    columnNames: ["horse.name", "horse.trainer.lastName"],
    requestsOrderBy: ["horse.name", "horse.trainer.lastName"],
    examsOrderBy: ["horse.name", "horse.trainer.lastName"]
  },
  {
    columnNames: ["date"],
    requestsOrderBy: ["date"],
    examsOrderBy: ["workoutRequest.date"]
  },
  {
    columnNames: ["horse.barn.name"],
    requestsOrderBy: ["horse.barn.name"],
    examsOrderBy: ["horse.barn.name"]
  },
  {
    columnNames: ["workoutExam.reason"],
    requestsOrderBy: ["workoutExam.reason"],
    examsOrderBy: ["reason"]
  },
  {
    columnNames: ["horse.healthWatchStatus"],
    requestsOrderBy: ["horse.healthWatchStatus"],
    examsOrderBy: ["horse.healthWatchStatus"]
  },
  {
    requestsOrderBy: ["workoutExam.type"],
    columnNames: ["workoutExam.type"],
    examsOrderBy: ["type"]
  },
  {
    columnNames: ["workoutExam.assignedUser.lastName"],
    requestsOrderBy: ["workoutExam.assignedUser.lastName"],
    examsOrderBy: ["assignedUser.lastName"]
  },
  {
    columnNames: ["horse.birthday"],
    requestsOrderBy: ["horse.birthday"],
    examsOrderBy: ["workoutRequest.horse.birthday"]
  },
  {
    columnNames: ["highSpeedFurlongs"],
    requestsOrderBy: ["highSpeedFurlongs"],
    examsOrderBy: ["workoutRequest.highSpeedFurlongs"]
  },
  {
    columnNames: ["horse.lastWorkoutDate"],
    requestsOrderBy: ["horse.lastWorkoutDate"],
    examsOrderBy: ["workoutRequest.horse.lastWorkoutDate"]
  },
  {
    columnNames: ["horse.lastRaceDate"],
    requestsOrderBy: ["horse.lastRaceDate"],
    examsOrderBy: ["workoutRequest.horse.lastRaceDate"]
  },
  {
    columnNames: ["horse.lastExamDate"],
    requestsOrderBy: ["horse.lastExamDate"],
    examsOrderBy: ["workoutRequest.horse.lastExamDate"]
  },
  {
    columnNames: ["horse.riskFactorsCount"],
    requestsOrderBy: ["horse.riskFactorsCount", "horse.risksGroupTag"],
    examsOrderBy: [
      "workoutRequest.horse.riskFactorsCount",
      "workoutRequest.horse.risksGroupTag"
    ]
  },
  {
    columnNames: ["isFlagged"],
    requestsOrderBy: ["isFlagged"],
    examsOrderBy: ["workoutRequest.isFlagged"]
  },
  //TODO: uncomment when Barn-Stall api ready
  // {
  //   columnNames: ["BarnStall"]
  // },
  {
    columnNames: ["workoutExam.assignedUser.lastName"],
    requestsOrderBy: ["workoutExam.assignedUser.lastName"],
    examsOrderBy: ["assignedUser.lastName"]
  },
  {
    columnNames: ["horse.activeVetListsCount"],
    requestsOrderBy: ["horse.activeVetListsCount"],
    examsOrderBy: ["horse.activeVetListsCount"]
  },
  {
    requestsOrderBy: ["un-assign"],
    columnNames: ["un-assign"]
  },
  {
    requestsOrderBy: ["last"],
    columnNames: ["last"]
  }
];

export const listWorkoutRequestsSelectedFields = [
  "id",
  "facility.name",
  "date",
  "status",
  "pastWorkoutRequestsCount",
  "examReasonNote.id",
  "examReasonNote.body",
  "examReasonNote.createdBy.firstName",
  "examReasonNote.createdBy.lastName",
  "examReasonNote.createdOn",
  "examReasonNote.updatedBy.firstName",
  "examReasonNote.updatedBy.lastName",
  "examReasonNote.updatedOn",
  "highSpeedFurlongs",
  "horse.id",
  "horse.name",
  "horse.barn.name",
  "horse.birthday",
  "horse.chipNumber",
  "horse.completedWorkoutExamsWithNoteCount",
  "horse.flagOnTrack.flagOnTrackComment",
  "horse.gender",
  "horse.color",
  "horse.hasActualFlagOnTrack",
  "horse.healthWatchStatus",
  "horse.lastWorkoutDate",
  "horse.lastRaceDate",
  "horse.lastExamDate",
  "horse.registrationNumber",
  "horse.trainer.firstName",
  "horse.trainer.middleName",
  "horse.trainer.lastName",
  "horse.lastPastPerformances",
  "horse.lastWorkouts",
  "horse.lifetime",
  "horse.riskFactors",
  "horse.riskFactorsCount",
  "horse.lastCompletedWorkoutExamWithNote.note",
  "horse.lastCompletedWorkoutExamWithNote.assignedUser.firstName",
  "horse.lastCompletedWorkoutExamWithNote.assignedUser.lastName",
  "horse.lastCompletedWorkoutExamWithNote.completedDate",
  "horse.activeVetListsCount",
  "workoutExam.id",
  "workoutExam.type",
  "workoutExam.assignedUser.firstName",
  "workoutExam.assignedUser.lastName",
  "workoutExam.horse.trainer.firstName",
  "workoutExam.horse.trainer.lastName",
  "created_on",
  "updated_on",
  "hasComment",
  "flagRequest.id",
  "flagRequest.createdBy.lastname",
  "flagRequest.priority",
  "flagRequest.createdOn"
];

export const listWorkoutRequestCommentSelectedFields = [
  "id",
  "body",
  "createdOn",
  "updatedOn",
  "createdBy.firstName",
  "createdBy.lastName",
  "workoutRequest.id"
];

export const listWorkoutExamsSelectedFields = [
  "id",
  "status",
  "result",
  "reason",
  "note",
  "riskLevel",
  "type",
  "completedDate",
  "assignedUser.firstName",
  "assignedUser.lastName",
  "highSpeedFurlongs",
  "horse.name",
  "horse.barn.name",
  "horse.birthday",
  "horse.gender",
  "horse.color",
  "horse.healthWatchStatus",
  "horse.lastWorkoutDate",
  "horse.lastRaceDate",
  "horse.lastExamDate",
  "horse.trainer.firstName",
  "horse.trainer.middleName",
  "horse.trainer.lastName",
  "horse.registrationNumber",
  "horse.riskFactors",
  "horse.riskFactorsCount",
  "horse.chipNumber",
  "horse.activeVetListsCount",
  "workoutRequest.isFlagged",
  "workoutRequest.id",
  "workoutRequest.facility.name",
  "workoutRequest.date",
  "workoutRequest.status",
  "workoutRequest.flagRequest.id",
  "workoutRequest.flagRequest.createdBy.lastname",
  "workoutRequest.flagRequest.priority",
  "workoutRequest.flagRequest.createdOn",
  "workoutRequest.examReasonNote.id",
  "workoutRequest.examReasonNote.body",
  "workoutRequest.examReasonNote.createdBy.firstName",
  "workoutRequest.examReasonNote.createdBy.lastName",
  "workoutRequest.examReasonNote.createdOn",
  "workoutRequest.examReasonNote.updatedBy.firstName",
  "workoutRequest.examReasonNote.updatedBy.lastName",
  "workoutRequest.examReasonNote.updatedOn",
  "workoutRequest.pastWorkoutRequestsCount"
];
