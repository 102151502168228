import parseISO from "date-fns/parseISO";
import startOfToday from "date-fns/startOfToday";
import differenceInCalendarYears from "date-fns/differenceInCalendarYears";
import { racehorse360 } from "@tsg/1st-grpc-web";
import { differenceInDaysOrNA } from "./date-utils";
import { parseHorseGender } from "./enum-parser";
import { DASH, NOT_ACCESSIBLE } from "common/constants";

export const CALIFORNIA_BRED_OR_CALIFORNIA_SIRED =
  "California Bred or California Sired";
export const FLORIDA_BRED = "Florida Bred";

export enum AlertEnum {
  na = "naAlert",
  non = "nonAlert",
  middle = "middleAlert",
  moderate = "moderateAlert",
  high = "highAlert"
}

export const getAlertStatus = (days: number, limit: number = 90): AlertEnum => {
  if (!Number.isInteger(days)) return AlertEnum.na;
  if (days > limit) return AlertEnum.high;
  if (days > 60) return AlertEnum.moderate;
  if (days > 30) return AlertEnum.middle;
  return AlertEnum.non;
};

export const toTitleCase = (str: string): string => {
  return str
    ?.toLowerCase()
    .trim()
    .split(/\s/)
    .map(word => word.charAt(0).toUpperCase() + word.substring(1))
    .join(" ");
};

export const formatBirthday = (
  date: string,
  isPluralizedString: boolean
): string => {
  const pluralize = (count, noun) =>
    `${count} ${noun}${count !== 1 ? "s" : ""}`;

  if (date) {
    const age =
      startOfToday().getUTCFullYear() - parseISO(date).getUTCFullYear();
    if (isPluralizedString) {
      return `${pluralize(age, "Year")} Old`;
    } else {
      return String(age);
    }
  } else {
    return "Unknown";
  }
};

export const getHorseAge = (
  horse: racehorse360.IHorse,
  isPluralizedString = false
): string => {
  return formatBirthday(horse?.birthday, isPluralizedString);
};

export const getWorkDaysOff = (horse: racehorse360.IHorse): number | string => {
  const raceOff = differenceInDaysOrNA(horse.lastRaceDate);
  const workoutOff = differenceInDaysOrNA(horse.lastWorkoutDate);
  const workOff = Math.min(
    Number.isInteger(workoutOff)
      ? Number(workoutOff)
      : Number.POSITIVE_INFINITY,
    Number.isInteger(raceOff) ? Number(raceOff) : Number.POSITIVE_INFINITY
  );
  return Number.isInteger(workOff) ? workOff : "N/A";
};

export const getHorseAgeSexColorValue = (
  horse: racehorse360.IHorse,
  divider: string = "."
): string => {
  if (!horse) return NOT_ACCESSIBLE;

  const age = horse.birthday
    ? differenceInCalendarYears(new Date(), new Date(horse.birthday))
    : DASH;
  const sex = parseHorseGender(horse.gender).trim();
  const color = horse?.color?.trim() || DASH;

  return `${color}${divider}${sex}${divider}${age}`;
};
